







































































import { Vue, Component } from 'vue-property-decorator';
import { AppModule, RegionModule } from '@/utils/storemodules';
import Nav from '@/components/NewNavigation.vue';
import Footer from '@/components/NewFooter.vue';
import OrganizerCard from './presentational/OrganizerCard.vue';
import SliderNav, { INavElement } from '@/components/SliderNav.vue';
import Socials from '@/components/presentational/Socials.vue';
import { TicketType } from '@/models/store/booking';
import CardsSlider from '@/components/presentational/CardsSlider.vue';
import Card from '@/components/presentational/Card.vue';
import Subscription from '@/components/presentational/Subscription.vue';
import { NModalModule, OrganizerModule } from '@/utils/storemodules';
import { ModalStatus } from '@/models/definitions';
import { IBrandingInfo, IBrandingPicture, IOrganizerMainInfo } from '@/models/organizer';
import { setOrganizerColor, resetSiteColors } from '@/utils/colors';
import { Meta } from '@/utils/decorators';
import { numberWithApostrophes, setLangForMeta } from '@/utils/helpers';
import moment from 'moment';
import { Route } from 'vue-router';
import dayjs from 'dayjs';
import { ISocialsInfo } from '@/models/store/product';
import { RouteNames } from '@/models/enums';

@Component({
  components: {
    Nav,
    Footer,
    OrganizerCard,
    SliderNav,
    Socials,
    CardsSlider,
    Card,
    Subscription,
  },
})
export default class OrganizerPage extends Vue {
  private isLoading: boolean = true;
  private activities: string = '';
  private brandingInfo: IBrandingInfo | null = null;
  private socialsInfo: ISocialsInfo | null = null;
  private organizerLogo: string = '';
  private organizerName: string = '';
  private picturesArray: IBrandingPicture[] | null = null;

  private get pageTags(): INavElement[] {
    const defaultTags = [
      {
        content: this.$t('organizer-page.about'),
        anchor: '#about',
      },
      {
        content: this.$t('organizer-page.top-sellers'),
        anchor: '#sellers',
      },
      // {
      //   content: this.$t('organizer-page.calendar'),
      //   anchor: '#calendar',
      // },
      {
        content: this.$t('organizer-page.all-activities'),
        anchor: '#activities',
      },
      // {
      //   content: this.$t('organizer-page.reviews'),
      //   anchor: '#reviews',
      // },
    ];
    if ( this.socialsInfo ) {
      defaultTags.splice(1, 0, {
        content: this.$t('organizer-page.social-media'),
        anchor: '#socials',
      });
    }
    return defaultTags;
  }

  private openContactForm() {
    NModalModule.setStatus(ModalStatus.ContactOrganizer);
  }

  private get isTouch() {
    return AppModule.isTouch;
  }

  private get isTablet() {
    return AppModule.isTablet;
  }

  private get isMobile() {
    return AppModule.isMobile;
  }

  private get isDesktop() {
    return AppModule.isDesktop;
  }

  private get getAllActivitiesProducts() {
    return this.isMobile ? OrganizerModule.getAllActivities.slice(0, 4) : OrganizerModule.getAllActivities;
  }

  private get getTopSellersProducts() {
    return OrganizerModule.getTopSellersActivities;
  }

  private get membershipProducts() {
    return this.isMobile ? OrganizerModule.memberships.slice(0, 4)
                         : OrganizerModule.memberships;
  }

  private get giftCards() {
    return this.isMobile ? OrganizerModule.giftCards.slice(0, 4)
                         : OrganizerModule.giftCards;
  }

  private get organizerInfo() {
    return OrganizerModule.getInfo;
  }

  private get backgroundPicture() {
    if ( this.picturesArray) {
      const desktopPicture = this.picturesArray.find((item: IBrandingPicture) => {
        return item.type === 1;
      });
      const mobilePicture = this.picturesArray.find((item: IBrandingPicture) => {
        return item.type === 2;
      });
      if ( !this.isMobile && desktopPicture) {
        return desktopPicture;
      } else {
        return mobilePicture;
      }
    }
  }

  private get pictureForMeta() {
    const OrganizerPicture = this.brandingInfo ? this.brandingInfo.brandingPictures.find((item: any) => {
      return item.type === 4;
    }) : '';
    return OrganizerPicture;
  }

  @Meta
  private metaInfo() {
    const brandingInfo = this.brandingInfo;
    const companyName = (brandingInfo && brandingInfo.companyName) || '';

    // Since the organizer description may contain html tags. Let's use the dom as a parser
    let description = (brandingInfo && brandingInfo.description) || '';
    const div = document.createElement('div');
    div.innerHTML = description;
    description =  div.textContent || div.innerText;

    return {
      title: this.brandingInfo ? `${companyName} | Smeetz` : '',
      link: [
        { rel: 'canonical', href: location.protocol + '//' + location.host + location.pathname },
      ],
      meta: [
        { hid: 'title', name: 'title',
          content: this.brandingInfo ? `${companyName} | Smeetz` : '' },
        { hid: 'description', name: 'description', content: this.brandingInfo ? description : ''},
        { property: 'fb:admins', content: '100001288015151' },
        { hid: 'og:title', property: 'og:title',
          content: this.brandingInfo ? `${companyName} | Smeetz` : ''},
        { hid: 'og:description', property: 'og:description',
          content: this.brandingInfo ? description : '' },
        { hid: 'og:type', property: 'og:type', content: 'page' },
        { hid: 'og:url', property: 'og:url', content: location.protocol + '//' + location.host + location.pathname },
        { hid: 'og:image', property: 'og:image',
          content: this.brandingInfo ? this.pictureForMeta ? this.pictureForMeta.url : '' : ''},
        { hid: 'og:image:alt', property: 'og:image:alt',
          content: this.brandingInfo ? `${companyName} logo | Smeetz` : '' },
        { hid: 'og:locale', property: 'og:locale',
          content: setLangForMeta(this.$route.params.locale || this.$i18n.locale)},
      ],
    };
  }

  private async created() {
    const { organizer_slug } = this.$route.params;
    this.isLoading = true;
    try {
        await OrganizerModule.getOrganizerInfo(organizer_slug);
        if ( this.organizerInfo ) {
        const { productCount, groupInfo } = this.organizerInfo;
        this.activities = numberWithApostrophes(Number(productCount));
        this.brandingInfo = groupInfo.branding as IBrandingInfo;
        const brandingPictures = this.brandingInfo.brandingPictures;
        // logo taken from organizer square logo
        // type === 1 - desktop, type === 2 - mobile, type === 3 - logo, type === 4 - square logo
        const logo = brandingPictures.find((item: any) => {
          return item.type === 4;
        });
        if ( this.brandingInfo ) {
          this.organizerLogo = logo ? logo.url : '';
          this.organizerName = this.brandingInfo.companyName;
          this.getProductSocials();
          this.getPictures();
          setOrganizerColor({
            primary: this.brandingInfo.smeetzPrimaryColor,
          });
        }
        this.getAllActivities(1);
        this.getTopSellers(1);
        this.getMemberships(1);
        this.getGiftCards(1);
        this.isLoading = false;
      }

    } catch (err) {
      this.isLoading = false;
      this.$router.replace({name: RouteNames.PageNotFound});
    }
  }

  private beforeDestroy() {
    resetSiteColors();
  }

  private getPictures() {
    this.picturesArray = (this.brandingInfo as IBrandingInfo).brandingPictures;
  }

  private getProductSocials() {
    const { website,
            facebookWebsite,
            instagramWebsite,
            linkedinWebsite,
            youtubeWebsite } = this.brandingInfo as IBrandingInfo;

    const socialsObject = {
      website,
      facebook: facebookWebsite,
      instagramm: instagramWebsite,
      linkedin: linkedinWebsite,
      youtube: youtubeWebsite,
    };
    function checkEveryParameterForEmpty(obj: any) {
      for (const i of Object.keys(obj)) {
        if ( obj[i] ) {
          return true;
        }
        return false;
      }
    }
    this.socialsInfo = checkEveryParameterForEmpty(socialsObject) ? socialsObject : null;
  }

  private async getAllActivities(current: number = 1, size: number = 7) {
    await OrganizerModule.fetchAppSearch({
        type: 'AllActivities',
        options: {
          page: {
            current,
            size,
          },
          search_fields: { organiser_id: {} },
          sort: {
            dates: 'desc',
          },
          filters: {
            all : [
              {
                organiser_id: ((this.organizerInfo as IOrganizerMainInfo).groupInfo.id).toString(),
              },
              // { dates: { from: dayjs().toISOString() } }, // from now
              { dates: { from: new Date(new Date().setHours(0, 0, 0, 0)).toISOString() } }, // from midnigh
              { smeetz_channel: 1 },
            ],
          },
        },
    });
  }

  private async getTopSellers(current: number = 1, size: number = 16) {
    await OrganizerModule.fetchAppSearch({
        type: 'TopSellers',
        options: {
          page: {
            current,
            size,
          },
          search_fields: { organiser_id: {} },
          sort: {
            dates: 'desc',
          },
          filters: {
            all : [
              {
                organiser_id: ((this.organizerInfo as IOrganizerMainInfo).groupInfo.id).toString(),
              },
              // { dates: { from: dayjs().toISOString() } }, // from now
              { dates: { from: new Date(new Date().setHours(0, 0, 0, 0)).toISOString() } }, // from midnigh
              { smeetz_channel: 1 },
            ],
          },
        },
    });
  }

  private async getMemberships(current: number = 1, size: number = 16) {
    await OrganizerModule.fetchAppSearch({
        type: 'Memberships',
        options: {
          page: {
            current,
            size,
          },
          search_fields: { organiser_id: {} },
          sort: {
            dates: 'desc',
          },
          filters: {
            all : [
              { organiser_id: ((this.organizerInfo as IOrganizerMainInfo).groupInfo.id).toString()},
              { ticket_type: [TicketType.Membership, TicketType.MembershipVoucher, TicketType.SeatedMembership]},
              { ticketing_status: 1 },
              { smeetz_channel: 1 },
            ],
          },
        },
    });
  }

  private async getGiftCards(current: number = 1, size: number = 16) {
    await OrganizerModule.fetchAppSearch({
        type: 'GiftCards',
        options: {
          page: {
            current,
            size,
          },
          search_fields: { organiser_id: {} },
          sort: {
            dates: 'desc',
          },
          filters: {
            all : [
              { organiser_id: ((this.organizerInfo as IOrganizerMainInfo).groupInfo.id).toString()},
              { ticket_type: [TicketType.Gift]},
              { ticketing_status: 1 },
              { smeetz_channel: 1 },
            ],
          },
        },
    });
  }

}

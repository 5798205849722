






import { Vue, Component } from 'vue-property-decorator';
import Debug from 'debug';
import { AuthModule } from '@/utils/storemodules';

const debug = Debug('smeetz:auth');

@Component({
  components: {
  },
})
export default class GoogleAuth extends Vue {
  @AuthModule.Action('gLogin') private gLogin!: any;

  private async login() {
    let authCode!: string;
    try {
      authCode = await this.$gAuth.getAuthCode();
      debug(authCode);
    } catch (err) {
      debug(err);
    }

    if (!authCode) {
      return;
    }

    this.gLogin({
      authCode,
    });
  }
}





























import DateHelperMixin from '@/mixins/DateHelper';
import { IGQLProductSlotState } from '@/models/store/booking';
import { NBookingModule, RBookingModule } from '@/utils/storemodules';
import dayjs from 'dayjs';
import { Component, Prop, Mixins, Watch } from 'vue-property-decorator';
import TicketCard from '@/views/OrganizerUserSpace/components/RefundBooking/MyBooking/TicketCard.vue';

@Component({
  components: {
    TicketCard,
  },
})
  export default class SelectedGroup extends Mixins<DateHelperMixin>(
  DateHelperMixin,
)  {
    @Prop({default: null}) public selectedGroup!: IGQLProductSlotState[];

    private get currency() {
      return NBookingModule.bookingSummary?.data.ProductSlotBookingRead.Currency;
    }
    private get selectedSlots() {
      return RBookingModule.selectedSlots;
    }
    private get selectedGroupReactive() {
      const slotsIds = this.selectedGroup.map((item) => item.Id);
      return this.selectedSlots.filter((item) => slotsIds.includes(item.Id));
    }

    private translateDate(date: string, onlyDate: boolean = true) {
      return this.currentDate(date, onlyDate);
    }

    private getTime(date: string) {
      const momentDate = dayjs(date);
      return this.formatTime(momentDate.format('HH:mm'));
    }
    private subTotal(slotsGroup: IGQLProductSlotState[]) {
      const subTotal = slotsGroup.map((item: IGQLProductSlotState) => item.PriceValue)
          .reduce((a: number, b: number) => a + b, 0);
      return subTotal / 100;
    }
}































































import { Vue, Component } from 'vue-property-decorator';
import UserSpaceCard from './components/UserSpaceCard.vue';
import { AuthModule, ModalModule, NModalModule } from '@/utils/storemodules';
import { ModalStatus } from '@/models/definitions';

@Component({
  components: {
    UserSpaceCard,
  },
})
export default class Phones extends Vue {
  private ModalStatus = ModalStatus;

  @AuthModule.Getter('getPhones') private phones: any;
  // @ModalModule.Mutation('setStatus') private setStatus: any;
  @ModalModule.Mutation('setData') private setData: any;
  @AuthModule.Action('cancelEmailPhone') private cancelPhone!: any;
  @AuthModule.Action('setPrimaryEmail') private setPrimaryPhone!: any;
  @AuthModule.Action('getUser') private getUser!: any;

  private setStatus(status: ModalStatus) {
    NModalModule.setStatus(status);
  }

  private async removePhone(phone: any) {

    try {
      await this.cancelPhone({
        emailPhoneId: phone.id,
        medium: 'phone',
      });
      await this.getUser();
    } catch (err) {
      return;
    }
  }

  private verifyPhone(phone: any) {
    this.setData({
      phone: phone.countryCode + phone.number,
    });
    this.setStatus(ModalStatus.MobilePhoneConf);
    return;
  }

  private makePrimary() {
    return;
  }
}

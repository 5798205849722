var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"ticket-badge",class:{
    'smtz-primary-tag': _vm.colorType === 'primary',
    'smtz-secondary-tag': _vm.colorType === 'secondary',
  },style:({
    color: _vm.txtColor,
    'background-color': _vm.bgColor,
    'padding': _vm.pad || '5px',
  })},[_vm._v(" "+_vm._s(_vm.txt)+" ")])}
var staticRenderFns = []

export { render, staticRenderFns }











import {Vue, Component} from 'vue-property-decorator';
import VueRecaptcha from 'vue-recaptcha';

@Component ({
    components: {
      VueRecaptcha,
    },
  })
export default class Recaptcha extends Vue {
  public responseRecaptcha: string = 'ok';

  private sitekey: string = '6LdgYj4UAAAAAMfYiG-gzvmrTdRLAwz7umanWOIA';

  public onVerify(response: string): void {
    // if (response) {
    //   this.$emit('ready');
    // }
    this.$emit('ready', response);
  }

  public expired() {
    this.$emit('expire');
  }

/*
  public onVerify(response: string): string {
    this.$emit('verify', response);
    return response;
  }

*/
  // public onVerify(response: string = 'dff'): string {
  //   return this.responseRecaptcha = response;
  // }

/*
  public recaptchaVerify(): string {
    return this.responseRecaptcha;
  }
*/
}
































































































import { Vue, Component } from 'vue-property-decorator';
import Nav from '@/components/NewNavigation.vue';
import Footer from '@/components/NewFooter.vue';
import { AppModule, RegionModule } from '@/utils/storemodules';
import SearchBar from './components/SearchBar.vue';
import DateFilter from './components/DateFilter.vue';
import ModalFilters from './components/ModalFilters.vue';
import ListItemsFilter from './components/ListItemsFilter.vue';
import Card from '@/components/presentational/Card.vue';
import CardsSlider from '@/components/presentational/CardsSlider.vue';
import Products from '@/components/product/Products.vue';
import { ProductsModule } from '@/utils/storemodules';
// Modal components
import NewButton from '@/components/presentational/NewButton.vue';
import Select from '@/components/presentational/NewSelect.vue';
import dayjs from 'dayjs';
import { Meta } from '@/utils/decorators';
import { setLangForMeta } from '@/utils/helpers';
import { ProductLocation, ProductSearchCity } from '@/models/store/products';

@Component({
  components: {
    Nav,
    Footer,
    SearchBar,
    DateFilter,
    ListItemsFilter,
    Card,
    CardsSlider,
    // Modal components
    ModalFilters,
    NewButton,
    Select,
    Products,
  },
})
export default class SearchPage extends Vue {
  // private isLoading: boolean = false;
  private searchString: string = 'Festivale';
  // Modal and index components
  // private categoriesItems: string[] = [
  //   'Nightclub',
  //   'Electro party',
  //   'Festival',
  //   'Silent disco',
  //   'Workshop',
  //   'Clubbing',
  //   'Outdoor',
  // ];
  // Modal parameters
  private sortBy: string[] = ['Most popular', 'Price(Lowest first)'];
  private from: string = '';
  private to: string = '';
  private currentCategory: string = '';
  private currentRegion: string = '';
  private currentSort: string = '';
  // private documentBody: HTMLElement = document.getElementsByTagName('body')[0];

  private setSelectValue(type: string, item: string) {
    this.$emit('sortBy', item);
    this.currentSort = item;
  }

  get categories() {
    const lang = this.$i18n.locale ;
    const english = 'en';
    // Going to display the right translation of the category name with first letter capitalized
    // If the right translation wasn't supplied we'll use English corresponding
    const categories = RegionModule.categories.map((c) => ({
      label: c.translations[lang] ?
      c.translations[lang][0].toUpperCase() + c.translations[lang].slice(1).toLowerCase()
      : c.translations[english][0].toUpperCase() + c.translations[english].slice(1).toLowerCase(),
      value: c.name,
    }));
    return categories;
  }

  get regionsItems() {
    const lang = this.$i18n.locale ;
    const regions = RegionModule.regions.map((r) => {
      return { label: lang === 'en' ? r.label : r.translations[lang], value: r.value };
    });
    return regions;
  }

  private get isMobile() {
    return AppModule.isMobile;
  }

  private get isTablet() {
    return AppModule.isTablet;
  }

  private get isLoading() {
    return ProductsModule.isLoading;
  }

  @Meta
  private metaInfo() {
    return {
      title: this.$t('seo.title-discover'),
      link: [
        {
          rel: 'canonical',
          href: location.protocol + '//' + location.host + location.pathname,
        },
      ],
      meta: [
        { hid: 'title', name: 'title', content: this.$t('seo.title-discover') },
        {
          hid: 'description',
          name: 'description',
          content: this.$t('seo.description-discover'),
        },
        { property: 'fb:admins', content: '100001288015151' },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.$t('seo.title-discover'),
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.$t('seo.description-discover'),
        },
        { hid: 'og:type', property: 'og:type', content: 'page' },
        {
          hid: 'og:url',
          property: 'og:url',
          content: location.protocol + '//' + location.host + location.pathname,
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content:
            this.$BASE_URL +
            'img/rasterized/1080px/homepage/homepage_slider_3.jpg',
        },
        {
          hid: 'og:image:alt',
          property: 'og:image:alt',
          content:
            this.$t('seo.title-discover') + ' ' + this.$t('common.image'),
        },
        {
          hid: 'og:locale',
          property: 'og:locale',
          content: setLangForMeta(
            this.$route.params.locale || this.$i18n.locale,
          ),
        },
        // Uncomment if we will show content relateed to lang in url
        // { hid: 'noindex', name: 'robots, follow', content: 'noindex' },
      ],
    };
  }

  private async created() {
    // lets store a list of categories to the state of it is not there already
    if (RegionModule.categories.length === 0) {
      RegionModule.setCategories(RegionModule.getCategories);
    }

    // lets store a list of regions to the state of it is not there already
    if (RegionModule.regions.length === 0) {
      RegionModule.fetchRegions();
    }

    window.scrollTo(0, 0);
    // this.documentBody.classList.add('smtz-q-app');
    ProductsModule.setOptions({
      // andFilters: [{ dates: { from: dayjs().toISOString() } }, {organiser_id: groupId}],
      // andFilters: [{ dates: { from: dayjs().toISOString() } }],

      // andFilters: [
      //   { product_location: {
      //         center: '46.5197, 6.633597',
      //         distance: 30,
      //         unit: 'km',
      //       }},

      //       // {
      //       //   smeetz_category: 'event'
      //       // },
      // ],
      // sort: 'dates',
      // sortDirection: 'asc',
      searchFields: {
        product_name: { weight: 10 },
        product_description: { weight: 5 },
        product_teaser: { weight: 3 },
        organiser_name: { weight: 2 },
      },
      // query: groupId,
      limit: 10,
    });
    await ProductsModule.search();
    await this.getRecommendedForYou(1);
  }

  // private beforeDestroy() {
  //   this.documentBody.classList.remove('smtz-q-app');
  // }

  private get isTouch() {
    return AppModule.isTouch;
  }

  private get getRecommendedForYouProducts() {
    return this.isMobile
      ? RegionModule.getRecommendedForYou.slice(0, 4)
      : RegionModule.getRecommendedForYou;
  }

  private get searchProducts() {
    return ProductsModule.products;
  }

  private submit() {
    ProductsModule.search();
  }

  private async getRecommendedForYou(current: number = 1, size: number = 10) {
    await RegionModule.fetchAppSearch({
      type: 'RecommendedForYou',
      options: {
        page: {
          current,
          size,
        },
        filters: {
          all: [
            { smeetz_channel: 1 },
            { ticketing_status: 1 },
            { status: 1 },
            {
              dates: {
                from: dayjs().toISOString(),
              },
            },
          ],
          none: [{ organiser_id: '1' }],
        },
      },
    });
  }

  private async resetFilters() {
    ProductsModule.setQuery('');
    ProductsModule.setProductLocation(ProductsModule.ignoredLocation);
    ProductsModule.setFrom(null);
    ProductsModule.setTo(null);
    ProductsModule.setSmeetzCategory([]);
    await ProductsModule.search();
  }
}

















































import { Vue, Component } from 'vue-property-decorator';
import { Mixins } from 'vue-mixin-decorator';
import DonationMixin from '@/mixins/Donation';
import { namespace } from 'vuex-class';
import { ModalStatus } from '@/models/definitions';
import { NModalModule } from '../../utils/storemodules';
import ReportActivity from '@/components/modal/ReportActivity.vue';
import { ProductModule } from '@/utils/storemodules';
import NewRegistration from '@/components/modal/NewRegistration.vue';
import NewLogin from '@/components/modal/NewLogin.vue';
import NewEmailConfirmation from '@/components/modal/NewEmailConfirmation.vue';
import NewForgotPassword from '@/components/modal/NewForgotPassword.vue';
import NewResetPassword from '@/components/modal/NewResetPassword.vue';
import BookingTimeEnd from '@/components/modal/BookingTimeEnd.vue';
import ContactOrganizer from '@/components/modal/NewContactOrganizer.vue';
import BookingEmailConf from '@/components/modal/BookingEmailConf.vue';
import BookingDonation from '@/components/modal/BookingDonation.vue';
import BookingDescription from '@/components/modal/BookingDescription.vue';
import CropImage from '@/components/modal/Crop.vue';
import { openLegalPage } from '@/utils/helpers';
import BookingPolicy from '@/components/modal/BookingPolicy.vue';
import { inIframe } from '@/utils/iframe';
import { hideFbLogin, hideGmailLogin } from '@/utils/helpers';
import Debug from 'debug';
const debug = Debug('smeetz:auth');

const modalModule = namespace('modal');

@Component({
  components: {
    ReportActivity,
    NewRegistration,
    NewLogin,
    NewEmailConfirmation,
    NewForgotPassword,
    NewResetPassword,
    BookingTimeEnd,
    ContactOrganizer,
    CropImage,
    BookingPolicy,
    BookingEmailConf,
    BookingDonation,
    BookingDescription,
  },
})
export default class NewModalOverview extends Mixins<DonationMixin>(DonationMixin) {
  // enableSubmit - activate Send button
  private enableSubmit: boolean = false;
  // sendableData - data that you can write from main component
  private sendableData: any = null;
  private classElem: string = 'stop-scrolling';
  private documentHTML: HTMLElement = document.getElementsByTagName('html')[0];
  private documentBody: HTMLElement = document.getElementsByTagName('body')[0];

  private ModalStatus = ModalStatus;
  // Get status from old and new solutions
  @modalModule.Getter('getStatus') private modalStatus!: ModalStatus;
  private get getModalStatus(): ModalStatus {
    return NModalModule.status || this.modalStatus;
  }

  // Indicates in which modal shall we hide the policies
  get hidePolicy() {
    const statuses = [
      ModalStatus.policyAgreement,
      ModalStatus.Donation,
      ModalStatus.Description,
    ];

    return statuses.includes(this.getModalStatus);
  }

  @modalModule.Mutation('setStatus') private setStatus!: any;
  // Here is header title for modal
  private get headerSpan() {
    if ( NModalModule.status === ModalStatus.ReportActivity ) {
      return this.$t('product.report-title');
    } else if ( NModalModule.status === ModalStatus.NewRegistration ) {
      return this.$t('modal-headers.sign-up');
    } else if ( NModalModule.status === ModalStatus.NewLogin ) {
      return this.$t('modal-headers.sign-in');
    } else if ( NModalModule.status === ModalStatus.NewEmailConfirmation ) {
      return this.$t('modal-headers.email-confirmation');
    } else if ( NModalModule.status === ModalStatus.NewForgotPassword ) {
      return this.$t('modal-headers.forgot-password');
    } else if ( NModalModule.status === ModalStatus.NewResetPassword ) {
      return this.$t('modal-headers.reset-password');
    } else if ( NModalModule.status === ModalStatus.bookingTimeEnd ) {
      return this.$t('modal-headers.booking-expired');
    } else if ( NModalModule.status === ModalStatus.ContactOrganizer ) {
      return 'Contact the organizer';
    } else if ( NModalModule.status === ModalStatus.CropImage ) {
      return this.$t('modal-headers.crop-picture');
    } else if (NModalModule.status === ModalStatus.policyAgreement ) {
      return this.$t('modal-headers.booking-policies');
    } else if (NModalModule.status === ModalStatus.BookingEmailConf ) {
      return this.$t('modal-headers.email-confirmation');
    } else if (NModalModule.status === ModalStatus.Donation && this.donationName) {
      return this.donationName;
    } else if (NModalModule.status === ModalStatus.Description) {
      return NModalModule.popupTitle;
    }
  }

  private beforeDestroy() {
    this.allowScrolling();
  }

  private mounted() {
    this.stopScrolling();
  }

  private get isInIframe() {
    return inIframe();
  }

  private get hideCloseButton() {
    return this.getModalStatus === ModalStatus.bookingTimeEnd;
  }

  private stopScrolling(): void {
    this.documentHTML.classList.add(this.classElem);
    this.documentBody.classList.add(this.classElem);
  }

  private allowScrolling(): void {
    this.documentHTML.classList.remove(this.classElem);
    this.documentBody.classList.remove(this.classElem);
  }

  private modalClose() {
    this.allowScrolling();
    this.$emit('close');
    NModalModule.setPopupTitle(null);
    NModalModule.setPopupDescription(null);
    this.setStatus(ModalStatus.Hidden);
    NModalModule.setStatus(ModalStatus.Hidden);
  }

  private goToLegalPage() {
    openLegalPage.call(this);
  }

  private get hideSocialLoginHeader() {
    if ((hideFbLogin() && hideGmailLogin() && NModalModule.status === ModalStatus.NewLogin)) {
      return true;
    } else {
      return false;
    }
  }

}

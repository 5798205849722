var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"dynamic-menu",class:{
      'active': _vm.active,
    }},[_c('nav',{staticClass:"dynamic-nav",class:{
      'transparent': _vm.transparent
    }},[_c('div',{staticClass:"smeetz-burger",class:{
        'active': _vm.active,
      },on:{"click":function($event){$event.stopPropagation();return _vm.toggle($event)}}},[_c('span'),_c('span'),_c('span')])]),_c('div',{staticClass:"menu",class:{'active': _vm.active}},_vm._l((_vm.links),function(link){return _c('router-link',{key:link.name,staticClass:"menu-item",class:{
        'selected': _vm.selectedLink === _vm.$t(("dynamic." + (link.name)))
      },attrs:{"to":{
        path: link.route,
      }}},[_vm._v(_vm._s(_vm.$t(("dynamic." + (link.name)))))])}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }
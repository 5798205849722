import Vue from 'vue';
// import { fetchTicketSlots } from '@/api/booking';
import dayjs from 'dayjs';
import { Prop, Watch } from 'vue-property-decorator';
import { Mixin } from 'vue-mixin-decorator';
import {
  ITimeslotsEntity, IExportPricesEntity, ITicket,
  IFrontRecapTicket, IPriceCount, PriceStatus, IteamInfo, ValidAccompCount, ITeamSetting, ISmtzTrackingData,
} from '@/models/store/booking';
import { EVSelect, EVSlotPrice } from '@/models/events';
import { NBookingModule, ProductModule, NNoty, AppModule, OrganizerModule, UserModule } from '@/utils/storemodules';
import { IPackagePrice, IPricePackage } from '@/models/store/packages';
import { genMainTicketId, genPackageId, isAdultPriceCategory, isChildPriceCategory } from '@/utils/booking';
import MembershipBooking from './MembershipBooking';
import {
  didAllCustomersBookThisCategory, didCustomersBookChild, didCustomersBookNonChild,
  hadAllCustomersBooked, setPriceValidatedBookingCodes,
} from '@/utils/membership';
import { isFamilyMembership } from '../utils/membership';
import { smeetzTracker } from '@/utils/tracking';
import { EventType } from '@/models/enums';
import { isIos } from '@/utils/platform';
import { removeCustomersTickets } from '@/utils/membership-utils';
import { MembershipType } from '@/models/memberships';
import { Loading } from 'quasar';
import { disableQuasarStyling, enableQuasarStyling } from '@/utils/styles';
import { RequiresBookingCodes } from '@/utils/booking-utils';
import { inIframe } from '@/utils/iframe';
import { fetchTicketSlots } from '@/api/booking';
import { nearestFutureSlot } from '@/utils/timeslot';
import { LoggerService } from '@/services/log';
import {PriceCategory} from '@/models/enums';
import { bookingFlowStartTracking, bookingFlowRemoveFromCart } from '@/utils/tracking';
import { IProduct } from '@/models/store/product';
@Mixin
// export default class SlotPriceMixin extends Vue {
export default class SlotPriceMixin extends MembershipBooking {
  @Prop() public pricePackage!: IPricePackage;
  @Prop() private originalPrice!: IExportPricesEntity;
  @Prop() private pprice!: IPackagePrice;
  @Prop() private timeSlot!: ITimeslotsEntity;
  @Prop() private ticket!: ITicket;
  @Prop() private mainTimeSlotId!: number;
  @Prop({ default: false }) private isAddonInTopLevel!: boolean;
  @Prop({ default: '' }) private unit!: string;
  // Hide top border flag
  @Prop({ default: false }) private noTopBord!: boolean;

  private EVSelect = EVSelect;
  private PriceStatus = PriceStatus;
  private isLoading = false;
  private slot: ITimeslotsEntity | null = null;
  // Count before add price operation
  private previousCount = 0;
  private openTimeoutDialog: boolean = false;
  private progressValue = 0;

  // Indicates whether the user already tried selecting this ticket
  // or not
  private isDirty = false;

  private accompMapping = {
    countMapping: {
      7: 2,
      8: 2,
      9: 3, 10: 3, 11: 3, 12: 3,
      13: 4, 14: 4, 15: 4, 16: 4,
    },

    priceIds: [
      {
        accompPriceId: 23038,
        childPriceId: 23032,
      },
      {
        accompPriceId: 23133,
        childPriceId: 23132,
        addultPriceId: 23131,
      },
      // Prod
      {
        accompPriceId: 211763,
        childPriceId: 205102, // Exploration
        addultPriceId: 205101,
      },
      {
        accompPriceId: 211763,
        childPriceId: 204643, // Decouverte
        addultPriceId: 204642,
      },
      // Brétigny
      // Accompagnant CAT id = 101287 product_ID =65469 , priceID= 231792
      // Formule decouverte cat id = 101312 product_ID = 65476 ;  adult_price_id = 231866 ; child_price_id = 231867
      // Formule Exploration cat ID = 101313  product_id = 65476 ; adult_price_id = 231868  ; child_price_id = 231869
      {
        accompPriceId: 231792,
        childPriceId: 231867, // Decouverte
        addultPriceId: 231866,
      },
      {
        accompPriceId: 231792,
        childPriceId: 231869, // Exploration
        addultPriceId: 231868,
      },
      // Angers
      // accompagnant cat_id = 100395  product_id = 65317 , price_id = 228533
      // Formule decouverte cat id = 100646 product_ID = 65369 ;  adult_price_id = 229316 ; child_price_id = 229317
      // Formule Exploration cat ID = 100647  product_id = 65369 ; adult_price_id = 229318  ; child_price_id = 229319
      {
        accompPriceId: 228533,
        childPriceId: 229317, // Decouverte
        addultPriceId: 229316,
      },
      {
        accompPriceId: 228533,
        childPriceId: 229319, // Exploration
        addultPriceId: 229318,
      },
      // Montpolier
      // accompagnant cat_id = 101365  product_id = 65486 , price_id = 232050
      // Formule decouverte cat id = 101485 product_ID = 65516 ;  adult_price_id = 234187 ; child_price_id = 234188
      // Formule Exploration cat ID =101486   product_id =65516  ; adult_price_id = 234189  ; child_price_id = 234190
      {
        accompPriceId: 232050,
        childPriceId: 234188, // Decouverte
        addultPriceId: 234187,
      },
      {
        accompPriceId: 232050,
        childPriceId: 234190, // Exploration
        addultPriceId: 234189,
      },
    ],

    accompCategories: {
      23131: {
        accompCategoryId: 45812,
        accompPriceId: 23133,
        productId: 37911,
      },
      23132: {
        accompCategoryId: 45812,
        accompPriceId: 23133,
        productId: 37911,
      },
      205101: {
        accompCategoryId: 93933,
        accompPriceId: 211763,
        productId: 63753,
      },
      205102: {
        accompCategoryId: 93933,
        accompPriceId: 211763,
        productId: 63753,
      },
      204642: {
        accompCategoryId: 93933,
        accompPriceId: 211763,
        productId: 63753,
      },
      204643: {
        accompCategoryId: 93933,
        accompPriceId: 211763,
        productId: 63753,
      },
      // Brétigny
      // decouverte
      231866: {
        accompCategoryId: 101287,
        accompPriceId: 231792,
        productId: 65469,
      },
      231867: {
        accompCategoryId: 101287,
        accompPriceId: 231792,
        productId: 65469,
      },
      // exploration
      231868: {
        accompCategoryId: 101287,
        accompPriceId: 231792,
        productId: 65469,
      },
      231869: {
        accompCategoryId: 101287,
        accompPriceId: 231792,
        productId: 65469,
      },

      // Angers
      // accompagnant cat_id = 100395  product_id = 65317 , price_id = 228533
      // decouverte
      229316: {
        accompCategoryId: 100395,
        accompPriceId: 228533,
        productId: 65317,
      },
      229317: {
        accompCategoryId: 100395,
        accompPriceId: 228533,
        productId: 65317,
      },
      // exploration
      229318: {
        accompCategoryId: 100395,
        accompPriceId: 228533,
        productId: 65317,
      },
      229319: {
        accompCategoryId: 100395,
        accompPriceId: 228533,
        productId: 65317,
      },
      // Montpolier
      // accompagnant cat_id = 101365  product_id = 65486 , price_id = 232050
      // decouverte
      234187: {
        accompCategoryId: 101365,
        accompPriceId: 232050,
        productId: 65486,
      },
      234188: {
        accompCategoryId: 101365,
        accompPriceId: 232050,
        productId: 65486,
      },
      // exploration
      234189: {
        accompCategoryId: 101365,
        accompPriceId: 232050,
        productId: 65486,
      },

      234190: {
        accompCategoryId: 101365,
        accompPriceId: 232050,
        productId: 65486,
      },
    },
  };

  private get currency() {
    return ProductModule.productCurrency;
  }

  get isPackagePrice(): boolean {
    const pprice = this.pprice;
    if (!pprice) {
      return false;
    }
    return true;
  }

  get price(): IExportPricesEntity {
    const pprice = this.pprice;
    if (!pprice) {
      const originalPrice = this.originalPrice;
      this.originalPrice.categoryId = this.ticket.categoryId;
      return originalPrice;
    }

    const productName = this.pricePackage ?
      this.pricePackage.package_categories[pprice.categoryId].product_name : undefined;

    return {
      priceId: pprice.priceId,
      priceName: pprice.priceName,
      priceValue: Number(pprice.displayedValue),
      priceStatus: pprice.priceStatus || 1,
      priceDescription: pprice.priceDescription || '',
      priceBasis: 1,
      minBooking: pprice.minQuantity,
      maxBooking: pprice.maxQuantity,
      quantityLeft: 10,
      blockedWithCode: false,
      count: 1,
      priceCategory: pprice.priceCategory,
      productName,
      categoryId: this.ticket.categoryId,
    };
  }

  get selectRef() {
    return String(Math.random());
  }

  get validatedBookingCodes() {
    return NBookingModule.validatedBookingCodes;
  }

  get priceCount(): number {
    if (NBookingModule.numberBookingCodePrices.length > 0 &&
      NBookingModule.numberBookingCodePrices[0].price.priceId === this.price.priceId) {
      return Number(NBookingModule.numberBookingCodePrices[0].count);
    }
    const priceId = this.price.priceId;

    const price = this.pprice ?
      NBookingModule // Package price
        .selectedPackagePriceById(
          priceId, this.timeSlot.id, genMainTicketId(String(this.mainTimeSlotId), String(this.pprice.mainPriceId)),
        )
      : NBookingModule // Normal prices
        .selectedNormalPriceById(
          priceId, this.timeSlot.id,
        );

    return (price && Number(price.quantity)) || 0;
  }


  // Returns th total qty of selected pricePackages
  get packagePricesCount(): number {
    let count = 0;
    // if not a package, ignore
    if (!this.pprice) {
      return count;
    }
    // construct mainticket
    const constructedMainTicket = `${this.mainTimeSlotId}:${this.pprice.mainPriceId}`;
    // Get the recap categories
    const bookings = NBookingModule.bookingRes;
    if (!bookings) {
      return count;
    }
    const recapCategories = bookings.bookingRecap.categories;
    // debug(recapCategories.length);
    if (!recapCategories || !recapCategories.length) {
      return count;
    }

    const packageCategories = this.pricePackage.package_categories;
    // combine the quantity of all recap categories with mainTicket equal to our constructed
    // main ticket and whise categoryId exist in package_categories
    // the second condition excludes addons booked automatically from the server
    for (const cat of recapCategories) {
      if (cat.mainTicket === constructedMainTicket
        && packageCategories[cat.categoryId] !== undefined) {
        count += Number(cat.quantity);
      }
    }
    return count;
  }

  // Soldout only if indicated by a priceStatus
  get isSoldOut() {
    return this.price.priceStatus === this.PriceStatus.SoldOut;
  }

  // Take MaxBooking into consideration
  get isMaxQuantityPerBooking() {
    return this.ticket.maxBooking !== 10000; // When maxBookingPerBooking is not set, its value will be 10000
  }

  // Not available if indicated by
  get isNotAvailable() {
    return this.price.priceStatus === this.PriceStatus.NotAvailable;
  }

  // returns the number of prices already booked for a given timeslot
  get bookedSlotQuantity(): number {
    const slot = this.slot || this.timeSlot;
    if (!slot) {
      return 0;
    }

    let quantity = 0;

    for (const t of NBookingModule.orderRecap) {
      if (t.timeSlotId === slot.id) {
        // timeslot found. add the prices and return the total
        for (const price of t.prices) {
          quantity = quantity + Number(price.quantity);
        }
        return quantity;
      }
    }

    return quantity;
  }

  // Get the quantity of booked included addons on second step (retails and tickets)
  get bookedAddonQuantityOnSecondStep(): number {
    let quantity = 0;
    if (NBookingModule.addonsSecondStepInfo && NBookingModule.addonsSecondStepInfo.length > 0) {
      const addonsIds: number [] = [];
      NBookingModule.addonsSecondStepInfo.forEach((addon) => {
        if (addon.included) {
          addonsIds.push(addon.categoryId);
        }
      });
      const bookedAddons = NBookingModule.recapCategories.filter((addon) =>
      addonsIds.includes(addon.categoryId));
      if (bookedAddons && bookedAddons.length > 0) {
        for (const addon of bookedAddons) {
          quantity += Number(addon.quantity);
        }
      }
    }
    return quantity;
  }

  // get the maximum of the top level package price To limit the tickets on Second step
  get maxQuantityPackagePriceForSecondStep() {
    const ticketAddon = NBookingModule.addonsSecondStepInfo.find((addon) =>
    addon.categoryId === this.ticket.categoryId);
    if (ticketAddon && ticketAddon.MaxQuantitySetAtTopLevel) {
      return ticketAddon.MaxQuantitySetAtTopLevel;
    }
  }

  // returns the number of prices already booked within same category
  get bookedCategoryQuantity(): number {
    if (!NBookingModule.recapCategories) {
      return 0;
    }

    return NBookingModule.recapCategories.reduce((total, cat) => {
      return this.price.categoryId === cat.categoryId ? total + Number(cat.quantity) : total;
    }, 0);
  }

  get priceRange(): number[] {
    const range = [0];
    const slot = this.slot || this.timeSlot;
    if (!this.price) {
      return range;
    }

    if (!slot) {
      return range;
    }

    // TODO
    if (this.pprice && !this.isAddonInTopLevel) {
      // The price range we're going to return concerns a package price
      const r = [0];

      const maxQuantity: number = this.packagePriceMaxQuantity;

      let minQuantity: number = this.packagePriceMinQuantity;


      // // Make sure that max quantity is multiplied by the amount of
      // // main price selected
      // // Ex: 2 main price selected & max quantity for addon is 7
      // // the total max quantity will be 2*7 = 14;
      // maxQuantity = maxQuantity * number_tickets;

      // TODO add max price selection validation
      // // Check how many prices we can still buy for this main price
      // if (max_quantity) {
      //   // get the prices that are selected for now.
      // }

      // // max quantity is the min of remaining
      // Make sure that max quantity is larger than 0
      // This can happen if user didn't configure maxQuantity or max_quantity
      // if (!maxQuantity) {
      //   maxQuantity = 100;
      // }

      while (minQuantity <= maxQuantity) {
        r.push(minQuantity);
        minQuantity++;
      }
      return r;
    }
    const min = this.price.minBooking;
    // max is remaining spots + what is already reserved
    let priceMax: number;
    // If this ticket is an addon, we take in consideration the max addon quantity
    if (this.ticket.isAddonInSeparateStep && this.ticket.maxAddonQuantity) {
      priceMax =
        Math.min(this.timeSlot.publicCount, this.ticket.maxAddonQuantity) - this.bookedSlotQuantity + this.priceCount;
    } else if (this.ticket.isAddonInSeparateStep && this.maxQuantityPackagePriceForSecondStep
      && this.price.priceValue === 0) {
      // If the ticket is an included addon and there is maxQuantity set on the top level of package price
      // we take the maxQuantity on consideration
      priceMax =
      Math.min(this.timeSlot.publicCount, this.maxQuantityPackagePriceForSecondStep)
       - this.bookedAddonQuantityOnSecondStep + this.priceCount;
    } else {
      // We take take minimum between maxBooking and publicCount if we have maxBooking
      const publicCount =  this.isMaxQuantityPerBooking ? Math.min(this.ticket.maxBooking, this.timeSlot.publicCount) :
      this.timeSlot.publicCount;
      const bookedSlot = this.isMaxQuantityPerBooking ? this.bookedCategoryQuantity : this.bookedSlotQuantity;
      priceMax = publicCount - bookedSlot + this.priceCount;
    }

    // max must not be more than max booking
    let max = priceMax > this.price.maxBooking ? this.price.maxBooking : priceMax;

    // Exception for membershiop bookings since they depend on the customers
    if (NBookingModule.membership) {
      max = this.maxMembershipPriceCount;
    }

    let count = min;

    // don't duplicate the zero
    if (count === 0) {
      count++;
    }

    while (count <= max) {
      range.push(count);
      count++;
    }

    return range;
  }

  // Returns the minimum quantity that should be selected from a package price (following a predefined formulae)
  private get packagePriceMinQuantity(): number {
    // const { number_tickets } = this.pricePackage;
    let minQuantity: number;
    // For chillion accounts the minimum of addons is scalable to the quantity
    // selected on the main ticket
    const chillionOrgID = [19066];
    if (chillionOrgID.includes(Number(OrganizerModule.id))) {
      minQuantity = this.price.minBooking * this.pricePackage.number_tickets;
    } else {
      minQuantity = this.price.minBooking;
    }
    /**
     * For now, the only case where min validation is useful for
     * addons with one price only. In all the other cases, we can't force
     * a user to select a minimum because he might have other intentions.
     *
     * So, we are disbling this now & only use minBooking as the initial value
     * on our package price dropdown
     */

    // TODO:DELETE IF NOT NECESSARY
    // // Don't recalculate min if this price has a value selected
    // if (this.onePackagePriceCount) {
    //   return Math.min(this.onePackagePriceCount, this.price.minBooking);
    // }

    // if (this.packagePricesCount !== 0) {
    //   if (this.pricePackage.min_quantity > 0) {
    //     if (!minQuantity) {
    //       minQuantity = this.pricePackage.min_quantity - this.packagePricesCount;
    //     } else {
    //       minQuantity = Math.max(
    //         this.pricePackage.min_quantity - (this.packagePricesCount /*- pckgSelectedQty*/),
    //         minQuantity,
    //       );
    //     }
    //   }
    // }

    return minQuantity;
  }

  // Returns the maximum quantity that can be selected from a package price (following a predefined formulae)
  private get packagePriceMaxQuantity(): number {
    const pckgSelectedQty: number = this.onePackagePriceCount;

    let maxQuantity = this.price.maxBooking * this.pricePackage.number_tickets;


    // maxBooking or max_quantity set to zero breaks our min formula.
    if (this.price.maxBooking > 0 && this.pricePackage.max_quantity > 0) {
      maxQuantity = Math.min(
        this.price.maxBooking * this.pricePackage.number_tickets,
        (this.pricePackage.max_quantity - (this.packagePricesCount - pckgSelectedQty)),
        this.timeSlot.publicCount,
      );
    } else {
      if (this.price.maxBooking > 0) {
        maxQuantity = Math.min(
          this.price.maxBooking * this.pricePackage.number_tickets,
          this.timeSlot.publicCount,
        );
      } else {
        maxQuantity = Math.min(
          this.price.maxBooking * this.pricePackage.number_tickets,
          (this.pricePackage.max_quantity - (this.packagePricesCount - pckgSelectedQty)),
          this.timeSlot.publicCount,
        );
      }
    }
    if (maxQuantity < pckgSelectedQty) {
      // it means user has selected from this addon the max that he can
      // lets return what he already selected from this addon as the max of the dropdown list
      // but why we need this? when can this happen?
      // _we need it in case of multiple of addon or multiple timeSlot for the same addon
      // _we might have 'X' remaining to reach the max of addons that the user can buy
      // _user has already selected 'Y' from this addon previously
      // _Y>X
      // _if we return 'X' the dropdown list will be rendered inappropriately
      // because its max will be less than its selected value 'Y'
      maxQuantity = pckgSelectedQty;
    }
    return maxQuantity;
  }

  // Returns this package price selected quantity
  private get onePackagePriceCount(): number {
    let pckgSelectedQty: number = 0;

    const bookings = NBookingModule.bookingRes;
    if (bookings) {
      const recapCategories = bookings.bookingRecap.categories;
      if (recapCategories && recapCategories.length) {
        for (const cat of recapCategories) {
          if (Number(cat.priceId) === this.price.priceId
            && Number(cat.timeSlotId) === this.timeSlot.id
            && cat.mainTicket === this.mainTimeSlotId.toString() + ':' + this.pprice.mainPriceId.toString()) {
            pckgSelectedQty = Number(cat.quantity);
            break;
          }
        }
      }
    }
    return pckgSelectedQty;
  }

  // required prices for this ticket
  get reqPrices() {
    return NBookingModule.ticketReqPrices[String(this.ticket.categoryId)];
  }

  // checks whether this is a required price
  get isRequiredPrice() {
    // TODO
    if (this.pprice) {
      return false;
    }
    return this.reqPrices.includes(this.price.priceId);
  }

  // Returns required ticket prices that aren't selected.
  get missingReqPrices() {
    const reqPrices = this.reqPrices;
    const timeSlot = this.timeSlot;

    // TODO
    if (this.pprice) {
      return [];
    }

    const nonSelectedPrices: IExportPricesEntity[] = [];
    // In case the ticket has required prices
    // make sure that they are already selected
    if (reqPrices && !reqPrices.includes(this.price.priceId)) {

      // check which required prices aren't already selected
      for (const pId of reqPrices) {
        const pr = NBookingModule.selectedPriceById(pId);
        if (!pr && timeSlot.export_prices) {
          const price = timeSlot.export_prices.find((p) => p.priceId === pId);
          if (price) {
            nonSelectedPrices.push(price);
          }
        }
      }
    }

    // this.missingReqPrices = [];
    return nonSelectedPrices;
  }

  get missingReqPricesNames(): string {
    return this.missingReqPrices.map((p) => p.priceName)
      .join(', ');
  }

  // price could be selected if it doesn't have missing req prices
  get canSelect() {
    return this.missingReqPrices.length === 0;
  }

  get accompanyingCount() {
    // DO Not Delete The Commented Part

    // const accompCount = this.isAccompConstraints(this.price.priceId, this.priceCount);
    // if (!accompCount) {
    //   return 0;
    // }
    // return Math.max(accompCount.accompCount - this.adultAccompanyingCount, 0);

    const accompConstraint = this.getAccompConstraints(this.priceCount);
    if (!accompConstraint) {
      return 0;
    }
    return accompConstraint.accompCount;
  }

   // Get the number of addults selected when we have child price with accompanying constraint
   get adultAccompanyingCount(): number {
    let count: number = 0;
    // Category id of the child price
    const categoryId = NBookingModule.recapCategories
                      .find((category) => category.priceId === this.price.priceId)?.categoryId;
    if (!categoryId) {
      return 0;
    }

    const addultPrices = NBookingModule.recapCategories.filter((category) => {
      if (category.timeSlotId === this.timeSlot.id
        && category.priceCategory === PriceCategory.Adult && category.categoryId === categoryId) {
        return category;
      }
    });

    if (addultPrices && addultPrices.length > 0) {
      addultPrices.forEach((price) => {
        count += Number(price.quantity);
      });
    }

    return count;
  }

  public addMembershipPrice(count: number) {
    this.addPrice(count, true);
  }

  // Clear this price if it's not a required price and there
  // are some missing required prices
  @Watch('missingReqPrices')
  private onMissReqPricesChange(missReqPrices: IExportPricesEntity[]) {
    if (missReqPrices.length && !this.isRequiredPrice) {
      this.addPrice(0);
    }
  }

  @Watch('validatedBookingCodes')
  private async onBookingCodesUpdated(val: string[]) {
    if (RequiresBookingCodes.includes(Number(this.price.membershipSetting))) {
      const membershipTicket = NBookingModule.recapCategories.find((cat) => cat.priceId === this.price.priceId);

      if (membershipTicket && Number(membershipTicket.quantity) > val.length) {
        // we cancel all booking codes accountings before doing a new Patch with old booking codes already applied
        const accountingIdsToRemove: number [] = [];
        const accountings = NBookingModule.listOfAllPromoApplied;
        if (accountings && accountings.length > 0) {
          val.forEach((code) => {
            const accouting = accountings.find((d) => d.promo_code_code === code);
            if (accouting) {
              accountingIdsToRemove.push(accouting.id);
            }
          });
        }
        if (accountingIdsToRemove.length > 0) {
          try {
            enableQuasarStyling();
            Loading.show();
            await NBookingModule.removePromoCode(accountingIdsToRemove);
          } catch (error) {
            Loading.hide();
            disableQuasarStyling();
            NNoty.createNewNoty({
              period: 4000,
              message: String(this.$t('error.error-response-banner')),
              type: 'error',
            });
            setTimeout(() => {
              throw error;
            }, 0);
          } finally {
            Loading.hide();
            disableQuasarStyling();
          }
        }

        this.addPrice(0, true);
      }
    }
  }

  private created() {

    // Ensure that we flag this if it's required
    const price = this.pprice;
    if (price && price.isMandatory) {
      NBookingModule.addBookingReqPrice(price);
    }
  }

  private destroyed() {

    // Remove required flag if necessary
    const price = this.pprice;
    if (price && price.isMandatory) {
      NBookingModule.remBookingReqPrice(price);
    }
  }

  private get maxMembershipPriceCount(): number {
    const customers = NBookingModule.membershipCustomers;
    const membership = NBookingModule.membership;
    // - did all customers buy before
    const didAllCustomersbook = hadAllCustomersBooked(customers);
    // - did customers buy adult or non child
    const nonChildBooked = didCustomersBookNonChild(customers, this.ticket);
    // - did customers buy child
    const childBooked = didCustomersBookChild(customers, this.ticket);
    // - is this child price
    const isChildPrice = isChildPriceCategory(this.price.priceCategory);
    // - is this non child price
    const isNonChildPrice = !isChildPrice;

    // - We can't add more if all customers have bought this show already
    if (didAllCustomersBookThisCategory(customers, this.ticket)) {
      return this.priceCount;
    }

    // remaining customers who can buy this show at this price
    const customersWhoCanBuyThisPrice =
      customers.filter((c) => this.canCustomerBuyThisCategoryPrice(c, this.price, this.ticket));

    // Max allowed is current count + remaining customers who can buy
    const max = this.priceCount + customersWhoCanBuyThisPrice.length;

    // - if we are in a family membership
    if (membership && isFamilyMembership(membership)) {
    //   - is this child price
      if (isChildPrice) {
        // Here we are checking if customers didn't buy before because
        // if they all bought before, then we have already ensured that we will
        // have a child + adult
        // Otherwise we must leave a room for an adult
        // - if customers didn't buy adult & not all customers bought before
        // reduce 1 so that we leave a spot for a non child
        if (!nonChildBooked && !didAllCustomersbook) {
          return max - 1;
        }

        return max;
      }

      // For non child prices
      // Here we are checking if customers didn't buy before because
      // if they all bought before, then we have already ensured that we will
      // have a child + adult
      // Otherwise we must leave a spot for a child
      if (isNonChildPrice) {
        // In case customers didn't buy child & not all customers have bought before
        if (!childBooked && !didAllCustomersbook) {
          // -1 so that we leave a spot for child
          return max - 1;
        }
        return max;
      }
      // TODO: if we add some code after family membership block, we need to return max
    }
    return max;
  }

  private async addPrice(count: number, isMembershipPrice: boolean = false) {
    const priceCount: IPriceCount = {
      price: this.price,
      slot: this.timeSlot,
      count: Number(count),
      ticket: this.ticket,
    };

    if (
      RequiresBookingCodes.includes(Number(this.price.membershipSetting)) &&
      !isMembershipPrice
    ) {
      // Show login page only if user is not logged in and he didn't skip that view before
      const isOrgUserLoggedIn = UserModule.isOrgUserLoggedIn;
      const hasSkippedLoginView = NBookingModule.skipLoginView;
      const hasAddedAMembershipPrice = NBookingModule.recapCategories.find((cat) =>
        RequiresBookingCodes.includes(Number(cat.membershipSetting)));
      if (!isOrgUserLoggedIn && !hasSkippedLoginView && !hasAddedAMembershipPrice && inIframe()) {
        NBookingModule.setShowLoginView(true);
      }

      NBookingModule.addNumberBookingCodePrices([priceCount]);
      if (count < NBookingModule.validatedBookingCodes.length) {
        const previousAddedCodes = NBookingModule.validatedBookingCodes;
        const numberOfCodesToDelete = NBookingModule.validatedBookingCodes.length - count;
        const codesToRemove = previousAddedCodes.splice(-numberOfCodesToDelete);
        const accountingIdsToRemove: number [] = [];
        const accountings = NBookingModule.listOfAllPromoApplied;
        if (accountings && accountings.length > 0) {
          codesToRemove.forEach((code) => {
            const accouting = accountings.find((d) => d.promo_code_code === code);
            if (accouting) {
              accountingIdsToRemove.push(accouting.id);
            }
          });
        }
        if (accountingIdsToRemove.length > 0) {
          try {
            enableQuasarStyling();
            Loading.show();
            await NBookingModule.removePromoCode(accountingIdsToRemove);
          } catch (error) {
            Loading.hide();
            disableQuasarStyling();
            NNoty.createNewNoty({
              period: 4000,
              message: String(this.$t('error.error-response-banner')),
              type: 'error',
            });
            setTimeout(() => {
              throw error;
            }, 0);
          } finally {
            Loading.hide();
            disableQuasarStyling();
          }
        }
        NBookingModule.setValidatedBookingCodes(previousAddedCodes);
        setPriceValidatedBookingCodes({priceId: this.price.priceId});
      }
      return;
    }

    if (isMembershipPrice && count > 0) {
      priceCount.codes = NBookingModule.validatedBookingCodes;
    }

    // Check if the price was already selected
    const orderRecap = NBookingModule.orderRecap;
    let priceFound = false;
    for (const ticket of orderRecap) {
      for (const price of ticket.prices) {
        if (Number(price.priceId) === this.price.priceId) {
          priceFound = true;
          break;
        }
      }
    }

    // Don't set count to zero if price wasn't already selected
    if (!Number(count) && !priceFound) {
      return;
    }

    this.isDirty = true;
    // Cancel the op if we can't select && price wasn't
    // already selected.
    if (!this.canSelect && !priceFound) {
      setTimeout(() => { (this.$refs[this.selectRef] as any).setSelected(0); }, 520);
      return;
    }

    this.isLoading = true;

    // set main price info for package prices
    const mainTimeSlotId = this.mainTimeSlotId;
    const pprice = this.pprice;
    if (pprice && mainTimeSlotId) {
      priceCount.mainTicket = `${mainTimeSlotId}:${pprice.mainPriceId}`;
      priceCount.packageCategoryId = pprice.categoryId;
      priceCount.packagePriceId = pprice.id;
    }

    try {
      // Store current price count so that we can check this for membership
      // discount after price is added;
      this.previousCount = this.priceCount;

      // Create a promise that resolves when the addPriceQ function completes
      const priceAddedPromise = new Promise((resolve, reject) => {
        // Call the function and wait for its response
        NBookingModule.addPriceQ({ priceCount, cb: this.onPriceAdded });
      });

      // Create a timeout promise that rejects after 3 seconds
      const timeoutPromise = new Promise((resolve, reject) => {
        setTimeout(() => {
          reject(new Error('Request timed out'));
        }, 3000); // 3 seconds timeout
      });

      try {
        // Use Promise.race to race the two promises: response or timeout
        await Promise.race([priceAddedPromise, timeoutPromise]);
      } catch (error) {
        // Handle timeout or other errors
        if (error.message === 'Request timed out' && this.isLoading) {
          // Show modal due to timeout
          enableQuasarStyling();
          this.openTimeoutDialog = true;
          this.progressValue = 0;
          // Simulate progress by incrementing value
          const interval = setInterval(() => {
            if (this.progressValue < 100) {
              this.progressValue += 10; // Increment progress
            } else {
              clearInterval(interval);
            }
          }, 500); // Increment every second
        }
      }

      // check if  prirce has accompanying constraints
      // if it  does we add accompanying price with according count
      // const isAccompConstraints = this.isAccompConstraints(priceCount.price.priceId, priceCount.count);
      const accompConstraints = this.getAccompConstraints(count);
      // check if accomp price was already added
      let accompPriceFound = false;
      for (const ticket of orderRecap) {
        for (const price of ticket.prices) {
          if (Number(price.priceId) === accompConstraints?.accompPriceId) {
            accompPriceFound = true;
            break;
          }
        }
      }

      // if (accompConstraints && accompPriceFound) {
      //   // await this.addAccompPrice(isAccompConstraints?.accompPriceId, 0);
      //   await this.addAccompPriceFromOtherCategory(accompConstraints.accompCount, this.price.priceId);
      // }
      if (accompConstraints) {
        // await this.addAccompPrice(isAccompConstraints.accompPriceId, isAccompConstraints.accompCount);
        await this.addAccompPriceFromOtherCategory(accompConstraints.accompCount, this.price.priceId);
      }

      // **** Do Not Delete ****

      // const addultPrice = this.getAccompanyingPirce(this.price.priceId);
      // // check if it's an addult price and we already have accompnaying price selected
      // // we substruct the adult select from the accompanying selected
      // if ( addultPrice ) {
      //   const newCount = Math.max(addultPrice.count - count, 0);
      //   await this.addAccompPriceFromOtherCategory(newCount, addultPrice.priceId);
      // }
      const addedCount = this.priceCount - this.previousCount;
      const isAdding = addedCount > 0;
      const isRemoving = addedCount < 0;

      // object that has the data we send for smeetz tracking
      // this data will be used for dynamic pricing.
      const smtzTrackingData: ISmtzTrackingData = {
        catId: this.ticket.categoryId,
        pId: this.ticket.productId,
        prId: priceCount.price.priceId,
        prVal: priceCount.price.priceValue,
        qt: isAdding ? addedCount : -1 * addedCount,
        catName: this.ticket.categoryName,
      };
      if (isAdding) {
        smeetzTracker(EventType.ticketAdded, smtzTrackingData);
        // The product is not Pre-fetched for the case of (productId == 0)
        ProductModule.fetchProducts([this.ticket.productId]).then(() => {
          bookingFlowStartTracking(
            ProductModule.product as IProduct,
            0,
            inIframe() ? 'Iframe' : 'Flow',
            smtzTrackingData,
            );
        });
      }

      if (isRemoving) {
        smeetzTracker(EventType.ticketRemoved, smtzTrackingData);
        // No need to fetch the product again it's already pre-fetched
        bookingFlowRemoveFromCart(
          ProductModule.product as IProduct,
           0,
          inIframe() ? 'Iframe' : 'Flow',
          smtzTrackingData,
          );
      }

      // clear any errors if they exist
      if (count) {
        NBookingModule.remPriceError(String(this.price.priceId));
        // clear package errors if they exist
        if (this.mainTimeSlotId) {
          NBookingModule.remPackageError(genPackageId(String(this.mainTimeSlotId), String(this.pprice.mainPriceId)));
        }
      }
      // scroll to this price if we have a package
      if (!pprice && priceCount.count && NBookingModule.pricePackage(this.price, this.timeSlot)) {
        // const priceRef = this.$refs['price-ref'];
        const priceRef = document.getElementById('price' + this.price.priceId);

        if (priceRef) {
          // Add more scroll on desktop to make price align with top of the screen
          const padding = AppModule.isMobile ? 50 : 200;
          // await Timeout.set(200);
          if (!isIos()) {
            window
              .scrollTo({
                // top: (priceRef as Element).getBoundingClientRect().y + padding,
                top: (priceRef as HTMLElement).offsetTop + padding,
                behavior: 'smooth',
              });
          }
        }
      }

      // Check if the price was already added and is included in the Team settings of the ticket
      // if So we substruct the previousCount from the total team count
      if (priceFound && this.isCountedInTeamSettings(this.ticket, this.price.priceId)) {
        this.setTeamInfo(priceFound);
      } else if (this.isCountedInTeamSettings(this.ticket, this.price.priceId)) {
        this.setTeamInfo(false);
      }



      // TODO
      // packages should add their count to main price for max_quantity validation
    } finally {
      this.isLoading = false;
    }
  }

  private async onPriceAdded(error: any, priceCount?: IPriceCount) {
    if (this.openTimeoutDialog) {
      this.openTimeoutDialog = false;
      disableQuasarStyling();
    }
    this.isLoading = false;
    if (error) {
      const response = error.response;
      const ticketCountError = response && response.data && response.data.messages
        && response.data.messages.length > 0
        && typeof response.data.messages[0] === 'string'
        && response.data.messages[0].includes('Quantity not available');
      if (response && response.status === 412 || ticketCountError) {
        NNoty.createNewNoty({
          period: 4000,
          message: String(this.$t('error.ticket-count')),
          type: 'error',
        });
        setTimeout(() => { (this.$refs[this.selectRef] as any).setSelected(0); }, 300);
        this.$emit(EVSlotPrice.CountNotPresent, this.timeSlot.id);
      } else {
        // We use the below try/catch block just to see why
        // messages[0].includes() is throwing 'not a function' in Bugsnag
        try {
          // if the below throw an error, we'll not use it, we'll throw mesages[0] type so that we can use that info
          // if it is of type string; the booking flow will show the wanted error msgs to the user
          // if it is not we'll use the output of this code in Bugsnag for a better solution
          const justTest = response.data.messages[0].includes('No free stock for stockCategory ');
          if (response && response.status === 400
            && response.data
            && response.data.messages
            && response.data.messages.length > 0
            && typeof response.data.messages[0] === 'string'
            && response.data.messages[0].includes('No free stock for stockCategory ')
            && response.data.messages[0].includes(' for this booking')) {
            NNoty.createNewNoty({
              period: 4000,
              message: String(this.$t('error.ticket-no-more-count')),
              type: 'error',
            });
            this.$emit(EVSlotPrice.CountNotPresent, this.timeSlot.id);
          } else {
            NNoty.createNewNoty({
              period: 4000,
              message: String(this.$t('error.error-response-banner')),
              type: 'error',
            });
          }
        } catch (error) {
          // this means messages[0].includes() is not a function
          // we'll show the default response error msg to the user
          // and log the type of messages[0] to Bugsnag
          NNoty.createNewNoty({
            period: 4000,
            message: String(this.$t('error.error-response-banner')),
            type: 'error',
          });
          throw new Error('original error of catch block: ' + error
            + 'and we have typeof messages[0] is ' + typeof typeof response.data.messages[0]);
        }

      }

      throw error;
    }

    // Update memberships whether we are adding or reducing
    if (priceCount && NBookingModule.membership) {
      const addedCount = this.priceCount - this.previousCount;
      const isAdding = addedCount > 0;
      if (isAdding) {
        this.addToMembership(priceCount, addedCount);
        return;
      }
      const membership = NBookingModule.membership;
      if (membership && membership.type === MembershipType.RequiredTicketsMembership) {
        enableQuasarStyling();
        Loading.show();
        try {
          await removeCustomersTickets();
        } catch (error) {
          setTimeout(() => {
            throw error;
          }, 0);
        } finally {
          Loading.hide();
          disableQuasarStyling();
        }
      } else {
        // Since reduce count is negative, let's make it positive
        const reducedCount = addedCount * -1;
        this.removeMembershipCustomerTicket(priceCount, reducedCount);
      }
    }
  }

  private setTeamInfo( previouslyAdded: boolean)  {


    // we Check if we have the teamSetting in the ticket information
    // Or we check if we have them in the package price (addons)
    let teamSetting: ITeamSetting | null = null;
    const teamSettingFromPricePackage: ITeamSetting | null = this.getTeamsSettingsFromPricePackage();
    if (this.ticket.teamSettings && this.ticket.teamSettings[0]) {
      teamSetting = this.ticket && this.ticket.teamSettings && this.ticket.teamSettings[0];
    } else if (teamSettingFromPricePackage) {
      teamSetting = teamSettingFromPricePackage;
    }

    const countedPsp = teamSetting?.countedPsp;
    let count = 0;
    // We check if we already have a team count so we add to it the count of other prices
    // from the same category or if the previous count is modified
    const teamCount = NBookingModule.teamInfoPerCategory
                                      .find((info) => info.categoryId === this.ticket.categoryId)?.teamCount;

    if (teamCount  && previouslyAdded && this.previousCount) {
      // If the price is previously added and we update the select we remove the prevoiusCount
      count += Number(teamCount) - this.previousCount + this.priceCount;
    } else if (teamCount) {
      // If another price is selected under the same category we add the existing team Count
      count += Number(teamCount) + this.priceCount;
    } else {
      count += Number(this.priceCount);
    }

    const teamInfo: IteamInfo = {
      teamCount: count,
      categoryId: this.ticket.categoryId,
      maxParticipants: teamSetting?.maxParticipants as number,
    };
    NBookingModule.setTeamInfo(teamInfo);
  }

  private isCountedInTeamSettings(ticket: ITicket, priceId: number) {
    const teamSetting = ticket && ticket.teamSettings && ticket.teamSettings[0];
    const countedPsp = teamSetting?.countedPsp;
    // check if the price Id is included in the team settings
    if (countedPsp && countedPsp.includes(String(priceId))) {
      return true;
    }
    // check if the PPrice is included in the team settings;
    const teamSettingsForAddons = this.getTeamsSettingsFromPricePackage();
    if (teamSettingsForAddons) {
      const countedPspForAddons = teamSettingsForAddons.countedPsp;
      if (countedPspForAddons && countedPspForAddons.includes(String(this.price.priceId))) {
        return true;
      }
    }

    return false;
  }
  // Retrieve team settings information from price package
  private getTeamsSettingsFromPricePackage() {
    if (this.pricePackage && this.pprice) {
      const packagePriceCategory = this.pricePackage.package_categories[this.pprice.categoryId];
      const teamSettings = packagePriceCategory.team_settings
       && packagePriceCategory.team_settings[0];
      if (teamSettings) {
        return teamSettings;
      }
      return null;
    }
    return null;
  }

  // private isAccompConstraints(priceId: number, count: number) {

  //   // enable this only for addons now
  //   if (!this.isPackagePrice) {
  //     return;
  //   }
  //   const validAccompCount = [7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
  //   let accompCount: number;

  //   if (!validAccompCount.includes(count)) {
  //     accompCount = 0 ;
  //   } else {
  //     accompCount = this.accompMapping.countMapping[count as ValidAccompCount];
  //   }
  //   for (const key in this.accompMapping.priceIds) {
  //     if (this.accompMapping.priceIds[key].childPriceId === priceId) {
  //       return {
  //         accompPriceId: this.accompMapping.priceIds[key].accompPriceId,
  //         accompCount,
  //       };
  //     }
  //   }
  //   return;
  // }

  private async addAccompPrice(priceId: number, count: number) {
    const prices = this.timeSlot.export_prices?.filter((price) => price.priceId === priceId);
    if (prices && prices[0]) {
      const priceCount: IPriceCount = {
        price: prices[0],
        slot: this.timeSlot,
        ticket: this.ticket,
        count,
      };
      await NBookingModule.addPriceQ({ priceCount, cb: this.onPriceAdded });
    }
  }

  // Adds accomp price from another category
  private async addAccompPriceFromOtherCategory(count: number, priceId: number) {
    // Get accomp price info
    const accompMapping = this.accompMapping;
    const accompInfo =
      (this.accompMapping.accompCategories as any)[priceId] as typeof accompMapping.accompCategories[23132];
    // Get nearest timeslot
    const productId = accompInfo.productId;
    const categoryId = accompInfo.accompCategoryId;
    const from = dayjs(this.timeSlot.startDateTime).format('YYYY-MM-DD');
    const to = dayjs(from).add(1, 'day').format('YYYY-MM-DD');
    try {
      const slots = await fetchTicketSlots({productId, categoryId, from, to});
      const closesSlot = nearestFutureSlot(this.timeSlot.startDateTime, slots);

      if (!closesSlot) {
        throw new Error('No closest slot found');
      }

      // Submit Accompanying info
      const accompPrice = closesSlot.export_prices?.find((price) => price.priceId === accompInfo.accompPriceId);
      if (!accompPrice || !accompPrice.priceId) {
        throw new Error('Accomp price was not found');
      }
      await NBookingModule.addPriceLite({
        timeSlotId: closesSlot.id,
        productId,
        ticketId: categoryId,
        priceId: accompPrice?.priceId,
        priceValue: accompPrice.priceValue,
        count,
      });

    } catch (error) {
      LoggerService.info('Unable to getting accompanying price ', {
        params: {
        productId,
        ticketId: categoryId,
        accompInfo,
        count,
      }});
      throw error;
    }
  }

  // check if the price is addult and have child price with accompanying constraint
  // In the same category, we return the child price and the count from accompMapping
  // private getAccompanyingPirce(priceId: number) {
  //   const addultPrice = NBookingModule.recapCategories.find((category) =>
  //   category.priceId === priceId);

  //   if (!addultPrice) {
  //     return null;
  //   }

  //   if (addultPrice.priceCategory !== PriceCategory.Adult) {
  //     return null;
  //   }

  //   const childPrices = NBookingModule.recapCategories.filter((category) =>
  //     category.priceCategory ===  PriceCategory.Child && category.categoryId === addultPrice.categoryId
  //     && category.timeSlotId === addultPrice.timeSlotId);

  //   if (childPrices && childPrices.length > 0) {
  //     for (const price of childPrices) {
  //       const accompPrice = this.isAccompConstraints(price.priceId, Number(price.quantity));
  //       if (accompPrice) {
  //         return {
  //          count: accompPrice.accompCount,
  //          priceId: price.priceId,
  //         };
  //       }
  //     }
  //   }

  //   return null;
  // }

  private getAccompConstraints(count: number) {
    count = Number(count);
    // enable this only for addons now
    if (!this.isPackagePrice) {
      return;
    }
    let accompCount: number = 0;
    const addultPrice = this.accompMapping.priceIds.find((price) => price.addultPriceId === this.price.priceId);
    // if no accomp price was already added we add one accompnaying for the addult price
    const orderRecap = NBookingModule.orderRecap;
    let accompPriceFound = false;
    for (const ticket of orderRecap) {
      for (const price of ticket.prices) {
        if (Number(price.priceId) === addultPrice?.accompPriceId) {
          accompPriceFound = true;
          break;
        }
      }
    }

    if (addultPrice && count === 0) {
      return {
        accompPriceId: addultPrice.accompPriceId,
        accompCount: 0,
      };
    }
    let childPriceAlreadyAdded = false;
    for (const ticket of orderRecap) {
      for (const price of ticket.prices) {
        if (Number(price.priceId) === addultPrice?.childPriceId) {
          childPriceAlreadyAdded = true;
          break;
        }
      }
    }

    if (addultPrice && !childPriceAlreadyAdded && count !== 0) {
      return {
        accompPriceId: addultPrice.accompPriceId,
        accompCount: 1,
        };
    }

    const childPrice = this.accompMapping.priceIds.find((price) => price.childPriceId === this.price.priceId);
    if (!childPrice) {
      return null;
    }

    const mainTicket = NBookingModule.recapCategories.find((cat) => cat.categoryId === this.ticket.categoryId);
    const totalBooked = mainTicket && Number(mainTicket.quantity);
    if (totalBooked) {
      if (
        (totalBooked <= 10 && count >= 2) ||
        (totalBooked > 10 && totalBooked <= 15 && count === 2) ||
        (totalBooked > 15 && totalBooked <= 20 && count === 2)
      ) {
        accompCount = 2;
      } else if (
        (totalBooked > 10 && totalBooked <= 15 && count >= 3) ||
        (totalBooked > 15 && totalBooked <= 20 && count === 3)
      ) {
        accompCount = 3;
      } else if (totalBooked > 15 && totalBooked <= 20 && count >= 4) {
        accompCount = 4;
      } else if (count === 1) {
        accompCount = 1;
      }
    }


    return {
      accompPriceId: childPrice.accompPriceId,
      accompCount,
    };
  }
}

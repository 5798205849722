import { client } from '@/utils/graphqlClient/graphql-client';
import {
  GetBookingTicketsQuery,
  GetBookingTicketsQueryVariables,
  GetProductsTicketsQuery,
  GetProductsTicketsQueryVariables,
  GetTicketGroupMembershipsQuery,
  GetTicketGroupMembershipsQueryVariables,
} from '@/types/gql/generated/graphql';
import { GET_BookingTickets, GET_ProductsTickets, GET_TicketGroupMemberships } from './gql/ticket.graphql';
import { ProductTicket } from '@/types/gql/product';
import { BookingPSS, MembershipDiscountMembership } from '@/types/gql/ticket';

/**
 * Returns a sales collection's memberships
 *
 * @param id ticket group id
 */
export async function getTicketGroupMemberships(ticketGroupId: number) {
  // transform ids to array of strings
  const res = await client.query<GetTicketGroupMembershipsQuery, GetTicketGroupMembershipsQueryVariables>(
    GET_TicketGroupMemberships,
    { id: String(ticketGroupId) }).toPromise();

  if (!res.data?.tickets || !res.data?.tickets.length) {
    throw new Error('No linked tickets');
  }

  return res.data.tickets.map((ticket) => ticket?.membership) as MembershipDiscountMembership[];
}

export async function getBookingTickets(data: {bookingId: number, goerToken: string}) {
  const res =
  await client.query<GetBookingTicketsQuery, GetBookingTicketsQueryVariables>(
    GET_BookingTickets,
    {bookingId: String(data.bookingId), bookingToken: data.goerToken},
  ).toPromise();
  if (!res.data?.ProductSlotBookingList || !res.data.ProductSlotBookingList.length) {
    throw new Error('No booking tickets');
  }
  return res.data.ProductSlotBookingList[0]?.pssList as BookingPSS[] || [];
}

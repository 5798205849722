


















































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Mixins } from 'vue-mixin-decorator';
import DateHelper from '@/mixins/DateHelper';
import { IDateData } from '@/models/product';
import { SITE_URL } from '@/config/constants';

@Component({})
export default class ProductDates extends Mixins<DateHelper>(DateHelper) {
  @Prop({default: null}) private dateData!: IDateData | null;

  private openLocation(LatLng: any) {
    window.open(`https://www.google.com/maps/search/${LatLng.lat},${LatLng.lng}`, '_blank');
  }

  private goToOrganizer(cardData: any) {
    if ( this.dateData) {
      window.open(`${SITE_URL}/org/${this.dateData.organiserSlug}`, '_blank');
    }
  }

  get startDate() {
    if (!this.dateData) {
      return '';
    }
    return this.currentDate(this.dateData.start);
  }

  get endDate() {
    if (!this.dateData) {
      return '';
    }
    return this.currentDate(this.dateData.end);
  }
}

























































































import { Vue, Component, Mixins } from 'vue-property-decorator';
import RetailMixin from '@/mixins/Retail';
import Select from '@/components/presentational/NewSelect.vue';
import { EVSelect } from '@/models/events';
import { NModalModule } from '@/utils/storemodules';
import { ModalStatus } from '@/models/definitions';

@Component({
  components: {
    Select,
  },
})
export default class Retail  extends Mixins<RetailMixin>(RetailMixin) {
  private EVSelect = EVSelect;

   private openBookingDescriptionModal() {
    if (this.isLongRetailDescription) {
      NModalModule.setStatus(ModalStatus.Description);
      NModalModule.setPopupTitle(String(this.retailName));
      NModalModule.setPopupDescription(String(this.retailDescription));
    }
  }

}






















































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { Mixins } from 'vue-mixin-decorator';
import UserSpaceCard from './UserSpaceCard.vue';
import {
    AppModule,
    AuthModule,
    ModalModule,
    OrganizerModule,
    ProductsModule,
    UserModule,
} from '@/utils/storemodules';
import {
    ICondition,
    IUserBookings,
    IUserBooking,
    IBookingProduct,
} from '@/models/store/user';
import {
    getUserBookings,
    gql_countUserBookings,
    gql_getUserBookings,
    gql_getUserBookingsThruPSS,
} from '@/api/user';
import dayjs from 'dayjs';
import { IActivity, IUserActivity } from '@/models/store/region';
import Card from '@/components/presentational/BookingCard.vue';
import { dateSortCompArraysGen } from '@/utils/helpers';
import DateHelperMixin from '@/mixins/DateHelper';
import Timeout from 'await-timeout/dist/es5';
import { setWidgetHeight } from '@/utils/iframe';
import { IGQLProductSlotState } from '@/models/store/booking';

@Component({
    components: {
        UserSpaceCard,
        Card,
    },
})
export default class UserBooking extends Mixins<DateHelperMixin>(
    DateHelperMixin,
) {
    @Prop({ default: 'upcoming' }) public type!: string;
    private isPagination: boolean = false;
    private pageNumber: number = this.currentPage;
    private rowsPerPage: number = 5;
    private pagesCount: number = 0;
    private query: ICondition = {
        page: this.currentPage,
        perPage: this.rowsPerPage,
        filterState: '3,5',
        order: 'productSlotState',
        sort: 'asc',
        from: dayjs().format('YYYY-MM-DD'),
    };
    private bookings: IUserBookings[] = [];
    private isLoading = false;

    @AuthModule.Getter('getPhones') private phones: any;
    @AuthModule.Getter('getEmails') private emails: any;

    private get isMobile() {
        return AppModule.isMobile;
    }

    private get userbookings(): IUserBooking[] {
        const userbookings: IUserBooking[] = [];
        for (const bookings of this.bookings) {
            if (bookings.bookings) {
                userbookings.push(...bookings.bookings);
            }
        }

        return userbookings;
    }

    private get customAuthEmail() {
        return UserModule.getCustomAuthEmail;
    }

    private get customAuthToken() {
        return UserModule.getCustomAuthToken;
    }

    private get gqlUserBookings() {
        if (this.isUpcoming) {
            return ProductsModule.userUpcomingBookings;
        } else { return ProductsModule.userPastBookings; }
    }

    private get productIds(): string[] {
        const ids: string[] = [];

        for (const booking of this.userbookings) {
            if (!booking.productSlotStates) {
                continue;
            }
            for (const pss of booking.productSlotStates) {
                ids.push(String(pss.category?.product.id));
            }
        }

        return ids;
    }

    private get bookingProducts() {
        const activities = ProductsModule.products;
        const products: Array<{ booking: IUserBooking; product: IActivity }> = [];
        for (const booking of this.userbookings) {
            if (booking.productSlotStates && booking.productSlotStates.length > 0) {
                const productsIds = booking.productSlotStates.map(
                    (pss) => pss.category?.product.id,
                );
                const activity = activities.find((act) =>
                    productsIds.includes(Number(act.id)),
                );
                if (activity) {
                    products.push({ product: activity, booking });
                }
            }
        }
        return products;
    }

    /**
     * Returns a 2 dimensions array of bookings grouped by their startDates
     * It is an array of arrays
     * Each array includes bookings of the same startDate
     * The source of those user bookings is Smeetz APIs
     */
    private get bookingProductsGroupedByDate(): Array<
        Array<{
            startDate: string | Date[];
            bookingObj: { booking: IUserBooking; product: IActivity };
        }>
    > {
        const bookings = this.bookingProducts;

        const products: Array<{
            startDate: string | Date[];
            bookingObj: { booking: IUserBooking; product: IActivity };
        }> = [];

        for (const b of bookings) {
            products.push({
                startDate: this.getBookingDate(b),
                bookingObj: { booking: b.booking, product: b.product },
            });
        }

        const groupByUnits = {
            m: 'YYYY-MM',
            d: 'YYYY-MM-DD',
            w: 'YYYY-MM--w',
        };

        const groupedProducts = this.groupObjectsByUnit(
            products,
            'startDate',
            groupByUnits.d,
        );
        return groupedProducts;
    }

    /**
     * Returns a 2 dimensions array of bookings grouped by their startDates
     * It is an array of arrays
     * Each array includes bookings of the same startDate
     * The source of those user bookings is GraphQL APIs
     */
    private get gqlUserBookingsGroupedByDate(): Array<
        Array<{ startDate: string; bookingObj: IUserActivity }>
    > {
        const bookings = this.gqlUserBookings;
        const bookingsToBeGrouped: Array<{
            startDate: string;
            bookingObj: IUserActivity;
        }> = [];

        for (const b of bookings) {
            bookingsToBeGrouped.push({ startDate: b.startDateTime, bookingObj: b });
        }

        const groupByUnits = {
            m: 'YYYY-MM',
            d: 'YYYY-MM-DD',
            w: 'YYYY-MM--w',
        };
        const groupedBookings = this.groupObjectsByUnit(
            bookingsToBeGrouped,
            'startDate',
            groupByUnits.d,
        );
        return groupedBookings;
    }

    /**
     * @param dateUnit: date format that is used to group similar dates
     */
    private groupObjectsByUnit<T>(
        data: T[],
        field: string,
        dateUnit: string,
    ): T[][] {
        const dateObj: { [s: string]: T[] } = {};

        // iterate over data
        for (const d of data) {
            const f = (d as any)[field];
            if (!f) {
                continue;
            }

            // get grouping unit of data unit
            const groupingUnit = dayjs(f as any).format(dateUnit);
            // get the array of that grouping
            let group = dateObj[groupingUnit];
            // create it if it doesn't exist
            if (!group) {
                dateObj[groupingUnit] = [];
                group = dateObj[groupingUnit];
            }

            // add the data unit
            group.push(d);
        }

        const groups: T[][] = [];
        // create an array of data units array
        for (const key in dateObj) {
            if (key) {
                groups.push(dateObj[key]);
            }
        }

        // sort the array using first field of each array;
        return groups.sort(dateSortCompArraysGen('dates'));
    }

    private getBookingDate(booking: {
        booking: IUserBooking;
        product: IActivity;
    }) {
        const productSlotState =
            booking.booking && booking.booking.productSlotStates;
        if (productSlotState && productSlotState.length) {
            return productSlotState[0].startDateTime;
        }

        return booking.product.dates;
    }

    private get isUpcoming(): boolean {
        return this.type === 'upcoming';
    }

    private get hasBookings(): boolean {
        return !!this.gqlUserBookings.length;
    }

    private get isOrganizerUserSpace() {
        return OrganizerModule.isOrganizerUserSpace;
    }

    private get currentPage() {
        return UserModule.userBookingsCurrentPage;
    }

    private async created() {
        // lets set a currentPage number if there isn't one
        if (
            !UserModule.userBookingsCurrentPage ||
            UserModule.userBookingsCurrentPage === 0
        ) {
            UserModule.setCurrentPage(1);
        }

        this.query = {
            page: this.currentPage,
            perPage: this.rowsPerPage,
            filterState: '3,5',
            order: 'productSlotState',
            sort: 'asc',
            from: dayjs().format('YYYY-MM-DD'),
        };

        if (OrganizerModule.isOrganizerUserSpace && OrganizerModule.id) {
            this.query.orgId = OrganizerModule.id;
        }

        this.pageNumber = this.currentPage;
        const query = this.query;
        try {
            this.isLoading = true;
            const organizerId = OrganizerModule.orgId;
            const userEmails = OrganizerModule.isOrganizerUserSpace
                ? [this.customAuthEmail]
                : this.emails
                    ? this.emails.map((e: any) => e.emailAddress)
                    : [];
            const gqlBookings = await gql_getUserBookingsThruPSS(
                this.customAuthToken,
                userEmails,
                organizerId,
                query.page,
                query.perPage,
                this.isUpcoming,
            );

            // We shouldn't do a whole api call to only count bookings
            // But when we put a count query with the guery that got
            // user bookings GQL claimed about a column name not existing
            const gqlBookingsCount = await gql_countUserBookings(
                this.customAuthToken,
                userEmails,
                organizerId,
                this.isUpcoming,
            );

            const bookings = this.convertToUserBookings(gqlBookings);
            if (this.isUpcoming) {
                ProductsModule.setUserUpcomingBookings(bookings);
            } else {
                ProductsModule.setUserPastBookings(bookings);
            }
            this.pagesCount = Math.ceil(
                gqlBookingsCount / this.rowsPerPage,
            );
            this.isPagination =
                gqlBookingsCount > this.rowsPerPage ? true : false;

            if (!bookings) {
                // User has no bookings
                return;
            }



        } catch (err) {
            throw err;
        } finally {
            if (this.isOrganizerUserSpace) {
                setTimeout(() => setWidgetHeight('smtz-p-widget'), 500);
            }
            this.isLoading = false;
        }
    }

    private async goToPage(page: number) {
        UserModule.setCurrentPage(page);
        this.query.page = this.currentPage;
        const query = this.query;
        try {
            this.isLoading = true;
            const organizerId = OrganizerModule.orgId;
            const userEmails = OrganizerModule.isOrganizerUserSpace
                ? [this.customAuthEmail]
                : this.emails.map((e: any) => e.emailAddress);
            const gqlBookings = await gql_getUserBookingsThruPSS(
                this.customAuthToken,
                userEmails,
                organizerId,
                query.page,
                query.perPage,
                this.isUpcoming,
            );
            if (!gqlBookings) {
                // User has no bookings
                return;
            }
            if (this.isUpcoming) {
                ProductsModule.setUserUpcomingBookings(this.convertToUserBookings(gqlBookings));
            } else {
                ProductsModule.setUserPastBookings(this.convertToUserBookings(gqlBookings));
            }
        } catch (err) {
            throw err;
        } finally {
            setTimeout(() => setWidgetHeight('smtz-p-widget'), 500);
            this.isLoading = false;
        }
    }

    private beforeDestroy() {
        ProductsModule.setOptions({
            smeetzChannel: [1],
        });
    }

    private convertToUserBookings(gqlBookings: any): IUserActivity[] {
        if (!gqlBookings.ProductSlotBookingList) {
            return [];
        }
        const userBookings: IUserActivity[] = [];
        for (const b of gqlBookings.ProductSlotBookingList) {
            const sortedDates =
            b.ProductSlotStateList
            .sort((t1: any, t2: any) => {
                const val = dayjs(t1.StartDateTime).isAfter(dayjs(t2.StartDateTime)) ? 1 : -1;
                return val;
                }).map((item: IGQLProductSlotState) => item.StartDateTime);
            const nearestDate = this.isUpcoming ? sortedDates.find((date: string) =>
                        dayjs(date).isAfter(dayjs().format('YYYY-MM-DD'))) : sortedDates[0];
            const ub: IUserActivity = {
                bookingId: b.Id,
                startDateTime: nearestDate,
                coverPicture: b.ProductSlotStateList[0].ProductSlotCategoryRead.ProductRead.ProductPictureList.find(
                    (p: any) => p.PictureRead.IsPrimary,
                ).PictureRead.RealUrl,
                city:
                    b.ProductSlotStateList[0].ProductSlotCategoryRead.ProductRead
                        .LocationRead.City,
                country:
                    b.ProductSlotStateList[0].ProductSlotCategoryRead.ProductRead
                        .LocationRead.Country,
                productName:
                    b.ProductSlotStateList[0].ProductSlotCategoryRead.ProductRead.Name,
            };
            userBookings.push(ub);
        }
        return userBookings.sort(
            (a, b) =>
                new Date(a.startDateTime).getTime() -
                new Date(b.startDateTime).getTime(),
        );
    }

}

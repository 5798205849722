












import { Vue, Component, Watch } from 'vue-property-decorator';
import { NBookingModule, NModalModule, AppModule } from '@/utils/storemodules';
import { ModalStatus } from '@/models/definitions';

@Component
export default class BookingTimer extends Vue {
  private time = 0;
  private timer: number | null = null;
  private showDescription: boolean = false;
  private seconds: number | string = 0;
  private minutes: number | string = 0;

  private timeout = setTimeout(() => {
    this.showDescription = false;
  }, 10000);

  private get timeGetter() {
    return NBookingModule.timer;
  }

  private get isMobile() {
    return AppModule.isMobile;
  }

private get isTablet() {
  if (AppModule.isTablet && window.innerWidth < 968 || AppModule.isMobile) {
    return true;
  }
}

  private getTimeRemaining(endtime: Date) {
    const timer = (Date.parse(endtime.toString()) - Date.parse(new Date().toString())) / 1000;
    const minutes = Math.floor(timer / 60);
    const seconds = Math.floor( (timer) % 60 );
    return {
      minutes: minutes < 10 ? '0' + minutes : minutes,
      seconds: seconds < 10 ? '0' + seconds : seconds,
      total: timer,
    };
  }

  private initializeClock(endtime: Date) {
    const timeinterval = setInterval(() => {
      const t = this.getTimeRemaining(endtime);
      this.time = t.total;
      this.seconds = t.seconds;
      this.minutes = t.minutes;
      if (  t.total <= 0 ) {
        clearInterval(timeinterval);
        NBookingModule.stopTimer();
        NModalModule.setStatus(ModalStatus.bookingTimeEnd);
      }
    }, 1000);
  }

  @Watch('timeGetter')
  private onTimeSet(val: any) {
    if ( val ) {
      const deadline = new Date(Date.parse(new Date().toString()) + val * 1000);
      this.initializeClock(deadline);
    } else {
      this.time = 0;
    }
  }
  // Trigger description only if we are not on cart or not on mobile or not on tablet.
  private triggerDescription() {
    if (!this.isTablet) {
      this.showDescription = true;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.showDescription = false;
      }, 10000);
    }
  }
}


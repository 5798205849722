



























































































import { Vue, Component, Prop } from 'vue-property-decorator';
import ModalButton from '@/components/modal/ModalButton.vue';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import { Validator } from '@/utils/validators';
import { AuthModule, ModalModule } from '@/utils/storemodules';
import { ISocialData } from '@/models/store/auth';
import { ModalStatus } from '@/models/definitions';

@Component({
  components: {
    ModalButton,
  },
  validations: {
    swisspassCardId: {
      required,
      minLength: minLength(15),
      maxLength: maxLength(15),
      invalid: Validator.numbersAndHyphenAndUpper,
    },
    zip: {
      required,
      minLength: minLength(4),
      maxLength: maxLength(4),
      invalid: Validator.hasOnlyNumbers,
    },
  },
})
export default class SwissPass extends Vue {
  @Prop({ default: 'en' })
  public languageName!: string;

  private checked: boolean = false;
  private swisspassCardId: string = '';
  private zip: string = '';
  private responseError: string = '';

  @ModalModule.Getter('getAuthType') private authType!: string;
  @ModalModule.Mutation('setStatus') private setStatus: any;
  @ModalModule.Mutation('setAuthType') private setAuthType: any;
  @AuthModule.Action('swisspassLogin') private login: any;

  get enableSubmit() {
    return !this.$v.$error && this.swisspassCardId && this.checked;
  }

  private beforeDestroy() {
    this.setAuthType('');
  }

  private changeSwisspassValue() {
    this.$v.swisspassCardId.$model = this.changeValue(this.$v.swisspassCardId.$model);
  }

  private changeValue(value: any) {
    if (value) {
      value = value.split('-').join('');
      if (value.length > 0) {
        return value.match(new RegExp('.{1,3}', 'g')).join('-');
      }
    }
  }

  private async submit() {
    const swisspassCardId = this.swisspassCardId.split('-').join('');
    const swisspassZip = this.zip;
    const data: ISocialData = { swisspassCardId, swisspassZip };

    try {
      await this.login(data);
      this.setStatus(ModalStatus.Hidden);
      this.setAuthType('');
      this.$router.push('/');
    } catch (err) {
      const { response } = err;
      this.responseError = String(response.status);
    }
  }
}


class FormValidator {
  /**
   * Checks whether value contains 1 or more alpha characters
   */
  public hasLetters(value: string) {
    return false;
  }
  /**
   * Checks whether value contains some numbers
   */
  public hasNumbers(value: string) {
    return /(?=.*?[0-9])/.test(value);
  }

  /**
   * Checks whether value contains only numbers
   */
  public hasOnlyNumbers(value: string) {
    return /^[0-9]*$/.test(value);
  }

  /**
   * Checks whether phone is in an international format
   */
  public isInterPhone(value: string) {
    return /^\+[0-9]*$/.test(value);
  }

  /**
   * Checks whether word has upper and lower case characters
   */
  public hasUpperAndLower(value: string) {
    return /(?=.*?[A-Z])(?=.*?[a-z])/.test(value);
  }

  /**
   * Checks wthere value has one of the following characters
   * &$#.
   */
  public hasSpecialCharacter(value: string) {
    return /(?=.*?[#?!@$%^&*-])/.test(value);
  }

  /**
   * Checks whether name is a valid first or last name
   */
  public validName(name: string) {
    return /^[a-zàáâäãåçéèêëîìíïôóòõöøúûùüÿýßñæœðšþžA-ZÀÁÂÄÃÅÆÇÉÈÊËÎÌÍÏÔÓÒÕÖØŒÙÚÛÜÝŸẞÑÐŠÞŽ\s-]{2,45}$/.test(name);
  }

  /**
   * Checks whether value has 8 characters or not
   */
  public hasEightChars(value: string) {
    return /^.{8,}$/.test(value);
  }

  /**
   * Checks whether value has numbers, hyphen and upper case characters
   */
  public numbersAndHyphenAndUpper(value: string) {
    return /^[A-Z-0-9]+( [A-Z-0-9]+)*$/.test(value);
  }

  /**
   * Checks whether value has numbers, upper and lower case characters, special characters
   */
  public numbersLettersSpecialCharacters(value: string) {
    const myRegExp = new RegExp(['^[A-ZÀÁÂÄÃÅÆÇÉÈÊËÎÌÍÏÔÓÒÕÖØŒÙÚÛÜÝŸẞÑÐŠÞŽa-zàáâäãåçéèêëîìíïôóòõöøúûùüÿýßñæœðšþž',
      '0-9\\\\!?/\'`\\-\\[\\]^",.;:£$%&*()}{@#~><>|=_+]+(',
      '[A-ZÀÁÂÄÃÅÆÇÉÈÊËÎÌÍÏÔÓÒÕÖØŒÙÚÛÜÝŸẞÑÐŠÞŽa-zàáâäãåçéèêëîìíïôóòõöøúûùüÿýßñæœðšþž',
      '0-9\\\\!?/\'`\\-\\[\\]^",.;:£$%&*()}{@#~><>|=_+]+)*$'].join(''));
    return myRegExp.test(value);
  }

  /**
   * City regex check
   */
  public validateCity(value: string) {
    // tslint:disable-next-line:max-line-length
    const cityRegex = /^([a-zàáâäãåçéèêëîìíïôóòõöøúûùüÿýßñæœðšþžA-ZÀÁÂÄÃÅÆÇÉÈÊËÎÌÍÏÔÓÒÕÖØŒÙÚÛÜÝŸẞÑÐŠÞŽ\u0080-\u024F]+(?:. |-| |'|`|’))*[a-zàáâäãåçéèêëîìíïôóòõöøúûùüÿýßñæœðšþžA-ZÀÁÂÄÃÅÆÇÉÈÊËÎÌÍÏÔÓÒÕÖØŒÙÚÛÜÝŸẞÑÐŠÞŽ\u0080-\u024F]*$/;

    return cityRegex.test(value);
  }

  /**
   * Checks whether values array is empty or not
   * TODO: check for a BUG
   */
  public isArrayEmpty(values: string[]) {
    return values.length > 0;
  }

  /**
   * Checks whther url is a valid url
   */
  public isValidURL(url: string) {
    const urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    return urlRegex.test(url);
  }

  /**
   * Checks whether r not an email is a valid email
   */
  public isValidEmail(email: string) {
    const emailPattern = /^[a-zA-Z0-9]+([!#$%&'*+-/=?_`{|}~]?[a-zA-Z0-9]+)*@[a-zA-Z0-9]+([\.-]?[a-zA-Z0-9]+)*(\.[a-zA-Z0-9]{2,20})+$/;
    return emailPattern.test(email);
  }
}

export const Validator = new FormValidator();

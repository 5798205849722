































import { Component, Vue, Prop } from 'vue-property-decorator';
import { ITitleData } from '@/models/product';

@Component({})
export default class ProductTitle extends Vue {
  @Prop({default: null}) private titleData!: ITitleData | null;
}

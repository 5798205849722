import Vue from 'vue';

// import './styles/quasar.scss';
import 'quasar/dist/quasar.ie.polyfills';
// import '@quasar/extras/material-icons/material-icons.css';
import {
  Dialog,
  Quasar,
  QLayout,
  QHeader,
  QDrawer,
  QDialog,
  QLinearProgress,
  QPageContainer,
  QPage,
  QToolbar,
  QToolbarTitle,
  // QBtn,
  QBadge,
  QCard,
  QCardActions,
  QCardSection,
  QChip,
  QIcon,
  QList,
  QInnerLoading,
  QItem,
  QItemSection,
  QItemLabel,
  Loading,
  QSpinner,
  ClosePopup,
  QOptionGroup,
  QTooltip,
  QBar,
  QForm,
  QInput,
  QRadio,
  QSpace,
  QSeparator,
  QStep,
  QSelect,
  QStepper,
  QStepperNavigation,
  QToggle,
  Notify,
  QPagination,
  QBtn,
  QDate,
  QPopupProxy,
  QField,
  QCheckbox,
  QFile,
  QBanner,
  QSlideTransition,
  QSkeleton,
} from 'quasar';

Vue.use(Quasar, {
  config: {},
  components: {
    QBanner,
    QLayout,
    QHeader,
    QDrawer,
    QDialog,
    QLinearProgress,
    QPageContainer,
    QPage,
    QBadge,
    QCard,
    QCardSection,
    QCardActions,
    QChip,
    QToolbar,
    QToolbarTitle,
    QIcon,
    QList,
    QInnerLoading,
    QItem,
    QItemSection,
    QItemLabel,
    QSpinner,
    QOptionGroup,
    QTooltip,
    QBar,
    QForm,
    QInput,
    QRadio,
    QSpace,
    QSeparator,
    QStep,
    QSelect,
    QStepper,
    QStepperNavigation,
    QToggle,
    QPagination,
    QBtn,
    QDate,
    QPopupProxy,
    QCheckbox,
    QFile,
    QField,
    QSlideTransition,
    QSkeleton,
  },
  directives: {
    ClosePopup,
  },
  plugins: {
    Loading,
    Notify,
    Dialog,
  },
});

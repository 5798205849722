

















































import { Prop, Component, Vue, Watch } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';
import { Validator } from '@/utils/validators';
import { IQuestion } from '../../../models/definitions';
import Select from '@/components/presentational/Select.vue';
import {
  IPostBookingField, IBookingField,
  ITicketField, QuestionType,
} from '@/models/store/booking';

@Component({
  components: {
    Select,
  },
  validations() {
    const question: ITicketField = this.$props.quest;
    const validation: any = {};

    if (question.questionType === QuestionType.text) {
      validation.answer = {};

      // skip ticket field validation
      if (!question.pssId && question.validation) {
      // if (question.validation) {
        validation.answer.required = required;
      }
    } else if (question.questionType === QuestionType.list) {
      validation.listAnswers = {};

      // skip ticket field validation
      if (!question.pssId && question.validation) {
        validation.listAnswers.isListEmpty = Validator.isArrayEmpty;
      }
    }

    return validation;
  },
})
export default class Question extends Vue {
  @Prop() public quest!: IBookingField;
  // field position
  @Prop({default: 0}) public position!: number;
  @Prop({default: false}) public instantBooking!: boolean;
  public QuestionType = QuestionType;
  private listAnswers: string[] = [];
  private selectValue: string = '';
  private answer: string = '';

  // unique string for this component
  private uniqueKey: string = String(Math.random() * Math.random());

  get isInstantBooking() {
    return this.instantBooking;
  }

  get selectList() {
    if (this.quest && this.quest.questionType === QuestionType.dropdown) {
      if (this.quest.list) {
        return this.quest.list.map((val: string) => ({
          key: val,
          value: val,
        }));
      }
    }

    return [];
  }

  get list() {
    if (this.quest && this.quest.questionType === QuestionType.list) {
      if (this.quest.list) {
        return this.quest.list;
      }
    }

    return [];
  }

  get hasError() {

    // Not sure whether we should show an error immediately
    // or until a user fills a field. Now we aren't showing directly
    return this.$v.$error;
    // return this.$v.$invalid;
  }

  get isInvalid() {
    return this.$v.$invalid;
  }

  /**
   * Returns whether is the question is answered or not
   */
  public isQuestionAnswered() {
    return !!this.answer || this.listAnswers.length > 0;
  }

  public getAnswer(): IPostBookingField {
    // if quesion isn't answered, return just field_id
    if (!this.isQuestionAnswered()) {
      return {
        fieldId: this.quest.fieldId,
      };
    }

    let answer: string | string[];
    if (this.quest.questionType === QuestionType.list) {
      answer = this.listAnswers;
    } else {
      answer = this.answer;
    }

    return {
      fieldId: this.quest.fieldId,
      answer,
    };
  }

  // @Watch('$v.answer.$invalid')
  @Watch('$v.$invalid')
  private onAnswerValidityChange(val: boolean) {
    // Do nothing for list questions
    if (this.quest.questionType === QuestionType.list) {
      return;
    }

    this.validate();
  }

  @Watch('$v.listAnswers.$invalid')
  private onListValidityChange(val: boolean) {
    // Do nothing for text questions
    if (this.quest.questionType === QuestionType.text) {
      return;
    }

    this.validate();
  }

  private mounted() {
    // set default value for select
    if (this.quest.questionType === QuestionType.dropdown && this.quest.list) {
      this.answer = this.quest.list[0];
    }

    // run a first validation if necessary
    this.validate();
  }

  // returns a checkbox value that is unique to this question
  private checkboxVal(quest: ITicketField) {
    // if (quest.questionType !== QuestionType.list) {
    //   return ``;
    // }

    // return quest.pssId ?
    //   `${quest.pssId}${quest.fieldId}` :
    //   `${quest.fieldId}`;

    // return String(Math.random());
    return this.uniqueKey;
  }

  private setSelectValue(item: any) {
    this.answer = item.value;
  }

  private validate() {
    this.emitValidity();
  }

  private emitValidity() {
    const obj = {
      // ticket fields have form position attached to them
      position: (this.quest as ITicketField).formPosition || this.position,
      valid: !this.isInvalid,
    };

    this.$emit('validity', obj);
  }
}





























































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { AppModule, ProductsModule, RegionModule } from '@/utils/storemodules';
import { ProductLocation, ProductSearchCity } from '@/models/store/products';


@Component
export default class ListItemsFilter extends Vue {
  @Prop({ default: '' }) private title!: string;
  @Prop({ default: () => [] }) private items!: any[];
  @Prop({ default: '' }) private listType!: string;
  // Lets hide 'See More' button for now
  private itemsLimit: number = 100;
  private fullShow: boolean = false;
  private checkedItems: string[] = [];
  private pickedItem?: string;
  private pickedCategories?: any[] = ProductsModule.smeetzCategory;

  private created() {
    this.pickedItem =
      RegionModule.regions.find(
        (r) => r.location === ProductsModule.productLocation,
      )?.value || '';
    // empty space is used for ignoredLocation
  }

  get openMore() {
    if (this.items.length > this.itemsLimit && !this.fullShow) {
      return true;
    }
    return false;
  }

  get showSeeMore() {
    return this.items.length > this.itemsLimit;
  }

  get pickedRegion() {
    if (this.pickedItem === '') {
      return this.title;
    }
    return this.pickedItem;
  }

  private getRegionName(location?: ProductLocation): string {
    if (location) {
      const region = this.items.find((e) => e.value.center === location.center);
      return region.label;
    }
    return this.title;
  }

  get pickedCategory() {
    return ProductsModule.smeetzCategory;
  }

  private toggleShow() {
    this.fullShow = !this.fullShow;
  }

  get ignoredLocation() {
    return ProductsModule.ignoredLocation;
  }

  get initialLocation() {
    return ProductsModule.productLocation || ProductsModule.ignoredLocation;
  }

  private get isMobile() {
    return AppModule.isMobile;
  }

  private async setCategories(pickedCategories: any[]) {
    ProductsModule.setSmeetzCategory(pickedCategories);
    await ProductsModule.search();
  }

  private async setRegions(pickedItem: string) {
    const searchCity: ProductSearchCity | undefined = RegionModule.regions.find(
      (r) => r.value === pickedItem,
    );
    if (!searchCity) {
      return;
    }
    ProductsModule.setProductLocation(searchCity.location);
    await ProductsModule.search();
  }

  // Watcher invoked when changing location
  // We will consier only the one coming from Restor Filters btn
  @Watch('$store.state.products.productLocation')
  private onProductLocationChange(newRegion: ProductLocation) {
    if (newRegion.center === ProductsModule.ignoredLocation.center) {
      // going to assign it the value we use fo ignoredLocation which is empty space
      this.pickedItem = '';
      return;
    }

    const searchCity: ProductSearchCity | undefined = RegionModule.regions.find(
      (r) =>
        r.location.center === newRegion.center &&
        r.location.unit === newRegion.unit &&
        r.location.distance === r.location.distance,
    );
    if (!searchCity) {
      return;
    }
    this.pickedItem = searchCity.value;
  }

  @Watch('$store.state.products.smeetzCategory')
  private onSmeetzCategoryChange() {
    this.pickedCategories = ProductsModule.smeetzCategory;
  }
}

/**
 * Events emitted by different components
 */

// Calendar component events
export enum EVCalendar {
  DateSelected = 'dateSelected',
  MonthChanged = 'monthChanged',
  CalendarMounted = 'calendarMounted',
}

// SlotsFilter component events
export enum EVSlotsFilter {
  // emits array of Date objects that are selected
  DatesSelected = 'datesSelected',

  // emitted when an hour is selected.
  // @Param string (in the format HH ex 12)
  HourSelected = 'hourselected',

  // emitted when a month is changed on the calendar
  MonthChanged = 'monthChanged',

  // emitted when the component is rendered to populate gradients
  UpdateCalendar = 'updateCalendar',

  // emitted when in mobile to auto scroll from calendar to slots

  ScrollToSlots = 'scrollToSlots',
}

// Select
export enum EVSelect {
  // emit a value that was selected
  ValueSelected = 'valueSelect',
}

// PromoCode
export enum EVPromoCode {
  // emitted when the promocode screen is visible
  // @Param boolean
  Popped = 'popped',

  // indicates whether we show the basket or not. useful for mobile
  // @Param boolean
  BasketVisible = 'basketVisible',
}

// Personla details
export enum EPersonalDetails {
  // emitted when there is an error after submitting custom fields
  // @Parsm error object
  Error = 'error',
}

// Slots hours
export enum ESlotsHours {
  // emitted when an hour is selected.
  // @Param string (in the format HH ex 12)
  HourSelected = 'hourselected',

}

// SlotPrice
export enum EVSlotPrice {
  // emitted when requested number of ticket is no longer available
  // @Params number (slot id)
  CountNotPresent = 'count-not-present',
}

export enum EBookigCodeErrors {
  PromoCodeIsAlreadyUsed = 'Promocode not can not be used anymore.',
  TimeSlotIsNotValid = 'Time slot date is not valid.',
}




























/**
 * @Prop price {IExportPricesEntity}
 * @Prop timeSlot {ITimeslotsEntity}
 * @Prop ticket {ITicket}
 */
import { Component } from 'vue-property-decorator';
import { Mixins } from 'vue-mixin-decorator';
import Select from '@/components/presentational/NewSelect.vue';
import SlotPriceMixin from '@/mixins/SlotPrice';

@Component({
  components: {
    Select,
  },
})
// export default class SlotPrice extends Vue {
export default class BasketPrice extends Mixins<SlotPriceMixin>(SlotPriceMixin) {
  // @Prop() private price!: IExportPricesEntity;
  // @Prop() private timeSlot!: ITimeslotsEntity;
  // @Prop() private ticket!: ITicket;
  // private EVSelect = EVSelect;
  // private isLoading = false;

  // get currency(): string {
  //   return 'CHF';
  // }

  // get priceCount(): number {
  //   return this.price.count || 0;
  // }

  // get priceRange(): number[] {
  //   const range = [0];
  //   if (!this.price) {
  //     return range;
  //   }

  //   const min = this.price.minBooking;
  //   const max = this.price.maxBooking;
  //   let count = min;

  //   // don't duplicate the zero
  //   if (count === 0) {
  //     count++;
  //   }

  //   while (count <= max) {
  //     range.push(count);
  //     count++;
  //   }

  //   return range;
  // }

  // private async addPrice(count: number) {
  //   this.isLoading = true;
  //   try {
  //     await NBookingModule.addPrice({
  //       price: this.price,
  //       slot: this.timeSlot,
  //       count: Number(count),
  //       ticket: this.ticket,
  //     });
  //   } finally {
  //     this.isLoading = false;
  //   }
  // }

}

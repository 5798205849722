

























































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import Footer from '../components/NewFooter.vue';
import Nav from '../components/NewNavigation.vue';
import {AppModule} from '@/utils/storemodules';
import { Meta } from '@/utils/decorators';
import {setLangForMeta} from '@/utils/helpers';
import Subscription from '../components/presentational/Subscription.vue';
import { email, required, maxLength } from 'vuelidate/lib/validators';
import request from '@/utils/request';
import { constants } from '@/config/constants.ts';
import { NNoty } from '@/utils/storemodules';


@Component({
  components: {
    Footer,
    Nav,
    Subscription,
  },
  validations: {
    email: {
      required,
      email,
      maxLength: maxLength(255),
    },
  },

})

export default class ContactPage extends Vue {
  private openAnswer: boolean = false;
  private answerId: number | null = null;
  private loading: boolean = false;
  private inputData = {
    firstName: '',
    lastName: '',
    email: '',
    subject: '',
    content: '',
    preferredlanguage: '',
  };

  private faq = [
    {
      id: 1,
      question: 'faqs.question1',
      answer: 'faqs.answer1',
    },
    {
      id: 2,
      question: 'faqs.question2',
      answer: 'faqs.answer2',
    },
    {
      id: 3,
      question: 'faqs.question3',
      answer: 'faqs.answer3',
    },
    {
      id: 4,
      question: 'faqs.question4',
      answer: 'faqs.answer4',
    },
    {
      id: 5,
      question: 'faqs.question5',
      answer: 'faqs.answer5',
    },
    {
      id: 6,
      question: 'faqs.question6',
      answer: 'faqs.answer6',
    },
  ];

  private get becomeProviderUrl() {
    return  constants.business[this.$i18n.locale];
  }

  private get isMobile() {
    return AppModule.isMobile;
  }


  private get isTablet() {
    if (AppModule.isTablet && window.innerWidth < 968 || AppModule.isMobile) {
      return true;
    }
  }

  private showAnswer(id: number) {
    for (const q of this.faq) {
      if (q.id === id) {
        this.openAnswer = !this.openAnswer;
        this.answerId = id;
      }
    }
  }

  @Watch('$store.state.auth.lang')
  private prefferedLang() {
    if (this.$i18n.locale === 'en') {
      this.inputData.preferredlanguage = 'English';
    }
    if (this.$i18n.locale === 'fr') {
      this.inputData.preferredlanguage = 'French';
    }
    if (this.$i18n.locale === 'de') {
      this.inputData.preferredlanguage = 'German';
    }
    if (this.$i18n.locale === 'it') {
      this.inputData.preferredlanguage = 'Italian';
    }
    if (this.$i18n.locale === 'ar') {
      this.inputData.preferredlanguage = 'Arabic';
    }
  }

// Clears form
  private resetForm() {
    this.inputData = {
      firstName: '',
      lastName: '',
      email: '',
      subject: '',
      content: '',
      preferredlanguage: '',
  };
    (this.$refs.qForm as any).reset();

  }

  private async onSubmit() {
    try {
      this.loading = true;
      const res = await request({
        method: 'post',
        url: '/hubspot/support-form',
        params: this.inputData,

      });

      this.loading = false;
      this.resetForm();
      NNoty.createNewNoty({
        message: this.$t('contact-us.on-success-msg') as string,
        period: 5000,
        type: 'success',
      });

    } catch (err) {
      this.loading = false;
      this.resetForm();
      NNoty.createNewNoty({
        message: this.$t('contact-us.on-error-msg') as string,
        period: 5000,
        type: 'error',
      });

    }
  }

  private mounted() {
    this.prefferedLang();
  }

  @Meta
  private metaInfo() {
    return{
      title: 'Lost ticket & Support',
      link: [
        { rel: 'canonical', href: location.protocol + '//' + location.host + location.pathname },
      ],
      meta: [
        { hid: 'title', name: 'title', content: this.$t('contact-us.title') },
        { hid: 'description', name: 'description',
         content: (this.$t('contact-us.content-part1') + ' ' as string)
         .concat((this.$t('contact-us.content-part2') as string))},
        { property: 'fb:admins', content: '100001288015151' },
        { hid: 'og:title', property: 'og:title',
          content: 'Lost ticket & support'},
        { hid: 'og:description', property: 'og:description',
          content: (this.$t('contact-us.content-part1') + ' ' as string)
          .concat((this.$t('contact-us.content-part2') as string))},
        { hid: 'og:type', property: 'og:type', content: 'page' },
        { hid: 'og:url', property: 'og:url', content: location.protocol + '//' + location.host + location.pathname },
        { hid: 'og:image', property: 'og:image', content: location.protocol + '//' + location.host + '/img/support_img.c1a6c91c.png' },
        { hid: 'og:image:alt', property: 'og:image:alt',
          content: this.$t('contact-us.contact-us') },
        { hid: 'og:locale', property: 'og:locale',
          content: setLangForMeta(this.$route.params.locale || this.$i18n.locale)},
      ],
    };
  }

}


import { client } from '@/utils/graphqlClient/graphql-client';
import {
  GetMembershipOverviewQuery,
  GetMembershipOverviewQueryVariables,
} from '@/types/gql/generated/graphql';
import { GET_MembershipOverview } from './gql/membership.graphql';

/**
 * Returns an overview of a given membership discount
 *
 * @param id Membership Discount id
 */
export async function getMembershipOverview(id: string) {

  const res = await client.query<GetMembershipOverviewQuery, GetMembershipOverviewQueryVariables>(
    GET_MembershipOverview,
    { id }).toPromise();

  return res.data?.MembershipDiscountRead;

}






























































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { email, required, maxLength } from 'vuelidate/lib/validators';
import { ProductModule } from '@/utils/storemodules';
import { ModalStatus } from '@/models/definitions';
import { IReportActivity } from '@/models/product';
import { NModalModule } from '@/utils/storemodules';

@Component({
  validations: {
    firstname: {
      required,
      maxLength: maxLength(255),
    },
    lastname: {
      required,
      maxLength: maxLength(255),
    },
    email: {
      required,
      email,
      maxLength: maxLength(255),
    },
    description: {
      required,
      maxLength: maxLength(500),
    },
  },
})
export default class ReportActivity extends Vue {
  private firstname: string = '';
  private lastname: string = '';
  private email: string = '';
  private description: string = '';

  // Vuelidate doesn't trigger if user doesn't click on field.
  // So we check for error and not-empty fields
  private get enableSubmit() {
    return !this.$v.$error && this.firstname && this.lastname && this.email && this.description;
  }

  // Submit changes to store
  private async submit() {
    const sendData: IReportActivity = {
      firstName: this.firstname,
      lastName: this.lastname,
      email: this.email,
      content: this.description,
      subject: `Report an activity - ${window.location.href}`,
      preferredlanguage: this.$i18n.locale,
    };
    if ( this.enableSubmit ) {
      await ProductModule.sendReport(sendData);
    }
    this.$emit('modalClose');
  }
}





















































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Mixins } from 'vue-mixin-decorator';
import { IGQLProductSlotState } from '@/models/store/booking';
import RefundTransaction from '@/views/OrganizerUserSpace/components/RefundBooking/RefundSummary/RefundTransaction.vue';
import DateHelperMixin from '@/mixins/DateHelper';
import dayjs from 'dayjs';
import { AppModule, RBookingModule } from '@/utils/storemodules';
import { QPagination } from 'quasar';


@Component({
  components: {
    RefundTransaction,
  },
})
  export default class BookingOverview extends Mixins<DateHelperMixin>(
  DateHelperMixin,
)  {
    private get isPagination(): boolean {
      return this.ticketNames.length > 1;
    }
    // Important property - decides which current ticket group
    private get currentTicketKey(): string {
      return this.ticketNames[this.ticketIndex - 1];
    }
    private get selectedSlots(): IGQLProductSlotState[] {
      return RBookingModule.selectedSlots;
    }
    private get slotsGroupesReactive(): any {
      return this.selectedSlots.reduce(
            (groupedSlots: any, slot: IGQLProductSlotState) => {
            const ProductName = slot.ProductSlotCategoryRead.ProductRead.Name;
            const CategoryName = slot.ProductSlotCategoryRead.CategoryName;
            groupedSlots[`${ProductName}/${CategoryName}`] =
            groupedSlots[`${ProductName}/${CategoryName}`] || [];
            groupedSlots[`${ProductName}/${CategoryName}`].push(slot);
            return groupedSlots;
            }, Object.create(null));
    }
    // SHOW only the chosen slots with their CategoryName and attendees
    private get currentSlotsGroupFixed(): IGQLProductSlotState[] {
      return this.slotsGroupesFixed![this.currentTicketKey] || [];
    }
    private get currentSlotsGroupReactive(): IGQLProductSlotState[] {
      return this.slotsGroupesReactive![this.currentTicketKey] || [];
    }
    private get attendees() {
      return RBookingModule.attendees;
    }

    private get currentAttendees() {
       // GET the tickets and their attendees that we want to show
      const currentSlotsIds = this.slotsGroupesFixed[this.currentTicketKey]
        .map((item: IGQLProductSlotState) => item.Id);
      const currentAttendees = this.attendees.filter((item) =>
        currentSlotsIds.includes(item.pssId) && item.fullName)
        .map((item) => ({fullName: item.fullName, pssId: item.pssId}));
      const currentAttendeesIds = currentAttendees.map((item) => item.pssId);

      // ASSIGN a price name instead if the ticket has no name
      if (currentAttendees.length <= this.slotsGroupesFixed[this.currentTicketKey].length) {
        // EXCLUDE the tickets that already have a name
        const virtualAttendees = this.slotsGroupesFixed[this.currentTicketKey].
        filter((item: IGQLProductSlotState) =>
          !currentAttendeesIds.includes(item.Id))
          .map((item: IGQLProductSlotState) => ({fullName: item.PriceName, pssId: item.Id}));
        return currentAttendees.concat(virtualAttendees);
      }
      return currentAttendees;
    }

    get isMobile() {
      return AppModule.isMobile;
    }

    private ticketNames: string[] = [];
    private ticketIndex: number = 1;
    private slotsGroupesFixed: any = null;

    private async created() {
      this.ticketNames = Object.keys(this.slotsGroupesReactive);
      this.slotsGroupesFixed = this.slotsGroupesReactive;
    }

    private translateDate(date: string, onlyDate: boolean = true) {
      return this.currentDate(date, onlyDate);
    }

    private getTime(date: string) {
      const momentDate = dayjs(date);
      return this.formatTime(momentDate.format('HH:mm'));
    }


    private isTicketIncluded(pssId: number) {
      return !!RBookingModule.selectedSlots.filter((item) => item.Id === pssId).length;
    }

    private selectTicket(pssId: number) {
      const slot = RBookingModule.slots!.find((item) => item.Id === pssId) as IGQLProductSlotState;
      if (this.isTicketIncluded(pssId)) {
        RBookingModule.setCurrentSlots({selectedSlots: [slot], add: false});
      } else {
        RBookingModule.setCurrentSlots({selectedSlots: [slot], add: true});
      }
    }

  }

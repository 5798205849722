


















import DateHelperMixin from '@/mixins/DateHelper';
import { RBookingModule } from '@/utils/storemodules';
import { Component, Mixins } from 'vue-property-decorator';
import TicketCard from '@/views/OrganizerUserSpace/components/RefundBooking/MyBooking/TicketCard.vue';
import SelectedGroup from '@/views/OrganizerUserSpace/components/RefundBooking/MyBooking/SelectedGroup.vue';
import NewButton from '@/components/presentational/NewButton.vue';
import { RefundSteps } from '@/store/modules/refundBooking/constants';
import { IGQLProductSlotState } from '@/models/store/booking';

@Component({
  components: {
    TicketCard,
    SelectedGroup,
    NewButton,
  },
})
  export default class TicketsCard extends Mixins<DateHelperMixin>(
  DateHelperMixin,
)  {

    private get selectedGroups() {
      return RBookingModule.slotsGroupedByPriceName;
    }
    private get isSelectedSlots() {
      return !!RBookingModule.selectedSlots.length;
    }
    private get selectedSlots() {
      return RBookingModule.selectedSlots;
    }
    private goRefundSummary() {
      RBookingModule.setCurrentStep(RefundSteps.RefundSummary);
    }
    private isSelected(slotsGroup: IGQLProductSlotState[]) {
      const slotsIds = slotsGroup.map((item) => item.Id);
      return !!this.selectedSlots.filter((item) => slotsIds.includes(item.Id)).length;
    }
}

export enum BookingSteps {
  Order,
  Checkout,
  Payment,
  Confirmation,
}

export const StepName: { [s: number]: string } = {
  [BookingSteps.Order]: 'order',
  [BookingSteps.Checkout]: 'checkout',
  [BookingSteps.Confirmation]: 'confirmation',
  [BookingSteps.Payment]: 'payment',
};

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { IErrorState, IErrorData } from '@/models/store/error';

@Module({ namespaced: true, name: 'nnoty' })
export default class NNoty extends VuexModule {
  public errorState: IErrorState = {
    timeout: undefined,
    message: '',
    type: 'error',
  };
  public isNotyShown: boolean = false;

  public get message() {
    return this.errorState.message;
  }

  @Mutation
  public setMessage(payload: string) {
    this.errorState.message = payload;
  }

  @Mutation
  public setType(payload: 'alert' | 'success' | 'warning' | 'error' | 'info') {
    this.errorState.type = payload;
  }

  @Mutation
  public setTimeout(payload: number) {
    if (this.errorState.timeout) {
      clearTimeout(this.errorState.timeout);
    }
    this.errorState.timeout = payload;
  }

  @Mutation
  public showNoty() {
    this.isNotyShown = !this.isNotyShown;
  }

  @Action({ rawError: true })
  public createNewNoty(payload: IErrorData) {
    this.setTimeout(payload.period);
    this.setMessage(payload.message);
    if ( payload.type ) {
      this.setType(payload.type);
    }
    this.showNoty();
  }

}

import { Module, ActionTree, MutationTree, GetterTree } from 'vuex';
import {
  register, verifyEmail, login, getUser,
  sendConfEmail, socialLogin, apiRefreshToken,
  resetPassReq, resetPass, changePass, updateUser,
  cancelAccount, addEmail, cancelEmailPhone, setPrimaryEmail,
  addPhone,
} from '@/api/auth';
import { IPassReset, IPassChange, IDeleteAccount } from '@/models/store/auth';
import { IRootState } from '@/models/store';
import {
  IAuthState, ILoginData, ISocialData,
  ILoginResponse, IFBData, IPhoneData,
} from '@/models/store/auth';
import {
  IUser, IUserData, IOrgUserData,
  IEmailVerifyData, SocialService,
  ModalStatus,
} from '@/models/definitions';
import { setTokenObj, getTokenObj, removeToken, getToken, getTokenRefresh, getTokenExpiry } from '@/utils/token';
import { Status } from '@/utils/status';
import { defineLanguage, getLanguageInt } from '@/utils/helpers';

import Debug from 'debug';
import { userIdentityTracking } from '@/utils/tracking';
import { NModalModule, UserModule } from '@/utils/storemodules';
import { inIframe } from '@/utils/iframe';

const debug = Debug('smeetz:auth');

const namespaced: boolean = true;

const state: IAuthState = {
  user: undefined,
  token: undefined,
  errorFlag: false,
  error: undefined,
  lang: 'en',
  langSet: false,
};

// Getters
const getters: GetterTree<IAuthState, IRootState> = {
  isLoggedIn(currentState): boolean {
    return !!(currentState.token && currentState.user);
  },

  getToken(currentState): string | undefined {
    return currentState.token;
  },

  getUser(currentState, currentGetters): IUser | undefined {
    if (!currentGetters.isLoggedIn && !UserModule.isOrgUserLoggedIn) {
      return;
    }

    return currentState.user;
  },

  getLangId(currentState): number {
    return getLanguageInt(currentState.lang);
  },

  getLang(currentState): string {
    return currentState.lang;
  },

  getBirthdate(currentState, currentGetters): string {
    if (!currentGetters.isLoggedIn) {
      return '';
    }

    return currentGetters.getUser.birthDate;
  },

  getLocation(currentState, currentGetters) {
    if (!currentGetters.isLoggedIn) {
      return {};
    }

    return currentGetters.getUser.location || {};
  },

  getLanguageName(currentState, currentGetters): string {
    if (!currentGetters.isLoggedIn) {
      return '';
    }

    return currentGetters.getUser.language.name;
  },

  getEmails(currentStatus, { isLoggedIn, getUser: mgetUser }) {
    if (!isLoggedIn) {
      return [];
    }

    return mgetUser.emails || [];
  },

  getPhones(currentStatus, { isLoggedIn, getUser: mgetUser }) {
    if (!isLoggedIn) {
      return [];
    }

    return mgetUser.phones || [];
  },

  firstName(currentState, currentGetters): string {
    // return an empty string if user is not logged in
    if (!currentGetters.isLoggedIn) {
      return '';
    }

    // otherwise return the name of the user with a capital
    // first letter.
    if (!currentState.user) {
      return '';
    }

    const firstName = currentState.user.firstName;
    if (!firstName) {
      return '';
    }

    return firstName[0].toUpperCase() + firstName.slice(1);
  },

  lastName(currentState, currentGetters): string {
    // return an empty string if user is not logged in
    if (!currentGetters.isLoggedIn) {
      return '';
    }

    // otherwise return the name of the user with a capital
    // first letter.
    if (!currentState.user) {
      return '';
    }

    const lastName = currentState.user.lastName;
    if (!lastName) {
      return '';
    }

    return lastName[0].toUpperCase() + lastName.slice(1);
  },

  fullName(currentState, { isLoggedIn, firstName, lastName }) {
    if (!isLoggedIn) {
      return '';
    }

    return `${firstName} ${lastName}`;
  },
};

// Mutations
const mutations: MutationTree<IAuthState> = {
  setToken(currentState, payload) {
    debug(`Setting token to: ${payload}`);
    currentState.token = payload;
  },
  setUser(currentState, user: IUser) {
    debug('Setting user');
    currentState.user = user;

    // Leave in case we are just clearing the user
    if (!user) {
      return;
    }

    userIdentityTracking({
      email: user.emails[0].emailAddress,
      firstname: user.firstName,
      lastname: user.lastName,
    });

    // Skip language setting if user has no lang or he manually chose which language
    // he wants
    if (!user.language || currentState.langSet) {
      return;
    }
    currentState.lang = user.language.code;
  },
  setLang(currentState, lang: string) {
    currentState.lang = lang;
  },
  // Indicates that the user did modify the language himself
  // So, it shouldn't be updated automatically when the user if fetched
  setLangSet(currentState, userLang: boolean) {
    currentState.langSet = userLang;
  },
};

// Actions
const actions: ActionTree<IAuthState, IRootState> = {
  async register({ commit }, data: IUserData) {

    // return;
    debug(`registering ${data}`);
    await register(data);

    // Login the user
    const { emailAddress: username, password } = data;
    const loginResponse = await login({ username, password: password.first });

    // set temporary cookies
    setTokenObj(loginResponse, true);
    return;
  },

  async verifyEmail({ commit }, data: IEmailVerifyData) {

    debug(`verifying ${JSON.stringify(data)}`);

    await verifyEmail(data);

    // change temporary token
    const tokenObj = getTokenObj(true);
    if (tokenObj.access_token) {
      // set access_token in the store and in a cookie
      setTokenObj(tokenObj);
      commit('setToken', tokenObj.access_token);
      debug('Token object is set');

      // remove temporary token from cookie
      removeToken(true);
      debug('Temporary token is removed');
    } else {
      debug('No token. exiting');
      return;
    }

    // get user data
    const user = await getUser();

    // commit user
    commit('setUser', user);
  },

  async sendConfEmail({ commit, getters: mgetters }, email: string) {
    await sendConfEmail({
      email,
      languageId: mgetters.getLangId,
    });

    return;
  },

  // Attempts to login the user if an access token cookie
  // is present.
  // It does nothing if the user is already logged in.
  // It attempts to refresh the token before getting user info if the token is expired already
  async attemptLogin({ commit, dispatch, getters: currentGetters }) {
    // skip if user is already logged in
    if (currentGetters.isLoggedIn) {
      return;
    }

    // skip if no token is present in cookie
    const token = getToken();
    if (!token) {
      return;
    } else {
      // there is a token present, lets check if it is not expired, we will not refresh it
      if (getTokenExpiry() > 0) {
        // token is not expired
        // get the user from server
        const user = await getUser();
        debug(user);

        // set user
        commit('setUser', user);
        commit('setToken', getToken());
      } else {
        // token is expired, lets refresh it
        await dispatch('refreshToken');

        // get the user from server
        const user = await getUser();

        // set user
        commit('setUser', user);
        // commit('setToken', getToken());
      }
    }


  },

  async refreshToken({ commit }): Promise<ILoginResponse | undefined> {
    debug('Attempting to refresh token');
    const refreshTk = getTokenRefresh();

    if (!refreshTk) {
      debug('No refresh token is present');
      return;
    }

    debug('Going to get refreshed token from server');
    // Make sure that we remove the token first, otherwise we get stuck in an infinite
    // loop because the request interceptor will find an expired token and will attempt
    // to refresh by calling this method again.
    // We do risk losing the token in the process, but that's a rare case.
    removeToken();
    const data = await apiRefreshToken(refreshTk);

    if (data) {
      // Proceed refreshing the tokens we already have only if we get a response from the server

      debug('Got refreshed token from the server');

      // use server data to refresh the token in the cookie
      setTokenObj(data);
      debug(`tokenExpiry refreshed and is: ${getTokenExpiry()}`);

      // Commit a mutation to reset the token in the state using cookie token
      debug('here is the token from cookie using getToken() method:');
      debug(getToken());
      // commit('setToken', getToken());
      commit('setToken', data.access_token);
    }

    return data;
  },

  async login({ commit }, userData: ILoginData): Promise<ILoginResponse> {
    // Retrieve and set store token
    const tokenResponse = await login(userData);
    commit('setToken', tokenResponse.access_token);
    try {
      // Get user then set cookie token
      const user = await getUser();
      setTokenObj(tokenResponse);

      // Set user
      commit('setUser', user);
    } catch (err) {
      // clear store token
      commit('setToken', undefined);

      const response = err.response;
      if (!response) {
        throw err;
      }

      const status = response.status;
      if (Status[status] === 'Unauthorized') {
        debug('User hasn\'t confirmed his email yet');
        setTokenObj(tokenResponse, true);
      }

      throw err;
    }

    // clear modal
    commit('modal/setStatus', ModalStatus.Hidden, { root: true });
    NModalModule.setStatus(ModalStatus.Hidden);
    return tokenResponse;
  },

  async fbLogin({ commit, getters: moduleGetters }, data: IFBData) {
    // Login user
    const tokenObj =
      await socialLogin(
        {
          facebook_token: data.accessToken,
          facebook_id: data.userID,
          language_id: moduleGetters.getLangId || 1,
        },
        SocialService.Facebook);

    // set token in store and cookie
    commit('setToken', tokenObj.access_token);
    setTokenObj(tokenObj);

    // get user data
    const user = await getUser();

    // set user data
    commit('setUser', user);

    // clear modal
    NModalModule.setStatus(ModalStatus.Hidden);
    commit('modal/setStatus', ModalStatus.Hidden, { root: true });
  },

  async gLogin({ commit, getters: moduleGetters, rootState }, data: ISocialData) {
    const languageId = 1;
    const tokenObj =
      await socialLogin(
        {
          auth_code: data.authCode,
          language_id: moduleGetters.getLangId || languageId,
        },
        SocialService.Google);

    // set token in store and cookie
    commit('setToken', tokenObj.access_token);
    setTokenObj(tokenObj);

    // get user data
    const user = await getUser();

    // set user data
    commit('setUser', user);

    // clear modal
    commit('modal/setStatus', ModalStatus.Hidden, { root: true });
    NModalModule.setStatus(ModalStatus.Hidden);
  },

  async swisspassLogin({ commit, getters: moduleGetters }, data: ISocialData) {
    const languageId = moduleGetters.getLangId;
    const tokenObj =
      await socialLogin(
        {
          swisspass_card_id: data.swisspassCardId,
          swisspass_zip: data.swisspassZip,
          language_id: languageId,
        },
        SocialService.Swisspass);

    // set token in store and cookie
    // commit('setToken', tokenObj.access_token);
    // setTokenObj(tokenObj);

    // // get user data
    // const user = await getUser();

    // // set user data
    // commit('setUser', user);
  },

  // Logs the user out
  async logout({ commit }) {
    // remove cookie
    removeToken();

    // remove user obj
    commit('setUser', undefined);

    // remove token from store
    commit('setToken', '');
  },

  // Requests user password reset.
  async resetPassReq({ commit }, email: string) {
    const data = await resetPassReq(email);

    return data;
  },

  // checks password reset token
  async resetPass(context, payload: IPassReset) {
    const data = await resetPass(payload);

    return data;
  },

  // change user password
  async changePass({ getters: mgetters }, payload: IPassChange) {
    const data = await changePass(payload, mgetters.isLoggedIn);

    return data;
  },

  async updateUser(context, payload: IUserData) {
    payload.languageId = context.getters.getLangId;
    const data = await updateUser(payload);

    const user = await getUser();
    context.commit('setUser', user);

    return data;
  },

  async cancelAccount(context, payload?: IDeleteAccount) {
    const data = await cancelAccount(payload);

    return data;
  },

  async addEmail(context, email: string) {
    const data = await addEmail(email, context.getters.getToken);

    const user = await getUser();
    context.commit('setUser', user);

    return data;
  },

  async getUser(context) {
    if (!context.getters.isLoggedIn) {
      return;
    }

    const user = await getUser();
    context.commit('setUser', user);
    return user;
  },

  async cancelEmailPhone(context, { emailPhoneId, medium }) {
    if (!medium) {
      medium = 'email';
    }

    const response = await cancelEmailPhone(emailPhoneId, medium);

    return response;
  },

  async setPrimaryEmail(context, emailId: string) {
    const data = await setPrimaryEmail(emailId);

    return data;
  },

  async addPhone(context, data: IPhoneData) {
    const responseData = await addPhone(data);

    return data;
  },

  setOrgUser(context, userData: IOrgUserData) {
    context.commit('setUser', userData);
  },
};

const auth: Module<IAuthState, IRootState> = {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};

export default auth;

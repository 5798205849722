


























import { Vue, Component, Prop } from 'vue-property-decorator';
import { AuthModule, ModalModule } from '@/utils/storemodules';
import { ModalStatus } from '@/models/definitions';
import { constants } from '@/config/constants.ts';

@Component({
  components: {
  },
})
export default class DeleteAccountVerified extends Vue {
  private responseStatus: string = 'success';
  private contactLink = '';

  @AuthModule.Action('cancelAccount') private cancelAccount: any;
  @ModalModule.Mutation('setStatus') private setStatus: any;

  private async mounted() {
    const { cancellationToken, userId } = this.$route.query;
    try {
      await this.cancelAccount({ cancellationToken, userId });
    } catch (err) {
      this.contactLink = constants.forms_email;
      this.responseStatus = 'fail';
    }
  }
}

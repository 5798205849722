import { client } from '@/utils/graphqlClient/graphql-client';
import { GET_BookingWaivers } from './gql/waivers.graphql';
import {
  GetBookingWaiversQuery,
  GetBookingWaiversQueryVariables,
} from '@/types/gql/generated/graphql';


/**
 * Returns waivers list of a booking
 */
export async function getBookingWaivers(bookingId: string, bookingToken: string) {

  const res = await client.query<GetBookingWaiversQuery, GetBookingWaiversQueryVariables>(
    GET_BookingWaivers,
    {bookingId,  bookingToken }).toPromise();

  return res.data?.ProductSlotBookingList;

}























































































import { Vue, Component, Prop } from 'vue-property-decorator';
import dayjs from 'dayjs';
import ProgressBar from './ProgressBar.vue';
import RatingComponent from './RatingStars.vue';
import { BookingModule } from '../../../utils/storemodules';
import {
  GET_LOADING, GET_PRODUCT, GET_PRODUCT_CURRENCY,
  GET_PRODUCT_NAME, GET_PRODUCT_DESCRIPTION,
  GET_SELECTED_SLOTS, GET_PRODUCT_LOCATION,
  GET_DP_PRICE, GET_FLATTENED_CATEGORIES,
  GET_DP_SLOT_DATA,
} from '@/store/modules/booking/constants';
import { ISlot, ICategory, IDPSlotSource } from '../../../models/store/booking';
import { ILocation, IProduct } from '@/models/store/product';

@Component({
  components: {
    ProgressBar,
    RatingComponent,
  },
})
export default class ProductCard extends Vue {
  @Prop({default: false}) public showDescription!: boolean;
  @Prop({default: 5}) public rating!: number;
  @Prop({default: 12}) public expiresIn!: number;
  @BookingModule.Getter(GET_LOADING) private isLoading!: boolean;
  @BookingModule.Getter(GET_PRODUCT) private product!: IProduct;
  @BookingModule.Getter(GET_PRODUCT_NAME) private title!: string;
  @BookingModule.Getter(GET_PRODUCT_CURRENCY) private currency!: string;
  @BookingModule.Getter(GET_PRODUCT_DESCRIPTION) private description!: string;
  @BookingModule.Getter(GET_SELECTED_SLOTS) private slots!: ISlot[];
  @BookingModule.Getter(GET_PRODUCT_LOCATION) private location!: ILocation;
  @BookingModule.Getter(GET_DP_PRICE) private price!: string;
  @BookingModule.Getter(GET_DP_SLOT_DATA) private dpSlot!: IDPSlotSource;
  @BookingModule.Getter(GET_FLATTENED_CATEGORIES) private getSelectedCategories!: ICategory[];

  // Checks if the test query parameter is set
  get isTesting() {
    return !!this.$route.query.test;
  }

  get image() {
    if (!this.product || !this.product.pictures) {
      return;
    }

    return this.product.pictures[3].url;
  }

  get priceOld() {
    // return (Number(this.price) + 10).toFixed(2);
    const category = this.getSelectedCategories[0];
    if (!category) {
      return 0;
    }

    for (const price of (category.price || [])) {
      if (price.priceId === this.dpSlot.price_id) {
        return price.priceValue;
      }
    }

    return 0;
  }

  get slot() {
    return this.slots[0];
  }

  get slotDay() {
    if (!this.slot) {
      return '';
    }

    const startDate = dayjs(this.slot.startDateTime);
    const day = startDate.format('DD.MM.YYYY');
    const weekDay = startDate.format('dddd').toLowerCase();
    const onString = this.$t('dynamic.label-on');
    return `${onString} ${this.$t('date.' + weekDay )}, ${day}`;
  }

  get slotTime() {
    if (!this.slot) {
      return '';
    }

    const start = dayjs(this.slot.startDateTime).format('HH:mm');
    const end = dayjs(this.slot.endDateTime).format('HH:mm');
    const from = this.$t('dynamic.label-from');
    const until = this.$t('dynamic.label-until');

    return `${from} ${start} ${until} ${end}`;
  }

  get policies() {
    if (!this.product) {
      return '';
    }

    return this.product.policies || [];
  }

  get address() {
    return this.location.address;
  }

  get city() {
    return `${this.location.zip} ${this.location.city}`;
  }
}


































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { OrganizerModule, AppModule } from '@/utils/storemodules';
import FacebookLogin from '@/components/auth/NewFacebookLogin.vue';
import GoogleLogin from '@/components/auth/NewGoogleAuth.vue';
import SocialsLoginPopup from './SocialsLoginPopup.vue';
import { NBookingModule, ProductModule } from '@/utils/storemodules';
import { hideFbLogin, hideGmailLogin } from '@/utils/helpers';
import { inIframe, requestCustomAuth } from '@/utils/iframe';
import { CustomAuthType } from '@/models/store/auth';
@Component({
  components: {
    FacebookLogin,
    GoogleLogin,
    SocialsLoginPopup,
  },
})
export default class SocialLogin extends Vue {
  @Prop({default: false}) public isLoginView!: boolean;
  private hideFbLogin: boolean = hideFbLogin();
  private hideGmailLogin: boolean = hideGmailLogin();
  private smeetzLogin: boolean = true;
  private showLoginPopup: boolean = false;

  get emailLoginTitle(): string {
    if (this.inIframe) {
      return this.$t('auth.social-email-iframe') as string;
    }
    return this.$t('auth.social-email') as string;
  }

  get inIframe(): boolean {
    return inIframe();
  }

  get hideSmeetzLogin(): boolean {

    // Only widgets can hide us
    if (!inIframe()) {
      return false;
    }

    const id = OrganizerModule.id;
    if (!id) {
      return !!null;
    }

    // Hide only for crocky trail on widgets
    return [18612, 18785].includes(id) ;
  }

  get showKeycloakLogin(): boolean {
    const orgId = OrganizerModule.id;
    if (!orgId) {
      return !!null;
    }
    // Show keycloak auth for Locarno festival
    // return [16438, 19017].includes(orgId);
    const isKeycloakEnable: boolean =  [16438, 19017, 19293].includes(orgId);
    return isKeycloakEnable;
  }

  private get isMobile() {
    return AppModule.isMobile;
  }

  private openLoginDialog() {
    if (this.showKeycloakLogin) {
      this.authenticateKeycloakUser();
      return;
    }
    this.$emit('openLoginDialog');
  }

  private onSuccessLogin() {
    this.$emit('goNextStep');
  }

  private onSkipLogin() {
    NBookingModule.setSkipLoginView(true);
    NBookingModule.userGuestCheckout();
    NBookingModule.setShowLoginView(false);
  }

  private openPoup() {
    this.showLoginPopup = true;
  }

  private hideSocialLoginPopup() {
    this.showLoginPopup = false;
  }

  private mounted() {
    if (this.showKeycloakLogin) {
      this.smeetzLogin = false;
    }
    // Use guestCheckoutValue to skip socials login page like guest checkout
    const guestCheckoutValue = 48298;
    if (ProductModule.product && ProductModule.product.id === guestCheckoutValue) {
      this.onSkipLogin();
    }
  }
  // Login using keycloak authentication
  private authenticateKeycloakUser() {
    requestCustomAuth({type: CustomAuthType.Keycloak});
    this.$emit('orgUserLoggingIn', true);
  }

}

import { ITrackingData, IServerBooking, ITransactionInfo, ISmtzTrackingData, ISmtzGTM, IPreBookingRes } from '@/models/store/booking';
import { Product } from '@/models/store/bookingClasses';
import { NBookingModule, OrganizerModule, ProductModule, ReferrerModule } from '@/utils/storemodules';
import Vue from 'vue';
import { IUser } from '@/models/definitions';
import VueRouter, { Route } from 'vue-router';
import Debug from 'debug';
import { IProduct } from '@/models/store/product';
import { sendGoogleAnalyticsData, sendGoogleGtmDataLayer, sendDatalayerIframe, inIframe } from '@/utils/iframe';
import { AppModule } from '@/utils/storemodules';
import dayjs from 'dayjs';
import { EventName, StrategyType } from '@/models/enums';
import { isInProduction } from './platform';
import { getFbc, getFbp } from './helpers';
import {logInfo} from './helpers';
import { getControlGroupUserList, getStrategyPrices } from '@/api/controlGroupUser';
import Mixpanel, { EVENTS } from '@/services/mixpanel';

interface Iga4Item extends Gtag.Item {
  currency?: string | undefined;
  value?: number | undefined;
  first_name?: string | undefined;
  last_name?: string | undefined;
  email?: string | undefined;
  discount?: number | undefined;
}


export function smeetzTracker(event: string, data: ISmtzTrackingData) {
  // Add the tracking id if it's not in the _smtz invocable object.
  if (!window._smtz.tid) {
    window._smtz.tid = AppModule.trackId;
  }

  const date = 1 * Number(new Date());
  // We don't want the app to fail in case tracking wasn't working.
  setTimeout(() => {
    window._smtz.p(event, date, { ...data });
  }, 0);
}

// Returns id of one organizer or ids of different organizers
function orgGoogleAnalyticIds(data: ITrackingData[]) {
  const allgAIds: string[] = [];

  for (const d of data) {
    if (d.googleTrackingId) {
      allgAIds.push(d.googleTrackingId);
    }
  }

  const uniqGaIds: string[] = [];

  // remove duplicate ids
  allgAIds.forEach((id) => {
    if (!uniqGaIds.includes(id)) {
      uniqGaIds.push(id);
    }
  });

  if (uniqGaIds.length > 1) {
    return uniqGaIds;
  }

  if (uniqGaIds.length === 1) {
    return uniqGaIds[0];
  }

  if (uniqGaIds.length === 0) {
    return null;
  }

}

// function that generate an iframe with tracking id as source
function generateEmbedIframe(gtmId: string) {
  if (checkEmbedIframe()) {
    return;
  }
  const iframe = document.createElement('iframe');
  iframe.id = 'smeetz-tracking-iframe';
  iframe.src = process.env.VUE_APP_EMBEDED_TRACING_SITE + '?gtmId=' + gtmId;
  iframe.style.display = 'none';
  document.body.appendChild(iframe);
}

// function to check if we have an iframe with tracking.smeetz.com as source
function checkEmbedIframe() {
  const iframe: HTMLElement | null = document.getElementById('smeetz-tracking-iframe');
  if (iframe) {
    return true;
  }
  return false;
}

const debug = Debug('smeetz:tracking');
// We sent this event when users first check the tickets
export async function bookingFlowViewItemList(
  productData: IProduct, position: number = 1, list: string) {
  const product = new Product(productData);
  const trackingData = product.toTrackingData(position, list);
  const trackingId = AppModule.trackId;

  const items: Iga4Item[] = [
    {
      id: String(trackingData.id),
      name: trackingData.name,
      currency: trackingData.currency,
      brand: trackingData.brand,
      quantity: trackingData.quantity || 1,
    },
  ];
  const gaData = {
    currency: trackingData?.currency,
  };
  // datalyer GTM object
  const dataLayerObject: ISmtzGTM = {
    event: 'smtz_view_item_list',
    smeetzLabel:  trackingData.name,
    smeetzCategory: 'ecommerce',
    smeetzAction: 'view Item List',
    smeetzData: items,
  };

  // If we have dynamic pricing we send an event to GTM
  // This event is used in the GTM container to flag the rest of the events
  // with the user property "pricing" with value "dynamic"
  const productIdString = String(product.id);
  const controlGroupUserList = await getControlGroupUserList(trackingId, productIdString);
  if (controlGroupUserList && controlGroupUserList.length > 0) {
    if (controlGroupUserList.some((controlGroupUser) => controlGroupUser?.controlGroup === 'dynamic')) {
      dataLayerObject.event = 'smtz_dynamicPricing_enabled';
      const gtmObject = {
        collable: 'dataLayer.push',
        args: [dataLayerObject],
      };
      sendGoogleGtmDataLayer(gtmObject);
      dataLayerObject.event = 'smtz_view_item_list';
    }
  }


  // check if we are in the iframe send the datalayer object to the orgnizer
  if (inIframe() && ReferrerModule.gtmId) {
    const gtmObject = {
      collable: 'dataLayer.push',
      args: [dataLayerObject],
    };
    sendGoogleGtmDataLayer(gtmObject);
  } else {
    // GTM for Facebook tracking
    Vue.gtm.trackEvent({
    event: 'view_item_list',
    action: 'View Item List',
    eventLabel: trackingData.name,
    eventCategory: 'ecommerce', // default
    trackingData: [trackingData],
    });
    setOrganisertracking(trackingData);
  }
  // Send datalayer object to the iframe in case we are in discover and orgnizer is using GTM
  if (productData.gtmId && !inIframe()) {
    generateEmbedIframe(productData.gtmId);
    const gtmObject = {
      collable: 'dataLayer.push',
      args: [dataLayerObject],
    };
    const iframe = document.getElementById('smeetz-tracking-iframe');
    if (iframe) {
      iframe.onload = () => {
        sendDatalayerIframe(gtmObject);
      };
    }
  }

  // GA4 or UA for orginizer when user uses Smeetz.com to book.
  if (trackingData.googleTrackingId && !inIframe()) {
    gtag('event', 'view_item_list', {
      ...gaData,
      send_to: trackingData.googleTrackingId,
      items,
    });
  }

  //// AddToCart Fb pixel server tracking
  const customData = {
    ...gaData,
    items,
  };
  setFbPixelDataAndCall(EventName.viewContent, customData)
  .catch((error) => {throw error; });

  // We send data to the smeetz property using send_to if the main property of the smeetz.com domain is
  // the same as the organizer property.
  // The properties of the smeetz domain and organizer are set to be the same
  // so that organizers can receive data related to their campaings.
  if (trackingData.googleTrackingId && trackingData.googleTrackingId === window.SM_GA4_TRACKING_ID) {
    gtag('event', 'view_item_list', {
      ...gaData,
      send_to: process.env.VUE_APP_GA_W_TRACKING_ID,
      items,
    });
  } else if (trackingData.googleTrackingId && !ReferrerModule.gtmId) {
    gtag('event', 'view_item_list', {
      ...gaData,
      items,
    });
  }

  // check if we are in an iframe and that we have google analytics id of organizer
  if (inIframe() && trackingData.googleTrackingId) {
    // object that we send to google analytics on widget (organizer site)
    const dataObj = {
      collable: 'gtag',
      args: ['event', 'view_item_list', { ...gaData, items }],
    };
    sendGoogleAnalyticsData(dataObj);
  }
  // Log the tracking event
  logInfo('view_item_list', {dataLayerObject});
  // Send view Item list to Mixpanel
  Mixpanel.track(EVENTS.ViewItemsList, {});
}

// We send this event when user remove frm cart
export async function bookingFlowRemoveFromCart(
  productData: IProduct, position: number = 1, list: string, smtzTrackingData: ISmtzTrackingData) {
  const product = new Product(productData);
  const trackingData = product.toTrackingData(position, list);

  const items: Iga4Item[] = [
    {
      id: String(smtzTrackingData.catName),
      name: trackingData.name,
      currency: trackingData.currency,
      brand: trackingData.brand,
      price: smtzTrackingData.prVal,
      quantity: smtzTrackingData.qt || 1,
    },
  ];
  const gaData = {
    currency: trackingData?.currency,
    value: smtzTrackingData.prVal,
  };
  // datalyer GTM object
  const dataLayerObject: ISmtzGTM = {
    event: 'smtz_remove_from_cart',
    smeetzLabel:  trackingData.name,
    smeetzCategory: 'ecommerce',
    smeetzAction: 'Remove From Cart',
    smeetzData: items,
  };

  // check if we are in the iframe send the datalayer object to the orgnizer
  if (inIframe() && ReferrerModule.gtmId) {
    const gtmObject = {
      collable: 'dataLayer.push',
      args: [dataLayerObject],
    };
    sendGoogleGtmDataLayer(gtmObject);
  } else {
    // GTM for Facebook tracking
    Vue.gtm.trackEvent({
    event: 'remove_from_cart',
    action: 'remove from cart',
    eventLabel: trackingData.name,
    eventCategory: 'ecommerce', // default
    trackingData: [trackingData],
    });
    setOrganisertracking(trackingData);
  }
  // Send datalayer object to the iframe in case we are in discover and orgnizer is using GTM
  if (productData.gtmId && !inIframe()) {
    generateEmbedIframe(productData.gtmId);
    const gtmObject = {
      collable: 'dataLayer.push',
      args: [dataLayerObject],
    };
    const iframe = document.getElementById('smeetz-tracking-iframe');
    if (iframe) {
      iframe.onload = () => {
        sendDatalayerIframe(gtmObject);
      };
    }
  }

  // GA4 or UA for orginizer when user uses Smeetz.com to book.
  if (trackingData.googleTrackingId && !inIframe()) {
    gtag('event', 'remove_from_cart', {
      ...gaData,
      send_to: trackingData.googleTrackingId,
      items,
    });
  }
  // Log tracking event
  logInfo('remove_from_cart', { dataLayerObject });
}
// This event is sent when we open the Basket
export async function bookingFlowViewCart(data: ITrackingData[]) {
    // retrive the organizer gtm Id from the organizer infos
    const gtmId: string | null | undefined = data && data.length > 0 && data[0]?.gtmId ? data[0].gtmId : '';

    let total = 0;
    const items: Iga4Item[] = [];
    for (const d of data) {
      total += (d.quantity as number) * (d.price as number);
      items.push({
        id: String(d.id),
        name: d.category,
        currency: d.currency,
        brand: d.brand,
        category: d.category,
        price: d.price,
        quantity: d.quantity,
      });
    }
    const gaData = {
      currency: data[0]?.currency,
      value: total,
    };
    // datalyer GTM object
    const dataLayerObject: ISmtzGTM = {
      event: 'smtz_view_cart',
      smeetzLabel: data[0] ? data[0].name : '',
      smeetzCategory: 'ecommerce',
      smeetzAction: 'View Cart',
      smeetzData: items,
    };

    // check if we are in the iframe send the datalayer object to the orgnizer
    if (inIframe() && ReferrerModule.gtmId) {
      const gtmObject = {
        collable: 'dataLayer.push',
        args: [dataLayerObject],
      };
      sendGoogleGtmDataLayer(gtmObject);
    } else {
      // GTM for Facebook tracking
      Vue.gtm.trackEvent({
      event: 'view_cart',
      action: 'View Cart',
      eventLabel: data[0] ? data[0].name : '',
      eventCategory: 'ecommerce',
      trackingData: data,
      value: total,
      });
      setOrganisertracking(data);
    }
    // Send datalayer object to the iframe in case we are in discover and orgnizer is using GTM
    if (gtmId && !inIframe()) {
      const gtmObject = {
        collable: 'dataLayer.push',
        args: [dataLayerObject],
      };
      sendDatalayerIframe(gtmObject);
    }

    const orgGaIds = orgGoogleAnalyticIds(data);
    // GA4 or UA for orginizer when users use smeetz.com to book
    if (orgGaIds && !inIframe()) {
      gtag('event', 'view_cart', {
        ...gaData,
        send_to: orgGaIds,
        items,
      });
    }

    // We send data to the smeetz property using send_to if the main property of the smeetz.com domain is
    // the same as the organizer property.
    // The properties of the smeetz domain and organizer are set to be the same
    // so that organizers can receive data related to their campaings.
    if (orgGaIds && orgGaIds === window.SM_GA4_TRACKING_ID ||
      orgGaIds?.length !== 0 && orgGaIds?.includes(window.SM_GA4_TRACKING_ID)
    ) {
      gtag('event', 'view_cart', {
        ...gaData,
        send_to: process.env.VUE_APP_GA_W_TRACKING_ID,
        items,
      });
    } else if (orgGaIds && !ReferrerModule.gtmId) {
      gtag('event', 'view_cart', {
        ...gaData,
        items,
      });
    }

    // check if we are in an iframe and that we have google analytics id of organizer
    if (inIframe() && orgGaIds) {
      // object that we send to google analytics on widget (organizer site)
      const dataObj = {
        collable: 'gtag',
        args: ['event', 'view_cart', { ...gaData, items }],
      };
      sendGoogleAnalyticsData(dataObj);
    }
    // Log tracking event
    logInfo('view_cart', { dataLayerObject });
}

/**
 * Starts booking flow start tracking.
 * It happens on Homepage, discover, product-list, product.
 * Note that on the product page, since this product is alone, we give
 * it a position of 1
 */
export async function bookingFlowStartTracking(
  productData: IProduct, position: number = 1, list: string, smtzTrackingData: ISmtzTrackingData) {
  const product = new Product(productData);
  const trackingData = product.toTrackingData(position, list);
  const discount = NBookingModule.bookingRes?.bookingRecap.discount;
  const transactionId = NBookingModule.bookingRes?.bookingRef;
  // We use trackingData to track the product info
  // We use smtzTrackingData to track the ticket info and the price info
  const items: Iga4Item[] = [
    {
      id: String(smtzTrackingData.catId),
      name: smtzTrackingData.catName,
      currency: trackingData.currency,
      brand: trackingData.brand,
      price: smtzTrackingData.prVal,
      quantity: smtzTrackingData.qt || 1,
      discount,
    },
  ];
  const gaData = {
    currency: trackingData?.currency,
    value: smtzTrackingData.prVal,
  };
  // datalyer GTM object
  const dataLayerObject: ISmtzGTM = {
    event: 'smtz_add_to_cart',
    smeetzLabel:  trackingData.name,
    smeetzCategory: 'ecommerce',
    smeetzAction: 'Add to cart',
    transactionId,
    smeetzData: items,
  };

  // check if we are in the iframe send the datalayer object to the orgnizer
  if (inIframe() && ReferrerModule.gtmId) {
    const gtmObject = {
      collable: 'dataLayer.push',
      args: [dataLayerObject],
    };
    sendGoogleGtmDataLayer(gtmObject);
  } else {
    // GTM for Facebook tracking
    Vue.gtm.trackEvent({
    event: 'add_to_cart_smtz',
    action: 'Add to cart',
    eventLabel: trackingData.name,
    eventCategory: 'ecommerce', // default
    trackingData: [trackingData],
    value: smtzTrackingData.prVal || 0,
    });
    setOrganisertracking(trackingData);
  }
  // Send datalayer object to the iframe in case we are in discover and orgnizer is using GTM
  if (productData.gtmId && !inIframe()) {
    generateEmbedIframe(productData.gtmId);
    const gtmObject = {
      collable: 'dataLayer.push',
      args: [dataLayerObject],
    };
    const iframe = document.getElementById('smeetz-tracking-iframe');
    if (iframe) {
      iframe.onload = () => {
        sendDatalayerIframe(gtmObject);
      };
    }
  }

  // GA4 or UA for orginizer when user uses Smeetz.com to book.
  if (trackingData.googleTrackingId && !inIframe()) {
    gtag('event', 'add_to_cart', {
      ...gaData,
      send_to: trackingData.googleTrackingId,
      items,
    });
  }

  //// AddToCart Fb pixel server tracking
  const customData = {
    ...gaData,
    items,
  };
  setFbPixelDataAndCall(EventName.addToCart, customData)
  .catch((error) => {throw error; });

  // GA4 for smeetz
  // ***********Commented for test*****************
  // gtag('event', 'add_to_cart', {
  //   ...gaData,
  //   items,
  // });
  // We send data to the smeetz property using send_to if the main property of the smeetz.com domain is
  // the same as the organizer property.
  // The properties of the smeetz domain and organizer are set to be the same
  // so that organizers can receive data related to their campaings.
  if (trackingData.googleTrackingId && trackingData.googleTrackingId === window.SM_GA4_TRACKING_ID) {
    gtag('event', 'add_to_cart', {
      ...gaData,
      send_to: process.env.VUE_APP_GA_W_TRACKING_ID,
      items,
    });
  } else if (trackingData.googleTrackingId && !ReferrerModule.gtmId) {
    gtag('event', 'add_to_cart', {
      ...gaData,
      items,
    });
  }

  // check if we are in an iframe and that we have google analytics id of organizer
  if (inIframe() && trackingData.googleTrackingId) {
    // object that we send to google analytics on widget (organizer site)
    const dataObj = {
      collable: 'gtag',
      args: ['event', 'add_to_cart', { ...gaData, items }],
    };
    sendGoogleAnalyticsData(dataObj);
  }
  // Log tracking event
  logInfo('add_to_cart', { dataLayerObject });
  Mixpanel.track(EVENTS.AddToCart, {});
}

export async function bookingFlowInitCheckoutTracking(data: ITrackingData[]) {
  // retrive the organizer gtm Id from the organizer infos
  const gtmId: string | null | undefined =  data && data[0] && data[0].gtmId ? data[0].gtmId : '';
  const discount = NBookingModule.bookingRes?.bookingRecap.discount;
  const transactionId = NBookingModule.bookingRes?.bookingRef;

  let total = 0;
  const items: Iga4Item[] = [];
  for (const d of data) {
    total += (d.quantity as number) * (d.price as number);
    items.push({
      id: String(d.id),
      name: d.category,
      currency: d.currency,
      brand: d.brand,
      category: d.category,
      price: d.price,
      quantity: d.quantity,
      discount,
    });
  }

  const gaData = {
    currency: data[0].currency,
    value: total,
  };
  // datalyer GTM object
  const dataLayerObject: ISmtzGTM = {
    event: 'smtz_begin_checkout',
    smeetzLabel: data[0] ? data[0].name : '',
    smeetzCategory: 'ecommerce',
    smeetzAction: 'Begin Checkout',
    transactionId,
    smeetzData: items,
  };

  // check if we are in the iframe send the datalayer object to the orgnizer
  if (inIframe() && ReferrerModule.gtmId) {
    const gtmObject = {
      collable: 'dataLayer.push',
      args: [dataLayerObject],
    };
    sendGoogleGtmDataLayer(gtmObject);
  } else {
    // GTM for Facebook tracking
    Vue.gtm.trackEvent({
    event: 'begin_checkout_smtz',
    action: 'Begin Checkout',
    eventLabel: data[0] ? data[0].name : '',
    eventCategory: 'ecommerce',
    trackingData: data,
    value: total,
    });
    setOrganisertracking(data);
  }

  // Send datalayer object to the iframe in case we are in discover and orgnizer is using GTM
  if (gtmId && !inIframe()) {
    const gtmObject = {
      collable: 'dataLayer.push',
      args: [dataLayerObject],
    };
    sendDatalayerIframe(gtmObject);
  }

  const orgGaIds = orgGoogleAnalyticIds(data);
  // GA4 or UA for orginizer when users use smeetz.com to book
  if (orgGaIds && !inIframe()) {
    gtag('event', 'begin_checkout', {
      ...gaData,
      send_to: orgGaIds,
      items,
    });
  }

  //// InitiateCheckout Fb pixel server tracking
  const customData = {
    ...gaData,
    items,
  };
  setFbPixelDataAndCall(EventName.initiateCheckout, customData)
  .catch((error) => {throw error; });


  // GA4 for smeetz
  // ***********Commented for test*****************
  // gtag('event', 'begin_checkout', {
  //   ...gaData,
  //   items,
  // });

  // We send data to the smeetz property using send_to if the main property of the smeetz.com domain is
  // the same as the organizer property.
  // The properties of the smeetz domain and organizer are set to be the same
  // so that organizers can receive data related to their campaings.
  if (orgGaIds && orgGaIds === window.SM_GA4_TRACKING_ID ||
    orgGaIds?.length !== 0 && orgGaIds?.includes(window.SM_GA4_TRACKING_ID)
  ) {
    gtag('event', 'begin_checkout', {
      ...gaData,
      send_to: process.env.VUE_APP_GA_W_TRACKING_ID,
      items,
    });
  } else if (orgGaIds && !ReferrerModule.gtmId) {
    gtag('event', 'begin_checkout', {
      ...gaData,
      items,
    });
  }


  // check if we are in an iframe and that we have google analytics id of organizer
  if (inIframe() && orgGaIds) {
    // object that we send to google analytics on widget (organizer site)
    const dataObj = {
      collable: 'gtag',
      args: ['event', 'begin_checkout', { ...gaData, items }],
    };
    sendGoogleAnalyticsData(dataObj);
  }
  // Log tracking event
  logInfo('begin_checkout', { dataLayerObject });
}
export async function bookingFlowCheckoutProgress(data: ITrackingData[]) {
 // retrive the organizer gtm Id from the organizer infos
 const gtmId: string | null | undefined =  data && data[0] && data[0].gtmId ? data[0].gtmId : '';
 let total = 0;
 const items: Iga4Item[] = [];
 const discount = NBookingModule.bookingRes?.bookingRecap.discount;
 const transactionId = NBookingModule.bookingRes?.bookingRef;


 for (const d of data) {
   total += (d.quantity as number) * (d.price as number);
   items.push({
     id: String(d.id),
     name: d.category,
     currency: d.currency,
     brand: d.brand,
     category: d.category,
     price: d.price,
     quantity: d.quantity || 1,
     discount,
   });
 }
 const gaData = {
   currency: data[0]?.currency,
   value: total,
 };
 const dataLayerObject: ISmtzGTM = {
   event: 'smtz_checkout_progress',
   smeetzLabel: data[0] ? data[0].name : '',
   smeetzCategory: 'ecommerce',
   smeetzAction: 'Checkout progress',
   transactionId,
   smeetzData: items,
 };



 // check if we are in the iframe, send the datalayer object to the orgnizer
 if (inIframe() && ReferrerModule.gtmId) {
   const gtmObject = {
     collable: 'dataLayer.push',
     args: [dataLayerObject],
   };
   // send dataLayer with the checkout_progress event
   sendGoogleGtmDataLayer(gtmObject);
 } else {
   // GTM for Facebook tracking
   Vue.gtm.trackEvent({
   event: 'checkout_progress_smtz',
   action: 'Checkout progress',
   eventLabel: data[0] ? data[0].name : '',
   eventCategory: 'ecommerce',
   trackingData: data,
   value: total,
   });
   setOrganisertracking(data);
 }

 // Send datalayer object to the iframe in case we are in discover and orgnizer is using GTM
 if (gtmId && !inIframe()) {
   generateEmbedIframe(gtmId);
   const gtmObject = {
     collable: 'dataLayer.push',
     args: [dataLayerObject],
   };
   sendDatalayerIframe(gtmObject);
 }

 const orgGaIds = orgGoogleAnalyticIds(data);

 // GA4 or UA for orginizer when users use Smeetz.com to book.
 if (orgGaIds && !inIframe()) {
   gtag('event', 'checkout_progress', {
     ...gaData,
     send_to: orgGaIds,
     items,
   });
 }


 //// AddPaymentInfo Fb pixel server tracking
 const customData = {
   ...gaData,
   items,
 };
 setFbPixelDataAndCall(EventName.checkoutPogress, customData)
 .catch((error) => {throw error; });

 // We send data to the smeetz property using send_to if the main property of the smeetz.com domain is
 // the same as the organizer property.
 // The properties of the smeetz domain and organizer are set to be the same
 // so that organizers can receive data related to their campaings.
 if (orgGaIds && orgGaIds === window.SM_GA4_TRACKING_ID ||
   orgGaIds?.length !== 0 && orgGaIds?.includes(window.SM_GA4_TRACKING_ID)
 ) {
   gtag('event', 'checkout_progress', {
     ...gaData,
     send_to: process.env.VUE_APP_GA_W_TRACKING_ID,
     items,
   });
 } else if (orgGaIds && !ReferrerModule.gtmId) {
   gtag('event', 'checkout_progress', {
     ...gaData,
     items,
   });
 }


 // check if we are in an iframe and that we have google analytics id of organizer
 if (inIframe() && orgGaIds) {
   // object that we send to google analytics on widget (organizer site)
   const dataObj = {
     collable: 'gtag',
     args: ['event', 'checkout_progress', { ...gaData, items }],
   };
   sendGoogleAnalyticsData(dataObj);
 }
 // Log tracking event
 logInfo('checkout_progress', { dataLayerObject });
}
export async function bookingFlowPaymentTracking(data: ITrackingData[]) {
  // retrive the organizer gtm Id from the organizer infos
  const gtmId: string | null | undefined =  data && data[0] && data[0].gtmId ? data[0].gtmId : '';
  let total = 0;
  const items: Iga4Item[] = [];
  const discount = NBookingModule.bookingRes?.bookingRecap.discount;
  const transactionId = NBookingModule.bookingRes?.bookingRef;


  for (const d of data) {
    total += (d.quantity as number) * (d.price as number);
    items.push({
      id: String(d.id),
      name: d.category, // In tracking data we send the name of ticket instead of product
      currency: d.currency,
      brand: d.brand,
      category: d.category,
      price: d.price,
      quantity: d.quantity || 1,
      discount,
    });
  }
  const gaData = {
    currency: data[0]?.currency,
    value: total,
  };
  const dataLayerObject: ISmtzGTM = {
    event: 'smtz_payment_info',
    smeetzLabel: data[0] ? data[0].name : '',
    smeetzCategory: 'ecommerce',
    smeetzAction: 'Add payment Info',
    transactionId,
    smeetzData: items,
  };



  // check if we are in the iframe, send the datalayer object to the orgnizer
  if (inIframe() && ReferrerModule.gtmId) {
    const gtmObject = {
      collable: 'dataLayer.push',
      args: [dataLayerObject],
    };
    // send dataLayer with the add_payment_info event
    sendGoogleGtmDataLayer(gtmObject);
  } else {
    // GTM for Facebook tracking
    Vue.gtm.trackEvent({
    event: 'add_payment_info',
    action: 'add payment info',
    eventLabel: data[0] ? data[0].name : '',
    eventCategory: 'ecommerce',
    trackingData: data,
    value: total,
    });
    setOrganisertracking(data);
  }

  // Send datalayer object to the iframe in case we are in discover and orgnizer is using GTM
  if (gtmId && !inIframe()) {
    generateEmbedIframe(gtmId);
    const gtmObject = {
      collable: 'dataLayer.push',
      args: [dataLayerObject],
    };
    sendDatalayerIframe(gtmObject);
  }

  const orgGaIds = orgGoogleAnalyticIds(data);

  // GA4 or UA for orginizer when users use Smeetz.com to book.
  if (orgGaIds && !inIframe()) {
    gtag('event', 'add_payment_info', {
      ...gaData,
      send_to: orgGaIds,
      items,
    });
  }


  //// AddPaymentInfo Fb pixel server tracking
  const customData = {
    ...gaData,
    items,
  };
  setFbPixelDataAndCall(EventName.addPaymentInfo, customData)
  .catch((error) => {throw error; });

  // GA4 for smeetz
  // ***********Commented for test*****************
  // gtag('event', 'add_payment_info', {
  //   ...gaData,
  //   items,
  // });

  // We send data to the smeetz property using send_to if the main property of the smeetz.com domain is
  // the same as the organizer property.
  // The properties of the smeetz domain and organizer are set to be the same
  // so that organizers can receive data related to their campaings.
  if (orgGaIds && orgGaIds === window.SM_GA4_TRACKING_ID ||
    orgGaIds?.length !== 0 && orgGaIds?.includes(window.SM_GA4_TRACKING_ID)
  ) {
    gtag('event', 'add_payment_info', {
      ...gaData,
      send_to: process.env.VUE_APP_GA_W_TRACKING_ID,
      items,
    });
  } else if (orgGaIds && !ReferrerModule.gtmId) {
    gtag('event', 'add_payment_info', {
      ...gaData,
      items,
    });
  }


  // check if we are in an iframe and that we have google analytics id of organizer
  if (inIframe() && orgGaIds) {
    // object that we send to google analytics on widget (organizer site)
    const dataObj = {
      collable: 'gtag',
      args: ['event', 'add_payment_info', { ...gaData, items }],
    };
    sendGoogleAnalyticsData(dataObj);
  }
  // Log tracking event
  logInfo('payment_info', { dataLayerObject });
}

export async function bookingFlowCompleteTracking(
  bookingInfo: IServerBooking, bookingId: string, productObj?: IProduct) {
  const data: ITrackingData[] = [];
  const items: Iga4Item[] = [];
  const recap = bookingInfo.bookingRecap;
  const product = bookingInfo.product;
  // retrive the organizer gtm Id from the organizer infos
  const gtmId: string | undefined | null = productObj?.gtmId;
  let total = 0;
  const discount = NBookingModule.bookingRes?.bookingRecap.discount;
  const transactionId = NBookingModule.bookingRes?.bookingRef;

  if (!bookingInfo.bookingRecap.categories) {
    return;
  }
  //  name is set based on productObj till backend adds nameWithoutTranslation to bookingInfo.product
  //  id should be in bookingInfo.product because its required for GA
  for (const category of bookingInfo.bookingRecap.categories) {
    const dataObj: ITrackingData = {
      name: productObj ? productObj.nameWithoutTranslation : product.name,
      brand: (product.organiser && product.organiser.name) || '',
      currency: bookingInfo.bookingRecap.currency,
      price: category.priceValue / Number(category.quantity),
      category: category.categoryName,
      quantity: Number(category.quantity),
    };

    if (productObj) {
      dataObj.id = productObj.id;
    }

    if (productObj && (OrganizerModule.fbPixelId || productObj.fbPixelId)) {
      dataObj.fbPixelId = (OrganizerModule.fbPixelId as string) || productObj.fbPixelId;
    }

    if (productObj && (OrganizerModule.gaId || productObj.googleTrackingId)) {
      dataObj.googleTrackingId = OrganizerModule.gaId || productObj.googleTrackingId;
    }

    if (productObj && (OrganizerModule.gtmId || productObj.gtmId)) {
      dataObj.gtmId = OrganizerModule.gtmId || productObj.gtmId;
    }

    // if (ReferrerModule.gtmId) {
    //   dataObj.gtmId = ReferrerModule.gtmId;
    // }

    // handle google analytics stuff
    if (productObj && productObj.organiser) {
      const { conversionId, conversionLabel } = productObj.organiser;
      if (conversionId && conversionLabel) {
        dataObj.googleAdsConversionId = conversionId;
        dataObj.googleAdsConversionLabel = conversionLabel;
      }
    }

    data.push(dataObj);
  }

  const transactionData: ITransactionInfo = {
    transactionId: bookingId,
    affiliation: bookingInfo.bookingReferrer,
    revenue: recap.total,
    tax: recap.paymentFees,
  };
  const gaData = {
    currency: recap?.currency,
    transaction_id: transactionData.transactionId,
    value: transactionData.revenue,
    affiliation: transactionData.affiliation,
    coupon: transactionData.coupon,
    tax: transactionData.tax,
  };
  // We can add discount but first it has to be added to data and ITrackingData interface from booking recap.
  data.forEach((d) => {
    total += (d.price as number) * (d.quantity as number);
    items.push({
      id: String(d.id),
      name: d.category,
      currency: d.currency,
      brand: d.brand,
      price: d.price,
      quantity: d.quantity || 1,
      category: d.category,
      discount,
    });
  });
  // datalyer GTM object
  const dataLayerObject: ISmtzGTM = {
    event: 'smtz_purchase',
    smeetzLabel: data[0] ? data[0].name : '',
    smeetzCategory: 'ecommerce',
    smeetzAction: 'Purchase',
    smeetzData: items,
    transactionId,
    value: total,
  };


  // check if we are in the iframe send the datalayer object to the orgnizer
  if (inIframe() && ReferrerModule.gtmId) {
    const gtmObject = {
      collable: 'dataLayer.push',
      args: [dataLayerObject],
    };
    sendGoogleGtmDataLayer(gtmObject);
  } else {
    // GTM for Facebook tracking
    Vue.gtm.trackEvent({
    event: 'purchase_smtz',
    action: 'Purchase',
    eventLabel: product.name,
    eventCategory: 'ecommerce',
    trackingData: data,
    value: recap.total,
    transactionInfo: transactionData,
  });
  }

  // Send datalayer object to the iframe in case we are in discover and orgnizer is using GTM
  if (gtmId && !inIframe()) {
    generateEmbedIframe(gtmId);
    const gtmObject = {
      collable: 'dataLayer.push',
      args: [dataLayerObject],
    };
    const iframe = document.getElementById('smeetz-tracking-iframe');
    if (iframe) {
      iframe.onload = () => {
        sendDatalayerIframe(gtmObject);
      };
    }
  }

  const orgGaIds = orgGoogleAnalyticIds(data);

  // GA4 or UA for orginizer when user books from Smeetz.com
  if (orgGaIds && !inIframe()) {
    gtag('event', 'purchase', {
      ...gaData,
      send_to: orgGaIds,
      items,
    });
  }

  //// Purchase Fb pixel server tracking
  const customData = {
    ...gaData,
    items,
  };
  setFbPixelDataAndCall(EventName.purchase, customData)
  .catch((error) => {throw error; });

  // GA4 for smeetz
  // ***********Commented for test*****************
  // gtag('event', 'purchase', {
  //   ...gaData,
  //   items,
  // });

  // We send data to the smeetz property using send_to if the main property of the smeetz.com domain is
  // the same as the organizer property.
  // The properties of the smeetz domain and organizer are set to be the same
  // so that organizers can receive data related to their campaings.
  if (orgGaIds && orgGaIds === window.SM_GA4_TRACKING_ID ||
    orgGaIds?.length !== 0 && orgGaIds?.includes(window.SM_GA4_TRACKING_ID)
  ) {
    gtag('event', 'purchase', {
      ...gaData,
      send_to: process.env.VUE_APP_GA_W_TRACKING_ID,
      items,
    });
  } else if (orgGaIds && !ReferrerModule.gtmId) {
    gtag('event', 'purchase', {
      ...gaData,
      items,
    });
  }


  // check if we are in an iframe and that we have google analytics id of organizer
  if (inIframe() && orgGaIds) {
    // object that we send to google analytics on widget (organizer site)
    const gaDataObj = {
      collable: 'gtag',
      args: ['event', 'purchase', { ...gaData, items }],
    };
    sendGoogleAnalyticsData(gaDataObj);
  }
  // Log purchase event
  logInfo(`purchase`, { dataLayerObject });
  return;
}

export async function trackPageView(route: Route) {
  // Vue.gtm.trackView('', route.fullPath);
  gtag('event', 'page_view', {
    page_location: route.fullPath,
  });

  //// Page-view Fb pixel server tracking
  setFbPixelDataAndCall(EventName.pageView, undefined)
  .catch((error) => {throw error; });

}

export function userIdentityTracking(user: IUser | any) {

  // supply firstname and lastname only if present.
  const userObj: any = { email: user.email };

  if (user.firstname) {
    userObj.firstname = user.firstname;
  }

  if (user.lastname) {
    userObj.lastname = user.lastname;
  }
  /* Send User identity with the dataLayer to the organizer if we are in the iframe
     The data will be sent only if the user accept the marketing optin
  */
  const dataLayerObject = {
    event: 'smtz_user_identity',
    smeetzLabel: user.email,
    smeetzCategory: 'user_identity',
    smeetzAction: 'Identify user',
    smeetzData: user,
  };
  if (inIframe() && ReferrerModule.gtmId) {
    const gtmObject = {
      collable: 'dataLayer.push',
      args: [dataLayerObject],
    };
    sendGoogleGtmDataLayer(gtmObject);
  } else {
    Vue.gtm.trackEvent({
      event: 'user_identity',
      action: 'Identify user',
      eventLabel: 'Identify user',
      user: userObj,
  });
  }
  // Log tracking event
  logInfo('user_identity', { dataLayerObject });
}

export async function productPageViewTracking(product: IProduct, route: Route) {

  const {
    id, name, currency,
    overview, organiser, fbPixelId,
    googleTrackingId,
  } = product;
  const price = overview.rangePrice.fromPrice;
  const organiserName = organiser && organiser.name;
  const brand = organiserName || '';

  if (!window.fbq) {
    debug('Facebook wasn\'t loaded');
    return;
  }

  // Facebook data
  const fbData = {
    content_ids: id,
    content_name: name,
    content_brand: brand,
    content_type: 'product',
    value: price,
    currency,
  };

  fbq('trackSingle', window.SM_FB_PIXEL_ID, 'ViewContent', fbData);
  // do the same for client pixel
  if (fbPixelId) {
    fbq('init', fbPixelId);
    fbq('trackSingle', fbPixelId, 'ViewContent', fbData);
  }

  //// ViewContent Fb pixel server tracking
  const customData = {
    currency,
    value: price,
    content_ids: id,
    content_type: 'product',
    content_name: name,
    content_brand: brand,
  };
  setFbPixelDataAndCall(EventName.viewContent, customData)
  .catch((error) => {throw error; });

  const gtmData: any = {
    event: 'content_view',
    ecommerce: {
      detail: {
        products: [
          {
            name,
            id,
            price,
            brand,
            currency,
          },
        ],
      },
    },
  };

  if (googleTrackingId) {
    gtmData.gaId = googleTrackingId;
    gtag('event', 'page_view', {
      page_location: route.fullPath,
      send_to: googleTrackingId,
    });
  }

  // Vue.gtm.trackEvent(gtmData);
}

// store google analytics tracking decoration locally
export function storeGADecoration(route: Route) {
  const { _ga } = route.query;
  if (_ga) {
    try {
      window.localStorage.setItem('smeetzga', (_ga as string));
    } catch (err) {
      debug('Error setting google ga');

      // defer throw so that it doesn't interrupt the calling stack
      setTimeout(() => {
        throw err;
      }, 0);
    }
  }
}

// decorate url for ga based on the presence of _ga in localstorage
export function decorateURLForGA(route: Route, router: VueRouter) {
  // set _ga query param if was set
  // May change in future implementations
  try {
    // tslint:disable-next-line
    const _ga = localStorage.getItem('smeetzga');
    if (_ga) {
      router.replace(
        (Object.assign({}, route, { query: Object.assign({ _ga }, route.query) }) as any),
      );
      window.localStorage.removeItem('smeetzga');
    }
  } catch (err) {
    debug('Error setting _ga query parameter');
    setTimeout(() => {
      throw err;
    }, 0);
  }
}

function setOrganisertracking(data: ITrackingData | ITrackingData[]) {
  const { fbPixelId, gaId } = OrganizerModule;

  if (Array.isArray(data)) {
    for (const d of data) {
      setOrganisertracking(d);
    }
    return;
  }

  const singleTrackingData = data as ITrackingData;
  if (fbPixelId) {
    singleTrackingData.fbPixelId = fbPixelId;
  }
  if (gaId) {
    singleTrackingData.googleTrackingId = gaId;
  }
}

export async function setFbPixelDataAndCall(eventName: string, customData: any) {
  // Group ip to be sent to back-end to get accessToken
  let groupId = null;
  const product: IProduct | null = ProductModule.product;
  const organizer = (product as IProduct)?.organiser;
  const organiserSlug = organizer?.slugName;
  if (organiserSlug) {
    await OrganizerModule.getOrganizerInfo(organiserSlug);
    groupId = OrganizerModule.id;
  }

  // Can be found in business.facebook in the Test events tab
  const eventTestCode = ReferrerModule.eventTestCode ? ReferrerModule.eventTestCode : undefined;
  const fbp = getFbp() || '_fbp';
  const fbc = getFbc();
  // Get user's email
  const bookingRes = NBookingModule.bookingRes as IPreBookingRes;
  const hashedEmail = bookingRes && bookingRes.email != null ? await sha256(bookingRes.email) :
    bookingRes && bookingRes.bookingEmail ? await sha256(bookingRes.bookingEmail) : null;
  const firstName = bookingRes && bookingRes.first_name != null ?
    await sha256(bookingRes.first_name) : undefined;
  const lastName = bookingRes && bookingRes.last_name != null ?
    await sha256(bookingRes.last_name) : undefined;
  const time = dayjs();

  const data = {
    data: [
      {
        event_name: eventName,
        event_time: time,
        user_data: {
          em: hashedEmail != null ? [hashedEmail] : undefined,
          client_user_agent: navigator.userAgent,
          fbp,
          fbc,
          fn: firstName,
          ln: lastName,
        },
        custom_data: customData,
        event_source_url: window.location.href,
        action_source: 'website',
      },
    ],
    test_event_code: eventTestCode,
  };

  await fbPixelServerTracking(data, fbp, groupId);
}

export async function fbPixelServerTracking(data: any, fbp: string, groupId: number |null) {
  debug('FBP = ', fbp);
  if (fbp === '_fbp') { return; }
  try {
    const trackingUrl = new URL(process.env.VUE_APP_TRACK_API);
    trackingUrl.pathname = `/pixel/${AppModule.env}/org/fb`;
    const result = await fetch(trackingUrl.href, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({data, groupId}),
    }).then((response) => response.json());
    debug('Facebook pixel server tracking service result', { result });
  } catch (err) {
    debug('Error tracking service');
    setTimeout(() => {
      throw err;
    }, 0);
  }
}

export async function sha256(message: string) {
  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(message);

  // hash the message
  const hashBuffer = await crypto.subtle?.digest('SHA-256', msgBuffer);

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // convert bytes to hex string
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
}


// Function to Track dynamic Pricing tickets
export async function trackDynamicPricingTickets(productIds: number[]) {
    // GET the list of all price Ids displayed in the widget
    const products = NBookingModule.prodsTickets;
    const priceIds: number[] = [];

    for (const product in products) {
      if (products.hasOwnProperty(product)) {
        for (const category of products[product]) {
          const prices = category.price;
          for (const p of prices) {
            priceIds.push(p.priceId);
          }
        }
      }
    }

    // Fetch all the prices that stratigic DP pricing applied to them
    // And the stragy have status 1
    const strategyPrices = await getStrategyPrices(priceIds);
    if (!(strategyPrices && strategyPrices.length > 0)) {
      return;
    }

    // If one of the stragy have a control Group value
    // It means that AB testing is enabled
    // we fetch the control group user info to check if the user blongs to
    // the normal/testing control group
    let isABTesting = false;
    let controlGroupUser: string = 'not enabled';
    for (const strategy of strategyPrices) {
      if (strategy) {
        const strategyInfo = strategy.strategyInfo;
        if (strategyInfo && strategyInfo.controlGroup) {
          isABTesting = true;
          break;
        }
      }
    }

    if (isABTesting) {
      const trackingIdFromLocalStorage = localStorage.getItem('smtz-tid');
      const trackingId = AppModule.trackId || String(trackingIdFromLocalStorage);
      const productId = productIds[0];
      const controlGroupUserList = await getControlGroupUserList(trackingId, String(productId));
      if ( controlGroupUserList && controlGroupUserList.some((c) => c?.controlGroup === 'dynamic')) {
        controlGroupUser = 'dynamic';
      } else {
        controlGroupUser = 'normal';
      }
    }

    const strategyApplied = strategyPrices.map((strategy) =>
      strategy && strategy.strategyInfo && strategy.strategyInfo.type ?
      StrategyType[strategy.strategyInfo.type] : StrategyType[1])
    .join(',');

    const trackingData = {
      strategy_name: strategyApplied,
      control_group: controlGroupUser,
    };

    NBookingModule.setDpTrackingData(trackingData);
}

// Cart list property on mixpanel will help us filter event according to certain categorie names
// prices or brands
export function buildMixpanelCartList() {
  const cartItem = [];
  // ticketsTracking is available after the booking is created and we have order recap
  if (NBookingModule.ticketsTracking && NBookingModule.ticketsTracking.length > 0) {
    const data: ITrackingData[] = NBookingModule.ticketsTracking;
    for (const d of data) {
      const item = {
        brand: d.brand,
        category: d.category,
        price: d.price,
        priceName: d.priceName,
        quantity: d.quantity,
      };
      cartItem.push(item);
    }
  }
  return cartItem;
}




























import { Vue, Component } from 'vue-property-decorator';
import dayjs from 'dayjs';
import { BookingModule, OrganizerModule, ProductModule } from '../../utils/storemodules';
import { GET_LOADING } from '../../store/modules/booking/constants';
import { IProduct } from '../../models/store/product';
import ProductCard from './components/ProductCard.vue';
import Nav from './components/Nav.vue';
import Footer from '@/components/booking/NewBookingFooter.vue';
import { inIframe } from '../../utils/iframe';
import { bookingFlowStartTracking } from '@/utils/tracking';
import { MetaInfo } from 'vue-meta';
import Debug from 'debug';
const debug = new Debug('smeetz:paymentmethods');

@Component({
  components: {
    ProductCard,
    Nav,
    Footer,
  },
})
export default class ProductList extends Vue {
  @BookingModule.Getter(GET_LOADING) private isLoading!: boolean;

  private products: IProduct[] = [];
  private query: any = {};
  private inIframeWidget: boolean = inIframe();

  private async mounted() {
    this.$gtm.trackView((this.$meta() as MetaInfo).title, this.$route.fullPath);
    const productIds: string = this.$route.params.ids as string;
    const productIdsArr: number[] = productIds.split(',').map((id) => Number(id));

    // get needed params (referrer, promoCode, cats, cat, startDate, endDate, listView)
    const {
      listView, referrer, promoCode, cats, cat, startDate, endDate, _ga,
      bookingId, bookingToken,
    } = this.$route.query;

    // If we are dealing with a single product. No need to fetch & move directly
    // to the booking flow (Unless we are explicitely asking for a listView)
    if ( productIdsArr.length && listView === 'true' ) {
      this.products = await ProductModule.fetchProducts(productIdsArr);

      // now try fetching organizer info
      const p = this.products.some((product) => product.organiser && product.organiser.slugName);
      debug('product with organizer?', p);
      if (p) {
        const organiserSlug = this.products.find((product) =>
        product.organiser && product.organiser.slugName)?.organiser?.slugName;
        debug('organiserSlug', organiserSlug);
        if (organiserSlug) {
          await OrganizerModule.getOrganizerInfo(organiserSlug);
        }
      }
    }

    // build query params
    // common query parameters are referrer, promoCode, cats, cat, listView
    const query: any = {};
    if (referrer) {
      query.referrer = referrer;
    }

    if (_ga) {
      query._ga = _ga;
    }

    if (promoCode) {
      query.promoCode = promoCode;
    }


    // set the hostname based on the referrer
    let hostname = inIframe() ? document.referrer : document.location.hostname;
    // if referrer query param is present, override hostname
    if (referrer) {
      hostname = referrer as string;
    }
    query.utm_source = hostname;

    // set page title to 'Smeetz - Booking'
    document.title = 'Smeetz - Booking';

    // if no list view, Move to booking flow.
    // Note: We are moving these query parameters to the booking flow.
    if (productIdsArr.length && (listView as string) !== 'true') {

      // these parameters are only useful in the context of a single product
      if (cats) {
        query.cats = cats;
      }

      if (cat) {
        query.cat = cat;
      }

      if (startDate) {
        query.startDate = startDate;
      }

      if (endDate) {
        query.endDate = endDate;
      }

      if (bookingId) {
        query.bookingId = bookingId;
      }

      if (bookingToken) {
        query.bookingToken = bookingToken;
      }
    // Leave commented until future tests
      // There is no need to get anything from the product anymore. Let the booking flow
      // handle all the rest.
    //   const product: IProduct = this.products[0];
    //   // const soldOut: boolean = product.booking_status === 1 ? false : true;
    // // TODO separate product list type from IProduct
    //   const soldOut: boolean = (product as any).booking_status === 1 ? false : true;

    //   // if soldout show sign. Love that design from brigi :)
    //   if (soldOut && ((product as any).statusName !== 'Draft')) {
    //     return;
    //   }

    //   // get from and to date
    //   const start = dayjs(product.overview.nextDateTime.startDateTime);
    //   const end = dayjs(product.overview.nextDateTime.endDateTime);

    //   // for booking flow 1 we are interested in only from date
    //   // TODO separate product list type from IProduct
    //   if ((product as any).booking_flow !== 0) {
    //     query.from = start.format('YYYY-MM-DD');
    //   }

    //   // TODO separate product list type from IProduct
    //   if ((product as any).booking_flow === 2) {
    //     query.to = end.format('YYYY-MM-DD');
    //   }

      // bookingFlowStartTracking(this.products[0], 0 /* Index */, 'Iframe');
      // this.$router.push({
      this.$router.replace({
        path: `/${this.$i18n.locale}/product/${productIds}/booking/placeholder_url`,
        query,
      });
      // this.$router.replace({
      //   path: `/${this.$i18n.locale}/product/${productIdsArr[0]}/booking/placeholder_url`,
      //   query,
      // });

      return;
    } // single product

    // otherwise render list
    this.query = query;
  }
}











































































import { Vue, Component, Prop } from 'vue-property-decorator';
import FacebookLogin from '@/components/auth/NewFacebookLogin.vue';
import GoogleLogin from '@/components/auth/NewGoogleAuth.vue';
import { hideFbLogin, hideGmailLogin, enableScrolling, disableScrolling } from '@/utils/helpers';
import { inIframe } from '@/utils/iframe';
import { OrganizerModule, NModalModule } from '@/utils/storemodules';
import { ModalStatus } from '@/models/definitions';

@Component({
  components: {
    FacebookLogin,
    GoogleLogin,
  },
})
export default class SocialsLoginPopup extends Vue {
  @Prop({ default: false }) public showLoginPopup!: boolean;
  private showPoup: boolean = this.showLoginPopup;
  private hideFbLogin = hideFbLogin();
  private hideGmailLogin = hideGmailLogin();

  private get hideSmeetzLogin(): boolean {
    // Only widgets can hide us
    if (!inIframe()) {
      return false;
    }

    const id = OrganizerModule.id;
    if (!id) {
      return !!null;
    }

    // Hide only for crocky trail on widgets
    return [18612, 18785].includes(id);
  }

  private get showKeycloakLogin(): boolean {
    const orgId = OrganizerModule.id;
    if (!orgId) {
      return !!null;
    }

    // Show keycloak auth for Locarno festival
    // return [16438, 19017].includes(orgId);
    const isKeycloakEnable: boolean = [16438, 16426, 19017, 19293].includes(
      orgId,
    );
    return isKeycloakEnable;
  }

  private close() {
    enableScrolling();
    this.showPoup = false;
    this.$emit('closePopup');
  }

  private openLoginDialog() {
    this.close();
    this.$emit('openLoginDialog');
  }

  private onSuccessLogin() {
    this.close();
    this.$emit('onSuccessLogin');
  }

  private authenticateKeycloakUser() {
    this.$emit('keycloakLoginEvent');
  }

  private mounted() {
    disableScrolling();
  }

  private beforeDestroy() {
    // Make sure to always enable back scrolling
    enableScrolling();
  }

  private goToRegisterForm() {
    NModalModule.setStatus(ModalStatus.NewRegistration);
  }
}

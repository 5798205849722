























import { Vue, Component, Prop } from 'vue-property-decorator';
import NewButton from '@/components/presentational/NewButton.vue';

@Component({
  components: {
    NewButton,
  },
})
export default class ExploreCard extends Vue {

}

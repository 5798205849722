










import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class ProgressBar extends Vue {
  @Prop({default: 80}) public progress!: number;
  private progressVal: number = 0;
  // private progress: number = 80;

  private mounted() {
    setTimeout(() => {
      this.progressVal = 100 - this.progress;
    }, 500);
  }
}




















































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import Nav from '@/components/NewNavigation.vue';
import Search from './components/Search.vue';
import CardsSlider from '@/components/presentational/CardsSlider.vue';
import Category from '@/components/presentational/Category.vue';
import ImageCardsSlider from './presentational/ImageCardSlider.vue';
import Card from '@/components/presentational/Card.vue';
import WeatherRecommend from './presentational/WeatherRecommend.vue';
import SliderNav, { INavElement } from '@/components/SliderNav.vue';
import { AppModule, NModalModule, RegionModule } from '@/utils/storemodules';
import OrganizerCard from './presentational/OrganizerCard.vue';
import BecomeProvider from './presentational/BecomeProvider.vue';
import ExploreCityCard from './presentational/ExploreCityCard.vue';
import Subscription from '@/components/presentational/Subscription.vue';
import Footer from '@/components/NewFooter.vue';
import config, { client } from '@/utils/searchConfig';
import { SearchDriver } from '@elastic/search-ui';
import NewButton from '@/components/presentational/NewButton.vue';
import moment from 'moment';
import { Meta } from '@/utils/decorators';
import { setLangForMeta } from '@/utils/helpers';
import dayjs from 'dayjs';
import { ModalStatus } from '@/models/definitions';
import Debug from 'debug';
const debug = new Debug('smeetz:priceGradient');


@Component({
  components: {
    Nav,
    Search,
    CardsSlider,
    Category,
    Card,
    SliderNav,
    ImageCardsSlider,
    WeatherRecommend,
    OrganizerCard,
    BecomeProvider,
    ExploreCityCard,
    Subscription,
    Footer,
    NewButton,
  },
})
export default class Region extends Vue {
  private driver: any;
  private isLoading: boolean = true;
  private nextDateCount: number =  1;
  private isInverted: boolean = true;

  private get getbestDealsProducts() {
    return this.isMobile
      ? RegionModule.getBestDeals.slice(0, 4)
      : RegionModule.getBestDeals;
  }

  get categories() {
    return RegionModule.getCategories;
  }

  get regions() {
    return RegionModule.getRegions;
  }

  private get getTopActivitiesProducts() {
    const length = RegionModule.getTopActivities.length;
    if (length < 7) {
      return RegionModule.getTopActivities.slice(0, 3);
    } else {
      return this.isMobile
        ? RegionModule.getTopActivities.slice(0, 4)
        : RegionModule.getTopActivities;
    }
  }

  private get getRecommendedForYouProducts() {
    return this.isMobile
      ? RegionModule.getRecommendedForYou.slice(0, 4)
      : RegionModule.getRecommendedForYou;
  }

  // private get getActivitiesForWeatherProducts() {
  //   return RegionModule.getActivitiesForWeather;
  // }

  private get getHappeningTodayProducts() {
    return RegionModule.getHappeningToday.slice(0, 6);
  }

  private get getOrganizerOfMonthProducts() {
    if (!RegionModule.getMonthOrganizer) {
      return [];
    }

    const lang = this.$i18n.locale ;
    // lets return the activities but with translated fields
    return RegionModule.getMonthOrganizer.activities.map((a: any) => {
      return{
        ...a,
        city: lang === 'en' ? a.city : a.translations.city[lang],
        country: lang === 'en' ? a.country : a.translations.country[lang],
        offer: lang === 'en' ? a.offer : a.translations.offer[lang],
        category: lang === 'en' ? a.category : a.translations.category[lang],
        product_name: lang === 'en' ? a.product_name : a.translations.product_name[lang],
      };
      });
  }

  private get organizerOfMonth() {
    const organizerOfMonth = RegionModule.getMonthOrganizer;
    if (!organizerOfMonth) {
      return;
    }

    const lang = this.$i18n.locale ;

    return {
      img: organizerOfMonth.img,
      organizerName: organizerOfMonth.name,
      organiserSlugName: organizerOfMonth.organiser_slug_name,
      region: lang === 'en' ? organizerOfMonth.city : organizerOfMonth.translations.city[lang],
      country: lang === 'en' ? organizerOfMonth.country : organizerOfMonth.translations.country[lang],
      cardColor: organizerOfMonth.color,
      upcomingActivities: organizerOfMonth.coming_activities,
    };
  }

  private get getExploreZurichProducts() {
    return RegionModule.getExploreZurich;
  }

  private get pageTags(): INavElement[] {
    return [
      {
        content: this.$t('region.best-deals'),
        anchor: '#bestDeals',
      },
      // {
      //   content: this.$t('region.categories'),
      //   anchor: '#categories',
      // },
      {
        content: this.$t('region.top-activities'),
        anchor: '#topActivities',
      },
      {
        content: this.$t('region.recommended'),
        anchor: '#recommendForYou',
      },
      // {
      //   content: 'Actvities for the weather',
      //   anchor: '#activitiesForWeather',
      // },
      {
        content: this.$t('region.happening-today'),
        anchor: '#happeningToday',
      },
      // {
      //   content: 'Organiser of the month',
      //   anchor: '#organizerOfMonth',
      // },
      // {
      //   content: this.$t('region.provider.title'),
      //   anchor: '#becomeProvider',
      // },
      {
        content: this.$t('region.explore-zurich'),
        anchor: '#exploreZurich',
      },
    ];
  }

  private recommendData: any = {
    offer: 'Special offer',
    img: 'assets/images/lausanne-lt_www_diapo_28409.jpg',
    name: 'What to do on a sunny weekend',
    desc:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut laboreet.',
  };

  private get isMobile() {
    return AppModule.isMobile;
  }

  private get isTablet() {
    return AppModule.isTablet;
  }

  private get currentDay() {
    return new Date().toUTCString().slice(0, 16);
  }

  @Meta
  private metaInfo() {
    return {
      title: this.$t('seo.title-homepage'),
      link: [
        {
          rel: 'canonical',
          href: location.protocol + '//' + location.host + location.pathname,
        },
      ],
      meta: [
        { hid: 'title', name: 'title', content: this.$t('seo.title-homepage') },
        {
          hid: 'description',
          name: 'description',
          content: this.$t('seo.description-homepage'),
        },
        { property: 'fb:admins', content: '100001288015151' },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.$t('seo.title-homepage'),
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.$t('seo.description-homepage'),
        },
        { hid: 'og:type', property: 'og:type', content: 'page' },
        {
          hid: 'og:url',
          property: 'og:url',
          content: location.protocol + '//' + location.host + location.pathname,
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content:
            this.$BASE_URL +
            'img/rasterized/1080px/homepage/homepage_slider_3.jpg',
        },
        {
          hid: 'og:image:alt',
          property: 'og:image:alt',
          content: 'Smeetz' + ' ' + this.$t('common.image'),
        },
        {
          hid: 'og:locale',
          property: 'og:locale',
          content: setLangForMeta(
            this.$route.params.locale || this.$i18n.locale,
          ),
        },
        // Uncomment if we will show content relateed to lang in url
        // { hid: 'noindex', name: 'robots, follow', content: 'noindex' },
      ],
    };
  }

  private async getActivitiesForDay(date: any, size: number = 6) {
    await RegionModule.fetchAppSearch({
      type: 'HappeningToday',
      options: {
        page: {
          current: 1,
          size,
        },
        filters: {
          all: [
            {
              product_location: {
                center: '46.5197, 6.633597',
                distance: 30,
                unit: 'km',
              },
            },
            { smeetz_channel: 1 },
            { ticketing_status: 1 },
            { status: 1 },
            {
              dates: [date.toISOString()],
            },
          ],
          none: [{ organiser_id: '1' }],
        },
      },
    });
  }

  private async getBestDeals(current: number = 1, size: number = 16) {
    await RegionModule.fetchAppSearch({
      type: 'BestDeals',
      options: {
        page: {
          current,
          size,
        },
        search_fields: { product_description: {} },
        sort: {
          average_number_of_ticket_sold: 'desc',
        },
        filters: {
          all: [
            {
              product_location: {
                center: '46.5197, 6.633597',
                distance: 30,
                unit: 'km',
              },
            },
            { smeetz_channel: 1 },
            { ticketing_status: 1 },
            { status: 1 },
            {
              dates: {
                from: dayjs().toISOString(),
              },
            },
          ],
          none: [{ organiser_id: '1' }],
        },
      },
    });
  }

  private async getTopActivities(current: number = 1, size: number = 8) {
    await RegionModule.fetchAppSearch({
      type: 'TopActivities',
      options: {
        page: {
          current,
          size,
        },
        sort: {
          number_booked_ticket_2weeks: 'desc',
        },
        filters: {
          all: [
            {
              product_location: {
                center: '46.5197, 6.633597',
                distance: 30,
                unit: 'km',
              },
            },
            { smeetz_channel: 1 },
            { ticketing_status: 1 },
            { status: 1 },
            {
              dates: {
                from: dayjs().toISOString(),
              },
            },
          ],
          none: [{ organiser_id: '1' }],
        },
      },
    });
  }

  private async getRecommendedForYou(current: number = 1, size: number = 16) {
    await RegionModule.fetchAppSearch({
      type: 'RecommendedForYou',
      options: {
        page: {
          current,
          size,
        },
        filters: {
          all: [
            {
              product_location: {
                center: '46.5197, 6.633597',
                distance: 30,
                unit: 'km',
              },
            },
            { smeetz_channel: 1 },
            { ticketing_status: 1 },
            { status: 1 },
            {
              dates: {
                from: dayjs().toISOString(),
              },
            },
          ],
          none: [{ organiser_id: '1' }],
        },
      },
    });
  }

  // private async getOrganizerOfMonth(current: number = 1, size: number = 2) {
  //   await RegionModule.fetchAppSearch({
  //     type: 'OrganizerOfMonths',
  //     options: {
  //       page: {
  //         current,
  //         size,
  //       },
  //       search_fields: { organiser_name: {} },
  //       sort: {
  //         average_number_of_ticket_sold: 'desc',
  //       },
  //     },
  //     searchQuery: 'dev_smeetz',
  //   });
  // }

  private async getExploreZurich(current: number = 1, size: number = 2) {
    await RegionModule.fetchAppSearch({
      type: 'ExploreZurich',
      options: {
        page: {
          current,
          size,
        },
        filters: {
          none: [{ organiser_id: '1' }],
        },
        search_fields: { city: {}, product_name: {} },
      },
      searchQuery: 'zurich',
    });
  }

  private async mounted() {
    const { auth, redirect } = this.$route.query;

    if (auth && redirect) {
      NModalModule.setStatus(ModalStatus.NewLogin);
    }
    // if (this.categories.length === 0) {
    //   RegionModule.fetchCategories();
    // }
    if (this.regions.length === 0) {
      RegionModule.fetchRegions();
    }
    // if (!this.organizerOfMonth) {
    //   RegionModule.fetchMonthOrganizer();
    // }
    if (this.getbestDealsProducts.length === 0) {
      this.getBestDeals(1);
    }
    if (this.getTopActivitiesProducts.length === 0) {
      this.getTopActivities(1);
    }
    if (this.getRecommendedForYouProducts.length === 0) {
      this.getRecommendedForYou(1);
    }
    if (this.getExploreZurichProducts.length === 0) {
      this.getExploreZurich(1);
    }
    if (RegionModule.getHappeningToday.length === 0) {
      const today = moment().utcOffset(0);
      today.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      this.getActivitiesForDay(today);
    }
    // this.getOrganizerOfMonth(1);
    this.isLoading = false;
  }

  private onScroll() {
    const header = document.getElementById('main-page-wrapper');
    const contentBackground = document.getElementById('content-background');
    if (!contentBackground || !header) {
      return;
    }

    let headerHeight: number = 80;
    if (this.isMobile) {
      headerHeight = 50;
    }

    debug('window.pageYOffset', window.pageYOffset);
    debug('AppModule.getScrollY', AppModule.getScrollY);

    if (AppModule.getScrollY /*window.pageYOffset*/ + headerHeight > contentBackground.offsetTop) {
      header.classList.add('wh-bg-shadowed');
      this.isInverted = false;
    } else {
      header.classList.remove('wh-bg-shadowed');
      this.isInverted = true;
    }
  }

  @Watch('$store.state.app.scrollY')
  private onScrollChange(newVal: number) {
    this.onScroll();
  }
}

export enum RefundSteps {
  MyBooking = 1,
  RefundSummary,
  Confirmation,
}

export const StepName: { [s: number]: string } = {
  [RefundSteps.MyBooking]: 'My booking',
  [RefundSteps.RefundSummary]: 'Refund summary',
  [RefundSteps.Confirmation]: 'Confirmation',
};







































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { NModalModule, UserModule } from '../utils/storemodules';
import { ModalStatus } from '../models/definitions';
import { isAuthRoute, goToHome } from '@/utils/auth';
import { constants } from '@/config/constants.ts';

@Component({})
export default class ProductNav extends Vue {
  @Prop({default: false}) private isTablet!: boolean;
  @Prop({default: false}) private inverted!: boolean;
  private burgerActive: boolean = false;
  private placeMenuOpen: boolean = false;
  private currentPlace: string = 'LAUSANNE';
  private places = [
    {
      name: 'Lausanne',
    },
    {
      name: 'Zürich',
    },
    {
      name: 'Geneva',
    },
  ];

  private get isLoggedIn(): boolean {
    return UserModule.isLogedIn;
  }

  private get navLinks(): Array<{name: any, url: string, handler: () => any}> {
    return [
      // {
      //   name: this.$t('new-footer.column1-row2'),
      //   url: '#',
      //   handler: this.navigateTo('/wishlist'),
      // },
      {
        name: this.$t('new-footer.column2-row3'),
        url: constants.business[this.$i18n.locale],
        handler: this.visitPartner,
      },
      {
        name: this.$t('nav.login'),
        url: '#',
        handler: this.showLogin,
      },
      {
        name: this.$t('nav.support'),
        url: '',
        handler: this.navigateTo(`/${this.$i18n.locale}/contact-support`),
      },
      {
        name: this.$t('nav.user-profile'),
        url: '',
        handler: this.navigateTo(`/${this.$i18n.locale}/user-space`),
      },
    ];
  }

  private goToLanding() {
    this.$router.push(`/${this.$i18n.locale}/`);
  }

  // On mobile, the user can go to the user space in the navigation
  private get navLinksMobile() {
    // return regular nav elements if not logged in
    if (!this.isLoggedIn) {
      return this.navLinks.slice(0, 3);
    }

    // Prepend user profile for logged in users
    // and replace login with logout at the bottom of the nav
    return ([
      {
        name: this.$t('nav.user-profile'),
        url: '',
        handler: this.navigateTo(`/${this.$i18n.locale}/user-space`),
      },
      {
        name: 'Support',
        url: '',
        handler: this.navigateTo(`/${this.$i18n.locale}/contact-support`),
      },
    ] as any).concat(this.navLinks.slice(0, 1), {
      name: this.$t('nav.signout'),
      url: '',
      handler: this.logOut,
    });
  }

  private toggleBurger() {
    this.burgerActive = !this.burgerActive;
  }

  private tooglePlaceMenu() {
    this.placeMenuOpen = !this.placeMenuOpen;
  }

  private changePlace(value: string) {
    this.currentPlace = value.toUpperCase();
  }

  // Displays the login modal
  private showLogin() {
    // Shoing login Modal using the new api
    NModalModule.setStatus(ModalStatus.NewLogin);
    this.burgerActive = false;
  }

  // Logs user out
  private logOut() {
    UserModule.logOut();
    this.burgerActive = false;
    if (isAuthRoute(this.$route, this.$router)) {
      goToHome(this.$router);
    }
  }

  // Visits the our pro space page
  private visitPartner() {
    window.location.href = constants.business[this.$i18n.locale];
  }

  // Go to another page
  private navigateTo(path: string) {
    return () => this.$router.push(path);
  }
}

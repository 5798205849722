














































import { Vue, Component, Prop } from 'vue-property-decorator';
import { NBookingModule, NNoty, OrganizerModule, AppModule } from '@/utils/storemodules';
import { AccountingEntity } from '@/models/store/booking';
import { disableQuasarStyling, enableQuasarStyling } from '@/utils/styles';
import { Dialog, Loading } from 'quasar';
import { checkPromoCode } from '@/api/booking';
import { setPriceValidatedBookingCodes } from '@/utils/membership';
import { getAllPromocodesWithCode } from '@/utils/promocode';
import { sendCartBookingInfoInCarts } from '@/utils/iframe';
import {EBookigCodeErrors} from '@/models/events';

@Component({})
export default class PromoInput extends Vue {
  @Prop({default: false}) private isBookingCode!: boolean;
  @Prop({default: 0}) private categoryId!: number;
  @Prop({default: null}) private indexOfBookingCode!: number | null;
  @Prop({default: ''}) private insertedCode!: string;
  @Prop({default: 0}) private accountingId!: number;
  @Prop({default: 0}) private timeSlotId!: number;
  @Prop({default: 0}) private priceId!: number;

  private focused = false;
  private loading = false;
  private code = this.insertedCode;
  private verified = false;
  private selectionToggeled = false;

  private get validatedBookingCodes() {
    return NBookingModule.validatedBookingCodes;
  }

  private get userMembershipBookingCodes() {
    return NBookingModule.userMembershipBookingCodes;
  }

  get userMembershipBookingCodesOptions() {
    const opts = [];
    for (const userBookingCode of this.userMembershipBookingCodes) {
      const { ticketName, voucher } = userBookingCode;
      opts.push({
        ticketName,
        value: voucher,
      });
    }

    return opts;
  }

  get applyBtnColor() {
    if (!this.code) {
      return 'grey5';
    } else if (this.verified || this.insertedCode) {
      return 'negative';
    }

    return 'primary';
  }

  public selectCode(code: string) {
    this.code = code;
    this.selectionToggeled = false;
  }

  private toggelSelection() {
    this.selectionToggeled = !this.selectionToggeled;
  }

  private mounted() {
    if (this.isBookingCode && this.indexOfBookingCode !== null &&
      this.validatedBookingCodes.length > 0 && this.validatedBookingCodes[this.indexOfBookingCode]) {
      this.code = this.validatedBookingCodes[this.indexOfBookingCode];
      this.verified = true;
    }
  }

  private openPrefillCodeDialog() {
    const opts = [];
    for (const userBookingCode of this.userMembershipBookingCodes) {
      const {ticketName, voucher} = userBookingCode;
      opts.push({
        label: `${ticketName} ${voucher}`,
        value: voucher,
      });
    }
    enableQuasarStyling();
    Dialog.create({
      title: this.$t('booking-flow.booking-codes-dialog-title') as string,
      message: this.$t('booking-flow.booking-codes-dialog-msg') as string,
      options: {
        type: 'radio',
        model: (0 as any),
        items: opts,
      },
      persistent: true,
    }).onOk(async (data: string) => {
      if (data) {
        this.code = data;
      }
      disableQuasarStyling();
      return;
    });
  }

  private async apply() {
    if (this.isBookingCode) {
      const previousAddedCodes = this.validatedBookingCodes;
      if (previousAddedCodes.includes(this.code) && !this.verified) {
        NNoty.createNewNoty({
          period: 4000,
          message:  String(this.$t('booking-flow.booking-code-used')),
          type: 'error',
        });
        return;
      }
      if (this.verified) {
        // only action possible is delete
        const removedCode = this.code;
        const newBookingCodes = previousAddedCodes.filter((code) => code !== this.code);
        NBookingModule.setValidatedBookingCodes(newBookingCodes);
        if (this.priceId) {
          setPriceValidatedBookingCodes({priceId: this.priceId});
        }
        this.code = '';
        this.verified = false;
        // search for accounting entry if it exists
        const accountings = NBookingModule.listOfAllPromoApplied;
        if (accountings && accountings.length > 0) {
          const accoutingId = accountings.find((d) => d.promo_code_code === removedCode);
          if (accoutingId) {
             try {
              enableQuasarStyling();
              Loading.show();
              await NBookingModule.removePromoCode([accoutingId.id]);
            } catch (error) {
              Loading.hide();
              disableQuasarStyling();
              NNoty.createNewNoty({
                period: 4000,
                message: String(this.$t('error.error-response-banner')),
                type: 'error',
              });
              setTimeout(() => {
                throw error;
              }, 0);
            } finally {
              Loading.hide();
              disableQuasarStyling();
            }
          }
        }
        return;
      }
      enableQuasarStyling();
      Loading.show();
      try {
        const groupId = OrganizerModule.id;
        const checkBookingCode = await checkPromoCode(Number(groupId), this.code, this.categoryId, this.timeSlotId);
        if (checkBookingCode.data.valid) {
          NBookingModule.setValidatedBookingCodeByIndex({code: this.code, index: Number(this.indexOfBookingCode)});
          if (this.priceId) {
            setPriceValidatedBookingCodes({priceId: this.priceId});
          }
          this.verified = true;
          const nnotyType = 'success';
          const nnotyMessage = this.$t('new-booking-flow.common.code-applied') as string;
          NNoty.createNewNoty({
            type: nnotyType,
            period: 5000,
            message: nnotyMessage,
          });

        }
      } catch (error: any) {
        Loading.hide();
        disableQuasarStyling();
        const errorMessage = error.response?.data?.messages?.length > 0 ? error.response.data.messages[0] : '';
        if (errorMessage && errorMessage === EBookigCodeErrors.PromoCodeIsAlreadyUsed) {
            NNoty.createNewNoty({
              period: 4000,
              message: String(this.$t('booking-flow.booking-code-conflicting')),
              type: 'error',
            });
        } else if (errorMessage && errorMessage === EBookigCodeErrors.TimeSlotIsNotValid) {
            NNoty.createNewNoty({
              period: 4000,
              message: String(this.$t('booking-flow.booking-code-canot-be-used')),
              type: 'error',
            });
        } else {
            NNoty.createNewNoty({
              period: 4000,
              message: String(this.$t('booking-flow.invalid-booking-code')),
              type: 'error',
            });
        }

        setTimeout(() => {
          throw error;
        }, 0);
      } finally {
        Loading.hide();
        disableQuasarStyling();
      }
      return;
    }
    if (this.insertedCode) {
      enableQuasarStyling();
      Loading.show();
      try {
        const accounting = NBookingModule.listPromoApplied?.find((acc) => acc.id === this.accountingId);
        if (!accounting) {
          throw new Error('Accounting not found');
        }

        let accountingsToDelete: number[] = [this.accountingId];
        // In case we have a promocode, let's remove all similar codes
        if (accounting.paymentMethod === 'PROMO_CODE') {
          accountingsToDelete = getAllPromocodesWithCode(this.accountingId)?.map((acc) => acc.id);
        }
        await NBookingModule.removePromoCode(accountingsToDelete);
        sendCartBookingInfoInCarts();
      } catch (error) {
        Loading.hide();
        disableQuasarStyling();
        NNoty.createNewNoty({
          period: 4000,
          message: String(this.$t('error.error-response-banner')),
          type: 'error',
        });
        setTimeout(() => {
          throw error;
        }, 0);
      } finally {
        Loading.hide();
        disableQuasarStyling();
      }
      return;
    }

    if (this.code.length === 0) {
      return;
    }

    this.loading = true;
    // Focus on the input because it looses it when we click on apply!!
    (this.$refs.promoinputbtn as any).focus();

    // Apply promo to notify success;
    try {
      await NBookingModule.sendPromoCode(this.code);
      sendCartBookingInfoInCarts();

      const isDiscountAppliable = this.isDiscountNotAreadyUsed();
      const nnotyType = isDiscountAppliable ? 'success' : 'error';
      const nnotyMessage = isDiscountAppliable ? this.$t('new-booking-flow.common.code-applied') as string
      : this.$t('new-booking-flow.common.code-not-applied') as string;

      NNoty.createNewNoty({
        type: nnotyType,
        period: 5000,
        message: nnotyMessage,
      });

      if (isDiscountAppliable) {
        this.code = '';
      }

    } catch (err) {
      NNoty.createNewNoty({
        type: 'error',
        period: 5000,
        message: this.$t('booking-flow.expired-or-not-existing-code') as string,
      });
    } finally {
      this.loading = false;
    }
  }

  private isDiscountNotAreadyUsed(): boolean {
    // To make sure a promo code is not already used it needs to have
    // promo_code_discount > 0 and accounting.accountingValue > 0
    // Why we need to check accounting.accountingValue?
    // One scenario: user can enter a valid promocode, then directly enter an already used promocode.
    // He will get a valid promocode message
    // We should prevent that by always considering acountingValue of accounting array
    const accountings: AccountingEntity[] | undefined = NBookingModule.bookingRes?.bookingRecap.accounting;

    const promoCodeAccounting = accountings?.filter((acc) => {
      return acc.promo_code_code.toLowerCase() === this.code.toLowerCase();
    });

    if (!promoCodeAccounting || promoCodeAccounting.length === 0) {
      // Our promo code isn't mentioned in the array --> Not Found
      // This is probably will never be reached, but we have to check.
      // Why never reached? Because NBookingModule.sendPromoCode(this.promoCode); would have thrown
      // an error before even calling this method
      throw new Error(this.$t('booking-flow.expired-or-not-existing-code') as string);
    }

    // A promo code is applied if:
    // 1- counts as discount
    // OR
    // 2- counts as giftcard
    // AND
    // 3- not already applied
    // Why using some() and not every()? Because in SICTIC case we had
    // accounting array of 8 elements for the same promo code in 1 trial
    // all with accountingValue = 0 except one.
    return ((NBookingModule.promoDiscount > 0 ||
      NBookingModule.recapInfo.discount > 0 ||
      NBookingModule.recapInfo.payments > 0)
      && promoCodeAccounting.some((p) => p.accountingValue > 0)) ? true : false;
  }
}

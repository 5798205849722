























import { Component, Vue, Prop } from 'vue-property-decorator';
import { Hooper, Slide, Pagination as HooperPagination } from 'hooper';
import { IPicturesEntity } from '@/models/store/product';

@Component({
  components: {
    Hooper,
    Slide,
    HooperPagination,
  },
})
export default class ProductCarousel extends Vue {
  @Prop({default: null}) private slides!: IPicturesEntity[] | null;
  private currentSlide: number = 1;

  private openImageViewer(index: any) {
    this.$emit('triggerImageViewer', index);
  }

  private get slidesCount() {
    return (this.slides as IPicturesEntity[]).length;
  }

  private getInfo(payload: any) {
    this.currentSlide = payload.currentSlide + 1;
  }
}

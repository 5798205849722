








import { Vue, Component, Prop } from 'vue-property-decorator';
import { NBookingModule, UserModule } from '@/utils/storemodules';
import Debug from 'debug';
import VueScript from 'vue-script2';

const debug = Debug('smeetz:auth');

@Component
export default class GoogleAuth extends Vue {
  @Prop({ default: true }) public showSpan!: boolean;
  private isLoading = false;
  private client: any = {};

  private async mounted(): Promise<void> {
    await VueScript.load('https://accounts.google.com/gsi/client');
    // @ts-ignore
    this.client = google.accounts.oauth2.initCodeClient({
      client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
      callback: this.onGoogleSignIn,
      scope: 'profile email openid',
      ux_mode: 'popup',
    });
  }


  // Show google authorization popup
  private logIn(): void {
    this.client.requestCode();
  }


  // Callback for google "oauth2"
  private async onGoogleSignIn(response: any): Promise<void> {
    this.isLoading = true;
    try {
      await UserModule.gLogin({ authCode: response.code });
      NBookingModule.socialLoginCheckout();
      this.$emit('successLogin');
      debug(response);
    } finally {
      this.isLoading = false;
    }
  }

}













































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import Timeout from 'await-timeout/dist/es5';
import { NBookingModule, NNoty } from '@/utils/storemodules';
import { AccountingEntity } from '@/models/store/booking';

@Component({})
export default class PromoInput extends Vue {
  @Prop() private edit!: boolean | undefined;
  private editable: boolean = false;
  private code = '';
  private focused = false;
  private loading = false;
  private error = false;

  @Watch('code')
  private onCodeChange() {
    this.error = false;
  }

  private async apply() {

    if (this.code.length === 0) {
      return;
    }

    this.loading = true;
    // Focus on the input because it looses it when we click on apply!!
    (this.$refs.promoinput as any).focus();

    // Apply promo to notify success;
    try {
      await NBookingModule.sendPromoCode(this.code);
      // NNoty.createNewNoty({
      //   type: 'success',
      //   period: 5000,
      //   message: this.$t('new-booking-flow.common.promo-applied') as string,
      // });

      const isDiscountAppliable = this.isDiscountNotAreadyUsed();
      const nnotyType = isDiscountAppliable ? 'success' : 'error';
      const nnotyMessage = isDiscountAppliable ? this.$t('new-booking-flow.common.code-applied') as string
      : this.$t('new-booking-flow.common.code-not-applied') as string;

      NNoty.createNewNoty({
        type: nnotyType,
        period: 5000,
        message: nnotyMessage,
      });

    } catch (err) {
      // this.error = true;
      NNoty.createNewNoty({
        type: 'error',
        period: 5000,
        message: this.$t('booking-flow.expired-or-not-existing-code') as string,
      });
    } finally {
      this.loading = false;
    }
  }

  private isDiscountNotAreadyUsed(): boolean {
    // To make sure a promo code is not already used it needs to have
    // promo_code_discount > 0 and accounting.accountingValue > 0
    // Why we need to check accounting.accountingValue?
    // One scenario: user can enter a valid promocode, then directly enter an already used promocode.
    // He will get a valid promocode message
    // We should prevent that by always considering acountingValue of accounting array
    const accountings: AccountingEntity[] | undefined = NBookingModule.bookingRes?.bookingRecap.accounting;

    const promoCodeAccounting = accountings?.filter((acc) => {
      return acc.promo_code_code.toLowerCase() === this.code.toLowerCase();
    });

    if (!promoCodeAccounting || promoCodeAccounting.length === 0) {
      // Our promo code isn't mentioned in the array --> Not Found
      // This is probably will never be reached, but we have to check.
      // Why never reached? Because NBookingModule.sendPromoCode(this.promoCode); would have thrown
      // an error before even calling this method
      throw new Error(this.$t('booking-flow.expired-or-not-existing-code') as string);
    }

    // A promo code is applied if:
    // 1- counts as discount
    // OR
    // 2- counts as giftcard
    // AND
    // 3- not already applied
    // Why using some() and not every()? Because in SICTIC case we had
    // accounting array of 8 elements for the same promo code in 1 trial
    // all with accountingValue = 0 except one.
    return ((NBookingModule.promoDiscount > 0 || NBookingModule.recapInfo.discount > 0)
      && promoCodeAccounting.some((p) => p.accountingValue > 0)) ? true : false;
  }

  /**
   * Triggered when the input is blurred
   */
  private onBlur() {
    if (this.loading) {
      return;
    }
    this.focused = false;
    this.code = '';
    this.editable = false;
  }

  private mounted() {
    this.editable = this.edit || false;
  }
}

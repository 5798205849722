




































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { email, required, maxLength, sameAs } from 'vuelidate/lib/validators';
import Timeout from 'await-timeout/dist/es5';
import { UserModule, NNoty, AppModule, ReferrerModule, OrganizerModule } from '@/utils/storemodules';
import { NBookingModule, ProductModule, NModalModule } from '@/utils/storemodules';
import { getBookingFields, postBookingField, postBookingFree, postBookingFile } from '@/api/booking';
import NewQuestion from './NewQuestion.vue';
import { IBookingField,
         ITicketField,
         IPostBookingField,
         IPreBookingRes,
         IProductSlotStatesEntity,
         IPostBookingFile,
         QuestionType,
         InformationType} from '@/models/store/booking';
import { IUserData, ModalStatus } from '@/models/definitions';
import { IGuestCheckoutUser } from '@/models/store/booking';
import NewButton from '@/components/presentational/NewButton.vue';
import { EPersonalDetails } from '@/models/events';
import { constants } from '@/config/constants.ts';

import Debug from 'debug';
import { IProduct } from '@/models/store/product';
import { getPrimaryColor } from '@/utils/colors';
import { destroyCart, inIframe } from '@/utils/iframe';
import { getFbPixelTracking, isEquilibre } from '@/utils/helpers';
import { ORG_PRIMARY_COLOR } from '@/config/constants';
import { QueryParameters } from '@/models/enums';
import { SiteLangs } from '@/models/site';
import {userIdentityTracking} from '@/utils/tracking';
import { withoutReplicatePromocode } from '@/utils/promocode';
import PromoInput from './PromoInputButton.vue';
import { Validator } from '@/utils/validators';
import { LoggerService } from '@/services/log';
import Mixpanel, { EVENTS, IUserIdentifier } from '@/services/mixpanel';
const debug = Debug('smeetz:booking');

const availableValidations: any = {
  firstname: {
    required,
    maxLength: maxLength(255),
  },
  lastname: {
    required,
    maxLength: maxLength(255),
  },
  email: {
    required,
    email: Validator.isValidEmail,
    maxLength: maxLength(255),
  },
};

@Component({
  components: {
    NewQuestion,
    NewButton,
    PromoInput,
  },
  validations() {
    // @ts-ignore
    return this.currentValidations;
  },
})
export default class PersonalDetails extends Vue {
  @Prop({default: false}) private isLogged!: boolean;
  @Prop({default: false}) private isSendData!: boolean;
  @Prop({default: false}) private isSkipedLogin!: boolean;
  @Prop({default: false}) private isDelivery!: boolean;
  @Prop({default: false}) private isFixedDelivery!: boolean;
  @Prop({default: false}) private isOrgUserLoggedIn!: boolean;

  private subscribeSmeetz: boolean = false;
  private addInsuranceToBooking: boolean = false;
  private policyAgree: boolean = true;
  private firstname: string = '';
  private lastname: string = '';
  private email: string = '';
  private confirmEmail: string = '';
  private user = {
    name: '',
    email: '',
  };
  // All booking questions
  private bookingQuestions: IBookingField[] = [];
  // Ticket questions for one ticket
  private ticketQuestions = [] as any;
  private productNames: string[] = [];
  // Ticket types (2 Adults, 1 Children)
  private ticketTypes: IProductSlotStatesEntity[] = [];
  // Is every field validated success
  private validated: boolean = false;
  // Array with validadtions of every question
  private validQuestions: boolean[] = [];
  private bookingId: number = -1;
  // private userChangeEmail: boolean = NBookingModule.isUserChangeEmail;
  // All question for tickets. Not sorted
  private allTicketQuestions: ITicketField[] = [];
  private legalLink = '';
  private isTouched = false;
  private prefillBookingCustomFileds = false;
  private prefillTicketCustomFileds = false;

  // Organiser id getter
  private get orgId() {
    return OrganizerModule.id;
  }

  get isMultiProducts(): boolean {
    return  ProductModule.isMultipleProducts;
  }

  private getProductName(categoryId: string | null | undefined): string {
    if (categoryId === null || categoryId === undefined) {
      return '';
    }
    // Why the below is casted as any?
    // Because the type IServerRecapCategory doesn't have a property 'productName', yet I can get it
    const category =
    NBookingModule.bookingRes?.bookingRecap.categories?.filter((c) => c.categoryId === Number(categoryId))[0] as any;
    return category.productName || '';
  }

  private get organizerInfo() {
    return NBookingModule.getOrganizerInfo;
  }

  private get bookingToken() {
    return NBookingModule.bookingToken;
  }

  private get userInfo() {
    return UserModule.user;
  }

  private get userChangeEmail() {
    return NBookingModule.isUserChangeEmail;
  }

  private get isLoggedIn() {
    return UserModule.isLogedIn || UserModule.isOrgUserLoggedIn;
  }

  private get isSocialLogin() {
    return NBookingModule.isSocialLogin;
  }

  private get emailExtension() {
    if (this.$i18n.locale === 'de') {
      return 'gesendet';
    }

    return '';
  }
  // Check if user fill personal data ONLY IF he choose "guest checkout" before
  private get filledUserInfo() {
    return !this.$v.$invalid;
  }

  private get currentValidations() {
    if ( this.isSkipedLogin) {
      availableValidations.confirmEmail = {
        required,
        sameAsEmail: sameAs('email'),
        email,
        maxLength: maxLength(500),
      };
      // TODO @Hamza check validation regex, it blocks valid emails
      // availableValidations.confirmEmail.email = () => {
      //   return Validator.isValidEmail(this.email);
      // };
      // availableValidations.email.email = () => {
      //   return Validator.isValidEmail(this.email);
      // };
    }
    return availableValidations;
  }

  // Which newsletter are we going to subscribe to
  private get newsLetterOwner() {
    if (AppModule.showSmeetzMarketing) {
      return 'Smeetz';
    }

    const product = ProductModule.product as IProduct;
    if (product && product.organiser) {
      const organizer = product.organiser;
      if (!organizer) {
        return '';
      }

      return organizer.name;
    } else {
      return '';
    }
  }

  private get showSmeetzMarketing() {
    return AppModule.showSmeetzMarketing;
  }

  // Some organisers always want to show policy
  private get alwaysShowPolicy() {
    const orgId = OrganizerModule.id;
    if (!orgId) {
      return false;
    }
    return [18612, 18785].includes(orgId);
  }

  // Hide smeetz policies on iframe
  private get hideSmeetzPolicy() {
    return inIframe();
  }

  private get isDesktop() {
    return AppModule.isDesktop;
  }

  // For equilibre, sumbit first name and last name from booking, as ticket fields
  // Doesn't apply if we are doing membership booking
  get submitTicketFieldsFromBookingFields(): boolean {

    // Only on Equilibre site. Not on Smeetz.com
    if (!inIframe()) {
      return false;
    }

    if (NBookingModule.isMembershipBooking) {
      return false;
    }

    const orgId = OrganizerModule.id;
    // Equilibre is running under fribourg-spectacles,
    // which has id 18773. But all the widget setup is done under
    // 16192. So, we will support both as equilibre
    if (orgId && isEquilibre(orgId)) {
      return true;
    }

    return false;
  }
  // We don't have to display Smeetz marketing Opt-in
  // if we have already question with
  // informationType === 14 which account for 'Marketing opt-in'
  get displaySmeetzMarketingOpt(): boolean {
    return this.bookingQuestions.some((p) => p.informationType === InformationType.Marketing ) ? false : true;
  }
  get marektingWording(): string[] {
    let wordings = String(this.$t('new-booking-flow.checkout.personal-details-subscribeUpdates-checkbox'));
    // For french Locale, we have this du vs de that should be written.
    // So for now, I will change it only for Hangar-Y, and later we see how to generalise this.
    // I think that we already have a custom field that allows organisers to put custom text. @Hanene, please confirm.
    // Group 16383 is just for testing on dev and should be removed.
    if (this.$i18n.locale === 'fr' && [19357, 16383].includes(this.orgId || 0)) {
      wordings = wordings.replace('de ', 'du ');
    }
    return wordings.split('||');
  }

  get policiesTermsWording(): string[] {
    return String(this.$t('new-booking-flow.checkout.personal-details-terms-with-cancelation')).split('||');
  }

  private get listPromoApplied() {
    const promos = NBookingModule.listPromoApplied;

    if (!promos) {
      return [];
    }

    return withoutReplicatePromocode(promos);
  }

  // TODO Hamza: We need to extract this method into a vuex mixin (BookingUrlHlper)
  // which contains url related work that needs to be shared accross components
  // Returns the custom link and custom text accodring to user's language.
  private customLinkWithLang() {
    if (ReferrerModule.customLinksData) {
      const custTexts = ReferrerModule.customLinksData.customLinksText;
      const custUrls = ReferrerModule.customLinksData.customLinksUrl;
      let textContainer: string | null = null;
      let urlContainer: string | null  = null;

      for (const text of custTexts) {
        const localLang = this.$i18n.locale;
        const custText = text[localLang as SiteLangs];
        if (custText) {
          textContainer = custText;
        }
      }

      for (const url of custUrls) {
        const localLang = this.$i18n.locale;
        const custUrl = url[localLang as SiteLangs];

        if (custUrl) {
          urlContainer = custUrl;
        }
      }

      if (textContainer && urlContainer ) {
        return {
          custText: textContainer,
          custUrl: urlContainer,
        };
      }
    }
  }

  // TODO Hamza: We need to extract this method into a vuex mixin (BookingUrlHlper)
  // which contains url related work that needs to be shared accross components
  // compared to the getter in Payment.vue, it doesn't have the if (!this.method)
  // check. So let's use it only in this component first.
  get paramsQs() {
    const { _ga } = this.$route.query;
    const sitePrimaryColor = getPrimaryColor();
    const { fbPixelId, gaId } = OrganizerModule;
    const { gtmId } = ReferrerModule;

    let qs: string = '';

    if (_ga) {
      qs = `_ga=${_ga}`;
    }

    if (sitePrimaryColor !== ORG_PRIMARY_COLOR) {
      qs = qs ?
        `${qs}&ocol=${escape(sitePrimaryColor)}` :
        `ocol=${escape(sitePrimaryColor)}`;
    }
    if (inIframe()) {
      if (AppModule.isCartWidget) {
        qs = qs ?
          `${qs}&wt=cart` :
          `wt=cart`;
      }
      if (AppModule.widgetId) {
        qs = qs ?
          `${qs}&wid=${AppModule.widgetId}` :
          `wid=${AppModule.widgetId}`;
      }
      if (ReferrerModule.customLinksData) {
        const custUrlandText = this.customLinkWithLang();
        if (custUrlandText) {
          qs = qs ?
          `${qs}&custText=${custUrlandText.custText}&custUrl=${custUrlandText.custUrl}` :
          `custText=${custUrlandText.custText}&custUrl=${custUrlandText.custUrl}`;
        }
      }
    }

    if (gaId) {
      qs = qs ?
        `${qs}&${QueryParameters.GAId}=${gaId}` :
        `${QueryParameters.GAId}=${gaId}`;
    }

    if (fbPixelId) {
      qs = qs ?
        `${qs}&${QueryParameters.FBId}=${fbPixelId}` :
        `${QueryParameters.FBId}=${fbPixelId}`;
    }

    if (gtmId) {
      qs = qs ?
        `${qs}&${QueryParameters.GTMID}=${gtmId}` :
        `${QueryParameters.GTMID}=${gtmId}`;
    }

    if (getFbPixelTracking()) {
      qs = qs ?
        `${qs}&${getFbPixelTracking()}` :
        `${getFbPixelTracking()}`;
    }

    if (AppModule.isNativeIntegration) {
      qs = qs ?
        `${qs}&${QueryParameters.IntegrationType}=${AppModule.integration}` :
        `${QueryParameters.IntegrationType}=${AppModule.integration}`;
    }

    // set language to make sure that it is set on the confirmation
    // page.
    qs = qs ?
    `${qs}&lang=${this.$i18n.locale}` :
    `lang=${this.$i18n.locale}`;

    return qs;
  }

  // check if we are in a mobile
  get isMobile() {
    return AppModule.isMobile;
  }
  // Display the prefill check box at ticket field level
  // If the question is firstName name or email
  private get isPrefillTicketLevel() {
    const ticketGroup = this.ticketQuestions && this.ticketQuestions[0];
    if (ticketGroup) {
      for (const question of ticketGroup) {
        const informationType = question.informationType;
        if (informationType === InformationType.FirstName || informationType === InformationType.LastName
        || informationType === InformationType.Email) {
          return true;
        }
      }
      return false;
    }
  }
  // Display the prefill check box at booking field level
  // If the question is firstName name or email
  private get isPrefillBookingLevel() {
   if (this.bookingQuestions && this.bookingQuestions.length > 0) {
    for (const question of this.bookingQuestions) {
        const informationType = question.informationType;
        if (informationType === InformationType.FirstName || informationType === InformationType.LastName
        || informationType === InformationType.Email) {
          return true;
        }
      }
    return false;
   }
  }
  // Watch if user press on button on Checkout component
  @Watch('isSendData')
  private onSendData() {
    this.sendData();
  }
  // Watch if user fill all personal data fields for registration
  @Watch('filledUserInfo')
  private onFillInfo() {
    this.emitValidated();
  }


  // Watcher to fill user info if loggedIn
  @Watch('isLoggedIn')
  private onUserLoggedIn() {
    this.user = {
      name: this.userInfo.fullname,
      email: this.userInfo.emails[0].emailAddress,
    };
    this.emitValidated();
  }

   // Watcher to fill user info if  organiser user is loggedIn
  @Watch('isOrgUserLoggedIn')
  private onOrgUserLoggedIn() {
    this.user = {
      name: this.userInfo.firstName + ' ' + this.userInfo.lastName,
      email: this.userInfo.emails[0].emailAddress,
    };
  }

 @Watch('bookingToken')
  private async onBookingUpdated() {
    if (!this.isSendData) {
      this.ticketQuestions = [];
      this.bookingQuestions = [];
      await this.fetchBookingFields();
    }
  }

  private changeUser() {
    NBookingModule.userChangeEmail();
    this.firstname = UserModule.user.firstName;
    this.lastname = UserModule.user.lastName;
    this.email = UserModule.user.emails[0].emailAddress;
    this.emitValidated();
  }

  private async sendData() {
    let userData: Partial<IGuestCheckoutUser> = {};


    if (this.isLoggedIn) {
      userData = {
        email: UserModule.user.emails[0].emailAddress,
        firstName: UserModule.user.firstName,
        lastName: UserModule.user.lastName,
        ...(this.showSmeetzMarketing && { emailMarketingSmeetz: this.subscribeSmeetz ? '1' : '0' }),
        ...(!this.showSmeetzMarketing && { emailMarketingOrganiser: this.subscribeSmeetz ? '1' : '0' }),
      };
    }

    if (this.userChangeEmail || (!this.isLoggedIn && this.filledUserInfo)) {
      userData = {
        email: this.email,
        firstName: this.firstname,
        lastName: this.lastname,
        // newsletterOptin: this.subscribeSmeetz ? '1' : '0',
      };

      if (this.showSmeetzMarketing) {
        userData.emailMarketingSmeetz = this.subscribeSmeetz ? '1' : '0';
      } else {
        userData.emailMarketingOrganiser =
          this.subscribeSmeetz ? '1' : '0';
      }
    }

    userData.termsOptin = this.policyAgree ? '1' : '0';
    // Track user identity if the user has filled the user info and has subscribed to marketing optin
    if (this.filledUserInfo && this.subscribeSmeetz) {
      const userInfo = {
        email: this.email,
        firstName: this.firstname,
        lastName: this.lastname,
      };
      userIdentityTracking(userInfo);
    }

    // User Tracking
    const logData: IUserIdentifier = {
      // userId: Number(user.id),
      userName: `${userData.firstName} ${userData.lastName}`,
      userEmail: userData.email || '',
    };
    Mixpanel.track(EVENTS.UserIdentifier, logData);

    await NBookingModule.patchBookingUser(userData as any);

    // retrieve all the answers
    const answers: IPostBookingField[] = [];
    const ticketAnswers: IPostBookingField[] = [];
    const bookingFiles: IPostBookingFile[] = [];
    const ticketFiles: IPostBookingFile[] = [];
    // if this booking has shipping method, booking custom fields will be displayed in another component
    if (!this.isDelivery) {
      for (const field of this.bookingQuestions) {
        const question: NewQuestion = (this.$refs['field' + field.fieldId] as any)[0] as NewQuestion;
        const answer = question.getAnswer();

        // add files answers here
        if (answer.answer && (answer.answer as File).name) {
          bookingFiles.push(answer as IPostBookingFile);
        } else if (answer.answer) {
          // Add only if answer is supplied
          answers.push(answer);
        }
      }
    }

    // avoid collecting ticket fields for equilibre non membership
    if (!this.submitTicketFieldsFromBookingFields) {
      for (const ticketQuestions of this.ticketQuestions) {
        for (const ticketQuestion of ticketQuestions) {
          const question: NewQuestion =
            (this.$refs['fieldPss' + ticketQuestion.fieldId + '' + ticketQuestion.pssId] as any)[0] as NewQuestion;
          const answer = question.getAnswer();

          // Add only if answer is supplied
          // If answer is a file, this means that we have a new selected file.
          if (answer.answer && (answer.answer as File).name) {
            ticketFiles.push({...answer as IPostBookingFile, pss: ticketQuestion.pssId});
          } else if (answer.answer) {
            ticketAnswers.push({...answer, pss: ticketQuestion.pssId});
          }
        }
      }
    }

    if (
      !answers.length
      && !ticketAnswers.length
      && !bookingFiles.length
      && !ticketFiles.length
      && !this.submitTicketFieldsFromBookingFields) {
      // Make sure that we patch user
      // THIS CAUSES THE BACKEND TO CLEAR CUSTOM FIELDS
      // await NBookingModule.patchBookingUser(userData as any);
      this.goForward();
      return;
    }

    try {
      const promiseArray: any[] = [];
      if (answers.length) {
        // promiseArray.push(postBookingField(this.bookingId as number, this.bookingToken, answers));
        // Log submitted booking fields
        LoggerService.info('Submitted booking fields', {params: JSON.stringify(answers)});
        await postBookingField(this.bookingId as number, this.bookingToken, answers);
      }

      // In case we have to take booking info and send them as custom fields
      if (this.submitTicketFieldsFromBookingFields) {
        for (const ticketQuestion of this.allTicketQuestions) {
          if (
            ticketQuestion.informationType === InformationType.FirstName
            || ticketQuestion.informationType === InformationType.LastName
          ) {

            const { pssId, fieldId } = ticketQuestion;
            const name = ticketQuestion.informationType === InformationType.FirstName ?
             userData.firstName : userData.lastName;
            const answer: IPostBookingField = {
              fieldId,
              pss: pssId,
              answer: name,
            };

            ticketAnswers.push(answer);
          }
        }
      }

      if (ticketAnswers.length) {
        // promiseArray.push(postBookingField(this.bookingId as number, this.bookingToken, ticketAnswers));
        await postBookingField(this.bookingId as number, this.bookingToken, ticketAnswers);
      }

      if (bookingFiles.length) {
        for (const bookingFile of bookingFiles) {
          promiseArray.push(postBookingFile(this.bookingId as number, this.bookingToken, bookingFile));
        }
      }
      if (ticketFiles.length) {
        for (const ticketFile of ticketFiles) {
          promiseArray.push(postBookingFile(this.bookingId as number, this.bookingToken, ticketFile));
        }
      }
      if (promiseArray.length) {
        await Promise.all(promiseArray);
      }

      // Call patch booking and update recap, because Morgan made some custom
      // fields modify total price
      // THIS CAUSES THE BACKEND TO CLEAR CUSTOM FIELDS
      // await NBookingModule.patchBookingUser(userData as any);

      this.goForward();
    } catch (err) {
      // error goes here
      debug(`Error: After submitting booking fields`);
      this.$emit(EPersonalDetails.Error, err);
      LoggerService.info('Error: After submitting booking fields', {params: JSON.stringify(err)});
      throw err;
    }
  }

  private async goForward() {
    NBookingModule.setSubmittedBooking(true);
    const shortUrl = (ProductModule.product as IProduct).short_url;
    const productId = (ProductModule.product as IProduct).id;
    const sitePrimaryColor = getPrimaryColor();
    const qs = `&ocol=${escape(sitePrimaryColor)}&reload=true`;

    if ( NBookingModule.recapInfo.total !== 0 ) {
      NBookingModule.stepForward();
    } else {
      try {
        // Excutes when it's a free ticket or when the usr applys a 100% promocode
        // (a promocode that covers the whole price so the ticket is free for the user)
        // or when the totoal cost is 0.
        await postBookingFree(this.bookingId, this.bookingToken);

        // Ensure that cart is destroyed. Otherwise it remains on organiser site
        if (AppModule.isCartWidget) {
          destroyCart();
        }
        // tslint:disable-next-line:max-line-length
        // this.$router.replace(`/product/${productId}/booking/${shortUrl}/success?bookingId=${this.bookingId}&bookingToken=${this.bookingToken}`, () => location.reload());

        // Reload site with a success page link.
        this.$router
          .replace(`/product/${productId}/booking/${shortUrl}/success?bookingId=${this.bookingId}&bookingToken=${this.bookingToken}&${this.paramsQs}&reload=true`);
        await Timeout.set(1000);
        location.reload();
      } catch (error) {
        this.$emit(EPersonalDetails.Error, error);
        NNoty.createNewNoty({
          period: 7000,
          message: String(this.$t('error.error-response-banner')),
          type: 'error',
        });
        throw error;
      }
    }
  }

  private async fetchBookingFields() {
    this.legalLink = constants.legal_main[this.$i18n.locale];
    if ( this.isLoggedIn || this.isOrgUserLoggedIn ) {
      this.user = {
        name: this.userInfo.fullname,
        email: this.userInfo.emails[0].emailAddress,
      };
      const bookingRes = NBookingModule.bookingRes as IPreBookingRes;
      this.subscribeSmeetz = !!bookingRes.emailMarketingSmeetz;
      this.policyAgree = !!bookingRes.termsOptin;
    }
    this.bookingId = NBookingModule.bookingId;
    // tslint:disable-next-line:max-line-length
    this.ticketTypes = (NBookingModule.bookingRes as any).productSlotStates || (NBookingModule.bookingRes as any).slots || [];
    if ( this.bookingId && this.bookingToken) {
      const customFields = await getBookingFields(this.bookingId, this.bookingToken);
      // Why we sort booking fields?
      // If booking fields are coming from two different products (case of one ticket and one addon)
      // we might have the same position value for two different custom fields.
      this.bookingQuestions = customFields.booking_fields?.sort((f1, f2) => {
          const s1 = Number(f1.productId) + f1.position;
          const s2 = Number(f2.productId) + f2.position;
          if (s1 > s2) { return 1; } else { return -1; }
        },
      ) as IBookingField[];
      this.allTicketQuestions = (customFields.ticket_fields as ITicketField[]).filter((q) => {
        // TODO remove next comment once BUD-8305 is tested
        // if (!ReferrerModule.memType) {
        if (!NBookingModule.isCustomerBooking) {
          return true;
        }

        // Hide prefilled membership custom fields
        // tslint:disable-next-line:max-line-length
        if (q.informationType === InformationType.MembershipInfo) {
          return !q.answer;
        }

        if (q.informationType === InformationType.FirstName) {
          return !q.answer;
        }

        if (q.informationType === InformationType.LastName) {
          return !q.answer;
        }

        return true;
      });
      const ticketCounts = this.ticketTypes.length;
      const questionPerTicket = this.allTicketQuestions.length / ticketCounts;
      // Sort tickets by ticket groups
      for ( let i = 0; i < ticketCounts; i++) {
        const oneTicket = [] as ITicketField[];
        const questionPerCurrentTicket = this.allTicketQuestions.filter((item) => {
          return item.pssId === (this.ticketTypes[i].id || this.ticketTypes[i].productSlotStateId);
        });
        if ( questionPerCurrentTicket.length ) {
          this.ticketQuestions.push(questionPerCurrentTicket);
          if (questionPerCurrentTicket[0] && questionPerCurrentTicket[0].categoryId) {
            this.productNames.push(this.getProductName(questionPerCurrentTicket[0].categoryId));
          }
        }
      }
      // All questions are valid at first.
      // We ensure this by creating an boolean array of length
      // similar to the number of booking and ticket fields.
      if ((this.bookingQuestions.length && !this.isDelivery) || this.allTicketQuestions.length) {
        let ticketFieldsCount = 0;
        for (const group of this.ticketQuestions) {
          for (const ticketField of group) {
            ticketFieldsCount++;
          }
        }
        const bookingQuestionLenght = !this.isDelivery ? this.bookingQuestions.length : 0;
        this.validQuestions = new Array(bookingQuestionLenght + ticketFieldsCount).fill(true);
      } else {
        this.emitValidated();
      }
    }
    this.fillFields();
  }

  private async mounted() {
   await this.fetchBookingFields();
  }

  private openPolicyModal() {
    NModalModule.setStatus(ModalStatus.policyAgreement);
  }

  private scrollIntoElement(id: string, hasError?: boolean) {
    const element = document.getElementById(id);
    if (element && (this.isDesktop || hasError)) {
      element.scrollIntoView({block: 'center', behavior: 'smooth'});
    }
  }

  private fillFields() {
    const bookingRes = NBookingModule.bookingRes as IPreBookingRes;
    if ( this.userChangeEmail || this.isSkipedLogin ) {
      this.firstname = bookingRes.first_name || this.firstname;
      this.lastname = bookingRes.last_name || this.lastname;
      this.email = bookingRes.email || this.email;
      this.subscribeSmeetz = !!bookingRes.emailMarketingSmeetz;
      this.policyAgree = !!bookingRes.termsOptin;

      if ( this.isSkipedLogin ) {
        this.confirmEmail = bookingRes.email || this.email;
      }
    }
  }

  private updateQuestionValidity(validity: {position: number, valid: boolean }) {
    this.validQuestions[validity.position - 1] = validity.valid;

    this.emitValidated();
  }

  private emitValidated() {
    const isUserGuestValidation = !this.validQuestions.includes(false);
    const isUserAuthValidation = this.isLoggedIn && !this.isSocialLogin
      && !this.validQuestions.includes(false)
      && !this.alwaysShowPolicy;

    const isUserLoggedInWithSocialLogin = this.isSocialLogin && !this.validQuestions.includes(false);

    // Some clients want to always force the users for the policy aggree
    // So let's treat them as non signed in users, because signed users don't have to agree
    const alwaysShowPolicyValidation =
      this.alwaysShowPolicy && !this.validQuestions.includes(false);
    // if organiser user is logged In and agree on smeetz policy
    if (this.isOrgUserLoggedIn) {
      this.$emit('validateFields', true);
      return;
    }
    if (alwaysShowPolicyValidation && !this.userChangeEmail) {
      this.$emit('validateFields', true);
      return;
    }
    if (isUserAuthValidation && this.userChangeEmail) {
      this.$emit('validateFields', true);
      return;
    }

    if ( (isUserAuthValidation && !this.userChangeEmail) || (this.filledUserInfo && isUserGuestValidation)
    || isUserLoggedInWithSocialLogin) {
      this.$emit('validateFields', true);
    } else {
      this.$emit('validateFields', false);
    }
  }

  private touchFields() {
    if (!this.isDelivery) {
      for (const field of this.bookingQuestions) {
        const question: NewQuestion = (this.$refs['field' + field.fieldId] as any)[0] as NewQuestion;
        (question as any).touch();
      }
    }

    for (const ticketQuestions of this.ticketQuestions) {
      for (const ticketQuestion of ticketQuestions) {
        const question: NewQuestion =
          (this.$refs['fieldPss' + ticketQuestion.fieldId + '' + ticketQuestion.pssId] as any)[0] as NewQuestion;
        (question as any).touch();
      }
    }
    this.handelPersonalDetailsError();
    this.isTouched = true;
  }

  // Make sure that the personal details are touched to check for Error
  // we scroll to the first field with the error
  private handelPersonalDetailsError() {
    const personalDetails = ['firstname', 'lastname', 'email'];
    for (const field of personalDetails) {
      this.$v[field].$touch();
    }

    for (const field of personalDetails) {
      if (this.$v[field].$error) {
        this.scrollIntoElement(field, true);
        return;
      }
    }
  }

  // PreFill personal inforamation custom Fields at the ticket level if the check box is selected
  private fillCustomFields(payload: {
    level: 'booking' | 'ticket',
    informationType: number,
    index?: number,
    }): string {
    const { level, informationType, index } = payload;

    if (level === 'booking') {
      if ((!this.prefillBookingCustomFileds || !informationType)) {
        return '';
      }
      return this.getUserInfo(informationType);
    }

    if ( level === 'ticket') {
      if ((!this.prefillTicketCustomFileds || !informationType)) {
        return '';
      }
      // Make sure fill the information only for the first ticket custom field
      if (index && index > 0) {
        return '';
      }
      return this.getUserInfo(informationType);
    }

    return '';
  }

  private getUserInfo(informationType: number): string {
    let user = {
      firstName: this.firstname,
      lastName: this.lastname,
      email: this.email,
    };

    // In case user is Loged In with social media we prefill user info with his info
    if (this.userInfo) {
      user = {
        firstName: this.userInfo.firstName,
        lastName: this.userInfo.lastName,
        email:  this.userInfo.emails[0].emailAddress,
      };
    }

    if (user.firstName && informationType === InformationType.FirstName) {
      return user.firstName;
    }
    if (user.lastName && informationType === InformationType.LastName) {
      return user.lastName;
    }
    if (user.email && informationType === InformationType.Email) {
      return user.email;
    }
    return '';
  }

}































































































/**
 * Renders hours for a given slots
 *
 * @Prop slots {ITimeslotsEntity}
 * @Prop ticket {ITicket}
 */
import { Vue, Component, Prop, Watch} from 'vue-property-decorator';
import dayjs from 'dayjs';
import { Mixins } from 'vue-mixin-decorator';
import { ITimeslotsEntity, ITicket } from '../../../models/store/booking';
import { ESlotsHours } from '../../../models/events';
import { colorByGradient, hasOccupancyGradient, hasPriceGradient } from '@/utils/booking';
import { debugGradient } from '@/utils/debug';
import DateHelperMixin from '@/mixins/DateHelper';
import { hideEndDateTime } from '@/utils/booking';
import { AppModule, NBookingModule, OrganizerModule, ProductModule, NNoty } from '@/utils/storemodules';
import { PriceCategory } from '@/models/enums';
import { getDecimalDigits } from '@/utils/helpers';
import { IPricePackage } from '@/models/store/packages';

interface IHourObj {
  bgColor?: string;
  startTime: string;
  endTime: string;
  publicCount: number;
  booked: number;
  toolTipTxt: string;
  price?: number;
  priceColor?: string;
}

@Component({})
export default class SlotsHours extends Mixins<DateHelperMixin>(DateHelperMixin) {
  @Prop() public slots!: ITimeslotsEntity[];
  @Prop() public ticket!: ITicket;
  @Prop({default: null}) public pricePackage!: IPricePackage;
  @Prop({default: null}) public mainTimeSlotId!: number;
  @Prop({default: false}) public withBorder!: boolean;
  @Prop({default: ''}) public hour!: string;
  @Prop({default: false}) public isAddon!: boolean;
  private selectedSlots: {[s: string]: ITimeslotsEntity} = {};
  private selectedTimes: {[s: string]: boolean} = {};
  private hasOccupancyGradient = false;
  private morningSlots: IHourObj[] = [];
  private afternoonSlots: IHourObj[] = [];
  private eveningSlots: IHourObj[] = [];
  private selectedPeriodOfDay: number = 0;
  private cateogriesTodisplayAvailabilities = [45775, 92420, 92512, 98915, 100646, 100647, 101312, 101313,
    101485, 101486, 101515, 101516, 101545, 101546, 101575, 101576, 101605, 101606];

  get finalSlots(): ITimeslotsEntity[] {
    if (!this.pricePackage || this.pricePackage.min_quantity === 0) { return this.slots; }
    return this.slots.filter((slot) => slot.publicCount >= this.pricePackage.min_quantity);
  }

  get hours(): IHourObj[] {
    const hours: IHourObj[] = [];
    const hoursObj: {[s: string]: IHourObj} = {};

    if (!this.finalSlots) {
      return [];
    }

    // create object with keys in the format 11:00
    for (const slot of this.finalSlots) {
      const startDateObj = dayjs(slot.startDateTime);
      const endDateObj = dayjs(slot.endDateTime);
      const startTime = startDateObj.format('HH:mm');
      const endTime = endDateObj.format('HH:mm');
      const publicCount = slot.publicCount;
      const booked = slot.booked;
      const gradient = booked / (booked + publicCount);
      const toolTipTxt = publicCount === 0 ? this.$t('button.sold-out').toString() : '';
      const bgColor = this.hasOccupancyGradient ? this.getBgColor(gradient) : '';
      // The price we're going to display is the minimum of reference prices (priceType===0) of the ticket
      // if the prices are all not reference prices then we will show the minimum price of that slot whatever type it is
      const referencePrices = slot.export_prices?.filter((p) => p.priceType === 0).map((p) => Number(p.priceValue));

      let price: number | undefined;
      let roundedPrice: number | undefined;
      if (referencePrices && referencePrices.length) {
        price = Math.min.apply(Math, referencePrices);
        roundedPrice = Number(price.toFixed(2));
      } else {
        const prices = slot.export_prices?.map((p) => p.priceValue);
        if (prices) {
          price = Math.min.apply(Math, prices);
          roundedPrice = Number(price.toFixed(2));
        }
      }
      const priceColor = (this.showPrice && price) ? this.getPriceColor(price) : '';


      hoursObj[dayjs(slot.startDateTime).format('HH:mm')] = {
        bgColor, startTime, endTime, publicCount, booked, toolTipTxt, price: roundedPrice, priceColor,
      };
    }

    // transform that obj to array
    // for (const key in hoursObj) {
    //   if (!key) {
    //     continue;
    //   }

    //   hours.push(key);
    // }
    for (const value of Object.values(hoursObj)) {
      hours.push(value);
    }

    // sort the array
    hours.sort((hour1: IHourObj, hour2: IHourObj) => {
      const h1 = Number(hour1.startTime.split(':')[0]);
      const h2 = Number(hour2.startTime.split(':')[0]);
      if (h1 < h2) {
        return -1;
      } else if (h1 > h2) {
        return 1;
      }

      return 0;
    });

    this.morningSlots = [];
    this.afternoonSlots = [];
    this.eveningSlots = [];

    for (const hour of hours) {
      const startTime = Number(hour.startTime.split(':')[0]);
      if (startTime < 12) {
        this.morningSlots.push(hour);
      } else if (startTime < 17) {
        this.afternoonSlots.push(hour);
      } else {
        this.eveningSlots.push(hour);
      }
    }

    return hours;
  }

  get periodOfDay() {
    return [
      {
        title: this.$t('new-booking-flow.common.period.morning'),
        data: this.morningSlots,
        disabled : this.morningSlots.every((t) => t.publicCount === 0) || this.morningSlots.length < 1,
      },
      {
        title: this.$t('new-booking-flow.common.period.afternoon'),
        data: this.afternoonSlots,
        disabled : this.afternoonSlots.every((t) => t.publicCount === 0) || this.afternoonSlots.length < 1,
      },
      {
        title: this.$t('new-booking-flow.common.period.evening'),
        data: this.eveningSlots,
        disabled : this.eveningSlots.every((t) => t.publicCount === 0) || this.eveningSlots.length < 1,
      }];
  }

  get enableNewCalendarTimeslotDesign() {
    // Groups for which we'll disable the new calendar design
    const disableCalendarNewDesign = [16375, 17962, 18636, 19285, 19327,
      19552, 19560, 19027, 16268, 19566, 19567, 19573, 19583, 19584, 19585, 19586, 19572];
    const groupId = Number(OrganizerModule.id);
    return this.hours.length > 9 && !disableCalendarNewDesign.includes(groupId);
  }

  get displayedSlots() {
    if (this.enableNewCalendarTimeslotDesign) {
      return this.periodOfDay[this.selectedPeriodOfDay].data;
    }
    return this.hours;
  }

  get showPrice() {
    return hasPriceGradient(this.ticket);
  }

  get timeSlotDuration() {
    if ( this.hours.length === 0 ) {
      return null;
    }
    const endTimeDate = this.slots[0].endDateTime;
    const startTimeDate = this.slots[0].startDateTime;
    const delta = Math.abs(
      new Date(endTimeDate.replace(/\s/, 'T')).valueOf() - new Date(startTimeDate.replace(/\s/, 'T')).valueOf(),
    ) / 1000;
    const minute = Math.floor(delta / 60) % 60 ;
    const hour = Math.floor(delta / 3600) > 0 ? Math.floor(delta / 3600) % 24 : null;
    return hour != null && minute > 0 ?
      `${hour} ${this.$t(`new-booking-flow.common.timeslot-duration-hour${hour > 1 ? 's' : ''}`)} ${minute} ${this.$t('new-booking-flow.common.timeslot-duration-minutes')}` :
      hour != null && minute === 0 ? `${hour} ${this.$t(`new-booking-flow.common.timeslot-duration-hour${hour > 1 ? 's' : ''}`)}` :
      `${minute} ${this.$t('new-booking-flow.common.timeslot-duration-minutes')}`;
  }

  get currency() {
    return ProductModule.productCurrency;
    // return 'CHF';
  }

  get showAvailability() {
    if (this.cateogriesTodisplayAvailabilities.includes(this.ticket.categoryId) && !this.showPrice) {
      return true;
    }
    return false;
  }
  get chooseTimeText() {
    // Anniversaire product of Fort Boyard
   if ([63413, 65365, 65471, 65496, 65497, 65498, 65499, 65500].includes(this.ticket.productId) && !this.isAddon) {
    return this.$t('new-booking-flow.common.choose-time-fort-boyard');
   } else {
    return this.$t('new-booking-flow.common.choose-time');
   }
  }

  get chooseTimePeriodText() {
    // Anniversaire product of Fort Boyard
    if ([63413, 65365, 65471, 65496, 65497, 65498, 65499, 65500].includes(this.ticket.productId) && !this.isAddon) {
      return this.$t('new-booking-flow.common.choose-time-fort-boyard');
    } else {
      return this.$t('new-booking-flow.common.choose-period-day');
    }
  }
  private getBgColor(gradient: number): string {
    if (gradient * 100 > this.ticket.highNumberGradient) {
      return this.ticket.highColorGradient;
    } else if (gradient * 100 > this.ticket.mediumNumberGradient) {
      return this.ticket.mediumColorGradient;
    } else {
      return this.ticket.lowColorGradient;
    }
  }

  private getPriceColor(price: number): string {
    if (price >= this.ticket.highNumberGradient) {
      return this.ticket.highColorGradient;
    } else if (price >= this.ticket.mediumNumberGradient) {
      return this.ticket.mediumColorGradient;
    } else {
      return this.ticket.lowColorGradient;
    }
  }

  private getDecimalDigits(n: number, x: number) {
    return getDecimalDigits(n, x);
  }

  @Watch('finalSlots')
  private async onfinalSlots() {
    if (this.isAddon && this.pricePackage &&
      this.mainTimeSlotId && this.finalSlots.length === 0 && this.slots.length > 0) {
       NNoty.createNewNoty({
        period: 3500,
        message: String(this.$t('error.no-addons-with-enough-quantity')),
        type: 'error',
      });

       await NBookingModule.addPriceLite({
        timeSlotId: this.mainTimeSlotId,
        ticketId: this.ticket.categoryId,
        priceId: this.pricePackage.price_id,
        count: 0,
      });
    }
  }

  @Watch('slots', {immediate: true})
  private onSlotsUpdate() {
    // // Incase we have many slots & only 1 hour (Multiple slots with one starting time)
    // // Make sure that we select that hour, cause the slots will be hidden
    const { slots, hours } = this;
    if (slots && slots.length > 1 && hours.length === 1) {
        this.selectHour(hours[0].startTime);
    }
    this.selectedPeriodOfDay = this.morningSlots.length > 0 ? 0 : this.afternoonSlots.length > 0 ? 1 : 2;
    if (this.periodOfDay[this.selectedPeriodOfDay].disabled) {
      let index = 0;
      for (const period of this.periodOfDay) {
        if (!period.disabled) {
          this.selectedPeriodOfDay = index;
          break;
        }
        index++;
      }
    }
  }

  private mounted() {
    debugGradient('this.hours:', this.hours);
    if (hasOccupancyGradient(this.ticket)) {
      this.hasOccupancyGradient = true;
    }

    /*
     * TODO DELETE
     */
    // BY DEFAULT NO SLOT HOUR IS SELECTED
    // by default select the first time
    // const slot = this.slots && this.slots[0];

    // if (this.hour) {
    //   return;
    // }

    // if (slot) {
    //   this.selectHour(dayjs(slot.startDateTime).format('HH:mm'));
    // } else if (this.hours[0]) {
    //   this.selectHour(this.hours[0].startTime);
    // }
  }

  private selectHour(hour: string) {
    this.$emit(ESlotsHours.HourSelected, hour);
  }

  get isEndHidden() {
    return hideEndDateTime(this.ticket);
  }

  private get isDesktop() {
    return AppModule.isDesktop;
  }

  private get isTablet() {
    return AppModule.isTablet;
  }
}

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import Debug from 'debug';
import Timeout from 'await-timeout/dist/es5';
import { IRootState } from '@/models/store';
import { IAppState } from '@/models/store/app';
import { GET_SCROLL_Y, SET_SCROLL_Y } from './constants';
import { inIframe } from '@/utils/iframe';
import { ProductModule, ReferrerModule } from '@/utils/storemodules';
import { trackC } from '@/api/tracking';
import { getTrackId, stripTrackId } from '@/utils/token';
import { hexToRGBA, resetSiteColors, setBookingColors, setWidgetColors } from '@/utils/colors';
import { PRIMARY_COLOR } from '@/config/constants';
import { IWidgetStyles } from '@/models/styles';
import { IntegrationTypes } from '@/models/enums';
import { genUID } from '@/utils/helpers';
import { LoggerService } from '@/services/log';

const debug = Debug('smeetz:app');


@Module({ namespaced: true, name: 'app' })
export default class AppModule extends VuexModule {
  public env: 'dev' | 'prod' | 'test' | '' = '';
  public scrollY: number = 0;
  public width: number | null = null;
  public primaryColor: string = '#D63864';
  public widgetId: string = '';
  public widgetType: '' | 'cart' = '';
  public widgetStyles: IWidgetStyles | null = null;
  public integration: 'native' | 'hybrid' | null = null;
  public isTCart: boolean = false;
  public appSessionId: string = genUID();

  // Universal smeetz tracking id. It is set either through
  // our website or widget tracker
  public trackId = '';

  public get isMobile() {
    if ( this.width ) {
      return this.width <= 480;
    }
  }

  public get isDesktop() {
    if ( this.width ) {
      return this.width > 968;
    }
  }

  public get isTablet() {
    if ( this.width ) {
      return this.width > 480;
    }
  }

  public get isTouch() {
    if (this.width) {
      return this.width < 768;
    }

    return false;
  }

  public get getScrollY() {
    return this.scrollY;
  }

  public get showSmeetzMarketing(): boolean {
    return ReferrerModule.isB2c;
  }

  public get isCartWidget() {
    return this.widgetType === 'cart';
  }

  // Getter function for retrieving the primary color with a
  // certain opacity applied. opacity ranges from 0 to 1.
  public get primaryWithOpacity() {
    return (opacity: number) => {
      return hexToRGBA(this.primaryColor, String(opacity));
    };
  }

  public get isNativeIntegration() {
    return this.integration === IntegrationTypes.Native;
  }

  public get isHybridIntegration() {
    return this.integration === IntegrationTypes.Hybrid;
  }

  @Mutation
  public setScrollY(y: number) {

    this.scrollY = y;
  }

  @Mutation
  public setWidth(changedWidth: number) {
    this.width = changedWidth;
  }

  @Mutation
  public setEnv(env: 'prod' | 'test' | 'dev') {
    this.env = env;
  }

  @Mutation
  public setColor(color: string) {
    this.primaryColor = color;
  }

  @Mutation
  public setWidgetId(id: string) {
    this.widgetId = id;
  }

  @Mutation
  public setWidgetType(type: '' | 'cart') {
    this.widgetType = type;
  }

  /**
   * @param tId {string} User tracking id setter
   */
  @Mutation
  public setTrackId(tId: string) {
    this.trackId = tId;
  }

  @Mutation
  public setIntegrationType(integration: 'native' | null) {
    this.integration = integration;
  }

  /**
   * Sets widget styles set by the organiser
   * @param styles Widget styles
   */
  @Mutation
  public setWidgetStyles(styles: IWidgetStyles) {
    this.widgetStyles = styles;
    setWidgetColors(styles);
  }

  // Set Tcart if it is sent from tracker code
  @Mutation
  public setTCart(payload: boolean) {
    this.isTCart = payload;
  }

  // In booking flow, show smeetzPrimaryColor as main color of
  // organiser
  @Action
  public changeBookingColors(color?: string) {
    const product = ProductModule.product;

    // Retrieve branding colors from product;
    const brandingColor = color || (product && product.organiser
      && product.organiser.branding
      && product.organiser.branding.smeetzPrimaryColor);
    if (!inIframe() && brandingColor) {
      setBookingColors({ smeetzPrimaryColor: brandingColor });
      // Make sure that we update the primary color on this module
      this.setColor(brandingColor);
    }
  }

  // Sets the tracking cookie only if not already set either through
  // one of our trackers deployed on the web or by a previous visit to
  // this site
  @Action( {rawError: true} )
  public async initTracking(color?: string) {
    if (this.trackId) {
      return;
    }
    const trackId = getTrackId();
    if (trackId) {
      this.setTrackId(stripTrackId(trackId));
      return;
    }
    try {
      await trackC();

      // the tracking id is set as a cookie, so we retrieve it
      // and set it in this module
      const fetchedTrackId = getTrackId();
      if (!fetchedTrackId) {
        if (inIframe()) {
          LoggerService.info(`WARNING: tid fetched but not set`);
          return;
        }
      }
      this.setTrackId(stripTrackId(fetchedTrackId));
    } catch (error) {
      debug('tr Err: ', error.message);
      throw error;
    }
  }


  /**
   * Reset the website color to default
   */
  @Action
  public clearBookingColors() {
    resetSiteColors();
    // make sure that we clear the website color to retrieve the default color
    this.setColor(PRIMARY_COLOR);
  }

  /**
   * This method waits for the tracking id to be set. Because sometimes when users
   * visit the booking flow through a direct link, we could send some requests that need
   * the tracking id while the tracking id hasn't been set already.
   *
   * WAITS FOR A MAXIMUM OF 2 seconds in order not to kill the UX
   */
  @Action({ rawError: true })
  public async waitForTracking() {
    const interval = 200; // waiting time between each check in ms.
    let timer = 0;

    while (timer < 2000 && !this.trackId) {
      await Timeout.set(interval);
      timer += interval;
    }
  }

}




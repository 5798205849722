



























import { Component, Vue } from 'vue-property-decorator';

@Component({
})
export default class ModalNav extends Vue {

  private close(): void {
    this.$emit('close');
    // this.$router.push('/');
  }
}

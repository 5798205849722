
















/**
 * Shows different tags needed on tickets
 *
 * @Prop colorType {string}: Which color type is it (primary, secondary)
 * @Prop bgColor {string}: Background color of string.
 * @Prop txtColor {string}: Text color.
 * @Prop txt {string}: Text to be written.
 */
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class TicketTag extends Vue {
  @Prop() public bgColor!: string;
  @Prop({default: 'white'}) public txtColor!: string;
  @Prop({default: ''}) public txt!: string;
  @Prop({default: '5px 10px'}) public pad!: string; // padding
  @Prop() public colorType!: 'primary' | 'secondary'; // color type
}

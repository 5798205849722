import { gql } from 'graphql-tag';

/**
 * Fetches Donations that are linked to a specific categories
 *
 */
// tslint:disable-next-line
export const GET_TicketDonationList = gql`
  query GetTicketDonationList($categoryIds: String!) {
    TicketDonationList(where: $categoryIds) {
      categoryId: CategoryId
      donationId: DonationId
      donationInfo : DonationRead {
        name: Name
        categoryId: CategoryId
        message: B2cMessage
        agreeMessage: AgreeMessage
        emailMessage: EmailMessage
        logo: Logo
        donationTranslationList : DonationTranslationList {
          locale: Locale
          field: Field
          message: Content
        }
      }
    }
  }
`;

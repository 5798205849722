


















import { Vue, Component, Prop } from 'vue-property-decorator';
import OrderSummaryConfirmation from './OrderSummaryConfirmation.vue';
import BookingConfirmed from './BookingConfirmed.vue';
import DonationConfirmed from './DonationConfirmed.vue';
import { AppModule, NBookingModule } from '@/utils/storemodules';
import { sendBookingInfoToNative } from '@/utils/iframe';

@Component({
  components: {
    OrderSummaryConfirmation,
    BookingConfirmed,
    DonationConfirmed,
  },
})
export default class ConfirmationStep extends Vue {
  @Prop({default: 0}) private windowWidth!: number;
  private isLogged: boolean = false;

  private created() {
    if (AppModule.isNativeIntegration) {
      sendBookingInfoToNative();
      return;
    }
  }

  private get isPaymentPending(): boolean {
    return NBookingModule.getIsPaymentPending;
  }

}

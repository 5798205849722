





























import { Prop, Component, Vue, Watch } from 'vue-property-decorator';
import NewButton from '@/components/presentational/NewButton.vue';
import { AppModule, NModalModule } from '@/utils/storemodules';
import { ModalStatus } from '@/models/definitions';
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import Debug from 'debug';

const debug = Debug('smeetz:crop');
@Component({
  components: {
    VueCropper,
    NewButton,
  },
})
export default class ModalCropper extends Vue {
  private imgSrc: string = '';
  private file: any | null = null;
  private mounted() {
    this.file = NModalModule.getCropFile as File;
    if (typeof FileReader === 'function') {
      const reader = new FileReader();
      reader.onload = (event) => {
        this.imgSrc = (event.target as FileReader).result as string;
        this.$nextTick(() => {
          (this.$refs.cropper as any).replace((event.target as FileReader).result);
        });
      };
      reader.readAsDataURL(this.file);
    } else {
      debug('FileReader API not supported');
    }
  }

  private submit() {
    (this.$refs.cropper as any).getCroppedCanvas().toBlob((blob: any) => {
      const cropedFile: any = new File([blob], this.file.name);
      cropedFile.id = this.file.id;
      NModalModule.setCropFile(cropedFile);
    }, 'image/jpeg');
  }

  private get isTablet() {
    return AppModule.isTablet;
  }

  private get isDesktop() {
    return AppModule.isDesktop;
  }

  private get isWidget() {
    return AppModule.isCartWidget;
  }
}

import { IMembershipDiscounts, MembershipType } from '@/models/memberships';
import { IMembershipCustomer, IPostBookingField, IProductSlotStatesEntity,
    IServerBooking, ITicketField, InformationType } from '@/models/store/booking';
import { NBookingModule } from './storemodules';
import { getBookingFields, postBookingField } from '@/api/booking';
import { getBookingTickets } from '@/api/ticket';
import { isActiveTicket } from './booking-utils';

export const RealMembershipDiscountTypes = [MembershipType.Family, MembershipType.Individual];

export function isRealMembershipDiscount(membership?: IMembershipDiscounts | null) {
    if (!membership) {
      return false;
    }
    return RealMembershipDiscountTypes.includes(membership.type);
}

// Logic for adding many tickets to each customer
export function addRegularTicketsToCustomers(
    membershipCustomers: IMembershipCustomer[],
    membership: IMembershipDiscounts,
    fetchedBooking: IServerBooking,
    categoryId: any,
    priceCategory: any,
    ) {
        if (membership && membership.type === MembershipType.RegularMembership) {
        // productSlotStates in post/patch booking doesn't contain priceId
        // const productSlotStates = (NBookingModule.bookingRes as any).productSlotStates ...
        const productSlotStates = (fetchedBooking as any).slots || [];
        // We get first all the PSS
        const allPss = productSlotStates.map((productSlotState: any) => {
        return {
            seat: productSlotState.seat,
            priceId: productSlotState.priceId,
            priceName: productSlotState.priceName,
            timeslotId: productSlotState.timeSlotId || productSlotState.timeslotId,
            pssId: productSlotState.id || productSlotState.productSlotStateId,
            };
        });

        const customersExistingPSSList = Array.prototype.concat.apply([],
            membershipCustomers.map((MemCustomer) => MemCustomer.tickets.map((t) => t.pssId)));

        // Then we remove from that list the ones that were added to customer before
        const diffPSS = allPss?.filter((pss: any) => !customersExistingPSSList.includes(pss.pssId));
        /*
         * When adding a membership x count ==> count * number of ticket
         * the ticket added and the addons automatically added
         * For example if we have a ticket with one automatically addded addon
         * When buying 3 tickets we'll get 6 Pss, 2 different timeslots (one for ticket and other one for addon)
         * We take only one combinaison of timeslots
         */
        const ids = diffPSS.map(({ timeslotId }: IProductSlotStatesEntity) => timeslotId);
        const filteredPss = diffPSS.filter(
            ({ timeslotId }: IProductSlotStatesEntity, index: number) =>
            !ids.includes(timeslotId, index + 1));
        const finalPss = filteredPss.map((pss: any) => {
            // We'll be using same categoryId, so that when we remove a price
            // we'll be able to remove from customer tickets the related addons
            // Check if categoryId is used on future API call,
            // Because doing it like this will set same categoryId for main Ticket and addons
            return {
                categoryId,
                seat: pss.seat,
                priceId: pss.priceId,
                priceName: pss.priceName,
                timeSlotId: pss.timeslotId,
                pssId: pss.pssId,
                priceCategory,
            };
        });
        return finalPss;
    }
}

export async function removeCustomersTickets() {
  // Get the tickets (getBookingTickets)
  const membership = NBookingModule.membership;
  const customers = NBookingModule.membershipCustomers;
  const bookingId = NBookingModule.bookingId;
  const bookingToken = NBookingModule.bookingToken;

  const bookingTickets = await getBookingTickets({
    bookingId,
    goerToken: bookingToken,
  });

  const nonActiveTickets = bookingTickets.filter((t) => !isActiveTicket(t));
  const nonActiveTicketsIds = nonActiveTickets.map((t) => t.Id);

  // remove any cancelled/abandoned (!isActive function) tickets from customers
  // Clear any cancelled tickets from customers
  for (const customer of customers) {
   customer.tickets = customer.tickets.filter((t) => !(nonActiveTicketsIds.includes(t.pssId) ));
  }
}


export async function submitCustomersCustomFields() {
    const bookingId = NBookingModule.bookingId;
    const bookingToken = NBookingModule.bookingToken;

    // Fetch ticket fields
    const answers: IPostBookingField[] = [];
    const customFields = await getBookingFields(bookingId, bookingToken);
    const ticketFields = customFields.ticket_fields as ITicketField[];

    // build answers array

    // for all ticket fields of firstname, lastname
    // get customer that owns the pss
    // set the customer info as answer
    // add to answers array

    for (const field of ticketFields) {
      // if (field.informationType === InformationType.FirstName && !field.answer) {
      if (field.informationType === InformationType.FirstName) {
        const customer = findCustomerWithPss(field.pssId);

        if (customer) {
          answers.push({
            // answer: `${memName} #${data}`,
            answer: customer.firstName,
            pss: field.pssId,
            fieldId: field.fieldId,
          });
        }
      }
    }
    // Post last name answer
    for (const field of ticketFields) {
      // if (field.informationType === InformationType.FirstName && !field.answer) {
      if (field.informationType === InformationType.LastName) {
        const customer = findCustomerWithPss(field.pssId);

        if (customer) {
          answers.push({
            // answer: `${memName} #${data}`,
            answer: customer.lastName,
            pss: field.pssId,
            fieldId: field.fieldId,
          });
        }
      }
    }

    // No answers, don't bother
    if (!answers.length) {
      return;
    }

    // submit answers
    try {
      await postBookingField(bookingId, bookingToken, answers);
    } catch (error) {
      throw(error);
    }
  }

// Find customer which owns a certain pss
export function findCustomerWithPss(pssId: number) {
    const customers = NBookingModule.membershipCustomers;
    for (const customer of customers) {
      const foundTicket = customer.tickets.find((t) => t.pssId === pssId);
      if (foundTicket) {
        return customer;
      }
    }

    return null;
}

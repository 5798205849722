







import { Vue, Component } from 'vue-property-decorator';
import { NModalModule } from '@/utils/storemodules';

@Component
export default class BookingDescription extends Vue  {

  get desciprion() {
    return NModalModule.popupDescription;
  }
}

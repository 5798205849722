



















import { Vue, Component } from 'vue-property-decorator';

@Component({})
export default class Options extends Vue {
  private options = [
    'Date', 'Location', 'Activity', 'Price', 'Don\'t show again',
  ];
}

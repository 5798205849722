










































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import Card from './Card.vue';
import Category from '@/components/presentational/Category.vue';
import NewButton from '@/components/presentational/NewButton.vue';
import { AppModule } from '@/utils/storemodules';

@Component({
  components: {
    Card,
    Category,
    NewButton,
  },
})
export default class CardsSlider extends Vue {
  @Prop({ default: null }) private cardsData: any;
  @Prop({ default: null }) private sliderCustomizations: any[] | undefined;
  @Prop({ default: false }) private isCategories!: boolean;
  private isShowMore: boolean = true;
  private currentSliderIndex: number =
    Math.floor(Math.random() * (100 - 1)) + 1;

  private defaultObject: any = {
    slidesPerView: 'auto',
    spaceBetween: 29,
    navigation: {
      nextEl: `#slider${(this.currentSliderIndex + '1').toString()}`,
      prevEl: `#slider${this.currentSliderIndex.toString()}`,
    },
  };

  // Return the default options declared above
  // bundled with any customization if there are any
  private get swiperOptions() {
    if (!this.sliderCustomizations || this.sliderCustomizations.length === 0) {
      return this.defaultObject;
    }
    for (const el of this.sliderCustomizations) {
      for (const prop of Object.keys(el)) {
      this.defaultObject[prop] = el[prop];
      }
    }
    return this.defaultObject;
  }

  private get isMobile() {
    return AppModule.isMobile;
  }

  private get isTablet() {
    return AppModule.isTablet;
  }
}

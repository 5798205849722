































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { email, required, maxLength } from 'vuelidate/lib/validators';
import ModalButton from '@/components/modal/ModalButton.vue';
import { AuthModule, ModalModule, NModalModule } from '@/utils/storemodules';
import { ModalStatus } from '@/models/definitions';
import { Status } from '@/utils/status';

@Component({
  components: {
    ModalButton,
  },
  validations: {
    email: {
      required,
      email,
      maxLength: maxLength(255),
    },
  },
})
export default class AddEmail extends Vue {
  private email: string = '';
  private emailSubmitted: boolean = false;
  private emailExists: boolean = false;

  @AuthModule.Action('addEmail') private addEmail: any;
  @ModalModule.Mutation('setStatus') private setStatus: any;

  get enableSubmit() {
    return !this.$v.$error && this.email;
  }

  private async submit() {
    if (!this.enableSubmit) {
      return;
    }

    this.emailExists = false;

    const button: any = this.$refs.button;
    button.executing(true);

    try {
      await this.addEmail(this.email);
      this.$router.replace({
        query: Object.assign({}, this.$route.query, { email: this.email }),
      });
      this.setStatus(ModalStatus.EmailConf);
      this.emailSubmitted = true;
    } catch (err) {
      const response = (err as any).response;
      if (response && Status[response.status] === 'Conflict') {
        this.emailExists = true;
      }
      this.setStatus(ModalStatus.Hidden);
    }

    button.executing(false);
  }

  private close() {
    NModalModule.setStatus(ModalStatus.Hidden);
  }

  @Watch('email')
  private onEmailChanged() {
    this.emailExists = false;
  }
}

import request, { esRequest, requestb2b, requestGraphql } from '@/utils/request';
import { dataToParams, getParameterByName } from '@/utils/helpers';
// import {
//   IUserData, IEmailVerifyData,
//   IUser, SocialService,
// } from '@/models/definitions';
// import {
//   ILoginData, ILoginResponse,
//   IEmailConfData, ISocialDataApi,
//   IPassChange, IPassReset,
//   IDeleteAccount, IPhoneData,
// } from '@/models/store/auth';
import Debug from 'debug';
import {
  IAvailabilities, IAvailabilitiesRange,
  IProductCalendar, IPreBookingReq, IPreBookingRes,
  IBookingPayments,
  IConfirmBookingData,
  IServerBooking,
  IStripeBookingData,
  IBookingField,
  IPostBookingField,
  IDPSlot,
  IFieldsResponse,
  ITicket,
  IPatchBookingOrderPayload,
  IBookingPaymentChoose,
  ITimeslotsEntity,
  ITicketSlotsFetch,
  NonWalleePayment,
  IPss,
  IPostBookingFile,
  IGQLBookingSummary,
  IPreBookingRetailReq,
  IPatchBookingOrderRetailPayload,
  IServerBookingPaymentStatus,
  Iwaivers,
  IPatchBookingOrderDonationPayload,
} from '@/models/store/booking';
import { AppModule, NBookingModule, ReferrerModule } from '@/utils/storemodules';
import { IProduct } from '@/models/store/product';
import { IAdyenSession, IAdyenSessionParams, IBookingTransactionParams } from '@/models/booking';
import { IGetMembershipResponse, IPostMembership, MembershipType } from '@/models/memberships';
import { getBookingDetailsQuery } from './graphql/queries';
import { timeslotsToDates } from '@/utils/tickets';
import { QueryParameters, RefundMethods, SellingChannels } from '@/models/enums';
import { inIframe } from '@/utils/iframe';
import { getBookingWaivers } from './waivers';

const debug = Debug('smeetz:booking');
let channel = inIframe() ? SellingChannels.Widget : SellingChannels.Discover;

// Registers the user
export async function fetchProduct(shortName: string): Promise<IProduct> {

  const response = await request({
    method: 'get',
    url: `/product/shortname/${shortName}`,
    params: {
      codes: getParameterByName(QueryParameters.Categories) !== null ?
        getParameterByName(QueryParameters.Categories) : undefined,
    },
  });

  return response.data;
}

export async function fetchProducts(productIds: number[], params = {}): Promise<IProduct[]> {
  const response = await request({
    method: 'get',
    url: '/product/list',
    params: {
      productId: productIds.join(','),
      ...params,
    },
  });

  const products = response.data;
  for (const product of products) {
    product.booking = {
      status: (product as any).booking_status,
      flow: (product as any).booking_flow,
    };
  }

  return products;
}

export async function postReportActivity(query: string) {
  const response = await request({
    method: 'post',
    url: `/hubspot/support-form?${query}`,
  });

  return response.data;
}

// get availabilities.
// Level 0 returns calendar slots ICalendarAvailabilitties
// level 1 returns day slots IAvailabilites
export async function fetchAvailabilities(data: IAvailabilitiesRange):
  Promise<IAvailabilities | IProductCalendar> {
  const productId = data.productId;
  delete data.productId;

  const response = await request({
    method: 'get',
    url: `/product/${productId}/productslots/availabilities`,
    params: data,
  });

  return response.data;
}

/**
 * GET_product_category_list
 * Fetches all the tickets related to a given product.
 *
 * @param productId: Id of the product
 */
export async function fetchTickets(productId: number): Promise<ITicket[]> {
  const options: any = {
    method: 'get',
    url: `/product/${productId}/category/list`,
    params: {},
  };

  // Set the tracking id as a query parameter if present
  const trackId = AppModule.trackId;
  const params: any = {};
  if (trackId) {
    options.params.tid = trackId;
  }

  // Fetch only the following categories if we are asking for them explicitly.
  // We do this for cart widget as well since we want to optmize the fetching.
  const cats = ReferrerModule.visibleTickets;
  if (cats.length) {
    options.params.cats = cats.join(',');
  }

  // display hidden with code categories
  const visibleCategories = ReferrerModule.categories;
  if (visibleCategories) {
    // options.params = {
    //   codes: visibleCategories,
    // };
    options.params.codes = visibleCategories;
  }


  const response = await request(options);
  const categories = response.data as ITicket[];
  for (const ticket of categories) {
    ticket.productId = productId;
    const categoryslots = ticket.categoryInfo.timeslots;
    ticket.categoryInfo.dates = timeslotsToDates(categoryslots);
  }

  return categories;
}

export async function fetchTicketsAddons(categoryIds: number[], codes?: string[]): Promise<ITicket[]> {
  const options: any = {
    method: 'get',
    url: `/product/category/list`,
    params: {},
  };

  options.params.categoryIds = categoryIds.join(',');
  options.params.codes = codes && codes.length > 0 ? codes.join(',') : undefined;

  const response = await request(options);
  const categories = response.data as ITicket[];
  for (const ticket of categories) {
    const categoryslots = ticket.categoryInfo.timeslots;
    ticket.categoryInfo.dates = timeslotsToDates(categoryslots);
  }

  return categories;
}

/**
 * GET_product_category_dates
 * Fetches all dates between 'from' and 'to' of a given category
 *
 * @param fetchData: productId - categoryId - from - to
 */
export async function fetchTicketDates(fetchData: ITicketSlotsFetch): Promise<Pick<ITicket, 'categoryInfo'>> {
  const { from, to, productId, categoryId } = fetchData;
  const params: any = { from, to };

  // HK bordeaux has overbookings, so we will block the 9th of March
  // we make from 1 day after
  if (categoryId === 96109 && from === '2024-03-10') {
    params.from = '2024-03-11';
  }
  const response = await request({
    method: 'get',
    url: `/product/${productId}/category/${categoryId}/dates`,
    params,
  });
  return response.data.data.category;
}

/**
 * GET_product_category_timeslots
 */
export async function fetchTicketSlots(fetchData: ITicketSlotsFetch): Promise<ITimeslotsEntity[]> {
  const { from, to, productId, categoryId } = fetchData;

  const tid = AppModule.trackId;
  const params: any = { from, to };
  if (tid) {
    params.tid = tid;
  }

  const response = await request({
    method: 'get',
    url: `/product/${productId}/category/${categoryId}/timeslots`,
    // params: { from, to },
    params,
  });

  return response.data;
}

// Pre book method so that tickets will be reserved for a small duration
// before making the payment.
export async function makeBooking(data: IPreBookingReq | IPreBookingRetailReq): Promise<IPreBookingRes> {
  // Set the tracking id as a query parameter if present
  const trackId = AppModule.trackId;
  const params: any = {};
  if (trackId) {
    params.tid = trackId;
  }

  params.channel = ReferrerModule.fromWidget ?  SellingChannels.Widget : channel;

  const response = await request({
    method: 'post',
    url: '/booking',
    data,
    params,
  });

  return response.data;
}

export async function patchBooking(bookingId: number, bookingToken: string, data: any):
  Promise<IPreBookingRes> {
  const response = await request({
    method: 'patch',
    url: '/booking',
    data: Object.assign({}, { bookingToken, bookingId }, data),
  });

  return response.data;
}

// Api call to not cancel tickets if we receive Authorized from Adyen
export async function authorizedSession(
    bookingId: number, bookingToken: string, sessionId: string, sessionResult: string) {
  const response = await request({
    method: 'POST',
    url: `/booking/${bookingId}/authorized-session`,
    params: {
      bookingToken,
      sessionId,
      sessionResult,
    },
  });

  return response.data;
}

// Cancel the booking
export async function cancelBooking(bookingId: number, bookingToken: string) {
  const response = await request({
    method: 'patch',
    url: `/booking/${bookingId}/cancel`,
    params: {
      bookingToken,
    },
  });

  return response.data;
}

// update the booking cart
export async function patchBookingOrder(
    payload: IPatchBookingOrderPayload |
    IPatchBookingOrderRetailPayload |
    IPatchBookingOrderDonationPayload): Promise<IPreBookingRes> {
  // Set the tracking id as a query parameter if present
  const trackId = AppModule.trackId;
  const params: any = {
    bookingToken: payload.bookingToken,
  };
  if (trackId) {
    params.tid = trackId;
  }

  params.channel = ReferrerModule.fromWidget ?  SellingChannels.Widget : channel;
  const {membership} = NBookingModule;
  if (membership && membership.type === MembershipType.RequiredTicketsMembership) {
    params.release_seats = true;
  }

  const response = await request({
    method: 'patch',
    url: `/booking/${payload.bookingId}/order`,
    params,
    data: {
      timeSlots: payload.slots,
      bookingReferrer: payload.bookingReferrer,
      utmChannel: payload.utmChannel,
      utmCampaign: payload.utmCampaign,
      utmMedium: payload.utmMedium,
      utmSource: payload.utmSource,
    },
  });

  return response.data;
}

// tslint:disable-next-line:max-line-length
export async function loadPaymentMethods(bookingId: number, bookingToken: string, params?: IBookingTransactionParams):
  Promise<IBookingPayments> {

  let apiParams = params || { bookingToken };

  channel  = ReferrerModule.fromWidget ? SellingChannels.Widget : channel;

  apiParams = { ...apiParams, channel };

  const response = await request({
    method: 'post',
    url: `/booking/${bookingId}/transaction`,
    params: apiParams,
    // data: new URLSearchParams(),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

  return response.data;
}

// api called after payment form is validated
export async function confirmBooking(data: IConfirmBookingData) {
  const requestData = dataToParams({
    paymentMethod: data.paymentMethod,
  });

  const response = await request({
    method: 'post',
    url: `/booking/${data.bookingId}/transaction/${data.transactionId}/confirm`,
    params: {
      bookingToken: data.bookingToken,
    },
    data: requestData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

  return response.data;
}

// Api called after user has paid and is redirected to success or failure page
export async function fetchServerBooking(bookingId: number, bookingToken: string, includePackages?: boolean):
  Promise<IServerBooking> {
  const response = await request({
    method: 'get',
    url: `/booking/${bookingId}`,
    params: {
      bookingToken,
      'include-packages': includePackages,
    },
  });

  // make sure that we have bookingId & token
  const data = response.data;
  data.bookingId = bookingId;
  data.bookingToken = bookingToken;

  return data;
}

// Api called to get booking payment status after user has paid and is redirected to success or failure page
export async function fetchServerBookingPaymentStatus(bookingId: number, bookingToken: string):
  Promise<IServerBookingPaymentStatus> {
  const response = await request({
    method: 'get',
    url: `/booking/${bookingId}/payment-status`,
    params: {
      bookingToken,
    },
  });

  // make sure that we have bookingId & token
  const data = response.data;
  data.bookingId = bookingId;
  data.bookingToken = bookingToken;

  return data;
}

/**
 * Returns the booking data using GraphQL query
 */
export async function gql_fetchBooking(bookingId: number):
  Promise<IGQLBookingSummary> {
  const response = await requestGraphql({
    method: 'POST',
    data: JSON.stringify({
      query: getBookingDetailsQuery(bookingId),
    }),
  });

  // make sure that we have bookingId & token
  const data = response.data;
  data.bookingId = bookingId;

  return data;
}

// Api called when booking with stripe
export async function bookingTransactionStripe(data: IStripeBookingData) {
  const response = await request({
    method: 'post',
    url: '/booking/transaction/stripe',
    data,
  });

  return response.data;
}

// Api called to retrieve booking fields
export async function getBookingFields(bookingId: number, bookingToken: string):
  Promise<IFieldsResponse> {

  const response = await request({
    method: 'get',
    url: `/booking/${bookingId}/fields`,
    params: {
      bookingToken,
    },
  });

  return Promise.resolve(response.data);
}

/**
 * Submit booking field data
 *
 * @param bookingId
 * @param bookingToken
 * @param data
 */
export async function postBookingField(
  bookingId: number, bookingToken: string, data: IPostBookingField[],
) {

  const isTicketField = data[0] && data[0].pss;
  const postData: any = {};
  postData[(!isTicketField ? 'fields' : 'ticketFields')] = data;

  const response = await request({
    method: 'put',
    url: `/booking/${bookingId}/${isTicketField ? 'ticket/fields' : 'fields'}`,
    params: {
      bookingToken,
    },
    // data,
    data: postData,
  });

  return response;
}
// Patch Booking Fields
export async function patchBookingField(
  bookingId: number, bookingToken: string, data: IPostBookingField[],
) {

  const isTicketField = data[0] && data[0].pss;
  const postData: any = {};
  postData[(!isTicketField ? 'fields' : 'ticketFields')] = data;



  const response = await request({
    method: 'patch',
    url: `/booking/${bookingId}/${isTicketField ? 'ticket/fields' : 'fields'}`,
    params: {
      bookingToken,
    },
    // data,
    data: postData,
  });

  return response;
}

/**
 * Submit booking field data
 *
 * @param bookingId
 * @param bookingToken
 * @param data
 */
// post booking field file type
export async function postBookingFile(
  bookingId: number, bookingToken: string, data: IPostBookingFile,
) {

  const postData = new FormData();
  let url = '';
  postData.append('fieldId', String(data.fieldId));

  if (data.pss) { // ticket fields
    postData.append('pss', String(data.pss));
    url = `/booking/${bookingId}/ticket/fields/file`;
  } else { // booking fields
    url = `/booking/${bookingId}/fields/file`;
  }

  postData.append('file', data.answer);

  const response = await request({
    method: 'post',
    url,
    params: {
      bookingToken,
    },
    // data,
    data: postData,
  });

  return response;
}

/**
 * Submit booking free success
 *
 * @param bookingId
 * @param bookingToken
 */
export async function
  postBookingFree(bookingId: number, bookingToken: string, data?: { paymentMethod: string }) {

  let paymentMethod: string = NonWalleePayment.Free;

  if (data && data.paymentMethod) {
    paymentMethod = data.paymentMethod;
  }

  channel  = ReferrerModule.fromWidget ? SellingChannels.Widget : channel;

  const response = await request({
    method: 'post',
    url: `/booking/${bookingId}/complete/free`,
    params: {
      bookingToken,
      channel,
    },
    data: {
      bookingToken,
      paymentMethod,
    },
  });

  return response;
}

export async function postPromoCode(bookingId: number, bookingToken: string, code: string):
  Promise<IServerBooking> {

  channel  = ReferrerModule.fromWidget ? SellingChannels.Widget : channel;

  const response = await request({
    method: 'post',
    url: `/booking/${bookingId}/promocode`,
    data: {
      bookingToken,
      promoCode: code,
    },
    params: {
      channel,
    },
  });

  // new api is wrapped in data.booking field
  return response.data.data.booking;
}

export async function removePromoCode(bookingId: number, accountingIds: number[], bookingToken: string):
  Promise<IServerBooking> {

  channel  = ReferrerModule.fromWidget ? SellingChannels.Widget : channel;

  const response = await request({
    method: 'post',
    url: `/booking/${bookingId}/promocode/remove`,
    data: {
      bookingToken,
      accountings: accountingIds.join(','),
    },
    params: {
      channel,
    },
  });

  // new api is wrapped in data.booking field
  return response.data.data.booking;
}

export async function checkPromoCode(groupId: number, promoCode: string, categoryId: number, timeSlotId: number) {

  const response = await request({
    method: 'post',
    url: `/booking/promocode/verify`,
    data: {
      groupId,
      promoCode,
      categoryId,
      timeSlotId,
    },
  });

  return response.data;
}

export async function getPss(pss: string): Promise<IPss> {
  const response = await request({
    method: 'get',
    url: `/booking/validate`,
    params: {
      pss,
    },
  });

  return response.data;
}

/**
 * Sets the user groupid for a given product.
 * This will help Mathias with knowing which price did the user see
 * for a dynamically priced product. This way we can always continue to
 * show to the user the same price that he already saw.
 * @param productId
 */
export async function postProductGroupId(productId: number) {
  // Set the tracking id as a query parameter if present
  const trackId = AppModule.trackId;
  const params: any = {};
  if (trackId) {
    params.tid = trackId;
  }

  const response = await request({
    method: 'post',
    url: `/product/${productId}/control-group-user`,
    params,
  });

  return response.data;
}
/**
 * GET_booking-field_file_url
 * Fetches the url of a file
 */
export async function getBookingFieldFile(bookingId: number, bookingToken: string, bookingField: any): Promise<any> {
  const fieldId = bookingField.answerId;
  const fieldType = bookingField.pssId ? 'ticket' : 'booking';
  const response = await request({
    method: 'get',
    url: `/booking/${bookingId}/${fieldType}-field/${fieldId}/url`,
    params: {
      bookingToken,
    },
  });

  return response.data;
}

// download ticket's pdf

export async function getTicketOrReceipt(bookingId: number, ticketToken: string, type: string): Promise<Blob> {
  const response = await request({
    responseType: 'blob',
    method: 'get',
    url: `/booking/${bookingId}/${type}`,
    params: {
      ticketToken,
    },
  });

  return response.data;
}

/**
 * GET_group_membership_discounts
 * Fetches the memberships of a group (Organiser)
 *
 * @param groupId : the group id concerned with the membership
 */
export async function getGroupMemberships(groupId: number): Promise<IGetMembershipResponse> {
  const response = await requestb2b({
    method: 'get',
    url: `/group/${groupId}/membership-discounts`,
    params: {
      b2c: 1,
    },
  });

  return response.data;
}

/**
 * POST_booking_membership-discount_apply
 * applies membership discounts to the booking
 * @param data
 *
 * @returns Product slots states and whether a discount is applied
 * to them or not
 */
export async function applyMemDiscount(data: { bookingId: number, bookingToken: string, memId: number })
  : Promise<IPostMembership> {
  const { bookingId, bookingToken, memId } = data;
  const response = await requestb2b({
    method: 'post',
    url: `/booking/${bookingId}/membership-discount/${memId}/apply?bookingToken=${bookingToken}`,
  });

  return response.data;
}

export async function
  cancelPSS(data: { bookingId: number, bookingToken: string, tickets: number[], releaseSeats: 1 | 0 })
  : Promise<IPostMembership> {
  const { bookingId, bookingToken, releaseSeats, tickets } = data;

  channel  = ReferrerModule.fromWidget ? SellingChannels.Widget : channel;

  const params: any = { bookingToken, channel };
  if (releaseSeats === 1) {
    params.releaseSeats = releaseSeats;
  }

  const response = await request({
    method: 'post',
    url: `/booking/${bookingId}/tickets/cancel`,
    params,
    data: {
      tickets,
    },
  });

  return response.data;
}

export async function fetchRandomSlot(avoidedSlotsIds: string[] = []): Promise<IDPSlot | undefined> {
  const res = await esRequest({
    method: 'post',
    url: '/_search?pretty',
    // params: { pretty: '' },
    data: {
      size: 1,
      query: {
        // bool: {
        //   must_not: {
        //     terms: {
        //       _id: avoidedSlotsIds,
        //     },
        //   },
        // },
        function_score: {
          query: {
            bool: {
              must_not: {
                terms: {
                  _id: avoidedSlotsIds,
                },
              },
            },
          },
          functions: [
            {
              random_score: {
                seed: String(Date.now()),
              },
            },
          ],
        },
      },
    },
  });

  const dpslots = res.data.hits.hits;

  return dpslots[0];
}

export async function postBookingTransactionAdyen(data: IAdyenSessionParams): Promise<IAdyenSession> {
  channel  = ReferrerModule.fromWidget ? SellingChannels.Widget : channel;

  const { bookingId, bookingToken, returnUrl, paymentMethod } = data;
  const response = await request({
    method: 'post',
    url: `/booking/${bookingId}/adyen-session`,
    params: { bookingToken, returnUrl, paymentMethod, channel},
  });

  return response.data.data;
}

export async function refundBooking(bookingId: number, bookingToken: string, tickets: any[],
                                    refundMethod: number): Promise<any> {
  const url =  refundMethod === RefundMethods.CreditCard ?
    `/booking/${bookingId}/tickets/cancel-by-user` : `/booking/${bookingId}/tickets/refund-as-voucher-by-user`;
  const response = await request({
    method: 'patch',
    url,
    params: { bookingToken },
    data:  { tickets },
  });

  return response.data;
}

/**
 * Returns the booking's waivers using GraphQL query
 */
export async function gql_fetchBookingWaivers(bookingId: string, bookingToken: string):
  Promise<any> {
  const response = await getBookingWaivers(bookingId, bookingToken);

  return response;
}

// Add Waivers to the booking
export async function addWaiversToBooking(waivers: Iwaivers[]) {
  const data = {
    data : waivers,
  };
  const response = await request({
    method: 'post',
    url: `/booking/waiver/add-waiver`,
    data,
  });

  return response.data;
}

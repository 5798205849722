
























































import { Vue, Component } from 'vue-property-decorator';
import Debug from 'debug';
import dayjs from 'dayjs';
import Nav from './components/Nav.vue';
import ProductCard from './components/ProductCard.vue';
import Options from './components/Options.vue';
import { AppModule, BookingModule } from '@/utils/storemodules';
import {
  IDPSlot, ISlot, BookingFlowType, IDPSlotSource,
  IDPSlotWReason, IDPSlotWReasonSource,
} from '@/models/store/booking';
import { GET_SCROLL_Y } from '../../store/modules/app/constants';
import {
  FETCH_RANDOM_SLOT, GET_LOADING , FETCH_PRODUCT,
  SET_DATE, FETCH_AVAILABILITIES, GET_BOOKING_FLOW,
  GET_BOOKING_FLOW_TYPE, TOGGLE_SLOT, GET_SELECTED_SLOTS,
  GET_PRODUCT_SHORT_URL, GET_PRODUCT_ID, GET_DP_SLOT,
  AVOID_DP_SLOT, CLEAR_BOOKING, GET_DP_PRICE,
  GET_PRODUCT_CURRENCY,
} from '../../store/modules/booking/constants';

const debug = Debug('smeetz:booking');

@Component({
  components: {
    Nav,
    ProductCard,
    Options,
  },
})
export default class DynamicPricing extends Vue {
  private moreInfo: boolean = false;
  private showOptions: boolean = false;
  private dpSlotTTL: any = this.$store.state.booking.dpSlotTTL;
  private dpSlotRating: any = this.$store.state.booking.dpSlotRating;
  private rating: number = 0;
  private expiresIn: number = 0;

  @BookingModule.Getter(GET_LOADING) private isLoading!: boolean;
  @BookingModule.Getter(GET_BOOKING_FLOW) private bookingFlowType!: BookingFlowType;
  @BookingModule.Getter('getSlots') private getSlots!: ISlot[];
  @BookingModule.Getter(GET_SELECTED_SLOTS) private slots!: ISlot[];
  @BookingModule.Getter(GET_DP_SLOT) private dpSlot!: IDPSlot;
  @BookingModule.Getter(GET_PRODUCT_ID) private productId!: number;
  @BookingModule.Getter(GET_PRODUCT_SHORT_URL) private shortURL!: string;
  @BookingModule.Getter(GET_DP_PRICE) private price!: string;
  @BookingModule.Getter(GET_PRODUCT_CURRENCY) private currency!: string;
  @BookingModule.Mutation(SET_DATE) private setDate: any;
  @BookingModule.Mutation('toggleCategory') private toggleCategory: any;
  @BookingModule.Mutation(CLEAR_BOOKING) private clearBooking: any;
  @BookingModule.Action(FETCH_RANDOM_SLOT) private fetchRandomSlot: any;
  @BookingModule.Action(FETCH_PRODUCT) private fetchProduct: any;
  @BookingModule.Action(FETCH_AVAILABILITIES) private fetchAvailabilities: any;
  @BookingModule.Action(TOGGLE_SLOT) private toggleSlot: any;
  @BookingModule.Action(AVOID_DP_SLOT) private avoidSlot: any;

  get transparent() {
    return AppModule.getScrollY < 190;
  }

  private async created() {
    this.fetchSlot();
  }

  private async fetchSlot() {

    this.clearBooking();
    // Fetch random slot
    const slot: IDPSlot | undefined = await this.fetchRandomSlot();
    if (!slot) {
      debug('No more dp slots, resetting');
      this.$store.state.booking.avoidedDPSlots = [];
      this.fetchSlot();
      return;
    }
    const slotSource: IDPSlotSource = slot._source;

    // TODO Extract shorname and datetime from slot
    // const shortname = 'conference-des-geologues';
    const shortname = (slotSource && slotSource.product_name);
    const startDateTime = dayjs(slotSource.start_date_time);
    const { schedule_id, price_id, category_id } = slotSource;

    // Set rating and time left for offer if not set
    if (!this.dpSlotRating[slot._id]) {
      // Make sure that the rating is between 4.5 and 5
      this.dpSlotRating[slot._id] = 4.5 + (Math.random() * .5);

      // Make sure that the time expires within 6 to 18 hours
      this.dpSlotTTL[slot._id] = 6 + (Math.random() * 12);
    }

    this.rating = Number(this.dpSlotRating[slot._id].toFixed(1));
    this.expiresIn = Math.round(this.dpSlotTTL[slot._id]);

    const date: Date = startDateTime.toDate();

    // set date
    this.setDate(date);

    // fetch product and slot availabilities
    await this.fetchProduct(shortname);
    await this.fetchAvailabilities();

    // Select slot if multi-day event, otherwise slot is already selected for booking flow 0
    if (this.bookingFlowType !== BookingFlowType.Type0) {
      debug('toggling slot since booking flow is', this.bookingFlowType);
      // select our wanted slot
      for (const s of this.getSlots) {
        if (s.schedule.id === schedule_id) {
          debug('Selecting slot');
          await this.toggleSlot(s);
          break;
        }
      }
      // return;
    }

    // select a category
    const selectedSlot = this.slots[0];

    // select category
    if (selectedSlot && selectedSlot.categories) {
      for (const category of selectedSlot.categories) {
        if (category.categoryId === category_id) {
          debug('Selecting category');
          this.toggleCategory(category);
          break;
        }
      }
    }
  }

  private showMore() {
    if (this.moreInfo || this.showOptions) {
      return;
    }

    this.moreInfo = true;
  }

  private showLess(e: any) {
    this.moreInfo = false;
  }

  private async handleOption(option: string) {
    this.showOptions = false;

    const avoidedSlot: IDPSlotWReason = this.dpSlot;
    avoidedSlot._source.reason = option;

    await this.avoidSlot({slot: avoidedSlot});
    await this.fetchSlot();
  }

  private skip() {
    this.handleOption('Activity');
  }

  private buy() {
    const productId = this.productId;
    const shortName = this.shortURL;
    // build booking url
    this.$router.push(`/product/${productId}/booking/${shortName}`);
  }
}

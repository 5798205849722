import { AccountingEntity } from '@/models/store/booking';
import { NBookingModule } from './storemodules';

export function withoutReplicatePromocode(accountings: AccountingEntity[]) {

  // Stores every accounting promocode that is encountered
  const promoCache: Record<string, AccountingEntity> = {};
  const nonPromoReplicaAccount: AccountingEntity[] = [];

  const noPromoReplicateAccountings = [];
  for (const acc of accountings) {
    // Non promocodes are kep
    // if (acc.paymentMethod !== 'PROMO_CODE') {
    //   nonPromoReplicaAccount.push(acc);
    //   continue;
    // }

    // Skip if this promocode was already visited
    // or it's a promocode with 0 discounted value
    // or it has no discount value
    if (
      promoCache[acc.promo_code_code] ||
      acc.accountingValue === 0
    ) {
      continue;
    }

    // cache the promo and add it to the array to be returned.
    promoCache[acc.promo_code_code] = acc;
    noPromoReplicateAccountings.push(acc);
  }

  return noPromoReplicateAccountings;
}

/**
 * Returns all the promocodes with a given codes
 */
export function getAllPromocodesWithCode(accountingId: number) {

  const appliedCodes = NBookingModule.listPromoApplied;
  const accounting = appliedCodes?.find((acc) => acc.id === accountingId);

  if (!accounting) {
    throw new Error(`No applied accounting found with id ${accountingId}`);
  }

  return appliedCodes?.filter((acc) => acc.promo_code_code === accounting.promo_code_code) || [];
}

import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import Store from '../../index';
import { IFBData, ISocialData, ILoginData } from '@/models/store/auth';
import { IUserData, IEmailVerifyData, IOrgUserData } from '@/models/definitions';

// Adding the n to make sure it's a new modal
@Module({ name: 'user', namespaced: true })
class User extends VuexModule {
  public userEmail: string = '';
  // currentPage number of user space bookings
  public userBookingsCurrentPage: number = 0;
  // whether or not show the goBack button in user space booking summary
  public isBookingSummaryShowGoBackButton: boolean = false;
  // checks if we got from the booking flow to the userspace or not
  public isFromBookingFlow: boolean = false;

  public isOrgUserLoggedIn: boolean = false;

  public customAuthToken: string | null | undefined = undefined;
  public customAuthEmail: string | null | undefined = undefined;
  public authWindowClosed: boolean = false;

  public get isLogedIn() {
    // Using auth module getter which was written in pure vuex
    return Store.getters['auth/isLoggedIn'];
  }

  public get getCustomAuthToken() {
    return this.customAuthToken;
  }

  public get getCustomAuthEmail() {
    return this.customAuthEmail;
  }

  public get isCustomAuthLoggedIn() {
    // This getter is temporary since we're using the new custom way of authorization
    // we still don't have a user object
    // below code should be written inside isLoggedIn() getter once we receive
    // the user object with the response of custom auth
    return !!(this.customAuthEmail && this.customAuthToken);
    // return Store.getters['auth/isCustomAuthLoggedIn'];
  }

  public get user() {
    return Store.getters['auth/getUser'];
  }

  public get userLanguageId(): number {
    return Store.getters['auth/getLangId'];
  }

  @Action
  public customAuthLogOut() {
    // remove token from store
    this.setCustomAuthToken('');

    // remove email from store
    this.setCustomAuthEmail('');
  }

  @Action
  public logOut() {
    // Calling auth module logout
    return this.context.dispatch('auth/logout', null, { root: true });
  }

  @Action
  public fbLogin(payload: IFBData) {
    // Calling auth module fbLogin
    return this.context.dispatch('auth/fbLogin', payload, { root: true });
  }

  @Action
  public gLogin(payload: ISocialData) {
    // Calling auth module gLogin
    return this.context.dispatch('auth/gLogin', payload, { root: true });
  }

  @Action({ rawError: true })
  public login(payload: ILoginData) {
    // Calling auth module login
    return this.context.dispatch('auth/login', payload, { root: true });
  }

  @Action({ rawError: true })
  public register(payload: IUserData) {
    return this.context.dispatch('auth/register', payload, { root: true });
  }

  @Action({ rawError: true })
  public verifyEmail(payload: IEmailVerifyData) {
    return this.context.dispatch('auth/verifyEmail', payload, { root: true });
  }

  @Action({ rawError: true })
  public sendConfEmail(payload: string) {
    return this.context.dispatch('auth/sendConfEmail', payload, { root: true });
  }

  @Action({ rawError: true })
  public resetPass(payload: string) {
    return this.context.dispatch('auth/resetPassReq', payload, { root: true });
  }

  @Action({ rawError: true })
  public setOrgUser(user: IOrgUserData): void {
    this.context.dispatch('auth/setOrgUser', user, { root: true });
  }

  @Mutation
  public setUserEmail(payload: string) {
    this.userEmail = payload;
  }

  @Mutation
  public setCurrentPage(payload: number) {
    this.userBookingsCurrentPage = payload;
  }

  @Mutation
  public setIsShowGoBackButton(payload: boolean) {
    this.isBookingSummaryShowGoBackButton = payload;
  }

  @Mutation
  public setIsFromBookingFlow(payload: boolean) {
    this.isFromBookingFlow = payload;
  }

  @Mutation
  public setCustomAuthEmail(email: string | null | undefined) {
    this.customAuthEmail = email;
  }

  @Mutation
  public setCustomAuthToken(token: string | null | undefined) {
    this.customAuthToken = token;
  }

  @Mutation
  public logOrgUser(logIn: boolean) {
    this.isOrgUserLoggedIn = logIn;
  }

  @Mutation
  public closedAuthWindow(payload: boolean) {
    this.authWindowClosed = payload;
  }
}

export default User;








































import { Component, Vue } from 'vue-property-decorator';
import DiscoverFilter from './presentational/DiscoverFilter.vue';

@Component({
  components: {
    DiscoverFilter,
  },
  metaInfo() {
    return {
      title: this.$t('seo.title-discover') as string,
      description: this.$t('seo.description-discover') as string,
    };
  },
})
export default class Home extends Vue {
  private showFilter: boolean = false;

  // Methods
  private toggleFilter(): void {
    this.showFilter = !this.showFilter;
  }
}


















import { Component, Vue, Prop } from 'vue-property-decorator';
import Terms from '@/components/presentational/Terms.vue';
import { NModalModule } from '../../utils/storemodules';
import { ModalStatus } from '../../models/definitions';


@Component({
  components: {
    Terms,
  },
})
export default class LoginRegisterLink extends Vue {
  @Prop({ default: 'register' })
  public authType!: string;

  private modifyAuthType() {
    if (this.authType === 'register') {
      NModalModule.setStatus(ModalStatus.Login);
      return;
    }

    NModalModule.setStatus(ModalStatus.Register);
  }
}


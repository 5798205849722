import { Module, MutationTree, GetterTree } from 'vuex';
import Debug from 'debug';
import { IRootState } from '@/models/store';
import { IModalState, IModalData } from '@/models/store/modal';
import { ModalStatus } from '@/models/definitions';
import auth from './auth';

const debug = Debug('smeetz:modal');


export const namespaced = true;

export const state: IModalState = {
  status: ModalStatus.Hidden,
  data: {},
};

export const getters: GetterTree<IModalState, IRootState> = {
  getStatus(currentState): ModalStatus | undefined {
    return currentState.status;
  },
  getAuthType(currentState): string {
    const { data } = currentState;
    const authType = data.authType;
    if (authType) {
      return authType;
    }

    return '';
  },
  getPhone(currentState): string {
    const { data } = currentState;
    const phone = data.phone;
    return phone || '';
  },
};

export const mutations: MutationTree<IModalState> = {
  setStatus(currentState, payload: ModalStatus) {
    currentState.status = payload;
  },
  setData(currentState, payload: IModalData) {
    // Object.assign(currentState.data, payload);
    currentState.data = payload;
  },
  setAuthType(currentState, authType: string) {
    debug(`setting authType to: ${authType}`);
    const { data } = currentState;
    currentState.data = Object.assign({}, data, {
      authType,
    });
  },
};

const modal: Module<IModalState, IRootState> = {
  namespaced,
  state,
  getters,
  mutations,
};

export default modal;

























































import { Vue, Component } from 'vue-property-decorator';
import { numeric, minLength } from 'vuelidate/lib/validators';
import { Validator } from '@/utils/validators';
import ModalButton from '@/components/modal/ModalButton.vue';
import { ModalStatus } from '@/models/definitions';
import { ModalModule, AuthModule } from '@/utils/storemodules';

@Component({
  components: {
      ModalButton,
    },
  validations: {
      code: {
        numeric,
        minLength: minLength(4),
      },
    },
  })
export default class MobilePhoneConf extends Vue {
  // private phone: string = '+41333333';
  private code: string = '';
  private responseError: string = '';

  @ModalModule.Getter('getPhone') private phone!: string;
  get enableSubmit() {
  return !this.$v.$error && this.code;
  }

  private close(): void {
    this.$emit('close');
  }
}

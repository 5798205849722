



























import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { IProductPolicies } from '@/models/product';

@Component({})
export default class ProductPolicies extends Vue {
  @Prop() private policiesData!: IProductPolicies;
  private showBooking: boolean = false;
  private isChangebleBooking: boolean = false;

  private triggerBooking() {
    this.showBooking = !this.showBooking;
  }

  private cutBookingPolicy(data: string) {
    if ( data.length && data.length > 300 && !this.showBooking ) {
      this.isChangebleBooking = true;
      this.showBooking = false;
      return data.slice(0, 300) + '...';
    } else {
      this.showBooking = true;
      return data;
    }
  }
}




















































import { Vue, Component, Watch } from 'vue-property-decorator';
import Debug from 'debug';
import VueScript from 'vue-script2';
import Timeout from 'await-timeout/dist/es5';
import dayjs from 'dayjs';
import Navbar from '@/views/NewBooking/components/BookingNav.vue';
import Order from './components/Order.vue';
import Checkout from './components/Checkout.vue';
import Confirmation from './components/Confirmation.vue';
import Payment from './components/Payment.vue';
import Timer from './components/Timer.vue';
import MembershipStepper from './components/MembershipStepper.vue';
import SoldOut from '@/views/Booking/components/SoldOut.vue';
import Footer from '@/components/booking/Footer.vue';
import NewBookingFooter from '@/components/booking/NewBookingFooter.vue';
import {
  NBookingModule as BookingModule, ProductModule, ReferrerModule, NBookingModule, AppModule, OrganizerModule,
} from '@/utils/storemodules';
import {
  shrinkIfChartIsPresent, inIframe, destroyCart, updateWidgetStatus, requestCartInfo, requestCustomLinkInfo,
} from '../../utils/iframe';
import { BookingSteps } from '../../store/modules/newBooking/constants';
import { NNoty } from '@/utils/storemodules';
import { setBookingColors, resetSiteColors } from '@/utils/colors';
import { Meta } from '@/utils/decorators';
import { IProduct, IPicturesEntity } from '@/models/store/product';
import { BookingStatus as ProductBookingStatus } from '@/models/store/product';
import {
  bookingFlowCompleteTracking,
  trackPageView,
  bookingFlowViewItemList,
  trackDynamicPricingTickets,
} from '../../utils/tracking';
import { initBookingChannel } from '@/utils/siteInit';
import { loadCssFile, setLangForMeta } from '@/utils/helpers';
import { NavigationGuardNext, Route } from 'vue-router';
import { isToProduct } from '@/utils/router';
import { AdyenStatus, BookingPaymentStatus, BookingPaymentStatusName,
  BookingStatus, BookingStatusName, DELAY_CALL_TIMEOUT } from '@/models/enums';
import { authorizedSession, fetchProduct } from '@/api/booking';
import { disableQuasarStyling, enableQuasarStyling } from '@/utils/styles';
import { WidgetStatus } from '@/models/iframe';
import { isInProduction } from '@/utils/platform';
// import SidebarWrapper from '@/views/Booking/components/SidebarWrapper.vue';
// import AttendiesSelect from '@/views/Booking/components/AttendiesSelect.vue';
// import RecapMain from '@/views/Booking/components/RecapMain.vue';
// >>>>>>> b30b751fc6e2bb8cb6c873bf4ecbeae511d5b202
import AdyenCheckout from '@adyen/adyen-web';
import { bookingDecorateErrorMessage } from '@/utils/debug';
import { Dialog } from 'quasar';
import { getOrganizerInfoById, getOrganizerData, getShippingTicketsData } from '@/api/organizer';
import { ITicket } from '@/models/store/booking';
import { LoggerService } from '@/services/log';
import Mixpanel, { EVENTS, parseBookingStep } from '@/services/mixpanel';
import { getVisibleTicketsFromStorage } from '@/utils/helpers';
const debug = Debug('smeetz:booking');

@Component({
  components: {
    Navbar,
    Order,
    SoldOut,
    Footer,
    Checkout,
    Confirmation,
    Payment,
    Timer,
    NewBookingFooter,
    MembershipStepper,
  },
})
export default class Booking extends Vue {
  private hideMobile: boolean = false;
  private inIframeWidget: boolean = inIframe();
  private bookingStatus: ProductBookingStatus | null = null;
  private BookingSteps = BookingSteps;
  private windowWidth: number = 0;
  private isLoading = false;
  private isDraft = false; // draft product
  private productIds: number[] = [];
  // Stores adyen payment status
  private adyenStatus: AdyenStatus | null = null;
  private adyenSessionResult: string = '';
  private relatedShow: ITicket | null = null;

  private documentBody: HTMLElement = document.getElementsByTagName('body')[0];
  private showMemStepper = false;
  private isTrackOpenWidget = true;

  get currentStep(): BookingSteps {
    return BookingModule.bookingStep;
  }

  get inIframe(): boolean {
    return inIframe();
  }

  get isMobile() {
    return AppModule.isMobile;
  }

  get product(): IProduct | null {
    return ProductModule.product;
  }

  private get isTablet() {
    if (AppModule.isTablet && window.innerWidth < 968 || AppModule.isMobile) {
      return true;
    }
  }

  // Checks if we are on the order page in cart.
  get orderCartView(): boolean {
    if (inIframe() && AppModule.isCartWidget) {
      return BookingModule.bookingStep === BookingSteps.Order;
    }

    return false;
  }

  // This shows whether the booking flow should get a padding at the bottom
  // necessary so that the call to action (book now section) doesn't cover the footer.
  // Required only for the order step
  private get withPadding(): boolean {
    if (this.currentStep !== BookingSteps.Order) {
      return false;
    }

    const { isFree, total } = BookingModule.recapInfo;
    return isFree || (total > 0);
  }

  @Watch('currentStep')
  public onStepchange(step: BookingSteps, stepOld: BookingSteps) {
    if (step !== BookingSteps.Confirmation) {
      // Step Tracking
      Mixpanel.track(parseBookingStep(step), {});
    }

    if (inIframe()) {
      const topOfBookingFlow = document.getElementById('new-booking-nav');
      if (topOfBookingFlow) {
        topOfBookingFlow.scrollIntoView();
      }
    } else {
      window.scrollTo(0, 0);
    }

    if (step !== BookingSteps.Order) {
      if (inIframe()) {
        shrinkIfChartIsPresent();
        this.isTrackOpenWidget = false;
      }
    }
  }

  @Watch('$store.state.auth.lang')
  public async onLangChanged(newLang: string) {
    this.isLoading = true;
    try {
      // await BookingModule.fetchTickets(Number(this.$route.params.id));
      // For cart widgets, let's fetch the products tickets of the product IDs sent from
      // from the cart widget in the widgetOp

      if (AppModule.isCartWidget) {
        const productIds = NBookingModule.receivedProductIdsFromWidgetOp;
        if (productIds && productIds.length > 0) {
          BookingModule.setProdsTickets({});
          await BookingModule.fetchProductsTickets(productIds);
          return;
        }
      }

      BookingModule.setProdsTickets({});
      await BookingModule.fetchProductsTickets(this.productIds);
    } catch (error) {
      throw Error(bookingDecorateErrorMessage(JSON.stringify(error)));
    } finally {
      this.isLoading = false;
    }
  }

  public async created() {
    window.scrollTo(0, 0);
    const { bookingId, bookingToken,
      linkedRetailIds, linkedTicketIds,
      linkedTicketIdsMin, linkedTicketIdsMax } = this.$route.query as {[s: string]: string};
    const retailIds = ReferrerModule.retailIds;

    debug('href', location.href);

    // booking already started and we want to complete it here
    const continueBooking = (!status && bookingId && bookingToken);
    if (ReferrerModule.memType) {
      enableQuasarStyling();
    }

    if (retailIds) {
      this.isLoading = true;
      try {
        await ProductModule.fetchRetail(retailIds.split(',').map((id) => Number(id)));
      } catch (error) {
        throw error;
      } finally {
        this.isLoading = false;
      }
    }

    await this.initBooking();

    // OpenWidget Tracking
    if (this.isTrackOpenWidget && this.currentStep === BookingSteps.Order) {
      Mixpanel.track(EVENTS.OpenWidget, {});
    }

    // setTimeout is set so that after the widget is reloaded at the step from Payment to Confirmation,
    // the Confirmation event contains data about the group(groupId and groupName)
    setTimeout(() => {
      // Step Tracking
      Mixpanel.track(parseBookingStep(this.currentStep), {});
    }, 1000);

    // Retrieves the custom links and custom texts data.
    if (inIframe()) {
      requestCustomLinkInfo();
    }
    // Retreive cart info on cart widgets if booking was already started.
    // cart info will include membership info if memebership is present.
    if (AppModule.isCartWidget && continueBooking) {
      LoggerService.info('booking already started');
      requestCartInfo();
      disableQuasarStyling();
      return;
    }

    // No booking was started and we have membership options
    if (!continueBooking && ReferrerModule.memType) {
      LoggerService.info('New booking with membership');
      // TODO: Delete commented part once client approves
      // if (ReferrerModule.memType === 1) {
      //   await this.chooseMembership();
      // } else if (ReferrerModule.memType === 2) {
      //   this.showMemStepper = true;
      // }
      this.showMemStepper = true;
    }
  }

  private async initBooking() {
    this.isLoading = true;
    initBookingChannel(this.$route);
    // // Track page view
    // this.$gtm.trackView('', this.$route.fullPath);

    const { short_name, status, id: productId } = this.$route.params;

    // At the beginning of the booking, make sure that tracking is set
    await AppModule.waitForTracking();

    const {
      bookingId, bookingToken, utm_source, tcart,
      cprods: cartProducts, ocol,
      redirectResult, sessionId, // Adyen query parameters
    } = this.$route.query as {[s: string]: string};
    const serverData = {bookingToken, bookingId: Number(bookingId)};
    const productIds = (cartProducts || productId).split(',').map((id) => Number(id));
    this.productIds = productIds;

    // booking already started and we want to complete it here
    const continueBooking = (!status && bookingId && bookingToken);

    // Did we get redirected by Adyen
    // This happens only if we are dealing with a payment that causes
    // the adyen sdk to break out of our site
    const isAdyenRedirect = sessionId && redirectResult;

    debug('isAdyenRedirect', isAdyenRedirect);
    if (isAdyenRedirect) {
      LoggerService.info(`isAdyenRedirect: ${isAdyenRedirect}`);
      await this.handleAdyen();
    }

    // Fetching this user group will attach it's tracking id to a group
    // TODO, in the future, make sure that we support multiple product ids.
    try {
      if (productIds[0] !== 0) {
        await ProductModule.fetchUserGroup(productIds[0]);
      }
    } catch (error) {
      setTimeout(() => {
        debug('Error when fetching user group');
        const errorInfo = `fetchUserGroup with Id: ${productIds[0]}`;
        LoggerService.info(bookingDecorateErrorMessage(JSON.stringify(error), errorInfo));
        // throw Error(bookingDecorateErrorMessage(JSON.stringify(error), errorInfo));
      }, 0);
    }

    if (utm_source) {
      ReferrerModule.setReferr(utm_source);
    }

    // Don't render any tickets if not on confirmation page on Carts
    if (AppModule.isCartWidget && !status) {
      if (productIds[0] !== 0) {
        await ProductModule.fetchProducts(productIds);
        const product = ProductModule.product as IProduct;
        if (product && product.organiser) {
          BookingModule.setOrganizerInfo(product.organiser);
        }
      }
      // await BookingModule.fetchProductsTickets(productIds);
      // ProductModule.fetchProducts(productIds).then(() => {
      //   return this.fetchOrganiserInfo();
      // });

      // Fetch booking if already present
      if (continueBooking) {
        NBookingModule.setBookingId(Number(bookingId));
        NBookingModule.setBookingToken(bookingToken);
        await BookingModule.fetchBooking(serverData);
        const bookingRes = BookingModule.bookingRes;
        if (bookingRes && bookingRes.bookingState ===  BookingStatus.Abandoned) {
          NNoty.createNewNoty({
            message: this.$t('cart.expired') as string,
            period: 8000,
            type: 'error',
          });

          // Restart the booking, but make sure we keep previously fetched stuff :)
          const previouslyFetchedTickets = NBookingModule.prodsTickets;
          const previouslyFetchedProducts = ProductModule.products;
          NBookingModule.clearBooking();
          NBookingModule.setProdsTickets(previouslyFetchedTickets);
          ProductModule.setProducts(previouslyFetchedProducts);
        }
      }
      // Commenting this for now cause it's causing issues for this
      // https://smeetz.atlassian.net/browse/IBO-473
      // else {
      //   if (productIds && (productIds.length === 1)) {
      //     await NBookingModule.fetchProductsTickets(productIds);
      //   }
      // }
      if (productIds[0] === 0 && OrganizerModule.id) {
        const {data} = await getOrganizerInfoById(OrganizerModule.id);
        OrganizerModule.setInfo(data);
        LoggerService.info(`Organizer module is set: ${OrganizerModule.id}`);
      } else {
        await OrganizerModule.fetchOrganiserInfo();
        LoggerService.info(`Organizer module is set: ${OrganizerModule.id}`);
        /**
         * Let's handle here specific case for shipping
         * When we reload a page that contains a cart widget,
         * the pre-loaded widget after refresh does not contain retail Ids
         * So we check if we have already added a retail with weight > 0 it means that the retail is delivreable
         */
        const skipRetailCheck = BookingModule.bookingRes &&
          BookingModule.bookingRes.bookingRecap.categories?.some((t) =>
            t.weight  && t.weight > 0 && t.isDeliverable) ? true : false;
        this.checkShipping(skipRetailCheck);
      }
      this.isLoading = false;
      updateWidgetStatus(WidgetStatus.Loaded);
      return;
    } // end of cart widget

    // incase a booking success or failure
    if (status || continueBooking) {
      NBookingModule.setBookingId(Number(bookingId));
      NBookingModule.setBookingToken(bookingToken);
      // fetch product
      // fetch booking
      try {
        // const { product } = await ProductModule.fetchProduct(short_name);
        await ProductModule.fetchProducts(productIds);
        const product = ProductModule.lastProduct as IProduct;
        const brandingColor = product && product.organiser
          && product.organiser.branding
          && product.organiser.branding.smeetzPrimaryColor;

        if (product && product.organiser) {
          BookingModule.setOrganizerInfo(product.organiser);
        }
        if (!inIframe() && !ocol) {
          if (brandingColor) {
            // we'll commit a mutation to state.primaryColor if the product have a branding color
            AppModule.setColor(brandingColor);
          } else {
            // use smeetz color if there is no organizer color, or there is more than one product to buy
            resetSiteColors();
          }
        }
        // BookingModule.fetchBooking({bookingToken, bookingId: Number(bookingId)}),

        // for failures, restore booking state and Move to next step
        // Failures could either be indicated by the status query param (Wallee)
        // Or Adyen payment status
        if (status === 'failure' ||
        (this.adyenStatus === AdyenStatus.Refused) ||
        (this.adyenStatus === AdyenStatus.Cancelled) ||
        (this.adyenStatus === AdyenStatus.NetworkError)) {
          LoggerService.info(`Booking status: ${status}`);
          LoggerService.info(`Adyen status: ${this.adyenStatus}`);
          const errorMessage = this.adyenStatus === AdyenStatus.Cancelled ?
            this.$t('user-space.booking-summary.payment-status') as string + ': ' +
            this.$t('user-space.booking-summary.payment-status-values.cancelled') as string :
            this.adyenStatus === AdyenStatus.NetworkError ? this.$t('error.payment.slow-internet') as string :
            this.$t('booking-flow.declined-payment-message') as string;

          NNoty.createNewNoty({
            message: errorMessage,
            period: 8000,
            type: 'error',
          });
          await BookingModule.fetchBooking(serverData);
          // await BookingModule.fetchTickets(Number(this.$route.params.id));
          await BookingModule.fetchProductsTickets(productIds);
          if (AppModule.isCartWidget) {
            const categoryIds = getVisibleTicketsFromStorage();
            NBookingModule.addCartPrice({productIds, categoryIds});
          }
          // BookingModule.restoreBookingState(serverData);
          BookingModule.stepForward();

          // clear the stored state after 10 seconds. To overcome the fact the user can
          // refresh immediately.
          // setTimeout(
          //   () => {
          //     BookingModule.clearStoredState();
          //   },
          //   10000,
          // );

        } else if (status === 'success') {
          LoggerService.info(`Booking with success status in route params`);
          // for success, move to last step
          await BookingModule.fetchBooking(serverData);
          const bookingRes = BookingModule.bookingRes;
          await bookingFlowCompleteTracking(bookingRes as any, bookingId as string, product);
          if (bookingRes && bookingRes.bookingReferrer) {
            ReferrerModule.setReferr(bookingRes.bookingReferrer);
          }

          LoggerService.info(`isAdyenRedirect: ${!!isAdyenRedirect}`);
          if (this.adyenStatus) {
            LoggerService.info(`Adyen status: ${this.adyenStatus}`);
          }

          // Ensure that the booking was successful and move to confirmation page
          const bookingStatus = bookingRes && bookingRes.bookingState;
          const successBookingsStates = [BookingStatus.Completed];
          // const adyenPendingStatuses = [AdyenStatus.Pending, AdyenStatus.Received];

          // Call api to not cancel tickets if we receive Authorized from Adyen and booking status is pending
          if (bookingStatus === BookingStatus.PendingPayment &&
            this.adyenStatus === AdyenStatus.Authorised &&
            sessionId &&
            this.adyenSessionResult) {
            try {
              // tslint:disable-next-line:max-line-length
              const result = await authorizedSession(serverData.bookingId, serverData.bookingToken, sessionId, this.adyenSessionResult);
              LoggerService.info(`Authorized session after adyen redirect: ${JSON.stringify(result)}`);
            } catch (error) {
              LoggerService.info(`WARNING: Authorized session after adyen redirect failed with sessionId:${sessionId} && sessionResult:${this.adyenSessionResult}`);
              setTimeout(() => {
                throw Error(bookingDecorateErrorMessage(JSON.stringify(error),
                  'Authorized session after adyen redirect'));
              }, 0);
            } finally {
              LoggerService.info(`open pending payment dialog`);
              this.openPendingPaymentDialog();
            }
            return;
          }

          if (bookingStatus && successBookingsStates.includes(bookingStatus)) {
            LoggerService.info(`Step to confirmation because booking status is === ${bookingStatus}`);
            // Ensure that cart is destroyed. Otherwise it remains on organiser site
            if (AppModule.isCartWidget) {
              destroyCart();
            }
            BookingModule.stepTo(BookingSteps.Confirmation);
            BookingModule.stopTimer();
            return;
          } else {
            // We are still waiting payments for adyen
            if (bookingStatus === BookingStatus.PendingPayment) {
              LoggerService.info(`Pending payment`);
              LoggerService.info(`Adyen status: ${this.adyenStatus}`);
              LoggerService.info(`booking status ${bookingStatus}`);
              // Check the payment status
              await BookingModule.fetchBookingPaymentStatus(serverData);
              const bookingPaymentStatusRes = BookingModule.bookingPaymentStatus;
              const FailedBookingPaymentStatus = [BookingPaymentStatus.FAILED, BookingPaymentStatus.CANCELLED];

              if (bookingPaymentStatusRes && bookingPaymentStatusRes.status === BookingPaymentStatus.PENDING) {
                LoggerService.info(`open pending payment dialog`);
                this.openPendingPaymentDialog();
                return;
              } else if (
                bookingPaymentStatusRes && bookingPaymentStatusRes.status === BookingPaymentStatus.COMPLETED) {
                // Ensure that cart is destroyed. Otherwise it remains on organiser site
                if (AppModule.isCartWidget) {
                  destroyCart();
                }
                BookingModule.stepTo(BookingSteps.Confirmation);
                BookingModule.stopTimer();
                return;
              } else if (
                bookingPaymentStatusRes && FailedBookingPaymentStatus.includes(bookingPaymentStatusRes.status)) {
                const errorMessage =  `Booking payement status:
                ${JSON.stringify(BookingPaymentStatusName[bookingPaymentStatusRes.status])} \n
                & reason is: ${bookingPaymentStatusRes.reason}`;
                setTimeout(() => {
                  throw Error(bookingDecorateErrorMessage(errorMessage));
                }, 0);
              }
            }

            if (bookingStatus !== BookingStatus.Completed) {

              let errorMessage = bookingStatus !== null ? `Booking status: ${JSON.stringify(BookingStatusName[bookingStatus])}` : `Booking status is not set`;
              // We have an issue with booking state
              // throw an error so that we get notified about it.

              if (this.adyenStatus) {
                errorMessage += (' --- ' + JSON.stringify(this.adyenStatus));
              }
              setTimeout(() => {
                LoggerService.info(bookingDecorateErrorMessage(errorMessage));
              }, 0);
              // Notify user that there was a booking error.
              // this is a rare case.
              NNoty.createNewNoty({
                period: 4000,
                message: 'Booking error',
                type: 'error',
              });
            }
          }
        } else {
          const serverDataWithPackages = {
            ...serverData,
            includePackages: true,
          };
          await BookingModule.fetchBooking(serverDataWithPackages);
          // BookingModule.fetchTickets(Number(this.$route.params.id));
          BookingModule.fetchProductsTickets(productIds);
          // BookingModule.restoreBookingState(serverData);

          // if we are coming from a failed payment because adyen
          // localstorage which doesn't work in iframes with third
          // party cookies disabled.
          // So, we move to checkout, then we move 1 step
          // forward to the payment page.
          if (ReferrerModule.fromWidget) {
            BookingModule.stepTo(BookingSteps.Checkout);
          }
          // if we are not coming from adyen failure
          // it means we are coming from product calendar
          // continue booking flow.
          BookingModule.stepForward();
        }
      } finally {
        this.isLoading = false;
      }
    } else {
      try {
        // const { product } = await ProductModule.fetchProduct(short_name);
        if (tcart) {
          AppModule.setWidgetType('cart');
        }
        await ProductModule.fetchProducts(productIds);
        const product = ProductModule.lastProduct as IProduct;
        if (product && product.organiser) {
          BookingModule.setOrganizerInfo(product.organiser);
        }
        // bypass this check if the organizer wants to show hidden tickets
        if ( product && product.booking.status !== 1 && !ReferrerModule.categories) {
          this.bookingStatus = product.booking.status;
          if (this.bookingStatus === 0 && product.statusName === 'Draft') {
            // this product is NOT_BOOKABLE and draft
            this.isDraft = true;
          }

          if (inIframe()) {
            updateWidgetStatus(WidgetStatus.Loaded);
          }
          return;
        }

        // await BookingModule.fetchTickets(Number(this.$route.params.id));

        await NBookingModule.fetchProductsTicketsWithoutDates(productIds);
        this.isLoading = false;
        await NBookingModule.fetchProductsTicketsDates(productIds);
        // await BookingModule.fetchProductsTickets(productIds);
        // await Promise.all([
        //   BookingModule.fetchTickets(Number(this.$route.params.id)),
        //   ProductModule.fetchProduct(short_name),
        // ]);
        trackDynamicPricingTickets(productIds);
        let brandingColor;
        if (product) {
          bookingFlowViewItemList(product, 0, inIframe() ? 'Iframe' : 'Flow');
          brandingColor = product.organiser
            && product.organiser.branding
            && product.organiser.branding.smeetzPrimaryColor;
        }

        if (!inIframe() && !ocol) {
          if (brandingColor) {
            // we'll commit a mutation to state.primaryColor if the product have a branding color
            AppModule.setColor(brandingColor);
          } else {
            // use smeetz color if there is no organizer color, or there is more than one product to buy
            resetSiteColors();
          }
        }
      } catch (err) {
        const data = err && err.response && err.response.data;
        if (data && (data.status === 'error')) {
          const msgs = data.messages;
          if (msgs && msgs.length && msgs.includes('User has not enough rights to view this product.')) {
            this.isDraft = true;
          }
        } else {
          throw Error(bookingDecorateErrorMessage(JSON.stringify(err)));
        }
      } finally {
        this.isLoading = false;
        if (inIframe()) {
          updateWidgetStatus(WidgetStatus.Loaded);
        }
      }
    }
    if (NBookingModule.getPreselectObject) {
      await NBookingModule.preselectTickets();
    }
    this.isLoading = false;

    trackPageView(this.$route);

    window.addEventListener('resize', this.handleResize);
    this.handleResize();

    // set activity as not bookable of no tickets are present
    // Avoid this check if we are continuing the booking or on a failure/success confirmation
    // page.
    if (!status
      && !continueBooking && NBookingModule.filteredTickets.length === 0
      && !ProductModule.visibleProductCategories.length
    ) {
      this.bookingStatus = ProductBookingStatus.BOOKING_NOT_BOOKABLE;
    }

    // fetch organiser info;
    await OrganizerModule.fetchOrganiserInfo();

    // Let's handle here specific case for shipping
    this.checkShipping();
  }

  private async checkShipping(skipRetailCheck: boolean = false) {
    const {data: { GroupRead: {shippingMethods} }} = await getOrganizerData(Number(OrganizerModule.id));

    const dynamicShippingMethods = ProductModule.retails.some((t) =>
      t.shippingDetails
      && t.shippingDetails.length > 0
      && t.shippingDetails[0].isDelivrable);

    if ( dynamicShippingMethods || skipRetailCheck) {
      const dynamicShippingTicketsIds = JSON.parse(shippingMethods);
      const {data: { ProductSlotCategoryList }} = await getShippingTicketsData(dynamicShippingTicketsIds);
      const dynamicShippingProducts = ProductSlotCategoryList as ITicket[];
      BookingModule.setDynamicShippingTickets(dynamicShippingProducts);
      return;
    }

    if ( shippingMethods ) {
      const shippingTicketsIds = JSON.parse(shippingMethods);
      const {data: { ProductSlotCategoryList }} = await getShippingTicketsData(shippingTicketsIds);
      const shippingProducts = ProductSlotCategoryList as ITicket[];
      // Filter the timeslots array to keep only objects with bookable === 1
      const filteredTimeslots = shippingProducts[0].timeslots.filter( (timeslot) => timeslot.bookable === 1);
      // Update the original object with the filtered timeslots
      shippingProducts[0].timeslots = filteredTimeslots;
      BookingModule.setShippingTickets(shippingProducts);
    }
  }

  private setSelectedShow(ticket: ITicket) {
    this.relatedShow = ticket;
  }

  private openPendingPaymentDialog() {
    // Ensure that cart is destroyed. Otherwise it remains on organiser site
    if (AppModule.isCartWidget) {
      destroyCart();
    }
    // we set Is pending payment to true so that in confirmation page we won't show 'download ticket ...'
    NBookingModule.setIsPaymentPending(true);
    BookingModule.stepTo(BookingSteps.Confirmation);
    enableQuasarStyling();
    const msg1 = this.$t('new-booking-flow.pending.msg1');
    const msg2 = this.$t('new-booking-flow.pending.msg2');
    const msg3 = this.$t('new-booking-flow.pending.msg3');
    const msg4 = this.$t('new-booking-flow.pending.msg4');
    const msg5 = this.$t('new-booking-flow.pending.msg5');
    Dialog.create({
      title: this.$t('new-booking-flow.pending.title') as string,
      message: `<p>` + msg1 + ` <strong>` + msg2 + `</strong> ` + msg3
        + `</p> <p>` + msg4 + `</p>` + msg5,
      html: true,
      persistent: true,
    }).onOk(() => {
      disableQuasarStyling();
    });
  }

  private chooseMembership() {
    this.$q.dialog({
      title: this.$t('membership.title') as string,
      message: this.$t('membership.request') as string,
      cancel: true,
      persistent: true,
    }).onOk(() => {
      // console.log('>>>> OK')
      // this.documentBody.classList.remove('smtz-q-app');
      this.showMemStepper = true;
    }).onCancel(() => {
      // console.log('>>>> second OK catcher')
      // this.documentBody.classList.remove('smtz-q-app');
      disableQuasarStyling();
    });
  }

  private hideStepper() {
    this.showMemStepper = false;
    disableQuasarStyling();
  }

  @Meta
  private metaInfo() {
    return {
      title: this.product ? this.$t('common.booking') + (this.product as IProduct).name + ' | Smeetz' : '',
      link: [
        { rel: 'canonical', href: location.protocol + '//' + location.host + location.pathname },
      ],
      meta: [
        { hid: 'title', name: 'title',
          content: this.product ? this.$t('common.booking') + (this.product as IProduct).name + ' | Smeetz' : '' },
        { hid: 'description', name: 'description', content: this.product ? (this.product as IProduct).teaser : '' },
        { property: 'fb:admins', content: '100001288015151' },
        { hid: 'og:title', property: 'og:title',
          content: this.product ? this.$t('common.booking') + (this.product as IProduct).name + ' | Smeetz' : ''},
        { hid: 'og:description', property: 'og:description',
          content: this.product ? (this.product as IProduct).teaser : '' },
        { hid: 'og:type', property: 'og:type', content: 'page' },
        { hid: 'og:url', property: 'og:url', content: location.protocol + '//' + location.host + location.pathname },
        { hid: 'og:image', property: 'og:image',
          content: this.product &&
          (this.product as IProduct).pictures &&
          (this.product as IProduct).pictures?.length ?
            ((this.product as IProduct).pictures as IPicturesEntity[])[0].url : ''},
        { hid: 'og:image:alt', property: 'og:image:alt',
          content: this.product ? (this.product as IProduct).name + ' ' + this.$t('common.image') : '' },
        { hid: 'og:locale', property: 'og:locale',
          content: setLangForMeta(this.$route.params.locale || this.$i18n.locale)},
        // Uncomment if we will show content relateed to lang in url
        // { hid: 'noindex', name: 'robots, follow', content: 'noindex' },
      ],
    };
  }

  private destroyed() {
    window.removeEventListener('resize', this.handleResize);
    // Lets clear products stored during the booking process
    NBookingModule.clearBooking();
  }

  private beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
    // we're leaving booking flow, so lets clear the color we used from css and from state.primaryColor
    // only when not going to product page && when we aren't reloading like in free booking
    const reload = to.query.reload;
    if (!isToProduct(to) && !reload) {
      AppModule.clearBookingColors();
    }
    return next();
  }

  private handleResize() {
    this.windowWidth = window.innerWidth;
  }

  private async handleAdyen() {

    const sessionId = new URLSearchParams(location.search).get('sessionId');
    const redirectResult = new URLSearchParams(location.search).get('redirectResult');
    debug('sessionId', sessionId);
    debug('redirectResult', redirectResult);
    // Fetch adyen js & css
    // loadCssFile('https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/5.0.0/adyen.css');
    // await VueScript.load('https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/5.0.0/adyen.js');

    // This configuration is only for submitting the redirect result
    const configuration = {
      environment: isInProduction() ? 'live' : 'test',
      clientKey: process.env.VUE_APP_ADYEN_KEY,
      session : {id: sessionId},
      onPaymentCompleted: this.processPaymentResult,
      onError: this.processPaymentResult,
    };
    // Initiate checkout object
    // const checkout = await (window as any).AdyenCheckout(configuration);
    const checkout = await AdyenCheckout(configuration);
    checkout.submitDetails({details: {redirectResult}});

    // Wait for adyen status to be recieved
    while (!this.adyenStatus) {
      await Timeout.set(300);
    }
    debug('Adyen status is recieved and set', this.adyenStatus);

  }
  private async processPaymentResult(paymentRes: any, component: any) {
    switch (paymentRes.resultCode) {
      // Successful payment
      case AdyenStatus.Authorised:
      case AdyenStatus.Pending:
      case AdyenStatus.Received:
      case AdyenStatus.Refused:
      case AdyenStatus.Cancelled:
        this.adyenSessionResult = paymentRes.sessionResult || '';
        this.adyenStatus = paymentRes.resultCode;
        break;
      default:
        if (paymentRes.name === AdyenStatus.NetworkError) {
          this.adyenStatus = paymentRes.name;
        } else {
          this.adyenStatus = paymentRes;
          // These are different errors that could be thrown by Adyen
          // Let's notify team about them for now and we will handle the booking
          // based on the booking state
          debug('Error from Adyen');
          throw new Error(`Unhandled Adyen status: ${JSON.stringify(paymentRes)}`);
        }
    }
  }
}

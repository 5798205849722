








































import { Vue, Component, Prop } from 'vue-property-decorator';
import Select from '@/components/presentational/NewSelect.vue';
import Search from '@/components/product/ProductSearch.vue';
import ModalFilters from './ModalFilters.vue';
import { ProductsModule } from '@/utils/storemodules';

@Component({
  components: {
    Select,
    Search,
    ModalFilters,
  },
})
export default class SearchBar extends Vue {
  @Prop({default: false}) private isMobile!: boolean;
  @Prop({default: () => []}) private regionsItems!: any[];
  @Prop({default: () => []}) private categoriesItems!: any[];
  private currentSort: string = '';
  private showFilters = false;

  get sortBy() {
    // return ['Most popular', 'Price(Lowest first)'];
    return [this.$t('field.most-popular'), this.$t('field.price-sort-asc')];
  }

  get sortMap() {
    return {
      [(this.$t('field.most-popular') as string)]: ['average_occupancy', 'desc'],
      [(this.$t('field.price-sort-asc') as string)]: ['advertised_price', 'asc'],
    };
  }

  get searchString() {
    return ProductsModule.query;
  }

  private clearQuery() {
    ProductsModule.setOptions({query: ''});
  }

  private setSelectValue(item: string) {
    this.$emit('sortBy', item);
    this.currentSort = item;
    const sortArray = (this.sortMap as any)[item];

    ProductsModule.setOptions({
      sort: sortArray[0],
      sortDirection: sortArray[1],
    });

    ProductsModule.search();
  }
}

import { IAttendee, IGQLProductSlotState } from '@/models/store/booking';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { RefundSteps } from './constants';



@Module({ namespaced: true, name: 'rbooking' })
export default class RefundBooking extends VuexModule {
    public slots: IGQLProductSlotState[] | null = null;
    public currentStep: RefundSteps = 1;
    public isRefunded: boolean = false;
    public organizerEmail: string = '';
    public selectedSlots: IGQLProductSlotState[] = [];
    public attendees: IAttendee[] = [];

      // GROUP the tickets by their price names
    get slotsGroupedByPriceName() {
        if (this.slots) {
          return this.slots.reduce(
            (groupedSlots: any, slot: IGQLProductSlotState) => {
            const ProductName = slot.ProductSlotCategoryRead.ProductRead.Name;
            const CategoryName = slot.ProductSlotCategoryRead.CategoryName;
            const PriceName = slot.PriceName;
            groupedSlots[`${ProductName}/${CategoryName}/${PriceName}`] =
            groupedSlots[`${ProductName}/${CategoryName}/${PriceName}`] || [];
            groupedSlots[`${ProductName}/${CategoryName}/${PriceName}`].push(slot);
            return groupedSlots;
            }, Object.create(null));
        }
    }

    get selectedGroupedSlots() {
        if (this.selectedSlots) {
          return this.selectedSlots.reduce(
            (groupedSlots: any, slot: IGQLProductSlotState) => {
                const ProductName = slot.ProductSlotCategoryRead.ProductRead.Name;
                const CategoryName = slot.ProductSlotCategoryRead.CategoryName;
                const PriceName = slot.PriceName;
                groupedSlots[`${ProductName}/${CategoryName}/${PriceName}`] =
                groupedSlots[`${ProductName}/${CategoryName}/${PriceName}`] || [];
                groupedSlots[`${ProductName}/${CategoryName}/${PriceName}`].push(slot);
                return groupedSlots;
            }, Object.create(null));
        }
    }
    @Mutation
    public setAttendees(attendees: any) {
        this.attendees = attendees;
    }
    @Mutation
    public setSlots(slots: IGQLProductSlotState[]) {
        this.slots = slots;
    }
    @Mutation
    public setCurrentStep(step: number) {
        this.currentStep = step;
    }
    @Mutation
    public setSelectedSlots(selectedSlots: IGQLProductSlotState[]) {
        this.selectedSlots = selectedSlots;
    }
    @Mutation
    public setIsRefunded(bool: boolean) {
        this.isRefunded = bool;
    }
    @Mutation
    public setOrganizerEmail(email: string) {
        this.organizerEmail = email;
    }
    @Action({ rawError: true })
    public setCurrentSlots(payload: {selectedSlots: IGQLProductSlotState[], add: boolean}) {
        const slotsIds = payload.selectedSlots.map((item) => item.Id);
        const slots = payload.add ? this.selectedSlots!.concat(payload.selectedSlots) :
        this.selectedSlots!.filter((item) => !slotsIds.includes(item.Id));
        this.context.commit('setSelectedSlots', slots);
    }

}

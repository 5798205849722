
















































import { Vue, Component, Mixins } from 'vue-property-decorator';
import RetailMixin from '@/mixins/Retail';
import Select from '@/components/presentational/NewSelect.vue';
import { EVSelect } from '@/models/events';

@Component({
  components: {
    Select,
  },
})
export default class AddonMiniCard extends Mixins<RetailMixin>(RetailMixin) {
  private EVSelect = EVSelect;
  private mounted() {
    this.isFullCartAddon = true;
  }

}

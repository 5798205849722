import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators';
// import { getOrganizerInfo } from '@/api/organizer';
import { IActivity, IUserActivity } from '@/models/store/region';
import { client } from '@/utils/searchConfig';
// import { IOrganizerState, IOrganizerMainInfo } from '@/models/organizer';
// import moment from 'moment';
// import { productNearestDate } from '@/utils/helpers';
import { ISearchOptions, ProductLocation } from '@/models/store/products';
import { productNearestDate, dateSortComp } from '@/utils/helpers';
import dayjs from 'dayjs';
import { inIframe } from '@/utils/iframe';

@Module({ name: 'products', namespaced: true })
class Products extends VuexModule {
  public isLoading = false;
  public products: IActivity[] = [];
  public userUpcomingBookings: IUserActivity[] = [];
  public userPastBookings: IUserActivity[] = [];
  // public topSellersActivities: IActivity[] = [];
  // public info: IOrganizerMainInfo | null = null;

  public from: Date | null = null;
  public to: Date | null = null;
  public sort: string = '';
  public sortDirection: 'asc' | 'desc' = 'asc';
  public query: string = '';
  public page: number = 1;
  public limit: number = 40;
  public andFilters: any[] = [];
  public searchFields: any = { organiser_id: {} };
  public smeetzChannel: number[] = inIframe() ? [0, 1] : [1];
  public smeetzCategory: string[] = [];
  public productLocation?: ProductLocation = { center: '', distance: -1, unit: 'km' };
  public ignoredLocation: ProductLocation = { center: '', distance: -1, unit: 'km' };

  get searchOptions() {
    const { sorts, fields, filtres } = this;
    const options: any = {
      page: {
        current: this.page,
        size: this.limit,
      },
    };

    if (fields) {
      options.search_fields = fields;
    }

    if (sorts) {
      options.sort = sorts;
    }

    if (filtres) {
      options.filters = filtres;
    }

    return options;
  }

  get fields() {
    return this.searchFields;
  }

  get sorts() {
    if (this.sort && this.sortDirection) {
      return {
        [this.sort]: this.sortDirection,
      };
    }

    return null;
  }

  get filtres() {
    const from = this.from;
    const to = this.to;

    // construct and filters. By default, from is today
    const datesFilters: any = {
      dates: {
        from: from ? dayjs(from).toISOString() : dayjs().toISOString(),
      },
    };

    // Set to only if present
    if (to) {
      datesFilters.dates.to = dayjs(to).toISOString();
    }
    // if (this.andFilters.length) {
    const f = {
      all: [...this.andFilters,
        datesFilters,
      { smeetz_channel: this.smeetzChannel },
      ],
    };

    if (this.smeetzCategory && this.smeetzCategory.length > 0) {
      f.all.push({ smeetz_category: this.smeetzCategory.map((c) => c.toLowerCase()) });
    }

    // for a location to be considered in the filtering it should be not equal to the initial imaginary location
    if (this.productLocation
      && this.productLocation.center !== ''
      && this.productLocation.distance !== -1) {
      f.all.push({ product_location: this.productLocation });
    }

    return f;
  }

  @Mutation
  public setLoading(loading: boolean) {
    this.isLoading = loading;
  }

  @Mutation
  public setSmeetzChannel(smeetzChannel: number[]) {
    this.smeetzChannel = smeetzChannel;
  }

  @Mutation
  public setSmeetzCategory(smeetzCategory: string[]) {
    this.smeetzCategory = smeetzCategory;
  }

  @Mutation
  public setProductLocation(productLocation?: ProductLocation) {
    if (productLocation) {
      this.productLocation = productLocation;
    } else {
      this.productLocation = this.ignoredLocation;
    }
  }

  @Mutation
  public setQuery(query: string) {
    this.query = query;
  }

  @Mutation
  public setTo(toDate: Date | null) {
    this.to = toDate;
  }

  @Mutation
  public setFrom(fromDate: Date | null) {
    this.from = fromDate;
  }

  @Mutation
  public setOptions(payload: ISearchOptions) {
    for (const optionName in payload) {
      if (optionName) {
        (this as any)[optionName] = (payload as any)[optionName];
      }
    }
  }

  @Mutation
  public setProducts(products: IActivity[]) {
    this.products = products;
  }

  @Mutation
  public setUserUpcomingBookings(bookings: IUserActivity[]) {
    this.userUpcomingBookings = bookings;
  }
  @Mutation
  public setUserPastBookings(bookings: IUserActivity[]) {
    this.userPastBookings = bookings;
  }

  @Action({ rawError: true })
  public async search() {
    this.setLoading(true);
    try {
      const resultList = await client.search(this.query, this.searchOptions);
      const data: IActivity[] = [];
      resultList.results.forEach((result: any) => {
        Object.keys(result.data).forEach((parameter: any) => {
          const d = result.data;
          if (!d || !d[parameter]) {
            return;
          }
          // tslint:disable-next-line:max-line-length
          if ((result && result.data && result.data[parameter]) &&
          result.data[parameter].raw ||
          result.data[parameter].raw === '' ||
          result.data[parameter].raw === 0 ||
          result.data[parameter].raw === null) {
            const rawData = result.data[parameter].raw;
            result.data[parameter] = rawData;
          }
        });
        data.push(result.data);
      });
      const productsWithOneDate = (productNearestDate(data) as IActivity[]);
      // .sort((a, b) => dateSortComp(String(a.date), String(b.date)));
      this.setProducts(productsWithOneDate as IActivity[]);
    } catch (error) {
      throw Error(`fetchAppSearch: ${error.message}`);
    } finally {
      this.setLoading(false);
    }
  }
}

export default Products;

















import { Component, Vue, Prop } from 'vue-property-decorator';
import { BookingModule } from '@/utils/storemodules';
import { GET_PRODUCT_NAME } from '@/store/modules/booking/constants';

@Component({})
export default class BookingQuestionsNav extends Vue {
  @Prop({default: false}) public instantBooking!: boolean;

  @BookingModule.Getter(GET_PRODUCT_NAME) private productName!: string;

  get isInstantBooking() {
    return this.instantBooking;
  }
}

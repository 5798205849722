import request, { requestBasic, requestb2bAPI } from '@/utils/request';
import { Countries } from '@/models/definitions';
// import { dataToParams } from '@/utils/helpers';


// Registers the user
export async function listCountries(lang: string): Promise<Countries> {

  const response = await request({
    method: 'get',
    url: '/common/countrylist',
    params: {
      lang,
    },
  });

  return response.data;
}

// Return the user's country code
export async function userCountry() {

  const response = await request({
    method: 'get',
    url: '/common/country-by-ip',
  });

  return response.data.countryCode;
}

// get a list of categories from a google sheet
export async function getCategoriesList() {
  const spreadsheetId = '1OUb67cP3Yt3ggyrMiMZr-yqMQF7lIQCYf0O2NDZS7F0';
  const response = await fetch(`https://docs.google.com/spreadsheets/d/${spreadsheetId}/gviz/tq?tqx=out:json&sheet=Product_Categories`)
    .then((res) => res.text())
    .then((text) => {
      const json = JSON.parse(text.substr(47).slice(0, -2));
      return json;
    });
  return response;
}

// get a list of regions from a google sheet
export async function getRegionsList() {
  const spreadsheetId = '1OUb67cP3Yt3ggyrMiMZr-yqMQF7lIQCYf0O2NDZS7F0';
  const response = await fetch(`https://docs.google.com/spreadsheets/d/${spreadsheetId}/gviz/tq?tqx=out:json&sheet=Regions`)
    .then((res) => res.text())
    .then((text) => {
      const json = JSON.parse(text.substr(47).slice(0, -2));
      return json;
    });
  return response;
}

// get the organizer of the month from a google sheet
export async function getOrganizerOfTheMonth() {
  const spreadsheetId = '1OUb67cP3Yt3ggyrMiMZr-yqMQF7lIQCYf0O2NDZS7F0';
  const response = await fetch(`https://docs.google.com/spreadsheets/d/${spreadsheetId}/gviz/tq?tqx=out:json&sheet=Organizer_of_the_Month`)
    .then((res) => res.text())
    .then((text) => {
      const json = JSON.parse(text.substr(47).slice(0, -2));
      return json;
    });
  return response;
}

// get redirect url from apiPath
export async function getUrlFromApiPath(apiPath: string): Promise<{ url: string }> {
  const response = await requestBasic({
    baseURL: apiPath,
    method: 'get',
    headers: {
      'X-api-key': process.env.VUE_APP_X_API_KEY,
    },
  });

  return response.data.data;
}

// Get "isEmailMarketingOrganiser" for unsubscribe customer
export async function postEmailPreference(payload: any): Promise<any> {
  const response = await request({
    method: 'post',
    url: '/customer/ea-receiver/email-preference',
    headers: {
      'Content-Type': 'application/json',
    },
    data: payload,
  });

  return response.data;
}

// Patch "isEmailMarketingOrganiser" for unsubscribe customer
export async function patchEmailPreference(payload: any): Promise<any> {
  const response = await request({
    method: 'patch',
    url: '/customer/ea-receiver/email-preference',
    headers: {
      'Content-Type': 'application/json',
    },
    data: payload,
  });

  return response.data;
}

/**
 * Get language list
 * Postman: `GET language list`
 */
export async function getLanguageList(): Promise<any> {
  const response = await requestb2bAPI({
    url: `/common/language`,
    method: 'get',
  });
  return response.data;
}




























































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import Timeout from 'await-timeout/dist/es5';
import dayjs from 'dayjs';
import WeekOfYear from 'dayjs/plugin/weekOfYear';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';
import { IActivity } from '@/models/store/region';
import { Mixins } from 'vue-mixin-decorator';
import { AppModule } from '@/utils/storemodules';
import Card from '@/components/presentational/Card.vue';
import SmtzIcon from '@/components/presentational/Icon.vue';
import GroupBooking from '@/views/GroupBooking/Index.vue';
import { dateSortComp, dateSortCompArrays, dateSortCompArraysGen, isSameDay } from '@/utils/helpers';
import DateHelperMixin from '@/mixins/DateHelper';
import { setWidgetHeight } from '@/utils/iframe';

dayjs.extend(WeekOfYear);
dayjs.extend(AdvancedFormat);
const groupByUnits = {
  m: 'YYYY-MM',
  d: 'YYYY-MM-DD',
  w: 'YYYY-MM--w',
};

@Component({components: {Card, SmtzIcon}})
export default class Products extends Mixins<DateHelperMixin>(DateHelperMixin) {
  @Prop({default: []}) public activities!: IActivity[];
  @Prop({default: false}) public controls!: boolean;
  @Prop({default: false}) public listdays!: boolean;

  // Group by field
  // m: month, d: day, y: year
  public groupBy: 'm' | 'd' | 'w' = 'm';
  public choices: any = [];
  private index = 0;

  private get isTouch() {
    return AppModule.isTouch;
  }

  @Watch('groupBy')
  private async onGroupByChange() {
    await Timeout.set(500);
    setWidgetHeight('smtz-p-widget');
  }

  @Watch('index')
  private async onIndexChange() {
    await Timeout.set(500);
    setWidgetHeight('smtz-p-widget');
  }

  // Activities grouped using groupBy field
  // by default returns them grouped by day
  private get groupedActivities(): IActivity[][] {
    const groupBy = this.groupBy;
    const activities = this.activities;
    if (!this.controls) {
      return this.groupObjectsByUnit(this.activities, 'dates', groupByUnits.d);
    }

    // grouped by month;
    if (groupBy === 'm') {
      return this.groupObjectsByUnit(activities, 'dates', groupByUnits.m);
    }

    if (groupBy === 'w') {
      return this.groupObjectsByUnit(activities, 'dates', groupByUnits.w);
    }

    return this.groupObjectsByUnit(activities, 'dates', groupByUnits.d);

  }

  // return activities at current index
  private get indexedActivities(): IActivity[] {
    // return grouped activities if no controls;
    const groupedActivities = this.groupedActivities;
    if (!this.controls) {
      return [];
    }

    return groupedActivities[this.index];
    // return this.groupObjectsByUnit(this.groupedActivities[this.index], 'dates', groupByUnits.d);
  }

  // indicates whether we can move to next on the calendar controls
  private get hasNext(): boolean {
    return this.groupedActivities.length > (this.index + 1);
  }

  // Returns the dates of the presented products
  private get productsDatesString(): string {
    const groupBy = this.groupBy;
    const currentProducts = this.indexedActivities;

    if (!this.controls || !currentProducts || !currentProducts.length) {
      return '';
    }

    // make sure that we sort the dates because sometimes they aren't sorted
    const productsDates = (currentProducts.map((prod) => prod.date) as Date[]).sort(dateSortComp);
    // const firstProduct = productsDates[];
    const firstProductDate = dayjs(productsDates[0]);

    // For months return in following format 'November 2020'
    if (groupBy === 'm') {
      const month = this.$t(`date.${firstProductDate.format('MMMM').toLowerCase()}`);

      return `${month} ${firstProductDate.format('YYYY')}`;
    }

    // For months return in following format 'November 2020'
    if (groupBy === 'd') {
      const month = this.$t(`date.${firstProductDate.format('MMMM').toLowerCase()}`);

      return this.formatDateStringWOTime(firstProductDate.toString());
    }

    // For months return in following format 'November 2020'
    if (groupBy === 'w') {
      const monthStart = this.$t(`date.${firstProductDate.format('MMM').toLowerCase()}`);

      const lastDate = dayjs(productsDates[currentProducts.length - 1]);
      const monthEnd = this.$t(`date.${lastDate.format('MMM').toLowerCase()}`);
      const sameMonth = monthEnd === monthStart;
      const sameDay = isSameDay(firstProductDate, lastDate) ;

      // return something in the form of 'Jan 17 - 18'
      return (`${monthStart}  ${firstProductDate.format('DD')} `) +
        // add ' - ' separator in case we have different days or months
        (!sameMonth || !sameDay ? ' - ' : '') +
        // make sure that we don't right the same mo
        (sameMonth ? '' : `${monthEnd} ` ) +
        (sameDay ? '' : lastDate.format('DD'));
    }

    return '';
  }

  private get activitiesListing(): IActivity[][] {
    let activities = this.controls && this.indexedActivities ?
      this.indexedActivities : this.activities;

    // Sort the activities by date
    activities = activities.sort((act1, act2) => {
      if (!act1.date || !act2.date) {
        return 0;
      }
      return dateSortComp(act1.date, act2.date);
    });
    return this.groupObjectsByUnit(activities, 'dates', groupByUnits.d);
  }

  private created() {
    this.choices = [
      {icon: 'calendar_view_day', text: this.$t('date.label-day'), groupBy: 'd'},
      {icon: 'calendar_view_week', text: this.$t('date.label-week'), groupBy: 'w'},
      {icon: 'calendar_view_month', text: this.$t('date.label-month'), groupBy: 'm'},
    ];
  }
  /**
   * @param dateUnit: date format that is used to group similar dates
   */
  private groupObjectsByUnit<T>(data: T[], field: string, dateUnit: string): T[][] {
    const dateObj: {[s: string]: T[]} = {};

    // iterate over data
    for (const d of data) {
      const f = (d as any)[field];
      if (!f) {
        continue;
      }

      // get grouping unit of data unit
      const groupingUnit = dayjs(f as any).format(dateUnit);
      // get the array of that grouping
      let group = dateObj[groupingUnit];
      // create it if it doesn't exist
      if (!group) {
        dateObj[groupingUnit] = [];
        group = dateObj[groupingUnit];
      }

      // add the data unit
      group.push(d);
    }

    const groups: T[][] = [];
    // create an array of data units array
    for (const key in dateObj) {
      if (key) {
        groups.push(dateObj[key]);
      }
    }

    // sort the array using first field of each array;
    return groups.sort(dateSortCompArraysGen('dates'));
  }
}

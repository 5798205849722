














































import { Vue, Component, Watch } from 'vue-property-decorator';
import { required, maxLength } from 'vuelidate/lib/validators';
import { Validator } from '@/utils/validators';
import { NBookingModule } from '@/utils/storemodules';
import NewButton from '@/components/presentational/NewButton.vue';

@Component({
  components: {
    NewButton,
  },
  validations: {
    code: {
      required,
      maxLength: maxLength(255),
      invalid: Validator.numbersLettersSpecialCharacters,
    },
  },
})
export default class AccessCode extends Vue {
  private code: string = '';
  private responseError: boolean = false;
  private loading: boolean = false;

  private get link() {
    return NBookingModule.accessCodeLink;
  }

  private get hasError() {
    return this.$v.code.$error || this.responseError;
  }

  // @Watch('code')
  // public onPromoCodeChanged(val: string) {
  //   (this.$v.code.$model as any) = val.toUpperCase();
  // }

  private async sendPromoCode() {
    if (this.code.length === 0) {
      return;
    }

    this.loading = true;
    try {
      await NBookingModule.sendPromoCode(this.code);
      NBookingModule.setAccessCode(this.code);
    } catch (err) {
      this.responseError = true;
    } finally {
      this.loading = false;
    }
  }
}































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import FacebookLogin from '@/components/auth/NewFacebookLogin.vue';
import GoogleLogin from '@/components/auth/NewGoogleAuth.vue';
import SwissPass from '@/components/auth/NewSwissPass.vue';
import { email, required } from 'vuelidate/lib/validators';
import { openLegalPage, validateOnDelay } from '@/utils/helpers';
import { AuthModule, NModalModule, NNoty, OrganizerModule, UserModule } from '@/utils/storemodules';
import Noty from 'noty';
import { ModalStatus } from '@/models/definitions';
import { Status } from '@/utils/status';
import { userIdentityTracking } from '@/utils/tracking';
import Debug from 'debug';
import { hideFbLogin, hideGmailLogin } from '@/utils/helpers';
import { RouteNames } from '@/models/enums';
import { submitCustomAuthCode, submitCustomAuthEmail, loginWithSmtzToken, submitOrganiserCustomAuthEmail } from '@/api/auth';
import { Validator } from '@/utils/validators';

const debug = Debug('smeetz:auth');
const touchMap = new WeakMap();

@Component({
  components: {
    FacebookLogin,
    GoogleLogin,
    SwissPass,
  },
  validations(): any {
    if (!NewLogin.isCustomAuth) {
      return {
        email: {
          required,
          email,
        },
        password: {
          required,
        },
      };
    } else {
      return {
        email: {
          required,
          email,
        },
      };
    }
  },
})
export default class NewLogin extends Vue {
  private static isCustomAuth: boolean = false;
  private static hasCustomAuthId: boolean = false;
  private invalidLogin: boolean = false;
  private email: string = '';
  private password: string = '';
  private customAuthId: string = '';
  private customAuthCode: string = '';
  private isCorrectCode: boolean = true;
  private isAccountFound: boolean = true;
  private smtzAuthToken: string = '';
  private isLoading: boolean = false;
  private hideFbLogin: boolean = hideFbLogin();
  private hideGmailLogin: boolean = hideGmailLogin();

  /**
   * It is a custom athentication if we're inside a user space widget in the organizer website
   */
  get isCustomAuth() {
    return OrganizerModule.isOrganizerUserSpace;
  }

  private created() {
    NewLogin.isCustomAuth = this.isCustomAuth;
  }

  private goToRegisterForm() {
    NModalModule.setStatus(ModalStatus.NewRegistration);
  }

  private onSuccessLogin() {
    NModalModule.setStatus(ModalStatus.Hidden);
    this.postSuccessLogin();
  }

  private postSuccessLogin() {
    const { redirect } = this.$route.query;
    if (redirect) {
      this.$router.push(redirect as string);
    } else if (this.isHomePage()) {
      this.$router.push({name: RouteNames.UserSpace});
    }
  }

  private isHomePage(): boolean {
    return this.$route.name === RouteNames.Home;
  }

  @Watch('email')
  private onEmailChange() {
    this.invalidLogin = false;
  }

  private forgottenPass() {
    NModalModule.setStatus(ModalStatus.NewForgotPassword);
  }

  private goToLegalPage() {
    openLegalPage.call(this);
  }

  private get canSubmit() {
    return !this.$v.$invalid && !this.invalidLogin;
  }

  /**
   * Whether or not user can submit an email to get the code
   * or can submit the code he has got
   */
  private get canSubmitCustomAuth() {
    return (this.email && !this.customAuthId && !this.$v.email.$invalid)
    || (this.customAuthId && this.customAuthCode);
  }

  private onInputChange(field: string, value: string) {
    validateOnDelay.call(this, field, value);
  }

  private async submit() {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }

    // Indicate that we are loading (on button)
    this.isLoading = true;

    try {
      userIdentityTracking({ email: this.email });

      await UserModule.login({
        username: this.email,
        password: this.password,
      });


    } catch (err) {
      const response = err.response;
      this.isLoading = false;
      if (!response) {
        debug('No response');
        return;
      }

      if (Status[response.status] === 'Bad Request') {
        debug('Invalid credentials');
        this.invalidLogin = true;
        return;
      } else if (Status[response.status] === 'Unauthorized') {
        debug('Unconfirmed user, send him another email');
        await UserModule.sendConfEmail(this.email);
        UserModule.setUserEmail(this.email);
        NModalModule.setStatus(ModalStatus.NewEmailConfirmation);
        return;
      }

      debug('ooops Nothing here');
    }
    this.isLoading = false;
    // NModalModule.setStatus(ModalStatus.Hidden);
    this.onSuccessLogin();
  }

  private submitCustomAuth() {
    this.customAuthId ? this.submitCustomAuthCode() : this.submitCustomAuthEmail();
  }

  private resetCustomAuth() {
    UserModule.setCustomAuthEmail(null);
    UserModule.setCustomAuthToken(null);
    this.customAuthId = '';
    this.email = '';
    this.customAuthCode  = '';
  }

  private resetvalidation() {
    // 1- When is this used?
    // when there is a validation eror
    // 2- Why we need it?
    // We are doing indirect async validation when we get respons from
    // server of invalid email/code we actually
    // include these kin of responss in our input validation. This way,
    // once the user reads the response as a alidation msg
    // he will click on the input to re-enter email/code and we reset the validation.
    // 4- What if we don't use i?
    // user will keep seeing th response msg as a valiadtion msg + form on't submit again.
    if (this.isCorrectCode === false) {
      // this.$refs.signInCode.resetValidation();
      this.isCorrectCode = true;
      (this.$refs.signInCode as any)?.resetValidation();
    }
    if (this.isAccountFound === false) {
      this.isAccountFound = true;
      (this.$refs.email as any)?.resetValidation();
    }
  }

  private async submitCustomAuthEmail() {
    this.isLoading = true;
    try {
      // const response = await submitCustomAuthEmail(this.email);
      const response = await submitOrganiserCustomAuthEmail(this.email, OrganizerModule.id || 1, this.$i18n.locale);
      this.customAuthId = response.id;
      UserModule.setCustomAuthEmail(this.email);
      this.isAccountFound = true;
    } catch (error) {
      this.isLoading = false;
      if ((error as any).response.data.message.toString().includes('User not found')) {
        this.isAccountFound = false;
      } else {
        new Noty({
          type: 'error',
          text: String(this.$t('error.error-response-banner')),
          timeout: 4000,
          theme: 'metroui',
        }).show();
        throw error;
      }
    } finally {
      this.isLoading = false;
    }
  }

  private async submitCustomAuthCode() {
    if (!this.customAuthCode) {
      return;
    }
    this.isLoading = true;
    try {
      const response = await submitCustomAuthCode(this.customAuthId, this.customAuthCode);
      this.smtzAuthToken = response.token;
      this.isCorrectCode = true;
      UserModule.setCustomAuthToken(this.smtzAuthToken);
    } catch (error) {
      this.isLoading = false;
      this.isCorrectCode = false;
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  private resendCode() {
    // In order to resend a new code to the user inbox we just need to re-submit his email adress
     this.submitCustomAuthEmail();
  }
}











































































































import { Vue, Component } from 'vue-property-decorator';
import ModalButton from '@/components/modal/ModalButton.vue';
import { ModalStatus } from '@/models/definitions';
import { ModalModule, AuthModule, NModalModule } from '@/utils/storemodules';
import { minLength, sameAs } from 'vuelidate/lib/validators';
import { Validator } from '@/utils/validators';
import { NNoty } from '@/utils/storemodules';

@Component({
  components: {
    ModalButton,
  },
  validations: {
    password: {
      minLength: minLength(8),
      hasUpperAndLower: Validator.hasUpperAndLower,
      hasNum: Validator.hasNumbers,
      hasSpecial: Validator.hasSpecialCharacter,
    },
    confirmPassword: {
      sameAsPassword: sameAs('password'),
    },
  },
})
export default class ChangePass extends Vue {
  private password: string = '';
  private confirmPassword: string = '';
  private resetToken: string = '';
  private userId: string = '';
  private validToken = true;
  private isLoading = false;

  @ModalModule.Mutation('setStatus') private setStatus: any;
  @AuthModule.Getter('isLoggedIn') private isLoggedIn!: boolean;
  @AuthModule.Action('resetPass') private resetPass: any;
  @AuthModule.Action('changePass') private changePass: any;


  get enableSubmit() {
    return !this.$v.$error && this.confirmPassword;
  }

  private async created() {
    // skip resetToken verification if user is already
    // logged in.
    if (this.isLoggedIn) {
      return;
    }

    const { resetToken, userId } = this.$route.query;
    this.resetToken = resetToken as string;
    this.userId = userId as string;

    try {
      await this.resetPass({resetToken, userId});
    } catch (err) {
      this.validToken = false;
    }
  }

  private async submit() {
    if (!this.enableSubmit) {
      return;
    }

    try {
      this.isLoading = true;
      await this.changePass({
        resetToken: this.resetToken,
        userId: this.userId,
        password: {
          first: this.password,
          last: this.confirmPassword,
        },
      });
      NNoty.createNewNoty({
        message: this.$t('message.pass-changed') as string,
        type: 'success',
        period: 5000,
      });
    } catch (err) {
      this.validToken = false;
    } finally {
      this.isLoading = false;
    }

    this.pathReset();
    // this.setStatus(ModalStatus.Hidden);
    NModalModule.setStatus(ModalStatus.Hidden);
  }

  private cancel() {
    this.setStatus(ModalStatus.Hidden);
    this.pathReset();
  }

  // remove query parameters from current path
  private pathReset() {
    const { path } = this.$route;
    this.$router.push({ path });
  }
}

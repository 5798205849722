import {
  IAddProdOp, ICartInfoOp, ICloseOp,
  IMembershipInfoOp, IResizeOp, IWidgetOp,
  IWidgetStatusOp, WidgetOps, WidgetStatus, IGADataOps, IInvokeOp, IParentUrlOp,
} from '@/models/iframe';
import { GAData } from '@/models/tracking';
import { IPreBookingRes, ISmtzGTM } from '@/models/store/booking';
import dayjs from 'dayjs';
import debug from './debug';
import { isChartPresent, removeVisibleTicketsFromStorage } from './helpers';
import { AppModule, NBookingModule, ProductModule, ReferrerModule } from './storemodules';
import { BookingStatus, CategoryType } from '@/models/enums';
import {handelUserAuthentification} from './auth';
import { CustomAuthType } from '@/models/store/auth';
import { UserModule } from './storemodules';
import { bookingFlowViewCart } from './tracking';
import { LoggerService } from '@/services/log';
import { setPriceValidatedBookingCodes } from '@/utils/membership';
import Mixpanel, { EVENTS } from '@/services/mixpanel';
export function iframeListener() {
  window.addEventListener('message', (event) => {
    if (event.data && typeof event.data === 'string' && event.data.indexOf('#') !== -1) {
      try {
        const data = JSON.parse(event.data);
        if (data.color) {
          const root: any = document.querySelector(':root');
          if (root && root.style) {
            debug('changing colors to ', data.color);
            root.style.setProperty('--primary-color', data.color);
            root.style.setProperty('--org-primary', data.color);
            root.style.setProperty('--secondary-color', data.color);
          }
        }
      } catch (error) {
        debug('Error: Wrong data type: ', event.data);
      }
    }
    // Smeetz widget operation
    if (event.data && typeof event.data === 'string' && event.data.includes('smtzOp')) {
      const op: any = JSON.parse(event.data);
      handleOp(op);
    }
  }, false);
}

export function closeIframe(op?: ICloseOp) {
  if (inIframe()) {
    //
    // set isCart info to false after closing the widget
    if (NBookingModule.getisCartInfo) {
      NBookingModule.setCartInfo(false);
    }
    removeVisibleTicketsFromStorage();
    if (AppModule.widgetId) {
      const operation: ICloseOp = op || {
        smtzOp: WidgetOps.CloseWidget,
        how: 'h',
        wId: AppModule.widgetId,
      };

      window.parent.postMessage(JSON.stringify(operation), '*');
      return;
    }
    window.parent.postMessage('closeIframe', '*');
    // window.location.reload(true);
  }
}

export function openWidget(parameters: any[]) {
  if (inIframe()) {
    window.parent.postMessage(JSON.stringify(parameters), '*');
  }
}

/**
 * Checks whether we are in an iframe or not
 */

export function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

/**
 * Checks whether this is iframe build site
 */
export function isIframebuild() {
  return process.env.VUE_APP_IFRAME;
}

// check if we are in a lightbox widget.
function lightboxWidget() {
  if (inIframe() && window.innerWidth > 638) {
    return true;
  }
  return false;
}

/**
 * Instructs the parent window to increase the size of the Iframe
 */
export function expandIframe() {
  debug('Should expand on an Iframe');
  // Expand the width of the Iframe for better chart visibility on desktops
  if (inIframe() && !lightboxWidget()) {

    const wId = AppModule.widgetId;
    if (wId) {

      debug('expanding with wigetId', wId);
      const resizeOp: IResizeOp = {
        wId,
        smtzOp: WidgetOps.Resize,
        how: 'expand',
      };

      window.parent.postMessage(JSON.stringify(resizeOp), '*');
      return;
    }

    debug('In Iframe, requesting to expand');
    if (window.top) {
      window.top.postMessage('expandIframe', '*');
    }
  }
}

/**
 * It requests the parent window to return Iframe to original size
 */
export function shrinkIframe() {
  debug('Should shrink on an Iframe');
  // Expand the width of the Iframe for better chart visibility on desktops
  if (inIframe()) {
    const wId = AppModule.widgetId;
    if (wId) {

      debug('shrinking with wigetId', wId);
      const resizeOp: IResizeOp = {
        wId,
        smtzOp: WidgetOps.Resize,
        how: 'shrink',
      };

      window.parent.postMessage(JSON.stringify(resizeOp), '*');
      return;
    }

    debug('In Iframe, requesting to shrink');
    if (window.top) {
      window.top.postMessage('shrinkIframe', '*');
    }
  }
}

/**
 * Requests parent window to expand if a chart is present on the site;
 */
export function expandIfChartIsPresent() {
  debug('expandIfChartIsPresent is called');
  if (inIframe() && isChartPresent()) {
    expandIframe();
  }
}

/**
 * Requests parent window to expand if a chart is present on the site;
 */
export function shrinkIfChartIsPresent() {
  debug('shrinkIfChartIsPresent is called');
  if (inIframe() && isChartPresent()) {
    shrinkIframe();
  }
}
// Sets the height of iframe on the organizer site.
// the height of the iframe will be equal to the height of the smeetz widget.
// So that the user doesn't have to scroll.
export function setWidgetHeight(widgetClass?: string) {
  if (inIframe()) {
    const args: any[] = [document.body.scrollHeight];
    if (widgetClass) {
      args.push(widgetClass);
    }
    window.parent.postMessage(JSON.stringify({
      method: WidgetOps.setWidgetHeight, args,
    }), '*');
  }
}

export async function handleOp(op: any) {
  if (op.smtzOp === WidgetOps.sendParentUrl) {
    const smtzOp = op as IParentUrlOp;
    LoggerService.info(`Received parent url: ${smtzOp.data.parentUrl}`);
    ReferrerModule.setParentUrl(smtzOp.data.parentUrl);
  } else if (op.smtzOp === WidgetOps.AddProduct) {
    const smtzOp = op as IAddProdOp;
    if (smtzOp.data.tcart) {
      await NBookingModule.addTcartPrice(op.data);
    } else {
      await NBookingModule.addCartPrice(op.data);
    }
  } else if (op.smtzOp === WidgetOps.OpenCart) {
    // Make sure that we maintian selected tickets open
    const bookingRes = NBookingModule.bookingRes;
    const recapCategories = bookingRes && bookingRes.bookingRecap && bookingRes.bookingRecap.categories;
    if (recapCategories && recapCategories.length) {
      for (const category of recapCategories) {
        ReferrerModule.addVisibleCategory(category.categoryId);
      }
    }
    NBookingModule.setShowBasket(true);
    NBookingModule.setOpenCart(true);
    bookingFlowViewCart(NBookingModule.ticketsTracking);
  } else if (op.smtzOp === WidgetOps.MembershipInfo) {
    const smtzOp = op as IMembershipInfoOp;
    NBookingModule.setMembership(smtzOp.data.membership);
    NBookingModule.setMembCustomers(smtzOp.data.membershipCustomers);
  } else if (op.smtzOp === WidgetOps.CartInfoWidget) {
    NBookingModule.setCartInfo(true);
    const smtzOp = op as ICartInfoOp;
    const { holdToken, membership, membershipCustomers, holdingTime, stash } = smtzOp.data;
    if (holdToken) {
      NBookingModule.setHoldToken(holdToken);
    }
    if (membership) {
      NBookingModule.setMembership(membership);
    }
    if (membershipCustomers) {
      NBookingModule.setMembCustomers(membershipCustomers);
    }
    if (holdingTime) {
      const dateNow = dayjs();
      const expirationDate = dayjs(holdingTime);
      const durationTillExpirationTime = expirationDate.diff(dateNow, 's');
      NBookingModule.startTimer(durationTillExpirationTime);
      if (!NBookingModule.timerExpirationDate) {
        NBookingModule.setTimerExpirationDate(holdingTime);
      }
    }
    if (stash) {
      if (stash.priceCodes) {
        setPriceValidatedBookingCodes(stash.priceCodes);
      }
    }
  } else if (op.smtzOp === WidgetOps.sendCustomLinksInfo) {
    ReferrerModule.setCustomLinksData(op.data);
  } else if (op.smtzOp === WidgetOps.SmtzAccessToken) {
    await handelUserAuthentification(op.data);
  } else if (op.smtzOp === WidgetOps.CloseChildWindow) {
    UserModule.closedAuthWindow(true);
  } else if (op.smtzOp === WidgetOps.SendTrackingStatus) {
    Mixpanel.track(EVENTS.UserOnLandingPage, {data: op.data});
  }
}

export function destroyCart() {
  if (inIframe()) {
    const op: IWidgetOp = {
      wId: AppModule.widgetId,
      smtzOp: WidgetOps.DestroyCart,
    };

    window.parent.postMessage(JSON.stringify(op), '*');
  }
}

export function sendCartBookingInfo(info: ICartInfoOp) {
  if (inIframe()) {
    window.parent.postMessage(JSON.stringify(info), '*');
  }
}

export function sendCartBookingInfoInCarts() {
  if (inIframe() && AppModule.isCartWidget) {
    const info = getCartBookingInfo();
    window.parent.postMessage(JSON.stringify(info), '*');
  }
}

export function getCartBookingInfo(): ICartInfoOp {
  const { bookingRes, membershipCustomers, membership } = NBookingModule;
  if (!bookingRes) {
    throw new Error('No booking made');
  }
  const holdingTime = (ProductModule.product && ProductModule.product.holdingTime) || 20;
  const { bookingId, bookingToken, bookingRecap } = NBookingModule.bookingRes as IPreBookingRes;
  const categoriesWithoutShippingAndDonationTicket = bookingRecap.categories?.filter((t) =>
    !t.isShipping && t.categoryType !== CategoryType.Donation);
  const bookingRecapWithoutShippingAndDonationTicket = {...bookingRecap};
  bookingRecapWithoutShippingAndDonationTicket.categories = categoriesWithoutShippingAndDonationTicket;
  const holdToken = NBookingModule.holdToken;
  const remainingSeconds = NBookingModule.timer || 0;
  const expirationDate = dayjs().add(remainingSeconds, 's').toISOString();
  return {
    wId: 'cart',
    smtzOp: WidgetOps.CartInfo,
    data: {
      bookingId,
      bookingRecap: bookingRecapWithoutShippingAndDonationTicket,
      bookingToken,
      holdingTime: NBookingModule.timerExpirationDate ? NBookingModule.timerExpirationDate : expirationDate,
      membership,
      membershipCustomers,
      holdToken,
      stash: {
        priceCodes: NBookingModule.priceValidatedBookingCodes,
      },
    },
  };
}

export function requestMembershipInfo() {
  const op: IWidgetOp = {
    smtzOp: WidgetOps.RequestMebership,
    wId: AppModule.widgetId,
  };

  if (inIframe()) {
    window.parent.postMessage(JSON.stringify(op), '*');
  }
}

export function requestCartInfo() {
  const op: IWidgetOp = {
    smtzOp: WidgetOps.CartInfoRequest,
    wId: AppModule.widgetId,
  };

  if (inIframe()) {
    window.parent.postMessage(JSON.stringify(op), '*');
  }
}

export function updateWidgetStatus(status: WidgetStatus) {
  const op: IWidgetStatusOp = {
    smtzOp: WidgetOps.StatusUpdate,
    wId: AppModule.widgetId,
    data: {
      status,
    },
  };

  if (inIframe()) {
    window.parent.postMessage(JSON.stringify(op), '*');
  }
}

export function widgetContShopping() {
  const op: IWidgetOp = {
    smtzOp: WidgetOps.ContinueShopping,
    wId: AppModule.widgetId,
  };

  if (inIframe()) {
    window.parent.postMessage(JSON.stringify(op), '*');
  }
}
// Sends the GA data to the widget code.
// Which means we are sending the data to the organizer's site.
export function sendGoogleAnalyticsData(info: GAData) {
  const op: IGADataOps = {
    smtzOp: WidgetOps.sendGAData,
    wId: AppModule.widgetId,
    data: {
      ...info,
    },
  };
  if (inIframe()) {
    window.parent.postMessage(JSON.stringify(op), '*');
  }
}
// Send the dataLayer object to the orgnizer if we iframe is open
export function sendGoogleGtmDataLayer(info: GAData) {
  const op: IInvokeOp = {
    smtzOp: WidgetOps.InvokeOnSite,
    wId: AppModule.widgetId,
    data: {
     ...info,
    },
  };
  if (inIframe()) {
    window.parent.postMessage(JSON.stringify(op), '*');
  }
}
// Send the dataLayer object to the tracking iframe if the organizer sell on discover.smeetz.com
export function sendDatalayerIframe(info: GAData) {
  const op: IInvokeOp = {
    smtzOp: WidgetOps.DataLayerPush,
    wId: AppModule.widgetId,
    data: {
      ...info,
    },
  };
  const iframe: HTMLIFrameElement = document.getElementById('smeetz-tracking-iframe') as HTMLIFrameElement;
  if (iframe && iframe.contentWindow) {
    iframe.contentWindow.postMessage(JSON.stringify(op), '*');
  }
}


// Sends the message to reload the organizer's site  after the booking timer is up.
export function sendPageReload() {
  const op: IWidgetOp = {
    smtzOp: WidgetOps.sendReloadPage,
    wId: AppModule.widgetId,
  };
  if (inIframe()) {
    window.parent.postMessage(JSON.stringify(op), '*');
  }
}

export function requestCustomLinkInfo() {
  const op: IWidgetOp = {
    smtzOp: WidgetOps.requestCustomLinksInfo,
    wId: AppModule.widgetId,
  };
  if (inIframe()) {
    window.parent.postMessage(JSON.stringify(op), '*');
  }
}

export function closeNativeIntegration() {
  if (!AppModule.isNativeIntegration) {
    throw new Error('Not native integration');
  }

  const statusObj = {
    status: 0,
  };

  if (window.vuplex) {
    // The window.vuplex object already exists, so go ahead and send the message.
    // alert(JSON.stringify(statusObj));
    window.vuplex.postMessage(statusObj);
  } else {
    // The window.vuplex object hasn't been initialized yet because the page is still
    // loading, so add an event listener to send the message once it's initialized.
    window.addEventListener('vuplexready', closeNativeIntegration);
  }
  return;
}

export function sendBookingInfoToNative() {
  if (!AppModule.isNativeIntegration) {
    throw new Error('Not native integration');
  }

  const bookingRes = NBookingModule.bookingRes;
  if (
       !bookingRes // No booking
    || bookingRes.bookingState !== BookingStatus.Completed // Not completed
    || !bookingRes.slots
  ) {
    throw new Error('Booking not in completed state');
  }

  const statusObj = {
    status: 1,
    tickets: bookingRes.slots.map((slot) => {
      // TODO @Riyadh, remove as any when doing real integrations in the future
      const { categoryName, priceName, qrCodeData, startDateTime} = slot as any;
      return {
        ticketName: slot.categoryName,
        priceOption: slot.priceName,
        qrCode: qrCodeData,
        startDateTime: (bookingRes as any).startDateTime || dayjs().toISOString(),
      };
    }),
  };

  if (window.vuplex) {
    // The window.vuplex object already exists, so go ahead and send the message.
    // alert(JSON.stringify(statusObj));
    window.vuplex.postMessage(statusObj);
  } else {
    // The window.vuplex object hasn't been initialized yet because the page is still
    // loading, so add an event listener to send the message once it's initialized.
    window.addEventListener('vuplexready', sendBookingInfoToNative);
  }

  return;
}

/* Helper function to request custom  authentification from the tracker code
*/
export function requestCustomAuth(data: { [key: string]: string }) {
  const type = data.type;
  // TODO: add an enum for the auth types
  if (type === CustomAuthType.Keycloak) {
  const op: IWidgetOp = {
    smtzOp: WidgetOps.RequestKeycloakAuth,
    wId: AppModule.widgetId,
  };
  if (inIframe()) {
    window.parent.postMessage(JSON.stringify(op), '*');
  }
}
}

import { Module, MutationTree, GetterTree, ActionTree } from 'vuex';
import Debug from 'debug';
import { IRootState } from '@/models/store';
import { IErrorState, IErrorData } from '@/models/store/error';
import { GET_MESSAGE, SET_MESSAGE, SET_TIMEOUT, CREATE_ERROR } from './constants';

const debug = Debug('smeetz:error');

export const namespaced = true;

export const State: IErrorState = {
  timeout: undefined,
  message: '',
};

export const getters: GetterTree<IErrorState, IRootState> = {
  [GET_MESSAGE](state): string {
    return state.message;
  },
};

export const mutations: MutationTree<IErrorState> = {
  [SET_MESSAGE](state, message: string): void {
    state.message = message;
  },
  [SET_TIMEOUT](state, timeout: number): void {
    if (state.timeout) {
      clearTimeout(state.timeout);
    }

    state.timeout = timeout;
  },
};

export const actions: ActionTree<IErrorState, IRootState> = {
  [CREATE_ERROR]({ commit }, payload: IErrorData) {
    commit(SET_TIMEOUT, setTimeout(() => {
      commit(SET_MESSAGE, '');
    }, payload.period));

    commit(SET_MESSAGE, payload.message);
  },
};

const modal: Module<IErrorState, IRootState> = {
  namespaced,
  state: State,
  getters,
  mutations,
  actions,
};

export default modal;


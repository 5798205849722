




import { Component, Vue } from 'vue-property-decorator';
import { ModalStatus } from '@/models/definitions';
import { ModalModule, NModalModule } from '@/utils/storemodules';

@Component({
  components: {
  },
})
export default class VerifyEmail extends Vue {
  // @ModalModule.Mutation('setStatus') private setStatus: any;

  private mounted() {
    // retrieve email and emailToken
    // https://dev.cloud.smeetz.com/#!/verify-email?mailToken=Mzg0NDk5MDE2NWM2MmExY2JkYzUzZDEuM
    // Tk4MDcwNzg&email=zenasni_re@hotmail.com
    const { email, mailToken } = this.$route.query;

    // Change route with given query params
    const route = this.$route;
    this.$router.push({
      // path: route.path,
      path: '/',
      query: route.query,
    });

    // show modal
    // this.setStatus(ModalStatus.EmailVerified);
    NModalModule.setStatus(ModalStatus.EmailVerified);

    // if (!email || !emailToken)
    // set loading

    // verify email
    // set modal status to EmailConf
    /*
    { 200
    "success": true
    }
    */
    // Error 400:
    /*
    {
    "status": "error", 400
    "messages": [
        "E-mail is already verified."
    ]
    }
    {
    "status": "error", 400
    "messages": [
        "Validation code is invalid."
    ]
    }
    {
    "status": "error", 406
    "messages": [
        "Validation code has been expired."
    ]
    }
    */

  }
}

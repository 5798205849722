import langDe from 'quasar/lang/de';
import langEn from 'quasar/lang/en-us';
import langFr from 'quasar/lang/fr';
import langIt from 'quasar/lang/it';
import langNl from 'quasar/lang/nl';
import langEs from 'quasar/lang/es';
import langHu from 'quasar/lang/hu';

export default {
  en: langEn,
  de: langDe,
  fr: langFr,
  it: langIt,
  nl: langNl,
  es: langEs,
  hu: langHu,
};

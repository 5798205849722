import { BookingSteps, IBookingState, ICalendarDate } from '@/models/store/booking';
// import Product from './shortname2.json';
// import Availabilities from './availabilities2.json';
// import Availabilities from './multiplePrice.json';
const Product: any = {};
const Availabilities = {};

export const State: IBookingState = {
  steps: [
    BookingSteps.DateTime,
    BookingSteps.Category,
    BookingSteps.Attendees,
    BookingSteps.Checkout,
  ],
  step: BookingSteps.DateTime,
  flow: 1,
  // instantBooking: true,
  instantBooking: Product.isInstantBooking,
  product: Product,
  date: undefined,
  slots: Product.slotsProposals,
  selectedSlots: [],
  slot: undefined,
  availabilities: Availabilities,
  // selectedCategories: [],
  categories: [],
  loading: false,
  calendar: { dates: ([] as ICalendarDate[]) },
  booking: undefined,
  guestBooking: undefined,
  serverBooking: undefined,
  paymentMethod: undefined,
  dpSlot: undefined,
  avoidedDPSlots: [],
  wishListDPSlots: [],
  dpSlotRating: {},
  dpSlotTTL: {},
};


export const StepName: { [s: number]: string; } = {
  [BookingSteps.DateTime]: 'date-time',
  [BookingSteps.Category]: 'category',
  [BookingSteps.Attendees]: 'attendees',
  [BookingSteps.Checkout]: 'checkout',
};

export const NextStep: { [s: number]: BookingSteps} = {
  [BookingSteps.DateTime]: BookingSteps.Category,
  [BookingSteps.Category]: BookingSteps.Attendees,
  [BookingSteps.Attendees]: BookingSteps.Checkout,
  [BookingSteps.Checkout]: BookingSteps.Checkout,
};

export const PrevStep: { [s: number]: BookingSteps} = {
  [BookingSteps.DateTime]: BookingSteps.DateTime,
  [BookingSteps.Category]: BookingSteps.DateTime,
  [BookingSteps.Attendees]: BookingSteps.Category,
  [BookingSteps.Checkout]: BookingSteps.Attendees,
};



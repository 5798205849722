


















// common
import { Vue, Component, Emit, Prop } from 'vue-property-decorator';

// components
import { VFBLoginScope } from 'vue-facebook-login-component';

// // modules
import { NBookingModule, UserModule } from '@/utils/storemodules';

import Debug from 'debug';

const debug = Debug('smeetz:auth');
const debug1 = Debug('smeetz:fb');

@Component({
  components: {
    VFBLoginScope,
  },
})
export default class FacebookAuth extends Vue {
  @Prop({ default: true }) public showSpan!: boolean;
  private appId: string = process.env.VUE_APP_FACEBOOK_APP_ID;
  private isLoading: boolean = false;

  private fbLoginObject: any = {
    scope: 'email,public_profile,user_age_range,user_gender,user_link,user_birthday',
  };

  private created() {
    debug1('hello from NewFacebookLogin.vue created');
  }

  private loginClick(item: any) {
    debug1('hello from loginClick');
    this.isLoading = true;
    item.login();
  }

  private async logIn(response: any) {
    debug1('Facebook Log in button executed');

    // Do nothing if the user didn't grant access
    if (response.status !== 'connected') {
      this.isLoading = false;
      return;
    }

    const { accessToken, userID } = response.authResponse;

    // login facebook
    await UserModule.fbLogin({accessToken, userID});
    NBookingModule.socialLoginCheckout();
    this.$emit('successLogin');
    this.isLoading = false;
  }
}











import { Component, Vue } from 'vue-property-decorator';
import Footer from '@/components/Footer.vue';
import Register from '@/components/Register.vue';

@Component({
  components: {
    Footer,
    Register,
  },
  metaInfo() {
    return {
      title: this.$t('seo.title-homepage') as string,
      description: this.$t('seo.description-homepage') as string,
    };
  },
})
export default class Home extends Vue {
}

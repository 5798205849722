

























import { Vue, Component } from 'vue-property-decorator';
import { NBookingModule } from '@/utils/storemodules';

@Component
  export default class RefundTransaction extends Vue {
    private get booking() {
        return NBookingModule.bookingSummary?.data.ProductSlotBookingRead;
    }
  }






























































import { Vue, Component, Prop } from 'vue-property-decorator';
import dayjs from 'dayjs';
import { IOverview, IProduct } from '../../../models/store/product';
import { bookingFlowStartTracking } from '@/utils/tracking';
import { ProductModule } from '@/utils/storemodules';

@Component({})
export default class ProductList extends Vue {
  @Prop() public index!: number;
  @Prop() public product!: IProduct;
  @Prop() public query: any;

  private showProductOverview: boolean = false;
  private productOverview!: IOverview;

  get productName() {
    return this.product.name;
  }

  get urlLocation() {
    const { id, short_url: shortName } = this.product;
    return {
      path: `/${this.$i18n.locale}/product/${id}/booking/${shortName}`,
      query: this.query ? this.query : {},
    };
  }

  get location() {
    return this.product.location;
  }

  get nextStartDateTime() {
    if (!this.showProductOverview) {return null; }
    return this.productOverview.nextDateTime.startDateTime;
  }

  get nextEndDateTime() {
    if (!this.showProductOverview) {return null; }
    return this.productOverview.nextDateTime.endDateTime;
  }

  get fromDateTimeInterval() {
    if (!this.nextStartDateTime || !this.nextEndDateTime) {return null; }
    const start = dayjs(this.nextStartDateTime).format('HH:mm');
    const end = dayjs(this.nextEndDateTime).format('HH:mm');

    return `${this.$t('discover.label-from')} ${start} ${this.$t('discover.label-to')} ${end}`;
  }

  get day() {
    if (!this.nextStartDateTime ) {return null; }
    return this.$t('date.' + dayjs(this.nextStartDateTime).format('dddd').toLowerCase());
  }

  get shortDay() {
    if (!this.nextStartDateTime ) {return null; }
    return this.$t('date.' + dayjs(this.nextStartDateTime).format('dddd').toLowerCase() + '_short');
  }

  get nextDate() {
    if (!this.nextStartDateTime ) {return null; }
    const date = dayjs(this.nextStartDateTime);
    const dayNum = date.format('DD');
    const month = this.$t('date.' + date.format('MMMM').toLowerCase());
    const year = date.format('YYYY');

    return `, ${dayNum} ${month}, ${year}`;
  }

  get fromPriceText() {
    if (!this.showProductOverview) {return null; }
    const price = this.productOverview.rangePrice.fromPrice;
    const currency = this.productOverview.rangePrice.fromPriceCurrency;

    return `${this.$t('discover.label-from')} ${price} ${currency}`;
  }

  get isBookable() {
    if (!this.product) {
      return false;
    }

    // TODO separate product list type from IProduct
    return (this.product as any).booking_status === 1;
  }

  get bookingStatus() {
    // TODO separate product list type from IProduct
    return (this.product as any).booking_status;
  }

  private clicked() {
    // return;
    // bookingFlowStartTracking(this.product, this.index, 'Iframe');
    if (this.urlLocation && this.isBookable) {
      this.$router.push(this.urlLocation);
    }

    // console.log(this.urlLocation);
  }

  private async fetchProductDateAndInfos() {
    const { productOverview } = await ProductModule.fetchProductDateAndInfos(this.product.short_url);
    this.productOverview = productOverview;
    this.showProductOverview = true;
  }
}

import gql from 'graphql-tag';
/**
 * Fetches product tickets
 *
 * variable example
 * { "id": "62731" }
 */

// tslint:disable-next-line
export const GET_ProductsTickets = gql`
  query GetProductsTickets($ids: [String]) {
    tickets: ProductSlotCategoryList(
      condition: {
        ProductId: { in: $ids }
        Type: {in: ["3","8"]}
        Cancelled: {in: ["0"]}
        Visible: {in: ["1"]}
      },
      limit: 500
    ) {
      id: Id
      name: CategoryName
      type: Type
      cancelled: Cancelled
      productId: ProductId
      timeSlot: TimeSlotList {
        availability: Availability
        start: StartDateTimeTz
        end: EndDateTimeTz
        bookable: Bookable
      }
    }
  }
`;

/**
 * Fetches product tickets
 *
 * variable example
 * { "id": "62731" }
 */

// export const GET_TicketGroupMemberships = gql`
// query GetTicketGroupMemberships($id: String) {
//   tickets: TicketGroupList(condition: {
//     Id: {eq: $id}
//   }) {
//     ticketList: ProductSlotCategoryMMList {
//       productId: ProductId
//       categoryId: Id
//     }
//   }
// }
// `
// tslint:disable-next-line
export const GET_TicketGroupMemberships = gql`
query getTicketGroupMemberships($id: String) {
  tickets: TicketGroupTicketList(condition: {
    TicketGroupId: {eq: $id}
  }) {
    membership: ProductSlotCategoryRead {
      categoryId: Id
      productId: ProductId
    }
  }
}
`;

/**
 * Get booking tickets
 *
 * variable example
 * {"bookingId": "3493780", "bookingToken": "NDQ4ODAzNDU1NjQ5MTZahhmHNLTHzkzNzAuNjY0MDI4ODM"}
 */
// tslint:disable-next-line
export const GET_BookingTickets = gql`
query getBookingTickets($bookingId: String!, $bookingToken: String!) {
  ProductSlotBookingList(condition: {Id: {eq: $bookingId},GoerToken: {eq: $bookingToken}}) {
    Id
    Uuid
    GoerToken
    pssList: ProductSlotStateList {
      Id
      BookingStatus
      MainTicketId
      PaymentStatus
      CategoryId
      CategoryName
      PriceId
      PriceName
      TimeSlotId
      MainTicketId
      FirstName
      LastName
    }
  }
}
`;


































































































import { Component, Vue } from 'vue-property-decorator';
import { ProductsModule, RegionModule } from '@/utils/storemodules';
import { client } from '@/utils/searchConfig';
import ProductSearch from '@/components/product/ProductSearch.vue';
import DateFilter from '@/views/SearchPage/components/DateFilter.vue';
import { ProductLocation, ProductSearchCity } from '@/models/store/products';
import CalendarInput from '@/components/presentational/CalendarInput.vue';
import dayjs from 'dayjs';
import { EVCalendar } from '@/models/events';

@Component({
  components: {
    ProductSearch,
    CalendarInput,
  },
})
export default class Search extends Vue {
  private EVCalendar = EVCalendar;
  private ignoredLocation: string = '';
  private pickedLocation: any = {
    label: 'Regions' /*this.$t("field.regions") as string*/,
    value: this.ignoredLocation,
  };

  // private search(input: string) {
  // return new Promise((resolve) => {
  //   if (input.length < 1) {
  //     return resolve([]);
  //   }
  //   client.search(input)
  //     .then((response: any) => {
  //       const arrayForData: any = [];
  //       response.results.forEach((result: any) => {
  //         Object.keys(result.data).forEach((parameter: any) => {
  // tslint:disable-next-line:max-line-length
  //           if ( result.data[parameter] && (result.data[parameter].raw || result.data[parameter].raw === '' || result.data[parameter].raw === 0 || result.data[parameter].raw === null)) {
  //             const rawData = result.data[parameter].raw;
  //             result.data[parameter] = rawData;
  //           }
  //         });
  //         arrayForData.push(result.data);
  //       });
  //       return resolve(arrayForData);
  //     });
  // });
  // }

  get from() {
    return ProductsModule.from;
  }

  get to() {
    return ProductsModule.to;
  }

  get fromOptions() {
    return (date: any) => {
      const to = ProductsModule.to;
      if (to) {
        return (
          date >= dayjs().format('YYYY/MM/DD') &&
          date < dayjs(to).format('YYYY/MM/DD')
        );
      }

      return date >= dayjs().format('YYYY/MM/DD');
    };
  }

  get toOptions() {
    return (date: any) => {
      const from = ProductsModule.from;
      if (from) {
        return date > dayjs(from).format('YYYY/MM/DD');
      }

      return date > dayjs().format('YYYY/MM/DD');
    };
  }

  get regions() {
    const lang = this.$i18n.locale ;
    return RegionModule.regions.map((r) => {
      return { label: lang === 'en' ? r.label : r.translations[lang], value: r.value };
    });
  }

  private async setRegion(pickedLocation: string) {
    ProductsModule.setProductLocation(
      RegionModule.regions.find((r) => r.value === pickedLocation)?.location,
    );
    // await ProductsModule.search();
  }

  private created() {
    ProductsModule.setOptions({
      // andFilters: [{ dates: { from: dayjs().toISOString() } }, {organiser_id: groupId}],
      // andFilters: [{ dates: { from: dayjs().toISOString() } }],
      // sort: 'dates',
      // sortDirection: 'asc',
      searchFields: {
        product_name: { weight: 10 },
        product_description: { weight: 5 },
        product_teaser: { weight: 3 },
        organiser_name: { weight: 2 },
      },
      // query: groupId,
      limit: 10,
    });
  }
  private onSubmit(result: any) {
    const locale = this.$i18n.locale;
    // this.$router.push(`/product/${result.short_name}`);
    ProductsModule.search();
    this.$router.push(`/${locale}/search`);
  }

  private callBack(result: any) {
    // do nothing if user selects a product from dropdown
    if (result) {
      return;
    }

    // user clicked enter, take them to search page
    this.$router.push(`/${this.$i18n.locale}/search`);
  }

  private async setFrom(date: Date) {
    ProductsModule.setOptions({
      from: date,
    });
    await ProductsModule.search();
  }

  private async setTo(date: Date) {
    const from = ProductsModule.from;
    ProductsModule.setOptions({
      to: date,
    });

    if (from && dayjs(date).isBefore(from)) {
      ProductsModule.setOptions({
        from: null,
      });
    }
    await ProductsModule.search();
  }
}

import Vue from 'vue';
import dayjs from 'dayjs';
import { Mixin } from 'vue-mixin-decorator';
import { OrganizerModule } from '@/utils/storemodules';
import { TimeFormatNameB2C, TimeFormatB2C } from '@/models/enums';
import Debug from 'debug';
const debug = Debug('smeetz:dateformat');


@Mixin
export default class DateHelperMixin extends Vue {

  get formatDateString(): (d: string) => string {
    return (date: string) => {
      return this.currentDate(date);
    };
  }

  get formatDateStringWOTime(): (d: string) => string {
    return (date: string) => {
      return this.currentDate(date, true);
    };
  }

  // private currentDate(date: string) {
  public currentDate(date: string, onlyDate: boolean = false) {
    const momentDate = dayjs(date);
    const dayString = momentDate.format('dddd').toLowerCase();
    const monthString = momentDate.format('MMM').toLowerCase();
    const monthNumber = momentDate.format('MM');
    const dayNumber = momentDate.format('DD');
    const year = momentDate.format('YYYY');

    const day = this.$t(`date.${dayString}_short`);
    const month = this.$t(`date.${monthString}`);

    const at = this.$t('booking-flow.label-at');

    let time = '';
    if (OrganizerModule.getInfo &&
      OrganizerModule.getInfo.groupInfo.timeFormatB2c === TimeFormatNameB2C[TimeFormatB2C._12HourFormat]) {
      time = dayjs(date, 'YYYY-MM-DD, hh:mm A').format('hh:mm A');
    } else {
      time = momentDate.format('HH:mm');
    }


    let timeExtension = '';
    // if (this.flowType === BookingFlowType.Type1) {
    timeExtension = at + time;
    // }

    if (this.$route.fullPath.includes('gbooking')) {
      return `${day}. ${dayNumber} ${month}. ${year} ${timeExtension}`;
    }

    if (!OrganizerModule.getInfo && onlyDate) {
      return this.$i18n.locale === 'nl' ? `${day}. ${dayNumber} ${month}. ${year}`
      : `${day}, ${dayNumber} ${month} ${year}`;
    }

    if (!OrganizerModule.getInfo) {
      return this.$i18n.locale === 'nl' ? `${day}. ${dayNumber} ${month}. ${year}`
      : `${day}, ${dayNumber} ${month} ${year}`;
    }

    let formatedDate;
    switch (OrganizerModule.getInfo.groupInfo.dateFormatB2c) {
      case 'M j, Y':
        formatedDate = `${month} ${dayNumber}, ${year}`;
        break;
      case 'D, j M Y':
        formatedDate = this.$i18n.locale === 'nl' ? `${day}. ${dayNumber} ${month}. ${year}`
        : `${day}, ${dayNumber} ${month} ${year}`;
        break;
      case 'Y-m-d':
        formatedDate = `${year}-${monthNumber}-${dayNumber}`;
        break;
      case 'm/d/Y':
        formatedDate = `${monthNumber}/${dayNumber}/${year}`;
        break;
      case 'd-m-Y':
        formatedDate = `${dayNumber}-${monthNumber}-${year}`;
        break;
      case 'd.m.Y':
        formatedDate = `${dayNumber}.${monthNumber}.${year}`;
        break;
    }

    if ( onlyDate ) {
      return `${formatedDate}`;
    }

    return `${formatedDate}${timeExtension}`;
  }

  // Takes time in 24 hours format and convert it into am/pm format
  public formatTime(time: string) {
    if (OrganizerModule.getInfo &&
      OrganizerModule.getInfo.groupInfo.timeFormatB2c === TimeFormatNameB2C[TimeFormatB2C._12HourFormat]) {
      let hour = Number(time.slice(0, 2));
      const minutes = time.slice(-2);
      if (hour === 24) {
        time = '00:' + minutes + ' AM';
      } else if (hour > 12) {
        hour = hour - 12;
        let hourString = hour.toString();
        if (hourString.length === 1) {
          hourString = '0' + hourString;
        }
        time = hourString + ':' + minutes + ' PM';
      } else if (hour === 12) {
        time = hour.toString() + ':' + minutes + ' PM';
      } else {
        let hourString = hour.toString();
        if (hourString.length === 1) {
          hourString = '0' + hourString;
        }
        time = hourString + ':' + minutes + ' AM';
      }
    }
    return time;
  }
}

import Vue from 'vue';
import Debug from 'debug';
import { Mixin } from 'vue-mixin-decorator';
import { AuthModule, OrganizerModule } from '@/utils/storemodules';
// import { inIframe, closeIframe } from '@/utils/iframe';
// import { ProductModule, ReferrerModule, NBookingModule } from '@/utils/storemodules';

const debug = Debug('smeetz:referrer');

@Mixin
export default class BookingDone extends Vue {
  private langMenuOpen: boolean = false;
  @AuthModule.Mutation('setLang') private setLang: any;
  @AuthModule.Mutation('setLangSet') private setLangSet: any;

  get displayDutchTranslation(): boolean {
    const addDutchintoGroups = [19285, 19327, 19348];
    const groupId = Number(OrganizerModule.id);
    return addDutchintoGroups.includes(groupId);
  }

  get displaySpanishTranslation(): boolean {
    const addSpanishintoGroups = [19377, 19348];
    const groupId = Number(OrganizerModule.id);
    return addSpanishintoGroups.includes(groupId);
  }

  get displayHungarianTranslation(): boolean {
    const addHungarianintoGroups = [19348];
    const groupId = Number(OrganizerModule.id);
    return addHungarianintoGroups.includes(groupId);
  }

  get languages() {
    const langArray = [
      {
        name: this.$t('new-footer.column3-row1'),
        value: 'en',
      },
      {
        name: this.$t('new-footer.column3-row2'),
        value: 'fr',
      },
      {
        name: this.$t('new-footer.column3-row3'),
        value: 'de',
      },
      {
        name: this.$t('new-footer.column3-row4'),
        value: 'it',
      },
    ];
    if (this.displayDutchTranslation || process.env.VUE_APP_BUILD_ENV !== 'production') {
      langArray.push({
        name: this.$t('new-footer.column3-row6'),
        value: 'nl',
      });
    }
    if (this.displaySpanishTranslation || process.env.VUE_APP_BUILD_ENV !== 'production') {
      langArray.push({
        name: this.$t('new-footer.column3-row7'),
        value: 'es',
      });
    }

    // We need to wait for Backend to add Hungarian
    // if (this.displayHungarianTranslation || process.env.VUE_APP_BUILD_ENV !== 'production') {
    //   langArray.push({
    //     name: this.$t('new-footer.column3-row8'),
    //     value: 'hu',
    //   });
    // }
    return langArray;
  }
  // Opens language menu. Also affects logged in user menu
  private openLangMenu() {
    if (!this.langMenuOpen) {
      this.addListener();
    }

    this.langMenuOpen = !this.langMenuOpen;
  }

  // Adds a listener on body that closes navbar menus
  private addListener() {
    document.body.addEventListener('click', this.hideS);
  }

  /**
   * Hides the search input
   */
  private hideS(event?: any): void {
    if (event) {
      event.stopPropagation();
    }

    this.langMenuOpen = false;
    document.body.removeEventListener('click', this.hideS);
  }

  private makeChoose(data: string) {
    if (data === 'en' || data === 'fr' || data === 'de' || data === 'it' || data === 'nl' || data === 'es' || data === 'hu') {
      this.changeLocale(data);
    } else {
      window.open(data, '_blank');
    }
  }

  private changeLocale(locale: string): void {
    this.setLang(locale);
    this.setLangSet(true);
  }

  private getFullLangName(value: string) {
    switch (value) {
      case 'en': return 'English';
      case 'fr': return 'French';
      case 'de': return 'Deutsch';
      case 'it': return 'Italiano';
      case 'nl': return 'Dutch';
      case 'es': return 'Spanish';
      case 'hu': return 'Hungarian';
    }
  }

  private handleMenuClick(event: any) {
    event.currentTarget.classList.toggle('is-active');
  }
}
















import { Vue, Component, Prop } from 'vue-property-decorator';
import Debug from 'debug';

const debug = Debug('smeetz:modal');
@Component({})
export default class ModalButton extends Vue {
  @Prop()
  public classNames!: string;

  @Prop({default: false})
  public disabled!: boolean;

  private loading: boolean = false;

  public execute() {
    if (!this.disabled) {
      debug('emiting');
      this.$emit('click');
    }
  }

  public executing(flag: boolean) {
    this.loading = flag;
  }

}

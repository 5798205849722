










































/**
 * Component for showing a calendary.
 *
 * @Prop date {Date}: initial selected date
 * @Prop to {Date}: upper limit date for the calendar
 *
 * @emit dateSelected {Date}: emitted when a day is selected
 */
import { Vue, Component, Prop } from 'vue-property-decorator';
import DatePicker from 'vuejs-datepicker';
import { en, de, fr, it, nl, es, hu } from 'vuejs-datepicker/dist/locale';
import { EVCalendar } from '@/models/events';
import dayjs from 'dayjs';
import { ICalendarLegend } from '@/models/site';
import CalendarLegend from '@/views/NewBooking/components/CalendarLegend.vue';
import { IDatePrice } from '@/models/store/booking';
import { getDecimalDigits } from '@/utils/helpers';
import Debug from 'debug';
import { AppModule, OrganizerModule } from '@/utils/storemodules';
import { WeekStartNameB2C, WeekStartB2C } from '@/models/enums';
const debug = new Debug('smeetz:calendarprices');

@Component({
  components: {
    DatePicker,
    CalendarLegend,
  },
})

export default class Calendar extends Vue {
  @Prop({default: null}) public to!: Date | null;
  @Prop({default: null}) public from!: Date | null;
  @Prop({default: () => []}) public availableDates!: Date[] | string[];
  @Prop({default: () => []}) public pricesForAvailableDates!: IDatePrice[];
  @Prop({default: false}) public inline!: boolean;
  @Prop({default: true}) public displayOnCalendar!: boolean;
  @Prop({default: false}) public isPricesCalendar!: boolean;
  @Prop({default: false}) public isOccupancyCalendar!: boolean;
  @Prop({default: false}) public inPopup!: boolean;
  @Prop({default: null}) public currency!: string;
  @Prop({default: null}) public legends!: ICalendarLegend[];
  @Prop() private date!: Date;
  @Prop() private openDate!: Date;
  @Prop({ default: true }) private isDisabledDates!: boolean;
  @Prop() private initialView!: string;
  @Prop({default: null}) private disabledDates!: Date | null;

  private datePicker: any;
  private block = false;
  private isOpen = false;
  private locale = {
    en, fr, de, it, nl, es, hu,
  };

  private monthNamesShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                  'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  private onMonthChangedDate: number | null = null;

  get isMondayFirst() {
    if (!OrganizerModule.getInfo) {
      return true;
    }
    return WeekStartNameB2C[WeekStartB2C.Monday] === OrganizerModule.getInfo.groupInfo.weekStartB2c;
  }

  // We get the current month from openDate
  get currentMonth() {
   let current = '';
   if (this.openDate) {
    current = this.openDate.toDateString().split(' ')[1];
   }
   const month = this.monthNamesShort.indexOf(current);
   if (month !== -1) {
    return month;
   } else {
    const date = new Date();
    return date.getMonth();
   }

  }

  // The previous month to be displayed in the calendar
  get previousMonth() {
    const currentMonth = this.onMonthChangedDate !== null ? this.onMonthChangedDate : this.currentMonth;

    let previousMonth;
    if (currentMonth === 0) {
      previousMonth = 11;
    } else {
      previousMonth = currentMonth - 1;
    }

    return this.monthNamesShort[previousMonth];
  }

  // The next month to be displayed in the calendar
  get nextMonth() {
    const currentMonth = this.onMonthChangedDate !== null ? this.onMonthChangedDate : this.currentMonth;
    let nextMonth;

    if (currentMonth === 11 ) {
      nextMonth = 0;
    } else {
      nextMonth = currentMonth + 1;
    }

    return this.monthNamesShort[nextMonth];
  }

  public setCell(x: any) {

    if (x.isDisabled) {
      return '<em>' + (x.date) + '</em>';
    }

    const day = this.pricesForAvailableDates.find((d) => d.date.getTime() === x.timestamp);
    if (!day || !day.price) {
      return '<em>' + (x.date) + '</em>';
    }

    return '<span>' + x.date + '</span><p style="color: ' + day.priceColor + '">'
    + '<span>' + this.currency + '</span>' + Math.floor(day.price)
    + '<span class="decimal-digits">' + getDecimalDigits(day.price, 2) + '</span>' + '</p>';
  }

  public showCalendar() {
    // if (this.isOpen) {
    //   debug('Skipped showing calendar. Already opened');
    //   return;
    // }

    // const component: any = this.$refs.datePicker;
    // component.showCalendar();
    this.datePicker.showCalendar();
    // this.isOpen = true;
    // this.inline = true;
    // component.$el.querySelector("input").focus();
    this.datePicker.$el.querySelector('input').focus();
  }

  public select(date: Date) {
    // this.isOpen = false;
    this.$emit(EVCalendar.DateSelected, date);
    // this.block = true;
    // setTimeout(() => {this.block = false; }, 1000);
    // this.inline = false;
    // setTimeout(() => {this.inline = false}, 2000);
    // this.datePicker.hideCalendar();
    // this.datePicker.hideCalendar();
    // this.datePicker.showCalendar();
  }


  public closeCalendar() {
    // this.isOpen = false;
  }

  public openCalendar() {
    // this.isOpen = true;
  }

  public getCurrency() {
    if (this.currency === 'CHF') {
      return this.currency.slice(0, 2);
    }
    return this.currency;
  }

  /**
   * Return a number with decimal digits without the decimal point
   * @param n the number to show decimal digits
   * @param x how many decimal digits to show
   */
  public decimalDigits(n: number, x: number) {
    return AppModule.isMobile ? getDecimalDigits(n, x).toString().slice(1) : getDecimalDigits(n, x).toString();
  }

  private async mounted() {
    this.datePicker = this.$refs.datePicker;
    this.$emit(EVCalendar.CalendarMounted);

    if (!this.legends) {
      return;
    }

    // We want to show the legend under the calendar
    // & this library can only show the legend on top.
    const calendarWrapper = this.$refs.calendarWrapper as HTMLElement;
    const daysWrapper = calendarWrapper.querySelector('.booking-calendar');
    if (!daysWrapper || !this.legends) {
      return;
    }
    // Detach the legend & append it at the bottom
    const calendarLegend = daysWrapper.querySelector('.calendar-header') as HTMLElement;
    daysWrapper.removeChild(calendarLegend);
    daysWrapper.appendChild(calendarLegend);
  }

  private isDisabledDate(date: Date) {
    const { showMonthView, showYearView } = this.datePicker || {};
    // Make sure that month and year view is available for future.
    // Cause we don't know whether they are avaialble or not
    // until they are fetched.
    // this is used only when availableDates is present
    if (
      (this.availableDates && this.availableDates.length) &&
      (showMonthView || showYearView)
    ) {
      return false;
    }

    const dateFound = (this.availableDates as Date[]).find((validDate: Date) => {
      // const day = dayjs(date);
      // const dayComp = dayjs(d);
      // if (isSameDay(day, dayComp)) {
      //   console.log('are the same ', day, '&', dayComp)
      // }
      // return isSameDay(day, dayComp);
      // const validDate = new Date(d.date);

      if (validDate.getFullYear() !== date.getFullYear()) {
        return false;
      }
      if ((validDate.getMonth()) !== date.getMonth()) {
        return false;
      }
      if (dayjs(validDate).format('DD') !== dayjs(date).format('DD')) {
        return false;
      }
      // if (Number(d.publicCount) !== 1) {
      //   debug(`${d.date} is full`);
      //   return false;
      // }

      return true;
    });
    // console.log(dateFound);
    return !dateFound;
  }

  private onMonthChange(date: string) {
    // We update the previous and next month displayed in the calendar
    this.setChangedMonth(date);
    this.$emit(EVCalendar.MonthChanged, date);
  }

  private setChangedMonth(date: any) {
    // In some cases Datepicker return the date as type of Date and in other cases it return the date as an object
    let month = '';
    if (date && date.timestamp) {
      const d = new Date(date.timestamp);
      this.onMonthChangedDate = d.getMonth();
      return;
    } else {
      month = date.toString().split(' ')[1];
      this.onMonthChangedDate = this.monthNamesShort.indexOf(month);
    }
  }

  private resetSelectedDate(date: string) {
    this.datePicker.selectedDate = date;
  }
}




























import { Vue, Component, Prop } from 'vue-property-decorator';
import Debug from 'debug';
import Select from '@/components/presentational/NewSelect.vue';
import { NBookingModule, GBookingModule } from '@/utils/storemodules';
import { IPriceEntity } from '@/models/store/booking';
import { ITicketPriceRange } from '@/models/store/gbooking';
import { EVSelect } from '@/models/events';
import { ticketSelectedSlots } from '@/utils/booking';

const debug = Debug('smeetz:gbooking');

@Component({
  components: {
    Select,
  },
})
export default class GroupTickets extends Vue {
  @Prop({default: () => ([])}) public ticketsId!: string[];
  private EVSelect = EVSelect;

  // private tickets = ['1235', '123245', '142456'];
  get tickets() {
    return NBookingModule.fetchedTickets.filter((ticket) => {
      return this.ticketsId.includes(String(ticket.categoryId)) && ticket.price.length;
    });
  }

/**
 * Returns the info of each ticket & price.
 * Note that each ticket could have multiple prices. So, we return an object for each
 * ticket price combination
 */
  get ticketsPrices(): ITicketPriceRange[] {
    const tprices: ITicketPriceRange[] = [];

    for (const ticket of this.tickets) {
      for (const price of ticket.price) {
        // const price = ticket.price[0];
        const range: number[] = [0];
        for (let num = price.minBooking; num <= price.maxBooking; num++) {
          range.push(num);
        }
        const ticketCount = GBookingModule.ticketCounts[ticket.categoryId];

        const priceCount = (ticketCount &&
          ticketCount.prices &&
          ticketCount.prices[price.priceId] &&
          ticketCount.prices[price.priceId].count) || range[0];

        tprices.push({
          ticketId: ticket.categoryId,
          ticketName: price.priceName,
          price,
          range,
          count: priceCount,
          sortPrice: price.sortPrice,
        });
      }
    }

    return tprices;
  }

  get orderedTicketsPrices() {
    // Order prices based on their sortPrice value
    const prices = this.ticketsPrices;

    const orderedPrices = prices?.sort((a, b) => {
      let comparaison = 0;
      if (a.sortPrice !== undefined && b.sortPrice !== undefined) {
        comparaison = a.sortPrice - b.sortPrice;
      } else if (!a.sortPrice) {
        comparaison = 1;
      } else if (!b.sortPrice) {
        comparaison = -1;
      }

      // if two prices have the same sortPrice we'll order them alphabetically
      if (comparaison === 0) {
        comparaison = a.ticketName[0] === b.ticketName[0] ? 0 : a.ticketName[0] < b.ticketName[0] ? -1 : 1;
      }
      return comparaison;
    });

    return orderedPrices;
  }


  /**
   * Adds a ticket count with the price selected.
   * Note that count is string because we receive it as a text string from the dom event
   */
  private addPrice(ticket: ITicketPriceRange, price: IPriceEntity , count: string | number) {
    const ticketCounts = GBookingModule.ticketCounts;

    // Update ticket count if already set. Or create a new one
    let ticketCount = ticketCounts[ticket.ticketId];
    if (ticketCount) {
      ticketCount.prices[price.priceId] = {price, count: Number(count)};
    } else {
      ticketCount = {
        prices: {[price.priceId]: {price, count: Number(count)} },
        count: Number(count),
        ticketId: Number(ticket.ticketId),
        // price,
      };
    }

    // Ticket count is equal to the sum of all prices count
    let pricesCount = 0;
    for (const priceId in ticketCount.prices) {
      if (!priceId) {
        continue;
      }
      const priceCount = ticketCount.prices[priceId];
      pricesCount += Number(priceCount.count);
    }

    // Delete the ticket count in case it's prices total count is 0.
    if (Number(pricesCount) === 0) {
      delete ticketCounts[ticket.ticketId];
    } else {
      ticketCount.count = pricesCount;
      ticketCounts[ticket.ticketId] = ticketCount;
    }

    // Make sure that we replace the ticketCounts object so that we trigger update to
    // all of ower watchers.
    GBookingModule.setTicketCounts({...ticketCounts});

    // remove start time if it is no longer supported
    const times = GBookingModule.dayTimes;
    const startDateTime = GBookingModule.startTime;
    if (startDateTime && !(times.includes(startDateTime))) {
      GBookingModule.setStartTime(null);
    }
  }
}

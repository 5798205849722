import Vue from 'vue';
import { Prop, Watch } from 'vue-property-decorator';
import Debug from 'debug';
import { Mixin } from 'vue-mixin-decorator';
import { NBookingModule, AppModule, ProductModule} from '@/utils/storemodules';
import { IProductCategory, LanguagesType } from '@/types/db/product-category';
import { createImageproxyUrl } from '@/utils/pictures';
import {IPriceRetail} from '@/models/store/booking';

const debug = Debug('smeetz:acompte');

enum RetailOptionType {
  Default_Retail_Option = 100,
}

// Map retail's types with their label
const typesLabels: Record<number, string> = {
  1: 'Material',
  2: 'Size',
  3: 'Colour',
  4: 'Style',
} as const;

@Mixin
export default class Retail extends Vue {
  @Prop() public retail!: IProductCategory;
  public isFullCartAddon: boolean = false;
  public isSoldOut: boolean = false;
  public selectedKey: string | number | undefined;
  public selectedQuantity: number = 0;
  public isDirty: boolean = false;
  public isLongRetailDescription: boolean = false;
  private isLoading = false;
  private type: string | undefined;
  private quantities: number[] | null = [];
  private selectedPrice: string | number | undefined;

  private get bookingToken() {
    return NBookingModule.bookingToken;
  }

  private get recapCategories() {
    return NBookingModule.recapCategories;
  }

  get retailError(): string {
    const err = NBookingModule.packageErrors[this.retail.id];
    // return empty string if there is no ticket errors and no price errors
    if (!err) {
      return '';
    } else if (err === 'min') {
      const min = NBookingModule.addonTicketsMinRequired[this.retail.id];
      return (this.$t('new-booking-flow.order.items-min-allowed') as string).replace('X', String(min));
      }
    return '';
  }

  get isMobile() {
    return AppModule.isMobile;
  }

  get currency() {
    if (this.retail.product.currency === 'GBP') {
      return '£';
    } else if (this.retail.product.currency === 'USD') {
      return '$';
    } else if (this.retail.product.currency === 'EUR') {
      return '€';
    }

    return this.retail.product.currency || 'CHF';
  }

  get retailName() {
    const currentLanguage = this.$i18n.locale as LanguagesType;
    return this.retail.nameLocale && this.retail.nameLocale[currentLanguage] ?
      this.retail.nameLocale[currentLanguage] : this.retail.name;
  }

  get retailDescription() {
    const currentLanguage = this.$i18n.locale as LanguagesType;
    return this.retail.descriptionLocale && this.retail.descriptionLocale[currentLanguage] ?
      this.retail.descriptionLocale[currentLanguage] : this.retail.description;
  }

  // Cut the retail description if it is long
  get shortRetailDescription(): string {
    if (this.retailDescription) {
      const textLimit = 180;
      // Useful when description is too near to textLimit
      let cutTextLimit = textLimit - 20;

      // Check how long is pure text in string
      const pureText = this.retailDescription.replace(/<[^>]*>?/gm, '');

      const btnText = this.$t('new-booking-flow.order.see-more-button');
      const btnId = this.retail.id + 'SeeMoreBtn';

      // If pure text > textLimit symbols and text is cutted
      if ( pureText.length > textLimit) {
        this.isLongRetailDescription = true;
        let handled = this.retailDescription.slice(0, cutTextLimit);
        // Make sure that the last word in handled text is a complete word from discreption
        const descriptionWords = this.retailDescription.replace(/<[^>]*>?/gm, '').split(' ');
        const handledWords = handled.replace(/<[^>]*>?/gm, '').split(' ');
        const lastWord = handledWords[handledWords.length - 1];
        if (!descriptionWords.includes(lastWord)) {
          cutTextLimit -= lastWord.length;
          handled = handled.slice(0, cutTextLimit);
        }
        return handled +
          '<a class="see-more-button" id="' + btnId + '"> ...' + btnText + '</a>' ;
      } else {
        this.isLongRetailDescription = false;
        return this.retailDescription;
      }
    } else {
      return '';
    }
  }

  /**
   * Returns retail's default RetailOption.SmeetzPictures first item for picture display use
   */
  get defaultRetailOptionSmeetzPicture(): string {
    const smeetzPicture =
      this.retail.prices.length === 1
        ? this.retail.prices[0].retail[0].smeetzPictures[0]
        : this.retail.prices.find((pr) =>
            pr.retail.some(
              (rt) => rt.type === RetailOptionType.Default_Retail_Option,
            ),
          )?.retail[0].smeetzPictures[0];

    const finalPicture = smeetzPicture !== undefined ?
      createImageproxyUrl(smeetzPicture) : require(`@/assets/images/shopping-icon.png`);

    return finalPicture;
  }

  /**
   * Returns retail's default picture
   */
  get defaultRetailPrice(): number |undefined {
    // If the retail is included as an addon  the defaultRetail price will be zero
    if (NBookingModule.addonsSecondStepInfo.length > 0) {
      const pprice = NBookingModule.addonsSecondStepInfo.find((info) =>
      info.categoryId === this.retail.id);

      if (pprice && pprice.included) {
        return 0;
      }

      // we return the dispalyed value if it is supperiod to zero
      if (pprice && pprice.displayedValue > 0) {
        return pprice.displayedValue * 100;
      }
    }
    const retailPrice =
      this.retail.prices.length === 1
        ? this.retail.prices[0].value
        : this.retail.prices.find((pr) =>
            pr.retail.some(
              (rt) => rt.type === RetailOptionType.Default_Retail_Option,
            ),
          )?.value;
    return retailPrice;
  }

  /**
   * Return options names to use in Select and quantities in the cas of default retail only
   */
  get retailOptionNames(): any {
    if (
      this.retail.prices.length === 1 &&
      this.retail.prices[0].retail[0].type === RetailOptionType.Default_Retail_Option
    ) {

      if (this.quantities && this.quantities?.length > 0)  { return null; }
      this.selectedPrice = this.retail.prices[0].id;
      this.selectedKey = this.retail.prices[0].retail[0].id;
      const retailCategories = this.recapCategories.filter((t) => t.retailOptionId !== null);
      const oldRetailInfo: any = retailCategories.length > 0 ?
      retailCategories.find((p: any) => p && p.retailOptionId === this.selectedKey) : [];
      if (!oldRetailInfo) {
        this.selectedQuantity = 0;
      } else {
        this.selectedQuantity = oldRetailInfo.quantity ? oldRetailInfo.quantity : 0;
      }
      this.quantities = [];
      // If the retail option is set as Selling when sold out we set the default quantity as 24
      const quantity = this.retail.prices[0].retail[0].isSellingWhenSoldOut ? 24 : this.retail.prices[0].quantity;
      const maxQuantity: number = NBookingModule.addonTicketsMaxRequired[this.retail.id] || quantity;
      // We get the Max-booking quantity from the package price of each retail
      const maxBookable = NBookingModule.addonTicketsMaxBookable[this.retail.id] || 24;
      let finalMaxBookable = maxBookable;
      if (this.retailAddon && this.retailAddon.MaxQuantitySetAtTopLevel
         && this.retailAddon.included) {
        finalMaxBookable = Math.min(finalMaxBookable, this.retailAddon.MaxQuantitySetAtTopLevel);
      }
      let minQuantity: number = 0;
      if (maxQuantity > 0) {
        const r = [];
        // In retail we don't have yet Max-booking quantity, we set it here to 25
        while (minQuantity <= maxQuantity && minQuantity <= finalMaxBookable) {
          r.push(minQuantity);
          minQuantity++;
        }
        this.quantities = r;
      }
      return null;
    } else {
      // this retail has options other than the default one
      const activePrices =  this.retail.prices.filter((price) => !price.cancelled);
      const retails = activePrices
        .filter((p) =>
          p.retail.every(
            (r) => r.type !== RetailOptionType.Default_Retail_Option,
          ),
        )
        .map((p) => {
          this.type = typesLabels[p.retail[0].type];
          return p.retail[0].name;
        }).sort();
      if (this.selectedKey) {
        const retailsOptions = this.getRetailOptions(this.retail);
        const selectedRetail = retailsOptions.filter((pr: any) => pr.key === this.selectedKey);
        this.setSelectOptionName(selectedRetail.name);
      } else {
        this.setSelectOptionName(retails[0]);
      }

      return retails;
    }
  }

  get retailAddon() {
    if (NBookingModule.addonsSecondStepInfo.length > 0) {
      const retailPackagePrice = NBookingModule.addonsSecondStepInfo.find((retail) =>
      retail.categoryId === this.retail.id);
      if (retailPackagePrice) {
        return retailPackagePrice;
      }
    }
  }

  get retailQuantities() {
    // If the Retail is included addon and the maximum booked addon is set on top level of package price
    // we remove the previously booked included addons (ticket and retail)
    // from the total quantities of the Retail
    if (this.retailAddon && this.retailAddon.included && this.retailAddon.MaxQuantitySetAtTopLevel) {
      const addonsIds: number [] = [];
      const previouslyBookedQuantity: number[] = [];
      // Get all included second step included addons
      NBookingModule.addonsSecondStepInfo.forEach((addon) => {
        if (addon.included) {
          addonsIds.push(addon.categoryId);
        }
      });
      // Get the already booked  included addons
      const bookedAddons = this.recapCategories.filter((retail) =>
      addonsIds.includes(retail.categoryId));
      for (const retail of bookedAddons) {
        previouslyBookedQuantity.push(Number(retail.quantity));
      }
      // calculate the total quantity of the booked addons
      const quantities: number = previouslyBookedQuantity.reduce((a, b) => a + b, 0);
      // substract the quantity from the maxQuantity set on the package price top level
      const finalMaxQuantity = this.retailAddon.MaxQuantitySetAtTopLevel - quantities;
      let minQuantity = 0;
      if (finalMaxQuantity >= 0) {
        const r = [];
        while (minQuantity <= finalMaxQuantity) {
          r.push(minQuantity);
          minQuantity ++;
        }
      // Make sure that new quantity is not less than the already selectedQuantity
        if (this.selectedQuantity && this.selectedQuantity > 0
        && r.length - 1 < this.selectedQuantity) {
          return this.quantities;
      }
      // Set the quantity
        this.quantities = r;
      }
      return this.quantities;
    }
    // else we return the quantities
    return this.quantities;
  }

  private selectRefName() {
    return String(Math.random());
  }

  private selectRef() {
    return String(Math.random());
  }

  private showFullAddon() {
    this.$emit('selected', this.retail);
  }

  /**
   * Set selected retail option and set quantity select
   */
  private async setSelectOptionName(name: string) {
    if (!name) {
      return;
    }
    const retailsOptions = this.getRetailOptions(this.retail);
    const selectedRetail = retailsOptions.filter((pr: any) => pr.name === name);
    this.selectedPrice = selectedRetail[0].price.id;
    this.selectedKey = selectedRetail[0].key;
    const retailCategories = this.recapCategories.filter((t) => t.retailOptionId !== null);
    const oldRetailInfo: any = retailCategories.length > 0
    ? retailCategories.find((p: any) => p && p.retailOptionId === this.selectedKey)
    : [];
    if (!oldRetailInfo) {
    this.selectedQuantity = 0;
    } else {
    this.selectedQuantity = oldRetailInfo.quantity ? oldRetailInfo.quantity : 0;
    }
    this.quantities = [];

    // If the retail option is set as Selling when sold out we set the default quantity as 24
    const quantity = selectedRetail[0].price.retail[0].isSellingWhenSoldOut ? 24 : selectedRetail[0].quantity;
    let maxQuantity: number = NBookingModule.addonTicketsMaxRequired[this.retail.id] || quantity;
    const quantityAlreadyAdded = NBookingModule.recapCategories.reduce((total, currentTicket) => {
      if (currentTicket.categoryId === this.retail.id) {
        return total + Number(currentTicket.quantity);
      }
      return total;
    }, 0);
    if (quantityAlreadyAdded) {
      maxQuantity = maxQuantity - Number(quantityAlreadyAdded);
    }
    const finalMaxQuantity = Math.max(maxQuantity, this.selectedQuantity);
    let minQuantity: number = 0;
     // We get the Max-booking quantity from the package price of each retail
    const maxBookable = NBookingModule.addonTicketsMaxBookable[this.retail.id] || 24;
    let finalMaxBookable = maxBookable;
    if (this.retailAddon && this.retailAddon.MaxQuantitySetAtTopLevel
      && this.retailAddon.included) {
      finalMaxBookable = Math.min(finalMaxBookable, this.retailAddon.MaxQuantitySetAtTopLevel);
    }
    if (finalMaxQuantity > 0) {
      const r = [];
      while (minQuantity <= finalMaxQuantity  && minQuantity <= finalMaxBookable) {
        r.push(minQuantity);
        minQuantity++;
      }
      this.quantities = r;
    }

    this.isDirty = false;
    if (selectedRetail && selectedRetail.length > 0 &&
      selectedRetail[0].quantity === 0 && !selectedRetail[0].price.retail[0].isSellingWhenSoldOut) {
      this.isSoldOut = true;
    } else {
      this.isSoldOut = false;
    }
  }

  private setSelectQuantity(quantity: number) {
    this.selectedQuantity = quantity;
    this.isDirty = true;
    if (this.isFullCartAddon && String(quantity) === '0') {
      this.addPriceRetail(0);
    }
    this.addPriceRetail(quantity);
  }

  private async addPriceRetail(quantity: number) {
    if (!quantity) {
      return;
    }
    this.selectedQuantity = quantity;
    this.isLoading = true;
    // on  AddonFullCard component on mobile we should update the selectedPrice and selectedKey
    // in case the Retail doesn't have options
    if (!this.retailOptionNames) {
      this.selectedPrice = this.retail.prices[0].id;
      this.selectedKey = this.retail.prices[0].retail[0].id;
    }
    const retail: IPriceRetail = {
      retailPriceId: Number(this.selectedPrice),
      count: Number(quantity),
      retailOptionId: Number(this.selectedKey),
      categoryId: this.retail.id,
    };
    // If the retail is an addon we add the package price informations
    if (NBookingModule.addonsSecondStepInfo.length > 0) {
      const pprice = NBookingModule.addonsSecondStepInfo.find((info) => info.priceId === this.selectedPrice);
      if (pprice) {
        retail.mainTicket = pprice.mainTicket;
        retail.packagePriceId = pprice.packagePriceId;
        retail.priceId = pprice.priceId;
      }
    }
    try {
      this.isDirty = false;
      await NBookingModule.addRetailPrice(retail);
    } finally {
      this.isLoading = false;
     // In AddonsFullCard we auto close the card
      // we Keep this code in case we revert the addons work
      // if (this.isFullCartAddon && this.isMobile) {
      //   this.$emit('close');
      //   this.selectedKey = 0;
      // }
    }
  }


  /**
   * Takes the Product Category and returns its retails options
   */
  private getRetailOptions(pc: IProductCategory): any | number | undefined {
      const retails = pc.prices
        .filter((p) =>
          p.retail.every(
            (r) => r.type !== RetailOptionType.Default_Retail_Option,
          ),
        )
        .map((p) => {
          return {
            price: p,
            key: p.retail[0].id,
            name: p.retail[0].name,
            quantity: p.quantity,
          };
        });
      return retails;
  }

  @Watch('bookingToken')
  private setRecapRetails() {
    if (this.isDirty && this.selectedQuantity > 0) {
      this.addPriceRetail(this.selectedQuantity);
      return;
    }
    const retailCategories = this.recapCategories.filter((t) => t.retailOptionId !== null);

    const oldRetailInfo: any = retailCategories.length > 0 ?
    retailCategories.find((p: any) => p && p.retailOptionId === this.selectedKey) : [];
    if (!oldRetailInfo) {
      this.selectedQuantity = 0;
    } else {
      this.selectedQuantity = oldRetailInfo.quantity ? oldRetailInfo.quantity : 0;
    }
  }

}

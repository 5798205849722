






















import { Vue, Component } from 'vue-property-decorator';
import PersonalDetails from './PersonalDetails.vue';
import Emails from './Emails.vue';
import Phones from './Phones.vue';
import Bookings from './Bookings.vue';
import Organize from './Organize.vue';
import SwissPass from './SwissPass.vue';
import { AppModule } from '@/utils/storemodules';
import Nav from '@/components/NewNavigation.vue';

@Component({
  components: {
    PersonalDetails,
    Emails,
    Phones,
    Bookings,
    Organize,
    SwissPass,
    Nav,
  },
})
export default class UserSpace extends Vue {
  get isTablet() {
    return AppModule.isTablet;
  }
}

import { ORG_PRIMARY_COLOR, PRIMARY_COLOR, SECONDARY_COLOR, ORG_BACKGROUND, ORG_FONT_COLOR } from '@/config/constants';
import { IOrganizerColors, ColorStyles, IMainColors, IWidgetStyles } from '@/models/styles';

export function setBookingColors(cols?: IOrganizerColors) {
  const colors: Partial<IOrganizerColors> = cols || {};

  const root: any = document.querySelector(':root');
  if (root && root.style) {
    root.style.setProperty(ColorStyles.Primary, colors.smeetzPrimaryColor || ORG_PRIMARY_COLOR);
    root.style.setProperty(ColorStyles.QPrimary, colors.smeetzPrimaryColor || ORG_PRIMARY_COLOR);
    root.style.setProperty(ColorStyles.Secondary, colors.smeetzPrimaryColor || ORG_PRIMARY_COLOR);
    root.style.setProperty(ColorStyles.Organizer, colors.smeetzPrimaryColor || ORG_PRIMARY_COLOR);
    root.style.setProperty(ColorStyles.OrganizerBackground, colors.colourBackground || ORG_BACKGROUND);
    root.style.setProperty(ColorStyles.OrganizerFontColor, colors.colourText || ORG_FONT_COLOR);
  }

  // Set pwa theme color
  const meta = document.querySelector('meta[name=theme-color]');
  if (meta) {
    meta.setAttribute('content', colors.smeetzPrimaryColor || ORG_PRIMARY_COLOR);
  }
}

export function setWidgetColors(styles?: IWidgetStyles) {
  const root: any = document.querySelector(':root');
  if (root && root.style) {
    root.style.setProperty('--widget-nav-color', styles?.navCol || 'white');
    root.style.setProperty('--widget-title-color', styles?.titleCol || 'white');
  }
}

export function setOrganizerColor(mainColors: IMainColors) {
  const root: any = document.querySelector(':root');
  if (root && root.style) {
    root.style.setProperty('--primary-color', mainColors.primary || PRIMARY_COLOR);
    root.style.setProperty('--q-color-primary', mainColors.primary || PRIMARY_COLOR);
    root.style.setProperty('--secondary-color', mainColors.secondary || SECONDARY_COLOR);
  }
}

export function resetSiteColors() {
  const root: any = document.querySelector(':root');
  if (root && root.style) {
    root.style.setProperty('--primary-color', PRIMARY_COLOR);
    root.style.setProperty('--q-color-primary', PRIMARY_COLOR);
    root.style.setProperty('--secondary-color', SECONDARY_COLOR);
    root.style.setProperty(ColorStyles.Organizer, PRIMARY_COLOR);
  }
}

export function getPrimaryColor() {
  const root: HTMLElement | null = document.querySelector(':root');
  if (root && root.style) {
    return root.style.getPropertyValue('--primary-color') || ORG_PRIMARY_COLOR;
  }

  return ORG_PRIMARY_COLOR;
}

/**
 * Returns primary color with different opacity
 * @param alpha {number} opacity from 0 to 1
 */
export function getPrimaryWithOpacity(alpha: string) {
  return hexToRGBA(getPrimaryColor(), alpha);
}

export function hexToRGBA(hex: string, alpha: string): string {
  let r: string | number = 0;
  let g: string | number = 0;
  let b: string | number = 0;

  // 3 digits
  if (hex.length === 4) {
    r = '0x' + hex[1] + hex[1];
    g = '0x' + hex[2] + hex[2];
    b = '0x' + hex[3] + hex[3];

    // 6 digits
  } else if (hex.length >= 7) {
    r = '0x' + hex[1] + hex[2];
    g = '0x' + hex[3] + hex[4];
    b = '0x' + hex[5] + hex[6];
  }

  return 'rgba(' + +r + ',' + +g + ',' + +b + ',' + alpha + ')';
}

// Takes a color code and returns it opposite color
export function invertColor(col: string): string {
  const colors = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f'];
  let inverseColor = '#';
  col = col.toLowerCase();
  col.replace('#', '').split('').forEach((i) => {
    const index = colors.indexOf(i);
    inverseColor += colors.reverse()[index];
  });
  return inverseColor;
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"local-login-form"},[_c('form',{attrs:{"method":"post","action":"/register"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('div',{staticClass:"field"},[_c('p',{staticClass:"control has-icons-right"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.email.$model),expression:"$v.email.$model"}],staticClass:"input is-medium",class:{'has-error': _vm.$v.email.$error},attrs:{"type":"email","placeholder":_vm.$t('auth.field-email-placeholder')},domProps:{"value":(_vm.$v.email.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.email, "$model", $event.target.value)}}}),_vm._m(0)])]),(_vm.$v.email.$error || _vm.invalidLogin)?_c('div',{staticClass:"input-error-message"},[_c('i',{staticClass:"material-icons"},[_vm._v("close")]),(_vm.invalidLogin)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('auth.field-password-wrong-combination'))+" ")]):(!_vm.$v.email.required)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('auth.field-email-empty'))+" ")]):(!_vm.$v.email.email)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('auth.field-email-invalid'))+" ")]):(!_vm.$v.email.maxLength)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('auth.field-email-max'))+" ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"field"},[_c('p',{staticClass:"control has-icons-right"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.password.$model),expression:"$v.password.$model"}],staticClass:"input is-medium",class:{'has-error': _vm.$v.password.$error},attrs:{"type":"password","placeholder":_vm.$t('auth.field-password-placeholder')},domProps:{"value":(_vm.$v.password.$model)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.password, "$model", $event.target.value)},_vm.clearInvalid]}}),_vm._m(1)])]),(_vm.$v.password.$error)?_c('div',[_c('div',{class:{ 
          'input-error-message': !_vm.$v.password.minLength,
          'input-success-message': _vm.$v.password.minLength,
        }},[(!_vm.$v.password.minLength)?_c('i',{staticClass:"material-icons"},[_vm._v("close")]):_c('i',{staticClass:"material-icons"},[_vm._v("check")]),_c('span',[_vm._v(" "+_vm._s(_vm.$t('auth.field-password-rule1'))+" ")])]),_c('div',{class:{ 
          'input-error-message': !_vm.$v.password.hasUpperAndLower,
          'input-success-message': _vm.$v.password.hasUpperAndLower,
        }},[(!_vm.$v.password.hasUpperAndLower)?_c('i',{staticClass:"material-icons"},[_vm._v("close")]):_c('i',{staticClass:"material-icons"},[_vm._v("check")]),_c('span',[_vm._v(" "+_vm._s(_vm.$t('auth.field-password-rule2'))+" ")])]),_c('div',{class:{ 
          'input-error-message': !_vm.$v.password.hasNum,
          'input-success-message': _vm.$v.password.hasNum,
        }},[(!_vm.$v.password.hasNum)?_c('i',{staticClass:"material-icons"},[_vm._v("close")]):_c('i',{staticClass:"material-icons"},[_vm._v("check")]),_c('span',[_vm._v(" "+_vm._s(_vm.$t('auth.field-password-rule3'))+" ")])]),_c('div',{class:{ 
          'input-error-message': !_vm.$v.password.hasSpecial,
          'input-success-message': _vm.$v.password.hasSpecial,
        }},[(!_vm.$v.password.hasSpecial)?_c('i',{staticClass:"material-icons"},[_vm._v("close")]):_c('i',{staticClass:"material-icons"},[_vm._v("check")]),_c('span',[_vm._v(" "+_vm._s(_vm.$t('auth.field-password-rule4'))+" ")])])]):_vm._e(),_c('a',{staticClass:"reset-password",attrs:{"to":"/"},on:{"click":_vm.forgottenPass}},[_vm._v(" "+_vm._s(_vm.$t('auth.link-forgot-password'))+" ")]),_c('hr'),_c('div',{staticClass:"submit-wrapper"},[_c('a',{staticClass:"button round is-primary",class:{'is-loading': this.isLoading},attrs:{"disabled":!_vm.enableSubmit},on:{"click":_vm.submit}},[_c('span',[_vm._v(_vm._s(_vm.$t('register.submit')))]),_c('i',{staticClass:"material-icons"},[_vm._v("arrow_forward")])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon is-right"},[_c('i',{staticClass:"material-icons"},[_vm._v("email")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon is-right"},[_c('i',{staticClass:"material-icons"},[_vm._v("lock_outline")])])}]

export { render, staticRenderFns }
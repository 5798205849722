
















import { Vue, Component, Watch } from 'vue-property-decorator';
import NewLogin from '@/components/modal/NewLogin.vue';
import { OrganizerModule, UserModule } from '@/utils/storemodules';
import UserSpaceNav from './UserSpaceNav.vue';
import { getPrimaryWithOpacity } from '@/utils/colors';
import UserBooking from '../UserSpace/Bookings.vue';
import { disableQuasarStyling, enableQuasarStyling, hideScrollBar } from '@/utils/styles';
import { inIframe, setWidgetHeight } from '@/utils/iframe';
import UserSpaceContentsWrapper from './UserSpaceContentsWrapper.vue';
import { getOrganizerData } from '@/api/organizer';
import { setAuthInfo } from '@/utils/siteInit';
import { RouteNames } from '@/models/enums';
import { setMainColors } from '@/utils/helpers';
import request from '@/utils/request';

// import { getOrganizerPictures } from '@/api/organizer';

@Component({
  components: {
    NewLogin,
    UserSpaceNav,
    UserBooking,
    UserSpaceContentsWrapper,
  },
})
export default class OrganizerUserSpace extends Vue {
  get isCustomAuthLoggedIn(): boolean {
    return UserModule.isCustomAuthLoggedIn;
  }

  private orgName: string = '';
  private orgColor: string = '';

  private get isIframe() {
    return inIframe();
  }

  private async created() {
    enableQuasarStyling();
    // lets get and set the organizer id because we will need it
    const {orgId} = this.$route.params;
    OrganizerModule.setORGId(Number(orgId));
    const {data: { GroupRead }} = await getOrganizerData(Number(orgId));
    this.orgName = GroupRead.Name;
    // CHECK if we get the color from the URL
    // if so get the color from the API
    this.orgColor = OrganizerModule.orgColor;
    // Empty string means we didn't get it from the URL
    if (this.orgColor === '') {
      this.orgColor = GroupRead.brandingList[0].WidgetHeaderColor;
    }
    // Set the main color
    setMainColors(this.orgColor);

    const { locale } = this.$route.params;
    if ( locale ) {
      this.$i18n.locale = locale;
      const HTML_TAG = document.getElementsByTagName('html');
      (HTML_TAG as any)[0].lang = this.$i18n.locale;
      request.defaults.baseURL = (request.defaults.baseURL as string).slice(0, -3) + this.$i18n.locale + '/';
    }
  }

  private async mounted() {
    setWidgetHeight('smtz-p-widget');
    hideScrollBar();
    setAuthInfo();
    // If user is not logged in we'll customize NewLogin component for him
    // so that it looks belonging to the organizer website
    if (!this.isCustomAuthLoggedIn) {
      this.customizeLoginColors();
    }
  }

  // Customizes login layout colors based on the organizer website colors
  private customizeLoginColors() {
    const bgColor = getPrimaryWithOpacity('0.085');
    const socialLogins: any = document.querySelectorAll('.new-login .wrapper .buttons-wrapper .grey');
    const footer: any = document.querySelector('.new-login footer');
    if (footer) {
      footer.style.backgroundColor = bgColor;
    }
    for (const btn of socialLogins) {
      if (btn) {
        (btn as any).style.backgroundColor = bgColor;
      }
    }
  }

  @Watch('isCustomAuthLoggedIn')
  private goToUserBookings(isAuth: boolean) {
    if (isAuth) {
      const accessToken = UserModule.getCustomAuthToken;
      if (this.$route.name === RouteNames.OrganizerUserSpace) {
        this.$router.replace({path: `/${this.$i18n.locale}/org-user/${this.$route.params.orgId}/userbookings`,
                            query: { access_token: accessToken } });
      } else {
        this.$router.replace({path: '', query: { access_token: accessToken }});
      }
    }
  }

  private destroyed() {
    disableQuasarStyling();
  }

   // A router guard to preserve the query parameters
  private beforeRouteUpdate(to: any, from: any, next: any) {
    // Avoid preserving query parameters if already preserved
    if (!!Object.keys(to.query).length) {
      return next();
    }
    // There is no point of preserving if there is no parameters to preserve
    if (!(Object.keys(from.query).length)) {
      return next();
    }
    return next({
      replace: true,
      path: to.path,
      query: {
        ...to.query,
        ...from.query,
      },
    });
  }
}



















































import { Vue, Component } from 'vue-property-decorator';
import UserSpaceCard from './components/UserSpaceCard.vue';
import { AuthModule, ModalModule, NModalModule, NNoty } from '@/utils/storemodules';
import { ModalStatus } from '@/models/definitions';


@Component({
  components: {
    UserSpaceCard,
  },
})
export default class Emails extends Vue {
  private ModalStatus = ModalStatus;
  private isLoading: boolean = false;

  @AuthModule.Getter('getEmails') private emails: any;
  @AuthModule.Action('getUser') private getUser!: any;
  @AuthModule.Action('sendConfEmail') private sendConfEmail!: any;
  @AuthModule.Action('cancelEmailPhone') private cancelEmail!: any;
  @AuthModule.Action('setPrimaryEmail') private setPrimaryEmail!: any;

  private setS(status: ModalStatus) {
    NModalModule.setStatus(status);
  }

  private async verifyEmail(email: string) {
    try {
      await this.sendConfEmail(email);
    } catch (err) {
      return;
    }

    this.$router.replace({
      query: Object.assign({}, this.$route.query, { email }),
    });

    NModalModule.setStatus(ModalStatus.EmailConf);
  }

  private async removeEmail(email: any) {
    try {
      this.isLoading = true;
      const response = await this.cancelEmail({ emailPhoneId: email.id });
      this.isLoading = false;

      const nnotyType = response.success ? 'success' : 'error';
      const nnotyMessage = response.success ? this.$t('user-space.email-successfully-removed') as string
      : this.$t('error.error-response-banner') as string;

      NNoty.createNewNoty({
        type: nnotyType,
        period: 5000,
        message: nnotyMessage,
      });

      await this.getUser();
    } catch (err) {
      return;
    }
  }

  private async makePrimary(email: any) {
    try {
      await this.setPrimaryEmail(email.id);
      await this.getUser();
    } catch (err) {
      return;
    }
  }
}

export const Status: {[index: number]: string} = {
  200: 'OK',
  201: 'Created',
  400: 'Bad Request',
  401: 'Unauthorized',
  409: 'Conflict',
  504: 'Gateway Time-out',
  406: 'Not Acceptable',
};

export const HttpStatus: {[index: string]: number} = {
  'OK': 200,
  'Created': 201,
  'Bad Request': 400,
  'Unauthorized': 401,
  'Conflict': 409,
  'Gateway Time-out': 504,
  'Not Acceptable': 406,
};




















































import { Vue, Component } from 'vue-property-decorator';
import ModalButton from '@/components/modal/ModalButton.vue';
import { AuthModule, NBookingModule, OrganizerModule, NNoty } from '@/utils/storemodules';
import { email, required, maxLength } from 'vuelidate/lib/validators';
import { IGQLProductSlotBookingRead } from '@/models/store/booking';
import { postResendBooking2 } from '@/api/user';

import Noty from 'noty';

const availableValidations: any = {
  confirmEmail: {
    required,
    email,
    maxLength: maxLength(255),
  },
};

@Component({
  components: {
    ModalButton,
  },
  validations() {
    // @ts-ignore
    return this.currentValidations;
  },
})
export default class BookingEmailConf extends Vue {
  private bookingId: number = 0;
  private bookingToken: string | null = null;
  private email!: string | undefined;
  private confirmEmail: string = '';
  private sendEmailAgain: boolean = false;
  private changeEmail: boolean = false;
  private emailSent: boolean = false;

  private created() {
    this.bookingId = Number(this.$route.query.bookingId);
    this.bookingToken = this.$route.query.bookingToken as string;

    this.email = NBookingModule.recapInfo.bookingEmail;
    if (!this.email) {
      return;
    }
  }

  private get currentValidations() {
    return availableValidations;
  }

  get enableSubmit() {
    return !this.$v.$error;
  }

  private validateEmail() {
    this.email = this.confirmEmail;
    this.confirmEmail = '';
    this.changeEmail = false;
  }

  // Send confirmation email
  private async send() {
    const button: ModalButton = this.$refs.button as ModalButton;
    button.executing(true);
    try {
      const result2 = await postResendBooking2(this.bookingId, this.bookingToken);
      new Noty({
        type: 'success',
        text: String(this.$t('field.message-tickets-sent')),
        timeout: 4000,
        theme: 'metroui',
      }).show();
    } catch (error) {
      new Noty({
        type: 'error',
        text: String(this.$t('error.error-response-banner')),
        timeout: 4000,
        theme: 'metroui',
      }).show();
      throw error;
    } finally {
      this.emailSent = true;
      button.executing(false);
    }

  }
}

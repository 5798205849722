import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators';
import { getOrganizerInfo, getAdyenOrganizerInfo } from '@/api/organizer';
import { IActivity } from '@/models/store/region';
import { client } from '@/utils/searchConfig';
import { IOrganizerState, IOrganizerMainInfo } from '@/models/organizer';
import moment from 'moment';
import { productNearestDate } from '@/utils/helpers';
import dayjs from 'dayjs';
import { EPaymentsLogos } from '@/config/constants';
import { ProductModule } from '@/utils/storemodules';
import { DateTimeFormat, PProcessors, RouteNames } from '@/models/enums';
import { inIframe } from '@/utils/iframe';
import { IGQLAdyenOrgInfo, IGQLGroupReadData } from '@/models/organizer';


@Module({ name: 'organizer', namespaced: true })
class Organizer extends VuexModule implements IOrganizerState {
  public allActivities: IActivity[] = [];
  public topSellersActivities: IActivity[] = [];
  public memberships: IActivity[] = [];
  public giftCards: IActivity[] = [];
  public info: IOrganizerMainInfo | null = null;
  public fbPixelId: string = '';
  public gaId: string = '';
  public gtmId: string = '';
  public orgId: number | null = null;
  public organizerInfoForAdyen: IGQLGroupReadData | null = null;
  public orgColor: string = '';

  public get getAllActivities() {
    return this.allActivities;
  }

  public get getTopSellersActivities() {
    return this.topSellersActivities;
  }

  public get getInfo() {
    return this.info;
  }

  /**
   * Returns the organizer id
   */
  public get id(): number | null {
    const info = this.info;
    const orgId = this.orgId;

    if (orgId) {
      return orgId;
    } else if (!info) {
      return null;
    }
    return info.groupInfo.id;
  }

  public get orgName(): string {
    const info = this.info;

    if (!info) {
      return '';
    }

    return info?.groupInfo?.branding?.companyName || '';
  }

  public get organizerWalleePaymentMethods(): string[] {
    const info = this.info;

    if (!info) {
      return [];
    }

    return info?.organizerWalleePaymentMethods;
  }

  // Returns the logos of all the electronic payment methods
  // supported by the organser
  public get paymentsLogos(): string[] {
    const {
      PAYPAL,
      VISA,
      MASTERCARD,
      POSTCARD,
      TWINT,
    } = EPaymentsLogos;

    // Show defaults when no organiser info is loaded
    if (!this.info || (this.info && !this.info.availablePaymentMethods)) {
      return [
        MASTERCARD, VISA, // PAYPAL, POSTCARD, TWINT,
      ];
    }

    let methods = this.info.availablePaymentMethods;
    if (!window.ApplePaySession) {
      methods = methods.filter((item: string) => !item.includes('APPLEPAY'));
    }
    const logos: string[] = [];
    for (const method of methods) {
      const logo = EPaymentsLogos[method];
      if (!logo) {
        continue;
      }

      logos.push(logo);
    }

    return logos;
  }

  get paymentMethods(): string[] {
    const info = this.info;
    if (!info) {
      return [];
    }

    return info.availablePaymentMethods;
  }

  public get getOrganizerDateFormat(): DateTimeFormat {
    if (ProductModule.productCurrency === '£' || ProductModule.productCurrency === '$') {
      return DateTimeFormat.British;
    } else {
      return DateTimeFormat.World;
    }
  }

  /**
   * Returns whether or not we are in organizer website uer space
   */
  public get isOrganizerUserSpace(): boolean {
    if (this.id && window.location.pathname.includes('org-user')) {
      return true;
    }
    return false;
  }

  /**
   * Checks whether organiser can process payments through Adyen.
   */
  public get canProcessAdyen(): boolean {
    const info = this.info;

    if (!info || !info.groupInfo) {
      return false;
    }

    return info.groupInfo.paymentProcessor === PProcessors.Adyen;
  }

  public get getOrganizerInfoForAdyen() {
    return this.organizerInfoForAdyen;
  }

  @Mutation
  public setInfo(payload: IOrganizerMainInfo) {
    this.info = payload;
  }

  @Mutation
  public setAllActivities(data: IActivity[]) {
    this.allActivities = this.allActivities.concat(data);
  }

  @Mutation
  public setTopSellers(data: IActivity[]) {
    this.topSellersActivities = this.topSellersActivities.concat(data);
  }

  @Mutation
  public setMemberships(data: IActivity[]) {
    this.memberships = this.memberships.concat(data);
  }

  @Mutation
  public setGiftCards(data: IActivity[]) {
    this.giftCards = this.giftCards.concat(data);
  }

  @Mutation
  public setORGId(orgId: number) {
    this.orgId = orgId;
  }

  @Mutation
  public setFBId(fbId: string) {
    this.fbPixelId = fbId;
  }

  @Mutation
  public setGAId(gaId: string) {
    this.gaId = gaId;
  }

  @Mutation
  public setGtmId(gtmId: string) {
    this.gtmId = gtmId;
  }

  @Mutation
  public setOrganizerInfoForAdyen(organizerInfoForAdyen: IGQLAdyenOrgInfo) {
    const {
      brandingList,
      LegalEntityType,
      Name,
      RegistrationNumber,
    } = organizerInfoForAdyen.data.GroupRead;

    this.organizerInfoForAdyen = {
      brandingList,
      LegalEntityType,
      Name,
      RegistrationNumber,
    };
  }
  @Mutation
  public setOrgColor(color: string) {
    this.orgColor = color;
  }

  @Action
  public async getOrganizerInfo(payload: string) {
    try {
      const response = await getOrganizerInfo(payload);
      this.setInfo(response);
    } catch (err) {
      throw Error(`getOrganizerInfo: ${err.message}`);
    }
  }

  // Fetches the info of organizer that we need for adyen integration.
  @Action
  public async fetchOrganizerInfoForAdyen(organizerId: number) {
    try {
      const response = await getAdyenOrganizerInfo(organizerId);
      this.setOrganizerInfoForAdyen(response);
    } catch (err) {
      throw Error(`fetchOrganizerInfoForAdyen: ${err}`);
    }
  }

  // fetch organiser info;
  @Action
  public async fetchOrganiserInfo() {
    const p = ProductModule.product;
    if (p) {
      const organiserSlug = p && p.organiser
        && p.organiser && p.organiser.slugName;

      if (organiserSlug) {
        await this.getOrganizerInfo(organiserSlug);
      }

      if (this.canProcessAdyen && this.id) {
        await this.fetchOrganizerInfoForAdyen(this.id);
      }
    }
  }

  @Action({ rawError: true })
  public async fetchAppSearch(payload: any) {
    // const dateNow = moment().utcOffset(0);
    // dateNow.set({hour: 0, minute: 0, second: 0, millisecond: 0});
    // dateNow.format();
    // const currentDate  = dateNow.toISOString();
    // payload.options.filters.all.push({
    //   dates: { from: currentDate },
    // });
    await client
      .search(payload.searchQuery ? payload.searchQuery : '', payload.options)
      .then((resultList: any) => {
        const arrayForData: any = [];
        resultList.results.forEach((result: any) => {
          Object.keys(result.data).forEach((parameter: any) => {
            // tslint:disable-next-line:max-line-length
            if ((result.data && result.data[parameter]) &&
              result.data[parameter].raw ||
              result.data[parameter].raw === '' ||
              result.data[parameter].raw === 0 ||
              result.data[parameter].raw === null) {
              const rawData = result.data[parameter].raw;
              result.data[parameter] = rawData;
            }
          });
          arrayForData.push(result.data);
        });
        let sortedByDateProducts = [];
        if (arrayForData.length) {
          const nearestProductsWithOneDate = productNearestDate(arrayForData);
          // tslint:disable-next-line:max-line-length
          sortedByDateProducts = (nearestProductsWithOneDate as any[]).sort((a, b) => moment(a.dates).format('x') - moment(b.dates).format('x'));
        }
        // @ts-ignore
        this[`set${payload.type}`](sortedByDateProducts);
      })
      .catch((error: any) => {
        throw Error(`fetchAppSearch: ${error.message}`);
      });
  }
}

export default Organizer;

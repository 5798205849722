import Vue from 'vue';
// import { fetchTicketSlots } from '@/api/booking';
import dayjs from 'dayjs';
import { Prop, Watch } from 'vue-property-decorator';
import { Mixin } from 'vue-mixin-decorator';
import {
  ITimeslotsEntity, IExportPricesEntity, ITicket,
  IFrontRecapTicket,
} from '@/models/store/booking';
import { EVSelect, EVSlotPrice } from '@/models/events';
import { NBookingModule, ProductModule, NNoty } from '@/utils/storemodules';

@Mixin
export default class SlotPriceMixin extends Vue {
  @Prop() private price!: IExportPricesEntity;
  @Prop() private timeSlot!: ITimeslotsEntity;
  @Prop() private ticket!: ITicket;
  @Prop({ default: '' }) private unit!: string;

  private EVSelect = EVSelect;
  private isLoading = false;
  private slot: ITimeslotsEntity | null = null;

  // Indicates whether the user already tried selecting this ticket
  // or not
  private isDirty = false;

  private get currency() {
    return ProductModule.productCurrency;
  }

  get selectRef() {
    return String(Math.random());
  }

  get priceCount(): number {
    // return this.price.count || 0;
    const price =
      NBookingModule.selectedNormalPriceById(this.price.priceId, this.timeSlot.id);

    return (price && Number(price.quantity)) || 0;
  }

  // returns the number of prices already booked for a given timeslot
  get bookedSlotQuantity(): number {
    const slot = this.slot || this.timeSlot;
    if (!slot) {
      return 0;
    }

    let quantity = 0;

    for (const t of NBookingModule.orderRecap) {
      if (t.timeSlotId === slot.id) {
        // timeslot found. add the prices and return the total
        for (const price of t.prices) {
          quantity = quantity + Number(price.quantity);
        }
        return quantity;
      }
    }

    return quantity;
  }

  get priceRange(): number[] {
    const range = [0];
    const slot = this.slot || this.timeSlot;
    if (!this.price) {
      return range;
    }

    if (!slot) {
      return range;
    }

    const min = this.price.minBooking;

    // max is remaining spots + what is already reserved
    const priceMax = this.timeSlot.publicCount - this.bookedSlotQuantity + this.priceCount;

    // max must not be more than max booking
    const max = priceMax > this.price.maxBooking ? this.price.maxBooking : priceMax;

    let count = min;

    // don't duplicate the zero
    if (count === 0) {
      count++;
    }

    while (count <= max) {
      range.push(count);
      count++;
    }

    return range;
  }

  // required prices for this ticket
  get reqPrices() {
    return NBookingModule.ticketReqPrices[String(this.ticket.categoryId)];
  }

  // checks whether this is a required price
  get isRequiredPrice() {
    return this.reqPrices.includes(this.price.priceId);
  }

  // Returns required ticket prices that aren't selected.
  get missingReqPrices() {
    const reqPrices = this.reqPrices;
    const timeSlot = this.timeSlot;

    const nonSelectedPrices: IExportPricesEntity[] = [];
    // In case the ticket has required prices
    // make sure that they are already selected
    if (reqPrices && !reqPrices.includes(this.price.priceId)) {

      // check which required prices aren't already selected
      for (const pId of reqPrices) {
        const pr = NBookingModule.selectedNormalPriceById(pId);
        if (!pr && timeSlot.export_prices) {
          const price = timeSlot.export_prices.find((p) => p.priceId === pId);
          if (price) {
            nonSelectedPrices.push(price);
          }
        }
      }
    }

    // this.missingReqPrices = [];
    return nonSelectedPrices;
  }

  get missingReqPricesNames(): string {
    return this.missingReqPrices.map((p) => p.priceName)
      .join(', ');
  }

  // price could be selected if it doesn't have missing req prices
  get canSelect() {
    return this.missingReqPrices.length === 0;
  }

  // Clear this price if it's not a required price and there
  // are some missing required prices
  @Watch('missingReqPrices')
  private onMissReqPricesChange(missReqPrices: IExportPricesEntity[]) {
    if (missReqPrices.length && !this.isRequiredPrice) {
      this.addPrice(0);
    }
  }

  private async addPrice(count: number) {

    // Check if the price was already selected
    const orderRecap = NBookingModule.orderRecap;
    let priceFound = false;
    for (const ticket of orderRecap) {
      for (const price of ticket.prices) {
        if (Number(price.priceId) === this.price.priceId) {
          priceFound = true;
          break;
        }
      }
    }

    // Don't set count to zero if price wasn't already selected
    if (!Number(count) && !priceFound) {
      return;
    }

    this.isDirty = true;
    // Cancel the op if we can't select && price wasn't
    // already selected.
    if (!this.canSelect && !priceFound) {
      setTimeout(() => { (this.$refs[this.selectRef] as any).setSelected(0); }, 520);
      return;
    }

    this.isLoading = true;
    const priceCount = {
      price: this.price,
      slot: this.timeSlot,
      count: Number(count),
      ticket: this.ticket,
    };

    try {
      await NBookingModule.addPriceQ({ priceCount, cb: this.onPriceAdded });
    } finally {
      this.isLoading = false;
    }
  }

  private onPriceAdded(error: any) {
    this.isLoading = false;

    if (error) {
      const response = error.response;
      if (response && response.status === 412) {
        NNoty.createNewNoty({
          period: 4000,
          message: String(this.$t('error.ticket-count')),
          type: 'error',
        });
        this.$emit(EVSlotPrice.CountNotPresent, this.timeSlot.id);
      } else {
        NNoty.createNewNoty({
          period: 4000,
          message: String(this.$t('error.error-response-banner')),
          type: 'error',
        });
      }
      throw error;
    }
  }

}

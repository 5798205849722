







































import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class NewButton extends Vue {
  // Color for text. If style of button !== primary or secondary
  @Prop({default: ''}) private textColor!: string;
  // Color for background. If style of button !== primary or secondary
  @Prop({default: ''}) private color!: string;
  @Prop({default: false }) private isDisabled!: boolean;
  @Prop({default: false }) private isLoading!: boolean;
  // Type of button. For now there are 3 types: "text"/"round"/"rectangle"
  @Prop({default: 'text'}) private type!: 'text' | 'round'| 'rectangle';
  // ColorType of button. For now there are 2 types: "primary"/"secondary"
  @Prop({default: ''}) private colorType!: 'primary' | 'secondary';
  // Html type of component. For now there are 2 types: "a"/"button"
  @Prop({default: 'a'}) private htmlType!: 'a' | 'button';
  // Parameter for 100% width of component. Pass isFullwidth to parameter w/o data to activate it
  // @ts-ignore
  @Prop(Boolean) private readonly isFullwidth: boolean;
  @Prop({default: false}) private hasBorder!: boolean;
  @Prop({default: ''}) private borderColor!: string;
  @Prop({default: '16px'}) private fontSize!: string;
  @Prop({default: 'none'}) private template!: 'none' | 'showMore';

  private currentColor = this.textColor;
  // Emit propogination to parent component
  private click() {
    // do nothing if disabled
    if (this.isDisabled) {
      this.$emit('disclick');
      return;
    }

    this.$emit('click');
  }

  private get fullWidth() {
    return this.isFullwidth ? '100%' : 'inherit';
  }
  // Get already defined classes for colorType
  private get getButtonClass() {
    if ( this.type === 'text' && this.colorType === 'primary') {
      return 'smtz-primary-text-btn';
    } else if ( this.type === 'text' && this.colorType === 'secondary' ) {
      return 'smtz-secondary-text-btn';
    } else if ( this.colorType === 'primary' ) {
      return 'smtz-primary-btn';
    } else if ( this.colorType === 'secondary' ) {
      return 'smtz-secondary-btn';
    }
  }
}


import { client } from '@/utils/graphqlClient/graphql-client';
import { GET_RetailOverview } from './gql/retail.graphql';
import {
  GetRetailOverviewQuery,
  GetRetailOverviewQueryVariables,
} from '@/types/gql/generated/graphql';
import { IProductCategory } from '@/types/db/product-category';

/**
 * Returns overview of a given retails
 *
 * @param ids retails ids
 */
export async function getRetailOverview(Ids: number[]) {

  const res = await client.query<GetRetailOverviewQuery, GetRetailOverviewQueryVariables>(
    GET_RetailOverview,
    { ids: `(id,in,${Ids.join(',')})` }).toPromise();

  return res.data?.ProductSlotCategoryList as IProductCategory[];

}

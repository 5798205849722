import { trRequest } from '@/utils/request';
import Debug from 'debug';

const debug = Debug('smeetz:booking');

/**
 * Method used for tracking users.
 * This set's the __smtz_id tracking cookie for users
 */
export async function trackC() {
  const response = await trRequest({
    method: 'post',
  });

  return response.data;
}





































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import Tickets from './Tickets.vue';
import TicketSelectionView from './TicketSelectionView.vue';
import LoginView from './LoginView.vue';
import Retails from './Retails.vue';
import Addons from './Addons.vue';
// import Basket from './Basket.vue';
import NewBasket from './NewBasket.vue';
import SliderNav, { INavElement } from '@/components/SliderNav.vue';
import { NBookingModule, OrganizerModule, ProductModule } from '@/utils/storemodules';
import { IServerRecapCategory, ITicket } from '@/models/store/booking';
import { BookingSteps } from '@/store/modules/newBooking/constants';
import { IProductCategory } from '@/types/db/product-category';
import { CategoryType } from '@/models/enums';
import { isCalendarTicket } from '@/utils/booking';
import { isRealMembershipDiscount } from '@/utils/membership-utils';
import { isEquilibre } from '@/utils/helpers';
import { inIframe } from '@/utils/iframe';

interface ITag {
  content: string;
  value: string | number;
}
@Component({
  components: {
    Tickets,
    Retails,
    Addons,
    // Basket,
    NewBasket,
    SliderNav,
    TicketSelectionView,
    LoginView,
  },
})
export default class Order extends Vue {
  @Prop({default: null}) private relatedShow!: ITicket;
  private openTicketSelectionView: boolean = false;
  private selectedTicket: ITicket | null = null;

  private get showLoginView() {
    return NBookingModule.showLoginView;
  }

  private get standartTags(): ITag[] {
    return [
    {
      content: this.$t('new-booking-flow.order.filters.all') as string,
      value: '',
    },
    ];
  }

  private get ticketTags(): ITag[] {
    this.getCustomTags(this.tickets);
    return this.standartTags.reduce((acc: ITag[], current: ITag) => {
      const x = acc.find((item: ITag) => item.value === current.value);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
  }

  private get containsCalendarTicket(): boolean {
    return this.tickets.some((t) => isCalendarTicket(t));
  }

  private get tickets(): ITicket[] {
    const orgId = OrganizerModule.id;
    const {membership} = NBookingModule;
    // const hideOtherShows = orgId && membership && isRealMembershipDiscount(membership) && isEquilibre(orgId);
    const hideOtherShows = false;
    const { recapCategories } = NBookingModule;

    const filteredTickets = NBookingModule.filteredTickets.filter((ticket) => {
      return ticket.type !== CategoryType.Retail;
    }).sort((t1, t2) => t1.sortOrder - t2.sortOrder);

    let finalFiltredTickets;

    if ( this.filterBy ) {
      finalFiltredTickets = filteredTickets.filter((ticket: ITicket) => {
        return typeof(this.filterBy) === 'string' ? ticket.tagCustom === this.filterBy : ticket.tag === this.filterBy;
      });
    } else {
      finalFiltredTickets = filteredTickets;
    }

    if (hideOtherShows && finalFiltredTickets.length > 0 && recapCategories.length > 0) {
      const productId = finalFiltredTickets[0].productId;
      // Equilibre is using addons on a membership discount ticket
      // thus we need to ignore the addons 106864, 106862
      const bypassedCats = [106864, 106862];
      const addedCategoriesFromSameProduct = recapCategories.reduce((acc: number[], cat: IServerRecapCategory) => {
        const ignoreCategories = cat.mainTicket !== null && bypassedCats.includes(cat.categoryId);
        if (cat.productId === productId && !(ignoreCategories)) {
          const categoryId = cat.seatingMainSubProductId || cat.categoryId;
          return acc.concat([categoryId]);
        } else {
          return acc;
        }}, []);

      if (addedCategoriesFromSameProduct.length > 0) {
        return finalFiltredTickets.filter((t) => addedCategoriesFromSameProduct.includes(t.categoryId));
      }
    }
    return finalFiltredTickets;
  }

  private get retails(): IProductCategory[] {
    return ProductModule.visibleProductCategories;
  }

  private get addons(): IProductCategory[] {
    return ProductModule.activityAddons;
  }

  private get ticketsAddons(): ITicket[] {
    return ProductModule.ticketsAddons;
  }

  private get hideAddons(): boolean {
    return ProductModule.hideAddons;
  }

  @Prop({default: 0}) private windowWidth!: number;
  private filterBy: string | number = '';

  // Using auto because we want the width not to be fixed
  private swiperOptions = {
    slidesPerView: 'auto',
    freeMode: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  };

  private isChosen(item: ITag): boolean {
    return item.value === this.filterBy;
  }

  private getCustomTags(tickets: ITicket[]) {
    tickets.forEach((ticket: ITicket) => {
      if ( ticket.tagCustom ) {
        this.standartTags.push({
          content: ticket.tagCustom,
          value: ticket.tagCustom,
        });
      } else if ( ticket.tag === 1 ) {
        this.standartTags.push({
          content: this.$t('new-booking-flow.order.filters.tickets') as string,
          value: 1,
        });
      } else if ( ticket.tag === 4) {
        this.standartTags.push({
          content: this.$t('new-booking-flow.order.filters.merchandising') as string,
          value: 4,
        });
      } else if ( ticket.tag === 2) {
        this.standartTags.push({
          content: this.$t('new-booking-flow.order.filters.bundle') as string,
          value: 2,
        });
      } else if ( ticket.tag === 3) {
        this.standartTags.push({
          content: this.$t('new-booking-flow.order.filters.addons') as string,
          value: 3,
        });
      }
    });
  }

  @Watch('relatedShow')
  private onSetRelatedShow() {
    if (this.relatedShow) {
      this.open(this.relatedShow);
      return;
    }
    this.close();
  }

  private mounted() {
    this.close();
    if (NBookingModule.bookingStep === BookingSteps.Order && NBookingModule.piazzaGrandeValidation) {
      this.open(ProductModule.piazzaShowsAddons[0]);
    }
    NBookingModule.setSubmittedBooking(false);
  }

  private filterList(ticketTag: ITag) {
    this.filterBy = ticketTag.value;
  }

  private showAddons() {
    ProductModule.setHideAddons(false);
  }

  private open(ticket: ITicket) {

    // opening ticket selection view, so let's set the ticket to display plus the adequate price.
    this.openTicketSelectionView = true;
    this.selectedTicket = ticket;

    if (inIframe()) {
      const topOfBookingFlow = document.getElementById('new-booking-nav');
      if (topOfBookingFlow) {
        topOfBookingFlow.scrollIntoView();
      }
    } else {
      window.scrollTo(0, 0);
    }
  }

  private close() {
    this.openTicketSelectionView = false;
    this.selectedTicket = null;
  }
}









import { Vue, Component, Prop } from 'vue-property-decorator';
import { ModalModule } from '@/utils/storemodules';
import { ModalStatus } from '@/models/definitions';

@Component({})
export default class SwissPassAuth extends Vue {
  @Prop({default: 'register'}) private authType!: string;
  @ModalModule.Mutation('setStatus') private setStatus: any;
  @ModalModule.Mutation('setAuthType') private setAuthType: any;

  private init() {
    this.setStatus(ModalStatus.SwissPass);
    this.setAuthType(this.authType);
    this.$router.push('/');
  }
}

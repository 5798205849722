







































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { ProductsModule, RegionModule, AppModule } from '@/utils/storemodules';
import { client } from '@/utils/searchConfig';
import dayjs from 'dayjs';
import { IActivity } from '@/models/store/region';

@Component
export default class ProductSearch extends Vue {
  @Prop({ default: true }) public showResults!: boolean;
  @Prop() public submitcb!: any;
  public inputQuery: string = '';

  private isLoading = false;

  get query() {
    return ProductsModule.query;
  }

  get isMobile() {
    return AppModule.isMobile;
  }

  private mounted() {
    this.inputQuery = this.query;
    const autocomplete = this.$refs['search-autocomplete'];

    // scroll to the input top on mobile when focused
    if (!autocomplete || !this.isMobile) {
      return;
    }

    setTimeout(() => {
      const input = (autocomplete as any).$refs.input as Element;
      input.addEventListener('focus', () => {
        const inputTop = input.getBoundingClientRect().y;

        if (this.isMobile && (inputTop > 15)) {
          window.scrollTo({top: inputTop - 15, behavior: 'smooth'});
        }
      });
    }, 1000);
  }

  private async search(input: string) {
    let error: any;

    try {
      this.isLoading = true;

      ProductsModule.setOptions({
        query: input,
      });

      await ProductsModule.search();
    } catch (err) {
      error = err;
    } finally {
      this.isLoading = false;
    }

    if (error) {
      // console.log('throiwng error');
      throw error;
    }
    return ProductsModule.products.length ? ProductsModule.products : [];
  }

  private onSubmit(result: any) {
    if (result) {
      this.$router.push(`/product/${result.short_name}`);
    }

    if (this.submitcb) {
      this.submitcb(result);
    }
  }

  private searchResultUrl(result: any ) {
    if (result) {
      return `/product/${result.short_name}`;
    }
  }

  private productTranslation(product: IActivity) {
    const locale = this.$i18n.locale;
    for (const [key, value] of Object.entries(JSON.parse(product.product_translations))) {
      if (key === locale) {
        // so this is the translations object we're looking for
        return value as any;
      }
    }
    return undefined;
  }

  private translatedName(product: IActivity) {
    if (!product.product_translations) {
      return product.product_name;
    }
    const valObj = this.productTranslation(product);
    if (valObj && valObj.hasOwnProperty('name') && valObj.name && valObj.name !== '') {
      return valObj.name;
    }
    return product.product_name;
  }

  private translatedTeaser(product: IActivity) {
    const locale = this.$i18n.locale;
    if (!product.product_translations) {
      return product.product_teaser;
    }
    const valObj = this.productTranslation(product);
    if (valObj && valObj.hasOwnProperty('teaser') && valObj.teaser && valObj.teaser !== '') {
      return valObj.teaser;
    }
    return product.product_teaser;
  }

  // Watcher invoked when changing 'query'
  // We will consier only the one coming from Restor Filters btn
  @Watch('$store.state.products.query')
  private onQueryChange(newQuery: string) {
    if (newQuery === '') {
        // lets clear the search input
        const autocomplete = this.$refs['search-autocomplete'];
        (autocomplete as any).value = '';
        // why the below did not clear the autocomplete input?
        // this.inputQuery = newQuery;
      }
  }
}































import { Vue, Component, Watch } from 'vue-property-decorator';
import Navbar from '@/views/OrganizerUserSpace/components/RefundBooking/RefundNavBar.vue';
import Footer from '@/components/booking/Footer.vue';
import BookingOverview from '@/views/OrganizerUserSpace/components/RefundBooking/RefundSummary/BookingOverview.vue';
import RefundSummary from '@/views/OrganizerUserSpace/components/RefundBooking/RefundSummary/RefundSummary.vue';
import RefundTransaction from '@/views/OrganizerUserSpace/components/RefundBooking/RefundSummary/RefundTransaction.vue';
import SelectTickets from '@/views/OrganizerUserSpace/components/RefundBooking/MyBooking/SelectTickets.vue';
import TicketsAdded from '@/views/OrganizerUserSpace/components/RefundBooking/MyBooking/TicketsAdded.vue';
import NewBookingFooter from '@/components/booking/NewBookingFooter.vue';
import {NBookingModule, AppModule, RBookingModule,  OrganizerModule} from '@/utils/storemodules';
import { inIframe } from '@/utils/iframe';
import { RefundSteps } from '@/store/modules/refundBooking/constants';
import { IAttendee, IGQLProductSlotBookingRead, InformationType } from '@/models/store/booking';
import { getBookingFields } from '@/api/booking';
import booking from '@/store/modules/booking';


@Component({
  components: {
    Navbar,
    Footer,
    BookingOverview,
    RefundSummary,
    RefundTransaction,
    SelectTickets,
    TicketsAdded,
    NewBookingFooter,
  },
})
  export default class RefundBooking extends Vue {
    get isOrganizerUserSpace() {
      return OrganizerModule.isOrganizerUserSpace;
    }

    get currentStep(): RefundSteps {
      return RBookingModule.currentStep;
    }

    get inIframe(): boolean {
      return inIframe();
    }

    get isMobile() {
      return AppModule.isMobile;
    }

    private booking: IGQLProductSlotBookingRead | null = null;
    private RefundSteps = RefundSteps;

    private async created() {
      const bookingSummary = NBookingModule.bookingSummary;
      const bookingId = Number(this.$route.params.bookingId);
      if (!bookingSummary) {
        const orgId = this.$route.params.orgId;
        const baseUrl = this.isOrganizerUserSpace ?
        `/${this.$i18n.locale}/org-user/${orgId}` : `/${this.$i18n.locale}/user-space`;
        return this.$router.push(`${baseUrl}/userbookings/${bookingId}`);
      }

      this.booking = bookingSummary.data.ProductSlotBookingRead;
      RBookingModule.setCurrentStep(RefundSteps.MyBooking);
      // GET only the cancellable tickets
      const slots = this.booking.ProductSlotStateList.filter((item) =>
        item.ProductSlotCategoryRead.IsUserCancellable === 1);
      RBookingModule.setSlots(slots);
      const bookingToken = NBookingModule.bookingToken;

    // GET the attendees
      const attendees: IAttendee[] = [];

      const pssIds = slots.map((item) => item.Id);
      const customFields = await getBookingFields(bookingId, bookingToken!);
      const ticketFields = customFields.ticket_fields!;

      const firstNames = ticketFields.filter((item) => item.informationType === InformationType.FirstName)
    .map((item) => ({answer: item.answer, pssId: item.pssId}));
      const lastNames = ticketFields.filter((item) => item.informationType === InformationType.LastName)
    .map((item) => ({answer: item.answer, pssId: item.pssId}));
      const emails = ticketFields.filter((item) => item.informationType === InformationType.Email)
    .map((item) => ({answer: item.answer, pssId: item.pssId}));
      const phones = ticketFields.filter((item) => item.informationType === InformationType.Phone)
    .map((item) => ({answer: item.answer, pssId: item.pssId}));

      for (const pssId of pssIds) {
        let fullName;
        const firstName = firstNames.find((item) => item.pssId === pssId);
        const lastName = lastNames.find((item) => item.pssId === pssId);
        if (firstName && lastName && firstName.answer !== null && lastName.answer !== null) {
          fullName = `${firstName.answer} ${lastName.answer}`;
        }
        const email = emails.find((item) => item.pssId === pssId)?.answer as any;
        const phone = phones.find((item) => item.pssId === pssId)?.answer as any;
        if (fullName || email || phone) {
          attendees.push({pssId, fullName, email, phone});
        }
      }
      RBookingModule.setAttendees(attendees);

      // Update this after the demo
      // Here we just want to hide the top nav-bar.
      const orgUserSpace: HTMLElement = document.getElementsByClassName('org-user-space')[0] as any;
      const orgUserSpaceNav: HTMLElement = document.getElementsByClassName('user-space-nav-wrapper')[0] as any;
      (orgUserSpace.style as any)['padding-top'] = '0px';
      (orgUserSpaceNav.style as any).display = 'none';
    }

    private destroyed() {
      // Update this after the demo
      // Showing the top navbar again
      const orgUserSpace: HTMLElement = document.getElementsByClassName('org-user-space')[0] as any;
      const orgUserSpaceNav: HTMLElement = document.getElementsByClassName('user-space-nav-wrapper')[0] as any;
      (orgUserSpace.style as any)['padding-top'] = '5px';
      (orgUserSpaceNav.style as any).display = null;
    }

  }

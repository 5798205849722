
export function getElementById(id: string): HTMLElement | null {
  return document.getElementById(id);
}

export function getElementsByClassName(className: string): Element[] | null {
  const elements = document.querySelectorAll('.' + className);
  return Array.from(elements);
}

export function scrollToElement(element: HTMLElement, extra: number = 0) {
  window.scrollTo({
    top: element.offsetTop + extra,
    behavior: 'smooth',
  });
}

export function scrollIntoView(element: HTMLElement) {
  element.scrollIntoView({ behavior: 'smooth' });
}

import { ProductModule, NBookingModule } from './storemodules';

/**
 * Checks whether the addons on the separate page are selected
 */
export function areRequiredSecondPageAddonsSelected() {
  // Currently checking only Piazza shows
  if (ProductModule.requiredNumberPiazzaSeats > 0) {
    const piazzaSeatCategory = ProductModule.piazzaShowsAddons[0];
    if (piazzaSeatCategory) {
      const seatCategory = piazzaSeatCategory.categoryId;
      const seatsQuantity = NBookingModule
        .recapCategories.filter((cat) => cat.seatingMainSubProductId === seatCategory)
        .map((seatCat) => Number(seatCat.quantity))
        .reduce((total, seatQuantity) => {
          return total + seatQuantity;
        }, 0);
      return (seatsQuantity || 0) >= ProductModule.requiredNumberPiazzaSeats;
    }
  }
  // Not implemented now for non Piazza tickets
  return false;
}


export function openTheAddonShowRelatedToCurrentFetchedCategory() {
  // If Piazza grande seating plan we show it
  if (ProductModule.piazzaShowsAddons.length > 0) {
    // Make sure to open the right addon
    // For now we have one show displayed at once and one seating plan linked to its prices
    const displayedCategory = NBookingModule.filteredTickets[0];

    const pricesAdded = NBookingModule.recapCategories.filter((cat) => cat.categoryId === displayedCategory.categoryId);
    for (const priceAdded of pricesAdded) {
      const packages = NBookingModule.pricePackageByPriceId(priceAdded.priceId, priceAdded.timeSlotId);
      if (packages) {
        // As we have only one seating plan linked to a category at at time
        const mainSeatingPlanId = Object.values(packages.package_categories)[0].seating_main_sub_product_id;
        const piazzaSeatingPlan = ProductModule.piazzaShowsAddons.find((show) =>
          show.categoryId === mainSeatingPlanId);
        return piazzaSeatingPlan;
      }
    }
  }
}




































import { Vue, Component } from 'vue-property-decorator';
import ModalButton from '@/components/modal/ModalButton.vue';
import { AuthModule, ModalModule, NModalModule } from '@/utils/storemodules';
import { ModalStatus } from '@/models/definitions';

@Component({
  components: {
    ModalButton,
  },
})
export default class DeleteAccountConf extends Vue {
  private responseStatus: string = 'success';

  @AuthModule.Action('cancelAccount') private cancelAccount: any;
  @AuthModule.Action('logout') private logout: any;
  // @ModalModule.Mutation('setStatus') private setStatus: any;

  private close(): void {
    this.$emit('close');
  }

  private async submit() {
    const button: any = this.$refs.button;
    button.executing(true);

    try {
      await this.cancelAccount();
      await this.logout();
      this.$router.push('/');
      this.close();
    } catch (err) {
      button.executing(false);
      this.responseStatus = 'fail';
    }

    // this.setStatus(ModalStatus.Hidden);
    button.executing(false);
  }
}






































import { IProductCategory } from '@/types/db/product-category';
import { Vue, Component, Prop } from 'vue-property-decorator';
import AddonMiniCard from './AddonMiniCard.vue';
import AddonFullCard from './AddonFullCard.vue';
import { AppModule, OrganizerModule } from '@/utils/storemodules';
import Tickets from './Tickets.vue';
import { ITicket } from '@/models/store/booking';
import Retails from './Retails.vue';
import {isRealMobile} from '@/utils/helpers';
import { inIframe } from '@/utils/iframe';


@Component({
  components: {
    AddonMiniCard,
    AddonFullCard,
    Tickets,
    Retails,
  },
})
export default class NAddons extends Vue {
  @Prop({default: 0}) public windowWidth!: number;
  @Prop({default: []}) private addons!: IProductCategory[];
  @Prop({default: []}) private ticketsAddons!: ITicket[];
  private selectedAddon: IProductCategory | null = null;

  private get isMobile() {
    return AppModule.isMobile;
  }

  // If the reutrn is true we show the mobile scroll design
  private get isRetailsOnly() {
    const FlipLabIds = [19319, 19543, 19544, 19545, 19546];
    const groupId = OrganizerModule.id || OrganizerModule.orgId;
    if (FlipLabIds.includes(Number(groupId))) {
      return false;
    }

    if ((this.ticketsAddons && this.ticketsAddons.length > 0) || this.isSidePanelWidgetOnDesktop) {
      return false;
    }

    if (this.addons.length < 3) {
      return false;
    }

    return true;
  }

  // Get if the widget is side panel opened in a desktop
  private get isSidePanelWidgetOnDesktop() {
    let check = false;
    // Check if the userAgent is a mobile
    if (isRealMobile()) {
      check = true;
    }
    // if the userAgent is not mobile but the screen size of disover is mobile it means that the widget
    // is Side panel on Desktop
    if (!check && this.isMobile ) {
      return true;
    }
    return false;
  }

  private setAddon(v: IProductCategory) {
    this.selectedAddon = v;
  }

  private mounted() {
    if (this.isRetailsOnly && this.isMobile && this.addons?.length > 2) {
      // we auto-scroll the addons cards on mobile
      const element = document.getElementById('naddons-minicard');
      element?.scrollBy({
        top: 0,
        left: 65,
        behavior: 'smooth',
      });
    }
    // make sure that we scroll to top of view when in 2nd step
    if (inIframe()) {
      const topOfBookingFlow = document.getElementById('new-booking-nav');
      if (topOfBookingFlow) {
        topOfBookingFlow.scrollIntoView();
      }
    }
  }

}

import Vue from 'vue';
import VueI18n from 'vue-i18n';

import Fr from './fr.json';
import En from './en.json';
import De from './de.json';
import It from './it.json';
import Nl from './nl.json';
import Es from './es.json';
import Hu from './hu.json';


Vue.use(VueI18n);

const languages: VueI18n.LocaleMessages = {
  en: En,
  fr: Fr,
  de: De,
  it: It,
  nl: Nl,
  es: Es,
  hu: Hu,
};

const i18n = new VueI18n({
  locale: 'en',
  messages: languages,
});

export default i18n;

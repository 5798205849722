import { gql } from 'graphql-tag';

/**
 * Fetches RetailOverview info using id
 *
 * variable example
 * {"id": "44357"}
 */
// tslint:disable-next-line
export const GET_RetailOverview = gql`
  query GetRetailOverview($ids: String!) {
    ProductSlotCategoryList(where: $ids) {
    id: Id
    name: CategoryName
    description: CategoryDescription
    nameLocale : CategoryNameLocale
    descriptionLocale :  CategoryDescriptionLocale
    product: ProductRead {
      currency: Currency
    }
    shippingDetails: RetailShippingDetailsList {
      isDelivrable: IsDeliverable
      weight: Weight
    }
    prices: ProductSlotPriceList {
      id: Id
      name: PriceName
      cancelled: Cancelled
      value: Value
      quantity: QuantitAvailable
      retail: RetailOptionList {
        id: Id
        name: OptionName
        type: OptionType
        isSellingWhenSoldOut: SellingSoldOut
        smeetzPictures: SmeetzPictureList {
          id: Id
          metaData: MetaData
          name: Name
        }
      }
    }
  }
  }
`;

import i18n from '@/locales';
import { NBookingModule, OrganizerModule, ReferrerModule, UserModule } from '@/utils/storemodules';
import Logger from 'logger-remote/build/main/lib/logger';
import { logRequest } from '@/utils/request';
import { genUID } from '@/utils/helpers';
import { StepName } from '@/store/modules/newBooking/constants';
import { buildOrderB2bUrl } from '@/utils/helpers';




export const LoggerService = new Logger({
  // Function that returns meta info for each log
  meta: () => {
    return {
      // These are default for each app
      env: process.env.VUE_APP_BUILD_ENV,
      timestamp: Date.now(),
      app: 'b2c',
      logid: genUID(),

      groupId: OrganizerModule.id || OrganizerModule.orgId ||  '',
      groupName: OrganizerModule.orgName || '',
      referrerModule: ReferrerModule.referrer,
      lang: i18n.locale,
      bookingId: String(NBookingModule.bookingId || ''),
      bookingRef: String(NBookingModule.bookingRes?.bookingRef || ''),
      bookingRecap : NBookingModule.bookingRes?.bookingRecap || '',
      bookingStep: String(StepName[NBookingModule.bookingStep] || ''),
      paymentProcessor: OrganizerModule.getInfo != null ? OrganizerModule.getInfo.groupInfo.paymentProcessor : '',
      hostUrl: ReferrerModule.hostUrl || '',
      url: window.location.href,
      path: window.location.pathname,
      b2bOrderUrl: buildOrderB2bUrl(),
      width: window.screen.width || '',
      height: window.screen.height || '',
      userAgent: window.navigator.userAgent || '',
    };
  },
  // For apps that don't want to lose logs, we can use this function to save the logs.
  // Shouldn't be used when localstorage is not available
  persistHandler: async (logs: any[]) => {
    // Not needed on B2C
    return;
  },
  // How to post logs to remote server.
  postHandler: async (logs: any[]) => {
    if (!logs || !logs.length) { return; }
    await postLogs(logs);
    return;
  },
  syncInterval: 2000,
});

/**
 * POST logs to remote server
 * @param data
 * @returns
 */
export async function postLogs(logs: any[]): Promise<unknown> {
  const data = { logs };
  try {
    const response = await logRequest({
      method: 'POST',
      data,
    });
    return response.data;
  } catch (error) {
    // throw error;
  }
}

export async function postLogCall(level: string, message: string): Promise<unknown> {
  const log = {
    env: process.env.VUE_APP_BUILD_ENV,
    timestamp: Date.now(),
    app: 'b2c',
    logid: genUID(),
    groupId: OrganizerModule.id || '',
    groupName: OrganizerModule.orgName || '',
    referrerModule: ReferrerModule.referrer,
    lang: i18n.locale,
    bookingId: String(NBookingModule.bookingId || ''),
    bookingStep: String(StepName[NBookingModule.bookingStep] || ''),
    paymentProcessor: OrganizerModule.getInfo != null ? OrganizerModule.getInfo.groupInfo.paymentProcessor : '',
    level,
    message,
  };

  const logs = [log];
  const data = { logs };
  try {
    const response = await logRequest({
      method: 'POST',
      data,
    });
    return response.data;
  } catch (error) {
    // throw error;
  }
}






















































import {Vue, Component, Prop} from 'vue-property-decorator';

@Component
  export default class ContainerSmeetzItem extends Vue {
    @Prop()
    public item!: any;

  private srcSet1440: string = '';
  private srcSet1080: string = '';
  private srcSet750: string = '';
  private srcSet640: string = '';

  private mounted() {
    if (this.item.img) {
      this.srcSet1440 = this.item.img.srcset_1440;
      this.srcSet1080 = this.item.img.srcset_1080;
      this.srcSet750 = this.item.img.srcset_750;
      this.srcSet640 = this.item.img.srcset_640;
    }
  }
}






















































































































































/**
 * @Prop price {IExportPricesEntity}
 * @Prop timeSlot {ITimeslotsEntity}
 * @Prop ticket {ITicket}
 */
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Mixins } from 'vue-mixin-decorator';
import Select from '@/components/presentational/NewSelect.vue';
// import SlotPriceMixin from '@/mixins/SlotPrice';
import SlotPriceMixin from '@/mixins/SlotPPrice';
import { NBookingModule, AppModule, ProductModule } from '@/utils/storemodules';
import Debug from 'debug';
import ToolTipIcon from '@/components/presentational/ToolTipIcon.vue';
import { MembershipType } from '@/models/memberships';
import { isRequiredTicketsMembership } from '@/utils/membership';
import PromoInput from './PromoInputButton.vue';
const debug = Debug('smeetz:booking');

@Component({
  components: {
    Select,
    ToolTipIcon,
    PromoInput,
  },
})
// export default class SlotPrice extends Vue {
export default class SlotPrice extends Mixins<SlotPriceMixin>(SlotPriceMixin) {
  // @Prop() private price!: IExportPricesEntity;
  // @Prop() private timeSlot!: ITimeslotsEntity;
  // @Prop() private ticket!: ITicket;
  // private EVSelect = EVSelect;
  // private isLoading = false;

  // get currency(): string {
  //   return 'CHF';
  // }

  private requiredMembership: {
    name: string;
    requiredTicketPerShow: number;
    quantity: number;
  } | null = null;

  // Does this price have a long description
  private isPriceDescriptionChangeable: boolean = false;
  // Is the long description fully displayed or is it cut
  private isPriceDescriptionFullyShown: boolean = false;

  private get hasNoAllBookingCodes(): boolean {
    return NBookingModule.hasNoAllBookingCodes;
  }

  // Cut the price.priceDescription if it includes more than X words
  get handlePriceDescription(): string {
    if (this.price.priceDescription !== null) {
      const priceDescription = this.price.priceDescription;
      // Check how much is pure text in string
      // const pureText = priceDescription.replace(/<[^>]*>?/gm, '');
      // Show the See More button if wordsLimit is reached
      let wordsLimit = 8;
      let textLimit = 40;
      if (!this.isDesktop) {
        wordsLimit = 10;
        textLimit = 70;
        if (this.isMobile) {
          wordsLimit = 5;
          textLimit = 35;
        }
      }
      // If price description words > wordsLimit and priceDescription length > 26
      // and text is cutted and this is a packagePrice
      const words = priceDescription.split(' ');
      if ( (words.length > wordsLimit || priceDescription.length > textLimit)
           && !this.isPriceDescriptionFullyShown) {
        this.isPriceDescriptionChangeable = true;
        return priceDescription.slice(0, textLimit - 10);
      } else {
        return priceDescription;
      }
    } else {
      return '';
    }
  }

  private get isMobile() {
    return AppModule.isMobile;
  }

  private get isDesktop() {
    return AppModule.isDesktop;
  }

  private get isAcompte() {
    return this.price.acomptePriceValue || 0;
  }

  private get isAccompanyingPrice() {
    return [23038, 205103, 205104].includes(this.price.priceId);
  }
  private get numberBookingCodePrices() {
    return NBookingModule.numberBookingCodePrices;
  }

  private get bookingCodesRequired() {
    const isPriceInBookingCodes =
      this.numberBookingCodePrices
        .find((bookedPrice) => bookedPrice.price.priceId === this.price.priceId);
    if (this.numberBookingCodePrices.length > 0 && isPriceInBookingCodes) {
      return Number(isPriceInBookingCodes.count);
    }
    return 0;
  }

  private get displayPrice() {

    // For required tickets membership, there is only one price that we show.
    // So, let's make that price the sum of all shows in the membership
    // This approach is DANGEROUS as we are assuming that there is only 1 price in the whole booking flow.
    const membership = NBookingModule.membership;
    if (isRequiredTicketsMembership(membership) && NBookingModule.membershipCustomers.length > 0) {
      const displayPrices = ProductModule.displayPrices;
      // As we display quantity we show only the price of one membership
      // return ProductModule.addonsTicketsTotalPrice / NBookingModule.membershipCustomers.length;
      const displayPrice = displayPrices[this.price.priceId];
      if (displayPrice) {
        return displayPrice;
      }
    }

    return this.price.priceValue;
  }

  private togglePriceDescriptionFullShow() {
    this.isPriceDescriptionFullyShown = !this.isPriceDescriptionFullyShown;
  }


  get hasError() {
    return NBookingModule.priceErrors[this.price.priceId];
    // return true;
  }

  get noMoreSeats() {
    const range = this.priceRange;

    if (range.length === 1 && range[0] === 0) {
      return true;
    }

    return false;
  }

  get isRegularMembership() {
    return NBookingModule.membership && NBookingModule.membership.type === MembershipType.RegularMembership;
  }

  private mounted() {
    if (NBookingModule.membership && NBookingModule.membership.type === MembershipType.RequiredTicketsMembership ) {
      this.requiredMembership = {
        name: NBookingModule.membership.name,
        requiredTicketPerShow: NBookingModule.membership.num_ticket,
        quantity: NBookingModule.membershipCustomers.length,
      };
    }
  }

  // get priceCount(): number {
  //   return this.price.count || 0;
  // }

  // get priceRange(): number[] {
  //   const range = [0];
  //   if (!this.price) {
  //     return range;
  //   }

  //   const min = this.price.minBooking;
  //   const max = this.price.maxBooking;
  //   let count = min;

  //   // don't duplicate the zero
  //   if (count === 0) {
  //     count++;
  //   }

  //   while (count <= max) {
  //     range.push(count);
  //     count++;
  //   }

  //   return range;
  // }

  // private async addPrice(count: number) {
  //   this.isLoading = true;
  //   try {
  //     await NBookingModule.addPrice({
  //       price: this.price,
  //       slot: this.timeSlot,
  //       count: Number(count),
  //       ticket: this.ticket,
  //     });
  //   } finally {
  //     this.isLoading = false;
  //   }
  // }

  @Watch('hasNoAllBookingCodes')
  private onBookingCodesInserted() {
    const filtredValidatedBookingCodes = this.validatedBookingCodes.filter((item) => item);
    if (!this.hasNoAllBookingCodes &&
      filtredValidatedBookingCodes.length === this.bookingCodesRequired &&
      this.bookingCodesRequired &&
      this.numberBookingCodePrices.length > 0 &&
      this.numberBookingCodePrices[0].price.priceId === this.price.priceId) {
        this.addMembershipPrice(this.numberBookingCodePrices[0].count);
      }
  }

  @Watch('numberBookingCodePrices')
  private onMembershipPriceUpdated() {
    if (!this.hasNoAllBookingCodes &&
      NBookingModule.numberBookingCodePrices.length > 0 &&
      NBookingModule.numberBookingCodePrices[0].price.priceId === this.price.priceId) {
        this.addMembershipPrice(NBookingModule.numberBookingCodePrices[0].count);
      }
  }

}

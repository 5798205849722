




























import { OrganizerModule } from '@/utils/storemodules';
import { Vue, Prop, Component } from 'vue-property-decorator';
@Component({})
export default class UserSpaceCard extends Vue {
  @Prop({ default: 'hello' }) public title!: string;

  private get isOrganizerUserSpace() {
    return OrganizerModule.isOrganizerUserSpace;
  }
}















import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
export interface INavElement {
  content: any;
  anchor: string;
}

@Component({})
export default class SliderNav extends Vue {
  @Prop({}) public navElements!: any[];

  // Using auto because we want the width not to be fixed
  private swiperOptions = {
    slidesPerView: 'auto',
    freeMode: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  };

}


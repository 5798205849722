import { ITrackingData } from './booking';
import { IProduct } from './product';

export class Product {
  private product: IProduct;

  constructor(product: IProduct) {
    this.product = product;
  }


  public get id(): number {
    return this.product.id;
  }

  public get name(): string {
    return this.product.nameWithoutTranslation;
  }

  public get brand(): string {
    return (this.product.organiser && this.product.organiser.name) || '';
  }

  public get overview(): any {
    return this.product.overview;
  }


  public get currency(): string {

    return this.product.currency || 'CHF';
  }

  /**
   * toTrackingData
   */
  public toTrackingData(position: number, list: string): ITrackingData {
    const product = this.product;
    const id = String(product.id);
    const name = product.nameWithoutTranslation;
    const brand = this.brand;
    const currency = this.currency;
    const fbPixelId = product.fbPixelId ? product.fbPixelId : '';
    const googleTrackingId = product.googleTrackingId || '';
    const gtmId = product.gtmId || '';

    return {
      id, name, brand, currency, position, list,
      fbPixelId, googleTrackingId, gtmId,
    };
  }
}













































































import { Vue, Component, Prop } from 'vue-property-decorator';
import CardTag from '@/components/presentational/TicketTag.vue';
import { AppModule } from '@/utils/storemodules';
import moment from 'moment';
import { inIframe, openWidget } from '@/utils/iframe';
import { getPrimaryColor, setBookingColors, resetSiteColors } from '@/utils/colors';
import { SITE_URL } from '@/config/constants';
import dayjs from 'dayjs';


@Component({
  components: {
    CardTag,
  },
})
export default class Card extends Vue {
  @Prop({default: null}) private cardData: any;
  @Prop({default: false}) private isGorizontal!: boolean;
  // @Prop({default: null}) private siteColor!: string;
  private openTeaser: boolean = false;

  private get isMobile() {
    return AppModule.isMobile;
  }

  private get siteColor() {
    if (inIframe()) {
      return 'var(--primary-color)';
    }

    return '';
  }

  private get cardColor() {
    return this.siteColor || this.cardData.branding_smeetz_primary_color;
  }

  get productTranslations(): null | {teaser?: string, name?: string, description?: string} {
    const trans = this.cardData.product_translations;
    if (!trans) {
      return null;
    }
    const transObj = JSON.parse(trans);
    return transObj[this.$i18n.locale];
  }

  get productName() {
    const trans = this.productTranslations;
    const name = this.cardData.product_name;
    if (!trans || !trans.name) {
      return name;
    }

    return trans.name || name;
  }

  get productImage() {
    const cardData = this.cardData;
    if (!cardData || !(cardData.cover_picture)) {
      return '';
    }

    return this.cardData.cover_picture;
  }

  get cardUrl() {
    const short_name = this.cardData.short_name;

    if ( short_name ) {
      return `/product/${short_name}`;
    }
  }

  private translateDate(date: string) {
    const categoryId = this.cardData.categoryId;
    const sendedDate = dayjs(date).format('dddd, D MMM YYYY');
    const dateStringArray = sendedDate.split(' ');
    const dayOfWeek = this.$t(`date.${dateStringArray[0].toLowerCase().slice(0, -1)}_short`);
    const month = this.$t(`date.${dateStringArray[2].toLowerCase()}`);
    dateStringArray[0] = dayOfWeek as string + ',';
    dateStringArray[2] = month as string;
    let dateString = dateStringArray.join(' ');
    // Ensure that for ticket cards, we show time as well cause we can have multiple tickets per product
    if (categoryId) {
      dateString += dayjs(date).format(' HH:mm');
    }
    return dateString;
  }

  private showTeaser() {
    this.openTeaser = !this.openTeaser;
  }

  private get cardTeaser() {
    if ( this.$i18n.locale !== 'en') {
      const translationsObject = JSON.parse(this.cardData.product_translations);
      if ( translationsObject && translationsObject[this.$i18n.locale]) {
        return translationsObject[this.$i18n.locale].teaser;
      } else {
        return false;
      }
    }
    return this.cardData.product_teaser;
  }

  private getPrice(price: number) {
    return price % 1 === 0 ? price : price.toFixed(2);
  }

  private goToBooking() {
    const id = this.cardData.id;
    const short_name = this.cardData.short_name;
    if ( id && short_name) {

      if (inIframe()) {
        this.openWidgetBooking();
        return;
      }

      // Make sure we change the color before going to booking flow
      this.changeColorIfNecessary();
      const categoryId = this.cardData.categoryId;
      let bookingLink = `/product/${id}/booking/${short_name}`;
      // On Card tickets we need to fetch only the ticket in consideration
      if (categoryId) {
        bookingLink += `?cats=${categoryId}`;
      }
      this.$router.push(bookingLink);
    }
  }

  private goToOrganizer(cardData: any) {
    // this.$router.push(`/new/organizer/${cardData.organiser_slug_name}`);
    window.open(`${SITE_URL}/org/${cardData.organiser_slug_name}`, '_blank');
  }

  private goSeeMore() {
    if (inIframe()) {
      this.openWidgetBooking();
      return;
    }

    const short_name = this.cardData.short_name;
    if ( short_name ) {
      // Change color varable of :root so the product page is loaded in the organizer color
      this.changeColorIfNecessary();

      this.$router.push(`/product/${short_name}`);
    }
  }

  // Changes the site color if card has different color from smeetz
  // default color
  private changeColorIfNecessary() {
    if (this.cardColor) {
      // so there is a special color for this product, lets consider it
      setBookingColors({ smeetzPrimaryColor: this.cardColor });
      AppModule.setColor(this.cardColor);
    }
  }

  private openWidgetBooking() {
    const id = this.cardData.id;
    const short_name = this.cardData.short_name;
    const categoryId = this.cardData.categoryId;
    if ( id && short_name) {
      const data: any = {
        productId: `${id}`,
        lightbox: true,
        listView: false,
        ocol: getPrimaryColor(),
      };
      // Ensure that we set category to show if we have it on the card
      if (categoryId) {
        data.cats = String(categoryId);
      }
      // @ts-ignore
      openWidget(['openwidget', 1 * new Date(), data]);
      return;
    }
  }
}

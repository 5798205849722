import { IDatesEntity, ITimeslotsEntity } from '@/models/store/booking';
import dayjs from 'dayjs';
import { NBookingModule } from './storemodules';

export function timeslotsToDates(slots: ITimeslotsEntity[]): IDatesEntity[] {
  const dates: IDatesEntity[] = [];

  for (const slot of slots) {
    const slotDay = dayjs(slot.startDateTime).format('YYYY-MM-DD');
    const publicCount = Number(slot.publicCount);
    const date = dates.find((d) => {
      return d.startDate === slotDay;
    });

    // create a new date if not present
    if (!date) {

      // Since calendar tickets, will refetch their first month,
      // we will not store gradient parameters at first
      dates.push({
        startDate: slotDay,
        publicCount: publicCount ? '1' : '0',
      });
      continue;
    }

    // break if day already has availability
    if (date && date.publicCount === '1') {
      continue;
    }

    // No availabity was found in this day, set it to current slot public count
    date.publicCount = publicCount ? '1' : '0';
  }

  return dates;
}

export function findRecapCategoriesByCategoryId(categoryId: number) {
  const recapCategories = NBookingModule.recapCategories;
  if (!recapCategories || !recapCategories.length) {
    return [];
  }

  return recapCategories.filter((cat) => cat.categoryId === categoryId);
}

import { ITimeslotsEntity } from '@/models/store/booking';
import dayjs from 'dayjs';

/**
 * Returns the future timeslot that is closest to the reference date
 * @param referenceDate : a reference date to compare timeslots to
 * @param slots : array of slots
 * @returns closest slot
 */
export function nearestFutureSlot(referenceDate: string | number, slots: ITimeslotsEntity[]) {
  const refDate = dayjs(referenceDate);
  const refISODate = refDate.toISOString();
  const afterOrEqualRefDate = slots.filter((slot) => {
    const slotDate = dayjs(slot.startDateTime).toISOString();
    return refISODate <= slotDate;
  });

  let closestDate = afterOrEqualRefDate[0];
  afterOrEqualRefDate.forEach((slot) => {

    // capture the closes date
    if (dayjs(closestDate.startDateTime).isAfter(dayjs(slot.startDateTime))) {
      closestDate = slot;
    }
  });

  return closestDate;

}








import { Vue, Component, Watch } from 'vue-property-decorator';
import { NBookingModule, NModalModule } from '@/utils/storemodules';
import { ModalStatus } from '@/models/definitions';

@Component
export default class BookingTimerEnd extends Vue {

  private get agreementText() {
    const organizerInfo = NBookingModule.getOrganizerInfo;
    return organizerInfo && organizerInfo.branding && organizerInfo.branding.bookingPolicy;
  }

  private hideModal() {
    NModalModule.setStatus(ModalStatus.Hidden);
  }

}


import i18n from '@/locales';

export const constants = Object.freeze({
  social_link: {
    smeetz: 'https://www.smeetz.com/',
    instagramLausanne: 'https://www.instagram.com/smeetz_lausanne/',
    instagramGeneva: 'https://www.instagram.com/smeetz_geneva/',
    instagramZurich: 'https://www.instagram.com/smeetz_zurich/',
    facebookLausanne: 'https://www.facebook.com/smeetz.lausanne/',
    facebookGeneva: 'https://www.facebook.com/smeetz.geneva/',
    facebookZurich: 'https://www.facebook.com/smeetz.zurich/',
    linkedin: 'https://www.linkedin.com/company/smeetz-sa/',
    medium: 'https://medium.com/@smeetz',
  },
  telephone: '+41-21-311-14-57',
  address: 'Rue Beau-Séjour 9, 1003 Lausanne, Switzerland',
  forms_email: 'support@smeetz.com',
  email_first: 'sales@smeetz.com',
  knowledge: 'https://knowledge.smeetz.com/',
  business: {
    en: 'https://business.smeetz.com',
    fr: 'https://business.smeetz.com/fr/home',
    de: 'https://business.smeetz.com/de/home',
    it: 'https://business.smeetz.com/fr/home',
  },
  contacts: {
    fr: 'https://business.smeetz.com/fr/contacts',
    en: 'https://business.smeetz.com/en/contacts',
    de: 'https://business.smeetz.com/de/contacts',
    it: 'https://business.smeetz.com/fr/contacts',
  },
  support: {
    en: 'https://knowledge.smeetz.com/en/for-users',
    fr: 'https://knowledge.smeetz.com/fr/pour-les-utilisateurs',
    de: 'https://knowledge.smeetz.com/de/für-benutzer',
    it: 'https://knowledge.smeetz.com/fr/pour-les-utilisateurs',
  },
  company: {
    fr: 'https://business.smeetz.com/fr/company',
    en: 'https://business.smeetz.com/en/company',
    de: 'https://business.smeetz.com/de/company',
    it: 'https://business.smeetz.com/fr/company',
  },
  blog: {
    fr: 'https://business.smeetz.com/fr/blog',
    en: 'https://business.smeetz.com/en/blog',
    de: 'https://business.smeetz.com/de/blog',
    it: 'https://business.smeetz.com/fr/blog',
  },
  legal_main: {
    fr: 'https://knowledge.smeetz.com/fr/legal',
    en: 'https://knowledge.smeetz.com/en/legal',
    de: 'https://knowledge.smeetz.com/de/legal',
    it: 'https://knowledge.smeetz.com/fr/legal',
  },
  legal: {
    terms: {
      fr: 'https://knowledge.smeetz.com/fr/terms-conditions',
      en: 'https://knowledge.smeetz.com/en/terms-conditions',
      de: 'https://knowledge.smeetz.com/de/terms-conditions',
      it: 'https://knowledge.smeetz.com/fr/terms-conditions',
    },
    policy: {
      fr: 'https://knowledge.smeetz.com/fr/privacy-policy',
      en: 'https://knowledge.smeetz.com/en/privacy-policy',
      de: 'https://knowledge.smeetz.com/de/privacy-policy',
      it: 'https://knowledge.smeetz.com/fr/privacy-policy',
    },
    termsConditions: {
      en: 'TermsConditions_EN.rtf',
      fr: 'TermsConditions_FR.rtf',
      de: 'TermsConditions_DE.rtf',
      it: 'TermsConditions_FR.rtf',
    },
    privacyPolicy: {
      en: 'PrivacyPolicy_EN.rtf',
      fr: 'PrivacyPolicy_FR.rtf',
      de: 'PrivacyPolicy_DE.rtf',
      it: 'PrivacyPolicy_FR.rtf',
    },
    cookiePolicy: {
      en: 'CookiesPolicy_EN.rtf',
      fr: 'CookiesPolicy_FR.rtf',
      de: 'CookiesPolicy_DE.rtf',
      it: 'CookiesPolicy_FR.rtf',
    },
    termsTickets: {
      en: 'TermsTickets_EN.rtf',
      fr: 'TermsTickets_FR.rtf',
      de: 'TermsTickets_DE.rtf',
      it: 'TermsTickets_FR.rtf',
    },
    referralProgram: {
      en: 'ReferralProgram_EN.rtf',
      fr: 'ReferralProgram_FR.rtf',
      de: 'ReferralProgram_DE.rtf',
      it: 'ReferralProgram_FR.rtf',
    },
  },
  faqGoer: {
    en: 'FaqGoers_EN.rtf',
    fr: 'FaqGoers_FR.rtf',
    de: 'FaqGoers_DE.rtf',
    it: 'FaqGoers_FR.rtf',
  },
  faqOrganiser: {
    settingActivity: {
      en: 'TermsConditions_EN.rtf',
      fr: 'TermsConditions_FR.rtf',
      de: 'TermsConditions_DE.rtf',
      it: 'TermsConditions_FR.rtf',
    },
    scanningTickets: {
      en: 'CookiesPolicy_EN.rtf',
      fr: 'CookiesPolicy_FR.rtf',
      de: 'CookiesPolicy_DE.rtf',
      it: 'CookiesPolicy_FR.rtf',
    },
    payment: {
      en: 'ReferralProgram_EN.rtf',
      fr: 'ReferralProgram_FR.rtf',
      de: 'ReferralProgram_DE.rtf',
      it: 'ReferralProgram_FR.rtf',
    },
  },
  defaultGeolocationArr: {
    latitude: '46.88612161',
    longitude: '7.93047047',
  },
  availableLangs: ['fr', 'en', 'it', 'de'],
});

export const PRIMARY_COLOR = '#e91e63';
export const SECONDARY_COLOR = '#25c9ec';
export const BACKGROUND = '#FAFBFB';
export const ORG_PRIMARY_COLOR = '#D63864';
export const ORG_BACKGROUND = '#FAFBFB';
export const ORG_BUTTON = ORG_PRIMARY_COLOR;
export const ORG_FONT_COLOR = '#3f3f3f';

// Tracking
export const TRACK_ID = '__smtz_id';

// Payment methods logos
export const EPaymentsLogos: {[paymentName: string]: string} = {
  TWINT: 'twint_logo.png',
  POSTCARD: 'postfinance_logo.png',
  PAYPAL: 'Paypal.svg',
  VISA: 'Visa.svg',
  MASTERCARD: 'Mastercard.svg',
  GOOGLEPAY: 'google-pay.png',
  IDEAL: 'ideal-logo.png',
  BANCONTACT: 'bancontact-logo.png',
  APPLEPAY: 'applepay.png',
};

// In new domain, discover website is separate from widget.
// so, we update the site url accordingly on widget builds
// This will only work when site url is similar to widget url with
// discover replaced with widget
const siteUrl = process.env.VUE_APP_SITE_URL || '';
export const SITE_URL = process.env.VUE_APP_IFRAME
  ? siteUrl.replace('discover', 'widget')
  : siteUrl;

export const DEFAULT_PAYMENT_METHOD = 'PENDING';

import Vue from 'vue';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { Loading } from 'quasar';
import {
  ICategory, ITicket, ISelectSlotPayload,
  ITicketCount, ITicketBasket, ITimeslotsEntity,
  IPriceCount,
  IPreBookingRes,
  IPreBookingReq,
  IPreBookProductQt,
  IServerRecapCategory,
  ISlotOrder,
  IRecapInfo,
  IGuestCheckoutUser,
  IServerBooking,
  IServerBookingData,
  IFrontRecapTicket,
  IPriceCountQueue,
  ITrackingData,
  ISeatingSubProductsEntity,
  IExportPricesEntity,
  IPatchBookingOrderPayload,
  ICartPrice,
  IPriceCountLite,
  IMembershipCustomer,
  ISeatPriceType,
  IGQLBookingSummary,
  ITicketSlotsFetch,
  IPreBookProductQtRetail,
  IPriceRetail,
  IPreBookingRetailReq,
  ISlotOrderRetail,
  IPatchBookingOrderRetailPayload,
  IPreBookingPaymentStatusRes,
  IPatchBookingOrderDonationPayload,
  AccountingEntity,
  IProductSlotStatesEntity,
  IteamInfo,
} from '@/models/store/booking';
import {
  fetchTickets, makeBooking, patchBookingOrder,
  patchBooking, postPromoCode, fetchServerBooking,
  cancelBooking, applyMemDiscount, gql_fetchBooking,
  fetchTicketDates, fetchServerBookingPaymentStatus, removePromoCode, cancelPSS,
} from '@/api/booking';
import Debug from 'debug';
import { BookingSteps, StepName } from './constants';
import { ProductModule, ReferrerModule, AppModule, OrganizerModule, NBookingModule } from '@/utils/storemodules';
import dayjs from 'dayjs';
import {
  ticketSelectedSlots as ticketSelectedSlotsMethod, ticketRequiresCode,
  priceRequiresCode, priceRequiresCodeLink, isSeatingTicket, isCalendarTicket,
} from '@/utils/booking';
import { Product } from '@/models/store/bookingClasses';
import { IPackageCategory, IPackagePrice, IPricePackage, IAddonPackageInfo } from '@/models/store/packages';
import { IOrganiser } from '@/models/store/product';
import { BookingStatus, CategoryStatus, PaymentStatus, SeatType, CategoryType, ChargeFlowTypes } from '@/models/enums';
import { getCartBookingInfo, inIframe, sendCartBookingInfo } from '@/utils/iframe';
import { WidgetOps } from '@/models/iframe';
import { IMembershipDiscounts, IMembershipProductSlotStates, IPostMembershipData, MembershipType } from '@/models/memberships';
import { disableQuasarStyling, enableQuasarStyling } from '@/utils/styles';
import { getLanguageInt, isEquilibre, isSeatParentCategory } from '@/utils/helpers';
import { IProductCategory } from '@/types/db/product-category';
import i18n from '@/locales';
import { IProduct } from '@/models/store/product';
import { TicketsAllowedPrices, TicketsShowsPrices } from '@/types/app/product';
import { findBookingCodesForPrice, findRecapCategoriesWithMembershipSetting } from '@/utils/membership';
import { UserMembership } from '@/types/gql/generatedShipping/graphql';
import {bookingFlowViewItemList} from '@/utils/tracking';
import { setVisibleTicketsOnStorage } from '@/utils/helpers';

const debug = Debug('smeetz:nbooking');
const debug1 = Debug('smeetz:forceprice');

const TICKETS_STORAGE = 'tickets';
const FETCHED_TICKET_STORAGE = 'fitchedTickets';
const BOOKING_RES = 'bookingRes';

@Module({ namespaced: true, name: 'nbooking' })
export default class NBooking extends VuexModule {

  public tickets: { [key: string]: ITicket } = {}; // hidden categories that should be visible
  // public fetchedTickets: ITicket[] = []; // used to know order of tickets
  // public ffetchedTickets: ITicket[] = [];
  // To know if user has clicked on confirmed in checkout step and went to payment step
  public submittedBooking: boolean = false;
  public bookingStep: BookingSteps = BookingSteps.Order;
  public bookingRes: IPreBookingRes | null = null;
  public bookingPaymentStatus: IPreBookingPaymentStatusRes | null = null;
  public bookingSummary: IGQLBookingSummary | null = null;
  public loading: boolean = false;
  public isPaymentPending: boolean = false;
  public timer: number | null = null;
  public isUserChangeEmail: boolean = false;
  public isUserGuest: boolean = true;
  public isSocialLogin: boolean = false;
  public bookingId: number = 0;
  public bookingToken: string = '';
  public preselectObject: any = null;
  public accessCode: string = '';
  public countryCode: string = 'CH';
  public organizerInfo: IOrganiser | null = null;
  public showBasket = false; // Indicator whether we should show basket or not
  public closeCart = true; // Indicates whether a cart should be closed.
  public linkedRetailIds: number[] = [];
  public linkedTicketIds: number[] = [];
  public linkedTicketCodes: string[] = [];
  public linkedTicketPackageCategory: { [ticketId: string]: IPackageCategory } = {};
  public linkedTicketIdsMin: number[] = [];
  public linkedTicketIdsMax: number[] = [];

  public prodsTickets: { [prodId: number]: ITicket[] } = {};
  public shippingTickets: ITicket[] = [];
  public dynamicShippingTickets: ITicket[] = [];
  public editedTicketIds: {ticketId: number, timeSlotId: number} | null = null;

  public memId: number | null = null;
  public discounts: IMembershipDiscounts[] = [];
  public membership: IMembershipDiscounts | null = null;
  public membershipCount: number = 1;
  public membershipCustomers: IMembershipCustomer[] = [];
  // Memberhsip product slot states
  public membershipPSS: IMembershipProductSlotStates[] = [];
  public membershipApplied: IPostMembershipData | null = null;
  public holdToken: string | null = null;
  public timerExpirationDate: string = '';
  // Mapping of required price within a category
  // CategoryId => PriceId (required price)
  public ticketReqPrices: { [s: string]: number[] } = {
    [38275]: [4013],
    [55739]: [12285],
    [57118]: [14926],
    [57660]: [16044],
    [57324]: [15265],
    [90635]: [225588],
  };

  // Prices that are required (package prices)
  public bookingRequiredPrices: { [s: string]: IPackagePrice | null } = {};
  public priceErrors: { [priceId: string]: string } = {};

  // Package categories with min
  public packageMin: { [packageId: string]: IPricePackage } = {};
  public packageErrors: { [packageId: string]: string } = {};

  // Tickets that are required
  public addonTicketsMinRequired: { [id: string]: number } = {};
  public addonTicketsMaxRequired: { [id: string]: number } = {};
  public addonTicketsMaxBookable: { [id: string]: number} = {};
  // We use the retail package informations to link the retail addon with the main ticket
  // The package informations contains a boolean for we can know
  // if the retail addon is included in the main price or not
  public addonsSecondStepInfo: IAddonPackageInfo [] = [];

  // Booking codes needed for each price
  public numberBookingCodePrices: IPriceCount[] = [];
  public validatedBookingCodes: string[] = [];
  public showLoginView: boolean = false;
  public skipLoginView: boolean = false;
  public priceValidatedBookingCodes: Record<number, string[]> = {};
  public userMembershipBookingCodes: UserMembership[] = [];

  public isOpendLoginDialog: boolean = false;
  public isCartInfo: boolean = false;

  public receivedProductIdsFromWidgetOp: number [] = [];

  // Todo 1: Ticket min validation
  // public ticketsMin1: { [ticketId: number]: number } = {
  //   // Naturklang wanted to remove this limit because we ended up in a case where
  //   // there is 1 seat remaining. And the limit of 2 will not allow anyone to buy
  //   // that.
  //   // 39302: 2,
  //   // 68871: 2,
  //   // 68897: 2,
  //   // 68875: 2,
  //   // 68903: 2,
  //   // 71394: 4,
  //   42202: 4, // DEV product
  //   65843: 20,
  // };

  public piazzaGrandeValidation: boolean = false;



  public piazzaSeatingPlanPrices: { [mainTicketId: string]: { showId: number, packagePriceId: number} } = {
    '204550_34800339': { showId: 92315, packagePriceId: 10520 },
    '204563_34800339': { showId: 92315, packagePriceId: 10520 },
    '204552_34800342': { showId: 92319, packagePriceId: 10524 },
    '204565_34800342': { showId: 92319, packagePriceId: 10524 },
    '204553_34800343': { showId: 92321, packagePriceId: 10526 },
    '204566_34800343': { showId: 92321, packagePriceId: 10526 },
    '204554_34800344': { showId: 92323, packagePriceId: 10530 },
    '204567_34800344': { showId: 92323, packagePriceId: 10530 },
    '204555_34828198': { showId: 92325, packagePriceId: 10531 },
    '204568_34828198': { showId: 92325, packagePriceId: 10531 },
    // LFF 2024
    '258905_41157607': {showId: 108243, packagePriceId: 17158},
    '258915_41157607': {showId: 108243, packagePriceId: 17158},
    '258906_41157608': {showId: 108296, packagePriceId: 17160},
    '258916_41157608': {showId: 108296, packagePriceId: 17160},
    '258907_41157609': {showId: 108310, packagePriceId: 17161},
    '258917_41157609': {showId: 108310, packagePriceId: 17161},
    '258908_41157610': {showId: 108312, packagePriceId: 17163},
    '258918_41157610': {showId: 108312, packagePriceId: 17163},
    '258944_41157611': {showId: 108314, packagePriceId: 17165},
    '258926_41157611': {showId: 108314, packagePriceId: 17165},
    '258945_41157613': {showId: 108316, packagePriceId: 17167},
    '258927_41157613': {showId: 108316, packagePriceId: 17167},
    '258946_41157614': {showId: 108318, packagePriceId: 17169},
    '258928_41157614': {showId: 108318, packagePriceId: 17169},
    '258947_41157615': {showId: 108320, packagePriceId: 17171},
    '258929_41157615': {showId: 108320, packagePriceId: 17171},
    '258948_41157619': {showId: 108322, packagePriceId: 17173},
    '258930_41157619': {showId: 108322, packagePriceId: 17173},
    '258949_41157618': {showId: 108324, packagePriceId: 17175},
    '258931_41157618': {showId: 108324, packagePriceId: 17175},
    '258950_41157617': {showId: 108326, packagePriceId: 17178},
    '258932_41157617': {showId: 108326, packagePriceId: 17178},
  };

  public pricesMin: { [ticketIdPriceId: string]: { minQty: number, optional: boolean } } = {
    // - Validates a solo price minimum quantity in a given category
    // - Format: parentCategoryId_priceId: {minQty, optional}
    // - An optional price is a price which the user can move forward with 0 qty of it
    // - If user booked from optional price then he must book at least min qty
    // - A mandatory price is where the user must book at least min qty
    // '71394_129694': {minQty: 4, optional: true},
    // '71394_130392': {minQty: 4, optional: true},

    // '71946_131388': {minQty: 4, optional: true},
    // '71946_131386': {minQty: 4, optional: true},

    // '71943_131383': {minQty: 4, optional: true},
    // '71943_131381': {minQty: 4, optional: true},

    // '71940_131378': {minQty: 4, optional: true},
    // '71940_131376': {minQty: 4, optional: true},

    // '71937_131371': {minQty: 4, optional: true},
    // '71937_131373': {minQty: 4, optional: true},



    // '71891_131282': {minQty: 4, optional: true},
    // '71891_131287': {minQty: 4, optional: true},

    // '71897_131302': {minQty: 4, optional: true},
    // '71897_131307': {minQty: 4, optional: true},
    '76830_160896': {minQty: 4, optional: true},
    '76830_161079': {minQty: 4, optional: true},
    '76833_161423': {minQty: 4, optional: true},
    '76833_161428': {minQty: 4, optional: true},
    '76836_161431': {minQty: 4, optional: true},
    '76836_161434': {minQty: 4, optional: true},
    '76839_161435': {minQty: 4, optional: true},
    '76839_161436': {minQty: 4, optional: true},
    '76841_161437': {minQty: 4, optional: true},
    '76841_161438': {minQty: 4, optional: true},
    '76845_161439': {minQty: 4, optional: true},
    '76845_161440': {minQty: 4, optional: true},
    '76849_161441': {minQty: 4, optional: true},
    '76849_161442': {minQty: 4, optional: true},

    '81568_165447': {minQty: 4, optional: true},
    '81571_165452': {minQty: 4, optional: true},

    '77365_168058': {minQty: 4, optional: true},

    '108064_256808': {minQty: 8, optional: true},
    '108226_257054': {minQty: 8, optional: true},
    '108270_257111': {minQty: 8, optional: true},
    '108273_257113': {minQty: 8, optional: true},
    '108276_257117': {minQty: 8, optional: true},
    '108279_257119': {minQty: 8, optional: true},
    '108282_257123': {minQty: 8, optional: true},
    '108285_257125': {minQty: 8, optional: true},
    '108715_257784': {minQty: 8, optional: true},
    '108697_257749': {minQty: 8, optional: true},
    '108864_258111': {minQty: 8, optional: true},
    '108867_258115': {minQty: 8, optional: true},
    '108870_258117': {minQty: 8, optional: true},
    '108873_258121': {minQty: 8, optional: true},
    '108876_258123': {minQty: 8, optional: true},
  };

  // I WANTED TO GO WITH BELOW STRUCTURING TICKETID:PRICEID
  // BUT WE MAY HAVE A CASE WHERE A PRICE IS USED IN TWO DIFFERENT TICKETS
  // AND HAS TWO DIFFERENT MIN QTY
  // SO I HAD TO GO WITH ONE ARRAY ONLY OF  TICKETID-PRICEID:MINQTY SHOWN ABOVE AS pricesMin
  // public ticketPriceRelation: { [ticketId: number]: number } = {
  //   // childCategoryId: priceId
  //   // determines the priceId that will have min validation inside a given ticket
  //   42203: 14193,
  // };


  public pricesSetMin: { [ticketId: string]: { pricesIds: string[], minTotalQty: number, optional: boolean } } = {
    // - Validate min quantity of total qty of prices group in a given category
    // - Format: parentCategoryId: {[priceId1, priceId2, ...], minTotalQty, optional}
    // - An optional set of prices is a set of prices which the user can move forward with 0 total qty of them
    // - If user booked from optional set of prices then he must book at least min qty as the total from those prices
    // - A mandatory set of prices is where the user must book at least min qty as the total from those prices
    71937: { pricesIds: ['131373', '131371'], minTotalQty: 4, optional: true },
    // i.e: ticket 71394 should have at least total of 4 pss of price 131373 and/or price 131371
    // (0 pss is acceptable as well)

    71946: { pricesIds: ['131388', '131386'], minTotalQty: 4, optional: true },
    71943: { pricesIds: ['131383', '131381'], minTotalQty: 4, optional: true },
    71940: { pricesIds: ['131378', '131376'], minTotalQty: 4, optional: true },
    71891: { pricesIds: ['131282', '131287'], minTotalQty: 4, optional: true },
    71897: { pricesIds: ['131302', '131307'], minTotalQty: 4, optional: true },

    71394: { pricesIds: ['130392', '129694'], minTotalQty: 4, optional: true },

    71975: { pricesIds: ['131458', '131463'], minTotalQty: 4, optional: true },
    71981: { pricesIds: ['131480', '131475'], minTotalQty: 4, optional: true },

    71958: { pricesIds: ['131421', '131416'], minTotalQty: 4, optional: true },
    72643: { pricesIds: ['133006'], minTotalQty: 4, optional: true },
    72885: { pricesIds: ['133680'], minTotalQty: 4, optional: true },
    76968: { pricesIds: ['150463'], minTotalQty: 4, optional: true },
    78284: { pricesIds: ['154667'], minTotalQty: 4, optional: true },
    79115: { pricesIds: ['157404'], minTotalQty: 4, optional: true },
    79117: { pricesIds: ['157408'], minTotalQty: 4, optional: true },

    // product 61269
    83816: { pricesIds: ['215047'], minTotalQty: 4, optional: true },
    83818: { pricesIds: ['213217'], minTotalQty: 4, optional: true },
    83820: { pricesIds: ['215042'], minTotalQty: 4, optional: true },
    83822: { pricesIds: ['215043'], minTotalQty: 4, optional: true },
    83824: { pricesIds: ['212860'], minTotalQty: 4, optional: true },
    83826: { pricesIds: ['215045'], minTotalQty: 4, optional: true },
    83828: { pricesIds: ['215046'], minTotalQty: 4, optional: true },

    // product 63872
    94579: { pricesIds: ['213097'], minTotalQty: 4, optional: true },

    // product 64124
    95453: { pricesIds: ['215739'], minTotalQty: 4, optional: true },
    95455: { pricesIds: ['215744'], minTotalQty: 4, optional: true },

    // product 64162
    95560: { pricesIds: ['216163'], minTotalQty: 4, optional: true },
    95563: { pricesIds: ['216169'], minTotalQty: 4, optional: true },

    // productID = 65884
    103727: { pricesIds: ['240606'], minTotalQty: 4, optional: true },
    103729: { pricesIds: ['240609'], minTotalQty: 4, optional: true },
  };


  public parentCategoryForPrices: { [ticketId: number]: number } = {
    108866: 108864,
    108868: 108867,
    108872: 108870,
    108874: 108873,
    108878: 108876,
    108716: 108715,
    108699: 108697,
    108287: 108285,
    108283: 108282,
    108281: 108279,
    108277: 108276,
    108275: 108273,
    108271: 108270,
    108228: 108226,
    108065: 108064,

    77366: 77365,

    81569: 81568,
    81572: 81571,

    76851: 76849,
    76850: 76849,
    76847: 76845,
    76846: 76845,
    76844: 76841,
    76842: 76841,
    76843: 76839,
    76840: 76839,
    76838: 76836,
    76837: 76836,
    76835: 76833,
    76834: 76833,
    76832: 76830,
    76831: 76830,

    39302: 39302,
    68873: 68871,
    68898: 68897,
    68878: 68875,
    68905: 68903,

    71395: 71394,
    71641: 71394,
    71947: 71946,
    71948: 71946,

    71944: 71943,
    71945: 71943,

    71938: 71937,
    71939: 71937,

    71941: 71940,
    71942: 71940,

    71892: 71891,
    71893: 71891,
    71898: 71897,
    71899: 71897,

    71976: 71975,
    71977: 71975,

    71982: 71981,
    71983: 71981,

    71960: 71958,
    71959: 71958,
    72644: 72643,
    72886: 72885,
    76969: 76968,
    78285: 78284,
    79116: 79115,
    79118: 79117,

    42203: 42202,

    // product 61269
    83817: 83816,
    83819: 83818,
    83821: 83820,
    83823: 83822,
    83825: 83824,
    83827: 83826,
    83829: 83828,

    // product 63872
    94580: 94579,

    // product 64124
    95454: 95453,
    95456: 95455,

    // product 64162
    95561: 95560,
    95564: 95563,

    // product 65884
    103728: 103727,
    103730: 103729,
  };

  // public parentCategory: { [ticketId: number]: number } = {
  //   65843: 65843,
  // };

  // Internals
  // Q implementation for processing add price count.
  // This forces requests to be sent to the backend 1 at a time
  public processing = false;

  public renderedChartRef: string | null = null;

  // Whether a price is Seating Plan or General Admission
  public seatPricesTypes: ISeatPriceType[] = [];

  // Whether seatSelectionValidators is triggerd
  public seatSelectionValidators: number[] = [];

  public teamInfoPerCategory: IteamInfo [] = [];

  public dynamicPricingTrackingData: {strategy_name: string, control_group: string} = {strategy_name: '', control_group: ''};


  private q: IPriceCountQueue[] = [];
  private openCart: boolean = false;

  public get parentCategory(): { [ticketId: number]: number } {
    const obj: { [ticketId: number]: number } = {};

    const tickets = this.fetchedTickets;

    // tslint:disable-next-line
    for (let i = 0; i < tickets.length; i++) {
      if (!tickets[i].seatingSubProducts || tickets[i].seatingSubProducts?.length === 0) {
        obj[tickets[i].categoryId] = tickets[i].categoryId;
      } else {
        const childrenIds = tickets[i].seatingSubProducts?.map((t) => t.categoryId);
        if (!childrenIds) {
          obj[tickets[i].categoryId] = tickets[i].categoryId;
        } else {
          // tslint:disable-next-line
          for (let j = 0; j < childrenIds.length; j++) {
            obj[childrenIds[j]] = tickets[i].categoryId;
          }
        }
      }
    }

    return obj;
  }

  public get ticketsMin(): { [ticketId: number]: number } {
    const obj: { [ticketId: number]: number } = {};

    const tickets = this.fetchedTickets;
    // tslint:disable-next-line
    for (let i = 0; i < tickets.length; i++) {
      obj[tickets[i].categoryId] = tickets[i].minQuantityPerBooking;
    }

    // NOW, in case of seated ticket we cannot set minBooking and maxBooking in B2B
    // we have to add it using a similar line code as:
    // obj[parentCategoryId_1] = 3;
    // obj[parentCategoryId_2] = 5;

    return obj;
  }

  public get hasNoAllBookingCodes(): boolean {
    if (this.numberBookingCodePrices.length === 0) {
      return false;
    }
    // For now we support only one membership price
    const filtredValidatedBookingCodes = this.validatedBookingCodes.filter((item) => item);
    if (filtredValidatedBookingCodes.length < this.numberBookingCodePrices[0].count) {
      return true;
    }
    return false;
  }

  public get membershipPriceWasAdded(): boolean {
    if (this.numberBookingCodePrices.length === 0) {
      return false;
    }

    const checkMembershipCount = this.numberBookingCodePrices.find((price) => price.count > 0);

    if (checkMembershipCount) {
      return true;
    }
    return false;
  }

  public get getOrganizerInfo() {
    return this.organizerInfo;
  }

  public get getPreselectObject() {
    return this.preselectObject;
  }

  public get getIsLoading() {
    return this.loading;
  }

  public get getIsPaymentPending() {
    return this.isPaymentPending;
  }

  public get fetchedTickets(): ITicket[] {
    const prodsTickets = this.prodsTickets;
    let tickets: ITicket[] = [];
    for (const key in prodsTickets) {
      if (!key) {
        continue;
      }
      tickets = tickets.concat(prodsTickets[key]);
    }

    return tickets;
  }

  /**
   * Returns booking basket (shopping cart)
   */
  public get basket() {
    const tickets = this.fetchedTickets;
    const basket: ITicketBasket[] = [];

    debug('Basket retrieved');
    // Populate the basket with each ticket;
    for (const ticket of tickets) {

      // one ticket basket
      const ticketBasket: ITicketBasket = {
        id: ticket.categoryId,
        name: ticket.categoryName,
        slots: [],
      };

      // populate basketTicket with selected slots
      const slots = ticket.slots || {};
      for (const slotId in slots) {
        if (!slotId) {
          continue;
        }

        const slot = slots[slotId];
        ticketBasket.slots.push(slot);
      }

      // Add tickets with selected slots to basket
      if (ticketBasket.slots.length) {
        basket.push(ticketBasket);
      }
    }

    return basket;
  }

  get bookingSteps(): string[] {
    const steps: string[] = [];
    for (let i = 0; i <= BookingSteps.Confirmation; i++) {
      steps.push(StepName[i]);
    }

    return steps;
  }

  get isFirstStep(): boolean {
    return this.bookingStep === BookingSteps.Order;
  }

  get isLastStep(): boolean {
    return this.bookingStep === BookingSteps.Confirmation;
  }

  get slotTicket(): (slot: ITimeslotsEntity) => ITicket | undefined {
    return (slot: ITimeslotsEntity) => {
      const tickets = this.fetchedTickets;
      for (const ticket of tickets) {
        const slots = ticket.slots;
        if (slots && slots[slot.id]) {
          return ticket;
        }
      }
    };
  }

  get filteredTickets(): ITicket[] {
    const tickets = this.fetchedTickets.filter(ReferrerModule.isTicketVisible);

    // if filtering with mfrom, make sure that we show only tickets with
    // timeslots after mfrom;
    let filteredTickets: ITicket[] = tickets;
    if (ReferrerModule.mFrom) {
      const mfrom = dayjs(ReferrerModule.mFrom);
      filteredTickets = filteredTickets.filter((t) =>
        t.categoryInfo.dates.some((d) =>
          mfrom.isSame(d.startDate) || mfrom.isBefore(d.startDate)));
    }

    // if filtering with mto, make sure that we show only tickets with
    // timeslots before mto;
    if (ReferrerModule.mTo) {
      const mto = dayjs(ReferrerModule.mTo);
      filteredTickets = filteredTickets.filter((t) =>
        t.categoryInfo.dates.some((d) =>
          mto.isSame(d.startDate) || mto.isAfter(d.startDate)));
    }
    return filteredTickets;
  }

  get flatTickets(): ITicket[] {
    const tickets: ITicket[] = [];

    for (const t of this.fetchedTickets) {
      tickets.push(t);
      if (isSeatingTicket(t) && t.seatingSubProducts) {
        const subProducts: ISeatingSubProductsEntity[] = t.seatingSubProducts;
        tickets.push(...(subProducts as any));
      }
    }

    return tickets;
  }

  get ticketById(): (categoryId: number) => ITicket | undefined {
    return (id: number): ITicket | undefined => {
      const tickets = this.fetchedTickets;

      return tickets.find((ticket) => {
        return ticket.categoryId === id;
      });
    };
  }

  get flatTicketById(): (categoryId: number, flat?: boolean) => ITicket | undefined {
    return (id: number, flat?: boolean): ITicket | undefined => {
      const tickets = flat ? this.flatTickets : this.fetchedTickets;

      return tickets.find((ticket) => {
        return ticket.categoryId === id;
      });
    };
  }

  get selectedPriceById(): (priceId: number, slotId?: number) => IServerRecapCategory | undefined {
    return (id: number, slotId?: number): IServerRecapCategory | undefined => {
      const serverBooking = this.bookingRes;
      if (!serverBooking) {
        return;
      }

      const categories = serverBooking.bookingRecap && serverBooking.bookingRecap.categories;

      if (!categories) {
        return;
      }

      return categories.find((cat) => {
        if (slotId) {
          return cat.priceId === id && cat.timeSlotId === slotId;
        }
        return cat.priceId === id;
      });
    };
  }

  get selectedNormalPriceById(): (priceId: number, slotId?: number) => IServerRecapCategory | undefined {
    return (id: number, slotId?: number): IServerRecapCategory | undefined => {
      const serverBooking = this.bookingRes;
      if (!serverBooking) {
        return;
      }

      const categories = serverBooking.bookingRecap && serverBooking.bookingRecap.categories;

      if (!categories) {
        return;
      }

      // In addition to priceId & slotId we need to use mainTicket too
      // because in one scenario we had a package price in a product
      // and we had it as a normal price in the same product
      // we would have got the wrong price from the categories in such a case
      return categories.find((cat) => {
        if (slotId) {
          return cat.priceId === id
            && cat.timeSlotId === slotId
            && (cat.mainTicket === null || cat.mainTicket === '');
        }
        return cat.priceId === id && (cat.mainTicket === null || cat.mainTicket === '');
      });
    };
  }

  get selectedPackagePriceById(): (priceId: number, slotId: number, mainTicket: string) =>
    IServerRecapCategory | undefined {
    return (id: number, slotId: number, mainTicket: string): IServerRecapCategory | undefined => {
      const serverBooking = this.bookingRes;
      if (!serverBooking) {
        return;
      }

      const categories = serverBooking.bookingRecap && serverBooking.bookingRecap.categories;

      if (!categories) {
        return;
      }

      return categories.find((cat) => {
        if (slotId) {
          return cat.priceId === id
            && cat.timeSlotId === slotId
            && cat.mainTicket === mainTicket;
        }
        return cat.priceId === id;
      });
    };
  }

  // returns the selected slots of a give ticket
  get ticketSelectedSlots(): (ticket: ITicket) => ITimeslotsEntity[] {

    return ticketSelectedSlotsMethod;
  }

  get isSlotEmpty(): (slot: ITimeslotsEntity) => boolean {
    return (slot: ITimeslotsEntity) => {
      const prices = slot.export_prices || [];
      let priceCount = 0;
      for (const price of prices) {
        priceCount += price.count || 0;
      }

      return priceCount === 0;
    };
  }

  get isTicketEmpty(): (ticket: ITicket) => boolean {
    return (ticket: ITicket): boolean => {
      const slots = ticket.slots || {};
      // Are there any empty slots
      let isSlotEmpty = true;
      for (const slotId in slots) {
        if (!slotId) {
          continue;
        }

        isSlotEmpty = this.isSlotEmpty(slots[slotId]);
        if (!isSlotEmpty) {
          break;
        }
      }

      return isSlotEmpty;
    };
  }

  get selectedTickets(): ITicket[] {
    const tickets: ITicket[] = [];
    for (const ticket of this.fetchedTickets) {
      if (this.tickets[ticket.categoryId]) {
        tickets.push(ticket);
      }
    }
    return tickets;
  }

  get basketTotal(): number {
    let total = 0;

    for (const ticket of this.selectedTickets) {
      const slots = ticket.slots;
      if (!slots) {
        continue;
      }

      for (const slotId in slots) {
        if (!slotId) {
          continue;
        }
        const slot = ticket.slots[slotId];
        const prices = slot.export_prices || [];
        for (const price of prices) {
          if (price.count) {
            total += price.count * price.priceValue;
          }
        }
      } // slots
    } // tickets;

    return total;
  }

  get orderRecap(): IFrontRecapTicket[] {
    // group by ticket start and end date time
    const tickets: IFrontRecapTicket[] = [];
    const { bookingRes, membershipApplied } = this;

    const categories = bookingRes && bookingRes.bookingRecap && bookingRes.bookingRecap.categories;

    if (!categories) {
      return tickets;
    }

    const ticketsObj: { [s: string]: IFrontRecapTicket } = {};

    const filtredCategories = categories.filter((t) => t.isShipping === false);
    // populate tickets array
    for (const ticketPrice of filtredCategories) {
      const {
        startDateTime, endDateTime, categoryName,
        categoryId, timeSlotId, seat, hideDateTime, categoryType,
      } = ticketPrice;
      // const name = categoryName + startDateTime + endDateTime;
      let name = categoryName + timeSlotId;
      if (seat) {
        name = name + seat;
      }

      let ticket: IFrontRecapTicket | undefined = ticketsObj[name];

      // Add ticket if it doesn't exist
      if (!ticket) {
        ticket = {
          categoryName,
          startDateTime,
          endDateTime,
          categoryId,
          timeSlotId: Number(timeSlotId),
          hideDateTime,
          prices: [],
          type: undefined,
          categoryType,
        };

        // const seat = ticketPrice.seat;
        if (seat) {
          ticket.seat = seat;
        }

        ticketsObj[name] = ticket;
        tickets.push(ticket);
      }

      let priceValueDefault = 0;
      if (membershipApplied) {
        const pss = membershipApplied.productSlotStates.find((p) => {
          return p.categoryId === Number(ticketPrice.categoryId) &&
            p.timeSlotId === Number(ticketPrice.timeSlotId) &&
            p.priceId === Number(ticketPrice.priceId);
        });

        if (pss) {
          priceValueDefault = pss.priceValueDefault;
        }
      }
      if (priceValueDefault) {
        ticket.prices.push({
          ...ticketPrice,
          priceValueDefault: priceValueDefault * Number(ticketPrice.quantity),
        });
      } else {
        ticket.prices.push(ticketPrice);
      }
    }

    return tickets;
  }

  get recapInfo(): IRecapInfo {
    const info: IRecapInfo = {
      total: 0,
      totalAcompte: 0,
      fees: 0,
      discount: 0,
      subTotal: 0,
      deposit: 0,
      isFree: false,
      bookingEmail: '',
      shipping: 0,
      taxes: [],
      payments: 0,
    };

    const serverBooking = this.bookingRes;
    if (!serverBooking) {
      return info;
    }

    // update info with server data
    const recap = serverBooking.bookingRecap;
    info.total = recap.bookingFlowTotal;
    info.totalAcompte = recap.totalAcompte || 0;
    info.fees = recap.paymentFees || 0;
    info.subTotal = recap.subtotal || 0;
    info.discount = recap.discount || 0;
    info.deposit = recap.deposit || 0;
    info.shipping = recap.shipping || 0;
    info.bookingEmail = serverBooking.bookingEmail || '';
    info.taxes = recap.taxes || [];
    info.payments = recap.payments || 0;

    // Get booking doesn't return productSlotStates. So we should be flexible
    if (serverBooking.productSlotStates && Array.isArray(serverBooking.productSlotStates)) {
      info.isFree = (serverBooking.productSlotStates.filter((pss) =>
        pss.bookingStatus !== CategoryStatus.BookingStatusCancelled
        && pss.bookingStatus !== CategoryStatus.BookingStatusAbandoned).length !== 0 && info.total === 0);
    } else if (recap.categories && Array.isArray(recap.categories)) {
      info.isFree = (recap.categories.length !== 0 && info.total === 0);
    }

    return info;
  }

  get recapCategories(): IServerRecapCategory[] {
    const bookingRes = this.bookingRes;
    if (!bookingRes) {
      return [];
    }

    const categories = bookingRes.bookingRecap.categories;

    return categories || [];
  }

  // Acompte booking is a booking that requires a deposit
  // and an amount to be charged later on site when the user arrives.
  get isAcompteBooking(): boolean {

    const serverBooking = this.bookingRes;
    if (!serverBooking) {
      return false;
    }

    const recap = serverBooking.bookingRecap;

    // One of the tickets must be linked to an acompte
    for (const ticket of (recap.categories || [])) {
      if (ticket.acompteId) {
        // if we are paying the total, then it is not an acompte.
        // this happens if we have acompte configuration that works for certain tickets only
        // when they are addons or only when they are main tickets.
        // BOO-1962
        return recap.total !== recap.totalAcompte;
      }
    }

    return false;
  }

  // Check if we have an acompte that is equal to total.
  get isAcompteEqualToTotal(): boolean {

    const serverBooking = this.bookingRes;
    if (!serverBooking) {
      return false;
    }

    const recap = serverBooking.bookingRecap;

    // One of the tickets must be linked to an acompte
    for (const ticket of (recap.categories || [])) {
      if (ticket.acompteId) {
        // if we are paying the total, then we will not consider this as acompte.
        // But in reality, we have an acompte setup. So we need to know about this
        // when dealing with things like deposits.
        return recap.total === recap.totalAcompte;
      }
    }

    return false;
  }

  // The amount that will be charged on site for Acompte booking
  get toBeChargedOnSiteAmount(): number {
    if (!this.isAcompteBooking) {
      return 0;
    }

    return this.bookingRes ? this.bookingRes.bookingRecap.total - this.bookingRes.bookingRecap.totalAcompte : 0;
  }

  // Generates tracking data so that they can easily be digested for tracking
  get ticketsTracking(): ITrackingData[] {
    const productData = ProductModule.product;
    if (!productData) {
      return [];
    }

    const product: Product = new Product(productData);
    const tickets: ITrackingData[] = [];
    for (const ticket of this.orderRecap) {
      for (const price of ticket.prices) {
        const ticketData: ITrackingData = {
          id: String(product.id),
          name: product.name,
          brand: product.brand,
          currency: product.currency,
          quantity: Number(price.quantity),
          category: ticket.categoryName,
          price: price.priceValue / Number(price.quantity),
          position: 0,
          list: '', // TODO: need to confirm this
        };

        if (productData.fbPixelId) {
          ticketData.fbPixelId = productData.fbPixelId;
        }

        // Add google tracking id
        if (productData.googleTrackingId) {
          ticketData.googleTrackingId = productData.googleTrackingId;
        }

        // Add GTM measurement id
        if (productData.gtmId) {
          ticketData.gtmId = productData.gtmId;
        }

        tickets.push(ticketData);
      }
    }
    return tickets;
  }

  // checks if we need to show access code input or not.
  get isAccessCodeRequired(): boolean {
    // for (const ticket of this.filteredTickets) {
    //   if (ticketRequiresCode(ticket) && !this.accessCode) {
    //     return true;
    //   }
    // }

    // Access code is required if current basket contains a ticket that
    // requires access code.
    const bookingRes = this.bookingRes;
    if (!bookingRes) {
      return false;
    }

    const categories = bookingRes.bookingRecap.categories;
    if (!categories) {
      return false;
    }

    for (const cat of categories) {
      const id = Number(cat.categoryId);
      const ticket = this.flatTicketById(id, true);
      if (!ticket) {
        continue;
      }

      if (
        (ticketRequiresCode(ticket) || priceRequiresCode(Number(cat.priceId), ticket))
        && !this.accessCode
      ) {
        return true;
      }
    }

    // Equilibre has some blocked by code tickets, and sometimes we don't have all the tickets
    // at that point. So, we will hardcode the prices that require access by code.
    // This is only for widgets
    const orgId = OrganizerModule.id;
    if (orgId && isEquilibre(orgId) && inIframe()) {
      for (const cat of categories) {
        // Make sure that if we already have an access code, we skip this
        if (cat.priceName === `Pass'jeune` && (!this.accessCode)) {
          return true;
        }
      }
    }

    return false;
  }

  get accessCodeLink(): string {

    // Backward compatible support. This is how we solve the problem for OVV
    for (const ticket of this.fetchedTickets) {
      if (ticketRequiresCode(ticket) && ticket.requiredBeforeBookingLink) {
        return ticket.requiredBeforeBookingLink;
      }
    }

    // price based link checks
    const bookingRes = this.bookingRes;
    if (!bookingRes) {
      return '';
    }

    const categories = bookingRes.bookingRecap.categories;
    if (!categories) {
      return '';
    }

    for (const cat of categories) {
      const id = Number(cat.categoryId);
      const ticket = this.flatTicketById(id, true);
      if (!ticket) {
        continue;
      }

      return priceRequiresCodeLink(Number(cat.priceId), ticket);
    }

    // Equilibre has some blocked by code prices, and sometimes we don't have all the tickets
    // at that point. So, we will hardcode the prices that require access by code.
    // This is only for widgets
    const orgId = OrganizerModule.id;
    if (orgId && isEquilibre(orgId) && inIframe()) {
      for (const cat of categories) {
        if (cat.priceName === `Pass'jeune`) {
          return `https://www.equilibre-nuithonie.ch/fr/infos-pratiques/billets-et-abonnements/passjeunes`;
        }
      }
    }

    return '';
  }

  get pricePackage() {

    return (price: IExportPricesEntity, timeSlot: ITimeslotsEntity): IPricePackage | null => {
      const bookingRes = this.bookingRes;
      const timeslot = timeSlot;
      if (!bookingRes || !bookingRes.packages || !timeslot) {
        return null;
      }

      const packages = bookingRes.packages;
      const timeSlotPackage = packages[String(timeslot.id)];
      if (!timeSlotPackage) {
        return null;
      }

      const pricePackage = timeSlotPackage.package_main_prices[String(price.priceId)];
      if (!pricePackage) {
        return null;
      }

      // skip if price contains no packages
      if (!Object.keys(pricePackage.package_categories).length) {
        return null;
      }

      // Price packages with priority 2 will be displayed on a separate page
      let findPriorityOne = false;
      Object.keys(pricePackage.package_categories).forEach((packageCategory) => {
        if (pricePackage.package_categories[packageCategory].display_page === 1) {
          findPriorityOne = true;
        }
      });

      if (!findPriorityOne) {
        return null;
      }

      return pricePackage;
    };

  }

  get pricePackageByPriceId() {

    return (priceId: number, timeSlotId: number): IPricePackage | null => {
      const bookingRes = this.bookingRes;
      const timeslot = timeSlotId;
      if (!bookingRes || !bookingRes.packages || !timeslot) {
        return null;
      }

      const packages = bookingRes.packages;
      const timeSlotPackage = packages[String(timeslot)];
      if (!timeSlotPackage) {
        return null;
      }

      const pricePackage = timeSlotPackage.package_main_prices[String(priceId)];
      if (!pricePackage) {
        return null;
      }

      // skip if price contains no packages
      if (!Object.keys(pricePackage.package_categories).length) {
        return null;
      }

      return pricePackage;
    };

  }

  get missingReqBookingPrices(): Array<IPackagePrice | IExportPricesEntity> {
    const recap = this.bookingRes && this.bookingRes.bookingRecap;
    const missingPrices: Array<IPackagePrice | IExportPricesEntity> = [];
    const categories = (recap && recap.categories) || [];
    const missingReqBookingPrices = this.bookingRequiredPrices;

    for (const priceId of Object.keys(missingReqBookingPrices)) {
      const reqPrice = missingReqBookingPrices[priceId];
      if (!reqPrice) {
        continue;
      }
      const price = categories.find((cat) => String(cat.priceId) === priceId);
      if (!price) {
        missingPrices.push(reqPrice);
      }
    }

    return missingPrices;
  }

  // Returns pacakges that didn't reach their min
  get packagesWithoutMinReached(): string[] {
    const packages: string[] = [];
    const bookingRes = this.bookingRes;

    if (!bookingRes || !bookingRes.bookingRecap || !bookingRes.bookingRecap.categories) {
      return packages;
    }

    // Get the ids of all packages that didn't reach min
    for (const packageId of Object.keys(this.packageMin)) {
      const pack = this.packageMin[packageId];
      const packageMin = pack.min_quantity;
      const packageCategories = pack.package_categories;
      let packageTotal = 0;
      const packageMainTicket = packageId;

      // get total count of all categories
      for (const recapCat of bookingRes.bookingRecap.categories) {
        // if (catsIds.includes(String(recapCat.categoryId))) {
        if (packageMainTicket === recapCat.mainTicket
          && packageCategories[recapCat.categoryId] !== undefined) {
          packageTotal += Number(recapCat.quantity);
        }
      }

      // packages that didn't reach min are flagged
      if (packageTotal < packageMin) {
        packages.push(packageId);
      }
    }

    return packages;
  }

  // Returns tickets that didn't reach their min
  get ticketsWithoutMinReached(): string[] {
    const tickets: string[] = [];
    const bookingRes = this.bookingRes;

    if (!bookingRes || !bookingRes.bookingRecap || !bookingRes.bookingRecap.categories) {
      return tickets;
    }

    // Get the ids of all tickets that didn't reach min
    for (const categoryId of Object.keys(this.addonTicketsMinRequired)) {
      const ticketMin = this.addonTicketsMinRequired[categoryId];
      let ticketTotal = 0;
      // get total count of all categories
      for (const recapCat of bookingRes.bookingRecap.categories) {
        if (Number(categoryId) === recapCat.categoryId || Number(categoryId) === recapCat.seatingMainSubProductId) {
          ticketTotal += Number(recapCat.quantity);
        }
      }
      if (ticketTotal < ticketMin) {
        tickets.push(categoryId);
      }
    }
    return tickets;
  }

  // Returns pacakges that exceeds their max
  get packagesWithMaxExceeded(): string[] {
    const packages: string[] = [];
    const bookingRes = this.bookingRes;

    if (!bookingRes || !bookingRes.bookingRecap || !bookingRes.bookingRecap.categories) {
      return packages;
    }

    // Get the ids of all packages that exceeds their max
    for (const packageId of Object.keys(this.packageMin)) {
      const pack = this.packageMin[packageId];
      const packageMax = pack.max_quantity;
      const packageCategories = pack.package_categories;
      // this.pricePackage.package_categories;
      let packageTotal = 0;
      const packageMainTicket = packageId;

      // get total count of all categories
      for (const recapCat of bookingRes.bookingRecap.categories) {
        // combine the quantity of all recap categories with mainTicket equal to our constructed
        // main ticket and whise categoryId exist in package_categories
        // the second condition excludes addons booked automatically from the server
        if (packageMainTicket === recapCat.mainTicket
          && packageCategories[recapCat.categoryId] !== undefined) {
          packageTotal += Number(recapCat.quantity);
        }
      }

      // packages that didn't reach max are flagged
      if (packageTotal > packageMax) {
        packages.push(packageId);
      }
    }

    return packages;
  }

  /**
   * Checks whether the booking awaits confirmation
   */
  get isRequestToBook(): boolean {
    const bookingRes = this.bookingRes;
    if (!bookingRes) {
      return false;
    }

    const categories = bookingRes.bookingRecap.categories;

    // Only 2 booking types require confirmation
    const delayedCategories = [
      CategoryStatus.BookingStatusPendingOrganizer,
      CategoryStatus.BookingStatusPendingCron,
    ];

    const delayedChargeFlowTypes = [
      ChargeFlowTypes.ChargeFlowTypeAuthorized,
      ChargeFlowTypes.ChargeFlowTypeTokenized,
      ChargeFlowTypes.ChargeFlowTypeTokenizedDeliver,
    ];

    if (!categories) {
      return false;
    }

    // Check if we have a ticket (category) that requires confirmation
    const categoryThatRequiresConfirmation = categories.find((cat) =>
      delayedCategories.includes(Number(cat.bookingStatus)) ||
      delayedChargeFlowTypes.includes(Number(cat.chargeFlowType)));

    if (categoryThatRequiresConfirmation) {
      return true;
    }

    return false;
  }

  /**
   * Returns the discount from promo code
   */
  get promoDiscount(): number {
    const { bookingRes } = this;
    if (!bookingRes) {
      return 0;
    }

    return bookingRes.bookingRecap.promo_code_discount;
  }

  /**
   * Returns the name of the promo code applied
   */
  get promoName(): string {
    const { bookingRes } = this;
    if (!bookingRes) {
      return '';
    }

    const accounting = bookingRes.bookingRecap.accounting;
    if (!accounting || !accounting.length) {
      return '';
    }

    return accounting.reduce((allPromo, promo) => {
      return allPromo === '' ? allPromo + promo.promo_code_code : allPromo + ', ' + promo.promo_code_code;
    }, '');

    // return accounting[accounting.length - 1].promo_code_code;
  }

  /**
   * Returns the list of promo code applied
   */
  get listPromoApplied(): AccountingEntity[] | null {
    const { bookingRes } = this;
    if (!bookingRes) {
      return null;
    }

    const accounting = bookingRes.bookingRecap.accounting;
    if (!accounting || !accounting.length) {
      return null;
    }

    if (accounting.length === 1 && Object.keys(accounting[0]).length === 0) {
      return null;
    }

    const accountingsToDisplay = ['GIFT_CARD', 'PROMO_CODE', 'MEMBERSHIP', 'VOUCHER'];

    const discountAccounting = accounting.filter((acc: AccountingEntity) =>
      accountingsToDisplay.includes(acc.paymentMethod));

    const groupId = OrganizerModule.id;
    const removeMembershipAccountings = [19017].includes(Number(groupId));
    if (removeMembershipAccountings) {
      return discountAccounting.filter((acc) => acc.paymentMethod !== 'MEMBERSHIP');
    }

    return discountAccounting;
  }

  /**
   * Returns the list of ALL promo code applied
   */
  get listOfAllPromoApplied(): AccountingEntity[] | null {
    const { bookingRes } = this;
    if (!bookingRes) {
      return null;
    }

    const accounting = bookingRes.bookingRecap.accounting;
    if (!accounting || !accounting.length) {
      return null;
    }

    if (accounting.length === 1 && Object.keys(accounting[0]).length === 0) {
      return null;
    }

    return accounting;
  }

  /**
   * Returns an array of the modules filtered tickets groups
   */
  get filtTicketsGroups(): number[] {
    const allowedGroups = this.filteredTickets.map((t) => t.ticketGroups);

    // make sure we add groups from seating sub products
    for (const t of this.filteredTickets) {
      if (isSeatParentCategory(t) && t.seatingSubProducts && t.seatingSubProducts.length) {
        for (const seatCat of t.seatingSubProducts) {
          if (seatCat.ticketGroups) {
            allowedGroups.push(seatCat.ticketGroups);
          }
        }
      }
    }

    const groups: number[] = [];

    for (const groupArray of allowedGroups) {
      for (const group of groupArray) {
        groups.push(group.id);
      }
    }

    return groups;
  }

  /**
   * Indicates whether we are dealing with a memebership booking.
   *
   * It basically checks if the user has already chosen a membership.
   * It doesn't check whether we are dealing with a memebeership only booking
   * and other organiser/widget set parameters.
   */
  get isMembershipBooking(): boolean {
    return !!this.membership;
  }

  get enableNewCalendarDesign() {
    // Groups for which we'll Enable the new calendar design on mobile
    const enableNewDesign = [16426];
    const groupId = Number(OrganizerModule.id);
    // If query param newCalendar is set
    const newCalendar = ReferrerModule.newCalendar;
    return enableNewDesign.includes(groupId) || newCalendar;
  }

  /**
   * Have we got customers added to our booking.
   *
   * It only checks if we have customers added. It doesn't check
   * or validate any parameters from the organiser side.
   */
  get isCustomerBooking(): boolean {
    return !!this.membershipCustomers.length;
  }

  get getSeatPricesTypes(): ISeatPriceType[] {
    return this.seatPricesTypes;
  }

  get getSeatSelectionValidators(): number[] {
    return this.seatSelectionValidators;
  }

  get getDpTrackingData() {
    return this.dynamicPricingTrackingData;
  }
  get getisCartInfo() {
    return this.isCartInfo;
  }

  get getOpenCart() {
    return this.openCart;
  }

  @Mutation
  public setOpenCart(value: boolean) {
    this.openCart = value;
  }

  @Mutation
  public setOpenLoginDialog(value: boolean) {
    this.isOpendLoginDialog = value;
  }

  @Mutation
  public setPiazzaGrandeValidation(value: boolean) {
    this.piazzaGrandeValidation = value;
  }

  @Mutation
  public setSubmittedBooking(value: boolean) {
    this.submittedBooking = value;
  }

  // Sets the timerExpirationDate to the date that was first created when the first ticket was selected
  // or when the timer was first started after opening seating chart.
  @Mutation
  public setTimerExpirationDate(expirationDate: string) {
    this.timerExpirationDate = expirationDate;
  }

  @Mutation
  public setRenderedChartRef(renderedChartRef: string | null) {
    debug1('hi from setRenderedChartRef');
    debug1('going to set it to', renderedChartRef);
    this.renderedChartRef = renderedChartRef;
  }

  @Mutation
  public setSeatPricesTypes(seatPriceType: ISeatPriceType) {
    if (this.seatPricesTypes.filter((p) => p.priceId === seatPriceType.priceId).length > 0) {
      // Element exists already. Do nothing
    } else {
      this.seatPricesTypes.push(seatPriceType);
    }
  }

  @Mutation
  public setSeatSelectionValidators(payload: { value: number, remove: boolean }) {
    if (payload.remove) {
      this.seatSelectionValidators.splice(this.seatSelectionValidators.indexOf(payload.value), 1);
      return;
    } else if (this.seatSelectionValidators.indexOf(payload.value) === -1) {
      this.seatSelectionValidators.push(payload.value);
    }
  }

  @Mutation
  public setAccessCode(code: string) {
    this.accessCode = code;
  }

  @Mutation
  public setCountryCode(countryCode: string) {
    this.countryCode = countryCode;
  }

  @Mutation
  public userChangeEmail() {
    this.isUserChangeEmail = true;
  }

  @Mutation
  public userGuestCheckout() {
    this.isUserGuest = true;
  }

  @Mutation
  public socialLoginCheckout() {
    this.isSocialLogin = true;
  }

  /**
   * Changes booking step backward
   */
  @Mutation
  public stepBack() {
    const step: number = this.bookingStep;
    if (step === BookingSteps.Order
      && ProductModule.hasAddonsInASeparateStep
      && ProductModule.hideAddons === false) {
      ProductModule.setHideAddons(true);
      this.bookingStep = (BookingSteps.Order as number);
      return;
    }
    if (step !== BookingSteps.Order || step !== (BookingSteps.Confirmation as number)) {
      this.bookingStep--;
    }
  }

  /**
   * Changes booking step forward
   */
  @Mutation
  public stepForward() {
    const step: number = this.bookingStep;
    if (step !== BookingSteps.Confirmation) {
      this.bookingStep++;
    }
  }

  @Mutation
  public stepTo(step: BookingSteps) {
    this.bookingStep = step;
  }

  /**
   * Changes booking step to start
   */
  @Mutation
  public backToStart() {
    this.bookingStep = BookingSteps.Order;
    this.bookingRes = null;
  }

  @Mutation
  public setShowBasket(show: boolean) {
    this.showBasket = show;
  }

  @Mutation
  public setCloseCart(close: boolean) {
    this.closeCart = close;
  }

  @Mutation
  public setLoading(status: boolean) {
    this.loading = status;
  }

  @Mutation
  public setIsPaymentPending(status: boolean) {
    this.isPaymentPending = status;
  }

  @Mutation
  public setProcessing(status: boolean) {
    this.processing = status;
  }

  /**
   * Sets tickets in the module
   * @param tickets: Array of tickets
   */
  // @Mutation
  // public setTickets(tickets: ITicket[]) {
  //   this._fetchedTickets = tickets;
  // }

  /**
   * Sets the products tickets
   * @param prodsTickets: Object of products tickets
   */
  @Mutation
  public setProdsTickets(prodsTickets: { [prodId: number]: ITicket[] }) {
    this.prodsTickets = { ...prodsTickets };
  }

  /**
   * Sets the shipping tickets
   * @param prodsTickets: Object of shipping tickets
   */
  @Mutation
  public setShippingTickets(shippingTickets: ITicket[]) {
    this.shippingTickets = [ ...shippingTickets ];
  }

  /**
   * Sets the dynamic shipping tickets
   * @param prodsTickets: Object of shipping tickets
   */
  @Mutation
  public setDynamicShippingTickets(dynamicShippingTickets: ITicket[]) {
    this.dynamicShippingTickets = [ ...dynamicShippingTickets ];
  }

  /**
   * Selects slot by adding it to the ticket slots
   * @param payload {ISelectSlotPayload}: payload for adding a slot
   */
  // @Mutation
  // public selectSlot(payload: ISelectSlotPayload) {
  //   debug('slot selected');
  //   // find the slot
  //   const ticket = this.tickets[payload.ticketId];
  //   const { slot } = payload;

  //   // set the price if it doesn't exist
  //   if (!slot.prices) {
  //     slot.prices = {};
  //   }

  //   // Add the slot
  //   ticket.slots[slot.id] = slot;
  //   // provoke basket calculations
  //   this.fetchedTickets = [...this.fetchedTickets];
  // }

  /**
   * Selects slot by adding it to the ticket slots
   * @param payload {ISelectSlotPayload}: payload for adding a slot
   */
  // @Mutation
  // public deSelectSlot(payload: ISelectSlotPayload) {

  //   // find the slot in the ticket
  //   const ticket = this.tickets[payload.ticketId];
  //   const { slot } = payload;

  //   // delete the slot
  //   delete ticket.slots[slot.id];

  //   // provoke basket calculation
  //   this.fetchedTickets = [...this.fetchedTickets];
  // }

  /**
   * Sets ticket count
   * @param payload {ITicketCountPayload}: Ticket price information
   * to be modified
   */
  // @Mutation
  // public setTicketCount(payload: ITicketCount) {

  //   // find price
  //   const { ticketId, price, slotId, count } = payload;
  //   const slot = this.tickets[ticketId].slots[slotId];
  //   let addedPrice = slot.prices && slot.prices[price.priceId];

  //   // make sure that prices is set for that slot
  //   if (!slot.prices) {
  //     slot.prices = {};
  //   }

  //   // Modify price count if already inserted
  //   if (addedPrice) {
  //     addedPrice.count = count;
  //   } else {
  //     // add price with count
  //     price.count = count;

  //     slot.prices[price.priceId] = price;
  //     addedPrice = price;
  //   }

  //   // remove price if count is 0;
  //   if (count === 0) {
  //     addedPrice.count = 0;
  //     delete slot.prices[addedPrice.priceId];
  //   }

  //   // provoke basket calculation
  //   this.fetchedTickets = [...this.fetchedTickets];
  // }

  /**
   * Sets the current booking object
   * @param data {IPreBookingRes}
   */
  @Mutation
  public setBooking(data: IPreBookingRes) {
    this.bookingRes = data;
  }

  /**
   * Sets the current booking payment status
   * @param data {IPreBookingPaymentStatusRes}
   */
     @Mutation
     public setBookingPaymentStatus(data: IPreBookingPaymentStatusRes) {
       this.bookingPaymentStatus = data;
     }

  /**
   * Sets the summary of a given booking operation
   * @param data {any}
   */
  @Mutation
  public setBookingSummary(data: IGQLBookingSummary) {
    this.bookingSummary = data;
  }

  @Mutation
  public setTicket(ticket: ITicket) {
    if (!this.tickets[ticket.categoryId]) {
      Vue.set(this.tickets, String(ticket.categoryId), ticket);
    }
  }

  @Mutation
  public deSelectTicket(ticket: ITicket) {

    const newTickets: { [s: string]: ITicket } = {};

    for (const ticketId in this.tickets) {
      // add only other tickets
      if (ticketId !== String(ticket.categoryId)) {
        newTickets[ticketId] = this.tickets[ticketId];
      }
    }

    // replace ticket object
    this.tickets = newTickets;
  }

  // @Mutation
  // public async persistBooking() {
  //   const bookingRes = this.bookingRes;
  //   if (!bookingRes) {
  //     debug('Can\'t persist state without a booking');
  //     return;
  //   }

  //   const bookingId = bookingRes.bookingId;

  //   // store tickets and fetched tickets
  //   localStorage.setItem(bookingId + FETCHED_TICKET_STORAGE, JSON.stringify(this.fetchedTickets));
  //   localStorage.setItem(bookingId + TICKETS_STORAGE, JSON.stringify(this.tickets));
  //   localStorage.setItem(bookingId + BOOKING_RES, JSON.stringify(this.bookingRes));
  // }

  // @Mutation
  // public restoreBookingState(info: IServerBookingData) {
  //   // let bookingRes = this.bookingRes;
  //   // if (!bookingRes) {
  //   //   debug('Can\'t persist state without a booking');
  //   //   return;
  //   // }
  //   // this.bookingRes = (Object.assign({}, bookingRes, info));
  //   // bookingRes = this.bookingRes;

  //   // const bookingId = bookingRes.bookingId;
  //   const bookingId = info.bookingId;
  //   const bookingRes = localStorage.getItem(bookingId + BOOKING_RES);
  //   const tickets = localStorage.getItem(bookingId + TICKETS_STORAGE);
  //   const fetchedTickets = localStorage.getItem(bookingId + FETCHED_TICKET_STORAGE);

  //   if (!tickets || !fetchedTickets || !bookingRes) {
  //     debug('No tickets were found, for', bookingId);
  //     return;
  //   }

  //   debug('Setting tickets and fetched tickets back to state');
  //   this.tickets = JSON.parse(tickets);
  //   this.fetchedTickets = JSON.parse(fetchedTickets);
  //   this.bookingRes = JSON.parse(bookingRes);

  // }

  // @Mutation
  // public clearStoredState(info?: IServerBookingData) {
  //   // Get the id
  //   const bookingRes = this.bookingRes;
  //   if (!bookingRes && !info) {
  //     return;
  //   }

  //   let bookingId: number = 0;
  //   if (info) {
  //     bookingId = info.bookingId;
  //   } else if (bookingRes) {
  //     bookingId = bookingRes.bookingId;
  //   }

  //   // remove Items from the store
  //   debug('removing stored items');
  //   localStorage.removeItem(bookingId + TICKETS_STORAGE);
  //   localStorage.removeItem(bookingId + FETCHED_TICKET_STORAGE);
  //   localStorage.removeItem(bookingId + BOOKING_RES);
  // }

  @Mutation
  public clearBooking() {
    this.submittedBooking = false;
    this.tickets = {};
    this.bookingRes = null;
    // this.fetchedTickets = [];
    this.prodsTickets = {};
    this.bookingStep = BookingSteps.Order;
    this.loading = false;
    this.isPaymentPending = false;
    this.timer = null;
    this.isUserChangeEmail = false;
    this.isUserGuest = true;
    this.isSocialLogin = false;
    this.bookingId = 0;
    this.bookingToken = '';
    this.preselectObject = null;
    this.accessCode = '';
    this.organizerInfo = null;
    this.bookingRequiredPrices = {};
    this.priceErrors = {};
    this.packageMin = {};
    this.packageErrors = {};
    this.addonTicketsMinRequired = {};
    this.addonTicketsMaxRequired = {};
    this.addonTicketsMaxBookable = {};
    this.addonsSecondStepInfo = [];
    this.numberBookingCodePrices = [];
    this.userMembershipBookingCodes = [];
    this.validatedBookingCodes = [];
    this.showLoginView = false;
    this.skipLoginView = false;
    this.priceValidatedBookingCodes = {};
    this.prodsTickets = {};
    this.memId = null;
    this.discounts = [];
    this.membershipPSS = [];
    this.membership = null;
    this.membershipApplied = null;
    this.membershipCount = 0;
    this.membershipCustomers = [];
    this.holdToken = null;
    this.isOpendLoginDialog = false;
    ProductModule.setViewedDonation(false);
    ProductModule.setDonations([]);
    ProductModule.setProducts([]);
    ProductModule.setRetailAddons([]);
    ProductModule.setTicketsAddons([]);
    ProductModule.setShowsAddons([]);
    ProductModule.setAddonTicketsShowsPrices({});
    ProductModule.setPizzaShowsAddons([]);
    ProductModule.setRequiredNumberPiazzaSeats(0);
    ProductModule.setRequiredNumberPiazzaSeatsPerCategory([]);
    this.piazzaGrandeValidation = false;
    this.linkedRetailIds = [];
    this.linkedTicketIds = [];
    this.linkedTicketCodes = [];
    this.linkedTicketIdsMin = [];
    this.linkedTicketIdsMax = [];
    ProductModule.setHideAddons(true);
    ProductModule.setCurrency('');
    this.seatPricesTypes = [];
    this.seatSelectionValidators = [];
    this.timerExpirationDate = '';
    ProductModule.setRetails([]);
    ReferrerModule.setRetailIds('');
    ReferrerModule.setFromWidget('');
    ReferrerModule.setTopLevelDomainUrl('');
    ReferrerModule.setPaymentMethod('');
    ReferrerModule.setCategories('');
    ReferrerModule.setVisibleCategories();
    this.shippingTickets = [];
    this.dynamicShippingTickets = [];
    this.editedTicketIds = null;
    this.receivedProductIdsFromWidgetOp = [];
    this.dynamicPricingTrackingData = {strategy_name: '', control_group: ''};
    this.isCartInfo = false;
    this.openCart = false;
  }

  @Mutation
  public setCartInfo(payload: boolean) {
    this.isCartInfo = true;
  }

  @Mutation
  public startTimer(payload: number) {
    if (!this.timer) {
      this.timer = payload;
    }
  }

  @Mutation
  public stopTimer() {
    this.timer = null;
  }

  @Mutation
  public setBookingToken(token: string) {
    this.bookingToken = token;
  }

  @Mutation
  public setBookingId(id: number) {
    this.bookingId = id;
  }

  @Mutation
  public SetPreselected(payload: any) {
    this.preselectObject = payload;
  }

  @Mutation
  public removeTicketFromPreselected(payload: string | number) {
    this.preselectObject.ticket = this.preselectObject.ticket.filter((item: string) => item !== payload);
  }

  @Mutation
  public setOrganizerInfo(payload: IOrganiser) {
    this.organizerInfo = payload;
  }

  @Mutation
  public addBookingReqPrice(payload: IPackagePrice) {
    // this.bookingRequiredPrices[payload.priceId] = payload;
    Vue.set(this.bookingRequiredPrices, payload.priceId, payload);
  }

  @Mutation
  public remBookingReqPrice(payload: IPackagePrice) {
    delete this.bookingRequiredPrices[payload.priceId];
  }

  @Mutation
  public addPackageMin(payload: { id: string, package: IPricePackage }) {
    Vue.set(this.packageMin, payload.id, payload.package);
  }

  @Mutation
  public removePackageMin(packageId: string) {
    delete this.packageMin[packageId];
  }

  // Todo 2
  // Add min ticket and remove ticket min setters when a ticket is added.

  @Mutation
  public addPackageError(errorInfo: { packageId: string, error?: string }) {
    if (errorInfo.error) {
      Vue.set(this.packageErrors, errorInfo.packageId, errorInfo.error);
      return;
    }
    Vue.set(this.packageErrors, errorInfo.packageId, 'min');
  }

  @Mutation
  public addMaxPackageError(packageId: string) {
    Vue.set(this.packageErrors, packageId, 'max');
  }

  @Mutation
  public remPackageError(packageId: string) {
    if (this.packageErrors[packageId]) {
      this.packageErrors[packageId] = '';
    }
  }

  @Mutation
  public addPriceError(priceId: string) {
    // this.bookingRequiredPrices[payload.priceId] = payload;
    Vue.set(this.priceErrors, priceId, '1');
  }

  @Mutation
  public remPriceError(priceId: string) {
    this.priceErrors[priceId] = '';
  }

  @Mutation
  public addAddonTicketsMinRequired(payload: { id: string, minRequired: number }) {
    Vue.set(this.addonTicketsMinRequired, payload.id, payload.minRequired);
  }

  @Mutation
  public addAddonTicketsMaxRequired(payload: { id: string, maxRequired: number }) {
    Vue.set(this.addonTicketsMaxRequired, payload.id, payload.maxRequired);
  }

  @Mutation
  public addAddonTicketsMaxBookable(payload: {id: string, maxBookable: number}) {
    Vue.set(this.addonTicketsMaxBookable, payload.id, payload.maxBookable);
  }

  @Mutation
  public addAddonsSecondStepInfo(payload: IAddonPackageInfo) {
    this.addonsSecondStepInfo.push(payload);
  }
  @Mutation
  public addNumberBookingCodePrices(price: IPriceCount[]) {
    this.numberBookingCodePrices = price;
  }

  @Mutation
  public setNumberBookingCodePrices(price: IPriceCount[]) {
    this.numberBookingCodePrices = price;
  }

  @Mutation
  public setUserMembershipBookingCodes(vouchers: UserMembership[]) {
    this.userMembershipBookingCodes = vouchers;
  }

  @Mutation
  public setValidatedBookingCodes(codes: string[]) {
    this.validatedBookingCodes = codes;
  }

  @Mutation
  public setShowLoginView(value: boolean) {
    this.showLoginView = value;
  }

  @Mutation
  public setSkipLoginView(value: boolean) {
    this.skipLoginView = value;
  }

  public setPriceValidatedBookingCodes(data: Record<number, string[]>) {
    this.priceValidatedBookingCodes = data;
  }

  @Mutation
  public setValidatedBookingCodeByIndex(payload: {code: string, index: number}) {
    Vue.set(this.validatedBookingCodes , payload.index, payload.code);
  }


  @Mutation
  public setMembershipPSS(pss: IMembershipProductSlotStates[]) {
    this.membershipPSS = pss;
  }

  @Mutation
  public setMembershipDiscounts(discounts: IMembershipDiscounts[]) {
    this.discounts = discounts;
  }

  @Mutation
  public setMembership(membership: IMembershipDiscounts | null) {
    this.membership = membership;
  }

  @Mutation
  public setMembershipCount(count: number) {
    this.membershipCount = count;
  }

  @Mutation
  public setMemId(memId: number) {
    this.memId = memId;
  }

  @Mutation
  public setMembershipApplied(membership: IPostMembershipData | null) {
    this.membershipApplied = membership;
  }

  @Mutation public setMembCustomers(memCustomers: IMembershipCustomer[]) {
    this.membershipCustomers = memCustomers;
  }

  @Mutation public setHoldToken(tk: string) {
    this.holdToken = tk;
  }

  @Mutation
  public setLinkedRetailIds(Ids: number[]) {
    this.linkedRetailIds = Ids;
  }

  @Mutation
  public setLinkedTicketIds(Ids: number[]) {
    this.linkedTicketIds = Ids;
  }

  @Mutation
  public setLinkedTicketCodes(codes: string[]) {
    this.linkedTicketCodes = codes;
  }

  @Mutation
  public setLinkedTicketPackageCategory(linkedTicketPackageCategory: { [ticketId: string]: IPackageCategory }) {
    this.linkedTicketPackageCategory = linkedTicketPackageCategory;
  }

  @Mutation
  public setLinkedTicketIdsMin(Ids: number[]) {
    this.linkedTicketIdsMin = Ids;
  }

  @Mutation
  public setLinkedTicketIdsMax(Ids: number[]) {
    this.linkedTicketIdsMax = Ids;
  }

  @Mutation
  public setTeamInfo(teamInfo: IteamInfo) {
    const index = this.teamInfoPerCategory.findIndex((info) => info.categoryId === teamInfo.categoryId);
    if (index !== -1) {
      this.teamInfoPerCategory[index].teamCount = teamInfo.teamCount;
      return;
    }

    this.teamInfoPerCategory.push(
      {
        categoryId: teamInfo.categoryId,
        teamCount: teamInfo.teamCount,
        maxParticipants: teamInfo.maxParticipants,
      },
    );
  }

  @Mutation public setEditedTicketIds(payload: {ticketId: number, timeSlotId: number}) {
    this.editedTicketIds = payload;
  }

  @Mutation
  public setReceivedProductIds(payload: number[]) {
    this.receivedProductIdsFromWidgetOp = payload;
  }

  @Mutation
  public setDpTrackingData( payload: {strategy_name: string, control_group: string}) {
    this.dynamicPricingTrackingData = payload;
  }

  @Action({ rawError: true })
  public async preselectTickets() {
    let preselectTickets: any[] = [];
    for (const ticketId of this.preselectObject.ticket) {
      preselectTickets = preselectTickets.concat(this.fetchedTickets.find((item: any) => {
        return item.categoryId === Number(ticketId);
      }));
    }
    preselectTickets.forEach((item: any, index: number) => {
      if (item) {
        const priceCount = {
          price: item.price[0],
          slot: item.categoryInfo.timeslots[0],
          count: this.preselectObject.count,
          ticket: item,
        };
        this.addPriceQ({
          priceCount,
          cb: (err: any) => {
            if (!err && index === preselectTickets.length - 1) {
              this.stepTo(BookingSteps.Checkout);
            } else {
              debug(err);
            }
          },
        });
      }
    });
  }

  /**
   * Fetches all the product tickets
   * @param productId: The id of the product
   */
  // @Action({ rawError: true })
  // public async fetchTickets(productId: number): Promise<ITicket[]> {
  //   const tickets = await fetchTickets(productId);

  //   this.setTickets(tickets);
  //   return tickets;
  // }

  /**
   * Fetch each product tickets
   * @param productIds: products id
   */
  @Action({ rawError: true })
  public async fetchProductsTickets(productsId: number[]) {
    // const prodsTickets = this.prodsTickets;

    // for (const prodId of productsId) {
    //   if (!prodsTickets[prodId]) {
    //     prodsTickets[prodId] = await fetchTickets(prodId);
    //   }
    // }

    // this.setProdsTickets(prodsTickets);

    const prodsTickets = this.prodsTickets;
    const promises: Array<Promise<ITicket[]>> = [];
    for (const prodId of productsId) {
      if (!prodsTickets[prodId] || AppModule.isCartWidget) {
        // prodsTickets[prodId] = await fetchTickets(prodId);
        promises.push(fetchTickets(prodId).then(async (tickets) => {

          // fetch dates for first month for all calendar tickets
          for (const ticket of tickets) {
            if (isCalendarTicket(ticket)) {
              const firstDate = ticket.categoryInfo.dates[0];

              // skip if we don't have at least one day
              if (!firstDate) {
                continue;
              }

              const category = await fetchTicketDates({
                productId: prodId,
                categoryId: ticket.categoryId,
                from: firstDate.startDate,
                to: '',
              });

              ticket.categoryInfo.dates = category.categoryInfo.dates;
              ticket.categoryInfo.isCalendar = true;
            }
          }

          prodsTickets[prodId] = tickets;
          return tickets;
        }));
      }
    }

    await Promise.all(promises);

    this.setProdsTickets(prodsTickets);
  }

  /**
   * Fetch each product tickets without dates api
   * @param productIds: products id
   */
  @Action({ rawError: true })
  public async fetchProductsTicketsWithoutDates(productsId: number[]) {
    const prodsTickets = this.prodsTickets;
    const promises: Array<Promise<ITicket[]>> = [];
    for (const prodId of productsId) {
      // If it's cart widget we fetch the tickets
      if (!prodsTickets[prodId] || AppModule.isCartWidget) {
        // Fetch the tickets first
        const tickets = await fetchTickets(prodId);

        for (const ticket of tickets) {
          if (isCalendarTicket(ticket)) {
            const firstDate = ticket.categoryInfo.dates[0];

            // skip if we don't have at least one day
            if (!firstDate) {
              continue;
            }
            ticket.isFetching = true;
            ticket.categoryInfo.isCalendar = true;
          }
        }
        prodsTickets[prodId] = tickets;
        this.setProdsTickets(prodsTickets);
      }
    }
  }

  /**
   * Fetch each product tickets dates
   * @param productIds: products id
   */
  @Action({ rawError: true })
  public async fetchProductsTicketsDates(productsId: number[]) {
    const prodsTickets = this.prodsTickets;
    const promises: Array<Promise<ITicket[]>> = [];
    for (const prodId of productsId) {
      const tickets = prodsTickets[prodId];
      if (prodsTickets[prodId]) {
        promises.push((async () => {
        // Process the fetched tickets
        for (const ticket of tickets) {
          if (isCalendarTicket(ticket)) {
            const firstDate = ticket.categoryInfo.dates[0];
            // skip if we don't have at least one day
            if (!firstDate) {
              continue;
            }
            let category = await fetchTicketDates({
              productId: prodId,
              categoryId: ticket.categoryId,
              from: firstDate.startDate,
              to: '',
            });

            // Ensure that the dates fetched, should have at least 1 day with availability,
            // Otherwise, we fetch next month.
            // This makes sure that we don't show an empty calendar if we have a month with no availability.
            // We fetch at max, 3 extra months
            let monthCount = 0;
            while (monthCount++ < 3) {
              // check if we have 1 date with availability
              const hasAvailability = category.categoryInfo.dates.some((date) => date.publicCount === '1');
              // if we have availability, then we are good
              if (hasAvailability) {
                break;
              }
              // no availability was found, so let's fetch next month
              category = await fetchTicketDates({
                productId: prodId,
                categoryId: ticket.categoryId,
                from: dayjs(firstDate.startDate).add(1, 'month').startOf('month').format('YYYY-MM-DD'),
                to: '',
              });
            }

            ticket.categoryInfo.dates = category.categoryInfo.dates;
            ticket.categoryInfo.isCalendar = true;
            ticket.isFetching = false;
          }
        }

        prodsTickets[prodId] = tickets;
        return tickets; })());
      }
    }
    await Promise.all(promises);
    this.setProdsTickets(prodsTickets);
  }

  /**
   * Fetch each product tickets
   * @param productIds: products id
   */
  @Action({ rawError: true })
  public async fetchTicketDates(fetchData: ITicketSlotsFetch) {
    // we're supposed to recive only one ticekt filtered based on fetchData.categoryId
    // but it seems i is received in an array format

    const ticket = await fetchTicketDates(fetchData);
    const responseDates = ticket.categoryInfo.dates;
    if (!responseDates || !responseDates.length) {
      return;
    }

    const prodsTickets = this.prodsTickets;

    if (prodsTickets[fetchData.productId]) {
      // so this ticket exists in prodsTicket, lets update its dates[] array
      // update dates[] of the ticket in prodsTickets[]
      // and then call this.setProdsTickets(prodsTickets)
      const ourTicket = prodsTickets[fetchData.productId].find((t) => t.categoryId === fetchData.categoryId);
      const oldDates = ourTicket?.categoryInfo.dates;
      // for (let i = 0; i < responseDates.length; i++) {
      for (const date of responseDates) {
        if (oldDates?.find((d) => d.startDate === date.startDate)) {
          // no need to add this date element; it exists already
          continue;
        }
        oldDates?.push(date);
      }
    }

    this.setProdsTickets(prodsTickets);
  }

  /**
   * Adds a retail price to our booking.
   * @param payload: Add price payload
   */
 @Action({ rawError: true })
 public async addRetailPrice(payload: IPriceRetail): Promise<undefined> {
   const { count, retailPriceId, retailOptionId, categoryId, mainTicket, priceId, packagePriceId } = payload;
   const product = ProductModule.product;
   const { membership, membershipCustomers } = this;

   if (!product) {
     return;
   }

   try {
     // if there is no booking, create a booking
     if (!this.bookingRes) {
       debug('No booking. Creating a new one');
       const bookingObject: IPreBookingRetailReq = {
         productId: product.id,
         bookingLanguage: getLanguageInt(i18n.locale),
         bookingReferrer: ReferrerModule.referrer,
         ProductQt: [],
         utmChannel: ReferrerModule.utmChannel,
         utmMedium: ReferrerModule.utmMedium,
         utmCampaign: ReferrerModule.utmCampaign,
         utmSource: ReferrerModule.utmSource,
       };

       const remainingSeconds = product.holdingTime
         ? Number(product.holdingTime) * 60
         : 600;
       // Set timer time to product.holdingTime(pass in seconds)
       this.startTimer(remainingSeconds);
       const expirationDate = dayjs()
         .add(remainingSeconds, 's')
         .toISOString();
       if (!this.timerExpirationDate) {
         this.setTimerExpirationDate(expirationDate);
       }

       // Add referrers data
       const { utmCampaign, utmMedium, utmSource } = ReferrerModule;
       if (utmCampaign) {
         bookingObject.utmCampaign = utmCampaign;
       }
       if (utmMedium) {
         bookingObject.utmMedium = utmMedium;
       }
       if (utmSource) {
         bookingObject.utmSource = utmSource;
       }

       const productQt: IPreBookProductQtRetail = {
         // startDateTime: dayjs(slot.startDateTime).format('YYYY-MM-DD HH:mm:ss'),
         // endDateTime: dayjs(slot.endDateTime).format('YYYY-MM-DD HH:mm:ss'),
         categoryId,
         quantity: count,
         retailOptionId: retailPriceId ? retailOptionId : undefined,
         priceId: retailPriceId,
         groupId: OrganizerModule.id != null ? OrganizerModule.id : undefined,
       };

       bookingObject.ProductQt.push(productQt);
       // await this.book(bookingObject);
       this.setLoading(true);
       const booking = await makeBooking(bookingObject);
       this.setBooking(booking);
       this.setBookingId(booking.bookingId);
       this.setBookingToken(booking.bookingToken);
     } else {
       debug('We already have a booking');

       const booking = this.bookingRes;
       const selectedSlots: ISlotOrderRetail[] = [];
       this.remPackageError(String(categoryId));
       const slotOrder: ISlotOrderRetail = {
         categoryId,
         priceId: priceId || retailPriceId,
         quantity: count,
         retailOptionId,
         groupId: OrganizerModule.id != null ? OrganizerModule.id : undefined,
       };
       // Add mainTicket and packagePriceId to link the reail to main ticket
       if (mainTicket) {
        slotOrder.mainTicket = mainTicket;
       }
       if (packagePriceId) {
        slotOrder.packagePriceId = packagePriceId;
       }

       selectedSlots.push(slotOrder);

       this.setLoading(true);
       // Add referrers data
       const {
         utmChannel,
         utmCampaign,
         utmMedium,
         utmSource,
         referrer,
       } = ReferrerModule;
       const patchPayload: IPatchBookingOrderRetailPayload = {
         bookingId: this.bookingId,
         bookingToken: this.bookingToken,
         slots: selectedSlots,
         bookingReferrer: referrer || '',
         utmChannel,
       };
       if (utmCampaign) {
         patchPayload.utmCampaign = utmCampaign;
       }
       if (utmMedium) {
         patchPayload.utmMedium = utmMedium;
       }
       if (utmSource) {
         patchPayload.utmSource = utmSource;
       }

       const bookingRes = await patchBookingOrder(patchPayload);

       const emailMarketingSmeetz = booking.emailMarketingSmeetz;
       const termsOptin = booking.termsOptin;

       bookingRes.emailMarketingSmeetz = emailMarketingSmeetz;
       bookingRes.termsOptin = termsOptin;

       this.setBooking(bookingRes);
       this.setBookingToken(bookingRes.bookingToken);
     }
     await this.deleteDynamicShippingTicket();
     await this.addRemoveShippingTicket(count);
     // Send booking info if cart widget
     if (inIframe() && AppModule.isCartWidget && this.bookingRes) {
       const holdingTime =
         (ProductModule.product && ProductModule.product.holdingTime) || 20;
       const { bookingId, bookingToken, bookingRecap } = this.bookingRes;
       const categoriesWithoutShippingAndDonationTicket = bookingRecap.categories?.filter((t) =>
        !t.isShipping && t.categoryType !== CategoryType.Donation);
       const bookingRecapWithoutShippingAndDonationTicket = {...bookingRecap};
       bookingRecapWithoutShippingAndDonationTicket.categories = categoriesWithoutShippingAndDonationTicket;
       const remainingSeconds = this.timer || 0;
       const expirationDate = dayjs()
         .add(remainingSeconds, 's')
         .toISOString();

       const stash = {
         priceCodes: JSON.parse(
           JSON.stringify(NBookingModule.priceValidatedBookingCodes),
         ),
       };
       sendCartBookingInfo({
         wId: 'cart',
         smtzOp: WidgetOps.CartInfo,
         data: {
           bookingId,
           bookingRecap: bookingRecapWithoutShippingAndDonationTicket,
           bookingToken,
           holdingTime: this.timerExpirationDate
             ? this.timerExpirationDate
             : expirationDate,
           membership,
           membershipCustomers,
           holdToken: null,
           stash,
         },
       });
     }
   } finally {
     this.setLoading(false);
   }

   return;
 }


  /**
   * Adds a ticket price to our booking.
   * @param payload: Add price payload
   */
  @Action({ rawError: true })
  public async addPrice(payload: IPriceCount): Promise<undefined> {
    const {
      price, count, slot,
      ticket, seat, holdToken,
      seatCategoryId, mainTicket,
      packageCategoryId, packagePriceId,
      codes,
    } = payload;
    const product = ProductModule.product;
    const { membership, membershipCustomers } = this;

    if (!product) {
      return;
    }
    // retrieve ticket
    if (!ticket) {
      return;
    }

    // if we have a seating ticket
    let seatData: any = {};
    if (seat) {
      seatData = {
        type: 3,
        holdToken,
        seat,
      };
    }
    try {
      // if there is no booking, create a booking
      if (!this.bookingRes) {
        debug('No booking. Creating a new one');
        const bookingObject: IPreBookingReq = {
          productId: product.id,
          bookingLanguage: getLanguageInt(i18n.locale),
          bookingReferrer: ReferrerModule.referrer,
          ProductQt: [],
          utmChannel: ReferrerModule.utmChannel,
          utmMedium: ReferrerModule.utmMedium,
          utmCampaign: ReferrerModule.utmCampaign,
          utmSource: ReferrerModule.utmSource,
        };

        const remainingSeconds = product.holdingTime ? Number(product.holdingTime) * 60 : 600;
        // Set timer time to product.holdingTime(pass in seconds)
        this.startTimer(remainingSeconds);
        const expirationDate = dayjs().add(remainingSeconds, 's').toISOString();
        if (!this.timerExpirationDate) {
          this.setTimerExpirationDate(expirationDate);
        }

        // Add referrers data
        const { utmCampaign, utmMedium, utmSource } = ReferrerModule;
        if (utmCampaign) {
          bookingObject.utmCampaign = utmCampaign;
        }
        if (utmMedium) {
          bookingObject.utmMedium = utmMedium;
        }
        if (utmSource) {
          bookingObject.utmSource = utmSource;
        }

        const productQt: IPreBookProductQt = {
          // startDateTime: dayjs(slot.startDateTime).format('YYYY-MM-DD HH:mm:ss'),
          // endDateTime: dayjs(slot.endDateTime).format('YYYY-MM-DD HH:mm:ss'),
          timeSlot: slot.id,
          categoryId: seatCategoryId || ticket.categoryId,
          quantity: count,
          priceId: price.priceId,
          groupId: OrganizerModule.id,
          codes: codes && codes.length > 0 ? codes : undefined,
          ...seatData,
        };
        // Add the following dynamic pricing properties if booking happened
        // in booking flow with computed price tickets
        if (price.computedPriceId) {
          productQt.computedPriceId = price.computedPriceId;
          productQt.priceValue = price.priceValue;
        }

        if (price.validComputedPriceId) {
          productQt.computedPriceId = price.validComputedPriceId;
          productQt.priceValue = price.priceValue;
        }

        if (mainTicket && packagePriceId) {
          productQt.mainTicket = mainTicket;
          productQt.packagePriceId = packagePriceId;
        }

        bookingObject.ProductQt.push(productQt);
        // await this.book(bookingObject);
        this.setLoading(true);
        const booking = await makeBooking(bookingObject);
        this.setBooking(booking);
        // We'll set the retail/tickets add-ons ids if they exist so that we retreive/save them
        await this.setAddonsIds(booking);
        await this.retreiveRetailAddons();
        await this.retreiveTicketsAddons();
        this.setBookingId(booking.bookingId);
        this.setBookingToken(booking.bookingToken);

        if (this.membership && this.membership.type === MembershipType.RequiredTicketsMembership) {
          await this.setShowsAddons({booking});
        }

        // const piazzaGrandeProductIds = [63100, 37871];
        const piazzaGrandeProductIds = [37871, 63337, 63100, 64621];

        const hasPiazzaGrandeProduct = piazzaGrandeProductIds.includes(ticket.productId);

        if (hasPiazzaGrandeProduct) {
          await this.setShowsAddons({booking, isPiazzaGrande: true});
        }

        if ( ReferrerModule.promoCode ) {
          await this.sendPromoCode(ReferrerModule.promoCode);
        }
      } else {
        // We already have a booking. So update it.
        debug('We already have a booking');
        // get all ticket objects and send them to backend
        const booking = this.bookingRes;
        const selectedSlots: ISlotOrder[] = [];
        // checks whether the price was already selected or not
        // tslint:disable-next-line
        let wasPriceAdded: boolean = false;

        /*

        LEAVE COMMENTED. THIS FUNCTIONALITY WILL SEND TO THE BACKEND
        ALL THE TICKETS IN THE CURRENT BOOKING.
        WE CHANGED BECAUSE THE BACKEND WANTED US TO SEND ONLY THE NEWLY
        SELECTED TICKET PRICE.

        // get all the ticket counts from the selected tickets
        const tickets = this.selectedTickets;
        for (const iterticket of tickets) {
          const iterslots = iterticket.categoryInfo.timeslots || [];

          for (const iterslot of iterslots) {
            const prices = iterslot.export_prices || [];

            for (const iterprice of prices) {
              // skip if the price isn't the added price and
              // skip if price is not already selected
              const isAddedPrice = iterprice.priceId === price.priceId;
              if (!isAddedPrice && !iterprice.count) {
                continue;
              }

              // store that the price was added
              if (isAddedPrice) {
                debug('price was already added, updating it now');
                wasPriceAdded = isAddedPrice;
              }

              selectedSlots.push({
                categoryId: iterticket.categoryId,
                priceId: iterprice.priceId,
                // make sure that we send data for the  price not the stored one
                quantity: isAddedPrice ? count : iterprice.count,
                timeSlot: iterslot.id,
              });
            } // prices
          } // slots
        } // tickets
        */

        // make sure that the price is added if we are dealing with a non
        // selected ticket.
        const categoryId = packageCategoryId || seatCategoryId || ticket.categoryId;
        const slotOrder: ISlotOrder = {
          categoryId,
          timeSlot: slot.id,
          priceId: price.priceId,
          quantity: count,
          groupId: OrganizerModule.id,
          codes: codes && codes.length > 0 ? codes : undefined,
          ...seatData,
        };

        // When dealing with dynamic pricing
        if (price.computedPriceId) {
          slotOrder.computedPriceId = price.computedPriceId;
          slotOrder.priceValue = price.priceValue;
        }

        if (price.validComputedPriceId) {
          slotOrder.computedPriceId = price.validComputedPriceId;
          slotOrder.priceValue = price.priceValue;
        }

        if (packagePriceId && mainTicket) {
          // slotOrder.main_ticket = main_ticket;
          slotOrder.packagePriceId = packagePriceId;
          slotOrder.mainTicket = mainTicket;
        }

        if (Object.keys(ProductModule.addonTicketsShowsPrices).length) {
          Object.values(ProductModule.addonTicketsShowsPrices).forEach((ticketShowPrice) => {
            const pricePackage = ticketShowPrice.find((pprice: IPackagePrice) =>
              price.priceId ===  pprice.priceId && categoryId === pprice.categoryId);
            if (pricePackage) {
              slotOrder.packagePriceId = pricePackage.id;
              slotOrder.mainTicket = pricePackage.mainTimeSlotId + ':' + pricePackage.mainPriceId;
            }
          });
        } else if (this.piazzaGrandeValidation) {
          const mainTicketSeatedIds = Object.keys(this.piazzaSeatingPlanPrices);
          const bookingRecapSeated = NBookingModule.recapCategories.filter((cat) =>
            mainTicketSeatedIds.includes(cat.priceId + '_' + cat.timeSlotId) && cat.seat === null);
          slotOrder.mainTicket = bookingRecapSeated[0].timeSlotId + ':' + bookingRecapSeated[0].priceId;
          slotOrder.packagePriceId = this.piazzaSeatingPlanPrices
            [bookingRecapSeated[0].priceId + '_' + bookingRecapSeated[0].timeSlotId].packagePriceId;
        }

        if (!wasPriceAdded) {
          debug(`ticket wasn't already selected. Adding it's price`);
          selectedSlots.push(slotOrder);
          // selectedSlots.push({
          //   categoryId: seatCategoryId || ticket.categoryId,
          //   timeSlot: slot.id,
          //   priceId: price.priceId,
          //   quantity: count,
          //   ...seatData,
          // });
        }

        this.setLoading(true);
        // Add referrers data
        const { utmChannel, utmCampaign, utmMedium, utmSource, referrer } = ReferrerModule;
        const patchPayload: IPatchBookingOrderPayload = {
          bookingId: this.bookingId,
          bookingToken: this.bookingToken,
          slots: selectedSlots,
          bookingReferrer: referrer || '',
          utmChannel,
        };
        if (utmCampaign) {
          patchPayload.utmCampaign = utmCampaign;
        }
        if (utmMedium) {
          patchPayload.utmMedium = utmMedium;
        }
        if (utmSource) {
          patchPayload.utmSource = utmSource;
        }

        const piazzaGrandeProductIds = [63100, 37871, 63337, 64621];

        if (!seat && piazzaGrandeProductIds.includes(ticket.productId) ||
          !seat && membership && membership.type === MembershipType.RequiredTicketsMembership) {
            await NBookingModule.fetchBooking({bookingToken: this.bookingToken, bookingId: this.bookingId});
            const productSlotStates = (NBookingModule.bookingRes as any).slots;
            const mainPss = productSlotStates.filter((pss: IProductSlotStatesEntity) =>
              pss.bookingStatus !== 5 && pss.categoryName === ticket.categoryName);
            const mainPssIds = mainPss.map((pss: IProductSlotStatesEntity) => pss.productSlotStateId);
            const seatedPss = productSlotStates.filter((pss: IProductSlotStatesEntity) =>
              pss.bookingStatus !== 5 && pss.seat && pss.mainTicketId && mainPssIds.includes(pss.mainTicketId));
            const pssIdsToRemove = seatedPss.map((pss: IProductSlotStatesEntity) => pss.productSlotStateId);
            if (pssIdsToRemove.length > 0) {
              const payloadSeatPss = {
                bookingId: this.bookingId,
                bookingToken : this.bookingToken,
                releaseSeats: 1 as (1 | 0),
                tickets: pssIdsToRemove,
              };
              await cancelPSS(payloadSeatPss);
            }
          }

        const bookingRes = await patchBookingOrder(patchPayload);

        // emailMarketingSmeetz & termsOptin are not present in the patchBookingOrder response
        const emailMarketingSmeetz = booking.emailMarketingSmeetz;
        const termsOptin = booking.termsOptin;

        bookingRes.emailMarketingSmeetz = emailMarketingSmeetz;
        bookingRes.termsOptin = termsOptin;

        this.setBooking(bookingRes);
        // We'll set the retail/tickets add-ons ids if they exist so that we retreive/save them
        await this.setAddonsIds(bookingRes);
        await this.retreiveRetailAddons();
        await this.retreiveTicketsAddons();
        // Booking token is always changing. so let's update for each patch
        // booking call.
        this.setBookingToken(bookingRes.bookingToken);
        if (this.membership && this.membership.type === MembershipType.RequiredTicketsMembership) {
          await this.setShowsAddons({booking: bookingRes});
        }

        // const hasPiazzaGrandeProduct = ticket.productId === 37871;
        const hasPiazzaGrandeProduct = [63337, 37871, 63100, 64621].includes(ticket.productId);
        if (hasPiazzaGrandeProduct) {
          await this.setShowsAddons({booking: bookingRes, isPiazzaGrande: true});
        }
        // We check if the only remaining category is a donation then we delete it
        if (bookingRes.bookingRecap.categories &&
            bookingRes.bookingRecap.categories.length === 1 &&
            bookingRes.bookingRecap.categories[0].categoryType === CategoryType.Donation) {
          this.addOrDeleteDonation(true);
        }
      }


      // Send booking info if cart widget
      if (inIframe() && AppModule.isCartWidget && this.bookingRes) {
        const holdingTime = (ProductModule.product && ProductModule.product.holdingTime) || 20;
        const { bookingId, bookingToken, bookingRecap } = this.bookingRes;
        const remainingSeconds = this.timer || 0;
        const expirationDate = dayjs().add(remainingSeconds, 's').toISOString();
        const stash = {
          priceCodes: JSON.parse(JSON.stringify(NBookingModule.priceValidatedBookingCodes)),
        };
        sendCartBookingInfo({
          wId: 'cart',
          smtzOp: WidgetOps.CartInfo,
          data: {
            bookingId, bookingRecap, bookingToken,
            holdingTime: this.timerExpirationDate ? this.timerExpirationDate : expirationDate,
            membership,
            membershipCustomers,
            holdToken: holdToken || null,
            linkedRetailIds: this.linkedRetailIds,
            linkedTicketIds: this.linkedTicketIds,
            linkedTicketIdsMin: this.linkedTicketIdsMin,
            linkedTicketIdsMax: this.linkedTicketIdsMax,
            stash,
          },
        });
      }

      // update price count
      // TODO: This should happen in a mutation
      if (!price.count && price.count !== 0) {
        // make sure that the update affects vue reactivity system
        Vue.set(price, 'count', count);
      } else {
        price.count = count;
      }

      // Make sure that ticket has active selected slots
      if (!ticket.slots) {
        debug(`slots wasn't set, setting it now`);
        Vue.set(ticket, 'slots', {});
      }

      const slots = ticket.slots;
      // set slot
      if (slots[slot.id]) {
        // remove empty slots
        if (this.isSlotEmpty(slots[slot.id])) {
          delete slots[slot.id];
          debug('Removing empty slot from slots object');
        } else {
          debug(`updating slot`);
          slots[slot.id] = slot;
        }
      } else {
        debug(`Adding a new slot to ticket slots`);
        Vue.set(slots, String(slot.id), slot);
      }

      // make sure that the ticket is selected
      if (!this.tickets[ticket.categoryId]) {
        debug('Ticket wasn\'t selected. Selecting it now');
        this.setTicket(ticket);
      } else if (price.count === 0 && this.isTicketEmpty(ticket)) {
        // Deselecting empty ticket
        debug('Deselecting an empty ticket');
        this.deSelectTicket(ticket);
      }
    } finally {
      this.setLoading(false);
    }

    return;
  }

  /**
   * Adds a ticket price to our booking.
   * Takes only strings & numbers as parameters. (No objects)
   * @param payload: Add price payload
   */
  @Action({ rawError: true })
  public async addPriceLite(payload: IPriceCountLite): Promise<undefined> {
    const {
      productId, timeSlotId, ticketId,
      priceId, computedPriceId, validComputedPriceId,
      priceValue, count,
      seat, holdToken,
      seatCategoryId, mainTicket,
      packageCategoryId, packagePriceId,
      holdingTime,
    } = payload;

    const { membership, membershipCustomers } = this;

    // if we have a seating ticket
    let seatData: any = {};
    if (seat) {
      seatData = {
        type: 3,
        holdToken,
        seat,
      };
    }
    try {
      // if there is no booking, create a booking
      if (!this.bookingRes) {
        debug('No booking. Creating a new one');
        const bookingObject: IPreBookingReq = {
          productId,
          bookingLanguage: getLanguageInt(i18n.locale),
          bookingReferrer: ReferrerModule.referrer,
          ProductQt: [],
          utmChannel: ReferrerModule.utmChannel,
          utmMedium: ReferrerModule.utmMedium,
          utmCampaign: ReferrerModule.utmCampaign,
          utmSource: ReferrerModule.utmSource,
        };

        const remainingSeconds = holdingTime ? Number(holdingTime) * 60 : 600;
        // Set timer time to product.holdingTime(pass in seconds)
        this.startTimer(remainingSeconds);
        const expirationDate = dayjs().add(remainingSeconds, 's').toISOString();
        if (!this.timerExpirationDate) {
          this.setTimerExpirationDate(expirationDate);
        }

        // Add referrers data
        const { utmCampaign, utmMedium, utmSource } = ReferrerModule;
        if (utmCampaign) {
          bookingObject.utmCampaign = utmCampaign;
        }
        if (utmMedium) {
          bookingObject.utmMedium = utmMedium;
        }
        if (utmSource) {
          bookingObject.utmSource = utmSource;
        }

        const productQt: IPreBookProductQt = {
          // startDateTime: dayjs(slot.startDateTime).format('YYYY-MM-DD HH:mm:ss'),
          // endDateTime: dayjs(slot.endDateTime).format('YYYY-MM-DD HH:mm:ss'),
          timeSlot: timeSlotId,
          categoryId: seatCategoryId || ticketId,
          quantity: count,
          groupId: OrganizerModule.id,
          priceId,
          ...seatData,
        };
        // Add the following dynamic pricing properties if booking happened
        // in booking flow with computed price tickets
        if (computedPriceId) {
          productQt.computedPriceId = computedPriceId;
          productQt.priceValue = priceValue;
        }

        if (validComputedPriceId) {
          productQt.computedPriceId = validComputedPriceId;
          productQt.priceValue = priceValue;
        }

        if (mainTicket) {
          productQt.mainTicket = mainTicket;
        }
        if (packagePriceId) {
          productQt.packagePriceId = packagePriceId;
        }

        bookingObject.ProductQt.push(productQt);
        // await this.book(bookingObject);
        this.setLoading(true);
        const booking = await makeBooking(bookingObject);
        await this.setBooking(booking);
        await this.setBookingId(booking.bookingId);
        await this.setBookingToken(booking.bookingToken);

      } else {
        // We already have a booking. So update it.
        debug('We already have a booking');
        // get all ticket objects and send them to backend
        const booking = this.bookingRes;
        const selectedSlots: ISlotOrder[] = [];
        // checks whether the price was already selected or not
        // tslint:disable-next-line
        let wasPriceAdded: boolean = false;

        /*

        LEAVE COMMENTED. THIS FUNCTIONALITY WILL SEND TO THE BACKEND
        ALL THE TICKETS IN THE CURRENT BOOKING.
        WE CHANGED BECAUSE THE BACKEND WANTED US TO SEND ONLY THE NEWLY
        SELECTED TICKET PRICE.

        // get all the ticket counts from the selected tickets
        const tickets = this.selectedTickets;
        for (const iterticket of tickets) {
          const iterslots = iterticket.categoryInfo.timeslots || [];

          for (const iterslot of iterslots) {
            const prices = iterslot.export_prices || [];

            for (const iterprice of prices) {
              // skip if the price isn't the added price and
              // skip if price is not already selected
              const isAddedPrice = iterprice.priceId === price.priceId;
              if (!isAddedPrice && !iterprice.count) {
                continue;
              }

              // store that the price was added
              if (isAddedPrice) {
                debug('price was already added, updating it now');
                wasPriceAdded = isAddedPrice;
              }

              selectedSlots.push({
                categoryId: iterticket.categoryId,
                priceId: iterprice.priceId,
                // make sure that we send data for the  price not the stored one
                quantity: isAddedPrice ? count : iterprice.count,
                timeSlot: iterslot.id,
              });
            } // prices
          } // slots
        } // tickets
        */

        // make sure that the price is added if we are dealing with a non
        // selected ticket.
        const categoryId = packageCategoryId || seatCategoryId || ticketId;
        const slotOrder: ISlotOrder = {
          categoryId,
          timeSlot: timeSlotId,
          priceId,
          quantity: count,
          ...seatData,
        };

        // When dealing with dynamic pricing
        if (computedPriceId) {
          slotOrder.computedPriceId = computedPriceId;
          slotOrder.priceValue = priceValue;
        }

        if (validComputedPriceId) {
          slotOrder.computedPriceId = validComputedPriceId;
          slotOrder.priceValue = priceValue;
        }

        if (mainTicket) {
          // slotOrder.main_ticket = main_ticket;
          slotOrder.mainTicket = mainTicket;
        }

        if (packagePriceId) {
          // slotOrder.main_ticket = main_ticket;
          slotOrder.packagePriceId = packagePriceId;
        }

        if (!wasPriceAdded) {
          debug(`ticket wasn't already selected. Adding it's price`);
          selectedSlots.push(slotOrder);
          // selectedSlots.push({
          //   categoryId: seatCategoryId || ticket.categoryId,
          //   timeSlot: slot.id,
          //   priceId: price.priceId,
          //   quantity: count,
          //   ...seatData,
          // });
        }

        this.setLoading(true);
        // Add referrers data
        const { utmChannel, utmCampaign, utmMedium, utmSource, referrer } = ReferrerModule;
        const patchPayload: IPatchBookingOrderPayload = {
          bookingId: this.bookingId,
          bookingToken: this.bookingToken,
          slots: selectedSlots,
          bookingReferrer: referrer || '',
          utmChannel,
        };
        if (utmCampaign) {
          patchPayload.utmCampaign = utmCampaign;
        }
        if (utmMedium) {
          patchPayload.utmMedium = utmMedium;
        }
        if (utmSource) {
          patchPayload.utmSource = utmSource;
        }

        const bookingRes = await patchBookingOrder(patchPayload);
        this.setBooking(bookingRes);
        this.setBookingToken(bookingRes.bookingToken);
      }


      // Send booking info if cart widget
      if (inIframe() && AppModule.isCartWidget && this.bookingRes) {
        // const holdingTime = (ProductModule.product && ProductModule.product.holdingTime) || 20;
        const { bookingId, bookingToken, bookingRecap } = this.bookingRes;

        const stash = {
          priceCodes: JSON.parse(
            JSON.stringify(NBookingModule.priceValidatedBookingCodes),
          ),
        };
        sendCartBookingInfo({
          wId: 'cart',
          smtzOp: WidgetOps.CartInfo,
          data: {
            bookingId, bookingRecap, bookingToken,
            holdingTime: dayjs().add(holdingTime || 20, 'minute').toISOString(),
            membership,
            membershipCustomers,
            holdToken: holdToken || null,
            stash,
          },
        });
      }

    } finally {
      this.setLoading(false);
    }

    return;
  }

  @Action({ rawError: true })
  public async addCartPrice(payload: ICartPrice) {
    const { productIds, categoryIds, priceId, count, retailOptionId } = payload;

    // tslint:disable-next-line
    // Set the vissible categries if it exist else clear the categories
    if (categoryIds.length > 0) {
      const cats = categoryIds.join(',');
      ReferrerModule.setVisibleCategories(cats);
      setVisibleTicketsOnStorage(cats);
    } else {
      ReferrerModule.setVisibleCategories();
    }

    try {
      const filteredProductIds = productIds.filter((id) => id !== 0);
      NBookingModule.setReceivedProductIds(filteredProductIds);
      // Fetch product
      // We avoid showing a spinner if we are adding customers info
      if (this.membershipCustomers.length || retailOptionId) {
        enableQuasarStyling();
        Loading.show();
      }

      // Show spinner when the tickets are fetched only when we don't have any membership.
      if (!ReferrerModule.memType) {
        enableQuasarStyling();
        Loading.show();
      }
      if (filteredProductIds.length > 0) {
        ProductModule.fetchProducts(filteredProductIds).then((products) => {
          const product = products && products.length > 0 && products[0];
          if (product) {
              bookingFlowViewItemList(product, 0, inIframe() ? 'Iframe' : 'Flow');
            }
        });
      }

      // Fetch product categories
      if (retailOptionId) {
        await ProductModule.fetchRetail(categoryIds.map((id) => Number(id)));
      } else {
        // Fetch the Tickets
        await NBookingModule.fetchProductsTicketsWithoutDates(filteredProductIds);
        // Check if we previously have a price that requires membership settings
        // and add it's numbered price count
        // Only when selecting a single ticket like for Piazza grande
        if (categoryIds.length === 1) {
          const categoryId = Number(categoryIds[0]);
          const recapCategories = findRecapCategoriesWithMembershipSetting(Number(categoryId));
          if (recapCategories.length) {
            // make sure that verified codes are already set
            const firstRecapCategory = recapCategories[0];
            // disabling tslint shadowed priceId???
            // tslint:disable-next-line
            const priceId = firstRecapCategory.priceId;
            const priceBookingCodes = findBookingCodesForPrice({ priceId });
            if (priceBookingCodes) {
              NBookingModule.setValidatedBookingCodes(Object.values(priceBookingCodes));

              // set numbered price Count
              const ticket = NBookingModule.ticketById(categoryId);
              if (ticket) {
                const slots = ticket.categoryInfo.timeslots;

                // get the price if we have slots
                const price =
                  slots[0] &&
                  slots[0].export_prices?.find((p) => p.priceId === priceId);

                if (price) {
                  NBookingModule.addNumberBookingCodePrices([
                    {
                      price,
                      slot: slots[0],
                      count: Number(firstRecapCategory.quantity),
                      ticket,
                    },
                  ]);
                }
              }
            }
          } else {
            // make sure that we clear other selections
            NBookingModule.setValidatedBookingCodes([]);
            NBookingModule.setNumberBookingCodePrices([]);
          }
        }
        // Display the tickets
        if (categoryIds && categoryIds.length) {
          for (const categoryId of categoryIds) {
            ReferrerModule.addVisibleCategory(categoryId);
          }
        } else {
          // if no categories are selected, show all categories of the product.
          let categories: ITicket[] = [];
          productIds.forEach((id) => {
            categories = [...categories, ...this.prodsTickets[id]];
          });

          if (!categories || !categories.length) {
            return;
          }

          for (const category of categories) {
          ReferrerModule.addVisibleCategory(category.categoryId);
          }
        }
        // Stop the Loading
        Loading.hide();
        // Fetch Tickets Dates
        await NBookingModule.fetchProductsTicketsDates(filteredProductIds);
      }

    } catch (error) {
      throw error;
    } finally {
      Loading.hide();
      // TODO remove comment after BUD-8305 is tested
      // if (ReferrerModule.isMembershipOnly && this.membershipCustomers.length) {
      if (this.isCustomerBooking || retailOptionId || !ReferrerModule.memType) {
        disableQuasarStyling();
      }
    }

    // CONTINUE
  }


  @Action({ rawError: true })
  public async addTcartPrice(payload: ICartPrice) {
    try {
      const {categoryIds, productIds, retailOptionId } = payload;
      // clear the categories
      ReferrerModule.setVisibleCategories();
      enableQuasarStyling();
      Loading.show();
      if (retailOptionId) {
        await ProductModule.fetchRetail(categoryIds.map((id) => Number(id)));
      } else {
        await NBookingModule.fetchProductsTicketsWithoutDates(productIds);
      }
        // stop the loading after tickets are fetched
      Loading.hide();
      // For tcart make sure that prodsTickets have only the productIds sent from widget
      const prodsTicket = NBookingModule.prodsTickets;
      for (const key in prodsTicket) {
        if (!productIds.includes(Number(key))) {
          delete prodsTicket[key];
        }
      }
      NBookingModule.setProdsTickets(prodsTicket);
        // show the tickets before dates are fetched
      if (categoryIds && categoryIds.length > 0) {
          for (const category of categoryIds) {
            ReferrerModule.addVisibleCategory(Number(category));
          }
        }  else {
            // if no categories are selected, show all categories of the product.
            let categories: ITicket[] = [];
            productIds.forEach((id) => {
              categories = [...categories, ...this.prodsTickets[id]];
            });

            if (!categories || !categories.length) {
              return;
            }

            for (const category of categories) {
              ReferrerModule.addVisibleCategory(category.categoryId);
            }
        }
      await NBookingModule.fetchProductsTicketsDates(productIds);
    } catch (error) {
      setTimeout(() => {
        throw(error);
      }, 0);
    } finally {
      Loading.hide();
      disableQuasarStyling();
    }
  }
  /**
   * Queues an addPrice operation to be executed at a later stage
   * @param payload: Add price payload
   * @param cb: Callback to be executed once the operation is done
   */
  @Action({ rawError: true })
  public async addPriceQ(payload: IPriceCountQueue) {

    const q = this.q;
    q.push(payload);

    // skip if the queue is being processed
    if (this.processing) {
      debug('The queue is processing already');
      return;
    }

    // start processing
    this.setProcessing(true);
    let op: IPriceCountQueue | undefined = q.shift();

    while (op) {

      // Attempt to run the operation
      try {
        await this.addPrice(op.priceCount);
        op.cb(null, op.priceCount);
        debug('Processed Job from queue');
      } catch (error) {
        // We are at a failed op here
        // We don't want to set the error now, because the function
        // could throw and block our queue processing
        const operation = op;
        setTimeout(() => {
          return operation && operation.cb(error);
        }, 0);
      }

      // Our operation is over at this stage, let's move to the
      // next one.
      debug('Finished a job, proceeding to next one');
      op = q.shift();
    }

    // The queue is empty at this stage
    debug('Done processing the queue');
    this.setProcessing(false);
  }

  @Action({ rawError: true })
  public async book(data: IPreBookingReq) {
    const booking = await makeBooking(data);
    this.setBooking(booking);
  }

  @Action({ rawError: true })
  public async patchBookingUser(payload: IGuestCheckoutUser) {


    const bookingId = this.bookingRes ? (this.bookingRes as IPreBookingRes).bookingId : this.bookingId;
    const bookingToken = this.bookingRes ? (this.bookingRes as IPreBookingRes).bookingToken : this.bookingToken;

    try {
      const bookingResponse = await patchBooking(bookingId, bookingToken, payload);
      // termsOptin was removed in the patchBooking response as it is always true
      // We add it to keep data coherence
      bookingResponse.termsOptin = payload.termsOptin;
      this.setBooking(bookingResponse);
    } catch (err) {
      throw err;
    }
  }

  @Action({ rawError: true })
  public async sendPromoCode(promoCode: string) {
    const bookingRes = this.bookingRes;
    if (!bookingRes) {
      return;
    }

    const booking = await postPromoCode(this.bookingId, this.bookingToken, promoCode);
    // Updating booking recap since it's the only thing that is needed for now.
    if (this.bookingRes) {
      const bookingToken = (booking as any).goerToken;
      this.bookingRes.bookingRecap = booking.bookingRecap;
      this.bookingRes.bookingToken = bookingToken;
      this.setBookingToken(bookingToken);

      // If total === donation value we need to remove donation
      if (booking.bookingRecap && booking.bookingRecap.categories) {
        const donationTicket = booking.bookingRecap.categories.find((cat) =>
          cat.categoryType === CategoryType.Donation);
        if (donationTicket?.priceValue === booking.bookingRecap.total) {
          this.addOrDeleteDonation(true);
        }
      }
    }

    // this.setBooking(booking as any);
  }

  @Action({ rawError: true })
  public async removePromoCode(accountingIds: number[]) {
    const bookingRes = this.bookingRes;
    if (!bookingRes) {
      return;
    }
    const booking = await removePromoCode(this.bookingId, accountingIds, this.bookingToken);
    if (this.bookingRes) {
      const bookingToken = (booking as any).goerToken;
      this.bookingRes.bookingRecap = booking.bookingRecap;
      this.bookingRes.bookingToken = bookingToken;
      this.setBookingToken(bookingToken);
    }
  }

  @Action({ rawError: true })
  public async fetchBooking(payload: IServerBookingData) {
    this.setLoading(true);

    try {
      const data = await fetchServerBooking(payload.bookingId, payload.bookingToken, payload.includePackages);
      this.setBooking(data as any);
      if (!payload.includePackages) { return; }
      // We'll set the retail/tickets add-ons ids if they exist so that we retreive/save them
      const booking = data as any as IPreBookingRes;
      await this.setAddonsIds(booking);
      await this.retreiveRetailAddons();
      await this.retreiveTicketsAddons();
    } finally {
      this.setLoading(false);
    }
  }

  @Action({ rawError: true })
  public async fetchBookingPaymentStatus(payload: IServerBookingData) {
    this.setLoading(true);

    try {
      const data = await fetchServerBookingPaymentStatus(payload.bookingId, payload.bookingToken);
      this.setBookingPaymentStatus(data as IPreBookingPaymentStatusRes);
    } finally {
      this.setLoading(false);
    }
  }

  @Action({ rawError: true })
  public async gql_fetchBooking(bookingId: number) {
    this.setLoading(true);

    try {
      const data = await gql_fetchBooking(bookingId);
        // FILTER the slots (tickets)
      data.data.ProductSlotBookingRead.ProductSlotStateList
        .filter((item) => (item.BookingStatus === BookingStatus.Completed &&
        item.PaymentStatus === PaymentStatus.Completed) || (item.BookingStatus === BookingStatus.PendingInfo &&
        item.PaymentStatus === PaymentStatus.Completed) || item.BookingStatus === BookingStatus.Canceled);
      this.setBookingSummary(data);
    } finally {
      this.setLoading(false);
    }
  }

  @Action({ rawError: true })
  public async clear() {
    // const bookingRes = this.bookingRes;

    // if (bookingRes) {
    //   this.clearStoredState({bookingId: bookingRes.bookingId, bookingToken: bookingRes.bookingToken});
    // }

    this.clearBooking();
    this.stopTimer();
  }

  @Action({ rawError: true })
  public async cancelBooking() {
    if (this.bookingRes) {
      debug('Cancelling booking');
      const { bookingId, bookingToken } = this;
      return cancelBooking(bookingId, bookingToken);
    }
  }

  @Action({ rawError: true })
  public async applyMembership(): Promise<IPostMembershipData> {

    const { bookingId, bookingToken, membership } = this;
    if (!bookingId || !bookingToken || !membership) {
      throw new Error('No membership');
    }

    const response = (await applyMemDiscount({
      bookingId, bookingToken, memId: membership.id,
    }));

    this.setMembershipApplied(response.data);

    return response.data;
  }

  // We'll check the booking packages and keep their ids if they are meant to be displayed in second step
  @Action ({ rawError: true})
  public  setAddonsIds(booking: IPreBookingRes) {
    if (!booking) {
      return;
    }
    const addedPrices = booking.bookingRecap.categories?.map((t) => {
      return {
        price: t.priceId,
        timeslot: t.timeSlotId,
       };
    });
    const NewLinkedRetailIds: number[] = [];
    const NewLinkedTicketIds: number[] = [];
    const NewLinkedTicketCodes: string[] = [];
    const NewLinkedTicketPackageCategory: { [ticketId: string]: IPackageCategory } = {};

    addedPrices?.forEach((t) => {

      const packages = booking.packages;

      const timeSlotPackage = packages[t.timeslot];
      if (!timeSlotPackage) {
        return null;
      }

      const pricePackage = timeSlotPackage.package_main_prices[t.price];
      if (!pricePackage) {
        return null;
      }

      // skip if price contains no packages
      if (!Object.keys(pricePackage.package_categories).length) {
        return null;
      }

      /* Price packages with priority 2 will be displayed on a separate page
       * display_page === 2 means that the add-ons will be displayed in the second step
       * We'll build two arrays one for retails and the other one for tickets
       * And for tickets their respective min Quantity and max Quantity for booking validation
       */
      Object.keys(pricePackage.package_categories).forEach((packageCategory) => {
        const catPrices = pricePackage.package_categories[packageCategory].package_prices;
        const prices = Object.keys(catPrices);
        let minRequired = catPrices[prices[0]].minQuantity;
        let maxRequired = catPrices[prices[0]].maxQuantity * pricePackage.number_tickets;
        const maxBookable = catPrices[prices[0]].maxQuantity;
        const isMandatory = catPrices[prices[0]].isMandatory;
        const MaxQuantitySetAtTopLevel = pricePackage.max_quantity;
        const MinQuantitySetAtTopLevel = pricePackage.min_quantity;
        // If maxQuanity is set at top level we'll follow its values
        if (MaxQuantitySetAtTopLevel > 0) {
          maxRequired = MaxQuantitySetAtTopLevel;
        }
        // If minQantity is set at to level we will choose its value
        if (MinQuantitySetAtTopLevel > 0) {
          minRequired = MinQuantitySetAtTopLevel;
        }

        let categoryId = Number(packageCategory);

        if (pricePackage.package_categories[packageCategory].category_type === CategoryType.Retail
          && pricePackage.package_categories[packageCategory].display_page === 2
          && !NewLinkedRetailIds.some((p) => p === categoryId)) {
            NewLinkedRetailIds.push(categoryId);
        } else if (pricePackage.package_categories[packageCategory].category_type !== CategoryType.Retail
          && pricePackage.package_categories[packageCategory].display_page === 2
          && !NewLinkedTicketIds.some((p) => p === categoryId)
         ) {
          // If the category represent a seating plan we need to get the main seating plan category id
          // As we receive for seating plan, the id of the sub categories
          if (pricePackage.package_categories[packageCategory].category_type === CategoryType.SeatingPlan) {
            categoryId = Number(pricePackage.package_categories[packageCategory].seating_main_sub_product_id);
            NewLinkedTicketIds.push(categoryId);
          } else {
            NewLinkedTicketIds.push(categoryId);
          }
          NewLinkedTicketPackageCategory[categoryId] = {
            ...pricePackage.package_categories[packageCategory],
            mainTimeSlotId: t.timeslot,
          };
          const code = pricePackage.package_categories[packageCategory].category_code;
          if (code && !NewLinkedTicketCodes.some((c) => c === code)) {
            NewLinkedTicketCodes.push(code);
          }
        }
        // If price is mandatory we'll add Min and max required number to check
        if (isMandatory) {
          this.addAddonTicketsMinRequired({id: String(categoryId), minRequired});
          this.addAddonTicketsMaxRequired({id: String(categoryId), maxRequired});
          this.remPackageError(String(categoryId));
        }
        this.addAddonTicketsMaxBookable({id: String(categoryId), maxBookable});
        const mainTicket = `${t.timeslot}:${catPrices[prices[0]].mainPriceId}`;
        const packagePriceId = catPrices[prices[0]].id;
        const priceId = catPrices[prices[0]].priceId;
        // We use package price informations to link retails addon with the main ticket
        if (catPrices[prices[0]].displayPage === 2) {
          this.addAddonsSecondStepInfo({
            mainTicket,
            packagePriceId,
            priceId,
            categoryId,
            included: catPrices[prices[0]].displayedValue === 0 ? true : false,
            displayedValue: Math.max(catPrices[prices[0]].displayedValue, 0),
            MaxQuantitySetAtTopLevel: MaxQuantitySetAtTopLevel ? MaxQuantitySetAtTopLevel : null,
          });
        }
      });

      let index = 0;
      const NewLinkedTicketIdsMin: any[] = [];
      NewLinkedTicketIds.forEach((id) => {
          NewLinkedTicketIdsMin[index] = this.addonTicketsMinRequired[id] ? this.addonTicketsMinRequired[id] : 0;
          index++;
      });
      index = 0;
      const NewLinkedTicketIdsMax: any[] = [];
      NewLinkedTicketIds.forEach((id) => {
          NewLinkedTicketIdsMax[index] = this.addonTicketsMaxRequired[id] ? this.addonTicketsMaxRequired[id] : 0;
          index++;
      });
      this.setLinkedRetailIds(NewLinkedRetailIds);
      this.setLinkedTicketIds(NewLinkedTicketIds);
      this.setLinkedTicketCodes(NewLinkedTicketCodes);
      this.setLinkedTicketPackageCategory(NewLinkedTicketPackageCategory);
      this.setLinkedTicketIdsMin(NewLinkedTicketIdsMin);
      this.setLinkedTicketIdsMax(NewLinkedTicketIdsMax);
    });
  }

  // We'll get the shows (seating plan addons) and fetch them
  @Action ({ rawError: true})
  public  async setShowsAddons(payload: {booking: IPreBookingRes, isPiazzaGrande?: boolean}) {
    if (!payload.booking) {
      return;
    }
    const addedPrices = payload.booking.bookingRecap.categories?.map((t) => {
      return {
        price: t.priceId,
        timeslot: t.timeSlotId,
       };
    });
    const linkedShowsIds: number[] = [];
    // Shows (tickets) allowed prices
    const linkedTicketsAllowedPrices: TicketsAllowedPrices = {};
    const linkedTicketsShowsPrices: TicketsShowsPrices = {};

    if (!addedPrices) {
      ProductModule.setAddonTicketsShowsPrices({});
      if (!payload.isPiazzaGrande) {
        ProductModule.setShowsAddons([]);
      } else {
        ProductModule.setPizzaShowsAddons([]);
        ProductModule.setRequiredNumberPiazzaSeats(0);
        ProductModule.setRequiredNumberPiazzaSeatsPerCategory([]);
      }
      return;
    }

    addedPrices?.forEach((t) => {

      const packages = payload.booking.packages;

      const timeSlotPackage = packages[t.timeslot];
      if (!timeSlotPackage) {
        ProductModule.setAddonTicketsShowsPrices({});
        if (!payload.isPiazzaGrande) {
          ProductModule.setShowsAddons([]);
        } else {
          ProductModule.setPizzaShowsAddons([]);
          ProductModule.setRequiredNumberPiazzaSeats(0);
          ProductModule.setRequiredNumberPiazzaSeatsPerCategory([]);
        }
        return;
      }

      const pricePackage = timeSlotPackage.package_main_prices[t.price];
      if (!pricePackage) {
        ProductModule.setAddonTicketsShowsPrices({});
        if (!payload.isPiazzaGrande) {
          ProductModule.setShowsAddons([]);
        } else {
          ProductModule.setPizzaShowsAddons([]);
          ProductModule.setRequiredNumberPiazzaSeats(0);
          ProductModule.setRequiredNumberPiazzaSeatsPerCategory([]);
        }
        return;
      }

      // skip if price contains no packages
      if (!Object.keys(pricePackage.package_categories).length) {
        ProductModule.setAddonTicketsShowsPrices({});
        if (!payload.isPiazzaGrande) {
          ProductModule.setShowsAddons([]);
        } else {
          ProductModule.setPizzaShowsAddons([]);
          ProductModule.setRequiredNumberPiazzaSeats(0);
          ProductModule.setRequiredNumberPiazzaSeatsPerCategory([]);
        }
        return;
      }

      Object.keys(pricePackage.package_categories).forEach((packageCategoryId) => {
        if (pricePackage.package_categories[packageCategoryId].category_type === CategoryType.SeatingPlan) {
          const packageCategory = pricePackage.package_categories[packageCategoryId];
          const categoryId = packageCategory.seating_main_sub_product_id;
          if (!linkedShowsIds.some((p) => p === Number(packageCategoryId))) {
            linkedShowsIds.push(Number(categoryId));
          }

          // We save price package to be used when we buy show price as an addon-price, display addon price ...
          linkedTicketsAllowedPrices[Number(categoryId)] =
            Object.values(packageCategory.package_prices)
              .map((packagePrice) => Number(packagePrice.priceId));
          if (!linkedTicketsShowsPrices[Number(categoryId)]) {
            linkedTicketsShowsPrices[Number(categoryId)] = Object.values(packageCategory.package_prices)
            .map((packagePrice) => {
              return {
                ...packagePrice,
                mainTimeSlotId: t.timeslot, // MaintimeSlotId is added to be used when buying an addon price
                number_tickets: pricePackage.number_tickets };
            });
          } else {
            const newLinkedPrices = Object.values(packageCategory.package_prices).map((packagePrice) => {
              return {
                ...packagePrice,
                mainTimeSlotId: t.timeslot, // MaintimeSlotId is added to be used when buying an addon price
                number_tickets: pricePackage.number_tickets };
            });
            linkedTicketsShowsPrices[Number(categoryId)] =
              linkedTicketsShowsPrices[Number(categoryId)].concat(newLinkedPrices);
            }
        }
      });
    });
    ProductModule.setAddonTicketsShowsPrices(linkedTicketsShowsPrices);
    if (payload.isPiazzaGrande) {
      await ProductModule.fetchTicketsAddon({ids: linkedShowsIds, isPiazzaAddon: true});

      // Add mainCategoryId to each object
      const allTicketPrices = Object.entries(linkedTicketsShowsPrices)
      .flatMap(([mainCategoryId, ticketPrices]) =>
          ticketPrices.map((ticketPrice: any) => ({
              ...ticketPrice,
              mainCategoryId: Number(mainCategoryId),
          })),
      );

      // Calculate total number of seats required for all tickets
      const requiredPiazzaSeats = allTicketPrices.reduce((total, price) => {
          return total + price.number_tickets;
      }, 0);

      // Create an array of objects where each object represents categoryId and maxSeats
      const requiredNumberPiazzaSeatsPerCategory = allTicketPrices.map((price: any) => ({
          categoryId: price.mainCategoryId,
          maxSeats: price.number_tickets,
      }));

      // Aggregate the seats per category
      const seatsPerCategory = allTicketPrices.reduce<Record<number, number>>((acc, price) => {
        const { mainCategoryId, number_tickets } = price;
        if (!acc[mainCategoryId]) {
          acc[mainCategoryId] = 0;
        }
        acc[mainCategoryId] += number_tickets;
        return acc;
      }, {});

      // Convert the aggregated object to an array of objects
      const aggregatedSeatsPerCategory = Object.entries(seatsPerCategory).map(([categoryId, maxSeats]) => ({
        categoryId: Number(categoryId),
        maxSeats: maxSeats as number,
      }));


      ProductModule.setRequiredNumberPiazzaSeats(requiredPiazzaSeats);
      ProductModule.setRequiredNumberPiazzaSeatsPerCategory(aggregatedSeatsPerCategory);
      return;
    }
    await ProductModule.fetchTicketsAddon({ids: linkedShowsIds, isShowAddons: true});
  }

  @Action({ rawError: true })
  public async retreiveRetailAddons() {
    if (this.linkedRetailIds.length === 0) {
      return;
    }

    await ProductModule.fetchRetailAddon(this.linkedRetailIds);
  }

  @Action({ rawError: true })
  public async retreiveTicketsAddons() {
    if (this.linkedTicketIds.length === 0) {
      return;
    }

    await ProductModule.fetchTicketsAddon({ids: this.linkedTicketIds, codes: this.linkedTicketCodes});
  }

  @Action({ rawError: true })
  public async addRemoveShippingTicket(count: number = 0) {
    const isShippingTicketAdded = this.bookingRes ?
      this.bookingRes.bookingRecap.categories?.some((t) => t.isShipping === true) : false;

    const isRetailInBasket = this.bookingRes ?
      this.bookingRes.bookingRecap.categories?.some((t) => t.retailOptionId != null) : false;

     /**
      *
      * First and Main condition to add/remove a shipping ticket is
      * if we fetched shippingTickets related to a specific group
      *
      * Second condition => If a Retail was added and no shipping ticket was added yet (we add it)
      *
      * third one => If there is no retail in basket it means that we deleted all retails
      * and we have a shipping ticket (we remove it)
      *
      */

    if ((this.shippingTickets.length > 0 || this.dynamicShippingTickets.length > 0) &&
      (isRetailInBasket && !isShippingTicketAdded) ||
      (!isRetailInBasket && isShippingTicketAdded)) {
      const shippingTicket =
        this.dynamicShippingTickets.length > 0 ? this.dynamicShippingTickets[0] : this.shippingTickets[0];
      const shippingCategoryId = shippingTicket.categoryId;
      const slotOrder: ISlotOrder = {
        categoryId: shippingCategoryId,
        timeSlot: shippingTicket.timeslots[0].id,
        priceId: shippingTicket.price[0].priceId,
        quantity: count === 0 ? 0 : 1, // We need to add only ONE shipping-ticket if we buy retail(s)
        groupId: OrganizerModule.id != null ? OrganizerModule.id : undefined,
      };

      const patchPayload: IPatchBookingOrderPayload = {
        bookingId: this.bookingId,
        bookingToken: this.bookingToken,
        slots: [slotOrder],
        bookingReferrer: ReferrerModule.referrer,
        utmChannel: ReferrerModule.utmChannel,
        utmMedium: ReferrerModule.utmMedium,
        utmCampaign: ReferrerModule.utmCampaign,
        utmSource: ReferrerModule.utmSource,
      };

      const bookingRes = await patchBookingOrder(patchPayload);
      this.setBooking(bookingRes);
      this.setBookingToken(bookingRes.bookingToken);
    }
  }

  @Action({ rawError: true })
  public async updateDynamicShippingTicket(payload: { price: number, rateToken: string }) {

      const shippingTicket = this.dynamicShippingTickets[0];
      const shippingCategoryId = shippingTicket.categoryId;
      const slotOrder: ISlotOrder = {
        categoryId: shippingCategoryId,
        timeSlot: shippingTicket.timeslots[0].id,
        priceId: shippingTicket.price[0].priceId,
        quantity: 1,
        groupId: OrganizerModule.id != null ? OrganizerModule.id : undefined,
        priceValue: payload.price,
        rateToken: payload.rateToken,
      };

      const patchPayload: IPatchBookingOrderPayload = {
        bookingId: this.bookingId,
        bookingToken: this.bookingToken,
        slots: [slotOrder],
        bookingReferrer: ReferrerModule.referrer,
        utmChannel: ReferrerModule.utmChannel,
        utmMedium: ReferrerModule.utmMedium,
        utmCampaign: ReferrerModule.utmCampaign,
        utmSource: ReferrerModule.utmSource,
      };

      const bookingRes = await patchBookingOrder(patchPayload);
      this.setBooking(bookingRes);
      this.setBookingToken(bookingRes.bookingToken);
}

@Action({ rawError: true })
  public async deleteDynamicShippingTicket() {

    const isDynamicShippingTicketAdded = this.bookingRes ?
      this.bookingRes.bookingRecap.categories?.some((t) => t.isShipping === true && t.priceValue > 1) : false;

    if (isDynamicShippingTicketAdded && this.dynamicShippingTickets.length > 0) {
      const shippingTicket = this.dynamicShippingTickets[0];
      const shippingCategoryId = shippingTicket.categoryId;
      const slotOrder: ISlotOrder = {
        categoryId: shippingCategoryId,
        timeSlot: shippingTicket.timeslots[0].id,
        priceId: shippingTicket.price[0].priceId,
        quantity: 0,
        groupId: OrganizerModule.id != null ? OrganizerModule.id : undefined,
      };

      const patchPayload: IPatchBookingOrderPayload = {
        bookingId: this.bookingId,
        bookingToken: this.bookingToken,
        slots: [slotOrder],
        bookingReferrer: ReferrerModule.referrer,
        utmChannel: ReferrerModule.utmChannel,
        utmMedium: ReferrerModule.utmMedium,
        utmCampaign: ReferrerModule.utmCampaign,
        utmSource: ReferrerModule.utmSource,
      };

      const bookingRes = await patchBookingOrder(patchPayload);
      this.setBooking(bookingRes);
      this.setBookingToken(bookingRes.bookingToken);
    }
  }

  @Action({ rawError: true })
  public async addOrDeleteDonation(deleteDonation: boolean = false) {
    if (ProductModule.donations.length > 0) {
      const donation = ProductModule.donations[0]; // For now we support only one donation
      const { utmChannel, utmCampaign, utmMedium, utmSource, referrer } = ReferrerModule;
      const patchDonationPayload: IPatchBookingOrderDonationPayload = {
        bookingId: this.bookingId,
        bookingToken: this.bookingToken,
        slots: [
          {
            categoryId: Number(donation.donationInfo?.categoryId),
            quantity: deleteDonation ? 0 : 1,
          },
        ],
        bookingReferrer: referrer || '',
        utmChannel,
      };
      if (utmCampaign) {
        patchDonationPayload.utmCampaign = utmCampaign;
      }
      if (utmMedium) {
        patchDonationPayload.utmMedium = utmMedium;
      }
      if (utmSource) {
        patchDonationPayload.utmSource = utmSource;
      }

      const bookingDonation = await patchBookingOrder(patchDonationPayload);
      this.setBooking(bookingDonation);
      this.setBookingToken(bookingDonation.bookingToken);

      // Send booking info if cart widget
      if (AppModule.isCartWidget) {
        sendCartBookingInfo(getCartBookingInfo());
      }
    }
  }

}

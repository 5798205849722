



































































import dayjs from 'dayjs';
import { Vue, Component, Watch } from 'vue-property-decorator';
import Timeout from 'await-timeout/dist/es5';
import GroupTickets from './components/GroupTickets.vue';
import GroupDate from './components/GroupDate.vue';
import NewButton from '@/components/presentational/NewButton.vue';
import { IDayTicketSlots } from '@/models/store/gbooking';
import { IPriceCount, ITimeslotsEntity } from '@/models/store/booking';
import { fetchTickets, fetchTicketSlots } from '@/api/booking';
import Debug from 'debug';
import { EVCalendar } from '@/models/events';
import { NBookingModule, GBookingModule, BookingModule, ProductModule, ReferrerModule } from '@/utils/storemodules';
import { FETCH_PRODUCTS } from '@/store/modules/booking/constants';
import { Product } from '@/models/store/bookingClasses';
import { inIframe, openWidget, setWidgetHeight } from '@/utils/iframe';
import { getPrimaryColor } from '@/utils/colors';
import { hideScrollBar, showScrollBar } from '@/utils/styles';
const debug = new Debug('smeetz:gbooking');

@Component({
  components: {
    GroupTickets,
    GroupDate,
    NewButton,
  },
})
export default class GroupBooking extends Vue {
  @BookingModule.Action(FETCH_PRODUCTS) private fetchProducts!: any;

  private EVCalendar = EVCalendar;
  private currentMonth = '';
  private ticketsId: string[] = [];
  private productId: null | number = null;
  private daysTicketsSlots: IDayTicketSlots = {};
  private openDate: Date | null = null;
  // private product: IProduct | null = null;

  private loading = false;
  private bLoading = false;

  get total() {
    return GBookingModule.total;
  }

  get canBook() {
    return GBookingModule.canBook;
  }

  get currency() {
    if (!this.product) {
      return '';
    }

    return this.product.overview.rangePrice.fromPriceCurrency;
  }

  get product() {
    return ProductModule.product;
  }

  @Watch('currentMonth')
  private async watchMonthChange(month: string) {
    debug('On month change');
    if (!this.productId || !this.ticketsId.length) {
      return;
    }
    this.daysTicketsSlots = {};
    this.loading = true;
    // await NBookingModule.fetchTickets(Number(this.$route.params.id));
    const from = dayjs(`${month}-01`);
    const daysOfMonth = from.daysInMonth();
    // debug('days in month are', daysOfMonth);
    // const to = dayjs(`${month}-${daysOfMonth}`);
    const to = from.add(1, 'month');

    const timeSlotsPromises: Array<Promise<ITimeslotsEntity[]>> = [];
    // fetch slots for current month
    for (const ticketId of this.ticketsId) {
      timeSlotsPromises.push(fetchTicketSlots({
        productId: this.productId,
        categoryId: Number(ticketId),
        from: from.format('YYYY-MM-DD'),
        to: to.format('YYYY-MM-DD'),
      }));
    }

    const ticketSlots = await Promise.all(timeSlotsPromises);
    GBookingModule.setTimeSlots(ticketSlots);

    for (let i = 0; i < this.ticketsId.length; i++) {
      const ticketId = this.ticketsId[i];
      for (const slot of ticketSlots[i]) {
        const day = dayjs(slot.startDateTime);
        const dayString = day.format('YYYY-MM-DD');
        let dayTicketsSlots  = this.daysTicketsSlots[dayString];
        if (!dayTicketsSlots) {
          this.daysTicketsSlots[dayString] = {};
          dayTicketsSlots  = this.daysTicketsSlots[dayString];
        }
        let dayTimeSlots = dayTicketsSlots[slot.startDateTime];
        if (!dayTimeSlots) {
          dayTicketsSlots[slot.startDateTime] = {};
          dayTimeSlots = dayTicketsSlots[slot.startDateTime];
        }
        let dayTicketTimeSlots = dayTimeSlots[ticketId];
        if (!dayTicketTimeSlots) {
          dayTimeSlots[ticketId] = [];
          dayTicketTimeSlots = dayTimeSlots[ticketId];
        }

        dayTicketTimeSlots.push(slot);
      }
    }

    GBookingModule.setDayTicketSlots({...this.daysTicketsSlots});
    this.loading = false;

    // delay a bit to give time for new elements to be rendered
    await Timeout.set(300);
    setWidgetHeight();
    // group slots by day { startDateTime: { ticketId: }}
  }

  private async mounted() {
    hideScrollBar();
    const params = this.$route.params;
    const query = this.$route.query;
    this.productId = Number(params.id);
    const ticketIds = query.cats;

    // minimum day from which we show bookings
    const minDay  = ReferrerModule.mFrom ? dayjs(ReferrerModule.mFrom) : dayjs();
    // fetch Tickets
    // await NBookingModule.fetchTickets(this.productId);
    await NBookingModule.fetchProductsTickets([this.productId]);
    if (!ticketIds) {
      const tickets = NBookingModule.fetchedTickets;
      if (tickets) {
        for (const ticket of tickets) {
          this.ticketsId.push(String(ticket.categoryId));
        }
      }
      // return;
    } else {
      this.ticketsId = ticketIds.toString().split(',');
    }

    if (this.ticketsId.length) {
      for (const ticketId of this.ticketsId) {
        const ticket = NBookingModule.ticketById(Number(ticketId));
        if (ticket && (ticket.categoryInfo.onlineStatus === 'available_for_sales')) {
          const firstDate = ticket.categoryInfo.dates.filter((d) => {
            // Make sure that min day is not before today
            return !dayjs(d.startDate).isBefore(minDay.subtract(1, 'd'));
          })[0];
          if (firstDate && (firstDate.publicCount === '1')) {
            debug('First date is', firstDate);
            const day = dayjs(firstDate.startDate);
            GBookingModule.setDate(day.toDate());
            this.currentMonth = day.format('YYYY-MM');
            break;
          }
        }
      }
    }

    // set month
    if (!this.currentMonth) {
      this.currentMonth = minDay.format('YYYY-MM');
    }

    this.openDate = dayjs(`${this.currentMonth}-01`).toDate();

    const products = await this.fetchProducts([this.productId]);
    const product = products[0];
    if (product) {
      ProductModule.fetchProduct(product.short_url);
    }
  }

  private beforeDestroy() {
    showScrollBar();
  }

  private book() {
    const fetchedTickets = NBookingModule.fetchedTickets;
    const product = this.product;
    const ticketCounts = GBookingModule.ticketCounts;
    const ticketSlots = GBookingModule.ticketSlots;
    const startDateTime = GBookingModule.getChosenTime;

    let callBacksCount = 0;
    if (!ticketSlots) {
      debug('No ticket slots');
      return;
    }

    // We should get all the prices & ticket info needed to call 'addPriceQ' method.
    // It would have been great to combine all the prices to gether in one call but backend
    // has dropped support for that & addPriceQ already queues calls so that they are run one
    // at a time.
    for (const ticketId in ticketCounts) {
      if (!ticketId) {
        continue;
      }

      const ticketCount = ticketCounts[ticketId];
      const ticket = fetchedTickets.filter((tick) => tick.categoryId === Number(ticketId))[0];
      if (!ticket) {
        debug('No ticket');
        continue;
      }

      for (const priceId in ticketCount.prices) {

        if (!priceId) {
          continue;
        }
        const price = ticketCount.prices[priceId];
        // const count = ticketCount.count;
        const count = price.count;
        const slots = ticketSlots[ticketId];
        const slot = slots.filter((sl) => sl.startDateTime === startDateTime )[0];
        if (!slot) {
          debug('No slot');
          continue;
        }

        // Ticket is no longer limited to 1 price. So, we should get the price that we are
        // targetting.
        const exportedPrice = slot.export_prices && slot.export_prices.find((p) => p.priceId === price.price.priceId);
        if (!exportedPrice) {
          debug('No price');
          continue;
        }

        // Poupulcate the addPriceQ parameters
        const priceCount: IPriceCount = {
          count,
          ticket,
          slot,
          price: exportedPrice,
        };

        this.bLoading = true;
        callBacksCount++;
        NBookingModule.addPriceQ({
          priceCount,
          cb: (err) => {
            if (err) {
              throw err;
            }
            callBacksCount--;
            if (callBacksCount === 0) {
              NBookingModule.stepForward();
              if (inIframe()) {
                this.openIframe();
              } else {
                this.goToBooking();
              }
              this.bLoading = false;
              GBookingModule.setTicketCounts({});
              debug('Done with booking');
            }
          },
        });
      }
    }
  }

  private onMonthChange(month: string) {
    this.currentMonth = month;
  }

  private goToBooking() {
    const product = this.product;
    if (!product) {
      return;
    }
    const id = product.id;
    const short_url = product.short_url;
    this.$router.replace(`/product/${id}/booking/${short_url}`);
  }

  private openIframe() {
    const {bookingId, bookingToken} = NBookingModule;

    // @ts-ignore
    openWidget(['openwidget', 1 * new Date(), {
      productId: `${this.productId}`,
      bookingId,
      bookingToken,
      lightbox: true,
      listView: false,
      ocol: getPrimaryColor(),
    }]);
  }
}










































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { AuthModule, AppModule, ProductModule, OrganizerModule, NBookingModule } from '@/utils/storemodules';
import { inIframe } from '../../utils/iframe';
import dayjs from 'dayjs';
import { Mutation } from 'vuex-class';
import { EPaymentsLogos } from '@/config/constants';
import { CategoryType, ChargeFlowTypes } from '@/models/enums';

@Component({})
export default class NewBookingFooter extends Vue {
  // private organizerName: string = 'Smeetz SA © 2020';
  private langMenuOpen: boolean = false;

  // private socials = [
  //   {
  //     site: 'instagram',
  //     link: 'https://www.instagram.com/smeetz.io/',
  //     img: `img/rasterized/icons/instagram_new.svg`,
  //   },
  //   {
  //     site: 'facebook',
  //     link: 'https://www.facebook.com/smeetz.io',
  //     img: `img/rasterized/icons/Facebook_new.svg`,
  //   },
  //   {
  //     site: 'linkedin',
  //     link: 'https://www.linkedin.com/company/smeetz-sa/',
  //     img: `img/rasterized/icons/LinkedIn_new.svg`,
  //   },
  // ];

  // Some organisers want a different name on the footer,
  // this is a hack while we develop that feature on b2b
  private altName = {
    'the-jump-spot': 'Royalty Entertainment SA',
    'dev-smeetz-7510': 'Smeetz Developers Inc',
  };

  get socials() {
    return [
      {
        site: 'instagram',
        link: 'https://www.instagram.com/smeetz.io/',
        img: `img/rasterized/icons/instagram_new.svg`,
      },
      {
        site: 'facebook',
        link: 'https://www.facebook.com/smeetz.io',
        img: `img/rasterized/icons/Facebook_new.svg`,
      },
      {
        site: 'linkedin',
        link: 'https://www.linkedin.com/company/smeetz-sa/',
        img: `img/rasterized/icons/LinkedIn_new.svg`,
      },
    ];
  }

  get hasRequestToBookCategory(): boolean {
     const delayedChargeFlowTypes = [
      ChargeFlowTypes.ChargeFlowTypeAuthorized,
      ChargeFlowTypes.ChargeFlowTypeTokenized,
      ChargeFlowTypes.ChargeFlowTypeTokenizedDeliver,
    ];

     const filteredTickets = NBookingModule.filteredTickets.filter((ticket) => {
      return ticket.type !== CategoryType.Retail;
    });

    // Check if we fetched a category that requires confirmation
     const categoryThatRequiresConfirmation = filteredTickets.find((cat) =>
      delayedChargeFlowTypes.includes(Number(cat.chargeFlowType)));

     if (categoryThatRequiresConfirmation) {
      return true;
    }

     return false;
  }

  private get isMobile() {
    return AppModule.isTouch;
  }

  private get organizerName() {
    let owner = '';
    if (AppModule.showSmeetzMarketing) {
      owner = 'Smeetz SA';
    } else {
      owner = ProductModule.organiserName;
    }

    const altName = (this.altName as any)[ProductModule.organiserSlug];
    if (altName && !AppModule.showSmeetzMarketing) {
      owner = altName;
    }

    return `${owner} © ${dayjs().format('YYYY')}`;
  }

  private get paymentsLogos(): string[] {
    if (this.hasRequestToBookCategory) {
      const PaymentMethodsNotAllowed = {
        TWINT: 'TWINT',
        PAYPAL: 'PAYPAL',
      } as const;
      const disableRequestToBook = (card: string) =>
        card !== EPaymentsLogos[PaymentMethodsNotAllowed.PAYPAL] &&
        card !== EPaymentsLogos[PaymentMethodsNotAllowed.TWINT];
      return OrganizerModule.paymentsLogos.filter(disableRequestToBook);
    }
    return OrganizerModule.paymentsLogos;
  }

  private get organizerEmail(): string {
    const organizerInfo = NBookingModule.getOrganizerInfo;
    return organizerInfo?.branding?.emailAddress || 'support@smeetz.com';
  }

  private get organizerInfoForAdyen() {
    return OrganizerModule.getOrganizerInfoForAdyen;
  }

  private get isIframe() {
    return inIframe();
  }

  private get adyenAccountHolder() {
    return OrganizerModule.getOrganizerInfoForAdyen?.brandingList[0];
  }

  private goToLanding() {
    if (inIframe()) {
      return;
    }

    this.$router.push('/');
  }


}











import dayjs from 'dayjs';
import { Vue, Component, Watch } from 'vue-property-decorator';
import Timeout from 'await-timeout/dist/es5';
import Debug from 'debug';
// import NewButton from '@/components/presentational/NewButton.vue';
import Products from '@/components/product/Products.vue';
import { ProductsModule } from '@/utils/storemodules';
import { inIframe, setWidgetHeight } from '@/utils/iframe';
import { setEmbedableWidgetStyles, unsetEmbedableWidgetStyles } from '@/utils/styles';
import { AppModule } from '@/utils/storemodules';
import { getProductsTickets } from '@/api/product';
import { ProductTicket } from '@/types/gql/product';
import { PSCCancelStatus, TimeslotBookable } from '@/models/enums';
const debug = new Debug('smeetz:cproducts');

@Component({
  components: {
    Products,
  },
})
export default class CalendarProducts extends Vue {

  // display with Month controls or as list
  private dis: 'm' | 'l' = 'm';
  private productTickets: ProductTicket[] = [];
  private showTickets: boolean = false;
  private productsIds: number[] = [];
  get activities() {
    const products = ProductsModule.products.filter((product) => this.productsIds.includes(Number(product.id)));

    // generate products for each ticket if we are showing each ticket as product
    if (this.showTickets) {
      // Don't show cancelled tickets
      const now = dayjs();
      const filteredTickets = this.productTickets.filter((t) => t.cancelled !== PSCCancelStatus.Cancelled);
      return filteredTickets.map((productTicket) => {

        // Keep only bookable timeslots
        const ts =
          productTicket?.timeSlot?.
            filter((t) => t && (t?.bookable === TimeslotBookable.Bookable) && (dayjs(t.end || '').isAfter(now)));
        if (!ts) {
          return productTicket;
        }

        // Use the only bookable timeslots.
        // For the future, it would be better to create an activity for each bookable timeslot
        const slot = ts[0];
        const product = products.find((p) => Number(p.id) === productTicket.productId);
        return Object.assign({}, product, {
          dates: slot?.start,
          date: slot?.start,
          categoryId: productTicket.id,
        });
      });
    }
    return products;
  }

  get width() {
    return AppModule.width;
  }

  @Watch('width')
  private onWidthChange() {
    setWidgetHeight('smtz-p-widget');
  }

  private async created() {
    if (inIframe()) {
      setEmbedableWidgetStyles();
    }
    const groupId = this.$route.params.groupId;
    const { limit, dis } = this.$route.query;

    if (dis === 'l') {
      this.dis = dis;
    }

    ProductsModule.setOptions({
      andFilters: [{ dates: { from: dayjs().toISOString() } }, {organiser_id: groupId}],
      sort: 'dates',
      sortDirection: 'asc',
      searchFields: { organiser_id: {} },
      query: groupId,
      limit: limit ? Number(limit) : 50,
    });

    await ProductsModule.search();
    // 19377 : spanis client
    // 18636 : murten client
    // 16438 : group on dev for testing
    const groupIds = [19377, 18636];
    const {prods} = this.$route.query;

    if (prods) {
      this.productsIds = (prods as string).split(',').map((id) => Number(id));
    } else {
      this.productsIds = ProductsModule.products.map((p) => Number(p.id));
    }

    // Generate tickets as products only for allowed groupIds
    if (this.productsIds.length && (groupIds.includes(Number(groupId)))) {
      this.showTickets = true;
      this.productTickets = await getProductsTickets(this.productsIds);
    }
    await Timeout.set(1500);
    setWidgetHeight('smtz-p-widget');
  }

  private beforeDestroy() {
    if (inIframe()) {
      unsetEmbedableWidgetStyles();
    }
  }
}





















import DateHelperMixin from '@/mixins/DateHelper';
import { IGQLProductSlotState } from '@/models/store/booking';
import { NBookingModule, RBookingModule } from '@/utils/storemodules';
import dayjs from 'dayjs';
import { Vue, Component, Prop, Mixins } from 'vue-property-decorator';
@Component
  export default class TicketsCard extends Mixins<DateHelperMixin>(
  DateHelperMixin,
)  {
  private get subTotal() {
    let subTotal = 0;
    subTotal = this.slotsGrouped.map((item: IGQLProductSlotState) => item.PriceValue)
          .reduce((a: number, b: number) => a + b, 0);
    return subTotal / 100;
  }

  private get currency() {
    return NBookingModule.bookingSummary?.data.ProductSlotBookingRead.Currency;
  }
  private get selected() {
    const slotsIds = this.slotsGrouped.map((item: IGQLProductSlotState) => item.Id);
    return !!RBookingModule.selectedSlots.filter((item) => slotsIds.includes(item.Id)).length;
  }
  @Prop({ default: {} }) private slotsGrouped!: any;

  private translateDate(date: string, onlyDate: boolean = true) {
    return this.currentDate(date, onlyDate);
  }

  private getTime(date: string) {
    const momentDate = dayjs(date);
    return this.formatTime(momentDate.format('HH:mm'));
  }

  private formatPriceName(slotsGrouped: any) {
    return `${slotsGrouped[0].PriceName} ${String(this.$t('user-space.booking-label-ticket'))}`;
  }

  private selectTickets(slotsGrouped: IGQLProductSlotState[]) {
    if (!this.selected) {
      RBookingModule.setCurrentSlots({selectedSlots: slotsGrouped, add: true});
    } else {
      RBookingModule.setCurrentSlots({selectedSlots: slotsGrouped, add: false});
    }
  }

}

import Debug from 'debug';
import { NBookingModule, OrganizerModule, ReferrerModule } from './storemodules';
import { StepName } from '@/store/modules/newBooking/constants';

export default Debug('smeetz:*');

export const debugGradient = Debug('smeetz:gradient');

/**
 * Adds indicator information on which client an error is happening
 */
export function decorateErrorMessage(message: string): string {

  return `Client: ${OrganizerModule.id}, ${OrganizerModule.orgName}, ${ReferrerModule.referrer}\n`
  + `${message}`;
}

/**
 * Adds indicator information about booking errors
 */
export function bookingDecorateErrorMessage(message: string, moreInfo?: string): string {

  return `${message}\n`
  + (NBookingModule.bookingId === 0 ? `Booking Id not set yet\n` : `Booking Id: ${NBookingModule.bookingId}\n`)
  + `Booking Step: ${StepName[NBookingModule.bookingStep]}\n`
  + `Client: ${OrganizerModule.id}, ${OrganizerModule.orgName}, ${ReferrerModule.referrer}\n`
  + (OrganizerModule.getInfo != null ? `Payment processor : ${OrganizerModule.getInfo.groupInfo.paymentProcessor}\n` : ``)
  + (moreInfo !== undefined ? `${moreInfo}\n` : ``);
}


import VueRouter, { Route } from 'vue-router';
import i18n from '@/locales';
import { extractUserInfoFromSmtzToken } from '@/utils/token';
import { NBookingModule, UserModule } from './storemodules';
import { IOrgUserData } from '@/models/definitions';
import { getUserMembershipsBookingCodes } from './membership';


// Checks whether route requires navigation
export function isAuthRoute(route: Route, router: VueRouter) {
  const name = route.name;
  return isAuthRouteHelper(name || '');
}

export function isAuthRouteHelper(name: string) {
  return ['user'].includes(name);
}

export function goToHome(router: VueRouter) {
  const locale = i18n.locale;
  router.push(`/${locale}/`);
}

/*Helper function that logs in organiser users using a custom auth*/
export async function handelUserAuthentification(data: {accessToken: string}) {
  const smtzAccessToken = data.accessToken;
  const info = extractUserInfoFromSmtzToken(smtzAccessToken);
  // login user in the b2c
  const emailAddress = info && info.email;
  const firstName = info && info.firstName;
  const lastName = info && info.lastName;

  const userData: IOrgUserData = {
    firstName: firstName || '',
    lastName: lastName || '',
    fullname: firstName + ' ' + lastName,
    emails: [{emailAddress}],
  };

  // Fetch user membership booking codes
  if (smtzAccessToken) {
    await getUserMembershipsBookingCodes(smtzAccessToken);
    NBookingModule.setShowLoginView(false);
  }

  UserModule.setOrgUser(userData);
  UserModule.setCustomAuthToken(smtzAccessToken);
  UserModule.logOrgUser(true);
}

export interface IProductState {
  product: IProduct | null;
}

export enum BookingStatus {
  BOOKING_NOT_BOOKABLE = 0,
  BOOKING_BOOKABLE = 1,
  BOOKING_SOLD_OUT = 2,
  BOOKING_EXPIRED = 3,
}

export interface IProduct {
  googleTrackingId: null | string;
  gtmId: null | string;
  booking: IBookingStatus;
  fbPixelId?: null | string;
  slotsProposals?: ISlotsProposalsEntity[] | null;
  overview: IOverview;
  id: number;
  short_url: string;
  description: string;
  name: string;
  nameWithoutTranslation: string;
  status: number;
  statusName: string;
  visibility: number;
  ticketingStatus: number;
  smeetzChannel: number;
  illustration?: null;
  userPaymentFee: boolean;
  languages?: ILanguage[] | null;
  tags?: ITagsEntity[] | null;
  pictures?: IPicturesEntity[] | null;
  location: ILocation;
  policies?: null; // moved to branding
  time_zone: string;
  isIdivTax: boolean;
  vatId?: null;
  vatRate: number;
  currency: string;
  organiser?: IOrganiser;
  holdingTime: number;
  teaser: string;
  productHighlights: IProductHighlights | null;
  productCategory?: null;
  locationComplement?: null;
  smeetz_rating: number;
  productPageType: number;
  conversionId?: null | string; // Google ads
  conversionLabel?: null | string; // Google ads
  addedValue?: null; // TODO check
  advantages?: Array<null> | null; // TODO check
}

export interface IProductHighlights {
  seasonName: IStringObject;
  dayOfWeekNames: IStringObject;
  timeOfDayNames: IStringObject;
  weatherNames: IStringObject;
  targetCustomerNames: IStringObject;
  transportTypeNames: IStringObject;
}

export interface IStringObject {
  [index: number]: string;
}

export interface IBookingStatus {
  status: number;
  flow: number;
}

export interface ISlotsProposalsEntity {
  schedule: Schedule;
  startDateTime: string;
  endDateTime: string;
}

export interface Schedule {
  id: number;
  info?: null;
}

export interface IOverview {
  rangePrice: IRangePrice;
  nextDateTime: INextDateTime;
  lastDateTime: INextDateTime;
}

export interface IRangePrice {
  fromPrice: number;
  fromPriceCurrency: string;
  maxPrice: number;
}

export interface INextDateTime {
  startDateTime: string;
  endDateTime: string;
}

export interface ILanguage {
  code: string;
  local: string;
  name: string;
  native_name: string;
}

export interface ITagsEntity {
  id: number;
  name: string;
}

export interface IPicturesEntity {
  coverTypeName: string;
  id: number;
  type: number;
  typeName: string;
  isPrimary: boolean;
  dimensions: IDimensions;
  groupHash: string;
  url: string;
  coverType: number;
}

export interface IDimensions {
  h: number;
  w: number;
}

export interface ILocation {
  name: string;
  address: string;
  zip: string;
  city: string;
  country: string;
  lat: string;
  lng: string;
}

export interface IOrganiser {
  name: string;
  slugName: string;
  conversionId?: null;
  conversionLabel?: null;
  branding?: IBranding;
}

export interface IBranding {
  bookingPolicy: string | null;
  facebookWebsite: string | null;
  linkedinWebsite: string | null;
  instagramWebsite: string | null;
  youtubeWebsite: string | null;
  website: string | null;
  emailAddress: string | null;
  phoneNumber: string | null;
  smeetzPrimaryColor?: string | null;
}

export interface ISocialsInfo {
  website: string | null | undefined;
  facebook: string | null | undefined;
  instagramm: string | null | undefined;
  linkedin: string | null | undefined;
  youtube: string | null | undefined;
}























import { Vue, Component } from 'vue-property-decorator';
import { ModalModule } from '@/utils/storemodules';
import { ModalStatus } from '@/models/definitions';

@Component({
  components: {
  },
})
export default class SwissPass extends Vue {

  @ModalModule.Mutation('setStatus') private setStatus: any;

  private addCard() {
    this.setStatus(ModalStatus.SwissPass);
  }
}

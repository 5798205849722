



































import { Component, Vue } from 'vue-property-decorator';
import { NNoty } from '@/utils/storemodules';

@Component({})
export default class ProductShare extends Vue {
  private copyLink() {
    const url = document.createElement('input');
    const text = window.location.href;
    document.body.appendChild(url);
    url.value = text;
    url.select();
    document.execCommand('copy');
    document.body.removeChild(url);
    NNoty.createNewNoty({
      message: 'Succesfully copied to your clipboard',
      period: 4000,
      type: 'success',
    });
  }

  private shareOnFacebook() {
    const text = window.location.href;
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${text}`, '_blank');
  }
}

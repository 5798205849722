















































import { Vue, Component } from 'vue-property-decorator';
// import Recaptcha from '@/components/Recaptcha.vue';
import { email, required, maxLength } from 'vuelidate/lib/validators';
import { ProductModule } from '@/utils/storemodules';
import { NNoty } from '@/utils/storemodules';

@Component({
  // components: {
  //   Recaptcha,
  // },
  validations: {
    email: {
      required,
      email,
      maxLength: maxLength(255),
    },
  },
})
export default class SubscriptionBlock extends Vue {
  private email: string = '';
  // private recaptcha: boolean = false;

  private get getDisabledButton() {
    return !this.enableSubmit;
    // || !this.recaptcha;
  }

  private get enableSubmit() {
    return !this.$v.$error && this.email.length;
  }

  // private captchaVerify() {
  //   this.recaptcha = true;
  // }

  // private captchaExpired() {
  //   this.recaptcha = false;
  // }

  private async submit() {
    if ( !this.getDisabledButton ) {
      const sendData: any = {
        email: this.email,
        subject: 'Newsletter Subscription',
        preferredlanguage: this.$i18n.locale,
        emailMarketingSmeetz: true,
      };
      try {
        await ProductModule.sendReport(sendData);
        NNoty.createNewNoty({
          message: this.$t('region.subscribe.success') as string,
          period: 8000,
          type: 'success',
        });
      } catch (err) {
         NNoty.createNewNoty({
          message: this.$t('error.error-response-banner') as string,
          period: 8000,
          type: 'error',
        });
      }

    }
  }

}

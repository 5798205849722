

















































































import { Component, Vue } from 'vue-property-decorator';
import Nav from './components/Nav.vue';
import Question from './components/Question.vue';
import { IQuestion } from '../../models/definitions';
import ModalButton from '@/components/modal/ModalButton.vue';
import { BookingModule, AuthModule } from '@/utils/storemodules';
import { getBookingFields, postBookingField } from '@/api/booking';
import { FETCH_PRODUCT } from '@/store/modules/booking/constants';
import {
  FetchProduct, IPostBookingField,
  IFieldsResponse, ITicketField,
} from '@/models/store/booking';
import { IServerBookingData, IBookingField } from '@/models/store/booking';
import { inIframe } from '@/utils/iframe';

import Debug from 'debug';
const debug = Debug('smeetz:booking');

@Component({
  components: {
    Nav,
    Question,
    ModalButton,
  },
})
export default class BookingQuestions extends Vue {
  private bookingId: number = 0;
  private bookingToken: string = '';
  private productShortName: string = '';
  private loading: boolean = false;
  private fields: IBookingField[] = [];
  private fieldsResponse: IFieldsResponse = {};
  private validQuestions: boolean[] = [];

  @BookingModule.Action(FETCH_PRODUCT) private fetchProduct!: FetchProduct;
  @AuthModule.Mutation('setLang') private setLang: any;

  get hasQuestions(): boolean {
    return !!(this.questions.length || this.ticketQuestionGroups.length);
  }

  get hasBookingQuestions(): boolean {
    return !!(this.questions.length);
  }

  // return only non answered questions, sorted by position ascending
  get questions(): IBookingField[] {
    return this.fields.filter((q) => (q.answer === null))
      .sort((field1, field2) => field1.position - field2.position);
  }

  // return only non answered ticket questions sorted by pss_id
  get ticketQuestionGroups(): ITicketField[][] {

    // This counter keeps track of the position of the ticket field
    // in the form. Start counting after the booking fields.
    let counter = this.fields.length + 1;
    const ticketFields = this.fieldsResponse.ticket_fields;
    if (!ticketFields) {
      return [];
    }

    // Group questions based on pssId
    const groups: {[s: string]: ITicketField[]} = {};

    // prefill the group object
    for (const question of ticketFields) {

      // skip if there is an answer
      if (question.answer !== null) {
        continue;
      }

      // create groupsect if it doesn't exist
      if (!groups[question.pssId]) {
        groups[question.pssId] = [];
      }

      // Only if there are no answers
      groups[question.pssId].push(question);

      // add the position to the question
      question.formPosition = counter++;
    }

    const groupsArray: ITicketField[][] = [];
    // retrun an array of question groups
    for (const key in groups) {
      if (groups[key]) {
        groupsArray.splice(Number(key) - 1, 0, groups[key]);
      }
    }

    return groupsArray;
  }

  // Indicates whether the user is able to submit or no
  get canSubmit() {
    // user can submit when all questions are valid
    for (const validQuestion of this.validQuestions) {
      if (!validQuestion) {
        return false;
      }
    }

    return true;
  }

  get isInstantBooking() {
    return true;
  }

  private async mounted() {
    // const queryParams = this.$route.query;

    // get query params
    const {
      short_url: shortName, bookingId, bookingToken,
      lang, /*redirect NOT used in this method*/
    } = this.$route.query;
    if (lang) {
      this.setLang(lang as string);
    }

    // signal that we are ready on an iframe
    if (inIframe()) {
      debug('Ready inside an iframe');
      window.parent.postMessage('userIframeReady', '*');
    }

    const payload: IServerBookingData = {
      bookingId: Number(bookingId),
      bookingToken: bookingToken as string,
    };

    this.productShortName = shortName as string;
    this.bookingId = payload.bookingId;
    this.bookingToken = payload.bookingToken;

    // fetch product
    try {
      this.loading = true;
      await this.fetchProduct(shortName as string);
      const fieldsResponse = await getBookingFields(payload.bookingId, payload.bookingToken);
      const fields = fieldsResponse.booking_fields || [];
      const ticketFields = fieldsResponse.ticket_fields || [];
      this.fieldsResponse = fieldsResponse;
      this.fields = fields;

      // All questions are valid at first.
      // We ensure this by creating an boolean array of length
      // similar to the number of booking and ticket fields.
      if (fields.length || ticketFields.length) {
        let ticketFieldsCount = 0;
        for (const group of this.ticketQuestionGroups) {
          for (const ticketField of group) {
            ticketFieldsCount++;
          }
        }
        this.validQuestions = new Array(fields.length + ticketFieldsCount).fill(true);
      }

    } catch (err) {
      // loading error goes here
      debug(`Error: Booking component after fetching ${shortName}`);
    } finally {
      this.loading = false;
    }

    if (!this.hasQuestions) {
      debug('No questions to answer, Leaving.....');
      this.exit();
    }
  }

  private updateQuestionValidity(validity: {position: number, valid: boolean }) {
    debug(`Updating validity`, validity);
    this.validQuestions.splice(validity.position - 1, 1, validity.valid);
  }

  private async submit() {
    debug('Calling submit');

    // retrieve all the answers
    const answers: IPostBookingField[] = [];
    const ticketAnswers: IPostBookingField[] = [];
    for (const field of this.questions) {
      const question: Question = (this.$refs['field' + field.fieldId] as any)[0] as Question;
      const answer = question.getAnswer();

      // Add only if answer is supplied
      if (answer.answer) {
        answers.push(answer);
        debug('sending', answer);
      }
    }

    for (const ticketQuestions of this.ticketQuestionGroups) {
      for (const ticketQuestion of ticketQuestions) {
        const question: Question =
          (this.$refs['fieldPss' + ticketQuestion.fieldId + '' + ticketQuestion.pssId] as any)[0] as Question;
        const answer = question.getAnswer();

        // Add only if answer is supplied
        if (answer.answer) {
          ticketAnswers.push({...answer, pss: ticketQuestion.pssId});
          debug('Sending', {...answer, pss: ticketQuestion.pssId});
        }
      }
    }

    if (!answers.length && !ticketAnswers.length) {
      debug('No questions were answered');
      this.exit();
      return;
    }

    try {
      this.loading = true;
      const promiseArray: any[] = [];
      if (answers.length) {
        promiseArray.push(postBookingField(this.bookingId, this.bookingToken, answers));
      }
      if (ticketAnswers.length) {
        promiseArray.push(postBookingField(this.bookingId, this.bookingToken, ticketAnswers));
      }
      await Promise.all(promiseArray);
    } catch (err) {
      // loading error goes here
      debug(`Error: After submitting booking fields`);
    } finally {
      this.loading = false;
    }

    // const button = this.$refs.button as any;
    // button.executing(true);
    this.exit();
    debug('Answers submitted');
  }

  private exit() {
    const {
      short_url: shortName,
      source, // where did the user come from. 'email' is the only option now.
    } = this.$route.query;

    debug('exiting');
    const { redirect } = this.$route.query;

    // redirect if we are coming from another page on site
    if (redirect) {
      this.$router.push(redirect + '&no_fields=1');
      return;
    }

    // signal that we are done on an Iframe
    if (inIframe()) {
      debug('Exiting from iframe');
      window.parent.postMessage('closeUserIframe', '*');
      return;
    }

    // If the user came from an email, take him to product page
    if (source === 'email') {
      return this.$router.push(`/product/${shortName}`);
    }

    // return to home page for now
    this.$router.push('/');
  }
}

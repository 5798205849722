var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"new-slots-hours"},[(_vm.hours.length > 1)?_c('div',{staticClass:"bouncing p-color f-heavy",style:({
      'text-align': (_vm.isDesktop || _vm.isTablet) ? 'left' : ''})},[(!_vm.enableNewCalendarTimeslotDesign)?_c('span',[_vm._v(_vm._s(_vm.chooseTimeText))]):_c('span',[_vm._v(_vm._s(_vm.chooseTimePeriodText))])]):_vm._e(),_c('div',{class:{
      'with-border': _vm.withBorder,
    }},[(_vm.enableNewCalendarTimeslotDesign)?_c('div',{staticClass:"period-of-day"},_vm._l((_vm.periodOfDay),function(pr,index){return _c('div',{key:index,staticClass:"f-heavy f-reduce f-tiny-mob",attrs:{"for":index}},[_c('div',{staticClass:"slot-time with-border",class:{
            'is-selected': index === _vm.selectedPeriodOfDay,
            'disabled-slot': pr.disabled,
            'mouse-pt f-pri': !pr.disabled,
          },attrs:{"data-tooltip":pr.disabled ? _vm.$t('new-booking-flow.common.no-timeslot-tooltip') : undefined},on:{"click":function($event){!pr.disabled ? _vm.selectedPeriodOfDay = index : ''}}},[_c('span',[_vm._v(_vm._s(pr.title))])])])}),0):_vm._e(),_c('div',{staticClass:"d-flex fl-a-c fl-j-c"},[((_vm.displayedSlots.length > 0 && _vm.slots.length > 1))?_c('div',{staticClass:"slot-times"},_vm._l((_vm.displayedSlots),function(hr){return _c('div',{key:hr.startTime + Math.random(),staticClass:"f-reduce f-tiny-mob"},[_c('div',{staticClass:"d-flex fl-col fl-j-c slot-time",class:{
              'is-selected': hr.startTime === _vm.hour,
              'f-pri is-selected': hr.startTime === _vm.hour,
              'disabled-slot': hr.publicCount === 0,
              'mouse-pt f-pri': hr.publicCount > 0,
              'mouse-pt': hr.publicCount > 0,
            },style:(_vm.ukTimeDisplay && !_vm.isEndHidden? 'width: 140px;' : ''),attrs:{"data-tooltip":hr.toolTipTxt || undefined},on:{"click":function($event){hr.publicCount > 0 ? _vm.selectHour(hr.startTime) : ''}}},[_c('div',{staticClass:"d-flex fl-row ",staticStyle:{"white-space":"pre"}},[_c('div',[_c('span',{style:({'margin-top': _vm.displayPriceStrategy || _vm.displayAvailabilityStrategy ? '6px': ''})},[_vm._v(_vm._s(_vm.formatTime(hr.startTime)))]),((!_vm.isEndHidden))?_c('span',[_vm._v(" - ")]):_vm._e(),((!_vm.isEndHidden))?_c('span',[_vm._v(_vm._s(_vm.formatTime(hr.endTime)))]):_vm._e()])]),(_vm.shouldShowHurryUp(hr))?_c('span',{staticClass:"slot-remaining-seats-highlighted",class:{
                  'slot-remaining-inner': _vm.isHurryUpInner
                },style:(_vm.hurryUpStyle(hr))},[_vm._v(" "+_vm._s(_vm.getHurryUpText(hr.publicCount))+" ")]):_vm._e(),(_vm.shouldShowSoldOut(hr))?_c('span',{staticClass:"slot-remaining-seats-highlighted",class:{
                  'slot-remaining-inner': _vm.isSoldOutInner
                },style:(_vm.soldOutStyle(hr))},[_vm._v(" "+_vm._s(_vm.$t('common.full-seats'))+" ")]):_vm._e(),(_vm.displayPriceStrategy && hr.price && !_vm.shouldHidePrice(hr))?_c('div',{staticClass:"hourslot-price-container f-reduce f-tiny-mob",staticStyle:{"font-size":"12px"},style:({'color': ("" + (hr.publicCount > 0 ? _vm.getPriceStrategyColor(hr) : ''))})},[_c('span',[_vm._v(_vm._s(_vm.currency))]),_vm._v(_vm._s(Math.floor(hr.price))+_vm._s(_vm.getDecimalDigits(hr.price, 2))+" ")]):_vm._e(),(_vm.shouldDisplayAvailability(hr))?_c('span',{staticClass:"slot-remaining-seats-highlighted",staticStyle:{"font-size":"12px"},style:(_vm.availabilityStyle(hr))},[_vm._v(" "+_vm._s(_vm.getAvailabilityText(hr.publicCount))+" ")]):_vm._e()])])}),0):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }
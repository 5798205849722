










import { Vue, Component, Prop } from 'vue-property-decorator';
import Ticket from './Ticket.vue';
import { AppModule, NBookingModule, ProductModule } from '../../../utils/storemodules';
import { ITimeslotsEntity, ITicket, ICategoryInfo, IExportPricesEntity } from '../../../models/store/booking';
import NewBasket from './NewBasket.vue';

@Component({
  components: {
    Ticket,
  },
})
export default class NTickets extends Vue {
  @Prop({default: 0}) public windowWidth!: number;
  @Prop({default: []}) private tickets!: any;
}


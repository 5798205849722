import request, { request2, requestGraphql } from '@/utils/request';
import { dataToParams } from '@/utils/helpers';
import { inIframe } from '@/utils/iframe';

import {
  IUserData, IEmailVerifyData,
  IUser, SocialService,
} from '@/models/definitions';
import {
  ILoginData, ILoginResponse,
  IEmailConfData, ISocialDataApi,
  IPassChange, IPassReset,
  IDeleteAccount, IPhoneData, ILoginWithTokenResponse,
} from '@/models/store/auth';
import Debug from 'debug';

const debug = Debug('smeetz:auth');

// Registers the user
export async function register(userData: IUserData) {
  debug(`Creating user`);

  const response = await request({
    method: 'post',
    url: '/user',
    data: userData,
    params: { realm: 'old' },
  });

  return response.data;
}

// Email login
export async function login(userData: ILoginData): Promise<ILoginResponse> {
  debug('Loging in user');

  const response = await request({
    method: 'get',
    url: '/oauth/v2/token',
    params: Object.assign({}, userData, {
      grant_type: 'password',
    }),
  });

  const data = response.data;

  return data;
}

/**
 *
 * @param userEmail
 * @returns an object containing 'id' which will be sent to authorization
 */
export async function submitCustomAuthEmail(userEmail: string): Promise<any> {
  const response = await request2({
    method: 'POST',
    url: '/api/v1/temp-auth',
    data: JSON.stringify({ email: userEmail }),
  });
  const data = response.data;

  return data;
}

/**
 *
 * @param userEmail
 * @param orgId: Organiser id
 * @returns an object containing 'id' which will be sent to authorization
 */
export async function submitOrganiserCustomAuthEmail(
  userEmail: string, orgId: number, lang: string,
): Promise<any> {
  const response = await request2({
    method: 'POST',
    url: `/api/v1/temp-auth/email/${orgId}`,
    params: {
      lang,
    },
    data: JSON.stringify({ email: userEmail }),
  });
  const data = response.data;

  return data;
}

/**
 *
 * @param id
 * @returns authorization token
 */
export async function submitCustomAuthCode(id: string, code: string): Promise<any> {
  const response = await request2({
    method: 'PATCH',
    url: '/api/v1/temp-auth/' + id,
    data: JSON.stringify({ code }),
  });
  const data = response.data;

  return data;
}

export async function loginWithSmtzToken(smtzToken: string): Promise<ILoginWithTokenResponse> {
  const response = await request2({
    method: 'POST',
    url: '/api/v1/temp-auth/smeetz',
    data: {smeetzToken: smtzToken},
  });

  const data = response.data;
  return data;
}

export async function loginWithBookingIdAndToken(bookingId: number, bookingToken: string):
  Promise<ILoginWithTokenResponse> {
    const response = await request2({
      method: 'POST',
      url: `/api/v1/temp-auth/booking/${bookingId}`,
      data: { bookingToken },
    });
    const data = response.data;
    return data;
}

// Refresh the authentication token
export async function apiRefreshToken(refreshTk: string): Promise<ILoginResponse> {
  const response = await request({
    method: 'get',
    url: '/oauth/v2/token',
    params: {
      grant_type: 'refresh_token',
      refresh_token: refreshTk,
    },
  });

  return response.data;
}

// Social Login
export async function socialLogin(data: ISocialDataApi, service: SocialService): Promise<ILoginResponse> {

  const requestData: ISocialDataApi = {
    ...data,
  };

  // const realm: any = { };
  // realm is old for normal site
  // new for booking widget
  // This should be modified when Marina fixes the logic in the backend
  const realm: any = { realm: 'old' };
  // Uncomment if we will need realm="new" on widget part
  // if (isIframebuild() && !process.env.VUE_APP_PRODUCT) {
  //   realm.realm = 'new';
  // }

  if (service === SocialService.Facebook) {
    requestData.grant_type = 'http://www.facebook.com';
  } else if (service === SocialService.Google) {
    requestData.grant_type = 'https://www.google.com';
    if (inIframe()) {
      realm.realm = 'new';
    }
  } else if (service === SocialService.Swisspass) {
    requestData.grant_type = 'https://www.swisspass.ch';
  }

  debug(`Callin social login with ${JSON.stringify(requestData)}`);
  const response = await request({
    method: 'get',
    url: '/oauth/v2/token',
    params: dataToParams(Object.assign(realm, requestData)),
  });

  return response.data;
}

// Verifies user email
export async function verifyEmail(data: IEmailVerifyData) {

  const response = await request({
    method: 'patch',
    url: '/user/email/validate',
    data: dataToParams(data),
    params: { realm: 'old' },
  });

  return response.data;
}


// Get user data
export async function getUser(): Promise<IUser> {
  const response = await request({
    method: 'get',
    url: '/user',
  });

  return response.data;
}

// Send confirmation email
export async function sendConfEmail(data: IEmailConfData): Promise<any> {
  const params = new URLSearchParams();
  params.append('email', data.email);
  params.append('languageId', String(data.languageId));

  const response = await request({
    method: 'post',
    url: '/user/email/validate',
    data: params,
  });

  return response.data;
}

// generates a user token for email password reset
export async function resetPassReq(email: string): Promise<any> {
  const data = { emailAddress: email };
  const params = dataToParams(data);

  const response = await request({
    method: 'post',
    url: '/user/password/reset',
    data: params,
    params: { realm: 'old' },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

  return response.data;
}

export async function resetPass(data: IPassReset) {
  const params = dataToParams(data);

  const resp = await request({
    method: 'patch',
    url: '/user/password/reset',
    data: params,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

  return resp.data;
}

export async function changePass(data: IPassChange, isLoggedIn: boolean = false) {
  const params = new URLSearchParams();
  if (!isLoggedIn) {
    params.append('resetToken', data.resetToken);
    params.append('userId', data.userId);
  }

  params.append('password[first]', data.password.first);
  params.append('password[last]', data.password.last);

  const resp = await request({
    method: 'patch',
    url: '/user/password/change',
    data: params,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

  return resp.data;
}

export async function updateUser(user: IUserData) {
  const data = dataToParams(user);

  const response = await request({
    url: '/user',
    method: 'patch',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

  return response.data;
}

export async function cancelAccount(data?: IDeleteAccount) {
  let params: any;
  let method = 'post';
  const realm: any = { realm: 'old' };

  if (data) {
    params = dataToParams(data);
    method = 'patch';
  }

  const response = await request({
    method,
    url: '/user/cancel',
    data: data ? params : {},
    params: realm,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

  return response.data;
}

export async function addEmail(email: string, accessToken: string) {
  // To avoid 403 repsponse, backend advised to
  // - the user should confirm his account (I tried this one and it kept giving 403 response)
  // - send the accessToken as user_access_token

  const params = dataToParams({ emailAddress: email });

  const response = await request({
    method: 'post',
    url: '/user/email',
    params: {
      user_access_token /*access_token*/: accessToken,
      realm: 'old',
    },
    data: params,
  });

  return response.data;
}

export async function cancelEmailPhone(emailPhoneId: string, medium = 'email') {
  const response = await request({
    method: 'patch',
    url: `/user/${medium}/${emailPhoneId}/cancel`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

  return response.data;
}

export async function setPrimaryEmail(emailId: string) {
  const response = await request({
    method: 'patch',
    url: `/user/email/${emailId}/setprimary`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

  return response.data;
}

export async function addPhone(data: IPhoneData) {
  const params = dataToParams(data);

  const response = await request({
    method: 'post',
    url: '/user/phone',
    data: params,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

  return response.data;
}













import { IProductCategory } from '@/types/db/product-category';
import { Vue, Component, Prop } from 'vue-property-decorator';
import Retail from './Retail.vue';

@Component({
  components: {
    Retail,
  },
})
export default class NRetails extends Vue {
  @Prop({default: 0}) public windowWidth!: number;
  @Prop({default: []}) private retails!: IProductCategory[];
}


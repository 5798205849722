import { gql } from 'graphql-tag';

/**
 * Fetches users membership vouchers
 *
 */
// tslint:disable-next-line
export const GET_UserMemberships = gql`
  query GetUserMemberships {
    getUserMemberships {
      uuid
      voucher
      ticketName
      status
      startDateTime
      endDateTime
    }
  }
`;

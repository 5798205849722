import { CategoryType, PriceCategory } from '@/models/enums';
import { IMembershipDiscounts, MembershipType } from '@/models/memberships';
import { ICustomerTickets, IExportPricesEntity, IMembershipCustomer, IPreBookingRes, ITicket } from '@/models/store/booking';
import { isChildPriceCategory, isNonChildPriceCategory } from './booking';
import { NBookingModule, ProductModule } from './storemodules';
import { getElementById, scrollToElement } from './dom';
import { findRecapCategoriesByCategoryId } from './tickets';
import { RequiresBookingCodes } from './booking-utils';
import { disableQuasarStyling, enableQuasarStyling } from './styles';
import { Loading } from 'quasar';
import { getUserMemberships } from '@/api/userMemberships';

export function isFamilyMembership(membership: IMembershipDiscounts) {
  return membership.type === MembershipType.Family;
}

/**
 * Checks whether all customers already have a ticket bought/reserved/booked
 * @returns boolean
 */
export function hadAllCustomersBooked(customers: IMembershipCustomer[]): boolean {
  return customers.every((c) => c.tickets && c.tickets.length > 0);
}

/**
 * Checks whether a customer has already booked this exact category
 * @returns boolean
 */
export function didCustomerBookThisCategory(customer: IMembershipCustomer, category: ITicket) {
  return (customer.tickets.find((t) => t.categoryId === category.categoryId));
}

/**
 * Checks whether all customers have bought a give category
 * @returns boolean
 */
export function didAllCustomersBookThisCategory(customers: IMembershipCustomer[], category: ITicket) {
  const customersWhoBought =
    customers.filter((c) => didCustomerBookThisCategory(c, category));

  return customers.length === customersWhoBought.length;
}

/**
 * Checks if a customer booked a price with a similar name
 * @returns boolean
 */
export function didCustomerBookSimilarPrice(
  customer: IMembershipCustomer, price: IExportPricesEntity): boolean {

  return !!(customer.tickets.find((t) => t.priceName === price.priceName));
}

/**
 * Checks whether a customer has already booked something
 * @returns boolean
 */
export function didCustomerBookAlready(customer: IMembershipCustomer): boolean {
  return customer.tickets && customer.tickets.length > 0;
}

/**
 * Checks whether customers have already booked a non child price for a given category
 * @returns boolean
 */
export function didCustomersBookNonChild(customers: IMembershipCustomer[], category: ITicket) {
  const ticketsBought: ICustomerTickets[] = [];
  for (const cust of customers) {
    for (const t of cust.tickets) {
      if (t.categoryId === category.categoryId) {
        ticketsBought.push(t);
        break;
      }
    }
  }

  return !!ticketsBought.find((t) => isNonChildPriceCategory(t.priceCategory));
}

/**
 * Checks whether customers have already booked a child price for a given category
 * @returns boolean
 */
export function didCustomersBookChild(customers: IMembershipCustomer[], category: ITicket) {
  const ticketsBought: ICustomerTickets[] = [];
  for (const cust of customers) {
    for (const t of cust.tickets) {
      if (t.categoryId === category.categoryId) {
        ticketsBought.push(t);
        break;
      }
    }
  }

  return !!ticketsBought.find((t) => isChildPriceCategory(t.priceCategory));
}

/**
 * Returns all the price categories that customers have already bought.
 * @param customers Membership customers
 * @returns price categories of all the tickets that customers have bought
 */
export function customersPriceCateogries(customers: IMembershipCustomer[]) {
  const bookedPriceCategories = new Set<PriceCategory>();
  for (const customer of customers) {
    for (const ticket of customer.tickets) {
      bookedPriceCategories.add(ticket.priceCategory);
    }
  }

  return Array.from(bookedPriceCategories);
}

/**
 * retrieves a customer with only tickets prices of type other selected.
 * That customer can select any price subsequently
 * @param customers Customers array to check
 */
export function getCustomerWithOnlyOtherTickets(customers: IMembershipCustomer[]) {

  for (const customer of customers) {
    if (isCustomerWithOnlyOtherTickets(customer)) {
      return customer;
    }
  }

  // No customer found
  return null;
}

/**
 * Checks wether a customer has only type other prices
 * @param customer Customer to check
 * @returns boolean
 */
export function isCustomerWithOnlyOtherTickets(customer: IMembershipCustomer) {
  return customer.tickets.every((ticket) => ticket.priceCategory === PriceCategory.Other);
}

/**
 * Indicates whether we are dealing with required tickets membership
 */
export function isRequiredTicketsMembership(membership?: IMembershipDiscounts | null) {
  if (!membership) {
    return false;
  }

  return membership.type === MembershipType.RequiredTicketsMembership;
}

/**
 * Checks whether all customers booked a requiredTicketMembership show.
 * Required ticket membership requires that all customers have booked already
 */
export function didAllCustomersBookRequiredMembershipTickets(
  customers: IMembershipCustomer[], membership: IMembershipDiscounts | null,
) {
    if (!membership || !isRequiredTicketsMembership(membership)) {
      return false;
    }

    const membershipWithError = membershipPriceWithError(membership);

    for (const membershipPriceId of Object.keys(membershipWithError)) {
      if (membershipWithError[membershipPriceId]) {
        return false;
      }
    }

    return true;
  }

/**
 * Returns the membership ticket in the list of tickets bought
 */
export function findMembershipCategories(booking?: IPreBookingRes | null) {

  if (!booking) {
    return [];
  }

  const categories = booking.bookingRecap.categories?.filter((cat) => {
    return cat.categoryType === CategoryType.Membership;
  });

  return categories;
}

/**
 *
 * @returns recap categories that belong to a certain membership
 */
export function requiredTicketsMemberships(membership: IMembershipDiscounts) {
  if (membership && membership.type === MembershipType.RequiredTicketsMembership) {
    const recapCategories = NBookingModule.recapCategories;

    // In required tickets memberships, memberships are not addons
    return recapCategories.filter((cat) => !cat.mainTicket);
  }
  return [];
}

/**
 *
 * @returns membership prices with error.
 * Error means that customers didn't book all of their required tickets
 */
export function membershipPriceWithError(membership: IMembershipDiscounts) {

  const membershipCustomers = NBookingModule.membershipCustomers;
  const reqTicketsMemberships = requiredTicketsMemberships(membership);
  const membershipWithError: Record<string, boolean> = {};

  // For now, we take all addon tickets as required tickets for a membership.
  // TODO WARNING This is wrong for multi membership with different addons cause each membership
  // has it's own required list of addons
  const addonTicketsShowsPrices = ProductModule.addonTicketsShowsPrices;
  const ticketsIds = Object.keys(addonTicketsShowsPrices);

  // Initially memberships have no errors
  for (const recapMembership of reqTicketsMemberships) {
    membershipWithError[recapMembership.priceId] = false;

    // Flag for skipping iterations if we spot that a membership price has an issue with one customer
    let breakLoops = false;

    // for each membership, check if any customer didn't book all their required tickets
    const customersWithThisMembership = membershipCustomers
      .filter(
        (cust) => cust.tickets.find(
          (ticket) => ticket.categoryId === recapMembership.categoryId && ticket.priceId === recapMembership.priceId),
        );

    // if there are customers who didn't book all required tickets, then we have an issue on that
    // membership price (the membership that this customer is buying)
    for (const ticketId of ticketsIds) {
      const ticketAddonsPrices: number[] = (addonTicketsShowsPrices as any)[ticketId]
        .map((price: any) => price.priceId);
      for (const customer of customersWithThisMembership) {

        const customerTicket = customer.tickets.find((t) => ticketAddonsPrices.includes(t.priceId));
        if (!customerTicket) {
          membershipWithError[String(recapMembership.priceId)] = true;
          breakLoops = true;
          break;
        }
      }

      // Since we have an issue with this membership price, no need to do further iterations
      if (breakLoops) {
        break;
      }
    }
  }

  return membershipWithError;
}

/**
 * Update validated booking codes for price
 */
export function setPriceValidatedBookingCodes(data: {priceId: number}) {
  const validatedBookingCodes = NBookingModule.validatedBookingCodes;
  const priceValidatedBookingCodes = NBookingModule.priceValidatedBookingCodes;
  priceValidatedBookingCodes[data.priceId] = {...validatedBookingCodes};
}

/**
 *  Finds all the recap categories with membership setting
 * @param categoryId
 * @returns
 */
export function findRecapCategoriesWithMembershipSetting(categoryId: number) {
  return findRecapCategoriesByCategoryId(categoryId)
    .filter((cat) => RequiresBookingCodes.includes(cat.membershipSetting));
}

/**
 * Finds the booking codes already verified for a given price
 */
export function findBookingCodesForPrice(data: {priceId: number}) {
  return NBookingModule.priceValidatedBookingCodes[data.priceId] || [];
}

/**
 * Api call to get user memberships booking codes using smtzAccessToken
 */
export async function getUserMembershipsBookingCodes(token: string) {
  try {
    enableQuasarStyling();
    Loading.show();
    const userBookingCodes = await getUserMemberships(token);
    if (userBookingCodes && userBookingCodes.length > 0) {
      const usabledBookingCodes = userBookingCodes.filter((bookingCode) => {
        const now = new Date();
        const endDateTime = new Date(bookingCode.endDateTime);
        return bookingCode.status === 3 && endDateTime > now;
      });
      if (usabledBookingCodes.length > 0) {
        NBookingModule.setUserMembershipBookingCodes(usabledBookingCodes);
      }
    }
  } catch (error) {
    Loading.hide();
    disableQuasarStyling();
    setTimeout(() => {
      throw error;
    }, 0);
  } finally {
    Loading.hide();
    disableQuasarStyling();
  }
}

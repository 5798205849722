




































































import { Vue, Component } from 'vue-property-decorator';
import NewBookingFooter from '@/components/booking/NewBookingFooter.vue';
import NewButton from '@/components/presentational/NewButton.vue';
import ConfTickets from './components/ConfTickets.vue';
import { getPss } from '../../api/booking';
import { IPss } from '../../models/store/booking';

@Component({
  components: {
    NewBookingFooter,
    NewButton,
    ConfTickets,
  },
})
export default class TicketConfirm extends Vue {
  private pss: string = '';
  private isLoading = false;
  private pssTickets: IPss | null = null;
  private currentStep: number = 0;

  get headingText(): string {
    const pssTickets = this.pssTickets;
    if (pssTickets) {
      return this.$t('ticket-conf.title-2') as string;
    }
    return this.$t('ticket-conf.title') as string;
  }

  private created() {
    const pss = this.$route.params.pss;
    if (pss) {
      this.pss = pss;
    }
  }

  private async confirm() {
    this.isLoading = true;
    if (this.pss) {
      try {
        this.pssTickets = await getPss(this.pss);
      } finally {
        this.currentStep = 1;
        this.isLoading = false;
      }
    }
  }

  private close() {
    this.$router.push('/');
  }
}

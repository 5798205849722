
































import { Vue, Component, Prop } from 'vue-property-decorator';
import ModalButton from '@/components/modal/ModalButton.vue';
import { ModalStatus } from '@/models/definitions';
import { AuthModule, ModalModule, NModalModule } from '@/utils/storemodules';
import { verifyEmail } from '@/api/auth';

@Component({
  components: {
    ModalButton,
  },
})
export default class EmailVerified extends Vue {
  // @Prop({default: 'test@test.test'})
  public userEmail!: string;

  private responseStatus: string = 'success';

  @ModalModule.Mutation('setStatus') private setStatus!: any;
  @AuthModule.Action('verifyEmail') private verifyEmail!: any;
  @AuthModule.Action('sendConfEmail') private sendConfEmail!: any;

  private async created() {
    const query = this.$route.query;
    const { email, mailToken } = query;
    this.userEmail = email as string;

    if (!(email && mailToken)) {
      this.responseStatus = 'error';
      return;
    }

    // verify email
    try {
      await this.verifyEmail({ email, mailToken });
    } catch (err) {
      this.responseStatus = 'error';
      return;
    }

    this.responseStatus = 'success';
  }

  private mounted() {
    // const {email, mailToken} = this.$route.query;
    // this.emailUser = email;
    // this.userEmail(email);
  }

  private close() {
    // this.setStatus(ModalStatus.Hidden);
    NModalModule.setStatus(ModalStatus.Hidden);
    this.$router.push(`/${this.$i18n.locale}/user-space`);
  }

  private async resend() {
    const button = this.$refs.button as ModalButton;

    button.executing(true);

    if (!this.userEmail) {
      button.executing(false);
      return;
    }

    try {
      await this.sendConfEmail(this.userEmail);
      // this.setStatus(ModalStatus.EmailConf);
      NModalModule.setStatus(ModalStatus.EmailConf);
    } catch (err) {
      button.executing(false);
    }

    button.executing(false);

    // remove mailToken and email from query parameters
    const query = Object.assign({}, this.$route.query);
    delete query.email;
    delete query.mailToken;
    this.$router.replace({ query });
  }
}

import Debug from 'debug';
import { ITicketSlots, ITicketCount } from '@/models/store/gbooking';
const debug = Debug('smeets:gbooking');

export function canSlotHandleCount(ticketSlots: ITicketSlots, count: ITicketCount): boolean {
  debug('Checking slot');
  let length = 0;
  // let canHandle = true;
  let ticketCount = 0;

  for (const ticketId in count) {
    if (!ticketId) {
      continue;
    }
    const tCount = count[ticketId];
    ticketCount = tCount.count + ticketCount;
    length++;

    const tSlots = ticketSlots[ticketId];
    if (!tSlots) {
      debug('No time slots');
      return false;
    }

    for (const slot of tSlots) {
      // if (! slot.export_prices) {
      //   canHandle = false;
      //   break;
      // }
      // const price = slot.export_prices[0];
      if (slot.publicCount < count[ticketId].count) {
        debug(`${slot.startDateTime} doesn't have enough seats for ${count[ticketId].count}`);
        return false;
      }
    }
  }

  if (!length) {
    debug('No tickets');
    return false;
  }

  if (!ticketCount) {
    debug('No tickets count');
    return false;
  }

  return true;
}

export interface IGetMembershipResponse {
  status: string;
  data: IMembershipData;
}

export interface IMembershipData {
  membershipDiscounts: (IMembershipDiscounts)[];
}

export interface IMembershipDiscounts {
  id: number;
  name: string;
  minimalNumberTicket: number;
  discount: string[];
  discountReduced: string[];
  status: number;
  type: MembershipType;
  ticketGroup: null | { id: number; };
  num_ticket: number;
}

export interface IPostMembership {
  status: string;
  data: IPostMembershipData;
}

export interface IPostMembershipData {
  productSlotStates: (IMembershipProductSlotStates)[];
  membershipDiscount: {
    id: number;
    name: string;
    minimalNumberTicket: number;
    ticketGroup: null | { id: number };
  };
  ticketsLeft: number;
}

export interface IMembershipProductSlotStates {
  categoryId: number;
  priceId: number;
  timeSlotId: number;
  id: number;
  membershipTicket: IMembershipTicket | null;
  priceValue: number;
  priceValueDefault: number;
  discountPercent: number;
  mainTicketId?: number;
}

export interface IMembershipProductSlotStatesFront extends
  IMembershipProductSlotStates {
  categoryName: string;
  priceName: string;
  discPriceValue?: number;
  membershipName?: string;
  seat?: string;
}

export interface IMembershipTicket {
  id: number;
  discount: number;
  membershipDiscountId: number;
  customer: string;
  reduced: boolean;
  status: MemTicketStatus;
  status_name: string;
  firstName?: string;
  lastName?: string;
}


export enum MembershipType {
  Individual = 1,
  Family = 2,
  RequiredTicketsMembership = 3,
  RegularMembership = 4,
}

export enum MemTicketStatus {
  NotEnoughTikcet = 1,
  Valid,
  NotValidSameDate,
}

// export enum MemticketStatusName {
// }

// Membership type
export enum MemType {
  // Membership & ticket
  MemAndTicket = 1,
  // Membership only
  MemOnly,
}




















































































import { Vue, Component, Prop } from 'vue-property-decorator';
import CardTag from '@/components/presentational/TicketTag.vue';
import { AppModule, NNoty, OrganizerModule } from '@/utils/storemodules';
import moment from 'moment';
import { inIframe, openWidget } from '@/utils/iframe';
import { getPrimaryColor } from '@/utils/colors';
import { IUserBooking } from '@/models/store/user';
import { postResendBooking } from '@/api/user';
import Debug from 'debug';
import { IUserActivity } from '@/models/store/region';
import { SITE_URL } from '@/config/constants';
const debug = new Debug('smeetz:userspace');

@Component({
  components: {
    CardTag,
  },
})
export default class BookingCard extends Vue {
  @Prop({default: null}) private cardData: any;
  @Prop({default: false}) private isGorizontal!: boolean;
  @Prop() private booking!: IUserBooking;
  // @Prop({default: null}) private siteColor!: string;
  private openTeaser: boolean = false;
  private isLoading = false;

  private get isMobile() {
    return AppModule.isMobile;
  }

  private get isOrganizerUserSpace() {
    return OrganizerModule.isOrganizerUserSpace;
  }

  private get siteColor() {
    if (inIframe()) {
      return 'var(--primary-color)';
    }

    return '';
  }

  private get cardColor() {
    return this.siteColor || this.cardData.branding_smeetz_primary_color;
  }

  get productTranslations(): null | {teaser?: string, name?: string, description?: string} {
    const trans = this.cardData.product_translations;
    if (!trans) {
      return null;
    }
    const transObj = JSON.parse(trans);
    return transObj[this.$i18n.locale];
  }

  get productName() {
    const trans = this.productTranslations;
    const name = this.cardData.product_name;
    if (!trans || !trans.name) {
      return name;
    }

    return trans.name || name;
  }

  get date() {
    const productSlotState = this.booking && this.booking.productSlotStates;
    if (productSlotState && productSlotState.length) {
      return productSlotState[0].startDateTime;
    }

    return this.cardData.dates;
  }

  get productImage() {
    return this.cardData.cover_picture;
  }

  private translateDate(date: string) {
    const sendedDate = moment(date).format('dddd, D MMM YYYY');
    const dateStringArray = sendedDate.split(' ');
    const dayOfWeek = this.$t(`date.${dateStringArray[0].toLowerCase().slice(0, -1)}_short`);
    const month = this.$t(`date.${dateStringArray[2].toLowerCase()}`);
    dateStringArray[0] = dayOfWeek as string + ',';
    dateStringArray[2] = month as string;
    return dateStringArray.join(' ');
  }

  private showTeaser() {
    this.openTeaser = !this.openTeaser;
  }

  private get cardTeaser() {
    if (this.booking) {
      return false;
    }

    if ( this.$i18n.locale !== 'en') {
      const translationsObject = JSON.parse(this.cardData.product_translations);
      if ( translationsObject && translationsObject[this.$i18n.locale]) {
        return translationsObject[this.$i18n.locale].teaser;
      } else {
        return false;
      }
    }
    return this.cardData.product_teaser;
  }

  private getPrice(price: number) {
    return price % 1 === 0 ? price : price.toFixed(2);
  }

  // Take the user to the booking flow
  private goBook() {
    const id = this.cardData.id;
    const short_name = this.cardData.short_name;
    if ( id && short_name) {

      if (inIframe()) {
        this.openWidgetBooking();
        return;
      }

      this.$router.push(`/product/${id}/booking/${short_name}`);
    }
  }

  private async goToBookingFlow() {
    const booking = this.booking;
    if (booking) {
      try {
        this.isLoading = true;
        await postResendBooking(booking.bookingId);
        NNoty.createNewNoty({
          period: 5000,
          message: this.$t('field.message-tickets-sent') as string,
          type: 'success',
        });
      } catch (error) {
        throw error;
      } finally {
        this.isLoading = false;
      }

      return;
    }
    const id = this.cardData.id;
    const short_name = this.cardData.short_name;
    if ( id && short_name) {

      if (inIframe()) {
        this.openWidgetBooking();
        return;
      }

      this.$router.push(`/product/${id}/booking/${short_name}`);
    }
  }

  private goToBookingSummary() {
    const booking = this.booking;
    if (booking) {
      if (this.isOrganizerUserSpace) {
        this.$router.replace(`/${this.$i18n.locale}/org-user/${this.$route.params.orgId}/userbookings/${booking.bookingId}`);
      } else {
        this.$router.push(`/${this.$i18n.locale}/user-space/userbookings/${booking.bookingId}`);
      }
    }
  }

  private goToOrganizer(cardData: any) {
    // this.$router.push(`/new/organizer/${cardData.organiser_slug_name}`);
    window.open(`${SITE_URL}/org/${cardData.organiser_slug_name}`, '_blank');
  }

  private goSeeMore() {
    if (inIframe()) {
      this.openWidgetBooking();
      return;
    }

    const short_name = this.cardData.short_name;
    if ( short_name ) {
      this.$router.push(`/product/${short_name}`);
    }
  }

  private openWidgetBooking() {
    const id = this.cardData.id;
    const short_name = this.cardData.short_name;
    if ( id && short_name) {
      // @ts-ignore
      openWidget(['openwidget', 1 * new Date(), {
        productId: `${id}`,
        lightbox: true,
        listView: false,
        ocol: getPrimaryColor(),
      }]);
      return;
    }
  }
}

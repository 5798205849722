import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import { GET_BOOKING_REFERRER_SITE } from '../booking/constants';
import { Validator } from '@/utils/validators';
import { ICategory, ITicket } from '@/models/store/booking';
import { AppModule } from '@/utils/storemodules';
import { isSeatingTicket } from '@/utils/booking';
import { MemType } from '@/models/memberships';
import { ICustomLinksData } from '@/models/organizer';
import { SITE_URL } from '@/config/constants';
import { IProductCategory } from '@/types/db/product-category';


@Module({ namespaced: true, name: 'referrer' })
export default class Referrer extends VuexModule {
  public referrer: string = 'smeetz.com';
  public fbp: string | null = null;
  public fbc: string | null = null;
  public fbclid: string | null = null;
  public eventTestCode: string | null = null;
  public gtmId: string | null = null;
  public promoCode: string = ''; // promoCode for user
  // Next is hidden with code categories (TODO: improve naming cause it's confusing)
  public categories: string = ''; // hidden categories that should be visible
  public retailIds: string = ''; // retails products that should be shown
  public newCalendar: string = ''; // new calendar design to display
  public newTimeslots: string = ''; // new timeslots design to display
  public numDaysNewCalendar: string = ''; // number of days to be displayed in new calendar
  public fromWidget: string = ''; // booking started on widget, Had issue, was redirected to discover
  public parentUrl: string = ''; // This allows us to redirect to the Organizer website
  public topLevelDomainUrl: string = ''; // This allows us to redirect to the Organizer website
  public paymentMethod: string = ''; // Payment method is preselected
  public from: string = ''; // from date where the tickets will be shown
  // Minimum date from which we show slots for calendar tickets
  public mFrom: string = '';
  // Maximum date to which we show slots for calendar tickets
  public mTo: string = '';
  // Url of the website that hosts the widget
  public hostUrl: string = '';

  // Analytics and commission related parameters
  public utmSource = '';
  public utmMedium = '';
  public utmCampaign = '';
  public utmChannel: 'b2b' | 'b2c' | 'iFrame' = 'b2c';
  public widgetOrigUrl = '';

  // Membership
  public memType: MemType | null = null; // membership type
  public memIds: number[] = []; // membershipIds
  public customLinksData: ICustomLinksData | null = null; // custom links and custom texts in different languages.
  public customText: string = ''; // custom text that organizer sets for the confirmation step.
  public customUrl: string = ''; // custom url that organizer sets for the confiramtion step.
  public visibleTickets: string[] = []; // tickets that should be visible on the flow


  private referrerSite: string = ''; // Site that brought us to smeetz

  get getReferrerSite(): string {
    // returns the booking referrer from booking recap
    const bookingReferrerSite: string = this.context.rootGetters[`booking/${GET_BOOKING_REFERRER_SITE}`];
    if (bookingReferrerSite) {
      return bookingReferrerSite;
    }

    // otherwise returns this module's referrer site.
    return this.referrerSite;
  }

  // returns url with http added to it in case protocol is missing
  get getReferrerSiteFull(): string {
    if (!this.getReferrerSite) {
      return '';
    }

    return this.getReferrerSite.includes('http') ?
      this.getReferrerSite : `http://${this.getReferrerSite}`;
  }

  get getReferrerFull(): string {
    if (!this.referrer) {
      return '';
    }

    return this.referrer.includes('http') ?
      this.referrer : `http://${this.referrer}`;
  }

  // returns our website url
  get siteUrl(): string {
    const url = SITE_URL;
    return url.slice(String('https://').length);
  }

  // the referrer site is not one of our sites
  get isExternalReferrerSite(): boolean {
    const referrerSite = this.getReferrerSite;
    if (referrerSite) {
      // site shouldn't be our site and not POS
      // and should be a valid url
      return !referrerSite.includes(this.siteUrl)
        && referrerSite !== 'smeetz.com'
        && referrerSite !== 'POS'
        && !['google.ads', 'social.f.ads', 'social.i.ads'].includes(referrerSite)
        && Validator.isValidURL(referrerSite);
    }

    return false;
  }

  // the referrer site is not one of our sites
  get isExternalReferrer(): boolean {
    const referrerSite = this.referrer;
    if (referrerSite) {
      // site shouldn't be our site and not POS
      // and should be a valid url
      return !referrerSite.includes(this.siteUrl)
        && referrerSite !== 'smeetz.com'
        && referrerSite !== 'POS'
        && !['google.ads', 'social.f.ads', 'social.i.ads'].includes(referrerSite)
        && Validator.isValidURL(referrerSite);
    }

    return false;
  }

  // an array of hidden with link categories that should be visible
  get catArray(): string[] {
    return this.categories.split(',');
  }

  get isB2c() {
    return this.utmChannel === 'b2c';
  }

  get isMembershipOnly() {
    return this.memType === MemType.MemOnly;
  }

  /**
   * Checks whether a ticket is visible
   */
  get isTicketVisible(): (ticket: ITicket) => boolean {
    return (ticket: ITicket): boolean => {
      // if we want to show only a subset, check if the ticket is one of them
      // this happens if we explicitely have limited categories or in a cart widget
      if ((this.visibleTickets.length > 0 || AppModule.isCartWidget) && !AppModule.isTCart) {
        // If we have a seating ticket, check if it's subticket is in the list
        if (isSeatingTicket(ticket) && ticket.seatingSubProducts && ticket.seatingSubProducts.length) {
          for (const subTicket of ticket.seatingSubProducts) {
            if (this.visibleTickets.includes(String(subTicket.categoryId))) {
              return true;
            }
          }
        }
        return this.visibleTickets.includes(String(ticket.categoryId));
      }

      // hide only non visible tickets
      if (ticket.visible !== false) {
        return true;
      }

      // check if hidden with link tickets should be shown
      if (ticket.code) {
        return this.isCategoryVisible(ticket as any);
      }

      return true;
    };
  }

  /**
   * Checks wether a product category is visitble
   * @param pcId Product category id
   */
  get isPCVisible(): (pc: IProductCategory) => boolean {
    return (pc: IProductCategory) => {
      return this.visibleTickets.includes(String(pc.id));
    };
  }

  @Mutation
  public setHostUrl(url: string) {
    this.hostUrl = url;
  }

  @Mutation
  public setCustomLinksData(custLinksData: ICustomLinksData) {
    this.customLinksData = custLinksData;
  }

  @Mutation
  public setCustomText(custText: string) {
    this.customText = custText;
  }

  @Mutation
  public setCustomUrl(custUrl: string) {
    this.customUrl = custUrl;
  }

  @Mutation
  public setReferrerSite(siteUrl: string) {
    this.referrerSite = siteUrl;
  }

  // Use ReferrerModule.setReferr(referr as string) for setting referr
  @Mutation
  public setReferr(url: string) {
    this.referrer = url;
  }

  // Use ReferrerModule.setReferr(referr as string) for setting referr
  @Mutation
  public setWidgetOrigUrl(url: string) {
    this.widgetOrigUrl = url;
  }

  /*
   * Sets the promo code for a given user.
   * It is mainly used when a user is brought to the
   * site with a promo code in the url
   */
  @Mutation
  public setPromoCode(promoCode: string) {
    this.promoCode = promoCode;
  }

  /*
   * Reinitializes the modle to it's original state.
   * this is useful when a user finishes with a booking and
   * we don't want to count the referrer for another booking.
   */
  @Mutation
  public reInitModule() {
    this.promoCode = '';
    this.referrer = '';
  }

  /*
   * Sets the hidden by link categories that should be
   * be made visible.
   */
  @Mutation
  public setCategories(categories: string) {
    this.categories = categories;
  }

  /**
   *
   * Sets the categories that should be visible
   */
  @Mutation
  public setVisibleCategories(categories?: string) {
    if (!categories) {
      this.visibleTickets = [];
      return;
    }
    this.visibleTickets = categories.split(',');
  }

  /**
   *
   * Adds a category to become visible
   */
  @Mutation
  public addVisibleCategory(categoryId: number) {
    const stringId = String(categoryId);
    if (this.visibleTickets.includes(stringId)) {
      return;
    }

    this.visibleTickets.push(stringId);
  }

  @Mutation
  public setRetailIds(retailIds: string) {
    this.retailIds = retailIds;
  }

  @Mutation
  public setNewCalendar(newCalendar: string) {
    this.newCalendar = newCalendar;
  }

  @Mutation
  public setNewTimeslots(newTimeslots: string) {
    this.newTimeslots = newTimeslots;
  }

  @Mutation
  public setNumDaysNewCalendar(numDaysNewCalendar: string) {
    this.numDaysNewCalendar = numDaysNewCalendar;
  }

  @Mutation
  public setParentUrl(parentUrl: string) {
    this.parentUrl = parentUrl;
  }

  @Mutation
  public setTopLevelDomainUrl(topLevelDomainUrl: string) {
    this.topLevelDomainUrl = topLevelDomainUrl;
  }

  @Mutation
  public setFromWidget(fromWidget: string) {
    this.fromWidget = fromWidget;
  }

  @Mutation
  public setPaymentMethod(paymentMethod: string) {
    this.paymentMethod = paymentMethod;
  }

  /**
   *
   * makes a category invisible
   */
  @Mutation
  public remVisibleCategory(categoryId: number) {
    const stringId = String(categoryId);

    // get all tickets(categories) other than the concerned category
    const withoutCatArray = this.visibleTickets.filter((ticket) => ticket !== stringId);
    this.visibleTickets = withoutCatArray;
  }

  /**
   * Sets the from date that calendar tickets should show slots
   */
  @Mutation
  public setFromDate(from: string) {
    this.from = from;
  }

  /**
   * Sets the minimum calendar date
   */
  @Mutation
  public setMFromDate(mfrom: string) {
    this.mFrom = mfrom;
  }

  /**
   * Sets the maximum calendar date
   */
  @Mutation
  public setMToDate(mto: string) {
    this.mTo = mto;
  }

  /**
   * // Sets the utm source
   * @param val utm_source
   */
  @Mutation
  public setUtmSource(val: string) {
    this.utmSource = val;
  }

  /**
   * // Sets the utm medium
   * @param val utm_medium
   */
  @Mutation
  public setUtmMedium(val: string) {
    this.utmMedium = val;
  }

  /**
   * // Sets the utm campaign
   * @param val utm_campaign
   */
  @Mutation
  public setUtmCampaign(val: string) {
    this.utmCampaign = val;
  }

  /**
   * // Sets the utm channel
   * @param val utm_channel
   */
  @Mutation
  public setUtmChannel(val: 'iFrame' | 'b2c' | 'b2b') {
    this.utmChannel = val;
  }

  // Membership type setter
  @Mutation
  public setMemType(type: MemType) {
    this.memType = type;
  }

  // Membership id setter
  @Mutation
  public setMemIds(ids: number[]) {
    this.memIds = ids;
  }

  @Mutation
  public setFbp(fbp: string | null) {
    this.fbp = fbp;
  }

  @Mutation
  public setFbc(fbc: string | null) {
    this.fbc = fbc;
  }

  @Mutation
  public setFbclid(fbclid: string | null) {
    this.fbclid = fbclid;
  }

  @Mutation
  public setEventTestCode(eventTestCode: string | null) {
    this.eventTestCode = eventTestCode;
  }

  @Mutation
  public setgtmId(gtmId: string | null) {
    this.gtmId = gtmId;
  }

  /*
   * Checks whether a category is visible or not
   */
  get isCategoryVisible() {

    return (category: ICategory) => {
      // hidden with link categories are categories that have
      // a code set
      if (!category.code) {
        return true;
      }

      // check with Morgan on whether code should be equal to productId or categoryId
      // support both for now
      return !!this.catArray.find((id) => {
        return (id === category.code) || (id === String(category.categoryId));
      });
    };
  }
}

import { gql } from 'graphql-tag';

/**
 * Fetches booking waivers info using bookingId and bookingToken
 *
 * variable example
 * { "bookingToken": "da6a1f40aebab9bf01cebf3db378394b52a02e19abc2263a43", "bookingId": "42"}
 */
// tslint:disable-next-line
export const GET_BookingWaivers = gql`
  query getBookingWaivers($bookingId: String!, $bookingToken: String!) {
    ProductSlotBookingList(condition: {Id: {eq: $bookingId},GoerToken: {eq: $bookingToken}}) {
      Id
      Uuid
      GoerToken
      pssList: ProductSlotStateList {
        category: ProductSlotCategoryRead {
          WaiverTemplate
        }
        WaiverList {
          BookingId
          ProductSlotStateId
          FieldGroup
        }
      }
    }
  }
`;

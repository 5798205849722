






















































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';
import Recaptcha from '@/components/Recaptcha.vue';
import { ModalModule } from '@/utils/storemodules';
import { ModalStatus } from '@/models/definitions';
import { email, required, minLength, maxLength, between, sameAs } from 'vuelidate/lib/validators';
import { Validator } from '@/utils/validators';
import { IUserData } from '@/models/definitions';
import { getLanguageInt } from '@/utils/helpers';
import { Status } from '@/utils/status';
import Debug from 'debug';
import { userIdentityTracking } from '../utils/tracking';

const debug = Debug('smeetz:auth');

// const authModule = namespace('auth');
const namespace = 'auth';

@Component({
  components: {
    Recaptcha,
  },
  validations: {
    email: {
      required,
      email,
      maxLength: maxLength(255),
    },
    firstname: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(255),
      invalid: Validator.validName,
    },
    lastname: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(255),
      invalid: Validator.validName,
    },
    password: {
      minLength: minLength(8),
      hasUpperAndLower: Validator.hasUpperAndLower,
      hasNum: Validator.hasNumbers,
      hasSpecial: Validator.hasSpecialCharacter,
    },
    confirmPassword: {
      sameAsPassword: sameAs('password'),
    },
  },
})
export default class LocalRegisterForm extends Vue {
  public emailExists: boolean = false;
  public isLoading: boolean = false;
  public enableSubmit: boolean = false;
  public recaptcha: boolean = false;

  public email: string = '';
  public firstname: string = '';
  public lastname: string = '';
  public password: string = '';
  public confirmPassword: string = '';

  // Getters
  @Action('register', { namespace }) private register!: any;
  @ModalModule.Mutation('setStatus') private setStatus!: any;

  // get enableSubmit() {
  //   console.log(this.$v.$error);
  //   return !this.$v.$error && this.email;
  // }

  get canSubmit() {
    return this.enableSubmit && this.recaptcha;
  }

  // Watcher to ensure that email exists error is cleared
  @Watch('email')
  public onEmailChanged() {
    this.emailExists = false;
  }

  @Watch('$v.$error')
  public onErrorChanged(val: boolean, oldVal: boolean) {
    this.enableSubmit = !val;
  }

  public async submit() {
    if (!this.canSubmit) {
      debug('Cant click now');
      return;
    }

    userIdentityTracking({ email: this.email });
    // Collect user data
    const user: IUserData = {
      emailAddress: this.email,
      firstName: this.firstname,
      lastName: this.lastname,
      password: {
        first: this.password,
        last: this.confirmPassword,
      },
      languageId: getLanguageInt(this.$i18n.locale),
    };

    // Indicate that we are loading
    this.isLoading = true;

    try {
      await this.register(user);
    } catch (err) {
      const response = err.response;
      this.isLoading = false;

      if (!response) {
        debug(`Unexpected error in registering user ${err}`);
        return;
      } else if (Status[response.status] === 'Bad Request') {
        debug('Email was already taken');
        this.emailExists = true;
        return;
      }

      debug('Unexpected error in registering user', err);
      return;
    }

    this.isLoading = false;
    // navigate to home page
    this.$router.push({
      // path: '/',
      query: {
        email: this.email,
      },
    });

    // show modal with email conf
    this.setStatus(ModalStatus.EmailConf);
  }

  private captchaVerify() {
    this.recaptcha = true;
  }

  private captchaExpired() {
    this.recaptcha = false;
  }
}

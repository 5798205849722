



















































































import { Component, Vue, Watch } from 'vue-property-decorator';
import Nav from '@/components/NewNavigation.vue';
import Carousel from './presentational/Carousel.vue';
import Title from './presentational/Title.vue';
import DateInfo from './presentational/DateInfo.vue';
import Description from './presentational/Description.vue';
import Highlights from './presentational/Highlights.vue';
import Schedule from './presentational/Schedule.vue';
import Policies from './presentational/Policies.vue';
import Footer from '@/components/NewFooter.vue';
import SliderNav, { INavElement } from '@/components/SliderNav.vue';
import Widget from './presentational/Widget.vue';
import ImageViewer from './presentational/ImageViewer.vue';
import Share from './presentational/Share.vue';
import Report from './presentational/Report.vue';
import Socials from '@/components/presentational/Socials.vue';
import { ProductModule, AppModule, UserModule, NModalModule } from '@/utils/storemodules';
import { ModalStatus } from '@/models/definitions';
import { IProduct,
         IPicturesEntity,
         ILanguage,
         IProductHighlights,
         ISocialsInfo,
         IOrganiser} from '@/models/store/product';
import { ITitleData, IDateData, IPolicyData, IWidgetData } from '@/models/product';
import { checkForExist, setLangForMeta } from '@/utils/helpers';
import dayjs from 'dayjs';
import { Hooper, Slide, Pagination as HooperPagination } from 'hooper';
import { Meta } from '@/utils/decorators';
import { initBookingChannel } from '@/utils/siteInit';
import { BookingStatus } from '@/models/store/product';
import { productPageViewTracking } from '../../utils/tracking';
import { NavigationGuardNext, Route } from 'vue-router';
import { isToBooking } from '@/utils/router';
import { RouteNames } from '@/models/enums';
import { SITE_URL } from '@/config/constants';

@Component({
  components: {
    Nav,
    Carousel,
    Title,
    DateInfo,
    Description,
    Highlights,
    Schedule,
    Policies,
    Footer,
    Widget,
    ImageViewer,
    Share,
    Report,
    Socials,
    Hooper,
    Slide,
    SliderNav,
  },
})
export default class Product extends Vue {

  private get currentSlideCounts() {
    return window.innerWidth >= 1024 ? 6 : window.innerWidth >= 768 ? 4 : 3;
  }

  private get nextSlideButtonVisibility() {
    return (this.pageTags.length > this.currentSlideCounts) &&
    (this.currentSlide + this.currentSlideCounts < this.pageTags.length);
  }

  private get prevSlideButtonVisibility() {
    return this.currentSlide > 0;
  }

  private get pageTags(): INavElement[] {
    const alwaysInTags: INavElement[] = [
      {
        content: this.$t('product.share-title'),
        anchor: '#share',
      },
      {
        content: this.$t('product.date-info-title'),
        anchor: '#dateInfo',
      },
      {
        content: this.$t('product.policy-title'),
        anchor: '#policies',
      },
    ];
    if ( this.description ) {
      alwaysInTags.push({
        content: this.$t('product.description-title'),
        anchor: '#description',
      });
    }
    if ( this.socialsInfo ) {
      alwaysInTags.push({
        content: this.$t('product.socials-link-title'),
        anchor: '#socials',
      });
    }
    if ( this.highlightsData ) {
      alwaysInTags.push({
        content: this.$t('product.highlights-title'),
        anchor: '#highlights',
      });
    }

    return alwaysInTags;
  }

  private get showingTags() {
    return this.showingTagsMethod(this.currentSlide);
  }

  private get product(): IProduct | null {
    return ProductModule.product;
  }


  private get isMobile() {
    return this.windowWidth <= 768;
  }

  private get isTablet() {
    return this.windowWidth <= 968;
  }

  private get isSoldOut() {
    return ProductModule.isSoldOut;
  }

  private get pictureForMeta() {
    const primaryPicture = ProductModule.productPictures.find((slide) => slide.isPrimary === true && slide.type === 2);
    return primaryPicture ? primaryPicture.url : '';
  }

  private get pictureForStructedData() {
    const picture = ProductModule.productPictures.find((img) => img.typeName === 'Desktop Large');
    return picture ? picture.url : '';
  }

  private get showBackButton() {
    return this.currentSlide > 0;
  }

  private get showNextButton() {
    return this.currentSlide === 0;
  }

  private get imageViewerPictures() {
    return ProductModule.productDevicePictures;
  }

  private get offerAvailability() {
    if (this.bookingStatus === 1) {
      return 'https://schema.org/InStock';
    }
    return 'https://schema.org/SoldOut';
  }

  // Checks whether the page was already tracked
  private tracked = false;

  private bookingStatus: BookingStatus | null = null;
  private showImageViewer: boolean = false;
  private windowWidth: number = 0;
  private currentPictureIndex: number = 0;
  private description: string = '';
  private isLoading: boolean = false;
  private socialsInfo: ISocialsInfo | null = null;
  private widgetData: IWidgetData = {
    name: '',
    price: '0',
    currency: 'CHF',
    availability: false,
  };
  private policiesData: IPolicyData  = {
    bookingPolicy: '',
    cancelationPolicy: '',
  };
  private titleData: ITitleData = {
    title: '',
    teaser: '',
  };
  private dateData: IDateData = {
    start: '',
    end: '',
    languages: [],
    location: '',
    organiserName: '',
    organiserSlug: '',
    locationLatLng: null,
  };
  // TODO: add then have more information
  private highlightsData: IProductHighlights | null = null;
  private scheduleData = null;
  private currentSlide = 0;
  private itemsToShow: number = 6;

  private swiperOptions = {
    slidesPerView: 3,
    slidesPerGroup: 3,
    spaceBetween: 0,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      1024: {
        slidesPerView: 6,
      },
      768: {
        slidesPerView: 5,
      },
      320: {
        slidesPerView: 3,
      },
    },
  };

  @Watch('$store.state.auth.lang')
  public async onLangChanged(newLang: string) {
    this.isLoading = true;
    try {
      this.initProductInfo();
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  private changeSwiperIndex() {
    this.currentSlide = (this.$refs.mySwiper as any).swiperInstance.activeIndex;
  }

  @Watch('currentSlide')
  private onCurrentSlideChange(val: number) {
    this.showingTagsMethod(val);
  }

  private showingTagsMethod(start: number) {
    return this.pageTags.slice(start * this.itemsToShow, this.itemsToShow + this.itemsToShow * start);
  }

  private async created() {
    initBookingChannel(this.$route);

    this.isLoading = true;
    await this.initProductInfo();
  }

  @Watch('$store.state.auth.user')
  private async onLoggedIn(val: any) {
    if ( val ) {
      this.isLoading = true;
      await this.initProductInfo();
    }
  }

  private async initProductInfo() {
    const { short_name } = this.$route.params;
    try {
      await ProductModule.fetchProduct(short_name);
    } catch (err) {
      const data = err && err.response && err.response.data;
      if (data) {
        const response = err.response.data.messages[0];
        if ( response === 'User has not enough rights to view this product.') {
          if ( !UserModule.isLogedIn ) {
            NModalModule.setStatus(ModalStatus.NewLogin);
            this.isLoading = false;
          } else {
            alert(this.$t('product.not-available'));
            window.location.href = SITE_URL;
          }
        }
        if (response === 'Product not found.') {
          this.$router.replace({name: RouteNames.PageNotFound});
        }
      }
      return;
    }

    // make sure that we track content-view only once.
    // Otherwise, we will be tracking each time the user
    // changes the language.
    if (!this.tracked && this.product) {
      this.tracked = true;
      productPageViewTracking(this.product, this.$route);
    }

    //  Check if product !== null
    if ( this.product !== null) {
      // we'll commit a mutation to state.primaryColor if the product have a branding color
      const brandingColor = this.product.organiser
          && this.product.organiser.branding
          && this.product.organiser.branding.smeetzPrimaryColor;
      if (brandingColor) {
            AppModule.setColor(brandingColor);
          }
      this.getBookingStatus();
      this.getTitleData();
      this.getDateData();
      this.getDescription();
      this.getPolicies();
      this.getWidgetData();
      this.getHighlights();
      this.getProductSocials();
      if (this.isInfoComplete()) {
        this.structuredData();
      }
    }
    this.isLoading = false;
  }

  private getBookingStatus() {
    if ((this.product as IProduct).booking) {
      this.bookingStatus = (this.product as IProduct).booking.status;
    }
  }

  @Meta
  private metaInfo() {
    return {
      title: this.product ? (this.product as IProduct).name + ' | Smeetz' : '',
      link: [
        { rel: 'canonical', href: location.protocol + '//' + location.host + location.pathname },
      ],
      meta: [
        { hid: 'title', name: 'title', content: this.product ? (this.product as IProduct).name + ' | Smeetz' : '' },
        { hid: 'description', name: 'description', content: this.product ? (this.product as IProduct).teaser : '' },
        { property: 'fb:admins', content: '100001288015151' },
        { hid: 'og:title', property: 'og:title',
          content: this.product ? (this.product as IProduct).name + ' | Smeetz' : ''},
        { hid: 'og:description', property: 'og:description',
          content: this.product ? (this.product as IProduct).teaser : '' },
        { hid: 'og:type', property: 'og:type', content: 'page' },
        { hid: 'og:url', property: 'og:url', content: location.protocol + '//' + location.host + location.pathname },
        { hid: 'og:image', property: 'og:image', content: this.pictureForMeta },
        { hid: 'og:image:alt', property: 'og:image:alt',
          content: this.product ? (this.product as IProduct).name + ' ' + this.$t('common.image') : '' },
        { hid: 'og:locale', property: 'og:locale',
          content: setLangForMeta(this.$route.params.locale || this.$i18n.locale)},
        // Uncomment if we will show content relateed to lang in url
        // { hid: 'noindex', name: 'robots, follow', content: 'noindex' },
      ],
    };
  }

  private getProductSocials() {
    const product = this.product as IProduct;
    const branding = product.organiser && product.organiser.branding;
    const { website,
            facebookWebsite,
            instagramWebsite,
            linkedinWebsite,
            youtubeWebsite } = branding || {};

    const socialsObject = {
      website,
      facebook: facebookWebsite,
      instagramm: instagramWebsite,
      linkedin: linkedinWebsite,
      youtube: youtubeWebsite,
    };
    function checkEveryParameterForEmpty(obj: any) {
      for (const i of Object.keys(obj)) {
        if ( obj[i] ) {
          return true;
        }
        return false;
      }
    }
    this.socialsInfo = checkEveryParameterForEmpty(socialsObject) ? socialsObject : null;
  }

  // Get highlights from product data
  private getHighlights() {
    // Check for highlights in product exist and them !== null
    if ( checkForExist((this.product as IProduct).productHighlights) ) {
      this.highlightsData = (this.product as IProduct).productHighlights;
    }
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  private destroyed() {
    window.removeEventListener('resize', this.handleResize);
    this.removeStructedData();
  }

  private beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
    // we're leaving booking flow, so lets clear the color we used from css and from state.primaryColor
    // only when not going to booking page
    if (!isToBooking(to)) {
      AppModule.clearBookingColors();
    }
    return next();
  }

  private handleResize() {
    this.windowWidth = window.innerWidth;
  }

  private getInfo(payload: any) {
    this.currentSlide = payload.currentSlide;
  }

  private slideNext() {
    // (this.$refs.anchorsNav as any).slideNext();
    this.currentSlide = this.currentSlide + 1;
    // console.log(this.$refs.anchorsNav);
  }

  private slidePrev() {
    this.currentSlide = this.currentSlide - 1;
    // (this.$refs.anchorsNav as any).slidePrev();
  }

  // Get data for title from product info
  private getTitleData() {
    // If name exist in product and it !== ''
    if ( (this.product as IProduct).name && (this.product as IProduct).name.length !== 0) {
      this.titleData.title = (this.product as IProduct).name;
    }
    // If teaser exist in product and it !== ''
    if ( (this.product as IProduct).teaser && (this.product as IProduct).teaser.length !== 0) {
      this.titleData.teaser = (this.product as IProduct).teaser;
    }
  }

  // Check if string !== null, ' '
  private checkString(target: string | null) {
    if ( target === '' || target === ' ' || target === null) {
      return '';
    } else {
      return target + ' ';
    }
  }

  // Get data for date block from product info
  private getDateData() {
    const overview = (this.product as IProduct).overview;
    const location = (this.product as IProduct).location;
    const languages = (this.product as IProduct).languages;
    const organiser = (this.product as IProduct).organiser;
    if ( checkForExist(organiser) ) {
      this.dateData.organiserName = (organiser as IOrganiser).name;
      this.dateData.organiserSlug = (organiser as IOrganiser).slugName;
    }
    // If overview exist in product and it !== null and if nextDateTime exist in product and it !== null
    if ( checkForExist(overview) && checkForExist(overview.nextDateTime)) {
      // If startDateTime exist in product and it !== null
      if ( checkForExist(overview.nextDateTime.startDateTime)) {
        const startDate = overview.nextDateTime.startDateTime;
        // this.dateData.start = dayjs(startDate).format('ddd, D MMM YYYY HH:mm');
        this.dateData.start = startDate;
      }
      // If endDateTime exist in product and it !== null
      // if ( checkForExist(overview.nextDateTime.endDateTime)) {
      if ( checkForExist(overview.lastDateTime.endDateTime)) {
        const endDate = overview.lastDateTime.endDateTime;
        // this.dateData.end = dayjs(endDate).format('ddd, D MMM YYYY HH:mm');
        this.dateData.end = endDate;
      }
    }
    // If languages exist in product and it !== null and it length !== 0
    if ( checkForExist(languages) && languages && languages.length !== 0) {
      this.dateData.languages = languages;
    }
    // If location exist in product and it !== null and it length !== 0
    if ( checkForExist(location) ) {
      const city = this.checkString(location.city);
      const country = this.checkString(location.country);
      const address = this.checkString(location.address);
      const name = this.checkString(location.name);
      const zip = this.checkString(location.zip);
      this.dateData.location = name.concat(address, zip, city, country);
      this.dateData.locationLatLng = {
        lat: location.lat,
        lng: location.lng,
      };
    }
  }

  // Get description for description block from product info
  private getDescription() {
    const description = (this.product as IProduct).description;
    // If description exist in product and it !== null and it length !== 0
    if ( checkForExist(description) && description.length !== 0 ) {
      this.description = description;
    }
  }

  // Get policies for policies block from product info
  private getPolicies() {
    const organizerInfo = (this.product as IProduct).organiser;
    // If policies exist in product and it !== null and it length !== 0
    if ( organizerInfo ) {
      const bookingPolicy = organizerInfo.branding && organizerInfo.branding.bookingPolicy;
      if ( bookingPolicy ) {
        this.policiesData.bookingPolicy = bookingPolicy;
      } else {
        const defaultDesc = this.$t('product.policy-default-booking');
        this.policiesData.bookingPolicy = defaultDesc as string;
      }
    } else {
      const defaultDesc = this.$t('product.policy-default-booking');
      this.policiesData.bookingPolicy = defaultDesc as string;
    }
  }

  // Get widget data for widget block from product info
  private getWidgetData() {
    const product = this.product as IProduct;
    const { overview, booking } = product;
    // If overview  and rangePrice inside exist in product and it !== null
    if ( checkForExist(overview) && checkForExist(overview.rangePrice)) {
      // If fromPrice  and fromPriceCurrency exist in product and it !== null
      if ( checkForExist(overview.rangePrice.fromPrice) && checkForExist(overview.rangePrice.fromPriceCurrency)) {
        this.widgetData.price = overview.rangePrice.fromPrice.toFixed(2);
        // this.widgetData.currency = overview.rangePrice.fromPriceCurrency;
        this.widgetData.currency = ProductModule.productCurrency;
      } else if ( overview.rangePrice.fromPrice === null ) {
        this.widgetData.price = null;
      }
    }
    // If booking  and status inside exist in product and it !== null
    if ( checkForExist(booking) && booking.status !== null) {
      this.widgetData.availability = booking.status === 1 || booking.status === 0;
    }
    // If name exist in product and it !== null
    if ( checkForExist((this.product as IProduct).name) ) {
      this.widgetData.name = (this.product as IProduct).name;
    }
  }

  // Trigger show image viewer
  private triggerImageViewer(index: number) {
    if ( window.innerWidth <= 2880 ) {
      this.currentPictureIndex = index;
      this.showImageViewer = !this.showImageViewer;
      document.documentElement.style.overflow = this.showImageViewer ? 'hidden' : '';
    }
  }

  private goBooking() {
    const id = (this.product as IProduct).id;
    const short_url = (this.product as IProduct).short_url;
    document.documentElement.style.overflow = '';
    this.$router.push(`/product/${id}/booking/${short_url}`);
  }

  private removeStructedData() {
    const script = document.getElementById('structuredData') as HTMLElement;
    if (script) {
      document.head.removeChild(script);
    }
  }

// Check if we have all the needed data for structured data
  private isInfoComplete() {
    const {start, end, location, organiserName, organiserSlug} = this.dateData;
    const {name, price, currency} = this.widgetData;
    const short_url = (this.product as IProduct).short_url;
    const allInfo = [
      start,
      end,
      location,
      organiserName,
      organiserSlug,
      name,
      price,
      currency,
      this.pictureForStructedData,
      this.titleData.teaser,
      this.offerAvailability,
      short_url,
    ];

    // Check if all the data in allInfo is valid
    const result = allInfo.every((e) => e !== null && e !== undefined && e !== '');
    return result;
  }

//  Structured data makes it easier for people to discover and attend events through Google Search results
  private structuredData() {
    const short_url = (this.product as IProduct).short_url;
    const eventData =  {
      '@context': 'https://schema.org',
      '@type': 'Event',
      'name': this.widgetData.name ,
      'startDate': this.dateData.start,
      'endDate': this.dateData.end,
      'eventAttendanceMode': 'https://schema.org/OfflineEventAttendanceMode',
      'eventStatus': 'https://schema.org/EventScheduled',
      'location': {
        '@type': 'Place',
        'name': this.dateData.location,
        'address': {
          '@type': 'PostalAddress',
          'name': this.dateData.location,
        },
      },
      'image': [
        this.pictureForStructedData,
       ],
      'description': this.titleData.teaser,
      'offers': {
        '@type': 'Offer',
        'url': `${SITE_URL}/product/${short_url}`,
        'price': this.widgetData.price,
        'priceCurrency': this.widgetData.currency,
        'availability': this.offerAvailability,
        'validFrom': this.dateData.start,
      },
      'organizer': {
        '@type': 'Organization',
        'name': this.dateData.organiserName,
        'url': `${SITE_URL}/org/${this.dateData.organiserSlug}`,
      },
    };
    const script = document.createElement('script');
    script.id = 'structuredData';
    script.setAttribute('type', 'application/ld+json');
    script.innerHTML = JSON.stringify(eventData);
    document.head.appendChild(script);
  }

}
















































































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Mixins } from 'vue-mixin-decorator';
import Timeout from 'await-timeout/dist/es5';
import {  AppModule, NBookingModule, NNoty,
OrganizerModule, ProductModule, ReferrerModule, NModalModule } from '@/utils/storemodules';
import { ModalStatus } from '@/models/definitions';
import DateHelperMixin from '@/mixins/DateHelper';
import TicketHelperMixin from '@/mixins/TicketHelper';
import DonationMixin from '@/mixins/Donation';
import AcompteMixin from '@/mixins/Acompte';
import { isRealMembershipDiscount } from '@/utils/membership-utils';
import { ticketSelectedSlots, slotSelectedPrices, isGiftTicket, seatParentTicket,
hideEndDateTime, hideStartDateTime, hideDateTime } from '../../../utils/booking';
import { IFrontRecapTicket,
IServerRecapCategory,
IPriceCountLite,
IMembershipCustomer,
ITicket,
ICustomerTicketRecap } from '../../../models/store/booking';
import ToolTipIcon from '@/components/presentational/ToolTipIcon.vue';
import NewButton from '@/components/presentational/NewButton.vue';
import { BookingSteps } from '@/store/modules/newBooking/constants';
import { getElementById, scrollToElement } from '@/utils/dom';
import { cancelPSS, patchBookingOrder } from '@/api/booking';
import { disableQuasarStyling, enableQuasarStyling } from '@/utils/styles';
import { Loading } from 'quasar';
import { getCartBookingInfo, sendCartBookingInfo } from '@/utils/iframe';
import { SeatType, EventType, CategoryType } from '@/models/enums';
import { smeetzTracker } from '@/utils/tracking';
import { MembershipType } from '@/models/memberships';
import Debug from 'debug';
import {
  didAllCustomersBookRequiredMembershipTickets,
  isRequiredTicketsMembership,
  membershipPriceWithError,
  requiredTicketsMemberships,
} from '@/utils/membership';
import PromoInput from './PromoInputButton.vue';
import { withoutReplicatePromocode } from '@/utils/promocode';
const debug = new Debug('smeetz:seats');



@Component({
  components: {
    ToolTipIcon,
    NewButton,
    PromoInput,
  },
})
export default class OrderSummary extends Mixins<TicketHelperMixin & AcompteMixin & DonationMixin>
  (TicketHelperMixin, AcompteMixin, DonationMixin) {
// export default class OrderSummary extends Mixins<DateHelperMixin>(DateHelperMixin) {
  @Prop({default: 0}) private windowWidth!: number;
  @Prop({default: false}) private inBasket!: boolean;
  @Prop({default: false}) private inCheckoutBasket!: boolean;
  private orderActive: boolean = false;
  private loading = false;
  private donationCheckBox: boolean = false;
  private MembershipType = MembershipType;
  private FlipLabIds = [19319, 19543, 19544, 19545, 19546];

  // get isMultiProducts(): boolean {
  //   return  ProductModule.isMultipleProducts;
  // }

  get isCustomerBookingInsideBasket() {
    return (NBookingModule.isCustomerBooking && this.isCartWidget);
  }

  get isCartWidget(): boolean {
    // return true;
    return AppModule.isCartWidget;
  }

  get products() {
    return ProductModule.products;
  }

  private get listPromoApplied() {
    const promos = NBookingModule.listPromoApplied;

    if (!promos) {
      return [];
    }

    return withoutReplicatePromocode(promos);
  }

  get total(): number {
    let totalMembershipPrice = 0;
    const membership = NBookingModule.membership;
    if (isRequiredTicketsMembership(membership) && ProductModule.addonsTicketsTotalPrice) {
      totalMembershipPrice = ProductModule.addonsTicketsTotalPrice;
    }
    return this.recapInfo.total >= totalMembershipPrice ? this.recapInfo.total : totalMembershipPrice;
  }

  private getProductName(productId: number | undefined, timeSlotId: number | undefined): string {
    // Why the below is casted as any?
    // Because the type IServerRecapCategory doesn't have a property 'productName', yet I can get it
    const category =
    NBookingModule.bookingRes?.bookingRecap.categories?.filter((c) => Number(c.timeSlotId) === timeSlotId)[0] as any;
    return category.productName || this.products.filter((p) => p.id === productId).map((p) => p.name)[0] || '';
  }

  private get selectedTickets() {
    return NBookingModule.selectedTickets;
  }

  get chosenPrices() {
    return slotSelectedPrices;
  }

  get ticketSlots() {
    return ticketSelectedSlots;
  }

  get recapInfo() {
    return NBookingModule.recapInfo;
  }

  get membership() {
    return NBookingModule.membership;
  }

  get displayPricesOfRequiredTicket() {
    return ProductModule.displayPrices;
  }

  get requiredTicketsMembershipsGetter() {
    if (this.membership) {
      return requiredTicketsMemberships(this.membership);
    }

    return [];
  }

  get membershipPWithError() {
    if (!this.membership) {
      return {};
    }
    return membershipPriceWithError(this.membership);
  }

  get errorMembership() {
    // Ensure that all customers in a required tickets memebership bought tickets
    const membership = NBookingModule.membership;
    const membershipCustomers = NBookingModule.membershipCustomers;
    if (
      isRequiredTicketsMembership(membership) &&
      !didAllCustomersBookRequiredMembershipTickets(
        membershipCustomers,
        membership,
      )
    ) {
      return this.$t('error.membership-shows-required');
    }
    return ``;
  }

  get discountMembership() {
    const appliedMembership = NBookingModule.membershipApplied;

    if (appliedMembership) {
      let total = 0;

      for (const pss of appliedMembership.productSlotStates) {
        total += pss.priceValueDefault - pss.priceValue;
      }

      return total;
    }

    return 0;
  }

  get subTotal() {
    const appliedMembership = NBookingModule.membershipApplied;

    if (appliedMembership) {
      return this.recapInfo.subTotal + this.discountMembership;
    }

    if (this.recapInfo.deposit && !this.isAcompteBooking && !this.isAcompteEqualToTotal) {
      return this.recapInfo.subTotal + this.recapInfo.deposit;
    }

    const membership = NBookingModule.membership;
    if (isRequiredTicketsMembership(membership)) {
      return ProductModule.addonsTicketsTotalPrice || this.recapInfo.subTotal;
    }

    return this.recapInfo.subTotal;
  }

  get isRegularMembership() {
    return NBookingModule.membership && NBookingModule.membership.type === MembershipType.RegularMembership;
  }

  get orderRecap() {
    return NBookingModule.orderRecap.map((ticket) => {
      return {
        ...ticket,
        ticket: NBookingModule.ticketById(Number(ticket.categoryId)),
        productName: this.isCartWidget ?
        this.getProductName(
          NBookingModule.ticketById(Number(ticket.categoryId))?.productId,
          ticket.timeSlotId) : '',
        };
    });
  }

  get ticketsRecap() {
    return this.orderRecap.filter((cat) => cat.categoryType !== CategoryType.Donation);
  }

  get donationTicket() {
    return  this.orderRecap.filter((cat) => cat.categoryType === CategoryType.Donation);
  }

  get ticketRecapToUI() {
    if (NBookingModule.isCustomerBooking) {
      return this.ticketRecapGroupedbyCustomer;
    } else {
      return this.ticketsRecap;
    }
  }

  get finalTicketRecapToUI() {
    const tickets = [];
    const retails = [];
    for (const d of this.ticketRecapToUI) {
      if (d && d.prices && d.prices.length && !d.prices[0].retailOptionId) {
        tickets.push(d);
      } else {
        d.isRetail = true;
        retails.push(d);
      }
    }
    // @ts-ignore
    const finalTickets =  [...tickets, ...retails];
    return finalTickets;
  }
  get shippingTicket() {
    const bookingRes = NBookingModule.bookingRes;
    if (!bookingRes || !bookingRes.bookingRecap.categories) {
      return null;
    }
    const tickets = bookingRes.bookingRecap.categories;
    const shippingProductCategory = tickets.filter((c) => c.isShipping === true);
    if (shippingProductCategory.length < 1) { return null; }
    return shippingProductCategory;
  }

  get ticketRecapGroupedbyCustomer(): ICustomerTicketRecap[] {
    const recapByCustomer: ICustomerTicketRecap[] = [];
    const ticketRecap = this.ticketsRecap;
    const { membershipCustomers } = NBookingModule;

    // 1- start from membershipCustomers to compose an array of customers/timeslots,

    // 2- then use ticketRecap to bring remaining tickets data from 'prices' array
    //    and from 'ticket' object

    // 1
    for (const cst of membershipCustomers) {
      for (const customerTicket of cst.tickets) {
        const seatType = NBookingModule.getSeatPricesTypes.find((p) =>
          p.priceId === customerTicket.priceId)?.seatType;
        recapByCustomer.push({
          firstname: cst.firstName,
          lastname: cst.lastName,
          fullname: cst.firstName + ' ' + cst.lastName,
          categoryId: customerTicket.categoryId,
          priceId: customerTicket.priceId,
          seat: customerTicket.seat,
          seatType,
          displayRemoveButton: seatType === SeatType.GeneralAdmission ? false : true,
          timeSlotId: customerTicket.timeSlotId,
          pssId: customerTicket.pssId,
          ticket: this.ticketsRecap.filter((t) =>
            this.isRegularMembership ? t.timeSlotId === customerTicket.timeSlotId :
            t.timeSlotId === customerTicket.timeSlotId && t.seat === customerTicket.seat,
          )[0].ticket,
        });
      }
    }


    // 2
    const clonedPrices = ticketRecap.map((t) => {
      const clonedP: IServerRecapCategory[] = [];
      t.prices.forEach((p) => clonedP.push({...p}));
      return clonedP;
    });
    const clonedPricesUni: IServerRecapCategory[] = Array.prototype.concat.apply([], clonedPrices);

    for (const price of clonedPricesUni) {
      // take data from price object into recapByCustomer array
      if (!price.quantity || price.quantity === '' || price.quantity === '0') {
        continue;
      }

      const customersFilteredByPrice = recapByCustomer.filter((r) =>
        r.timeSlotId === Number(price.timeSlotId)
        && r.seat === price.seat
        && r.priceId === Number(price.priceId),
      );

      for (const f of customersFilteredByPrice) {
        // indexOf will use object f reference to get its index in the array
        // we're objects comparing by reference, not their values
        recapByCustomer[recapByCustomer.indexOf(f)].productId = Number(price.productId);
        recapByCustomer[recapByCustomer.indexOf(f)].productName = price.productName;
        recapByCustomer[recapByCustomer.indexOf(f)].categoryName = price.categoryName;
        recapByCustomer[recapByCustomer.indexOf(f)].endDateTime = price.endDateTime;
        recapByCustomer[recapByCustomer.indexOf(f)].startDateTime = price.startDateTime;
      }
    }

    for (const rec of recapByCustomer) {
      rec.prices = clonedPricesUni.filter((p) =>
        Number(p.timeSlotId) === rec.timeSlotId
        && (p.seat ? p.seat === rec.seat : true) // avoid checking seats for non seating tickets
        && Number(p.priceId) === rec.priceId,
      );
      // recalculate prices values because we will show them by customer
      // and reset quantity to 1 because one customer cannot
      // have more than 1 seat in the same time
      rec.prices.forEach((p) => {
        p.priceValue = p.priceValue / Number(p.quantity);
        p.priceValueDefault = p.priceValueDefault / Number(p.quantity);
        p.quantity = '1';
      });
    }

    // Sort the result by fullname before returning it
    return recapByCustomer.sort((a, b) => {
      const aName: string = a.fullname ? a.fullname : '';
      const bName: string = b.fullname ? b.fullname : '';
      return aName.localeCompare(bName);
    });
  }

  get currency() {
    return ProductModule.productCurrency;
  }

  get hideDate() {
    // Todo, fix for gift tickets
    return (ticket: IFrontRecapTicket) => {
      const foundTicket = NBookingModule.ticketById(ticket.categoryId);
      if (!foundTicket) {
        return false;
      }
      // return isGiftTicket(foundTicket) || isMembershipTicket(foundTicket);
      return isGiftTicket(foundTicket);
    };
  }

  private async addOrDeleteDonation(deleteDonation: boolean = true) {
    this.loading = true;
    try {
      await NBookingModule.addOrDeleteDonation(deleteDonation);
    } catch (error) {
      setTimeout(() => { throw error; }, 0);
    }
    this.loading = false;
  }

  private openBookingDonationModal() {
    NModalModule.setStatus(ModalStatus.Donation);
  }

  private stepToOrder() {
    NBookingModule.stepTo(BookingSteps.Order);
  }

  private areDatesShown(ticket: ITicket | IFrontRecapTicket) {

    // In case we are using IFrontRecapTicket
    if (ticket && ticket.hideDateTime) {
      return !hideDateTime(ticket);
    }

    if (!ticket || !ticket.type) {
      return true;
    }
    return !hideDateTime(ticket);
  }


  private isStartHidden(ticket: ITicket | IFrontRecapTicket) {

    // In case we are using IFrontRecapTicket
    if (ticket && ticket.hideDateTime) {
      return hideStartDateTime(ticket);
    }

    if (!ticket || !ticket.type) {
      return false;
    }
    return hideStartDateTime(ticket);
  }

  // Hiding end date time for equilibre for now
  private isEndHidden(ticket: ITicket | IFrontRecapTicket) {
    const orgId = OrganizerModule.id;
    if (orgId && orgId === 16192) {
      return true;
    } else if (ticket && ticket.hideDateTime) { // In case we are using IFrontRecapTicket
      return hideEndDateTime(ticket);
    } else if (!ticket || !ticket.type) {
      return false;
    } else if (hideEndDateTime(ticket)) {
      return true;
    }

    return false;
  }

  private created() {
    if (this.inBasket) {
      this.orderActive = true;
    }

    if (this.isRegularMembership) {
      this.orderActive = false;
    }
  }

  private async mounted() {
    // Make order summary visible by default for FlipLab
    if (this.FlipLabIds.includes(Number(OrganizerModule.id))) {
      this.orderActive = true;
    }
    // We check if there are donation(s) linked to added tickets
    await this.checkDonation();
    if (this.donationCategory) {
      this.orderActive = true;
    }
    // If user has already sumbitted booking we won't add donation automatically when going back from payment
    if (this.donationCategory && this.total !== 0 && !NBookingModule.submittedBooking) {
      await this.addOrDeleteDonation(false);
    }
  }


  private triggerOrderActive() {
    this.orderActive = !this.orderActive;
  }

  private displayRemoveButton(price: IServerRecapCategory): boolean {
    debug('hi from displayRemoveButton()');
    const seatType = NBookingModule.getSeatPricesTypes.find((p) =>
          p.priceId === Number(price.priceId))?.seatType;
    return !(seatType === SeatType.GeneralAdmission);
  }


  /**
   * Removes a price or one productSlotState from order summary.
   * Removing productSlotState happans only if pssId was provided, which means we're in memberShipBooking
   * Will move back to order page if not tickets are left in the cart.
   */
  private async remove(price: IServerRecapCategory, pssId?: number) {
    debug('hi from remove()');
    debug('price', price);
    debug('pssId', pssId);

    debug('NBookingModule.isCustomerBooking', NBookingModule.isCustomerBooking);
    debug('this.ticketRecapGroupedbyCustomer', this.ticketRecapGroupedbyCustomer);
    debug('this.ticketsRecap', this.ticketsRecap);
    debug('I WILL DELETE');
    try {
      enableQuasarStyling();
      Loading.show();
      // enable scrolling again when go from the basket to order page
      if (this.inCheckoutBasket) {
        document.documentElement.style.overflow = '';
        const newBooking: HTMLElement | null =  document.querySelector('.new-booking');
        if (newBooking) {
          newBooking.style.position = '';
        }
      }
      const { bookingId, bookingToken } = NBookingModule;
      let pssList: number[] = [];
      if (pssId) {
        pssList.push(pssId);
      } else {
        const productId = price.productId;
        await NBookingModule.fetchBooking({bookingToken, bookingId});
        // Make sure that the category is already fetched
        // Don't worry about performance. This is a no op on normal booking :)
        await NBookingModule.fetchProductsTickets([productId]);
        const bookingRes = NBookingModule.bookingRes;

        if (!bookingRes) {
          throw new Error('No booking object');
        }

        const categories = bookingRes.bookingRecap.categories;
        if (!categories) {
          throw new Error('No categories are found in the booking');
        }

        const category = categories
          .find((c) => Number(c.priceId) === Number(price.priceId)
          && Number(c.categoryId) === Number(price.categoryId)
          && c.seat === price.seat);

        if (!category) {
          throw new Error('No category was found in recap');
        }

        const slots = bookingRes.slots;
        if (!slots || !slots.length) {
          throw new Error('No pss were found');
        }

        debug('LOOKING FOR THE RIGHT PSS');
        debug('slots', slots);
        debug('category.timeSlotId', category.timeSlotId);
        debug('category.seat', category.seat);
        debug('category.priceId', category.priceId);

        if (category.retailOptionId) {
          pssList = slots.filter((p) => p.priceId === Number(category.priceId))
          .map((p) => p.productSlotStateId);
        } else {
          pssList = slots.filter((p) => p.timeslotId === Number(category.timeSlotId)
          && p.seat === category.seat
          && p.priceId === Number(category.priceId))
          .map((p) => p.productSlotStateId);
        }
        if (!pssList.length) {
          throw new Error('Category with no matching pss');
        }
      }

      const payload = {
        bookingId,
        bookingToken,
        releaseSeats: 0 as (1 | 0),
        tickets: pssList,
      };

      if (price.seat) {
        payload.releaseSeats = 1;
      }
      debug('payload', payload);
      const response = await cancelPSS(payload);

      if (response && response.status === 'success') {
        // object that has the data we send for smeetz tracking
        // this data will be used for dynamic pricing.
        const smtzTrackingData = {
          catId: price.categoryId,
          pId: price.productId,
          prId: Number(price.priceId),
          prVal: price.priceValue,
          qt: Number(price.quantity),
        };
        smeetzTracker(EventType.ticketRemoved, smtzTrackingData);
        // TODO remove next comment once BUD-8305 is tested
        // if (ReferrerModule.isMembershipOnly) {
        if (NBookingModule.isCustomerBooking) {
          // Lets update the membershipCustomers in the state
          const {membershipCustomers, membership} = NBookingModule;
          const updatedMembershipCustomers: IMembershipCustomer[] = [];
          for (const customer of membershipCustomers) {
            const cus: IMembershipCustomer = {
              firstName: customer.firstName,
              lastName: customer.lastName,
              tickets: customer.tickets.filter((t) => {
                if (t.pssId !== undefined && payload.tickets.includes(t.pssId)) {
                  // Do nothing it is the one we have removed
                } else {
                  return t;
                }
              }),
            };
            updatedMembershipCustomers.push(cus);
          }
          NBookingModule.setMembCustomers(updatedMembershipCustomers);

          if (NBookingModule.membership && membership && isRealMembershipDiscount(membership)) {
            await NBookingModule.applyMembership();
          }
        }

        // Updating booking info
        await NBookingModule.fetchBooking({bookingToken, bookingId});
        // Check if we have only donation pss left
        const productSlotStates = (NBookingModule.bookingRes as any).slots;
        if (productSlotStates.length === 1 && productSlotStates[0].ticketType === CategoryType.Donation) {
          const payloadDonation = {
            bookingId,
            bookingToken: NBookingModule.bookingToken,
            releaseSeats: 0 as (1 | 0),
            tickets: [productSlotStates[0].productSlotStateId],
          };

          await cancelPSS(payloadDonation);
          await NBookingModule.fetchBooking({bookingToken, bookingId});
          if (ProductModule.piazzaShowsAddons.length > 0) {
            ProductModule.setAddonTicketsShowsPrices({});
            ProductModule.setPizzaShowsAddons([]);
            ProductModule.setRequiredNumberPiazzaSeats(0);
            ProductModule.setRequiredNumberPiazzaSeatsPerCategory([]);
          }
        }
        // Remove shipping ticket if no retail left
        await NBookingModule.addRemoveShippingTicket();
        if (AppModule.isCartWidget) {
          sendCartBookingInfo(getCartBookingInfo());
        }
      } else {
        NNoty.createNewNoty({
          period: 4000,
          message: String(this.$t('error.error-response-banner')),
          type: 'error',
        });
      }

      Loading.hide();
      disableQuasarStyling();

      // Go back to order page if we removed all categories
      if (!this.ticketsRecap.length) {
        if (!this.inCheckoutBasket && this.inBasket) {
          NBookingModule.setShowBasket(false);
        } else {
          NBookingModule.stepTo(BookingSteps.Order);
          // If we have second step addons we go directly to the first step
          if (!ProductModule.hideAddons) {
            NBookingModule.stepBack();
          }
        }
      }
      debug('FINISHED DELETING');
      debug('NBookingModule.isCustomerBooking', NBookingModule.isCustomerBooking);
      debug('this.ticketRecapGroupedbyCustomer', this.ticketRecapGroupedbyCustomer);
      debug('this.ticketsRecap', this.ticketsRecap);
    } catch (error) {
      Loading.hide();
      disableQuasarStyling();
      NNoty.createNewNoty({
        period: 4000,
        message: String(this.$t('error.error-response-banner')),
        type: 'error',
      });
      throw error;
    }
  }

  /**
   * Method invoked when editing a price
   */
  private async edit(price: IServerRecapCategory, ticket: IFrontRecapTicket) {
    const { productId } = price;
    // enable scrolling again when go from the checkout basket to order page
    if (this.inCheckoutBasket) {
      document.documentElement.style.overflow = '';
      const newBooking: HTMLElement | null =  document.querySelector('.new-booking');
      if (newBooking) {
        newBooking.style.position = '';
      }
    }
    // Make sure that the category is already fetched
    // Don't worry about performance. This is a no op on normal booking :)
    try {
      enableQuasarStyling();
      Loading.show({delay: 300});
      await NBookingModule.fetchProductsTickets([productId]);
    } catch (error) {
      NNoty.createNewNoty({
        period: 4000,
        message: String(this.$t('error.error-response-banner')),
        type: 'error',
      });
      throw error;
    } finally {
      Loading.hide();
      disableQuasarStyling();
    }

    let categoryId = Number(price.categoryId);

    // if we are dealing with a seating plan, fetch it's parent ticket
    if (price.seat) {
      const parent = seatParentTicket(categoryId);

      if (!parent) {
        throw new Error('No parent ticket found');
      }

      categoryId = parent.categoryId;
    }

    // Hide basket or Go back to order step.
    if (!this.inCheckoutBasket && this.inBasket) {
      // block closing the cart when hiding the basket
      NBookingModule.setCloseCart(false);
      NBookingModule.setShowBasket(false);
      // Make sure that this is the only ticket visible for cart widgets
      if (AppModule.isCartWidget) {
        ReferrerModule.setVisibleCategories();
        ReferrerModule.setVisibleCategories(String(categoryId));
        // ReferrerModule.addVisibleCategory(Number(categoryId));
      }

      // make sure that the user can still close the cart in the future
      setTimeout(() => {NBookingModule.setCloseCart(true); }, 2000);
    } else {
      // Make sure that this is the only ticket visible for cart widgets
      if (AppModule.isCartWidget) {
        ReferrerModule.setVisibleCategories();
        ReferrerModule.setVisibleCategories(String(categoryId));
        // ReferrerModule.addVisibleCategory(Number(categoryId));
      }
      NBookingModule.stepTo(BookingSteps.Order);
      // If we have second step addons we go back to the main ticket display
      if (!ProductModule.hideAddons) {
        NBookingModule.stepBack();
      }
    }

    // Basically, we want to scroll to the ticket and highlight it a bit.

    // We wait for 300ms because the tickets need some time to render in the order step
    // TODO: Create an event on order page when tickets are rendered because this 300ms
    // is a dirty hack.
    NBookingModule.setEditedTicketIds({ticketId: categoryId, timeSlotId: price.timeSlotId});
    await Timeout.set(300);
    const ticketElement = getElementById(`ticket-${categoryId}`);

    if (ticketElement) {
      scrollToElement(ticketElement, AppModule.isMobile ? 50 : 200);
      // The scrolling is smooth, so we wait a bit before highliting the ticket
      await Timeout.set(300);
      const elm = ticketElement.querySelector('.ticket.overlay');
      if (elm) {
        // Highlight the element
        elm.classList.add('target-highlight');
        if (price.seat) {
          const btn = ticketElement.querySelector('a.button') as HTMLAnchorElement | null;
          if (btn) {
            btn.click();
          }
        }
        await Timeout.set(3000);
        elm.classList.remove('target-highlight');
      }
    } else {
      throw new Error(`No html element was found with category ${categoryId}`);
    }
  }

    // Watch if user fill all personal data fields for registration
  @Watch('donationCheckBox')
  private onChangeDonationCheckBox() {
    if ((this.donationCheckBox === true && this.donationTicket && this.donationTicket?.length > 0) ||
     (this.donationCheckBox === false && this.donationTicket && this.donationTicket?.length === 0)) {
      return;
    } else if (this.donationCheckBox === true) {
      this.addOrDeleteDonation(false);
    } else {
      this.addOrDeleteDonation();
    }
  }

  @Watch('donationTicket', {immediate: true, deep: true})
  private onChangeDonationTicket() {
    if (this.donationTicket && this.donationTicket.length > 0) {
      this.donationCheckBox = true;
    } else {
      this.donationCheckBox = false;
    }
  }

}































import { Vue, Component } from 'vue-property-decorator';
import UserSpaceCard from './components/UserSpaceCard.vue';
import { AuthModule, ModalModule } from '@/utils/storemodules';

@Component({
  components: {
    UserSpaceCard,
  },
})
export default class Organize extends Vue {

  @AuthModule.Getter('getPhones') private phones: any;
}























































































































































































































































































































































































import { postResendBooking, postResendBooking2 } from '@/api/user';
import { getTicketOrReceipt } from '@/api/booking';
import NewButton from '@/components/presentational/NewButton.vue';
import NewModalOverlay from '@/components/modal/NewModalOverlay.vue';
import {
  NBookingModule,
  NNoty,
  OrganizerModule,
  ProductModule,
RBookingModule,
  UserModule,
} from '@/utils/storemodules';
import { Vue, Component, Watch } from 'vue-property-decorator';
import { Mixins } from 'vue-mixin-decorator';
import {
  IGQLProductSlotBookingRead,
  IGQLProductSlotState,
  IRecapInfo,
  IGuestCheckoutUser,
  QuestionType,
IBookingField,
ITicketField,
} from '@/models/store/booking';
import { CategoryStatus, PaymentStatus, BookingStatus } from '@/models/enums';
import { getBookingFields } from '@/api/booking';
import { hideScrollBar } from '@/utils/styles';
import { setWidgetHeight } from '@/utils/iframe';
import DateHelperMixin from '@/mixins/DateHelper';
import NewNavigation from '@/components/NewNavigation.vue';
import UserSpaceField from './components/UserSpaceField.vue';
import Footer from '@/components/NewFooter.vue';
import { AppModule } from '@/utils/storemodules';
import { Validator } from '@/utils/validators';
import Noty from 'noty';
import { disableQuasarStyling, enableQuasarStyling } from '@/utils/styles';
import { Loading } from 'quasar';

interface IUserFields {
  FirstName: string;
  LastName: string;
  Email: string;
}
// const statusesForNewModal = [
//   ModalStatus.ContactOrganizer,
// ];

@Component({
  components: {
    NewButton,
    NewModalOverlay,
    NewNavigation,
    Footer,
    UserSpaceField,
  },
})
export default class BookingSummary extends Mixins<DateHelperMixin>(
  DateHelperMixin,
) {
  private get isMobile() {
    return AppModule.isMobile;
  }

  private get isTablet() {
    if ((AppModule.isTablet && window.innerWidth < 968) || AppModule.isMobile) {
      return true;
    }
  }

  get bookingStatus() {
    let state = CategoryStatus[this.booking!.State];
    // ex: turn BookingStatusConfirmed to confirmed to use it in the translation
    state = state.replace('BookingStatus', '');
    state = state.charAt(0).toLowerCase() + state.slice(1);
    // ex: pendingOrganizer to pending-organzier
    state = state.replace(/([A-Z])/g, '-$1').trim();
    return this.$t('user-space.booking-summary.booking-status-values.' + state.toLowerCase());
  }

  get isOrganizerUserSpace() {
    // return true;
    return OrganizerModule.isOrganizerUserSpace;
  }

  get recapInfo(): IRecapInfo {
    return NBookingModule.recapInfo;
  }

  get currency() {
    return ProductModule.productCurrency;
  }

  private get slotsGroupedByPriceName() {
    return RBookingModule.slotsGroupedByPriceName;
  }

  public PaymentStatus = PaymentStatus;
  public BookingStatus = BookingStatus;
  public QuestionType = QuestionType;
  private bookingId: number = 0;
  private isLoading: boolean = false;
  private isEdit = false;
  private save = false;
  private cancel = false;
  private booking: IGQLProductSlotBookingRead | null = null;
  private slots: IGQLProductSlotState[] = [];
  private organizerEmail: string = '';
  private showDeposit: boolean = false;
  private showAccountingArea: boolean = false;
  private showVAT: boolean = false;
  // booking custom fields
  private bookingFields: IBookingField[] | null = null;
  private ticketFields: ITicketField[] | null = null;

  private userInfo: IUserFields = {
    FirstName: '',
    LastName: '',
    Email: '',
  };
  private cancelDialog: boolean = false;
  private expandedGroups: any = {};
  private isEditSlots: any = {};
  private validatedFields = 0;
  private validated = false;
  private menu = false;
  private productNames = new Set();

  private classElem: string = 'stop-scrolling';
  private documentBody: HTMLElement = document.getElementsByTagName('body')[0];


  private isValidName(Name: string) {
    return Validator.validName(Name);
  }

  private isValidEmail(Email: string) {
    return Validator.isValidEmail(Email);
  }

  private formatPriceName(slotsGrouped: IGQLProductSlotState[]) {
    return `${slotsGrouped[0].PriceName} ${String(this.$t('user-space.booking-label-ticket'))}`;
  }

  private isTicketFields(slotId: number) {
    if (this.ticketFields) {
      return this.ticketFields.filter((item) => item.pssId === slotId).length !== 0;
    } else { return false; }
  }

  private get isLocarno() {
    const groupId = OrganizerModule.id || OrganizerModule.orgId;
    return [19017].includes(Number(groupId));
  }

  private get bookingCancellable() {
    return this.slots.filter((item) =>
    item.ProductSlotCategoryRead.IsUserCancellable === 1).length !== 0;
  }
  // get modalStatuses() {
  //   return statusesForNewModal.includes(NModalModule.status);
  // }

  private async created() {
    const isFromBookingFlow = this.$route.query.booking_flow;
    if (isFromBookingFlow) {
      UserModule.setIsFromBookingFlow(true);
    }
    window.addEventListener('keydown', this.onKeydown);
    // lets show the goBack button
    if (!UserModule.isBookingSummaryShowGoBackButton) {
      UserModule.setIsShowGoBackButton(true);
    }

    this.bookingId = Number(this.$route.params.bookingId);

    try {
      const bookingId = this.bookingId;
      await NBookingModule.gql_fetchBooking(bookingId);
      const bookingSummary = NBookingModule.bookingSummary;

      if (!bookingSummary) {
        throw new Error('No booking object');
      }

      this.booking = bookingSummary.data.ProductSlotBookingRead;
      const { FirstName, LastName, Email } = this.booking;
      this.userInfo = {
        FirstName: FirstName as string,
        LastName: LastName as string,
        Email: Email as string,
      };
      // set bookingId, bookingToken to use them in patchBookingUser
      const bookingToken = this.booking.GoerToken;

      if (!bookingToken) {
        throw new Error(`Booking ${bookingId} has no token`);
      }
      NBookingModule.setBookingId(bookingId);
      NBookingModule.setBookingToken(bookingToken!);
      const customFields = await getBookingFields(bookingId, bookingToken!);
      this.bookingFields = customFields.booking_fields!;
      this.ticketFields = customFields.ticket_fields!;

      this.slots =
        bookingSummary.data.ProductSlotBookingRead.ProductSlotStateList;
      RBookingModule.setSlots(this.slots);

      for (const slot of this.slots) {
        Vue.set(this.isEditSlots, slot.Id, {isEdit: false, save: false , cancel: false});
      }

      for (const key of Object.keys(this.slotsGroupedByPriceName)) {
        Vue.set(this.expandedGroups, key, false);
        this.productNames.add(key.split('/')[0]);
      }

      // Get organizer email from slots where it exists and not null
      this.organizerEmail =
        this.slots
          .find(
            (s) =>
              s.ProductSlotCategoryRead.ProductRead.GroupRead.brandingList.filter(
                (b) => b.email !== null,
              ).length > 0,
          )
          ?.ProductSlotCategoryRead.ProductRead.GroupRead.brandingList.find(
            (b) => b.email !== null,
          )?.email || '';
    } catch (error) {
      NNoty.createNewNoty({
        period: 4000,
        message: String(this.$t('error.error-response-banner')),
        type: 'error',
      });
      throw error;
    } finally {
      // why waiting? so that the component is fully rendered and we get a correct height value
      setTimeout(() => setWidgetHeight('smtz-p-widget'), 500);
      hideScrollBar();
    }
  }

  private onKeydown(event: any) {
    const kc = event.key === 'Enter';
    if (kc) {
      this.save = true;
    }
  }

  private beforeDestroy() {
    window.removeEventListener('keydown', this.onKeydown);
    this.documentBody.classList.remove(this.classElem);
  }


  @Watch('cancel')
  private cancelChange(newVal: any) {
    if (newVal) {
      for (const key of Object.keys(this.userInfo)) {
        this.userInfo[key as keyof IUserFields] = this.booking![key as keyof IUserFields] as string;
      }
    }
  }

  @Watch('save')
  private onSubmit(newVal: any) {
    if (!newVal) {
      return;
    }
    for (const key of Object.keys(this.userInfo)) {
      if (this.$refs[key]) {
        (this.$refs[key] as Vue & { validate: () => Promise<boolean> })
          .validate()
          .then((success) => {
            if (success) {
              this.validate();
            } else {
              this.save = false;
            }
         });
      }
    }
  }

  @Watch('validated')
  private async updateChange(newVal: any) {
    if (!newVal) {
      return;
    }
    if (!this.booking) {
      throw new Error('No booking to manipulate');
    }
    if (!this.booking.Email) {
      throw new Error(`Booking ${this.booking.Id} has no email`);
    }

      // Email required in Patch booking request
    const userData: Partial<IGuestCheckoutUser> = {
      email: this.booking.Email,
    };
    for (const key of Object.keys(this.userInfo)) {
      const field = key as keyof IUserFields;
      const userField = key.charAt(0).toLowerCase() + key.slice(1) as keyof IGuestCheckoutUser;
      if (this.booking[field] !== this.userInfo[field]) {
        this.booking[field] = this.userInfo[field];
        userData[userField] = this.userInfo[field];
      }
    }
    await NBookingModule.patchBookingUser(userData as IGuestCheckoutUser);
  }

  private onSave(slotId = 0) {
    this.validatedFields = 0;
    this.validated = false;
    this.cancel = false;
    if (slotId === 0) {
      this.save = true;
    } else {
      this.isEditSlots[slotId].save = true;
    }
  }

  private onEdit(slotId = 0) {
    if (slotId === 0) {
      this.save = false;
      this.cancel = false;
      this.isEdit = true;
    } else {
      this.isEditSlots[slotId].save = false;
      this.isEditSlots[slotId].cancel = false;
      this.isEditSlots[slotId].isEdit = true;
    }
  }

  private onCancel(slotId = 0) {
    if (slotId === 0) {
      this.save = false;
      this.isEdit = false;
      this.cancel = true;
    } else {
      this.isEditSlots[slotId].save = false;
      this.isEditSlots[slotId].isEdit = false;
      this.isEditSlots[slotId].cancel = true;
    }
  }

  private validate(slotId = 0) {
    this.validatedFields += 1;
    if (slotId === 0) {
      this.validated = this.validatedFields === (this.bookingFields!.length + 3);
      if (this.validated) {
        this.isEdit = false;
        this.save = false;
      }
    } else {
      this.validated = this.validatedFields === this.ticketFields?.filter((item) => item.pssId === slotId).length;
      if (this.validated) {
        this.isEditSlots[slotId].isEdit = false;
        this.isEditSlots[slotId].save = false;
      }
    }
  }

  private goBack() {
    const isFromBookingFlow = this.$route.query.booking_flow;
    if (this.isOrganizerUserSpace && isFromBookingFlow) {
      this.$router.go(-1);
    } else if (this.isOrganizerUserSpace) {
      this.$router.replace(
        `/${this.$i18n.locale}/org-user/${this.$route.params.orgId}/userbookings`,
      );
    } else {
      this.$router.replace(`/${this.$i18n.locale}/user-space`);
    }
  }

  private goRefundPage() {
    this.$router.push('refund-booking/' + this.booking!.Id);
  }

  private async downloadPdf(type: string) {
    const ticketToken = this.booking?.GoerToken as string;
    try {
      const blob = await getTicketOrReceipt(this.bookingId, ticketToken, type);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${type}.pdf`;
      link.click();
    } catch (error) {
      throw new Error('Error in getting ticket with booking id #' + this.bookingId);
    }
  }

  private async sendBookingConfirmation() {
    try {
      this.isLoading = true;
      const token = this.booking ? this.booking.GoerToken : '';
      const result2 = this.isOrganizerUserSpace
        ? await postResendBooking2(this.bookingId, token)
        : await postResendBooking(this.bookingId);
      new Noty({
        type: 'success',
        text: String(this.$t('field.message-tickets-sent')),
        timeout: 4000,
        theme: 'metroui',
      }).show();
    } catch (error) {
      new Noty({
        type: 'error',
        text: String(this.$t('error.error-response-banner')),
        timeout: 4000,
        theme: 'metroui',
      }).show();
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  private contactOrganizer() {
    const email = this.organizerEmail;
    document.location.href = 'mailto:' + email;
  }

  private slotPaymentStatus(status: number) {
    return String(
      this.$t(
        'user-space.booking-summary.payment-status-values.' +
        PaymentStatus[status].toLocaleLowerCase(),
      ),
    );
  }

  // return the booking status of a ticket
  private slotBookingStatus(status: number) {
    return String(
      BookingStatus[status],
    );
  }

  private translateDate(date: string, onlyDate: boolean = false) {
    return this.currentDate(date, onlyDate);
  }

  @Watch('cancelDialog')
  private setScrolling(newVal: boolean): void {
    if (!newVal) {
      this.documentBody.classList.remove(this.classElem);
    } else {
      this.documentBody.classList.add(this.classElem);
    }
  }

}

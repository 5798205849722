import gql from 'graphql-tag';

// tslint:disable-next-line
export const GET_ControlGroupUserList = gql`
  query GetControlGroupUserList($userTrackerId: String, $productId: String) {
    controlGroupUserList: ControlGroupUserList(
      condition: {
        UserTrackerId: { eq: $userTrackerId }
        ProductId: {eq: $productId}
      }) {
      productId: ProductId
      controlGroup: ControlGroup
      priceId: ProductSlotPriceId
      price: ProductSlotPriceRead {
        id: Id
        value: Value
        priceName: PriceName
      }
    }
  }
`;

// tslint:disable-next-line
export const GET_StrategyPrices = gql`
  query GetStrategyPricesList($priceIds: String!) {
    StrategyPriceList (
    	where: $priceIds,
      condition: {Status: {eq:"1"}},
    ) {
      id:Id
			strategyId: StrategyId
      priceId: ProductSlotPriceId
      strategyInfo: StrategyRead {
        id: Id
			  type: Type
        controlGroup: ControlGroup
      }
		}
  }
`;

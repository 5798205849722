import { iframeListener, inIframe } from './iframe';
import { isSafari, isIos } from './platform';
import Store from '@/store';
import { SET_SCROLL_Y } from '@/store/modules/app/constants';
import {
  ReferrerModule, AppModule,
  NBookingModule, OrganizerModule, UserModule,
} from '@/utils/storemodules';
import { getParameterByName, setMainColors } from '@/utils/helpers';
import { QueryParameters, LocalStorageFields, IntegrationTypes, DELAY_CALL_TIMEOUT } from '@/models/enums';
import { Route } from 'vue-router';
import { setBookingColors } from './colors';
import { IWidgetStyles } from '@/models/styles';
import jwt_decode from 'jwt-decode';
import {extractUserInfoFromSmtzToken} from './token';
import { getUserMembershipsBookingCodes } from './membership';
import Mixpanel, { EVENTS, parseBookingStep } from '@/services/mixpanel';


// keeps track of whether a scroll handler is already registered
// or not.
let ticking: any;
let widthTicking: any;

export function iframeInit() {
  // Set environment
  setEnv();

  // iframe inits
  if (inIframe()) {
    iframeListener();
    window.parent.postMessage('getColors', '*');
    if (isSafari() && isIos()) {
      const element = document.getElementById('app');
      if (element) {
        element.classList.add('iframe-scrolling-body');
      }
    }


    // set booking channel
    ReferrerModule.setUtmChannel('iFrame');
    // Capture all the required query parameters
    captureParams();

    // Store the original url that opened the widget.
    const widgetId = AppModule.widgetId;
    if (widgetId) {
      ReferrerModule.setWidgetOrigUrl(location.href);
    }
  }
}

function setEnv() {
  const api = process.env.VUE_APP_API;

  if (/dev/.test(api)) {
    AppModule.setEnv('dev');
    return;
  } else if (/test/.test(api)) {
    AppModule.setEnv('test');
    return;
  }

  AppModule.setEnv('prod');
  return;
}

export async function siteInit() {
  // Set environment
  setEnv();
  // set width then site is init
  AppModule.setWidth(window.innerWidth);
  // set scroll position 200ms
  window.addEventListener('scroll', scrollHandler);
  // set resize listener 200ms
  window.addEventListener('resize', widthHandler);
  // Capture all the required query parameters
  captureParams();
  // Set tracking id
  await AppModule.initTracking();
}

/**
 * This function handles capturing the query parameters that
 * are passed when the user accesses the site. Its initial intent
 * is to be used only when the site is loaded for the first time,
 * this may change in the future.
 */
export async function captureParams() {
  // Set referrer in case it was found in url parameter.
  const referrer = getParameterByName(QueryParameters.Referrer);
  if (referrer) {
    ReferrerModule.setReferr(referrer as string);
  } else if (inIframe()) {
    ReferrerModule.setReferr(document.referrer);
  }

  // Set promo code in case it was found in url parameter,
  // or in case it was passed from sammy
  let promo = getParameterByName(QueryParameters.PromoCode);
  if (promo) {
    ReferrerModule.setPromoCode(promo);
  } else if (!inIframe() && sessionStorage.getItem(QueryParameters.PromoCode)) {
    // Here the promo code is passed from sammy product page.
    // This feature shouldn't be used on an iframe because some
    // browsers complain about accessing the session storage on
    // an iframe;
    promo = sessionStorage.getItem(QueryParameters.PromoCode);

    // promo is already checked and present in the else if block,
    // so, we override type to string
    ReferrerModule.setPromoCode(promo as string);
  }

  // Set the hidden with link tickets (categories) that should
  // be made visible.
  const categories: null | string = getParameterByName(QueryParameters.Categories);
  if (categories) {
    ReferrerModule.setCategories(categories);
  }

  // Set the tickets that should be visible in the booking flow
  const visibleTickets: null | string = getParameterByName(QueryParameters.VisibleCategories);
  if (visibleTickets) {
    ReferrerModule.setVisibleCategories(visibleTickets);
  }

  // Set the retail products that should be visible in the booking flow
  const retailIds: null | string = getParameterByName(QueryParameters.RetailIds);
  if (retailIds) {
    ReferrerModule.setRetailIds(retailIds);
  }

  // Variable to show the new calendar design
  const newCalendar: null | string = getParameterByName(QueryParameters.NewCalendar);
  if (newCalendar) {
    ReferrerModule.setNewCalendar(newCalendar);
  }

  // Variable to show the new timeslots design
  const newTimeslots: null | string = getParameterByName(QueryParameters.NewTimeslots);
  if (newTimeslots) {
    ReferrerModule.setNewTimeslots(newTimeslots);
  }

  // Variable to display the number of days in nw calendar design
  const numDaysNewCalendar: null | string = getParameterByName(QueryParameters.NumDaysNewCalendar);
  if (numDaysNewCalendar) {
    ReferrerModule.setNumDaysNewCalendar(numDaysNewCalendar);
  }

  // To know if a booking started on widget, and was redirected to discover
  const fromWidget: null | string = getParameterByName(QueryParameters.FromWidget);
  if (fromWidget) {
    ReferrerModule.setFromWidget(fromWidget);
  }

  // This allows us to redirect to the Organizer site when closing the booking flow
  const topLevelDomainUrl: null | string = getParameterByName(QueryParameters.TopLevelDomainUrl);
  if (topLevelDomainUrl) {
    ReferrerModule.setTopLevelDomainUrl(topLevelDomainUrl);
  }

  // To know if a payment method is preselected
  const paymentMethod: null | string = getParameterByName(QueryParameters.PaymentMethod);
  if (paymentMethod) {
    ReferrerModule.setPaymentMethod(paymentMethod);
  }

  // Set the date that should be selected on calendar
  const from: null | string = getParameterByName(QueryParameters.from);
  if (from) {
    ReferrerModule.setFromDate(from);
  }

  // Set the date from which calendar tickets should show slots
  const mFrom: null | string = getParameterByName(QueryParameters.mFrom);
  if (mFrom) {
    ReferrerModule.setMFromDate(mFrom);
  }
  // Set the date to which calendar tickets should stop showing slots
  const mTo: null | string = getParameterByName(QueryParameters.mTo);
  if (mTo) {
    ReferrerModule.setMToDate(mTo);
  }

  // The following parameters are for analytics and commission calculations
  const utmSource = getParameterByName(QueryParameters.UtmSource);
  if (utmSource) {
    ReferrerModule.setUtmSource(utmSource);
  }

  const utmMedium = getParameterByName(QueryParameters.UtmMedium);
  if (utmMedium) {
    ReferrerModule.setUtmMedium(utmMedium);
  }

  const utmCampaign = getParameterByName(QueryParameters.UtmCapmaign);
  if (utmCampaign) {
    ReferrerModule.setUtmCampaign(utmCampaign);
  }

  // Set FBP for fb pixel server tracking
  const fbp = getParameterByName(QueryParameters.FBP);
  ReferrerModule.setFbp(fbp);

  // Set FBC for fb pixel server tracking
  const fbc = getParameterByName(QueryParameters.FBC);
  ReferrerModule.setFbc(fbc);

  // Set FBCLID for fb pixel server tracking
  const fbclid = getParameterByName(QueryParameters.FBCLID);
  ReferrerModule.setFbclid(fbclid);

  // Set eventTestCode for fb pixel server tracking
  const eventTestCode = getParameterByName(QueryParameters.EventTestCode);
  ReferrerModule.setEventTestCode(eventTestCode);

  // Set gtmID
  const gtmId = getParameterByName(QueryParameters.GTMID);
  ReferrerModule.setgtmId(gtmId);

  const preselectTickets = getParameterByName(QueryParameters.PreSelectTickets);
  const preselectTicketsCount = getParameterByName(QueryParameters.PreSelectTicketsCount);
  if (preselectTickets) {
    NBookingModule.SetPreselected({
      ticket: preselectTickets.split(','),
      count: Number(preselectTicketsCount),
    });
  }

  const ocolColor = getParameterByName(QueryParameters.OrganizerColor);
  if (ocolColor) {
    setMainColors(ocolColor);
    setBookingColors({ smeetzPrimaryColor: ocolColor });
  }

  const navCol = getParameterByName(QueryParameters.NavCol);
  const buttonCol = getParameterByName(QueryParameters.ButtonColor);
  const titleCol = getParameterByName(QueryParameters.TitleColor);
  const textCol = getParameterByName(QueryParameters.TextColor);
  const font = getParameterByName(QueryParameters.Font);
  const uat = getParameterByName(QueryParameters.Uat);

  const widgetStyles: IWidgetStyles = {
    navCol, buttonCol, titleCol, textCol, font,
    primaryCol: ocolColor,
  };

  if (uat) {
    AppModule.setWidgetStyles(widgetStyles);
  }

  // Organizer id capture
  const orgId = Number(getParameterByName(QueryParameters.ORGId));
  if (orgId) {
    OrganizerModule.setORGId(orgId);
  }

  // Facebook and google id capture
  const fId = getParameterByName(QueryParameters.FBId);
  if (fId) {
    OrganizerModule.setFBId(fId);
  }
  const gaId = getParameterByName(QueryParameters.GAId);
  if (gaId) {
    OrganizerModule.setGAId(gaId);
  }

  // tcart flag
  const tcart = getParameterByName(QueryParameters.TCart);
  if (tcart) {
    AppModule.setTCart(true);
  }

  // widget id
  const wId = getParameterByName(QueryParameters.WId);
  if (wId) {
    AppModule.setWidgetId(wId);
  }

  // widget type
  const wType = getParameterByName(QueryParameters.WType);
  if (wType && !tcart) {
    AppModule.setWidgetType(wType as any);
  }

  // widget type
  const iType = getParameterByName(QueryParameters.IntegrationType);
  if (iType && ([IntegrationTypes.Native, IntegrationTypes.Hybrid].includes(iType as any))) {
    AppModule.setIntegrationType(iType as any);

    // For hybrid integrations, we skip locarno login
    if (iType === IntegrationTypes.Hybrid) {
      NBookingModule.setSkipLoginView(true);
    }
  }

  const mType = Number(getParameterByName(QueryParameters.MType));
  if (mType) {
    ReferrerModule.setMemType(mType);
  }

  const membershipIds = getParameterByName(QueryParameters.MIds);
  if (membershipIds) {
    ReferrerModule.setMemIds(membershipIds.split(',').map((id) => Number(id)));
  }

  const trackingId = getParameterByName(QueryParameters.TID);
  if (trackingId) {
    AppModule.setTrackId(trackingId);
  } else {
    setTimeout(() => (async () => {
      const response = await fetch(process.env.VUE_APP_TRACK_API, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
      });

      const data = await response.json();

      if (data && data.t) {
        window._smtz.tid = data.t;
        localStorage.setItem( LocalStorageFields.SmtzLocalStorageTID, data.t);
      }
    })(), DELAY_CALL_TIMEOUT);

  }

  const customText = getParameterByName(QueryParameters.CustomText);
  if (customText) {
    ReferrerModule.setCustomText(customText);
  }
  const customUrl = getParameterByName(QueryParameters.CustomUrl);
  if (customUrl) {
    ReferrerModule.setCustomUrl(customUrl);
  }
  const smeetzToken = getParameterByName(QueryParameters.SmeetzAccessToken);
  if (smeetzToken) {
    const infos = extractUserInfoFromSmtzToken(smeetzToken);
    if (infos) {
      const userData = {
        firstName: infos.firstName || '',
        lastName: infos.lastName || '',
        fullname: infos.firstName + ' ' + infos.lastName,
        emails: [{emailAddress: infos.email}],
      };
      UserModule.setOrgUser(userData);
      UserModule.logOrgUser(true);
    }
    await getUserMembershipsBookingCodes(smeetzToken);
  }

  const hostUrl = getParameterByName(QueryParameters.HostUrl);
  if (hostUrl) {
    ReferrerModule.setHostUrl(hostUrl);
  }
}
/**
 * Sets the access token from the Url - userspace
 */
export function setAuthInfo() {
  const accessToken = getParameterByName(QueryParameters.AccessToken);
  const object: any = jwt_decode(accessToken!);
  UserModule.setCustomAuthToken(accessToken);
  UserModule.setCustomAuthEmail(object.email);
}

/**
 * Sets the booking channel responsible for the booking operation.
 * This is important for commission calculation on the business side.
 * @param route Vue route object
 */
export function initBookingChannel(route: Route) {
  // if we are in a widget, don't bother with the reset
  if (inIframe()) {
    ReferrerModule.setUtmChannel('iFrame');
    return;
  }

  // the channel could be set as a route parameter
  const channel = route.params.channel;

  if (channel && channel === 'b2b') {
    ReferrerModule.setUtmChannel(channel);
    return;
  }

  ReferrerModule.setUtmChannel('b2c');
}

/**
 * Handler that is executed when the user change width at the window level
 * it implements a simple debounce of 200ms.
 */
function widthHandler() {
  // if we already have a callout, clear it
  if (widthTicking) {
    clearTimeout(widthTicking);
  }

  widthTicking = setTimeout(() => {
    AppModule.setWidth(window.innerWidth);
  }, 500);
}

/**
 * Handler that is executed when the user scrolls at the window level
 * it implements a simple debounce of 200ms.
 */
function scrollHandler() {

  // if we already have a callout, clear it
  if (ticking) {
    clearTimeout(ticking);
  }

  ticking = setTimeout(() => {
    AppModule.setScrollY(window.scrollY);
  }, 50);

}

import request, { requestGraphql } from '@/utils/request';

import Debug from 'debug';
import { ICondition, IGQLUserBookings, IUserBookings } from '@/models/store/user';
import {
  countUserBookingsQuery, countUserBookingsQueryThruPSS, getUserBookingsQuery,
  getUserBookins_byBookingIdsQuery, getUserPSSGroupedByBookingQuery,
} from './graphql/queries';
import { IUserActivity } from '@/models/store/region';
const debug = Debug('smeetz:userspace');

export async function getUserBookings(query: ICondition): Promise<IUserBookings> {
  const response = await request({
    method: 'get',
    url: '/user/bookings',
    params: query,
  });

  return response.data;
}

/**
 * Returns a bookings list filtered by userId and oranizerId? using GraphQL query
 */
export async function gql_getUserBookings(
  userToken: string | undefined, userEmails: string[],
  organizerId: number | null, currentPage: number, rowsPerPage: number)
  : Promise<any> {
  const response = await requestGraphql({
    method: 'POST',
    data: JSON.stringify({
      query: getUserBookingsQuery({ emails: userEmails, organizerId, currentPage, rowsPerPage }),
    }),
  });
  const data = response.data;

  return data;
}

/**
 * Returns a the number of booking filtered by userId and oranizerId? using GraphQL query
 */
export async function gql_countUserBookings(
  userToken: string | null | undefined, userEmails: string[],
  organizerId: number | null, upcoming: boolean)
  : Promise<number> {
  const response = await requestGraphql({
    method: 'POST',
    data: JSON.stringify({
      query: countUserBookingsQueryThruPSS({ emails: userEmails, organizerId }, upcoming),
    }),
  });

  if (response.data.data.ProductSlotStateGroupBy) {
    return response.data.data.ProductSlotStateGroupBy.length;
  }
  return 0;
}

/**
 * Returns a bookings list filtered by userId and oranizerId? using GraphQL queries
 * In this way we'll query using thru PSS
 * 1- Get PSS groupedBy bookingId ---> result properly paginated objects
 * 2- Get Bookings using their bookingId ---> list of bookings with the required data
 */
export async function gql_getUserBookingsThruPSS(
  userToken: string | null | undefined, userEmails: string[],
  organizerId: number | null, currentPage: number, rowsPerPage: number, upcoming: boolean)
  : Promise<IGQLUserBookings> {
  const response1 = await requestGraphql({
    method: 'POST',
    data: JSON.stringify({
      query: getUserPSSGroupedByBookingQuery({ emails: userEmails, organizerId, currentPage, rowsPerPage }, upcoming),
    }),
  });

  const pssGrouped = response1.data.data.ProductSlotStateGroupBy;
  if (!pssGrouped) {
    return { ProductSlotStateGroupBy: [] };
  }



  const bookingIds = pssGrouped.map((el: any) => el.BookingId.toString());

  if (bookingIds && bookingIds.length > 0) {
    const response2 = await requestGraphql({
      method: 'POST',
      data: JSON.stringify({
        query: getUserBookins_byBookingIdsQuery(bookingIds),
      }),
    });
    return response2.data.data;
  }

  return { ProductSlotStateGroupBy: [] };

}

export async function postResendBooking(bookingId: number) {
  const response = await request({
    method: 'post',
    url: `/booking/${bookingId}/resendconfirmation`,
  });

  return response.data;
}

export async function postResendBooking2(bookingId: number, goerToken: string | null) {
  const response = await request({
    method: 'post',
    url: `/booking/${bookingId}/resend-confirmation-email`,
    params: {
      bookingToken: goerToken,
    },
  });

  return response.data;
}

import request, { requestGraphql } from '@/utils/request';
import { getOrganizerInfoByIdQuery, getOrgInfo, getOrgInfoForAdyenQuery, getShippingTicketsQuery } from '@/api/graphql/queries';
import { IGQLAdyenOrgInfo } from '@/models/organizer';
import { requestb2b } from '@/utils/request';

export async function getOrganizerInfo(organizerSlug: string) {
  const response = await request({
    method: 'get',
    url: `/common/organizer/${organizerSlug}`,
  });

  return response.data;
}
export async function getOrganizerPictures(organizerId: number) {
  const response = await requestb2b({
    method: 'get',
    url: `/group/${organizerId}/branding/pictures`,
  });

  return response.data;
}


export async function getAdyenOrganizerInfo(orgId: number): Promise<IGQLAdyenOrgInfo> {
  const response =  await requestGraphql({
    method: 'POST',
    data: JSON.stringify({
      query: getOrgInfoForAdyenQuery(orgId),
    }),
  });

  return response.data;
}

export async function getOrganizerData(orgId: number) {
  const response =  await requestGraphql({
    method: 'POST',
    data: JSON.stringify({
      query: getOrgInfo(orgId),
    }),
  });

  return response.data;
}

export async function getOrganizerInfoById(orgId: number) {
  const response =  await requestGraphql({
    method: 'POST',
    data: JSON.stringify({
      query: getOrganizerInfoByIdQuery(orgId),
    }),
  });

  return response.data;
}

export async function getShippingTicketsData(pscIds: number[]) {
  const response =  await requestGraphql({
    method: 'POST',
    data: JSON.stringify({
      query: getShippingTicketsQuery(pscIds),
    }),
  });

  return response.data;
}








import { Component } from 'vue-property-decorator';
import { Mixins } from 'vue-mixin-decorator';
import BookingsByType from './components/BookingsByType.vue';
import DateHelperMixin from '@/mixins/DateHelper';

@Component({
  components: {
    BookingsByType,
  },
})
export default class UserBooking extends Mixins<DateHelperMixin>(
  DateHelperMixin,
) { }

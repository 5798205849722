







import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class ImageCard extends Vue {
  @Prop({default: null}) private cardData: any;
}

export interface IOrganizerColors {
  id?: number;
  colourSmeetz?: string | null;
  colourBackground?: string | null;
  colourText?: string | null;
  colourButton?: string | null;
  colourAccent?: string | null;
  font?: string | null;
  smeetzPrimaryColor?: string | null;
}

export interface IMainColors {
  primary?: string | null;
  secondary?: string | null;
}

export enum ColorStyles {
  Primary = '--primary-color',
  QPrimary = '--q-color-primary', // Quasar color
  Secondary = '--secondary-color',
  Organizer = '--org-primary',
  OrganizerBackground = '--org-background',
  OrganizerFontColor = '--org-font',
}

export interface IWidgetStyles {
  primaryCol: string | null;
  navCol: string | null;
  buttonCol: string | null;
  titleCol: string | null;
  textCol: string | null;
  font: string | null;
}

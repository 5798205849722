import Debug from 'debug';
import { MutationTree } from 'vuex';
import {
  IBookingState, BookingSteps, ISlot,
  IAvailabilities, ICategory, IProductCalendar,
  IPreBookingRes, IServerBooking, IPaymentMethod, ISlotDates, IGuestBooking, ICalendarDate, IDPSlot, IDPSlotWReason,
} from '@/models/store/booking';
import { NextStep, PrevStep, State } from './values';
import {
  SET_DATE, SET_PRODUCT, SET_CALENDAR, SET_LOADING,
  RESET_BOOKING_STATE, SET_AVAILABILITIES, SET_BOOKING,
  SET_SERVER_BOOKING, CLEAR_BOOKING, SET_PAYMENT_METHOD,
  ADD_SLOT, REMOVE_SLOT, SET_GUEST_BOOKING,
  ADD_AVOIDED_DP_SLOT, SET_DP_SLOT,
} from './constants';
import { isSlotFromDate, slotSortComp, isSeatParentCategory } from '@/utils/helpers';
import { IProduct } from '@/models/store/product';

const debug = Debug('smeetz:booking');

const Mutations: MutationTree<IBookingState> = {
  setStep(state, step: BookingSteps) {
    state.step = step;
  },

  stepForward(state) {
    state.step = NextStep[state.step];
  },

  stepBack(state) {
    state.step = PrevStep[state.step];
  },

  setNextStep(state, step: BookingSteps) {
    state.step = NextStep[step];
  },

  setPrevStep(state, step: BookingSteps) {
    state.step = PrevStep[step];
  },

  setSlot(state: IBookingState, slot: ISlot) {
    debug('Setting Slot');
    state.slot = slot;
    const categories = slot.categories;
    if (!categories) {
      state.categories = [];
      return;
    }

    for (const cat of categories) {
      cat.select = false;
    }

    state.categories = categories;
  },

  [SET_LOADING](state: IBookingState, isLoading: boolean) {
    state.loading = isLoading;
  },

  [SET_DATE](state, date: Date) {
    state.date = date;
  },

  [ADD_SLOT](state, slot: ISlot) {
    const { startDateTime, endDateTime } = slot;
    const slotDates: ISlotDates = {
      startDateTime,
      endDateTime,
    };

    const index = state.selectedSlots.findIndex((s) => isSlotFromDate(s, slotDates));
    if (index < 0) {
      debug('Adding slot');
      state.selectedSlots.push(slot);
      state.selectedSlots.sort(slotSortComp);

      // populate slot categories with dates
      if (!slot.categories) {
        return;
      }

      for (const cat of slot.categories) {
        cat.startDateTime = startDateTime;
        cat.endDateTime = endDateTime;

        // if we have a seating plan categories,
        // populate seats categories with dates.
        if (isSeatParentCategory(cat) && Array.isArray(cat.seatingSubProducts)) {
          for (const seatCat of cat.seatingSubProducts) {
            seatCat.startDateTime = startDateTime;
            seatCat.endDateTime = endDateTime;
          }
        }
      }

      return;
    }

    debug('Slot already present, Ignore ADD_SLOT commit');
  },

  [REMOVE_SLOT](state, slot: ISlot) {
    const { startDateTime, endDateTime } = slot;
    const slotDates: ISlotDates = {
      startDateTime,
      endDateTime,
    };

    const index = state.selectedSlots.findIndex((s) => isSlotFromDate(s, slotDates));
    if (index < 0) {
      debug('Slot not found, Ignore REMOVE_SLOT commit');
      return;
    }

    debug('removingSlot');
    state.selectedSlots.splice(index, 1);
  },

  [SET_PRODUCT](state: IBookingState, product: IProduct) {
    debug(`Setting product`);
    state.product = product;
  },

  [RESET_BOOKING_STATE](state: IBookingState) {
    debug('Resetting booking state');
    state = State;
  },

  [SET_AVAILABILITIES](state: IBookingState, availabilities: IAvailabilities) {
    debug('Setting Availabilties');

    // Add each computed price to its corresponding ticket price
    if (!availabilities.dates) {
      return;
    }

    // In each loop we have redundant checks to avoid iterating over non-existant arrays.
    for (const date of availabilities.dates) {
      if (!date.summarizedSlots) {
        continue;
      }

      for (const slot of date.summarizedSlots) {
        if (!slot.categories) {
          continue;
        }

        for (const category of slot.categories) {
          // Skip if there are no computed prices here.
          if (!category.computed_prices || !category.price) {
            continue;
          }

          // for each ticket price, attach corresponding computed price if available
          const computedPrices = category.computed_prices;
          for (const price of category.price) {
            const computedPrice = computedPrices.find((cPrice) => cPrice.priceId === price.priceId);
            if (computedPrice) {
              price.computedPrice = computedPrice;
            }
          }
        }
      }
    }

    // Set the availabilites object
    state.availabilities = availabilities;
  },

  // Currently, categories are set only when a slot is selected.
  setCategories(state, categories: ICategory[]) {
    debug('Setting categories');
    state.categories = categories;
  },

  toggleCategory(state, category: ICategory) {
    // // Remove category if it is already selected
    // const categories = [...state.categories];

    // // Add category (Select it)
    // const toggleCategory: ICategory | undefined =
    //   categories.find((cat: ICategory) => cat.categoryId === category.categoryId);

    // if (!toggleCategory) {
    //   debug(`WARN: Unknown category ${category}`);
    //   return;
    // }

    // // select category if it wasn't already selected
    // toggleCategory.select = !toggleCategory.select;
    // state.categories = categories;
    category.select = !category.select;
    state.selectedSlots = [...state.selectedSlots];
    return;
  },

  [SET_CALENDAR](state, calendar: IProductCalendar) {
    state.calendar = calendar;
  },
  // setSelectedCategories(state: IBookingState, categories: ICategory[]) {
  //   state.selectedCategories = categories;
  // },

  [SET_BOOKING](state, booking: IPreBookingRes) {
    state.booking = booking;
  },

  [SET_GUEST_BOOKING](state, booking: IGuestBooking) {
    state.guestBooking = booking as IGuestBooking;
  },

  [SET_SERVER_BOOKING](state, booking: IServerBooking) {
    state.serverBooking = booking;
  },

  [SET_PAYMENT_METHOD](state, paymentMethod: IPaymentMethod) {
    state.paymentMethod = paymentMethod;
  },

  [CLEAR_BOOKING](state) {
    state.serverBooking = undefined;
    state.booking = undefined;
    state.paymentMethod = undefined;
    state.date = undefined;
    state.product = {};
    state.step = BookingSteps.DateTime;
    state.selectedSlots = [];
    state.slot = undefined;
    state.categories = [];
    state.calendar = { dates: ([] as ICalendarDate[]) };
    state.guestBooking = undefined;
  },

  [SET_DP_SLOT](state, dpSlot: IDPSlot) {
    state.dpSlot = dpSlot;
  },

  [ADD_AVOIDED_DP_SLOT](state, dpSlot: IDPSlotWReason) {
    state.avoidedDPSlots.push(dpSlot);
  },
};

export default Mutations;

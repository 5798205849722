import Debug from 'debug';
import moment from 'moment';
import { ActionTree } from 'vuex';
import Vue from 'vue';

import {
  IBookingState, IAddTicketPrice, ICategory, ITicketPrice,
  IAvailabilities, IAvailabilitiesRange, IRecap,
  IPreBookingReq, IPreBookProductQt, IPreBookingRes, IBookingPayments,
  IServerBookingData, IServerBooking, ISlot, IAvailableDates,
  IPromoCodeData, ISlotDates, IGuestCheckoutUser, IDPSlot, IDPSlotWReason, IDPSlotSource,
} from '@/models/store/booking';
import { IRootState } from '@/models/store';
import {
  ADD_TICKET, GET_SELECTED_CATEGORIES, REMOVE_TICKET,
  FETCH_PRODUCT, SET_LOADING, RESET_BOOKING_STATE, SET_PRODUCT,
  FETCH_AVAILABILITIES, GET_PRODUCT, SET_AVAILABILITIES,
  FETCH_PRODUCT_CALENDAR, SET_CALENDAR, PRE_BOOK, GET_RECAP,
  GET_PRODUCT_ID, SET_BOOKING, GET_BOOKING, CANCEL_BOOKING,
  LOAD_PAYMENT_METHODS, GET_BOOKING_ID, GET_BOOKING_TOKEN,
  FETCH_SERVER_BOOKING, SET_SERVER_BOOKING, POST_PROMO_CODE,
  CLEAR_BOOKING, CLEAR_CHARTS, GET_BOOKING_FLOW, GET_FLATTENED_CATEGORIES,
  DESTROY_CHARTS, TOGGLE_SLOT, GET_SLOT_BY_DATE, ADD_SLOT, REMOVE_SLOT, FETCH_PRODUCTS,
  GET_SELECTED_SLOTS, PATCH_GUEST_BOOKING, SET_GUEST_BOOKING,
  FETCH_RANDOM_SLOT, SET_DP_SLOT, AVOID_DP_SLOT,
  ADD_AVOIDED_DP_SLOT, GET_AVOIDED_DP_SLOT, GET_NEXT_PRODUCT_DATE,
  GET_IS_DP_BOOKING, GET_DP_SLOT_DATA, GET_DP_PRICE,
} from './constants';
import {
  fetchProduct, fetchAvailabilities, makeBooking, cancelBooking,
  loadPaymentMethods, fetchServerBooking, postPromoCode,
  fetchProducts, patchBooking, fetchRandomSlot,
} from '@/api/booking';
import { ReferrerModule } from '@/utils/storemodules';
import { destroyCharts, getLanguageInt, isSeatCategory } from '@/utils/helpers';
import dayjs from 'dayjs';
import { IProduct } from '@/models/store/product';
import i18n from '@/locales';

const debug = Debug('smeetz:booking');

const Actions: ActionTree<IBookingState, IRootState> = {

  // This moves booking flow 1 step backward asynchronously
  async [CLEAR_CHARTS]({ getters, commit }) {

    // clear seats if present
    commit(SET_LOADING, true);
    if ((window as any).seatsio) {
      await destroyCharts(false);
    }

    commit(SET_LOADING, false);
  },

  // This destroys all charts without clearing seats
  async [DESTROY_CHARTS]({ getters, commit }) {

    // clear seats if present
    commit(SET_LOADING, true);
    if ((window as any).seatsio) {
      await destroyCharts(true);
    }

    commit(SET_LOADING, false);
  },

  // Toggles a slot by adding it to selectedSlots
  // or removes if already present.
  // This action is synchronous
  [TOGGLE_SLOT]({ getters, commit }, payload: ISlot) {
    debug('Toggling slot');
    // get slot
    const slotDates: ISlotDates = {
      startDateTime: payload.startDateTime,
      endDateTime: payload.endDateTime,
    };

    const slot: ISlot | undefined = getters[GET_SLOT_BY_DATE](slotDates);

    // if slot is present, remove it
    if (slot) {
      commit(REMOVE_SLOT, slot);
      return;
    }

    // slot is not present. Add it.
    commit(ADD_SLOT, payload);
  },

  // This adds or removes a ticket count
  [ADD_TICKET]({ state, getters, commit }, payload: IAddTicketPrice) {
    // const categories: ICategory[]  = getters[GET_SELECTED_CATEGORIES];

    // use GET_FLATTENED_CATEGORIES to handle seating categories too.
    const categories: ICategory[]  = getters[GET_FLATTENED_CATEGORIES];

    // find the category
    const category: ICategory | undefined = categories.find((cat) => {
      const dates = payload.dates;
      return cat.categoryId === payload.categoryId &&
        cat.startDateTime === dates.startDateTime &&
        cat.endDateTime === dates.endDateTime;
    });

    if (!category) {
      debug(`Can't find ticket in category: ${payload.categoryId}`);
      return;
    }

    // skip if category reached limit
    if (category.count === category.publicCount) {
      debug(`Category reached maxed count of ${category.count} tickets`);
      return;
    }

    // find ticket and add its count
    const tickets = (category as ICategory).price || [];

    const ticket = tickets.find((p) => p.priceId === payload.ticket.priceId);

    if (!ticket) {
      debug(`Can't find ticket in this category: ${category}`);
      return;
    }

    // calculate the new count for that category and ticket price
    // skip if it exceeds the maximum allowed booking
    // add 1 if no tickets are already added or if minbooking is 0
    // otherwise add minBooking as a first step
    const newTicketCount = (ticket.count || 0) + (ticket.count || !ticket.minBooking ? 1 : ticket.minBooking);
    if (newTicketCount > ticket.maxBooking) {
      debug(`Adding a ticket will exceed the max ticket booking of ${ticket.maxBooking}`);
      return;
    }

    // Check if we are going to exceed maxBooking of category only for non seats category
    const newCatCount = (category.count || 0) + (ticket.count ? 1 : ticket.minBooking || 1);
    if (newCatCount > category.publicCount && !isSeatCategory(category)) {
      debug(`Adding a ticket will exceed category public count of ${category.publicCount}`);
      return;
    }

    ticket.count = newTicketCount;
    category.count = newCatCount;

    // add seat if present
    if (payload.seat) {
      const seats = ticket.seats || [];
      seats.push(payload.seat);
      ticket.seats = seats;
    }

    (category.price as ITicketPrice[]).forEach((ticketElement) => {
      ticketElement.key = String(Math.random());
    });
    // category.price = [...tickets];
    // commit('setCategories', [...(getters.getCategories)]);
    state.selectedSlots = [...state.selectedSlots];
  },

  // removes a ticket. Or min booking count if the ticket count is at 0
  [REMOVE_TICKET]({ state, getters, commit }, payload: IAddTicketPrice) {
    // const categories: ICategory[] = getters[GET_SELECTED_CATEGORIES];
    const categories: ICategory[] = getters[GET_FLATTENED_CATEGORIES];

    // find the category
    const category: ICategory | undefined = categories.find((cat) => {
      const dates = payload.dates;
      return cat.categoryId === payload.categoryId &&
        cat.startDateTime === dates.startDateTime &&
        cat.endDateTime === dates.endDateTime;
    });

    if (!category) {
      debug(`Can't find ticket in category: ${category}`);
      return;
    }

    // skip if category reached lower limit
    if (!category.count) {
      debug(`Category reached lower count of ${category.count} tickets`);
      return;
    }

    // find ticket and add its count
    const tickets = (category as ICategory).price || [];

    const ticket = tickets.find((p) => p.priceId === payload.ticket.priceId);

    if (!ticket) {
      debug(`Can't find ticket in this category: ${category}`);
      return;
    }

    // skip if we haven't already bought tickets
    if (!ticket.count) {
      debug(`Ticket count is at minimum`);
      return;
    }

    // calculate the new count for that category and ticket price
    const newTicketCount =
      (ticket.count || 0) - (ticket.count > ticket.minBooking ?  1 : ticket.minBooking);

      // skip if it it's lower than the minimum (0 tickets)
    if (newTicketCount < 0) {
      debug(`removing a ticket will go below min ticket booking of ${newTicketCount}`);
      return;
    }

    // remove 1 if we already bought other ticket, or don't remove
    const newCatCount = (category.count || 0) - (ticket.count > ticket.minBooking ? 1 : (ticket.minBooking || 0));
    // don't lower if new category count becomes less than zero
    if (newCatCount < 0) {
      debug(`Removing a ticket will go below min category count of ${category.count}`);
      return;
    }

    // Update the ticket and category count when all goes well
    ticket.count = newTicketCount;
    category.count = newCatCount;

    // remove seat if present
    if (ticket.seats && payload.seat) {
      ticket.seats.splice(ticket.seats.indexOf(payload.seat), 1);
    }

    (category.price as ITicketPrice[]).forEach((ticketElement) => {
      ticketElement.key = String(Math.random());
    });
    // category.price = [...tickets];
    // commit('setCategories', [...(getters.getCategories)]);
    state.selectedSlots = [...state.selectedSlots];
  },

  // fetches the product through product shortname
  async [FETCH_PRODUCT]({commit}, shortname: string): Promise<IProduct> {
    // indicate that we are loading
    commit(SET_LOADING, true);

    try {
      const product = await fetchProduct(shortname);
      commit(RESET_BOOKING_STATE);
      commit(SET_PRODUCT, product);

      debug(`Action retrieved product ${shortname}`);
      return product;
    } catch (err) {
      debug(`Error: Action err while fetching ${shortname}`);

      throw err;
    } finally {
      commit(SET_LOADING, false);
    }
  },

  // fetches list of products
  async [FETCH_PRODUCTS]({commit}, productIds: number[]) {
    // set loading state
    commit(SET_LOADING, true);

    try {
      // fetch products
      const products = await fetchProducts(productIds);
      // return products
      return products;

    } catch (err) { // handle error

      debug(`ERROR: Action err while fetching ${productIds}`);
      throw err;

    } finally { // when all is done, unset loading state

      commit(SET_LOADING, false);

    }
  },

  async [FETCH_AVAILABILITIES]({ commit, getters, dispatch }):
  Promise<IAvailabilities> {
    const date: Date = getters.getDate;
    const product: IProduct = getters[GET_PRODUCT];
    const range: IAvailabilitiesRange = {
      from: moment(date).format('YYYY-MM-DD'),
      level: 1,
      productId: product.id,
    };

    if (getters[GET_BOOKING_FLOW] === 2) {
      range.to = moment(date).add(1, 'days').format('YYYY-MM-DD');
    }

    commit(SET_LOADING, true);

    try {

      const availabilities: IAvailabilities = await fetchAvailabilities(range);
      commit(SET_AVAILABILITIES, availabilities);

      // if booking flow is 0. Select slot directly to avoid Date and time checkout step
      if (!product) {
        return availabilities;
      }

      if (product.booking.flow > 0) {
        return availabilities;
      }

      debug(`Booking flow ${product.booking.flow}. Setting slot automatically`);
      const dates: IAvailableDates | null | undefined = availabilities.dates && availabilities.dates[0];
      const slot: ISlot | null | undefined = dates && dates.summarizedSlots && dates.summarizedSlots[0];
      commit('setSlot', slot);

      // new api requires toggling slots
      // toggle only if we don't have a selected slot
      const selectedSlots: ISlot[] = getters[GET_SELECTED_SLOTS];
      if (selectedSlots.length === 0) {
        await dispatch(TOGGLE_SLOT, slot);
      } else {
        // Otherwise, we already have a selected slot. So, we remove
        // it, then apply it again
        await dispatch(TOGGLE_SLOT, slot);
        await dispatch(TOGGLE_SLOT, slot);
      }

      return availabilities;
    } catch (err) {
      throw err;
    } finally {
      commit(SET_LOADING, false);
    }
  },

  async [FETCH_PRODUCT_CALENDAR]({ commit, getters }) {
    const date: Date = getters[GET_NEXT_PRODUCT_DATE] || getters.getDate || new Date();
    const from: string = moment(date).format('YYYY-MM-DD');
    const to: string = moment(date).add(1, 'years').format('YYYY-MM-DD');

    const product: IProduct = getters[GET_PRODUCT];
    const range: IAvailabilitiesRange = {
      from,
      to,
      level: 0,
      productId: product.id,
    };

    commit(SET_LOADING, true);
    try {
      const calendar = await fetchAvailabilities(range);
      commit(SET_CALENDAR, calendar);
    } catch (err) {
      throw err;
    } finally {
      commit(SET_LOADING, false);
    }
  },

  async [PRE_BOOK]({ commit, getters, rootGetters }) {
    // Do nothing if user has already booked
    const booking: IPreBookingRes = getters[GET_BOOKING];
    if (booking) {
      return;
    }

    // Modify app recap to send it to server
    const recap: IRecap = getters[GET_RECAP];
    const bookingData: IPreBookingReq = {
      productId: getters[GET_PRODUCT_ID],
      bookingLanguage: getLanguageInt(i18n.locale),

      // Referrer should be 1 of the following.
      // whichever one comes first, we choose it
      // 1) if a utm_source query parameter, set it
      // 2) if a referrer query parameter, set it
      // 3) smeetz.com
      bookingReferrer: ReferrerModule.getReferrerSite || ReferrerModule.referrer,
      ProductQt: [],
      utmChannel: ReferrerModule.utmChannel,
    };

    // const startDateTime = moment(recap.start).format('YYYY-MM-DD HH:mm:ss');
    // const endDateTime = moment(recap.end).format('YYYY-MM-DD HH:mm:ss');

    // Is this a dynamic pricing booking
    const isDPBooking: boolean = getters[GET_IS_DP_BOOKING];

    for (const cat of recap.categories) {
      for (const ticket of cat.ticketPrices) {
        const productQt: IPreBookProductQt = {
          startDateTime: dayjs(cat.startDateTime).format('YYYY-MM-DD HH:mm:ss'),
          endDateTime: dayjs(cat.endDateTime).format('YYYY-MM-DD HH:mm:ss'),
          categoryId: cat.id,
          quantity: ticket.count,
          priceId: ticket.id,
        };

        // Add dynamic pricing related parameters if this is a dynamic
        // pricing booking through dynamic pricing page
        if (isDPBooking) {
          const dpSlot: IDPSlotSource = getters[GET_DP_SLOT_DATA];

          productQt.dpSlot = dpSlot.dynamic_pricing_setup_id;
          productQt.timeSlot = dpSlot.time_slot_id;
          productQt.priceValue = getters[GET_DP_PRICE];
        }

        // Add the following dynamic pricing properties if booking happened
        // in booking flow with computed price tickets
        if (ticket.computedPrice) {
          productQt.timeSlot = ticket.computedPrice.timeSlotId;
          productQt.computedPriceId = ticket.computedPrice.computedPriceId;
          productQt.priceValue = ticket.computedPrice.priceValue;
        }

        // add each ticket by itself for seating products
        if (ticket.seats) {
          for (const seat of ticket.seats) {
            bookingData.ProductQt.push(Object.assign({}, productQt, {
              seat,
              holdToken: cat.holdToken,
              type: 3,
              quantity: 1,
            }));
          }
        } else {
          bookingData.ProductQt.push(productQt);
        }
      }
    }

    // Set loading
    commit(SET_LOADING, true);

    // bo
    try {
      const bookingResponse = await makeBooking(bookingData);
      // update store with booking result
      commit(SET_BOOKING, bookingResponse);
    } catch (err) {
      throw err;
    } finally {
      // clear loading
      commit(SET_LOADING, false);
    }
  },

  async [PATCH_GUEST_BOOKING]({ getters, commit }, payload: IGuestCheckoutUser) {
    const bookingId: number = getters[GET_BOOKING_ID];
    const bookingToken: string = getters[GET_BOOKING_TOKEN];

    try {
      commit(SET_LOADING, true);
      const booking: IPreBookingRes =
        await patchBooking(bookingId, bookingToken, payload);
      commit(SET_BOOKING, booking);
      return booking;
    } catch (err) {
      throw err;
    } finally {
      commit(SET_LOADING, false);
    }
  },

  async [CANCEL_BOOKING]({ getters, commit }) {
    // skip if no booking
    const booking: IPreBookingRes = getters[GET_BOOKING];
    if (!booking) {
      return;
    }

    // get booking id and token
    const bookingId = booking.bookingId;
    const bookingToken = booking.bookingToken;

    // make http request
    commit(SET_LOADING, true);
    try {
      await cancelBooking(bookingId, bookingToken);
    } catch (err) {
      throw err;
    } finally {
      commit(SET_LOADING, false);

      // clear booking data
      commit(SET_BOOKING, undefined);
      commit(SET_SERVER_BOOKING, undefined);
      commit(SET_GUEST_BOOKING, undefined);
    }

  },

  async [LOAD_PAYMENT_METHODS]({commit, getters}, payload?: IServerBookingData) {
    // retrieve bookingId and token
    const bookingId: number | undefined = payload ? payload.bookingId : getters[GET_BOOKING_ID];
    const bookingToken: string | undefined = payload ? payload.bookingToken : getters[GET_BOOKING_TOKEN];
    if (!bookingId || !bookingToken) {
      return;
    }

    // set loading
    commit(SET_LOADING, true);

    // fetch the payment methods
    try {
      const bookingPayments: IBookingPayments = await loadPaymentMethods(bookingId, bookingToken);
      // return payment methods
      return bookingPayments;
    } catch (err) {
      throw err;
    } finally {
      // unset loading
      commit(SET_LOADING, false);
    }

  },

  async [FETCH_SERVER_BOOKING]({ commit }, payload: IServerBookingData) {
    // Set loadinga
    commit(SET_LOADING, true);

    try {

      // fetch server booking
      const data: IServerBooking =
        await fetchServerBooking(payload.bookingId, payload.bookingToken);

      // set server booking
      commit(SET_SERVER_BOOKING, data);

      // return
      return data;

    } catch (err) {
      throw err;
    } finally {
      // unset loading
      commit(SET_LOADING, false);
    }
  },

  /**
   * Action used to add promo code to current booking.
   * It sets serverBooking.
   */
  async [POST_PROMO_CODE]({ commit }, payload: IPromoCodeData): Promise<IServerBooking> {
    // Set loadinga
    commit(SET_LOADING, true);

    try {

      // fetch server booking
      const data: IServerBooking =
        await postPromoCode(payload.bookingId, payload.bookingToken, payload.code);

      // set server booking
      commit(SET_SERVER_BOOKING, data);

      // return
      return data;

    } catch (err) {
      throw err;
    } finally {
      // unset loading
      commit(SET_LOADING, false);
    }
  },

  /**
   * Retrieve random dynamic pricing slot
   */
  async [FETCH_RANDOM_SLOT]({ commit, getters }): Promise<IDPSlot | undefined> {
    commit(SET_LOADING, true);
    const avoidedSlots: IDPSlotWReason[] = getters[GET_AVOIDED_DP_SLOT];
    const avoidedSlotsIds = avoidedSlots.map((slot) => slot._id);

    try {
      const slot = await fetchRandomSlot(avoidedSlotsIds);
      if (!slot) {
        // return Promise.resolve(undefined);
        return undefined;
      }

      // return Promise.resolve(slot);
      commit(SET_DP_SLOT, slot);
      return slot;
    } catch (err) {
      throw(err);
    } finally {
      setTimeout(() => {
        commit(SET_LOADING, false);
      }, 2000);
      // commit(SET_LOADING, false);
    }

  },

  async [AVOID_DP_SLOT]({ commit }, payload: {slot: IDPSlotWReason}) {
    commit(SET_LOADING, true);
    commit(ADD_AVOIDED_DP_SLOT, payload.slot);
    commit(SET_LOADING, false);

    return Promise.resolve();
  },
};

export default Actions;













import { Component, Vue } from 'vue-property-decorator';
import { ModalStatus } from '@/models/definitions';
import { ModalModule, NModalModule } from '@/utils/storemodules';

@Component({})
export default class ProductNav extends Vue {
  @ModalModule.Mutation('setStatus') private setStatus: any;

  private openReport() {
    // OPEN REPORT FORM HERE
    this.setStatus(ModalStatus.ReportActivity);
    NModalModule.setStatus(ModalStatus.ReportActivity);
  }
}

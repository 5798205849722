











/**
 * Renders a select html element with all options based on value prop.
 *
 * @Prop values {any[]}: Array of values as results
 * @Prop defaultValue {any}: Default value that should be chosen.
 *
 * @Event valueSelected: an event that is emitted once a value is selected
 */
import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import easydropdown from 'easydropdown';
import Timeout from 'await-timeout/dist/es5';
import { EVSelect } from '../../models/events';
import { isIos } from '@/utils/platform';

@Component({})
export default class Select extends Vue {
  @Prop({default: String(Math.random())}) private selectRef!: string;
  @Prop({default: String(Math.random())}) private name!: string;
  @Prop() private values!: string[] | number[];
  @Prop() private defaultValue!: any;
  @Prop() private new!: boolean;
  @Prop() private disabled!: boolean;

  private edd: any;
  private selected: string | number | null = this.defaultValue;
  private get getSelected() {
    return this.selected || null;
  }
  // Indicates whether the user can select or not.
  private canSelect: boolean = false;

  // Method for selecting the value;
  public setSelected(value: any) {
    this.selected = value;
  }
  // Selected value changes when default value changes.
  @Watch('defaultValue')
  private onDefaultChanged(val: string | number, oldVal: string | number) {
    // default value changed. So we change the selected value
    // without triggering a new select.
    this.canSelect = false;
    this.selected = val;
    // leave selection disabled for 1/2 a second
    Timeout.set(this.new ? 200 : 500).then(() => {
      if (this.new) {
        this.selected = val;
      }
      this.canSelect = true;
    });
  }

  @Watch('selected')
  private onSelectedChanged(val: string | number | null, oldVal: string | number | null) {
    // in case we're using this component in the same SlotPrice component
    // which happens to be inside PacageCategory component with multiple timeSlots
    // (SlotsHours component) we need to assign 'selected' the value of 'defaultValue' again
    // if (!val) {
    //   this.selected = this.defaultValue;
    // }
  }

  private async mounted() {
    // Watch out for zero as a default value.
    if (typeof this.defaultValue === 'number' || this.defaultValue) {
      this.selected = this.defaultValue;
    } else {
      this.selected = ' ';
    }

    const element = this.$refs[this.selectRef] as any;
    this.edd = easydropdown(element, {
      callbacks: {
        onSelect: this.onSelect,
      },
      behavior: {
        liveUpdates: true,
        // useNativeUiOnMobile: isIos() ? false : true,
      },
    });

    // avoid selection in the first render
    await Timeout.set(1000);
    this.canSelect = true;
  }

  private beforeDestroy() {
    this.edd.destroy();
  }

  private onSelect(value: any) {
    // can't select all the time
    if (!this.canSelect) {
      return;
    }

    this.$emit(EVSelect.ValueSelected, value);
  }

}


























import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class ProductDescription extends Vue {
  @Prop({default: ''}) private description!: string;
  private isFullShow: boolean = false;
  private isChangeble: boolean = false;

  private get cutDescription() {
    // Check how much is pure text in string
    const pureText = this.description.replace(/<[^>]*>?/gm, '');
    // Check how much is html text in string
    const htmlTextCount = this.description.length - pureText.length;
    // If pure text > 500 symbols and text is cutted
    if ( pureText.length > 250 && !this.isFullShow) {
      this.isChangeble = true;
      // return description pure text + html text + '...' in the end
      return this.description.slice(0, htmlTextCount + 250) + '...';
    } else {
      return this.description;
    }
  }

  private triggerShowFull() {
    this.isFullShow = !this.isFullShow;
  }
}
















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
})
export default class Select extends Vue {
  @Prop({ default: [] }) public items!: any[];
  @Prop({ default: '' }) public selected!: string;

  private selectedValue: string = '';
  private selectedItem: any;

  private isListVisible: boolean = false;

  private mounted() {
    this.selectedValue = this.selected;
    this.selectedItem = this.items.length ? this.items[0] : undefined;

    if (!this.selectedValue && this.selectedItem) {
      this.selectedValue = this.selectedItem.value;
    }
  }

  private selectedValueChange(item: any): void {
    // this.selectedValue = event.target.innerText;
    // this.$on('itemValue', this.selectedValue);
    this.selectedValue = item.value;
    this.selectedItem = item;
    this.toggleIsVisible();
    this.$emit('select', this.selectedItem);
  }

  private toggleIsVisible(): void {
    this.isListVisible = !this.isListVisible;
  }
}











import { Vue, Component } from 'vue-property-decorator';
import { constants } from '@/config/constants.ts';

@Component({})
export default class Terms extends Vue {
  private policyLink = '';
  private termsLink = '';

  private mounted() {
    this.policyLink = constants.legal.policy[this.$i18n.locale];
    this.termsLink = constants.legal.terms[this.$i18n.locale];
  }
}


















































import { Vue, Component } from 'vue-property-decorator';
import { email, required, maxLength } from 'vuelidate/lib/validators';
import ModalButton from '@/components/modal/ModalButton.vue';
import { ModalStatus } from '@/models/definitions';
import { ModalModule, AuthModule } from '@/utils/storemodules';
import Debug from 'debug';
const debug = Debug('smeetz:auth');


@Component({
  components: {
    ModalButton,
  },
  validations: {
    email: {
      required,
      email,
      maxLength: maxLength(255),
    },
  },
})
export default class ForgotPass extends Vue {
  private email: string = '';

  @AuthModule.Action('resetPassReq') private resetPass: any;
  @ModalModule.Mutation('setStatus') private setStatus: any;

  get enableSubmit() {
    return !this.$v.$error && this.email;
  }

  private async submit() {
    if (!this.enableSubmit) {
      return;
    }

    try {
      await this.resetPass(this.email);
      this.setStatus(ModalStatus.Hidden);
      debug('password reset was sent');
    } catch (err) {
      debug(`reset pass failed: ${err}`);
    }
  }
}

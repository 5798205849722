

















import NewButton from '@/components/presentational/NewButton.vue';
import { RouteNames } from '@/models/enums';
import { closeIframe } from '@/utils/iframe';
import { inIframe } from '@/utils/iframe';
import { NBookingModule, ProductModule, UserModule, AppModule } from '@/utils/storemodules';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { WidgetOps } from '@/models/iframe';

@Component({
  components: {
    NewButton,
  },
})

export default class UserSpaceNav extends Vue {
  get isCustomAuthLoggedIn(): boolean {
    return UserModule.isCustomAuthLoggedIn;
  }

  get isLoggedIn(): boolean {
    return UserModule.isLogedIn;
  }

  get isShowGoBackButton() {
    return UserModule.isBookingSummaryShowGoBackButton;
  }

  get isShowSignOutButton() {
    return !UserModule.isFromBookingFlow;
  }

  get isShowCloseButton() {
    return UserModule.isFromBookingFlow || inIframe();
  }

  // Logs user out
  private customAuthLogOut() {
    UserModule.customAuthLogOut();
  }
  private close() {
    if (UserModule.isFromBookingFlow && !inIframe()) {
      NBookingModule.clear();
      const productShortUrl = this.$route.query.product_short_url as string;
      if (productShortUrl) {
      this.$router.push({
        name: RouteNames.Product,
        params: { short_name: productShortUrl },
      });
      } else {
      this.$router.push({name: RouteNames.Home});
      }
    } else {
      // Make sure to destroy the widget
      closeIframe(
        {
        smtzOp: WidgetOps.CloseWidget,
        how: 'd',
        wId: AppModule.widgetId,
      },
      );
    }
  }
}

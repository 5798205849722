import { LoggerService } from '@/services/log';
import { createClient, dedupExchange, fetchExchange, mapExchange } from '@urql/core';

const url = process.env.VUE_APP_GQL_URL;

const latencyExchange = mapExchange({
  async onOperation(operation) {
    // Attach a start time to the operation context for latency tracking
    operation.context.requestStart = Date.now();
    return operation;
  },
  async onResult(result) {
    // Calculate the latency for the operation
    const latency = Date.now() - result.operation.context.requestStart;
    // Ensure if things break, we don't break the app
    try {
      // Log the latency
      const query = result.data && Object.keys(result.data)[0];
      LoggerService.info(`${query} took ${latency}ms`, {
        value: latency,
        response: result.data,
        error: result.error,
      });
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error('Error while logging urql latency', error);
    }
    return result;
  },
});

export const client = createClient({
  url,
  exchanges: [dedupExchange, latencyExchange, fetchExchange],
});











import { Vue, Component, Prop } from 'vue-property-decorator';
import SocialsLogin from './SocialsLogin.vue';
import LoginDialog from './LoginDialog.vue';
import { enableQuasarStyling } from '@/utils/styles';
import { Loading } from 'quasar';


@Component({
  components: {
    SocialsLogin,
    LoginDialog,
  },
})
export default class LoginView extends Vue {
  @Prop({default: 0}) public windowWidth!: number;
  private isOpenLoginDialog: boolean = false;

  private mounted() {
    const topOfBookingFlow = document.getElementById('new-booking-nav');
    if (topOfBookingFlow) {
      topOfBookingFlow.scrollIntoView();
    }
  }

  private openLoginDialog() {
    this.isOpenLoginDialog = true;
  }

  private closeLoginDialog() {
    this.isOpenLoginDialog = false;
  }

  private orgUserLoggedIn() {
    enableQuasarStyling();
    Loading.show();
  }
}


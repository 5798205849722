























































































/**
 * Renders hours for a given slots
 *
 * @Prop slots {ITimeslotsEntity}
 * @Prop ticket {ITicket}
 */
import { Vue, Component, Prop, Watch} from 'vue-property-decorator';
import dayjs from 'dayjs';
import { Mixins } from 'vue-mixin-decorator';
import { ITimeslotsEntity, ITicket, ITimeslotDisplayOption } from '../../../models/store/booking';
import { ESlotsHours } from '../../../models/events';
import DateHelperMixin from '@/mixins/DateHelper';
import { hideEndDateTime } from '@/utils/booking';
import { AppModule, NBookingModule, ProductModule, NNoty, OrganizerModule } from '@/utils/storemodules';
import { getDecimalDigits } from '@/utils/helpers';
import { IPricePackage } from '@/models/store/packages';
import { TimeFormatB2C, TimeFormatNameB2C, TimeslotAvailability, TimeslotFomoStrategy } from '@/models/enums';

interface IHourObj {
  startTime: string;
  endTime: string;
  publicCount: number;
  booked: number;
  toolTipTxt: string;
  price?: number;
}

@Component({})
export default class NewSlotsHours extends Mixins<DateHelperMixin>(DateHelperMixin) {
  @Prop() public slots!: ITimeslotsEntity[];
  @Prop() public ticket!: ITicket;
  @Prop({default: null}) public pricePackage!: IPricePackage;
  @Prop({default: null}) public mainTimeSlotId!: number;
  @Prop({default: false}) public withBorder!: boolean;
  @Prop({default: ''}) public hour!: string;
  @Prop({default: false}) public isAddon!: boolean;
  private morningSlots: IHourObj[] = [];
  private afternoonSlots: IHourObj[] = [];
  private eveningSlots: IHourObj[] = [];
  private selectedPeriodOfDay: number = 0;

  get finalSlots(): ITimeslotsEntity[] {
    if (!this.pricePackage || this.pricePackage.min_quantity === 0) { return this.slots; }
    return this.slots.filter((slot) => slot.publicCount >= this.pricePackage.min_quantity);
  }

  get hours(): IHourObj[] {
    const hours: IHourObj[] = [];
    const hoursObj: {[s: string]: IHourObj} = {};

    if (!this.finalSlots) {
      return [];
    }

    // create object with keys in the format 11:00
    for (const slot of this.finalSlots) {
      const startDateObj = dayjs(slot.startDateTime);
      const endDateObj = dayjs(slot.endDateTime);
      const startTime = startDateObj.format('HH:mm');
      const endTime = endDateObj.format('HH:mm');
      const publicCount = slot.publicCount;
      const booked = slot.booked;
      const gradient = booked / (booked + publicCount);
      const toolTipTxt = publicCount === 0 ? this.$t('button.sold-out').toString() : '';
      // The price we're going to display is the minimum of reference prices (priceType===0) of the ticket
      // if the prices are all not reference prices then we will show the minimum price of that slot whatever type it is
      const referencePrices = slot.export_prices?.filter((p) => p.priceType === 0).map((p) => Number(p.priceValue));

      let price: number | undefined;
      let roundedPrice: number | undefined;
      if (referencePrices && referencePrices.length) {
        price = Math.min.apply(Math, referencePrices);
        roundedPrice = Number(price.toFixed(2));
      } else {
        const prices = slot.export_prices?.map((p) => p.priceValue);
        if (prices) {
          price = Math.min.apply(Math, prices);
          roundedPrice = Number(price.toFixed(2));
        }
      }

      hoursObj[dayjs(slot.startDateTime).format('HH:mm')] = {
        startTime, endTime, publicCount, booked, toolTipTxt, price: roundedPrice,
      };
    }

    for (const value of Object.values(hoursObj)) {
      hours.push(value);
    }

    // sort the array
    hours.sort((hour1: IHourObj, hour2: IHourObj) => {
      const h1 = Number(hour1.startTime.split(':')[0]);
      const h2 = Number(hour2.startTime.split(':')[0]);
      if (h1 < h2) {
        return -1;
      } else if (h1 > h2) {
        return 1;
      }

      return 0;
    });

    this.morningSlots = [];
    this.afternoonSlots = [];
    this.eveningSlots = [];

    for (const hour of hours) {
      const startTime = Number(hour.startTime.split(':')[0]);
      if (startTime < 12) {
        this.morningSlots.push(hour);
      } else if (startTime < 17) {
        this.afternoonSlots.push(hour);
      } else {
        this.eveningSlots.push(hour);
      }
    }

    return hours;
  }

  get currency() {
    return ProductModule.productCurrency;
  }

  get ukTimeDisplay() {
    return OrganizerModule.getInfo &&
      OrganizerModule.getInfo.groupInfo.timeFormatB2c === TimeFormatNameB2C[TimeFormatB2C._12HourFormat];
  }

  get chooseTimeText() {
    // Anniversaire product of Fort Boyard
   if ([63413, 65365, 65471, 65496, 65497, 65498, 65499, 65500].includes(this.ticket.productId) && !this.isAddon) {
    return this.$t('new-booking-flow.common.choose-time-fort-boyard');
   } else {
    return this.$t('new-booking-flow.common.choose-time');
   }
  }

  get enableNewCalendarTimeslotDesign() {
    // Groups for which we'll disable the new calendar design
    // const disableCalendarNewDesign = [16375, 17962, 18636, 19285, 19327,
    //   19552, 19560, 19027, 16268, 19566, 19567, 19573, 19583, 19584, 19585, 19586, 19572];
    // const groupId = Number(OrganizerModule.id);
    if (this.timeSlotDisplayOp && !this.timeSlotDisplayOp.hide_new_time_slot_design) {
      return this.hours.length > 9;
    }
    // return this.hours.length > 9 && !disableCalendarNewDesign.includes(groupId);
  }

  get chooseTimePeriodText() {
    // Anniversaire product of Fort Boyard
    if ([63413, 65365, 65471, 65496, 65497, 65498, 65499, 65500].includes(this.ticket.productId) && !this.isAddon) {
      return this.$t('new-booking-flow.common.choose-time-fort-boyard');
    } else {
      return this.$t('new-booking-flow.common.choose-period-day');
    }
  }

  get periodOfDay() {
    return [
      {
        title: this.$t('new-booking-flow.common.period.morning'),
        data: this.morningSlots,
        disabled : this.morningSlots.every((t) => t.publicCount === 0) || this.morningSlots.length < 1,
      },
      {
        title: this.$t('new-booking-flow.common.period.afternoon'),
        data: this.afternoonSlots,
        disabled : this.afternoonSlots.every((t) => t.publicCount === 0) || this.afternoonSlots.length < 1,
      },
      {
        title: this.$t('new-booking-flow.common.period.evening'),
        data: this.eveningSlots,
        disabled : this.eveningSlots.every((t) => t.publicCount === 0) || this.eveningSlots.length < 1,
      }];
  }

  get displayedSlots() {
    if (this.enableNewCalendarTimeslotDesign) {
      return this.periodOfDay[this.selectedPeriodOfDay].data;
    }
    return this.hours;
  }

  private get timeSlotDisplayOp(): ITimeslotDisplayOption | null {
    return this.ticket.timeSlotDisplayOption;
  }

  // new timeslots design
  private get fomoStrategyEnabled(): boolean | null {
    return this.ticket && this.timeSlotDisplayOp && this.timeSlotDisplayOp.fomo_strategy_show_enabled;
  }

  private get hurryUpStrategy(): any {
    if (!this.fomoStrategyEnabled || !this.ticket || !this.timeSlotDisplayOp) {
      return false;
    }
    return {
      activateAt: this.timeSlotDisplayOp.hurry_up_activate_at,
      strategyType: this.timeSlotDisplayOp.hurry_up_strategy_type,
      textColor: this.timeSlotDisplayOp.hurry_up_text_color,
      backgroundColor: this.timeSlotDisplayOp.hurry_up_badge_color,
    };
  }

  private get isHurryUpInner() {
    return this.hurryUpStrategy.strategyType === TimeslotFomoStrategy.BadgeCenter ||
      this.hurryUpStrategy.strategyType === TimeslotFomoStrategy.TextCenter;
  }

  private get soldOutStrategy(): any {
    if (!this.fomoStrategyEnabled || !this.ticket || !this.timeSlotDisplayOp) {
      return false;
    }
    return {
      strategyType: this.timeSlotDisplayOp.sold_out_strategy_type,
      textColor: this.timeSlotDisplayOp.sold_out_text_color,
      backgroundColor: this.timeSlotDisplayOp.sold_out_badge_color,
    };
  }

  private get isSoldOutInner() {
    return this.soldOutStrategy.strategyType === TimeslotFomoStrategy.BadgeCenter ||
      this.soldOutStrategy.strategyType === TimeslotFomoStrategy.TextCenter;
  }

  private get displayPriceStrategy(): any {
    if (!this.ticket || !this.timeSlotDisplayOp || !this.timeSlotDisplayOp.price_show_enabled) {
      return false;
    }
    return {
      lowNumber: this.timeSlotDisplayOp.price_low_number_gradient,
      lowColor: this.timeSlotDisplayOp.price_low_color_gradient,
      mediumNumber: this.timeSlotDisplayOp.price_medium_number_gradient,
      mediumColor: this.timeSlotDisplayOp.price_medium_color_gradient,
      highNumber: this.timeSlotDisplayOp.price_high_number_gradient,
      highColor: this.timeSlotDisplayOp.price_high_color_gradient,
    };
  }


  private get displayAvailabilityStrategy(): any {
    if (!this.ticket || !this.timeSlotDisplayOp || !this.timeSlotDisplayOp.availability_show_enabled) {
      return false;
    }
    return {
      lowNumber: this.timeSlotDisplayOp.availability_low_number_gradient,
      lowTextColor: this.timeSlotDisplayOp.availability_low_text_color_gradient,
      lowBadgeColor: this.timeSlotDisplayOp.availability_low_badge_color_gradient,
      mediumNumber: this.timeSlotDisplayOp.availability_medium_number_gradient,
      mediumTextColor: this.timeSlotDisplayOp.availability_medium_text_color_gradient,
      mediumBadgeColor: this.timeSlotDisplayOp.availability_medium_badge_color_gradient,
      highNumber: this.timeSlotDisplayOp.availability_high_number_gradient,
      highTextColor: this.timeSlotDisplayOp.availability_high_text_color_gradient,
      highBadgeColor: this.timeSlotDisplayOp.availability_high_badge_color_gradient,
      displayAs: this.timeSlotDisplayOp.availability_display_as,
      position: this.timeSlotDisplayOp.availability_display_position,
    };
  }

  private getPriceStrategyColor(hr: IHourObj): string {
    const {
      lowNumber,
      lowColor,
      mediumNumber,
      mediumColor,
      highColor,
    } = this.displayPriceStrategy;
    if (hr && Number(hr.price) <= lowNumber) {
        return lowColor;
    } else if (hr && Number(hr.price) <= mediumNumber) {
        return mediumColor;
    } else {
        return highColor;
    }
  }

  private getAvailabilityTextColor(occupancy: number): string {
    const {
      lowNumber,
      lowTextColor,
      mediumNumber,
      mediumTextColor,
      highTextColor,
    } = this.displayAvailabilityStrategy;
    if (occupancy <= lowNumber) {
        return lowTextColor;
    } else if (occupancy <= mediumNumber) {
        return mediumTextColor;
    } else {
        return highTextColor;
    }
  }

  private getAvailabilityBadgeColor(occupancy: number): string {
    const {
      lowNumber,
      lowBadgeColor,
      mediumNumber,
      mediumBadgeColor,
      highBadgeColor,
    } = this.displayAvailabilityStrategy;
    if (occupancy <= lowNumber) {
        return lowBadgeColor;
    } else if (occupancy <= mediumNumber) {
        return mediumBadgeColor;
    } else {
        return highBadgeColor;
    }
  }

  private hideAvailability(timeslot: IHourObj): boolean {
    const { hurryUpStrategy, soldOutStrategy } = this;
    if (hurryUpStrategy && hurryUpStrategy.strategyType !== TimeslotFomoStrategy.NoStrategy &&
        timeslot.publicCount <= hurryUpStrategy.activateAt && timeslot.publicCount > 0) {
        return true;
    }
    if (soldOutStrategy && soldOutStrategy.strategyType !== TimeslotFomoStrategy.NoStrategy &&
      timeslot.publicCount === 0) {
        return true;
    }
    return false;
  }

  private isPriceStrategyDisplayed(timeslot: IHourObj): boolean {
    return this.displayPriceStrategy && timeslot.price;
  }

  private shouldShowHurryUp(hr: IHourObj) {
    return this.hurryUpStrategy &&
      this.hurryUpStrategy.strategyType !== TimeslotFomoStrategy.NoStrategy &&
      hr.publicCount <= this.hurryUpStrategy.activateAt &&
      hr.publicCount > 0;
  }

  private hurryUpStyle(hr: IHourObj) {
    return {
      'color': this.hurryUpStrategy.textColor,
      'background': this.hurryUpStrategy.strategyType === TimeslotFomoStrategy.TextCenter ?
        'transparent' : this.hurryUpStrategy.backgroundColor,
      'border': this.hurryUpStrategy.strategyType  === TimeslotFomoStrategy.TextCenter ||
        (this.hurryUpStrategy.strategyType !== TimeslotFomoStrategy.TextCenter &&
        !this.isWhiteBackground(hr.publicCount, 'hurryup')) ? 'none' : '',
      'position': this.hurryUpStrategy.strategyType === TimeslotFomoStrategy.BadgeTopLeft ? 'absolute' : '',
      'top': this.hurryUpStrategy.strategyType === TimeslotFomoStrategy.BadgeTopLeft ? '-8px' : '',
      'right': this.hurryUpStrategy.strategyType === TimeslotFomoStrategy.BadgeTopLeft ? '-8px' : '',
      'font-weight': 700,
    };
  }

  private shouldShowSoldOut(hr: IHourObj) {
    return this.soldOutStrategy &&
      this.soldOutStrategy.strategyType !== TimeslotFomoStrategy.NoStrategy &&
      hr.publicCount === 0;
  }

  private soldOutStyle(hr: IHourObj) {
    return {
      'color': this.soldOutStrategy.textColor,
      'background': this.soldOutStrategy.strategyType === TimeslotFomoStrategy.TextCenter ?
        'transparent' : this.soldOutStrategy.backgroundColor,
      'border': this.soldOutStrategy.strategyType === TimeslotFomoStrategy.TextCenter ||
        (this.soldOutStrategy.strategyType !== TimeslotFomoStrategy.TextCenter &&
        !this.isWhiteBackground(hr.publicCount, 'soldout')) ? 'none' : '',
      'position': this.soldOutStrategy.strategyType === TimeslotFomoStrategy.BadgeTopLeft ? 'absolute' : '',
      'top': this.soldOutStrategy.strategyType === TimeslotFomoStrategy.BadgeTopLeft ? '-8px' : '',
      'right': this.soldOutStrategy.strategyType === TimeslotFomoStrategy.BadgeTopLeft ? '-8px' : '',
      'font-weight': 700,
    };
  }

  private shouldHidePrice(hr: IHourObj) {
    return this.fomoStrategyEnabled && ((this.shouldShowSoldOut(hr) && this.isSoldOutInner) ||
      (this.shouldShowHurryUp(hr) && this.isHurryUpInner));
  }

  private shouldDisplayAvailability(hr: IHourObj) {
    return this.displayAvailabilityStrategy &&
      !this.hideAvailability(hr) &&
      hr.publicCount > 0;
  }

  private availabilityStyle(hr: IHourObj) {
    return {
      color: this.getAvailabilityTextColor(hr.publicCount),
      background: this.displayAvailabilityStrategy.displayAs === TimeslotAvailability.Text &&
        !this.isPriceStrategyDisplayed(hr) ?
        'transparent' : this.getAvailabilityBadgeColor(hr.publicCount),
      border: (this.displayAvailabilityStrategy.displayAs === TimeslotAvailability.Text &&
        !this.isPriceStrategyDisplayed(hr)) ||
        (this.displayAvailabilityStrategy.displayAs !== TimeslotAvailability.Text &&
        !this.isWhiteBackground(hr.publicCount, 'avail')) ?
        'none' : '',
      position: this.displayAvailabilityStrategy.position === TimeslotAvailability.Badge ||
        this.isPriceStrategyDisplayed(hr) ? 'absolute' : '',
      top: this.displayAvailabilityStrategy.position === TimeslotAvailability.Badge ||
        this.isPriceStrategyDisplayed(hr) ? '-8px' : '',
      right: this.displayAvailabilityStrategy.position === TimeslotAvailability.Badge ||
        this.isPriceStrategyDisplayed(hr) ? '-8px' : '',
    };
  }

  private isWhiteBackground(count: number, strategy: string): boolean {
    const color = strategy === 'avail' ? this.getAvailabilityBadgeColor(count) :
      strategy === 'soldout' ? this.soldOutStrategy.backgroundColor :
      strategy === 'hurryup' ? this.hurryUpStrategy.backgroundColor : '';
    const upperCaseColor = color.toUpperCase();
    return upperCaseColor === '#FFFFFF';
  }

  private getAvailabilityText(count: string): string {
    return (this.$t('common.avail-seats') as string).replace('XX', count);
  }

  private getHurryUpText(count: string): string {
    if (Number(count) <= 1) {
      return (this.$t('common.left-seat') as string).replace('XX', count);
    }
    return (this.$t('common.left-seats') as string).replace('XX', count);
  }

  private getDecimalDigits(n: number, x: number) {
    return getDecimalDigits(n, x);
  }

  @Watch('finalSlots')
  private async onfinalSlots() {
    if (this.isAddon && this.pricePackage &&
      this.mainTimeSlotId && this.finalSlots.length === 0 && this.slots.length > 0) {
       NNoty.createNewNoty({
        period: 3500,
        message: String(this.$t('error.no-addons-with-enough-quantity')),
        type: 'error',
      });

       await NBookingModule.addPriceLite({
        timeSlotId: this.mainTimeSlotId,
        ticketId: this.ticket.categoryId,
        priceId: this.pricePackage.price_id,
        count: 0,
      });
    }
  }

  @Watch('slots', {immediate: true})
  private onSlotsUpdate() {
    // // Incase we have many slots & only 1 hour (Multiple slots with one starting time)
    // // Make sure that we select that hour, cause the slots will be hidden
    const { slots, hours } = this;
    if (slots && slots.length > 1 && hours.length === 1) {
        this.selectHour(hours[0].startTime);
    }
    this.selectedPeriodOfDay = this.morningSlots.length > 0 ? 0 : this.afternoonSlots.length > 0 ? 1 : 2;
    if (this.periodOfDay[this.selectedPeriodOfDay].disabled) {
      let index = 0;
      for (const period of this.periodOfDay) {
        if (!period.disabled) {
          this.selectedPeriodOfDay = index;
          break;
        }
        index++;
      }
    }
  }

  private selectHour(hour: string) {
    this.$emit(ESlotsHours.HourSelected, hour);
  }

  get isEndHidden() {
    const endDisplayGroups = [19610];
    const groupId = OrganizerModule.id || OrganizerModule.orgId;
    if (endDisplayGroups.includes(Number(groupId))) {
      return hideEndDateTime(this.ticket);
    }
    if (this.timeSlotDisplayOp && this.timeSlotDisplayOp.show_end_time) {
      return false;
    }
    return true;
  }

  private get isDesktop() {
    return AppModule.isDesktop;
  }

  private get isTablet() {
    return AppModule.isTablet;
  }
}

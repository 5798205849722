


































































import { Vue, Component } from 'vue-property-decorator';
import { numeric, required, minLength, maxLength } from 'vuelidate/lib/validators';
import ModalButton from '@/components/modal/ModalButton.vue';
import Select from '@/components/presentational/Select.vue';
import { ModalModule, AuthModule } from '@/utils/storemodules';
import { ModalStatus } from '@/models/definitions';

@Component({
  components: {
    ModalButton,
    Select,
  },
  validations: {
    phone: {
      required,
      numeric,
      minLength: minLength(5),
      maxLength: maxLength(16),
    },
  },
})
export default class AddPhone extends Vue {
  private phone: string = '';
  private countryCode = '+41';
  private countryCodes = [
    {
      key: 'first',
      value: '+41',
    },
    {
      key: 'second',
      value: '+33',
    },
  ];
  private responseError: string = '';

  @AuthModule.Action('getUser') private getUser: any;
  @AuthModule.Action('addPhone') private addPhone: any;
  @ModalModule.Mutation('setStatus') private setStatus: any;
  @ModalModule.Mutation('setData') private setData: any;

  get enableSubmit() {
    return !this.$v.$error && this.phone;
  }

  private async submit() {
    if (!this.enableSubmit) {
      return;
    }

    const button: any = this.$refs.button;
    button.executing(true);

    try {
      await this.addPhone({
        countryCode: this.countryCode.slice(1),
        phoneNumber: this.phone,
      });

      await this.getUser();
    } catch (err) {
      button.executing(false);
      return;
    }

    this.setStatus(ModalStatus.MobilePhoneConf);
    this.setData({
      phone: `${this.countryCode}${this.phone}`,
    });
    button.executing(false);
  }

  private selectCode(item: any) {
    this.countryCode = item.value;
  }
}



























































import { Mixins, Component, Prop, Watch } from 'vue-property-decorator';
import DateHelperMixin from '@/mixins/DateHelper';
import {
  ITicket, ITimeslotsEntity, IMembershipCustomer,
  IPriceCount, ISeatingSubProductsEntity,
  IExportPricesEntity, ITicketField, InformationType,
  IPostBookingField,
  IServerRecapCategory,
  ICustomerTickets,
} from '../../../models/store/booking';
import { ISeatPrice, ISeatTicketType } from '@/models/seats';
import NewButton from '@/components/presentational/NewButton.vue';
import Tag from '@/components/presentational/TicketTag.vue';
import dayjs from 'dayjs';
import VueScript from 'vue-script2';
import { ProductModule, NBookingModule, AppModule, ReferrerModule, OrganizerModule, NNoty, BookingModule } from '@/utils/storemodules';
import { expandIfChartIsPresent, getCartBookingInfo, inIframe, sendCartBookingInfo } from '@/utils/iframe';
import Debug from 'debug';
import { SeatType, EventType, PriceCategory } from '../../../models/enums';
import { IProduct } from '@/models/store/product';
import Package from './PricePackage.vue';
import { IPricePackage } from '@/models/store/packages';
import { getElementById, scrollToElement } from '@/utils/dom';
import { disableQuasarStyling, enableQuasarStyling } from '@/utils/styles';
import { applyMemDiscount, getBookingFields, postBookingField } from '@/api/booking';
import { IPostMembershipData, MembershipType, MemType } from '@/models/memberships';
import { Loading } from 'quasar';
import {
  hideDateTime, hideEndDateTime, hideStartDateTime,
  isCalendarTicket,
  isChildPriceCategory, isChildPriceName, isNonChildPriceCategory,
  isNonChildPriceName,
} from '@/utils/booking';
import {
  customersPriceCateogries, getCustomerWithOnlyOtherTickets, hadAllCustomersBooked,
  isCustomerWithOnlyOtherTickets, isFamilyMembership, isRequiredTicketsMembership,
} from '@/utils/membership';
import { destroyCharts } from '@/utils/helpers';
import { smeetzTracker } from '@/utils/tracking';
import { isRealMembershipDiscount } from '@/utils/membership-utils';
import { arraysEqual } from '@/utils/jsHelpers';
const debug = Debug('smeetz:booking');
const debug1 = Debug('smeetz:forceprice');

@Component({
  components: {
    NewButton,
    Package,
    Tag,
  },
})
export default class SeatingSlot extends Mixins<DateHelperMixin>(DateHelperMixin) {
  @Prop() public ticket!: ITicket;
  @Prop() public slots!: ITimeslotsEntity[];
  @Prop() public selectedSlot!: ITimeslotsEntity[];
  @Prop({default: false}) public renderSeatingPlan!: boolean; // Show the seating chart directly
  @Prop({default: () => []}) public customCustomers!: IMembershipCustomer[];
  @Prop({default: 0}) public maxTicketValidation!: number;
  @Prop({default: () => []}) public priceIdsToDisplay!: number[];
  @Prop({default: () => []}) public categoryIdsToDisplay!: number[];
  private loading: boolean = false;
  private seatsio: any;
  private chartId: string = `chart${Math.random()}`;
  private chart: any;
  private isChartPresent: boolean = false;
  private maxSeatsSelected = 0;

  private availableCategories: string[] = [];
  private showPricePackage: boolean = false;
  private pricePackage?: IPricePackage | null;
  private price?: IExportPricesEntity;
  private chosenSlot?: ITimeslotsEntity;
  private canShowLoading = false;
  // used to prevent showing quasar spinner when
  // the seat is not deselected by a user click
  private didUserClick: boolean = false;

  // We want pricingData getter of chartConfig to do some prices filtering
  // - we want this to happen only when the user selects a customer or when the chart is completely rendered
  // - we don't want this filtering to happen when rendering the chart
  // because in a scenario where the user is coming back from membership validation page
  // he will find his charts empty even though he has already selected from it,
  // the selected seats will disappear because we rendered the chart without their prices
  private chartRendering: boolean = false;
  private eventKey = this.ticket.eventKey;

  // Contains a list of Categories whose Seats Max Selection is 1
  // for now we have 2: Playoffs Abonnement (Fribourg olympic) and GC Basketball GmbH
  private categoriesWithOneSeatMaxSelection: number[] = [60433, 60576];

  // return true if a seatingTicket has prices
  get hasPrices(): boolean {
    const seatingSubProducts = this.seatingTickets.filter((item: any) => {
      return item.price && item.price.length;
    });
    if (!seatingSubProducts || !seatingSubProducts.length) {
      return false;
    }
    return true;
  }

  // Hiding end date time for equilibre for now
  get isEndHidden() {
    const orgId = OrganizerModule.id;
    if ((orgId && orgId === 16192) || hideEndDateTime(this.ticket)) {
      return true;
    } else {
      return false;
    }
  }

  // Groups for which we'll add noOrphanSeats validation
  get activateNoOrphanSeatsValidation(): boolean {
    const noOrphanSeatsValidation = [14773, 19066, 19017, 19377, 18916, 19380, 18636, 19644];
    const groupId = Number(OrganizerModule.id);
    return noOrphanSeatsValidation.includes(groupId);
  }

  get isStartHidden() {
    return hideStartDateTime(this.ticket);
  }

  get showDate() {
    return !hideDateTime(this.ticket);
  }

  private get isOneTicketAfterSelect() {
    // const ticketsNum = NBookingModule.fetchedTickets.length;
    // const selectedTicketNum = NBookingModule.selectedTickets.length;

    // Show cart directly if one seat is added in the current seating plan
    const aSeatIsAdded = NBookingModule.recapCategories.find((cat) =>
      cat.seatingMainSubProductId === this.ticket.categoryId);

    // if (ticketsNum === 1 && selectedTicketNum === 1) {
    //   return true;
    // }

    if (aSeatIsAdded) {
      return true;
    }
    return false;
  }

  get getPricePackage() {
    return NBookingModule.pricePackage;
  }

  get finalSelectedSlot() {
    return this.slots.length === 0 ? [] :
      this.slots.length === 1 ?  this.slots :
      this.slots.length > 0 && this.selectedSlot ? this.selectedSlot : [];
  }

  get startDate() {
    return this.currentDate(this.finalSelectedSlot[0].startDateTime);
  }

  get endDate() {
    return this.currentDate(this.finalSelectedSlot[0].endDateTime);
  }

  get seatingTickets(): ISeatingSubProductsEntity[] {
    const seatingProducts = this.ticket.seatingSubProducts;

    if (seatingProducts) {
      return (seatingProducts as ISeatingSubProductsEntity[]);
    }

    return [];
  }

  get showButton() {
    // Show the button only if isChartPresent = false (the chart isn't present)
    // or renderedChartRef in state is different than this one
    // (it means this one can be rendered, so lets show the button)
    return !this.isChartPresent || this.chartId !== NBookingModule.renderedChartRef;
  }

  get shows(): ITicket[] {
    return ProductModule.showsAddons;
  }

  get showsPrices() {
    return ProductModule.showsPrices;
  }

  private exportPrice(price: IExportPricesEntity) {
    let exportPrices = this.ticket.categoryInfo.timeslots[0].export_prices;
    if (this.ticket.isRecurringSeat) {
      exportPrices = this.selectedSlot && this.selectedSlot.length > 0 ?
        this.selectedSlot[0].export_prices : this.slots[0].export_prices;
    }
    const exportPrice = exportPrices && exportPrices.find((ePrice) => {
      return ePrice.priceId === price.priceId;
    });

    if (exportPrice) {
      return exportPrice;
    }

    return null;
  }

  private async mounted() {
    this.loading = true;

    try {
      await VueScript.load('https://cdn.seatsio.net/chart.js');
      this.seatsio = (window as any).seatsio;
      // Show the seating chart directly when we have one ticket only.
      // After the user has selected seats clicked on "buy" and went back to the seating chart
      // by clicking on the go back arrow.
      if (this.isOneTicketAfterSelect || this.renderSeatingPlan) {
        this.renderChart();
      }

      // Set the max selected tickets for GA seats.
      // GA seats have quantity that could be larger than 1 & the seats have the same name
      // This will allow us to set the maxSeatsSelected which helps with knowing if we are
      // decreasing GA seats or not.
      const booking = NBookingModule.bookingRes;
      // TODO remove next comment once customers based checking is tested
      // if (!ReferrerModule.isMembershipOnly) {
      if (!NBookingModule.isCustomerBooking && !this.isCustomCustomerBooking) {
        return;
      }

      if (booking && booking.bookingRecap && booking.bookingRecap.categories) {
        const categories = booking.bookingRecap.categories;
        const subProducts = this.ticket.seatingSubProducts;

        if (!subProducts || !subProducts.length) {
          return;
        }
        const subProductsIds = subProducts.map((prod) => prod.categoryId);
        const seatBookingCategories = categories
          .filter((c) => subProductsIds.includes(c.categoryId));

        // No necessary if we have a GA with 1 seat selected.
        if (!seatBookingCategories.length) {
          return;
        }

        let total = 0;
        const seat = seatBookingCategories[0].seat;
        for (const cat of seatBookingCategories) {
          // differetn seat names aren't GA.
          if (cat.seat !== seat) {
            return;
          }
          total += Number(cat.quantity);
        }

        if (!total) {
          return;
        }
        // this is a GA category with multiple seats selected
        debug(`GA cat ${this.ticket.categoryName}`);
        this.maxSeatsSelected = total;
      }
    } finally {
      this.loading = false;
    }
  }

  /**
   * Indicates whether we are dealing with a custom customers booking.
   */
  get isCustomCustomerBooking() {
    return !!this.customCustomers.length;
  }

  /**
   * Previously we used to get membershipCustomers from NBookingModule.membershipCustomers
   * As we can have now custom customers, we just add a getter here to take it in consideration if present
   * Otherwise we return NBookingModule.membershipCustomers to keep old logic working
   */
  get membershipCustomers() {
    if (this.customCustomers.length > 0) {
      return this.customCustomers;
    }
    return NBookingModule.membershipCustomers || [];
  }

  // Pricing data holds information about each ticket type, this way
  // when you select a ticket, seats.io will send that information
  // back so that you know which ticket is selected on seats.io
  get pricingData(): ISeatPrice[] {
    debug1('hi from pricingDate getter');
    const pricingData: ISeatPrice[] = [];
    // Build our pricing data array

    const seatingSubProducts = this.seatingTickets.filter((item: any) => {
      return item.price && item.price.length;
    });
    seatingSubProducts.forEach((seatTicket) => {
      // debug(seatTicket);
      const ticketTypeArr: ISeatTicketType[] = [];
      const seatsCat: string = seatTicket.seatsCat || '';

      if (!seatTicket) {
        return;
      }

      // Filter seat ticket prices if we had supply priceIdsToDisplay
      let seatPrices = this.priceIdsToDisplay.length >  0 ?
        seatTicket.price.filter((t) => this.priceIdsToDisplay.includes(t.priceId)) : seatTicket.price;

      // Hide certain prices for CO2 if membership type 1 || 2
      const { membership } = NBookingModule;
      const orgId = OrganizerModule.id;
      const pricesToHide = ['Abon. soutien', 'AG Culturel', 'Carte Culture PT',
        'Carte Culture TR', 'Comédien', 'Invité',
        'PassBienvenue', 'Abon. découverte PT', 'Abon. découverte TR'];
      if (membership && isRealMembershipDiscount(membership) && orgId && orgId === 18875) {
        seatPrices = seatPrices.filter((price) => !pricesToHide.includes(price.priceName));
      }

      /*
        Hide certain prices for CO2 if it is not a membership
        These prices are displayed only in membership bookings
      */
      const pricesToHideMem = ['Abon. plein tarif', 'Abon. tarif AVS'];
      if (!membership && orgId && orgId === 18875) {
        seatPrices = seatPrices.filter((price) => !pricesToHideMem.includes(price.priceName));
      }

      if (!seatPrices || !seatPrices.length) {
        debug(`Seating subproduct has no price`);
        return;
      }

      // This is the info that will be sent seats.io whenever a seat
      // is selected or deselected. Feel free to add any info that you
      // need later when a seat is selected to do different stuff.
      // This info can be seen on onHold and onRelease methods below.
      seatPrices.forEach((priceType) => {
        const ePrice = this.exportPrice(priceType);
        const currentPriceType: ISeatTicketType = {
          ticketType: priceType.priceId.toString(),
          price: priceType.validComputedPriceId && ePrice ? ePrice.priceValue : Number(priceType.priceValue),
          priceId: priceType.priceId,
          priceName: priceType.priceName,
          currency: (ProductModule.productCurrency) as string,
          category: seatTicket.categoryName,
          priceCategory: priceType.priceCategory,
          // TODO: Riyadh, for now I am showing categoryName, because there are no category.seatsCat in category info.
          seatsCat,
          categoryId: seatTicket.categoryId,
          label: priceType.priceDescription ?
                // TODO: Put description between () until seatsio solve their bug
                //  priceType.priceName + ' <br/>' + priceType.priceDescription
                 (priceType.priceName + ' (' + priceType.priceDescription + ')')
                 :
                 priceType.priceName,
          // the following 2 fields are needed because we are now
          // supporting multi-slot (multiple dates) selection. So we can have multiple
          // charts trying to select the same seat on different dates.
          // TODO: Riyadh, for now I am hide startDateTime and endDateTime,
          // because there are no info about it in category.
          // If it needs - pls, add it from slot or ask backend to add this field to ticket
          // startDateTime: category.startDateTime,
          // endDateTime: category.endDateTime,
        };

        // // The following tells seats.io the max number of tickets that could be selected
        // // For now, we will enable it only for Fribourg Olympic, then we will generalize it.
        // // Further tests must be done on Moods, CPO and other seating plan heavy users to make
        // // sure that the integration is not broken. This has caused issues with Moods before!!

        // maxSelectedTickets.push({
        //   ticketType: currentPriceType.ticketType,
        //   //  Just in case the backend doesn't set a max quantity, force it to 20
        //   // TODO: Remove next comment once BUD-8305 is tested
        //   //  quantity: ReferrerModule.isMembershipOnly ? customers.length : (priceType.maxBooking || 20),
        //   // max quantity is based on customers only for memebership booking
        //   quantity: NBookingModule.isMembershipBooking ? customers.length : (priceType.maxBooking || 20),
        // });
        ticketTypeArr.push(currentPriceType);
      });

      // Only the following tickets are shown
      // 1) have type = 31
      // 2) and code = null or code is present in categoriesFilter
      // if (seatTicket.type === 31) {
      //   // if (!category.code) {
      //     // || categoriesFilter.includes(String(category.code))
      //     if (seatTicket.categoryName) {
      //       availableCategories.push(seatsCat);
      //     }
      //   // }
      // }

      // On the chart, we use category.seatsCat as the name of the
      // seats category. This way, we can change the name on the chart
      // without having to change the name of the category.
      // TODO: Riyadh, for now I am showing categoryName, because there are no category.seatsCat in category info.
      const currentPrice = {
          category: seatsCat,
          ticketTypes: ticketTypeArr,
      };

      pricingData.push(currentPrice);
    });

    debug1('pricingData before filtering', pricingData);
    // If we have membership customers and everyone of them has already booked at least one ticket
    // we return only those prices which correspond to what have been selected
    if (NBookingModule.isMembershipBooking) {
      debug1('this is a membershipBoking');
      debug1('this.ticket', this.ticket);

      // Lets check if all customers have booked at least one ticket
      const customers = this.membershipCustomers;
      debug1('NBookingModule.membershipCustomers', this.membershipCustomers);
      const didAllCustomersBook = customers.every((c) => c.tickets && c.tickets.length > 0);

      if (customers.length > 0 && didAllCustomersBook) {
        debug1('all customers have booked a ticket');
        debug1('going to build bookedPrices array');
        // 1- Lets build an array of prices already booked
        // const bookedPrices:  {priceName: string, priceId: number}[] = [];
        // const pricesIds: number[] = Array.prototype.concat.apply([],
        // customers.map((c) => c.tickets.map((t) => Number(t.priceId))));
        // debug1('booked pricesIds', pricesIds);

        let categories: IServerRecapCategory[] = [];
        const booking = NBookingModule.bookingRes;
        if (booking && booking.bookingRecap && booking.bookingRecap.categories) {
          categories = booking.bookingRecap.categories;
        }
        const bookedPriceCategories = customersPriceCateogries(customers).concat(PriceCategory.Other);
        const customerWithOnlyTypeOther = getCustomerWithOnlyOtherTickets(customers);
        debug('booked price categories', bookedPriceCategories);
        const bookedPrices: Array<{priceName: string, priceId: number}> =
        categories.map((c) => {
         return {priceName: c.priceName, priceId: Number(c.priceId)};
        });
        debug1('bookedPrices', bookedPrices);

        debug1('going to filter prices that havent been booked');
        // 2- Lets now filter prices that haven't been previously selected, we should remove them from pricingData
        // Old equilibre logic based on price name
        // for (const price of pricingData) {
        //   const tTypes: ISeatTicketType[] = (price.ticketTypes) as ISeatTicketType[];
        //   price.ticketTypes = tTypes.filter((t) =>
        //   t.priceName && bookedPrices.map((b) => b.priceName).includes(t.priceName));
        // }

        // Filter only if we don't have a customer that can select everything
        if (!customerWithOnlyTypeOther) {
          for (const price of pricingData) {
            const tTypes: ISeatTicketType[] = (price.ticketTypes) as ISeatTicketType[];
            price.ticketTypes = tTypes.filter((t) =>
            t.priceCategory && bookedPriceCategories.includes(t.priceCategory));
          }
          debug1('ok here is prices that have been booked and we will display them to user', pricingData);

          // 3- If only 1 customer hasn't bought from this show, we will keep only the price he's booking from
          debug1('going to check now whether there is only 1 customer without this ticket or not');
          debug1('customers', customers);
        }


        const customersWithoutCurrentTicket = customers.filter((c) =>
        c.tickets.every((t) => t.categoryId !== this.ticket.categoryId));
        const remainingCustomerWithOnlyTypeOther = getCustomerWithOnlyOtherTickets(customersWithoutCurrentTicket);


        // const customersWithoutCurrentTicket: IMembershipCustomer[] = [];
        // for(const cust of customers) {
        //   debug1('cust.tickets', cust.tickets);
        //   // if (cust.tickets.filter((t) => t.categoryId === this.ticket.categoryId).length === 0) {
        //   //   customersWithoutCurrentTicket.push(cust);
        //   // }
        //   let isWith = false;
        //   for(const ticket of cust.tickets) {
        //     if (ticket.categoryId === this.ticket.categoryId) {
        //       isWith = true;
        //     }
        //   }
        //   if (isWith === false) {
        //     debug1('cust isnot With', cust);
        //     customersWithoutCurrentTicket.push(cust);
        //   } else {
        //     debug1('cust isWith', cust);
        //   }
        // }


        debug1('this.ticket.categoryId', this.ticket.categoryId);
        debug1('customersWithoutCurrentTicket', customersWithoutCurrentTicket);


        // Now lets check if the remaining customers for this ticket have all one price only and not type other
        // so that we show them their price only
        // if (customersWithoutCurrentTicket && customersWithoutCurrentTicket.length > 1) { // equilibre old logic
        if (
          customersWithoutCurrentTicket &&
          customersWithoutCurrentTicket.length &&
          !remainingCustomerWithOnlyTypeOther
        ) {
          // going to extract an array of priceNames out of an array of customers
          // then we will compare whether these priceNames are actually the same or not
          // const remainingPriceNames = customersWithoutCurrentTicket.map((c) =>
          // c.tickets).map((tickets) => tickets.map((t) => t.priceName));
          // const flatRemainingPrices = Array.prototype.concat.apply([], remainingPriceNames);
          // // If the first price is identical to all prices then all prices are identical
          // const arePricesIdentical = flatRemainingPrices.every((p) => p === flatRemainingPrices[0]);
          // if (arePricesIdentical) {
          //   // We will show remaining customers this one price only
          //   for (const price of pricingData) {
          //     const ticketTypes: ISeatTicketType[] = (price.ticketTypes) as ISeatTicketType[];
          //     price.ticketTypes = ticketTypes.filter((tType) => tType.priceName
          //     && tType.priceName === flatRemainingPrices[0]);
          //   }
          // }

          // Allow remaining customers to select only price categories that they previously selected
          // or other
          const allowedPriceCategories =
            customersPriceCateogries(customersWithoutCurrentTicket).concat(PriceCategory.Other);
          debug('Allowed price categories for remaining customers', allowedPriceCategories);
          for (const price of pricingData) {
            const ticketTypes: ISeatTicketType[] = (price.ticketTypes) as ISeatTicketType[];
            price.ticketTypes = ticketTypes.filter((tType) => tType.priceCategory
            && allowedPriceCategories.includes(tType.priceCategory));
          }
        }

        // Now lets check if there is still only 1 customer so that we show him his price only
        // avoid filtering prices when the chart isn't rendered,
        // otherwise we might loose selected seats of prices that we will filter in here
        // if (customersWithoutCurrentTicket && customersWithoutCurrentTicket.length === 1 && !this.chartRendering) {
        //   debug1('remains 1 customer did not buy from this show');
        //   debug1('we will show him his price only');
        //   for (const price of pricingData) {
        //     const ticketTypes: ISeatTicketType[] = (price.ticketTypes) as ISeatTicketType[];
        //     price.ticketTypes = ticketTypes.filter((tType) => tType.priceName
        //     && customersWithoutCurrentTicket[0].tickets.map((customerTicket) =>
        //     customerTicket.priceName).includes(tType.priceName));
        //   }
        //   debug1('ok here is prices of the last customer', pricingData);
        // }

        debug1('filtered pricingData', pricingData);
      }
    }

    return pricingData;
  }

  @Watch('finalSelectedSlot', {immediate: true})
  private onSlotsChanged(val: ITimeslotsEntity[], oldVal: ITimeslotsEntity[]) {
    if (oldVal && arraysEqual(val, oldVal)) {
      return;
    }
    if (this.finalSelectedSlot && this.finalSelectedSlot.length > 0 && this.finalSelectedSlot[0].eventKey) {
      this.eventKey = [this.finalSelectedSlot[0].eventKey];
      this.isChartPresent = false;
    }
    if (isCalendarTicket(this.ticket) && this.finalSelectedSlot.length === 1) {
      this.renderChart();
    }

  }



  private renderChart() {

    const { status } = this.$route.params;
    const mainTicketId = this.ticket.categoryId;

    // For memebership bookings
    // don't render the chart if ticket group is not part of the current memebership
    const membership = NBookingModule.membership;
    // TODO: remove next comment if new membership logic is tested
    // if (ReferrerModule.isMembershipOnly && membership && membership.ticketGroup) {
    if (membership && membership.ticketGroup) {
      let groups = this.ticket.ticketGroups.map((g) => g.id);
      const subProducts = this.ticket.seatingSubProducts;

      // check seating seatingSubProducts groups
      if (subProducts && subProducts.length) {
        for (const seatCat of subProducts) {
          if (seatCat.ticketGroups && seatCat.ticketGroups.length) {
            groups = groups.concat(seatCat.ticketGroups.map((g) => g.id));
          }
        }
      }

      debug('Confirming that membership is included in', groups);
      if (!groups.includes(membership.ticketGroup.id) && membership.type !== MembershipType.RequiredTicketsMembership) {
        // alert('not included');
        enableQuasarStyling();
        this.$q.dialog({
          title: this.$t('common.warning') as string,
          message: (this.$t('membership.cant-include') as string).replace('xx', membership.name),
          persistent: true,
        }).onOk(() => {
          disableQuasarStyling();
        });
        return;
      }
    }

    // We will apply a ma selection of 1 seat for the Categories mentioned inside categoriesWithOneSeatMaxSelection
    // Or we apply max selection equal to number of customers for membership based bookings
    // TODO: remove next comment once BUD-8305 is tested.
    // const applyMaxSelection = ReferrerModule.isMembershipOnly ||
    const applyMaxSelection = true;
    // NBookingModule.isCustomerBooking || this.categoriesWithOneSeatMaxSelection.includes(mainTicketId);
    const customers = this.membershipCustomers;

    // Avoid rendering if no seats are present
    const seatingSubProducts = this.seatingTickets.filter((item: any) => {
      return item.price && item.price.length;
    });
    if (!seatingSubProducts || !seatingSubProducts.length) {
      debug('Not a seating ticket, skipping');
      return;
    }

    this.chartRendering = true;
    this.isChartPresent = true;
    // Pricing data holds information about each ticket type, this way
    // when you select a ticket, seats.io will send that information
    // back so that you know which ticket is selected on seats.io
    const pricingData: any = [];

    // which categories to show on seats.io Chart
    const availableCategories: string[] = [];
    // array with available counts for every ticket
    const maxSelectedTickets: any = [];

    // The following filter allows us to see only certain tickets
    // Note, tickets with category.code === null are always visible
    // TODO: Riyadh, resolve hidden with link categories for seating categories.
    // Also uncomment and add row 163 to if on the top
    // let categoriesFilter: any = [];
    // const cats: string = this.$route.query.cats as string;
    // if (cats) {
    //   categoriesFilter = cats.split(',');
    // }

    // Build our pricing data array
    seatingSubProducts.forEach((seatTicket) => {
      // debug(seatTicket);
      const ticketTypeArr: any = [];
      const seatsCat: string = seatTicket.seatsCat || '';

      if (!seatTicket) {
        return;
      }

      const seatPrices = seatTicket.price;

      if (!seatPrices || !seatPrices.length) {
        debug(`Seating subproduct has no price`);
        return;
      }

      // This is the info that will be sent seats.io whenever a seat
      // is selected or deselected. Feel free to add any info that you
      // need later when a seat is selected to do different stuff.
      // This info can be seen on onHold and onRelease methods below.
      seatPrices.forEach((priceType) => {
        const ePrice = this.exportPrice(priceType);
        const currentPriceType: ISeatTicketType = {
          ticketType: priceType.priceId.toString(),
          price: priceType.validComputedPriceId && ePrice ? ePrice.priceValue : Number(priceType.priceValue),
          priceId: priceType.priceId,
          currency: (ProductModule.productCurrency) as string,
          category: seatTicket.categoryName,
          priceCategory: priceType.priceCategory,
          // TODO: Riyadh, for now I am showing categoryName, because there are no category.seatsCat in category info.
          seatsCat,
          categoryId: seatTicket.categoryId,
          label: priceType.priceDescription ?
                // TODO: Put description between () until seatsio solve their bug
                //  priceType.priceName + ' <br/>' + priceType.priceDescription
                 (priceType.priceName + ' (' + priceType.priceDescription + ')')
                 :
                 priceType.priceName,
          // the following 2 fields are needed because we are now
          // supporting multi-slot (multiple dates) selection. So we can have multiple
          // charts trying to select the same seat on different dates.
          // TODO: Riyadh, for now I am hide startDateTime and endDateTime,
          // because there are no info about it in category.
          // If it needs - pls, add it from slot or ask backend to add this field to ticket
          // startDateTime: category.startDateTime,
          // endDateTime: category.endDateTime,
        };

        // The following tells seats.io the max number of tickets that could be selected
        // For now, we will enable it only for Fribourg Olympic, then we will generalize it.
        // Further tests must be done on Moods, CPO and other seating plan heavy users to make
        // sure that the integration is not broken. This has caused issues with Moods before!!

        maxSelectedTickets.push({
          ticketType: currentPriceType.ticketType,
          //  Just in case the backend doesn't set a max quantity, force it to 20
          // TODO: Remove next comment once BUD-8305 is tested
          //  quantity: ReferrerModule.isMembershipOnly ? customers.length : (priceType.maxBooking || 20),
          // max quantity is based on customers only for memebership booking
          quantity: NBookingModule.isMembershipBooking ? customers.length : (priceType.maxBooking || 20),
        });
        ticketTypeArr.push(currentPriceType);
      });

      // Order prices based on their sortPrice value
      seatPrices.sort((a, b) => {
        let comparaison = 0;
        if (a.sortPrice !== undefined && b.sortPrice !== undefined) {
          comparaison = a.sortPrice - b.sortPrice;
        } else if (!a.sortPrice) {
          comparaison = 1;
        } else if (!b.sortPrice) {
          comparaison = -1;
        }

        // if two prices have the same sortPrice we'll order them alphabetically
        if (comparaison === 0) {
          comparaison = a.priceName[0] === b.priceName[0] ? 0 : a.priceName[0] < b.priceName[0] ? -1 : 1;
        }
        return comparaison;
      });

      // Only the following tickets are shown
      // 1) have type = 31
      // 2) and code = null or code is present in categoriesFilter
      // 3) if categoryIdstoDisplay is specified we make sure that ticket category is included
      if (seatTicket.type === 31 &&
        (this.categoryIdsToDisplay.length === 0 ||
        (this.categoryIdsToDisplay.length > 0 && this.categoryIdsToDisplay.includes(seatTicket.categoryId)))) {
        // if (!category.code) {
          // || categoriesFilter.includes(String(category.code))
          if (seatTicket.categoryName) {
            availableCategories.push(seatsCat);
          }
        // }
      }

      // On the chart, we use category.seatsCat as the name of the
      // seats category. This way, we can change the name on the chart
      // without having to change the name of the category.
      // TODO: Riyadh, for now I am showing categoryName, because there are no category.seatsCat in category info.
      const currentPrice = {
          category: seatsCat,
          ticketTypes: ticketTypeArr,
      };

      pricingData.push(currentPrice);
    });

    // only to ticket Playoffs Abonnement && membership bookings
    // Here we are making sure that the user can select only 1 ticket
    // or the number of customers for membership only
    if (applyMaxSelection) {
      // TODO: Remove next comment once BUD-8305 is tested
      // if (ReferrerModule.isMembershipOnly) {
      if (NBookingModule.isMembershipBooking) {
        (maxSelectedTickets as any[]).push({total: customers.length});
      } else if (this.categoriesWithOneSeatMaxSelection.includes(mainTicketId)) {
        // These are client requests that want 1 max seat to be selected
        (maxSelectedTickets as any[]).push({total: 1});
      } else if (this.ticket.isAddonInSeparateStep && this.ticket.maxAddonQuantity) {
        (maxSelectedTickets as any[]).push({total: this.ticket.maxAddonQuantity});
      } else if (this.customCustomers.length === 1 && this.maxTicketValidation > 0) {
        // We apply maxValidation if it is higher than zero (MAx value set) and we have one custom Customer
        (maxSelectedTickets as any[]).push({total: this.maxTicketValidation});
      } else if (ProductModule.requiredNumberPiazzaSeats > 0) {
        // We set as max, the max seats of the current show only
        if (ProductModule.requiredNumberPiazzaSeatsPerCategory &&
          ProductModule.requiredNumberPiazzaSeatsPerCategory.length > 0) {
          const categoryMaxSeats =
            ProductModule.requiredNumberPiazzaSeatsPerCategory.find((cat:
            {categoryId: number; maxSeats: number; }) =>
              cat.categoryId === this.ticket.categoryId);
          if (categoryMaxSeats) {
            (maxSelectedTickets as any[]).push({total: categoryMaxSeats.maxSeats});
          }
        } else {
          (maxSelectedTickets as any[]).push({total: ProductModule.requiredNumberPiazzaSeats});
        }
      }
    }


    debug1(`pricing data is`, pricingData);
    debug1(`this.pricing data is`, this.pricingData);
    debug('Available categories', availableCategories);
    debug('Max selected tickets are', maxSelectedTickets);
    // charts with holdOnSelect, will hold a seat for a given number
    // of minutes, this way, no 2 users can select the same chart at
    // the same time. But, when the chart is destroyed and regenerated,
    // we will not be able to release or modify tickets that were already selected.
    // So, we store the hold token on the category, so that we make things easier to
    // manipulate.
    // By default, Categories don't have a hold token attached to them, this is
    // just a conveniece that we use on the frontend, to not loose track of already rendered charts.
    this.availableCategories = availableCategories;
    const holdToken: string = (this.ticket as any).holdToken;
    const maxSelectionReachedWithNumber: string =
    // TODO: Remove next comment when BUD-8305 is tested
    // ReferrerModule.isMembershipOnly
    NBookingModule.isMembershipBooking
    ? this.$t('chart-config-max-selection-reached-with-number-for-membership') as string
    : this.$t('chart-config-max-selection-reached-with-number') as string;

    const chartConfig: any = {
      divId: this.chartId,
      publicKey: this.ticket.publicKey,
      events: this.eventKey,
      // Always show seating plans (currently only shown on mobile when the user click)
      // https://docs.seats.io/docs/renderer/config-showsectioncontents/
      showSectionContents: 'always',
      // holdOnSelect: true,
      // if status is set, it means that we already have a booking, so we continue
      // using old hold token
      session: NBookingModule.bookingRes ? 'continue' : 'start',
      holdOnSelectForGAs: true,
      pricing: this.pricingData,
      // availableCategories: [],
      availableCategories,
      filteredCategories: availableCategories,
      priceFormatter: (price: number) => {
        if (this.shows &&
          this.shows.length > 0 &&
          this.showsPrices[this.ticket.categoryId] &&
          this.showsPrices[this.ticket.categoryId].length > 0) {
            return  this.showsPrices[this.ticket.categoryId][0].displayedValue === 0 ?
              this.$t('booking-flow.label-free') : price > 0 ? price + ' ' + ProductModule.productCurrency :
              this.$t('booking-flow.label-free');
        }
        const piazzaGrandeProductIds = [37871, 63337, 63100, 64621];

        const hasPiazzaGrandeProduct = piazzaGrandeProductIds.includes(this.ticket.productId);

        return hasPiazzaGrandeProduct ? this.$t('booking-flow.label-free') :
          price > 0 ? price + ' ' + ProductModule.productCurrency : this.$t('booking-flow.label-free');
      },
      onChartRendered: this.onChartRendered,
      onHoldSucceeded: this.onHold,
      onReleaseHoldSucceeded: this.onRelease,
      onObjectSelected: this.onObjectSelected/*this.showQuasarSpinner*/,
      onObjectDeselected: this.onObjectDeselected,
      onObjectClicked: this.onObjectClicked,
      onSelectionInvalid: this.onSelectionInvalid,
      onSelectionValid: this.onSelectionValid,
      language: this.$i18n.locale,
      maxSelectedObjects: applyMaxSelection ? maxSelectedTickets : undefined,
      messages: {
        // overriding 1 seats io msg with ours
        maxSelectionReachedWithNumber,
      },
      selectionValidators: this.activateNoOrphanSeatsValidation ?
        [{type: 'noOrphanSeats', mode: 'lenient'}] : undefined,
    };

    // If this chart was already opened, use the holdToken to see
    // already selected seats.
    // if (holdToken) {
    //   chartConfig.holdToken = holdToken;
    // }
    // } else {
    //   chartConfig.regenerateHoldToken = true;
    // }
    if (this.seatsio) {
      this.chart = new this.seatsio.SeatingChart(chartConfig);
    }

    // Now lets store the chartId into the state
    // but first lets check if there is already rendered chart in the state
    // if there is one we should destroy it
    if (NBookingModule.renderedChartRef !== null) {
      // this.destroyChart(NBookingModule.renderedChartRef);
      // this.seatsio.charts[0].destroy();
      destroyCharts(true);
    }
    NBookingModule.setRenderedChartRef(this.chartId);

    this.chart.render();
    const holdTime = (ProductModule.product as IProduct).holdingTime;
    const remainingSeconds = holdTime ? Number(holdTime) * 60 : 600;
    const expirationDate = dayjs().add(remainingSeconds, 's').toISOString();

    NBookingModule.startTimer(remainingSeconds);
    if (!NBookingModule.timerExpirationDate) {
      NBookingModule.setTimerExpirationDate(expirationDate);
    }
    this.chartRendering = false;
  }

  private destroy() {
    // We need to destroy currently opened chart and remove its reference from the state
    this.seatsio.charts[0].destroy();
    NBookingModule.setRenderedChartRef(null);
  }

  private onSelectionInvalid() {
    if (this.didUserClick && this.activateNoOrphanSeatsValidation) {
      NBookingModule.setSeatSelectionValidators({value: this.ticket.categoryId, remove: false});
    }
  }

  private onSelectionValid() {
    if (this.didUserClick && this.activateNoOrphanSeatsValidation) {
      NBookingModule.setSeatSelectionValidators({value: this.ticket.categoryId, remove: true});
    }
  }

  private onObjectDeselected() {
    if (this.didUserClick) {
      this.showQuasarSpinner();
    }
  }

  private onObjectSelected(object: any) {
    this.showQuasarSpinner();
  }

  private updateChartConfig() {
    debug1('hi from updateChartConfig');
    const pricingData = this.pricingData;
    // We keep a copy of availableCategories
    let newAvailableCategories = this.availableCategories;
    const newPricing = [];
    const membershipDiscount = NBookingModule.membership;
    const chart = this.chart;
    const selectedObjects = (chart && chart.selectedObjects) || [];
    const numberOfSelectedSeats = selectedObjects.length;
    const customers = this.membershipCustomers;
    const customersCount = customers.length;

    // Ensure for family memberships that at least 1 adult and 1 child is selected
    // when all seats are selected.
    // When 1 seat is remaining, force the user to pick the missing adult or child.
    if (chart && membershipDiscount && isFamilyMembership(membershipDiscount)) {
      // Only when 1 seat to be selected is remaining on this chart
      // & all customers haven't already booked tickets because otherwise they will already
      // be forced to book the same price that they previously selected ;)
      if (((customersCount - numberOfSelectedSeats) === 1) && !hadAllCustomersBooked(customers)) {

        // check all the prices already bought for this current show(ticket);
        const ticketsBought: ICustomerTickets[] = [];
        for (const cust of customers) {
          for (const t of cust.tickets) {
            if (t.categoryId === this.ticket.categoryId) {
              ticketsBought.push(t);
              break;
            }
          }
        }

        // TODO: Use price configuration to know if it's adult or child
        // const adultArray = ['Plein tarif', 'Tarif réduit'];
        // Only if child and adult weren't already selected
        const didCustomersByAdults =
          !!ticketsBought.find((t) => isNonChildPriceName(t.priceName));
          // !!ticketsBought.find((t) => isNonChildPriceCategory(t.priceCategory));
        const didCustomersByChild =
          !!ticketsBought.find((t) => isChildPriceName(t.priceName));
          // !!ticketsBought.find((t) => isChildPriceCategory(t.priceCategory));

        // Reconfigure only if clients didn't already buy child & adult tickets
        if (!(didCustomersByAdults && didCustomersByChild)) {

          // Force customers to buy child cause they bought only adults
          if (didCustomersByAdults) {
            debug('Forcing customer to buy child');
            // build new pricing and reconfigure chart
            for (const dataPrice of pricingData) {
              const seatPrice: ISeatPrice = {
                category: dataPrice.category,
                ticketTypes: // isEquilibre(OrganizerModule.id || 0) ?
                  // Detect child prices by name only for equilibre. TODO change in season 3
                  // dataPrice.ticketTypes.filter((seatType) => isChildPriceName(seatType.priceName || '')) :
                  // .filter((seatType) => childArray.includes(seatType.priceName || '')),
                  dataPrice.ticketTypes.filter((seatType) => isChildPriceCategory(seatType.priceCategory)),
              };

              if (seatPrice.ticketTypes.length) {
                newPricing.push(seatPrice);
              }
            }

            chart.changeConfig({pricing: newPricing});
            return;

          } else {
            // Forcing customers to buy adults
            debug('Forcing customers to buy adults');
            // build new pricing and reconfigure chart
            for (const dataPrice of pricingData) {
              const seatPrice: ISeatPrice = {
                category: dataPrice.category,
                ticketTypes: // isEquilibre(OrganizerModule.id || 0) ?
                  // Detect child prices by name only for equilibre. TODO change in season 3
                  // dataPrice.ticketTypes.filter((seatType) => isNonChildPriceName(seatType.priceName || '')) :
                  // .filter((seatType) => adultArray.includes(seatType.priceName || '')),
                  dataPrice.ticketTypes.filter((seatType) => isNonChildPriceCategory(seatType.priceCategory)),
              };

              if (seatPrice.ticketTypes.length) {
                newPricing.push(seatPrice);
              }
            }

            chart.changeConfig({pricing: newPricing});
            return;
          }
        }
      }
    }
    const categoriesToHide: string[] = [];
    this.pricingData.forEach((price) => {
      // If a category has no ticketTypes we hide the category
      if (price.ticketTypes.length === 0) {
        categoriesToHide.push(price.category);
      }
    });

    if (categoriesToHide.length > 0) {
      newAvailableCategories = this.availableCategories.filter((t) => !categoriesToHide.includes(t));
    }

    /*
     * newAvailableCategories has the original availableCategories array
     * it is filtred only when we have categoriesToHide
     * otherwise we keep the original value of availableCategories
     */
    this.chart.changeConfig({availableCategories: newAvailableCategories,
      filteredCategories: newAvailableCategories});

    this.chart.changeConfig({pricing: this.pricingData});
  }

  private onObjectClicked() {
    this.didUserClick = true;
    // this.startUpdateChartConfig = true;
  }

  // show quasar spinner when is selected/unselected successfully
  // don't show it if the seat is selected/unselected by backend
  private showQuasarSpinner() {
    const {membership} = NBookingModule;
    // TODO
    // if (membership && this.canShowLoading && this.didUserClick) {
    if ((NBookingModule.isCustomerBooking || this.isCustomCustomerBooking) &&
      this.canShowLoading &&
      this.didUserClick) {
      enableQuasarStyling();
      Loading.show();
    }
  }

  // Here, we are going to receive the selected (held) seats, once the user
  // clicks on a seat in the chart. The ticketTypes array, contains the info
  // that we already supplied in the renderChart method above.
  // Note: seats.io will send an array of seats. each seat corresponds to the
  // same index on objects and ticketTypes array.
  private async onHold(objects: any, ticketTypes: ISeatTicketType[]) {
    // handle seats one at a time.
    let count = 1;
    debug(`Seat held`, objects);
    debug(`ticketTypes`, ticketTypes);
    const seatObject = objects[0];
    if (seatObject && seatObject.objectType === SeatType.GeneralAdmission) {
      count = seatObject.numSelected;
      // The previous is wrong for GeneralAdmission like in Equilibre
      // if you select/deselect 1 spot, seats.io send us the total selected
      // as numSelected. We want only the ticket that was deselected
      if (ticketTypes.length === 1) {
        count = seatObject.selectionPerTicketType[ticketTypes[0].ticketType];
      }
      debug('General Admission seat with quantity', count);
    }

    // Lets add this price id with its seatType to the state
    // Why here when holding a seat?
    // Because I did not find where else we can get the seatType.
    // It is available when chart rendered in response.subChart.generalAdmissionsArea[]
    // But the documentation did not consider it as a proof of seatType
    // it considered object.objectType ('Seat', 'GeneralAdmissionArea', 'Booth', 'Table')
    NBookingModule.setSeatPricesTypes({priceId: ticketTypes[0].priceId, seatType: seatObject.objectType});

    this.addSeats(objects, ticketTypes, count);
    this.didUserClick = false;

    // object that has the data we send for smeetz tracking
    // this data will be used for dynamic pricing.
    const smtzTrackingData = {
      catId: ticketTypes[0].categoryId,
      pId: this.ticket.productId,
      prId: ticketTypes[0].priceId,
      prVal: ticketTypes[0].price,
      qt: count,
    };
    smeetzTracker(EventType.ticketAdded, smtzTrackingData);
  }

  // This method is called whenever a seat is released (un held).
  private onRelease(objects: any, ticketTypes: any[]) {
    debug(`Seat released`, objects);

    let count = 0;
    debug(`ticketTypes`, ticketTypes);
    const seatObject = objects[0];
    if (seatObject && seatObject.objectType === SeatType.GeneralAdmission) {
      count = seatObject.numSelected;
      // The previous is wrong for GeneralAdmission like in Equilibre
      // if you select/deselect 1 spot, seats.io send us the total selected
      // as numSelected. We want only the ticket that was deselected
      if (ticketTypes.length === 1) {
        count = seatObject.selectionPerTicketType[ticketTypes[0].ticketType];
      }
      debug('General Admission seat with quantity', count);
    }
    this.addSeats(objects, ticketTypes, count, true);
    this.didUserClick = false;
    // object that has the data we send for smeetz tracking
    // this data will be used for dynamic pricing.
    const smtzTrackingData = {
      catId: ticketTypes[0].categoryId,
      pId: this.ticket.productId,
      prId: ticketTypes[0].priceId,
      prVal: ticketTypes[0].price,
      qt: 1,
    };
    smeetzTracker(EventType.ticketRemoved, smtzTrackingData);

    // for (const type of ticketTypes) {
    //   const payload = {
    //     price: {
    //       priceId: type.priceId,
    //     },
    //     count: 1,
    //     slot: this.slots,
    //     ticket: this.ticket,
    //     // TODO: Riyadh, I am prepared seat id, but don't know - where to send it and is it neccessary or no.
    //     // seat: objects[i].id,
    //   };
    //   // TODO: add remove functionality
    // }
  }

  private async addSeats(objects: any, ticketTypes: ISeatTicketType[], count: number, isReleasing = false) {

    const isGeneralAdmission = objects[0] && objects[0].objectType === SeatType.GeneralAdmission;
    let i = 0;
    for (const type of ticketTypes) {

      // get price
      const seatTicket = this.seatingTickets.find((ticket) => {
        return ticket.categoryId === type.categoryId;
      });
      if (!seatTicket) {
        debug('Seat ticket not found');
        return;
      }
      const price = seatTicket.price && seatTicket.price.find((p) => {
        return p.priceId === type.priceId;
      });
      if (!price) {
        debug('Price not found');
        return;
      }

      if (this.finalSelectedSlot.length <= 0) {
        debug('No slots in this seat ticket type');
        return;
      }
      const ticketLabels = objects[i].labels;
      // TODO: need to check for data w/o row(with only 2 parameters) and with additional label (like for floor)
      // tslint:disable-next-line:max-line-length
      let currentLabel = '';
      if ( ticketLabels.section ) {
        currentLabel = ticketLabels.section + ',';
      }
      if ( ticketLabels.parent ) {
         currentLabel = currentLabel + ' row ' + ticketLabels.parent + ',';
      }
      if ( ticketLabels.own ) {
        currentLabel = currentLabel + ' seat ' + ticketLabels.own;
      }

      const seat = objects[i].id;
      const payload: IPriceCount = {
        price: {...price},
        count,
        slot: this.finalSelectedSlot[0],
        ticket: this.ticket,
        // TODO: Riyadh, I am prepared seat id, but don't know - where to send it and is it neccessary or no.
        seat,
        holdToken: this.chart.holdToken,
        seatCategoryId: type.categoryId,
      };
      i++;

      const ePrice = this.exportPrice(price);

      if ( ePrice && ePrice.computedPriceId) {
        payload.price.priceValue = ePrice.priceValue;
      }

      const customers = this.membershipCustomers;

      // For General admission tickets, we should remove all the tickets and add again
      const previousMaxSelected = this.maxSeatsSelected;
      this.maxSeatsSelected = count;
      let isDeselecting = false;

      // // Get the quantity that has already been selected for this category
      // if (isGeneralAdmission) {

      // }
      // In case we are deselecting, just make sure that we remove the user that is no
      // longer selected
      if (count && (count < previousMaxSelected)) {
        // clear all the customers related to this categoryId and do it again
        debug('Deselecing seats');
        isDeselecting = true;
      }

      const callback = (error: any) => {
        if (error) {
          debug('Error while adding seat');
          if (Loading.isActive) {
            Loading.hide();
            disableQuasarStyling();
          }
          NNoty.createNewNoty({
            period: 4000,
            message: String(this.$t('error.error-response-banner')),
            type: 'error',
          });
          throw error;
        }

        // display package prices if they exist
        this.displayAddons(payload);
        // if (count && !isDeselecting) {
        if (count && !isReleasing) {
          this.addToMembership(payload);
        // } else if (customers.length && seat && ReferrerModule.isMembershipOnly && !isDeselecting) {
        // TODO Remove next comment when BUD-8305 is tested
        // } else if (customers.length && seat && ReferrerModule.isMembershipOnly && !count && !isGeneralAdmission) {
        } else if (customers.length &&
          seat &&
          (NBookingModule.isCustomerBooking || this.isCustomCustomerBooking) &&
          !count &&
          !isGeneralAdmission) {
          // Here we are removing a seat, so make sure we remove it from the customer tickets

          const customer = customers.find((cust) => {
            const tickets = cust.tickets;
            return tickets.find((t) => {
              return t.categoryId === this.ticket.categoryId && t.seat === seat;
            });
          });

          if (!customer) {
            throw new Error('Can\'t find a customer who already selected this ticket');
          }

          const deleted = customer.tickets.splice(customer.tickets.findIndex((t) => {
            return t.categoryId === this.ticket.categoryId && t.seat === seat;
          }), 1);

          if (!deleted.length) {
            throw new Error('Didn\'t find a customer ticket to delete');
          }

          const { bookingId, bookingToken } = NBookingModule;

          // TODO: remove next comment once BUD-8305 is tested
          // NBookingModule.applyMembership().then(() => {
          Promise.resolve().then(() => {
            // Apply membership only when we have a memebership selected
            const { membership } = NBookingModule;
            if (membership && isRealMembershipDiscount(membership)) {
              return NBookingModule.applyMembership();
            }
            return;
          })
          .then(() => {
            return NBookingModule.fetchBooking({bookingToken, bookingId});
          }).finally(() => {
            if (AppModule.isCartWidget) {
              sendCartBookingInfo(getCartBookingInfo());
            }
            if (Loading.isActive) {
              Loading.hide();
              disableQuasarStyling();
            }
          });
        }

        // Make sure that we deselect the customers that no longer have pss on this seating plan
        // if (isDeselecting && customers.length) {
        if (isReleasing && customers.length) {
          const { bookingId, bookingToken } = NBookingModule;
          // TODO: remove next comment once BUD-8305 is tested
          // NBookingModule.applyMembership().then(() => {
          Promise.resolve().then(() => {
            const { membership } = NBookingModule;
            if (membership && isRealMembershipDiscount(membership)) {
              return NBookingModule.applyMembership();
            }
            return;
          }).then(() => {
            return NBookingModule.fetchBooking({bookingToken, bookingId});
          }).then(() => {

            // Check which customer doesn't have a pss related to this productId & category Id
            const slots = (NBookingModule.bookingRes && NBookingModule.bookingRes.slots) || [];
            // if (!slots || !slots.length) {
            //   throw new Error ('No pss was found when deselecting');
            // }

            // Keep only the slots that belong to this timeslot & category & price
            const timeSlotId = Number(payload.slot.id);
            const categoryId = Number(payload.ticket.categoryId);
            const priceId = Number(payload.price.priceId);
            const ticketSlots = slots.filter((pss) => {
              return Number(pss.timeslotId) === timeSlotId;
                // && Number(pss.priceId) === priceId;
            });

            // Build customers array
            const pssCustomers = ticketSlots.map((s) => {
              return { firstName: s.firstName, lastName: s.lastName };
            });

            // Get all the customers that don't have a pss
            const customersNoPss: IMembershipCustomer[] = [];
            for (const cust of customers) {
              const customerWithPss =
                pssCustomers.find((pssCust) =>
                  pssCust.firstName === cust.firstName
                  && pssCust.lastName === cust.lastName);

              if (!customerWithPss) {
                debug(`deselecting customer ${cust.firstName + cust.lastName}`);
                debug(cust.tickets);
                customersNoPss.push(cust);
              }
            }

            // if (!customersNoPss.length) {
            //   if (Loading.isActive) {
            //     Loading.hide();
            //     disableQuasarStyling();
            //   }
            //   throw new Error('No customers without pss were found');
            // }

            // Remove this ticket for all the remaining customers
            for (const cust of customersNoPss) {
              const tickets = cust.tickets;
              const ticketIndex = tickets.findIndex((t) => {
                return t.timeSlotId === timeSlotId
                  && t.priceId === priceId;
              });

              if (ticketIndex !== -1) {
                debug(`Removing ticket from ${cust.firstName} ${cust.lastName}`);
                tickets.splice(ticketIndex, 1);
              }
            }

            if (AppModule.isCartWidget) {
              sendCartBookingInfo(getCartBookingInfo());
            }

          }).finally(() => {
            if (Loading.isActive) {
              Loading.hide();
              disableQuasarStyling();
            }
            debug1('deselecting a seat and going to updateing chart config');
            this.updateChartConfig();
          });
        }
      };

      try {
        NBookingModule.remPackageError(String(payload.ticket.categoryId));
        await NBookingModule.addPriceQ({ priceCount: payload, cb: callback});
      } catch (error) {
        NNoty.createNewNoty({
          period: 4000,
          message: String(this.$t('error.error-response-banner')),
          type: 'error',
        });
        throw error;
      }

      // if (customers && customers.length) {
      //   await NBookingModule.applyMembership();
      // }
    }
  }

  private displayAddons(payload: IPriceCount) {
    this.showPricePackage = false;

    if (payload.slot.export_prices) {
      this.pricePackage = this.getPricePackage(payload.price, payload.slot);
    }
    if (this.pricePackage) {
      this.price = payload.price;
      this.chosenSlot = payload.slot;
      this.showPricePackage = true;

      const myComponent = this.$el;
      // getting the wrapper element of package prices inside this component special to this seating ticket
      const packagePricesElement = myComponent.querySelector('#package-prices');
      if (packagePricesElement) {
          const padding = AppModule.isMobile ? 50 : 200;
          setTimeout(() => scrollToElement(packagePricesElement as HTMLElement, padding), 200);
      }
    }
  }

  private async addToMembership_oneCustomerCase(priceCount: IPriceCount) {
    const categoryId = priceCount.ticket.categoryId;
    const timeSlotId = priceCount.slot.id;
    const priceId = priceCount.price.priceId;
    const priceName = priceCount.price.priceName;
    const priceCategory = priceCount.price.priceCategory;
    const seat = priceCount.seat as string;
    const { membership} = NBookingModule;
    const membershipCustomers = this.membershipCustomers;

    // Remove next comment once BUD-8305 is tested
    // if (!membership || !membershipCustomers.length) {
    if (!NBookingModule.isCustomerBooking && !this.isCustomCustomerBooking) {
      return;
    }

    const bookingId = NBookingModule.bookingId;
    const bookingToken = NBookingModule.bookingToken;
    if ( !bookingId || !bookingToken) {
      return;
    }

    // Fetch ticket fields.
    const customFields = await getBookingFields(bookingId, bookingToken);
    const ticketFields = customFields.ticket_fields as ITicketField[];

    const customer = membershipCustomers[0];
    const { firstName, lastName } = customer;

    const answers: IPostBookingField[] = [];

    // Push firstName & lastName é membership name answers if they aren't there already
    for (const field of ticketFields) {
      if (field.answer && field.answer !== '' && field.answer !== ' ') {
        continue;
      }
      if (field.informationType === InformationType.FirstName) {
        answers.push({
          answer: firstName,
          pss: field.pssId,
          fieldId: field.fieldId,
        });
      }
      if (field.informationType === InformationType.LastName) {
        answers.push({
          answer: lastName,
          pss: field.pssId,
          fieldId: field.fieldId,
        });
      }
      if (field.informationType === InformationType.MembershipInfo) {
        answers.push({
          answer: membership && (membership.type === MembershipType.Family) ?
          `${membershipCustomers[0].lastName}` : `${firstName} ${lastName}`,
          pss: field.pssId,
          fieldId: field.fieldId,
        });
      }
    }

    try {
      if (answers.length) {
        await postBookingField(bookingId, bookingToken, answers);
      }

      // Apply membership only if we are dealing with a memebership
      if (NBookingModule.isMembershipBooking &&
        isRealMembershipDiscount(membership)) {
        await NBookingModule.applyMembership();
      }

      await NBookingModule.fetchBooking({bookingToken, bookingId});

      /*
       * When dealing with custom customer booking, we take related pss Id
       * using priceName, seat, timeSlotId, priceId
       * We need to check this logic if we are in case of General Admission
       *
       */
      const pssId = this.isCustomCustomerBooking ?
        this.getRelatedPSSId(priceName, seat, timeSlotId, priceId) : this.getNewestPssId();
      customer.tickets.push(
        {
          categoryId,
          seat,
          priceId,
          priceName,
          timeSlotId,
          pssId,
          priceCategory,
        },
      );

      if (AppModule.isCartWidget) {
        sendCartBookingInfo(getCartBookingInfo());
      }
    } catch (error) {
      NNoty.createNewNoty({
        period: 4000,
        message: String(this.$t('error.error-response-banner')),
        type: 'error',
      });
      throw error;
    }
  }

  private addToMembership(priceCount: IPriceCount) {
    debug1('hi from addToMembership');
    debug1('priceCount', priceCount);
    debug('Invoking add to membership');

    if (Loading.isActive) {
      Loading.hide();
      disableQuasarStyling();
    }
    const categoryId = priceCount.ticket.categoryId;
    const timeSlotId = priceCount.slot.id;
    const priceId = priceCount.price.priceId;
    const priceName = priceCount.price.priceName;
    const priceCategory = priceCount.price.priceCategory;
    const seat = priceCount.seat as string;

    const memType = ReferrerModule.memType;
    // TODO remove next comment when BUD-8305 is tested
    // if (!memType) {
    if (!NBookingModule.isCustomerBooking && !this.isCustomCustomerBooking) {
      return;
    }

    const {membership} = NBookingModule;
    const membershipCustomers = this.membershipCustomers;

    // TODO remove next comment when BUD-8305 is tested
    // if (!membership || !membershipCustomers.length) {
    //   return;
    // }

    if (membershipCustomers.length === 1) {
      // No need to show customers popup if we have only one customer
      this.addToMembership_oneCustomerCase(priceCount);
      return;
    }

    const memName = membership ? membership.name : '';

    const opts = [];
    let i = 0;
    // let invalidIndex = 999;
    // for (let i = 0; i < membershipCustomers.length; i++) {
    //   opts.push({
    //     label: `Add ticket to "${memName} #${i}"`,
    //     value: i,
    //   });
    // }

    // TODO remove next comments once BUD-8305 is tested
    // if (membership.type === MembershipType.Individual) {
    for (const customer of membershipCustomers) {
      // Customer can't buy if he already has bought this category
      // if (customer.tickets.find((ticket) => ticket.categoryId === categoryId)) {
      //   invalidIndex = i;
      // }
      const {firstName, lastName} = customer;
      opts.push({
        label: `${firstName} ${lastName}`,
        value: i,
      });
      i++;
    }
    // } else if (membership.type === MembershipType.Family) {
    //   for (const customer of membershipCustomers) {
    //     // Customer can't buy if he already has bought this category
    //     // if (customer.tickets.find((ticket) => ticket.categoryId === categoryId)) {
    //     //   invalidIndex = i;
    //     // }
    //     const {firstName, lastName} = customer;
    //     opts.push({
    //       label: `${firstName} ${lastName}`,
    //       value: i,
    //     });
    //     i++;
    //   }
    // }

    let counter = 0;
    enableQuasarStyling();
    // for (let c = 0; c < priceCount.count; c++) {
    for (let c = 0; c < 1; c++) {
      counter++;
      this.$q.dialog({
        title: this.$t('membership.update') as string,
        message: membership && (membership.type === MembershipType.Individual) ?
          this.$t('membership.update2') as string : this.$t('membership.update3') as string,
        options: {
          type: 'radio',
          model: (0 as any),
          // inline: true
          items: opts,
          isValid: (data: any) => {
            // const customer = membershipCustomers[data];
            // return !(customer.tickets.find((t) => t.categoryId === categoryId));
            // If the customer has already booked this ticket then return not valid

            const customer = membershipCustomers[data];
            debug1('customer', customer);
            const userAlreadyBookedThisTicket = (customer.tickets.find((t) => t.categoryId === categoryId));
            if (userAlreadyBookedThisTicket) {
              return false;
            }
            // - If user already booked another price
            //   - if previous price is not the same as the current price
            if (customer.tickets && customer.tickets.length > 0) {
              // const notRightPriceForCustomer = !(customer.tickets.find((t) => t.priceName === priceName));
              const notRightPriceForCustomer =
              !isCustomerWithOnlyOtherTickets(customer) &&
              !(customer.tickets.find((t) => t.priceCategory === priceCategory)) &&
              // Everyone can select price category other
              !(priceCategory === PriceCategory.Other);
              //     - Not valid
              if (notRightPriceForCustomer ) {
                  return false;
              }
            }

            // It is valid
            return true;
          },
        },
        // cancel: true,
        persistent: true,
      }).onOk(/*data*/async (data: number) => {
        const dialog = this.$q.dialog({
          // message: 'Uploading... 0%',
          progress: true, // we enable default settings
          persistent: true, // we want the user to not be able to close it
          ok: false, // we want the user to not be able to close it
        });
        const bookingId = NBookingModule.bookingId;
        const bookingToken = NBookingModule.bookingToken;
        if ( !bookingId || !bookingToken) {
          return;
        }

        // Fetch ticket fields.
        const customFields = await getBookingFields(bookingId, bookingToken);
        const ticketFields = customFields.ticket_fields as ITicketField[];

        const customer = membershipCustomers[data];
        const { firstName, lastName } = customer;

        const answers: IPostBookingField[] = [];

        // Post firstName answer
        for (const field of ticketFields) {
          if (field.informationType === InformationType.FirstName && !field.answer) {
            answers.push({
              // answer: `${memName} #${data}`,
              answer: firstName,
              pss: field.pssId,
              fieldId: field.fieldId,
            });
            // await postBookingField(bookingId, bookingToken, []);
            break;
          }
        }

        // Post last name answer
        for (const field of ticketFields) {
          if (field.informationType === InformationType.LastName && !field.answer) {
            // Post firstName answer
            answers.push({
              // answer: `${memName} #${data}`,
              answer: lastName,
              pss: field.pssId,
              fieldId: field.fieldId,
            });
            // await postBookingField(bookingId, bookingToken, []);
            break;
          }
        }

        // Check ticket field with information type equal to 20 & doesn't have an answer
        for (const field of ticketFields) {
          if (field.informationType === InformationType.MembershipInfo && !field.answer) {

            // Post membership name as answer;
            answers.push({
              // answer: `${memName} #${data}`,
              answer: membership && (membership.type === MembershipType.Family) ?
                `${membershipCustomers[0].lastName}` : `${firstName} ${lastName}`,
              pss: field.pssId,
              fieldId: field.fieldId,
            });
            // await postBookingField(bookingId, bookingToken, []);

            // if (this.$route.query.applmem) {
              // await applyMemDiscount({
              //   bookingId, bookingToken, memId: membership.id,
              // });
            // }
          }
        }

        try {
          // We dont post booking Fields if we are dealing with a custom customer booking
          if (answers.length) {
            await postBookingField(bookingId, bookingToken, answers);
          }
          // We dont apply membership if we are dealing with a custom customer booking
          if (NBookingModule.isMembershipBooking &&
            !this.isCustomCustomerBooking &&
            isRealMembershipDiscount(membership)) {
            await NBookingModule.applyMembership();
          }

          await NBookingModule.fetchBooking({bookingToken, bookingId});

          /*
           * When dealing with custom customer booking, we take related pss Id
           * using priceName, seat, timeSlotId, priceId
           * We need to check this logic if we are in case of General Admission
           *
          */
          const pssId = this.isCustomCustomerBooking ?
            this.getRelatedPSSId(priceName, seat, timeSlotId, priceId) : this.getNewestPssId();

          customer.tickets.push(
            {
              categoryId,
              seat,
              priceId,
              priceName,
              timeSlotId,
              pssId,
              priceCategory,
            },
          );

          this.updateChartConfig();
          if (AppModule.isCartWidget) {
            sendCartBookingInfo(getCartBookingInfo());
          }
          counter--;
          debug('Counter is ', counter);
        } catch (error) {
          NNoty.createNewNoty({
            period: 4000,
            message: String(this.$t('error.error-response-banner')),
            type: 'error',
          });
          throw error;
        } finally {
          dialog.hide();
          disableQuasarStyling();
        }

        return;
      }); // onOk
    } // forLoop
  }

  // Returns the pssId that we got after applying the membership
  // and before adding it to the membershipCustomers
  private getNewestPssId(): number | undefined {
    const membershipCustomers = this.membershipCustomers;
    const appliedMembership = NBookingModule.membershipApplied;
    const updatedPSSList = appliedMembership?.productSlotStates.map((p) => p.id);
    // now look for the newest pss by looking for the one from updatedPSSList
    // that is inside membrshipCustomers already
    const customersExistingPSSList = Array.prototype.concat.apply([],
    membershipCustomers.map((c) => c.tickets.map((t) => t.pssId)));

    const diffPSS = updatedPSSList?.filter((pss) => !customersExistingPSSList.includes(pss));
    // newest PSS
    const newestPSS = diffPSS !== undefined ? diffPSS[0] : undefined;
    return newestPSS;
  }

  // Return pssId of a choosed seat
  private getRelatedPSSId(priceName: string, seat: string, timeSlotId: number, priceId: number): number | undefined {
    const productSlotStates = (NBookingModule.bookingRes as any).slots;
    const pss = productSlotStates.find((t: any) =>
      t.priceName === priceName && t.seat === seat && t.timeslotId === timeSlotId && t.priceId === priceId);

    return pss ? pss.productSlotStateId : null;
  }

  private onChartRendered() {
    this.chartRendering = false;
    // We update pricing here and not when chart rendered to cover below scenario
    // if chart has already selected seats from prices to be filtered, those seats won't appear in the chart
    // But when updating pricing when the chart has fully rendered, the selected seats will stay there
    this.updateChartConfig();
    // on Iframe, try to make the iframe wider on desktop, so that
    // it's easier to select seats
    expandIfChartIsPresent();

    setTimeout(() => {
      this.canShowLoading = true;
    }, 500);
    // NBookingModule.setHoldToken(this.chart.holdToken);
    // when the chart is rendered, we store it's hold token directly
    // on the category object, so that we can open the same chart again later
    // when it's destroyed.
    // Note: We shouldn't use the same holdToken if we don't want to manipulate
    // selected seats between different chart renders.
    debug('Chart rendered with token', this.chart.holdToken);
    (this.ticket as any).holdToken = this.chart.holdToken;
    if (!this.ticket.seatingSubProducts) {
      return;
    }

    // We store the hold token on each seats category for future reference.
    this.ticket.seatingSubProducts.forEach((product: any) => {
      product.holdToken = this.chart.holdToken;
    });
  }

}

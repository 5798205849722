import AppSearchAPIConnector from '@elastic/search-ui-app-search-connector';
import * as ElasticAppSearch from '@elastic/app-search-javascript';

const engine = {
  engineName: `${process.env.VUE_APP_SEARCH_ENGINE}`,
  hostIdentifier: 'host-wp6j4c',
  // endpointBase: 'https://c9ab378c1fbb4bd7b9e5e7ecce3dfca8.app-search.eu-central-1.aws.cloud.es.io',
  // endpointBase: 'https://search.smeetz.com',
  // searchKey: 'search-wcvrmmjptke93pssjsnbbowe',
  endpointBase: `${process.env.VUE_APP_SEARCH_ENDPOINT}`,
  searchKey: `${process.env.VUE_APP_SEARCH_PRODUCT_KEY}`,
  // set resultFields only if you need exactly several fields
  // resultFields: [
  //   'country',
  //   'last_booked',
  //   'number_booked_ticket_2weeks',
  //   'city',
  //   'weather_recommended',
  //   'advertised_price',
  //   'average_number_of_ticket_sold',
  //   'dates',
  //   'title',
  //   'smeetz_category',
  //   'product_name',
  //   'ticket_description',
  //   'colour',
  //   'ticket_name',
  //   'organiser_name',
  //   'name',
  //   'cover_picture',
  //   'max_discount',
  //   'currency',
  //   'average_occupancy',
  //   'is_dynamic_pricing',
  //   'product_description',
  //   'average_total_capacity',
  //   'id',
  //   'branding_smeetz_primary_color',
  //   'branding_widget_button_color',
  //   'branding_widget_text_color',
  //   'branding_widget_title_color',
  //   'branding_widget_navigation_color',
  //   'branding_widget_header_color',
  //   'branding_widget_font',
  //   'organiser_id',
  //   'organiser_name',
  //   'organiser_slug_name',
  // ],
  sortFields: [
    'dates',
  ],
  facets: [
    'city',
  ],
};

const { searchKey, endpointBase, hostIdentifier, engineName } = engine;
// Connector for input using
const connector = new AppSearchAPIConnector({
  searchKey,
  endpointBase,
  hostIdentifier,
  engineName,
});

// Client for get parameters for one time
export const client = ElasticAppSearch.createClient({
  searchKey,
  endpointBase,
  engineName,
  hostIdentifier,
});

// Config for input search. Use in Search field
const config = {
  debug: true,
  apiConnector: connector,
};

export default config;






























































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { required, maxLength } from 'vuelidate/lib/validators';
import { Validator } from '@/utils/validators';
import { AppModule, NBookingModule, NNoty, OrganizerModule, ProductModule, ReferrerModule } from '@/utils/storemodules';
import NewButton from '@/components/presentational/NewButton.vue';
import {
  IMembershipDiscounts, IMembershipProductSlotStates, IMembershipProductSlotStatesFront,
  MembershipType, MemTicketStatus, MemType,
} from '@/models/memberships';
import Tag from '@/components/presentational/TicketTag.vue';
import Select from '@/components/presentational/NewSelect.vue';
import { EVSelect } from '@/models/events';
import { scrollToElement } from '@/utils/dom';
import { disableQuasarStyling, enableQuasarStyling } from '@/utils/styles';
import { Loading } from 'quasar';
import { cancelPSS } from '@/api/booking';
import { IMembershipCustomer } from '@/models/store/booking';
import { getCartBookingInfo, sendCartBookingInfo } from '@/utils/iframe';
import { BookingSteps } from '@/store/modules/newBooking/constants';
import { isFamilyMembership } from '@/utils/membership';
import { isChildPriceCategory, isChildPriceName } from '@/utils/booking';
import { smeetzTracker } from '@/utils/tracking';
import { CategoryType, EventType } from '@/models/enums';

// const debug = new Debug('smeetz:seatnames');
@Component({
  components: {
    NewButton,
    Tag,
    Select,
  },
  validations: {
    code: {
      required,
      maxLength: maxLength(255),
      invalid: Validator.numbersLettersSpecialCharacters,
    },
  },
})
export default class Memberships extends Vue {
  // private memId: number | null = null;
  private memId: number | null = null;
  private loading: boolean = false;
  private btnLoading = false;
  private memType: MemType | null = null;
  private memIds: number[] = [];
  private responseError: boolean = false;
  private discounts: IMembershipDiscounts[] = [];
  private prodSlotStates: IMembershipProductSlotStates[] = [];
  private EVSelect = EVSelect;
  private membershipError = '';
  // no need to scroll down to error msg when user is removing the tickets
  private canScroll: boolean = true;
  // Equilibre is using addons on a membership discount ticket
  // thus we need to ignore the addons 106864, 106862
  private bypassedCats = [106864, 106862];

  get hasError() {
    return this.$v.code.$error || this.responseError;
  }

  get options() {
    // return this.discounts.map((discount) => {
    //   return { label: discount.name, value: discount.id };
    // });
    // return this.discounts.map((discount) => discount.id);

    return this.discounts.map((discount) => discount.name);
  }

  get canSubmit() {
    return !!this.memId;
  }

  get canContinue() {

    if (this.memId) {
      // Make sure that all the tickets are included for Membership type 1
      if (this.memType === MemType.MemOnly) {
        return this.notIncludedProdSlotStates.length === 0;
      }

      return !!this.includedProdSlotStates.length;
    }

    return this.prodSlotStates.length;
  }

  get currency() {
    return ProductModule.productCurrency;
  }

  get modProdSlotStates(): IMembershipProductSlotStatesFront[] {
    const ticketSlotStates: IMembershipProductSlotStatesFront[] = [];
    const recapCategories = NBookingModule.recapCategories;
    const {membershipCustomers} = NBookingModule;
    for (const prodSlotState of this.prodSlotStates) {
      const { priceId, categoryId, timeSlotId } = prodSlotState;
      // Find category
      const cat = recapCategories.find((c) => {
        const { categoryId: cId, priceId: pId, timeSlotId: tId } = c;
        return categoryId === cId && priceId === Number(pId) && timeSlotId === Number(tId);
      });
      if (!cat) {
        continue;
      }
      // Add Fronend required fields
      const modifiedProductSlotState: IMembershipProductSlotStatesFront = {
        ...prodSlotState,
        categoryName: cat.categoryName, priceName: cat.priceName, seat: cat.seat,
      };
      if (modifiedProductSlotState.membershipTicket) {
        const correspondingTicket = membershipCustomers.find((c) =>
        c.tickets.some((t) => t.pssId === modifiedProductSlotState.id));
        if (correspondingTicket) {
          modifiedProductSlotState.membershipTicket.firstName = correspondingTicket?.firstName;
          modifiedProductSlotState.membershipTicket.lastName = correspondingTicket?.lastName;
        }
      }
      const memTicket = prodSlotState.membershipTicket;
      if (memTicket && this.memId) {
        // const priceValue = cat.priceValue / Number(cat.quantity);
        // modifiedProductSlotState.priceValue = priceValue - priceValue * memTicket.discount / 100;
        modifiedProductSlotState.membershipName = this.membershipName(memTicket.membershipDiscountId);
      }
      ticketSlotStates.push(modifiedProductSlotState);
    }

    return ticketSlotStates;
  }

  get includedProdSlotStates() {
    return this.modProdSlotStates.filter((prodSlotState) => !!prodSlotState.membershipTicket);
  }

  get notIncludedProdSlotStates() {
    return this.modProdSlotStates.filter((prodSlotState) =>
      !prodSlotState.membershipTicket &&
      !(this.bypassedCats.includes(prodSlotState.categoryId) && prodSlotState.mainTicketId));
  }

  get memberships(): IMembershipProductSlotStatesFront[][] {
    const res: {[name: string]: IMembershipProductSlotStatesFront[]} = {};

    for (const prodSlotState of this.includedProdSlotStates) {
      if (!prodSlotState.membershipTicket) {
        continue;
      }

      if (!res[String(prodSlotState.membershipTicket.id)]) {
        res[String(prodSlotState.membershipTicket.id)] = [];
      }

      res[String(prodSlotState.membershipTicket.id)].push(prodSlotState);
    }

    const memberships = Object.keys(res).sort((mem1, mem2) => Number(mem1) - Number(mem2))
      .map((mem) => res[mem]);

    return memberships;
  }

  // @Watch('code')
  // public onPromoCodeChanged(val: string) {
  //   (this.$v.code.$model as any) = val.toUpperCase();
  // }

  private async created() {
    this.memType = ReferrerModule.memType || 0;
    this.memIds = ReferrerModule.memIds;
    const orgId = OrganizerModule.id;
    if (!orgId) {
      return;
    }

    // Apply membership on booking directly on render;
    const { membership } = NBookingModule;
    if (membership) {
      this.memId = membership.id;
      this.discounts = NBookingModule.discounts;
      this.submit();
      return;
    }
  }

  private updated() {
    this.$nextTick(function() {
      // Run the below after the entire view has been re-rendered
      if (this.membershipError && this.canScroll) {
        this.scrollToValidationError();
      }
    });
  }

  private getProductName(categoryId: number) {
    const { bookingRes } = NBookingModule;
    const category = bookingRes?.bookingRecap.categories?.find((cat) =>
      cat.categoryId === categoryId && cat.categoryType === CategoryType.SeatingPlan);
    if (category) {
      return category.productName + '-';
    }
    return null;
  }

  private scrollToValidationError() {
    const myComponent = this.$el;
    // getting the wrapper element of package prices inside this component special to this seating ticket
    const validationMsgElement = myComponent.querySelector('#validation-error-wrapper');
    if (validationMsgElement) {
          const padding = AppModule.isMobile ? 50 : 200;
          setTimeout(() => scrollToElement(validationMsgElement as HTMLElement, padding), 50);
      }
  }

  private optionsChange(memName: string) {
    const discount = this.discounts.find((d) => d.name === memName);

    if (discount) {
      this.memId = discount.id;
    } else {
      this.memId = null;
    }

    this.prodSlotStates = [];
  }

  private membershipName(memId: number) {
    const membership = this.discounts.find((disc) => disc.id === memId);
    return membership ? membership.name : '';
  }

  /**
   * This function handles
   * 1- Initial on created
   *
   */
  private async submit() {
    const { bookingId, bookingToken } = NBookingModule;
    const membership = NBookingModule.membership;
    const customers = NBookingModule.membershipCustomers;
    const membershipType = (membership && membership.type);

    if (!this.memId) {
      return;
    }

    // Incase we have to apply the discounts to the booking
    if (!this.prodSlotStates.length) {
      try {
        this.btnLoading = true;
        this.loading = true;
        const appliedMembership = await NBookingModule.applyMembership();
        this.prodSlotStates = appliedMembership.productSlotStates;

        let error: 'not-membership-ticket' | 'not-enough' | 'not-valid-family' | '' = '';

        // Parse all product slot states and check if we have a problem.
        for (const pss of appliedMembership.productSlotStates) {
          const ticket = pss.membershipTicket;
          if (!ticket && !(this.bypassedCats.includes(pss.categoryId) && pss.mainTicketId)) {
            error = 'not-membership-ticket';
            break;
          }

          if (ticket && ticket.status === MemTicketStatus.NotEnoughTikcet) {
            error = 'not-enough';
            break;
          }
        }

        // Make sure that we have Adult and child per show for family memberships
        if (membership && isFamilyMembership(membership)) {
          const customersCategories: {[cat: string]: {hasChild: boolean, hasNonChild: boolean}} = {};

          // Iterate over each customer and mark if he bought child/adult price for each ticket
          for (const customer of customers) {
            for (const ticket of customer.tickets) {
              const { categoryId, priceName, priceCategory } = ticket;
              if (!customersCategories[categoryId]) {
                customersCategories[categoryId] = { hasChild: false, hasNonChild: false };
              }
              // if (isChildPriceCategory(priceCategory)) {
              // Detect child prices by name only for equilibre. TODO change in season 3
              if (
                // isEquilibre(OrganizerModule.id || 0) ? isChildPriceName(priceName)
                // : isChildPriceCategory(priceCategory)
                isChildPriceCategory(priceCategory)
              ) {
                customersCategories[categoryId].hasChild = true;
                continue;
              }
              customersCategories[categoryId].hasNonChild = true;
            }
          }

          // make sure all tickets have adult and child
          for (const category of Object.values(customersCategories)) {
            if (!(category.hasChild && category.hasNonChild)) {
              error = 'not-valid-family';
              break;
            }
          }
        }

        if (!error) {
          // if no tickets are left, we are done
          NBookingModule.fetchBooking({bookingToken, bookingId});
          this.$emit('done');
          return;
        }

        // Membership conditions aren't fully satisfied.
        // const membership = appliedMembership.membershipDiscount;

        // User somehow didn't select a membership at first
        // Riyadh, this should never happen!!!
        if (!membership) {
          throw new Error('No membership was set !!');
        }


        // The next error is for family memebership that is not enough
        const { name, minimalNumberTicket } = membership;
        if (error === 'not-enough' && membership.type === MembershipType.Family) {
          this.membershipError = (this.$t('membership.error2') as string).replace('XX', name)
            .replace('XXX', String(minimalNumberTicket));
          return;
        }

        if (error === 'not-valid-family') {
          this.membershipError = (this.$t('membership.error3') as string).replace('XX', name);
          return;
        }

        // This error is for Individual memebership that is not enough
        if (error === 'not-enough') {
          this.membershipError = (this.$t('membership.error1') as string).replace('XX', name)
            .replace('XXX', String(minimalNumberTicket));
          return;
        }

        // This error is for tickets that are not membership tickets
        if (error === 'not-membership-ticket') {
          this.membershipError = this.$t('membership.not-membership-ticket-error') as string;
          return;
        }

      } catch (error) {
        NNoty.createNewNoty({
          period: 4000,
          message: String(this.$t('error.error-response-banner')),
          type: 'error',
        });
        throw error;
      } finally {
        this.loading = false;
        this.btnLoading = false;
      }

      return;
    }

    if (this.membershipError) {
      NBookingModule.stepBack();
    }
  }

  /**
   * Removes a price from prices list inside membership validation.
   * Will move back to order page if not tickets are left in the cart.
   */
  private async remove(prodSlotState: IMembershipProductSlotStates) {
    try {
      enableQuasarStyling();
      Loading.show();
      const { bookingId, bookingToken, bookingRes } = NBookingModule;
      const {id, categoryId, priceId, priceValue} = prodSlotState;

      const payload = {
        bookingId,
        bookingToken,
        releaseSeats: 1 as (1 | 0),
        tickets: [id],
      };

      const response = await cancelPSS(payload);
      if (response && response.status === 'success') {
        // Get the product id
        const removedTicketProductId =
        bookingRes?.bookingRecap.categories?.filter((category) => category.categoryId === categoryId)[0].productId;
        // object that has the data we send for smeetz tracking
        // this data will be used for dynamic pricing.
        const smtzTrackingData = {
          catId: categoryId,
          pId: removedTicketProductId,
          prId: priceId,
          prVal: priceValue,
          qt: 1,
        };
        smeetzTracker(EventType.ticketRemoved, smtzTrackingData);

        // TODO remove comment once BUD-8305 is tested
        // if (ReferrerModule.isMembershipOnly) {
        if (NBookingModule.membership) {
          // Lets update the membershipCustomers in the state
          const {membershipCustomers, membership} = NBookingModule;
          const updatedMembershipCustomers: IMembershipCustomer[] = [];
          for (const customer of membershipCustomers) {
            const cus: IMembershipCustomer = {
              firstName: customer.firstName,
              lastName: customer.lastName,
              tickets: customer.tickets.filter((t) => {
                if (t.pssId !== undefined && id === t.pssId) {
                  // Do nothing it is the one we have removed
                } else {
                  return t;
                }
              }),
            };
            updatedMembershipCustomers.push(cus);
          }
          NBookingModule.setMembCustomers(updatedMembershipCustomers);
        }
      } else {
        NNoty.createNewNoty({
          period: 4000,
          message: String(this.$t('error.error-response-banner')),
          type: 'error',
        });
      }
      this.canScroll = false;
      const appliedMembership = await NBookingModule.applyMembership();
      this.prodSlotStates = appliedMembership.productSlotStates;
      await NBookingModule.fetchBooking({bookingToken, bookingId});

      if (AppModule.isCartWidget) {
        sendCartBookingInfo(getCartBookingInfo());
      }

      Loading.hide();
      disableQuasarStyling();

      // Go back to order page if we removed all categories
      if (this.memberships.length === 0) {
        NBookingModule.stepTo(BookingSteps.Order);
      }

    } catch (error) {
      Loading.hide();
      disableQuasarStyling();
      NNoty.createNewNoty({
        period: 4000,
        message: String(this.$t('error.error-response-banner')),
        type: 'error',
      });
      throw error;
    }
  }
}

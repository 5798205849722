











import { Vue, Component, Prop } from 'vue-property-decorator';
import { ProductsModule } from '@/utils/storemodules';
import { IProductCategory } from '@/models/store/region';

@Component
export default class Category extends Vue {
  @Prop({ default: null }) private category: IProductCategory | null = null;

  get translatedCategoryName() {
    if (!this.category) {
      return '';
    }
    const lang = this.$i18n.locale ;
    return this.category.translations[lang];
  }

  private goToCategory(category: any) {
    const locale = this.$i18n.locale;
    ProductsModule.setSmeetzCategory([category.name]);
    this.$router.push(`/${locale}/search`);
  }
}


























































/**
 * This is the component that is responsible for showing modals.
 * These modals contain some functionality for login, resetting
 * passwords, ...etc
 *
 * It is activated using NModalModule and modal module imported
 * from /src/utils/storemodules.ts.
 * Use NModalModule now, because the modal module is only supported
 * for legacy code.
 */
import { Vue, Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ModalNav from '@/components/presentational/ModalNav.vue';
import Terms from '@/components/presentational/Terms.vue';
import EmailConf from '@/components/modal/EmailConf.vue';
import EmailVerified from '@/components/modal/EmailVerified.vue';
import ForgotPass from '@/components/modal/ForgotPass.vue';
import ChangePass from '@/components/modal/ChangePass.vue';
import SwissPass from '@/components/modal/SwissPass.vue';
import DeleteAccountConf from '@/components/modal/DeleteAccountConf.vue';
import DeleteAccountVerified from '@/components/modal/DeleteAccountVerified.vue';
import AddEmail from '@/components/modal/userspace/AddEmail.vue';
import AddPhone from '@/components/modal/userspace/AddPhone.vue';
import EditPersonalDetails from '@/components/modal/userspace/EditPersonalDetails.vue';
import MobilePhoneConf from '@/components/modal/MobilePhoneConf.vue';
import Login from '@/components/Login.vue';
import Register from '@/components/Register.vue';
import { ModalStatus } from '@/models/definitions';
import { NModalModule } from '../../utils/storemodules';


const modalModule = namespace('modal');

@Component({
  components: {
    ModalNav,
    Terms,
    EmailConf,
    EmailVerified,
    ForgotPass,
    ChangePass,
    SwissPass,
    DeleteAccountConf,
    DeleteAccountVerified,
    AddEmail,
    AddPhone,
    EditPersonalDetails,
    MobilePhoneConf,
    Login,
    Register,
  },
})
export default class ModalOverlay extends Vue {

  // @Prop({default: true})
  // public isModalVisible!: boolean;
  @Prop({default: 'test@test.test'})
  public emailUser!: any;

  private classElem: string = 'stop-scrolling';
  private documentHTML: HTMLElement = document.getElementsByTagName('html')[0];
  private documentBody: HTMLElement = document.getElementsByTagName('body')[0];

  private ModalStatus = ModalStatus;

  @modalModule.Getter('getStatus') private modalStatus!: ModalStatus;
  private get getModalStatus(): ModalStatus {
    // support both old and new modules
    return NModalModule.status || this.modalStatus;
  }

  @modalModule.Mutation('setStatus') private setStatus!: any;

  get hidePolicy() {
    const statuses = [
      ModalStatus.Register,
      ModalStatus.Login,
      ModalStatus.ChangePass,
    ];

    return statuses.includes(this.getModalStatus);
  }

  private beforeDestroy() {
    this.allowScrolling();
  }

  private mounted() {
    this.stopScrolling();
  }

  private stopScrolling(): void {
    this.documentHTML.classList.add(this.classElem);
    this.documentBody.classList.add(this.classElem);
  }

  private allowScrolling(): void {
    this.documentHTML.classList.remove(this.classElem);
    this.documentBody.classList.remove(this.classElem);
  }

  private modalClose() {
    this.allowScrolling();
    this.$emit('close');

    // Support both modules for legacy code
    this.setStatus(ModalStatus.Hidden);
    NModalModule.setStatus(ModalStatus.Hidden);
  }
}

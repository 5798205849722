









import { Component, Vue } from 'vue-property-decorator';
import Login from '@/components/Login.vue';
import Footer from '@/components/Footer.vue';

@Component({
  components: {
    Login,
    Footer,
  },
})
export default class LoginView extends Vue {
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"slots-hours"},[(_vm.hours.length > 1)?_c('div',{staticClass:"bouncing p-color f-heavy"},[(!_vm.enableNewCalendarTimeslotDesign)?_c('span',[_vm._v(_vm._s(_vm.chooseTimeText))]):_c('span',[_vm._v(_vm._s(_vm.chooseTimePeriodText))])]):_vm._e(),_c('div',{class:{
      'with-border': _vm.withBorder,
    }},[(_vm.enableNewCalendarTimeslotDesign)?_c('div',{staticClass:"period-of-day"},_vm._l((_vm.periodOfDay),function(pr,index){return _c('div',{key:index,staticClass:"f-heavy f-reduce f-tiny-mob",attrs:{"for":index}},[_c('div',{staticClass:"slot-time with-border",class:{
            'is-selected': index === _vm.selectedPeriodOfDay,
            'disabled-slot': pr.disabled,
            'mouse-pt f-pri': !pr.disabled,
          },attrs:{"data-tooltip":pr.disabled ? _vm.$t('new-booking-flow.common.no-timeslot-tooltip') : undefined},on:{"click":function($event){!pr.disabled ? _vm.selectedPeriodOfDay = index : ''}}},[_c('span',[_vm._v(_vm._s(pr.title))])])])}),0):_vm._e(),(_vm.enableNewCalendarTimeslotDesign)?_c('div',{staticClass:"d-flex fl-col fl-j-c fl-a-c slot-duration"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('new-booking-flow.common.timeslot-duration')))]),_c('span',[_vm._v(_vm._s(_vm.timeSlotDuration))])]):_vm._e(),_c('div',{staticClass:"d-flex fl-a-c fl-j-c"},[((_vm.displayedSlots.length > 0 && _vm.slots.length > 1))?_c('div',{staticClass:"slot-times"},_vm._l((_vm.displayedSlots),function(hr){return _c('div',{key:hr.startTime + Math.random(),staticClass:"f-reduce f-tiny-mob"},[(_vm.hasOccupancyGradient && hr.publicCount > 0)?_c('div',{staticClass:"slot-time",class:{
            'is-selected': hr.startTime === _vm.hour,
            'disabled-slot': hr.publicCount === 0,
            'mouse-pt': hr.publicCount > 0,
          },style:({
            'background-color': ("" + (hr.bgColor)),
            'margin-right': _vm.enableNewCalendarTimeslotDesign ? '6px' : '2px',
            'min-width': (_vm.enableNewCalendarTimeslotDesign && (_vm.isDesktop || _vm.isTablet)) ? _vm.showPrice ? '76px' : '86px' : ''}),attrs:{"data-tooltip":hr.toolTipTxt || undefined},on:{"click":function($event){hr.publicCount > 0 ? _vm.selectHour(hr.startTime) : ''}}},[_c('span',[_vm._v(_vm._s(_vm.formatTime(hr.startTime)))]),((!_vm.isEndHidden && !_vm.enableNewCalendarTimeslotDesign))?_c('span',[_vm._v(" - ")]):_vm._e(),((!_vm.isEndHidden && !_vm.enableNewCalendarTimeslotDesign))?_c('span',[_vm._v(_vm._s(_vm.formatTime(hr.endTime)))]):_vm._e(),(_vm.showAvailability)?_c('p',{staticStyle:{"font-size":"11px"}},[_vm._v(_vm._s(hr.publicCount)+" "+_vm._s(_vm.$t('new-booking-flow.common.timeslot-duration-availabilities')))]):_vm._e()]):_c('div',{class:{
            'slot-time': !_vm.showPrice,
            'slot-time-with-price': _vm.showPrice,
            'is-selected': hr.startTime === _vm.hour && !_vm.showPrice,
            'f-pri is-selected': hr.startTime === _vm.hour && _vm.showPrice,
            'disabled-slot': hr.publicCount === 0,
            'mouse-pt f-pri': hr.publicCount > 0 && !_vm.showPrice,
            'mouse-pt': hr.publicCount > 0 && _vm.showPrice,
          },style:({
            'margin-right': _vm.enableNewCalendarTimeslotDesign ? '6px' : '2px',
            'min-width': (_vm.enableNewCalendarTimeslotDesign && (_vm.isDesktop || _vm.isTablet)) ? _vm.showPrice ? '76px' : '86px' : ''
            }),attrs:{"data-tooltip":hr.toolTipTxt || undefined},on:{"click":function($event){hr.publicCount > 0 ? _vm.selectHour(hr.startTime) : ''}}},[_c('span',[_vm._v(_vm._s(_vm.formatTime(hr.startTime)))]),((!_vm.isEndHidden && !_vm.enableNewCalendarTimeslotDesign))?_c('span',[_vm._v(" - ")]):_vm._e(),((!_vm.isEndHidden && !_vm.enableNewCalendarTimeslotDesign))?_c('span',[_vm._v(_vm._s(_vm.formatTime(hr.endTime)))]):_vm._e(),(_vm.showAvailability)?_c('p',{staticStyle:{"font-size":"11px"}},[_vm._v(_vm._s(hr.publicCount)+" "+_vm._s(_vm.$t('new-booking-flow.common.timeslot-duration-availabilities')))]):_vm._e(),(_vm.showPrice && hr.price)?_c('div',{staticClass:"hourslot-price-container f-heavy f-reduce f-tiny-mob",style:({'color': ("" + (hr.publicCount > 0 ? hr.priceColor : '')), 'margin-right': '14px'})},[_c('span',[_vm._v(_vm._s(_vm.currency))]),_vm._v(_vm._s(Math.floor(hr.price))),_c('sup',[_vm._v(_vm._s(_vm.getDecimalDigits(hr.price, 2)))])]):_vm._e()])])}),0):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }
























import { Vue, Component, Prop } from 'vue-property-decorator';
import { BookingStatus } from '@/models/store/product';

@Component({})
export default class SoldOut extends Vue {
  @Prop({default: 1}) private bookingStatus!: BookingStatus;
  @Prop() private draftProduct!: boolean;

  private get bookingStatusToString() {
    if (this.draftProduct) {
      return (this.$t('common.not-bookable') as string).toLowerCase();
    }

    switch (this.bookingStatus) {
      case 0: return (this.$t('common.not-bookable') as string).toLowerCase();
      case 2: return (this.$t('common.sold-out') as string).toLowerCase();
      case 3: return (this.$t('common.expired') as string).toLowerCase();
    }
  }

  private get draftBooking() {
    // return (this.$t('common.draft') as string).toLowerCase();
    // we're not using the above one ('Soon' instead of 'Not Bookable') because Sarina Erni asked so
    return (this.$t('common.not-bookable') as string).toLowerCase();
  }
}

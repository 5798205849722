














import {Vue, Component, Prop} from 'vue-property-decorator';

@Component
export default class SmallBox extends Vue {
  @Prop()
  public item!: object;
}






































import { Component, Vue, Prop } from 'vue-property-decorator';
import { IWidgetData } from '@/models/product';
import { ModalStatus } from '@/models/definitions';
import { ModalModule, NModalModule } from '@/utils/storemodules';
import { BookingStatus } from '@/models/store/product';

@Component({})
export default class ProductWidget extends Vue {
  @Prop() private widgetData!: IWidgetData;
  @Prop() private bookingStatus!: BookingStatus;
  @Prop({default: false}) private isMobile!: boolean;
  @ModalModule.Mutation('setStatus') private setStatus: any;

  private openReport() {
    // OPEN REPORT FORM HERE
    this.setStatus(ModalStatus.ReportActivity);
    NModalModule.setStatus(ModalStatus.ReportActivity);
  }

  private get price() {
    if ( this.widgetData.price === null || this.bookingStatus === 0) {
      return null;
    }
    if ( this.widgetData.price !== '0' ) {
      return this.widgetData.currency + ' ' + this.widgetData.price;
    } else {
      return this.$t('booking-flow.label-free');
    }
  }

  private emitGoBooking() {
    if ( this.widgetData.availability ) {
      this.$emit('goBooking');
    }
  }
}

/**
 * Checks whether the site is opened in a facebook app
 */
export function isInFacebookApp() {
  const ua = navigator.userAgent;
  if (ua.indexOf('FBAN') === -1 && ua.indexOf('FBSN') === -1 && ua.indexOf('FBAV') === -1) {
      return false;
  }

  return true;
}

/**
 * Checks whether the site is opened in Instagram app
 */
export function isInInstagramApp() {

  const result = navigator.userAgent.indexOf('Instagram');
  if (result === -1) {
      return false;
  }

  return true;
}

/**
 * Checks whether we are on an ios (iphone, ipod, ipad) device
 */
export function isIos() {
  return navigator.userAgent.match(/iPhone|iPad|iPod/i) ||
    (/iPad|iPhone|iPod/.test(navigator.platform) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1));
}

/**
 * Detects if we are on a safari browser
 */
export function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

/* Sample function that returns boolean in case the browser is Internet Explorer*/
export function isIE() {
  const ua = navigator.userAgent;
  /* MSIE used to detect old browsers and Trident used to newer ones*/
  const isExplorer = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;

  return isExplorer;
}

/**
 * Detects whether the url supplied is a smeetz site
 * @param url: url to be checked
 */
export function isSmeetzSite(url: string) {

  if (url.indexOf('smeetz.com') === -1) {
    return false;
  }

  return true;
}

/**
 * Redirects the user to the same path on Sammy site. Useful for supporting pages that aren't
 * yet supported on smeetz vue user site.
 */
export function redirectToOldSite(path: string) {
  const redirect = process.env.VUE_APP_OLD_SITE + path;

  // remove iframe path ex: /widget from path string
  window.location.assign(redirect.replace(process.env.VUA_APP_IFRAME_PATH, ''));
}

/**
 * Detects whether we are on a producttion build or not
 */

export function isInProduction() {
  return process.env.VUE_APP_BUILD_ENV === 'production';
}

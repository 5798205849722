/**
 * Encapsulates all the query parameters that are used accross
 * the site.
 */
export enum QueryParameters {
  // referrer used for affiliate features
  Referrer = 'referrer',
  // The promo code that applies a discount to the booking
  PromoCode = 'promoCode',
  // the hidden by link categories that can be made visible
  Categories = 'codes',
  // the hidden by link categories that can be made visible
  VisibleCategories = 'cats',
  // date from which we should show calendar tickets
  from = 'from',
  // min calendar date
  mFrom = 'mfrom',
  // max calendar date
  mTo = 'mto',

  // analytics and commission related parameters
  UtmSource = 'utm_source',
  UtmMedium = 'utm_medium',
  UtmCapmaign = 'utm_campaign',
  UtmChannel = 'utm_channel',
  PreSelectTickets = 'set_ticket',
  PreSelectTicketsCount = 'set_count',
  OrganizerColor = 'ocol',

  // Organizer id
  ORGId = 'orgid',

  // Organiser facebook pixel id
  FBId = 'fid',

  // Organiser google analytics id
  GAId = 'gaid',

  // widget id
  WId = 'wid',

  // widget type
  WType = 'wt',

  // Membership type
  MType = 'mt',
  // Membership ids (Show only these memberships if present)
  MIds = 'mids',

  // Tracking id
  TID = 'tid',

  // Organiser widget params
  ButtonColor = 'bcol',
  NavCol = 'ncol',
  TitleColor = 'ttcol',
  TextColor = 'tcol', // Booking flow text color
  Font = 'font',
  CustomText = 'custText',
  CustomUrl = 'custUrl',
  AccessToken = 'access_token',
  SmeetzAccessToken = 'smeetzAccessToken',

  // Integration type (native only is supported)
  IntegrationType = 'integration',
  FBP = 'fbp',
  FBC = 'fbc',
  FBCLID = 'fbclid',
  EventTestCode = 'event-test-code',
  GTMID = 'gtmid',

  Uat = 'uat', // Organiser widget params was updated

  RetailIds = 'retailIds',
  FromWidget = 'fromWidget',
  NewCalendar = 'newCalendar',
  NumDaysNewCalendar= 'numDaysNewCalendar',
  TCart= 'tcart',
  PaymentMethod = 'paymentMethod',
  TopLevelDomainUrl = 'topLevelDomainUrl',
  HostUrl = 'hostUrl',
  NewTimeslots = 'newTimeslots',
}

/**
 * Defines seat io seat types
 */
export enum SeatType {
  // Seats that don't have a particular spot (Area where somone can sit)
  GeneralAdmission = 'GeneralAdmissionArea',
  // Specific seat like in a concert
  Seat = 'Seat',
}

/**
 * Defines price category (Adult, Child, Reduced, Others)
 */
export enum PriceCategory {
  Adult = 1,
  Reduced,
  Child,
  Other,
}

export enum CategoryStatus {
  BookingStatusPending = 1,
  BookingStatusWaitingList,
  BookingStatusConfirmed,
  BookingStatusCancelled,
  BookingStatusAbandoned,
  BookingStatusPendingOrganizer,
  BookingStatusPendingCron,
}

export enum ChargeFlowTypes {
  ChargeFlowTypeDirect = 1, // Direct
  ChargeFlowTypeAuthorized, // Authorized
  ChargeFlowTypeTokenized, // Tokenized
  ChargeFlowTypeDirectDeliver, // Direct confirmation + payment on delivery
  ChargeFlowTypeTokenizedDeliver, // Tokenized confirmation + payment on delivery
}

// Product slot state status;
export const PSSStatus = CategoryStatus;

export enum BookingStatus {
  Impossible, // Not used
  PendingInfo, // Waiting for user info
  PendingPayment, // Waiting for payment
  Completed, // Finished
  Canceled, // Canceled
  ToRefund = 6, // Booking canceled and should be refunded
  Abandoned, // Cancelled after non finished booking
}

export const BookingStatusName: { [s: number]: string } = {
  [BookingStatus.Impossible]: 'Impossible',
  [BookingStatus.PendingInfo]: 'PendingInfo',
  [BookingStatus.PendingPayment]: 'PendingPayment',
  [BookingStatus.Completed]: 'Completed',
  [BookingStatus.Canceled]: 'Canceled',
  [BookingStatus.ToRefund]: 'ToRefund',
  [BookingStatus.Abandoned]: 'Abandoned',
};

// Product slot booking status
export const PSBStatus = BookingStatus;

export enum AccountingStatus {
  Pending = 1,
  Started,
  Authorized,
  Tokenized,
  Completed,
  Failed,
  Canceled,
}

export enum PaymentStatus {
  None = 1,
  Started,
  WaitingForConfirmation,
  Completed,
  Failed,
  Cancelled,
}

// Stores route names
export enum RouteNames {
  Booking = 'booking',
  Product = 'product',
  ChannelBooking = 'cbooking',
  ChannelProduct = 'cproduct',
  Home = 'home',
  UserSpace = 'user',
  PageNotFound = 'pageNotFound',
  ContactSupport = 'contactSupport',
  OrganizerUserSpace = 'orguser',
  UserBookings = 'userbookings',
  BookingSummary = 'bookingsummary',
  RefundBooking = 'refundbooking',
  RefundConfirmation = 'refundconfirmation',
  UnsubscribeCustomer = 'unsubscribe',
}

/**
 * Defines date format to use
 */
export enum DateTimeFormat {
  British,
  World,
}

export enum WeekStartB2C {
  Monday,
  Sunday,
}

export const WeekStartNameB2C: { [s: string]: string } = {
  [WeekStartB2C.Monday]: '1',
  [WeekStartB2C.Sunday]: '0',
};

export enum TimeFormatB2C {
  _12HourFormat,
  _24HourFormat,
}

export const TimeFormatNameB2C: { [s: string]: string } = {
  [TimeFormatB2C._12HourFormat]: '1',
  [TimeFormatB2C._24HourFormat]: '0',
};

export enum PaymentMethodsTypes {
  TWINT = 1,
  MASTERCARD,
  POSTEFINANCE,
  PAYPAL,
  VISA,
  POSTCARD,
  AMEX,
  INVOICE,
  BANK_TRANSFER,
  EXTERNAL_VOUCHER,
  CASH,
  PROMOCODE,
}

/**
 * Payment processors
 */
export enum PProcessors {
  Adyen = 'ADYEN',
  Wallee = 'WALLEE',
}

/**
 * Selling channels
 */
export enum SellingChannels {
  Discover = 'discover_smeetz',
  Widget = 'widget',
}

/**
 * Adyen payment status
 */
export enum AdyenStatus {
  Authorised = 'Authorised',
  Pending = 'Pending',
  Received = 'Received',
  Refused = 'Refused',
  Cancelled= 'Cancelled',
  NetworkError= 'NETWORK_ERROR',
  ThreeDS2TimedOut= 'ThreeDS2 timed out',
  cancel = 'CANCEL',
  implementationError = 'IMPLEMENTATION_ERROR',
  error = 'ERROR',
}

// smeetz tracking events
export enum EventType {
  availClicked = 'avail-click', // Selecting a day on the calendar.
  ticketAdded = 'tick-add',
  ticketRemoved = 'tick-rem',
  timeSlotClicked = 'ts-click',
}

export enum EventName {
  addToCart = 'AddToCart',
  initiateCheckout = 'InitiateCheckout',
  addPaymentInfo = 'AddPaymentInfo',
  checkoutPogress = 'CheckoutProgress',
  purchase = 'Purchase',
  pageView = 'PageView',
  viewContent = 'ViewContent',
}

export enum LocalStorageFields {
  SmtzLocalStorageTID = 'smtz-tid', // Smeetz tracking id.
}

export enum CategoryType {
  Retail = 20,
  SeatingPlan = 31,
  Membership = 14,
  Donation = 11,
}

export enum BookingPaymentStatus {
  PENDING = 1,
  COMPLETED,
  FAILED,
  CANCELLED,
}

export const BookingPaymentStatusName: { [s: number]: string } = {
  [BookingPaymentStatus.PENDING]: 'Pending Payment',
  [BookingPaymentStatus.COMPLETED]: 'Completed Payment',
  [BookingPaymentStatus.FAILED]: 'Failed Payment',
  [BookingPaymentStatus.CANCELLED]: 'Canceled Payment',
};

export enum RefundMethods {
  Voucher = 0,
  CreditCard,
}
export enum AcompteType {
  Percentage = 1,
  FlatValue = 2,
}

export enum PSCCancelStatus {
  NotCancelled = 0,
  Cancelled,
}

export enum TimeslotBookable {
  NotBookable = 0,
  Bookable,
}

export enum TimeslotFomoStrategy {
  NoStrategy = 1,
  TextCenter,
  BadgeCenter,
  BadgeTopLeft,
}

export enum TimeslotAvailability {
  InnerBox = 1,
  TopRight = 2,
  Text = 1,
  Badge = 2,
}

export enum MembershipSetting {
  NormalPrice = 1,
  RequiresMembership = 2,
  RequiresMembershipZero = 3,
  BackenWillNotApplyMembership = 4,
}

export enum MembershipRenewalSettingDurationType {
  Days = 1,
  Weeks = 2,
  Months = 3,
  Years = 4,
}

export enum IntegrationTypes {
  Native = 'native',
  Hybrid = 'hybrid',
}

export const DELAY_CALL_TIMEOUT = 3000;

export enum StrategyType {
  RANDOM = 1,
  LINEAR,
  EXP,
  TIME_STEP,
  REVENUE_OPTI,
  ATTENDANCE_OPTI,
  OCCUPANCY_STEP,
  RULES,
}


























import { Component, Vue } from 'vue-property-decorator';
// import VueRecaptcha from 'vue-recaptcha';
import LoginSignupLink from '@/components/presentational/LoginRegisterLink.vue';
import LocalAuth from '@/components/LocalAuth.vue';
import SocialAuth from '@/components/SocialAuth.vue';
import ModalNav from '@/components/presentational/ModalNav.vue';

@Component({
  components: {
    // VueRecaptcha,
    LoginSignupLink,
    LocalAuth,
    SocialAuth,
    ModalNav,
  },
})
export default class Signup extends Vue {
  // private onSubmit(): void {
  //   this.$refs.invisibleRecaptcha.execute();
  // }
}






















import { Vue, Component } from 'vue-property-decorator';
import { VFBLoginScope } from 'vue-facebook-login-component';
import { AuthModule } from '@/utils/storemodules';
import Debug from 'debug';

const debug = Debug('smeetz:auth');

@Component({
  components: {
    VFBLoginScope,
  },
})
export default class FacebookAuth extends Vue {
  private appId: string = process.env.VUE_APP_FACEBOOK_APP_ID;
  private fbLoginObject: any = {
    scope: 'email,public_profile',
    version: 'v10.0',
  };

  @AuthModule.Action('fbLogin') private fbLogin!: any;

  private async loggedIn(response: any) {
    debug('Facebook Log in button executed');

    // Do nothing if the user didn't grant access
    if (response.status !== 'connected') {
      return;
    }

    const { accessToken, userID } = response.authResponse;

    // login facebook
    await this.fbLogin({
      accessToken, userID,
    });
  }
}

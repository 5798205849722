




















import { Vue, Component } from 'vue-property-decorator';
import { UserModule, NModalModule } from '@/utils/storemodules';
import { openLegalPage } from '@/utils/helpers';
import { ModalStatus } from '@/models/definitions';

@Component
export default class NewResetPassword extends Vue {
  private email: string = '';

  private get userInfo() {
    return UserModule.user;
  }

  private mounted() {
    this.email = UserModule.userEmail || this.userInfo.emails[0].emailAddress;
  }

  private async resend() {
    await UserModule.resetPass(this.email);
  }

  private goToLegalPage() {
    openLegalPage.call(this);
  }

  private hideModal() {
    NModalModule.setStatus(ModalStatus.Hidden);
  }
}



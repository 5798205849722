









































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import FacebookLogin from '@/components/auth/NewFacebookLogin.vue';
import GoogleLogin from '@/components/auth/NewGoogleAuth.vue';
import SwissPass from '@/components/auth/NewSwissPass.vue';
import { email, required, maxLength, sameAs, minLength } from 'vuelidate/lib/validators';
import { openLegalPage } from '@/utils/helpers';
import { NModalModule, UserModule } from '@/utils/storemodules';
import { ModalStatus } from '@/models/definitions';
import { Validator } from '@/utils/validators';
import Debug from 'debug';
import { userIdentityTracking } from '@/utils/tracking';
import { IUserData } from '@/models/definitions';
import { getLanguageInt, validateOnDelay } from '@/utils/helpers';
import { Status } from '@/utils/status';
import Recaptcha from '@/components/Recaptcha.vue';
import { hideFbLogin, hideGmailLogin } from '@/utils/helpers';

const debug = Debug('smeetz:auth');

@Component({
  components: {
    FacebookLogin,
    GoogleLogin,
    SwissPass,
    Recaptcha,
  },
  validations: {
    firstname: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(255),
      invalid: Validator.validName,
    },
    lastname: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(255),
      invalid: Validator.validName,
    },
    email: {
      required,
      email,
      maxLength: maxLength(255),
    },
    password: {
      minLength: minLength(8),
      hasUpperAndLower: Validator.hasUpperAndLower,
      hasNum: Validator.hasNumbers,
      hasSpecial: Validator.hasSpecialCharacter,
    },
    confirmPassword: {
      sameAsPassword: sameAs('password'),
    },
  },
})
export default class NewRegistration extends Vue {
  // public authType: string = 'register';
  public emailExists: boolean = false;
  public isLoading: boolean = false;
  public enableSubmit: boolean = false;
  public recaptcha: boolean = false;

  private firstname: string = '';
  private lastname: string = '';
  private email: string = '';
  private password: string = '';
  private confirmPassword: string = '';
  private hideFbLogin: boolean = hideFbLogin();
  private hideGmailLogin: boolean = hideGmailLogin();

  private goToLegalPage() {
    openLegalPage.call(this);
  }

  // Watcher to ensure that email exists error is cleared
  @Watch('email')
  private onEmailChanged() {
    this.emailExists = false;
  }

  private goToLoginForm() {
    NModalModule.setStatus(ModalStatus.NewLogin);
  }

  private onSuccessLogin() {
    NModalModule.setStatus(ModalStatus.Hidden);
  }

  private get canSubmit() {
    return !this.$v.$invalid && this.recaptcha;
  }

  private forgottenPass() {
    NModalModule.setStatus(ModalStatus.NewForgotPassword);
  }

  private onInputChange(field: string, value: any) {
    validateOnDelay.call(this, field, value);
  }

  private async submit() {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }

    // Indicate that we are loading (on button)
    this.isLoading = true;

    userIdentityTracking({ email: this.email });
    // Collect user data
    const user: IUserData = {
      emailAddress: this.email,
      firstName: this.firstname,
      lastName: this.lastname,
      password: {
        first: this.password,
        last: this.confirmPassword,
      },
      languageId: getLanguageInt(this.$i18n.locale),
    };

    try {
      await UserModule.register(user);
      UserModule.setUserEmail(user.emailAddress);
    } catch (err) {
      const response = err.response;
      this.isLoading = false;

      if (!response) {
        debug(`Unexpected error in registering user ${err}`);
        return;
      } else if (Status[response.status] === 'Bad Request') {
        debug('Email was already taken');
        // If email exist - activate error
        this.emailExists = true;
        // Make field dirty
        this.$v.email.$touch();
        // this.enableSubmit = false;
        return;
      }

      debug('Unexpected error in registering user', err);
      return;
    }

    this.isLoading = false;
    // navigate to email confirmation form
    NModalModule.setStatus(ModalStatus.NewEmailConfirmation);
  }

  private captchaVerify() {
    this.recaptcha = true;
  }

  private captchaExpired() {
    this.recaptcha = false;
  }
}



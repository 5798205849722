












































import { Vue, Component, Prop } from 'vue-property-decorator';
import NewButton from '@/components/presentational/NewButton.vue';
import { SITE_URL } from '@/config/constants';

@Component({
  components: {
    NewButton,
  },
})
export default class OrganizerCard extends Vue {
  @Prop({ default: null }) private cardData: any;

  private getStrokeStyling(color: string) {
    return {
      'color': 'white',
      '-webkit-text-stroke-width': '1px',
      '-webkit-text-stroke-color': color,
    };
  }

  private goToOrganizer(organiserSlugName: string) {
    window.open(`${SITE_URL}/org/${organiserSlugName}`, '_blank');
  }
}

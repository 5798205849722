import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import modal from './modules/modal';
import booking from './modules/booking';
import rbooking from './modules/refundBooking';
import error from './modules/error';
import app from './modules/app';
import referrer from './modules/referrer';
import product from './modules/product/index';
import products from './modules/products/index';
import nbooking from './modules/newBooking/index';
import gbooking from './modules/gBooking/Index';
import nnoty from './modules/newError/index';
import Modal from './modules/modal/index';
import User from './modules/user/index';
import Region from './modules/region';
import Organizer from './modules/organizer';

Vue.use(Vuex);
// Vue.config.devtools = true;

export default new Vuex.Store({
  modules: {
    auth,
    modal,
    nmodal: Modal,
    booking,
    rbooking,
    error,
    app,
    referrer,
    product,
    products,
    gbooking,
    nbooking,
    nnoty,
    user: User,
    region: Region,
    organizer: Organizer,
  },
});

import { IUser } from '../definitions';

export interface IAuthState {
  user?: IUser;
  token?: string;
  errorFlag: boolean;
  error?: IAuthError;
  lang: string;
  langSet: boolean; // indicates if user changed language on the site
  customAuthToken?: string;
  customAuthEmail?: string;
}

export interface IAuthError {
  status: string;
  error: any;
}


export interface ILoginData {
  username: string;
  password: string;
}

export interface ILoginResponse {
  access_token: string;
  // Token expiration in seconds
  expires_in: number;
  token_type?: string;
  refresh_token: string;
}

export interface ILoginWithTokenResponse {
  token: string;
}

export interface IEmailConfData {
  email: string;
  languageId: number;
}

export interface IFBData {
  accessToken: string;
  userID: string;
}

export interface IFBDataApi {
  facebook_token: string;
  facebook_id: string;
  language_id: number;
}

export interface ISocialData {
  accessToken?: string;
  userID?: string;
  authCode?: string;
  swisspassCardId?: string;
  swisspassZip?: string;
}

export interface ISocialDataApi {
  facebook_token?: string | undefined;
  facebook_id?: string | undefined;
  language_id: number;
  auth_code?: string | undefined;
  swisspass_card_id?: string;
  swisspass_zip?: string;
  grant_type?: string;
}

export interface IPassReset {
  resetToken: string;
  userId: string;
}

export interface IPassChange extends IPassReset {
  password: {
    first: string;
    last: string;
  };
}

export interface IDeleteAccount {
  cancellationToken: string;
  userId: string;
}

export interface IPhoneData {
  countryCode: string;
  phoneNumber: string;
}

export interface IUserInformations {
  groupId: number;
  firstName: string;
  lastName: string;
  email: string;
  uuid?: string;
  service?: string;
  iat?: number;
}

export enum CustomAuthType {
  Keycloak = '5',
}




































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Mixins } from 'vue-mixin-decorator';
import NewButton from '@/components/presentational/NewButton.vue';
import ImageField from '../components/ImageTypeField.vue';
import DateHelperMixin from '@/mixins/DateHelper';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import CountryCodes from 'libphonenumber-js/metadata.full.json';
import {
  QuestionType,
  InformationType,
  IPostBookingField,
  IPostBookingFile,
} from '@/models/store/booking';
import {
  getBookingFieldFile,
  patchBookingField,
  postBookingFile,
} from '@/api/booking';
import { AppModule, NBookingModule } from '@/utils/storemodules';
import { Validator } from '@/utils/validators';

@Component({
  components: {
    NewButton,
    ImageField,
    VuePhoneNumberInput,
  },
})
export default class UserSpaceField extends Mixins<DateHelperMixin>(
  DateHelperMixin,
) {
  private get isMobile() {
    return AppModule.isMobile;
  }

  public Validator = Validator;
  public QuestionType = QuestionType;
  public InformationType = InformationType;

  public CountryCodes: any = {};
  private isEditField = false;


  @Prop({ default: {} }) private bookingField!: any;
  @Prop({ default: false }) private isEdit!: boolean;
  @Prop({ default: false }) private save!: boolean;
  @Prop({ default: false }) private validated!: boolean;
  @Prop({ default: false }) private cancel!: boolean;


  private rules: any[] = [];
  private tmpBookingAnswer: any = null;
  private loadingBtn: boolean = false;
  private formattedAnswerFields: number[] = [3, 4, 6];
  private url: string = '';
  private imgSrc: string = '';

  private validPhone = false;
  private e164Phone = '';
  private countryCode = 'CH';

  private formatArray(array: string[]) {
    return array.join(', ');
  }

  private translateDate(date: string, onlyDate: boolean = false) {
    return this.currentDate(date, onlyDate);
  }

  private async created() {
    window.addEventListener('keydown', this.onKeydown);
    // tmpBookingAnwser initalisation
    if (this.bookingField.questionType === QuestionType.file) {
      this.tmpBookingAnswer = null;
    } else if (
      this.bookingField.questionType === QuestionType.list &&
      !this.bookingField.answer
    ) {
      this.tmpBookingAnswer = [];
    } else {
      this.tmpBookingAnswer = this.bookingField.answer;
    }
    // required fields logic
    if (this.bookingField.validation === true) {
      const requiredMessage = this.$t('validator.required-field');
      switch (this.bookingField.questionType) {
        case QuestionType.file:
          this.rules.push((val: File) => val != null || requiredMessage);
          break;
        case QuestionType.list:
          this.rules.push(
            (val: string[]) => Validator.isArrayEmpty(val) || requiredMessage,
          );
        default:
          this.rules.push((val: string) => !!val || requiredMessage);
          break;
      }
    } else if (this.bookingField.questionType === QuestionType.file) {
      this.rules.push(
        (val: any) =>
          val == null ||
          val instanceof File ||
          this.$t('validator.required-field'),
      );
    }
    // form validation logic
    switch (this.bookingField.informationType) {
      case InformationType.Phone:
        this.rules.push(
          () =>
            this.validPhone || this.$t('auth.field-phone-number-invalid'),
        );
        break;
      case InformationType.Tax:
        this.rules.push(
          (val: string) =>
            Validator.hasOnlyNumbers(val) || this.$t('auth.field-tax-invalid'),
        );
        break;
      case InformationType.FirstName:
        this.rules.push(
          (val: string) =>
            Validator.validName(val) || this.$t('auth.field-first-name-invalid'),
        );
        break;
      case InformationType.LastName:
        this.rules.push(
          (val: string) =>
            Validator.validName(val) || this.$t('auth.field-last-name-invalid'),
        );
        break;
      case InformationType.City:
        this.rules.push(
          (val: string) =>
            Validator.validateCity(val) || this.$t('auth.field-city-invalid'),
        );
        break;
      case InformationType.Zip:
        this.rules.push(
          (val: string) =>
            !Validator.hasSpecialCharacter(val) ||
            this.$t('auth.field-zip-code-invalid'),
        );
        break;
    }
    // getting the url of file types
    if (this.bookingField.questionType === QuestionType.file && this.bookingField.answer) {
      const bookingToken = NBookingModule.bookingToken;
      const bookingId = NBookingModule.bookingId;
      const bookingField = this.bookingField;
      const { url } = await getBookingFieldFile(
        bookingId,
        bookingToken,
        bookingField,
      );
      this.url = url;
      if (this.bookingField.informationType === InformationType.Picture) { this.setImgSrc(url); }
    }

  }
  private mounted() {
    const listType = this.bookingField.questionType === QuestionType.list;
    // for quasar options attribute
    if (listType) {
      if (!this.bookingField.list[0].label) {
        this.bookingField.list = (this.bookingField.list as string[]).map(
          (item) => {
            return {
              label: item,
              value: item,
            };
          },
        );
      }
    }
    if (this.bookingField.informationType === InformationType.Phone) {
      this.CountryCodes = CountryCodes;
      let countryCodeChanged = false;
      const phoneNumber = this.bookingField.answer;

      for (let i = 1; i <= 3; i++) {
        if (countryCodeChanged || !phoneNumber) {
          break;
        }
        // ex: +213673710705
        // remove the + symbole
        // check for 2 & 21 & 213 possible countryCallingCodes through the loop
        const countryCallingCode = phoneNumber.substr(1, i);
        const possibleCountryCodes = this.CountryCodes.country_calling_codes[countryCallingCode];
        // check if the countryCallingCode is valid by checking the length of the remaining string
        if (possibleCountryCodes) {
          for (const countryCode of possibleCountryCodes) {
            // get the possible phone number lengths
            const PossiblePhoneLengthList: number[] = this.CountryCodes.countries[countryCode][3];
            // check if the phone number length is valid if we use this countryCode
            if (PossiblePhoneLengthList.find((item) => item === phoneNumber.slice(i + 1).length)) {
              this.countryCode = countryCode;
              countryCodeChanged = true;
              break;
            }
          }
        }

      }
    }
  }
  private onPhoneUpdate(phoneObj: {isValid: boolean, e164: string,
    phoneNumber: string, countryCode: string}) {
    const { isValid, e164, phoneNumber, countryCode } = phoneObj;
    this.countryCode = countryCode;
    this.validPhone = isValid;
    // When setting 1 digit, e164 is undefined, so we use phoneNumber
    this.e164Phone = e164 || phoneNumber;
  }

  private onKeydown(event: any) {
      const kc = event.key === 'Enter';
      if (kc) {
        this.onSubmit(true);
      }
  }

  private beforeDestroy() {
    window.removeEventListener('keydown', this.onKeydown);
  }

  @Watch('cancel')
  private cancelChange(newVal: boolean) {
    if (newVal) {
      this.tmpBookingAnswer =
      this.bookingField.questionType === QuestionType.file
        ? null
        : this.bookingField.answer;
    }
  }

  @Watch('save')
  private onSubmit(newVal: any) {
    if (newVal) {
      if (this.$refs.form) {
        (this.$refs.form as Vue & { validate: () => Promise<boolean> })
          .validate()
          .then((success) => {
          if (success) {
            this.$emit('onValidate');
          } else {
            this.$emit('blockSave');
          }
        });
      } else {
        this.$emit('onValidate');
      }
    }
  }

  @Watch('validated')
  private onSave(newVal: any) {
    if (newVal) {
      this.updateChange();
    }
  }

  private updateImage(file: File) {
    this.tmpBookingAnswer = file;
    this.updateChange();
  }

  private async setImgSrc(url: string) {
    const reader = new FileReader();
    const response = await fetch(url);
    const data = await response.blob();
    const metadata = {
      type: 'image/jpg',
    };
    reader.onload = (event) => {
      this.imgSrc = (event.target as FileReader).result as string;
    };
    const file = new File([data], 'image.jpg', metadata);
    reader.readAsDataURL(file);
  }

  private async updateChange() {
    this.loadingBtn = true;
    if (this.tmpBookingAnswer === this.bookingField.answer) {
      this.loadingBtn = false;
      return;
    }
    if (this.bookingField.informationType === InformationType.Phone) {
      this.bookingField.answer = this.e164Phone;
    } else { this.bookingField.answer = this.tmpBookingAnswer; }

    const bookingId = NBookingModule.bookingId;
    const bookingToken = NBookingModule.bookingToken;
    const bookingField: IPostBookingField | IPostBookingFile = {
      fieldId: this.bookingField!.fieldId,
      answer: this.bookingField!.answer!,
    };
    if (this.bookingField.pssId) {
      bookingField.pss = this.bookingField.pssId;
    }
    // for File type custom field
    if (this.bookingField.answer instanceof File) {
      await postBookingFile(
        bookingId,
        bookingToken,
        bookingField as IPostBookingFile,
      );
      await new Promise((resolve) => setTimeout(resolve, 500));
      const { url } = await getBookingFieldFile(
        bookingId,
        bookingToken,
        this.bookingField,
      );
      this.url = url;
      if (this.bookingField.informationType === InformationType.Picture) { this.setImgSrc(url); }
    } else {
      const data: IPostBookingField[] = [];
      data.push(bookingField);
      patchBookingField(bookingId, bookingToken, data);
    }

    this.loadingBtn = false;
  }



}

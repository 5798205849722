








import { Vue, Component, Prop } from 'vue-property-decorator';
import { NModalModule } from '@/utils/storemodules';
import { ModalModule } from '@/utils/storemodules';
import { ModalStatus } from '@/models/definitions';

@Component({})
export default class SwissPassAuth extends Vue {
  @Prop({default: 'register'}) private authType!: string;

  // TODO: NEED TO MAKE RETURN ONSUCCESS THEN USER END SWISS PASS MODAL ACTION

  private init() {
    NModalModule.setStatus(ModalStatus.SwissPass);
    NModalModule.setAuthType(this.authType);
  }
}

import Vue from 'vue';
import Debug from 'debug';
import { Mixin } from 'vue-mixin-decorator';
import { inIframe, closeIframe, shrinkIfChartIsPresent, closeNativeIntegration } from '@/utils/iframe';
import { ProductModule, ReferrerModule, NBookingModule, AppModule } from '@/utils/storemodules';
import { WidgetOps } from '@/models/iframe';
import { BookingSteps } from '@/store/modules/newBooking/constants';
import { areRequiredSecondPageAddonsSelected, openTheAddonShowRelatedToCurrentFetchedCategory } from '@/utils/addon';

const debug = Debug('smeetz:referrer');

@Mixin
export default class BookingDone extends Vue {
  /* tslint:disable-next-line*/
  // @BookingModule.Getter(GET_IS_DP_BOOKING) private _isDPBooking!: boolean;
  // This function is called once the user finishes on
  // from the booking success page.
  public done() {
    if (inIframe()) {
      return closeIframe();
    }

    if (AppModule.isNativeIntegration) {
      closeNativeIntegration();
      return;
    }

    // redirect incase we came from an external site
    if (ReferrerModule.isExternalReferrer) {
      debug('redirecting to', ReferrerModule.getReferrerSite);
      /* tslint:disable-next-line*/
      return window.location.href = ReferrerModule.customUrl ? ReferrerModule.customUrl : ReferrerModule.getReferrerFull;
    }

    // return to product page
    debug('Going to product page');
    NBookingModule.clear();
    this.$router.push(`/`);
  }

  public close() {

    if (inIframe()) {
      // If Piazza grande seating plan we show it and we haven't selected
      // all of it's required addons
      if (
        !areRequiredSecondPageAddonsSelected() &&
        (ProductModule.piazzaShowsAddons.length > 0)
      ) {
        const piazzaSeatingPlan = openTheAddonShowRelatedToCurrentFetchedCategory();
        if (piazzaSeatingPlan) {
          this.$emit('open', piazzaSeatingPlan);
          return;
        }
      }

      // for piazza grande, if we selected what's required, let's end it here
      // this way, we can select other shows
      if (ProductModule.piazzaShowsAddons.length > 0) {
        ProductModule.setAddonTicketsShowsPrices({});
        ProductModule.setPizzaShowsAddons([]);
        ProductModule.setRequiredNumberPiazzaSeats(0);
        ProductModule.setRequiredNumberPiazzaSeatsPerCategory([]);
        this.$emit('open', null);
      }

    }

    const status = this.$route.params.status;
    if (inIframe()) {

      // If the widget type is cart make sure to go to the first step order if we have
      // second step addons
      if (AppModule.isCartWidget && !ProductModule.hideAddons) {
        NBookingModule.stepBack();
      }

      const success = (status === 'success');
      const failure = (status === 'failure');
      const widgetId = AppModule.widgetId;
      const originalUrl = ReferrerModule.widgetOrigUrl;

      // Hard widget close on booking confirmation
      if (success) {
        debug('We have a hard close on success');
        closeIframe({
          smtzOp: WidgetOps.CloseWidget,
          wId: widgetId,
          how: 'd',
        });
        return;
      }

      // Make sure we clear the list of non selected tickets on cart
      if (widgetId) {

        const bookingRes = NBookingModule.bookingRes;
        const categories = bookingRes && bookingRes.bookingRecap && bookingRes.bookingRecap.categories;
        if (AppModule.isCartWidget) {
          debug('soft close');
          shrinkIfChartIsPresent();
          closeIframe();
          NBookingModule.stepTo(BookingSteps.Order);
          ReferrerModule.setVisibleCategories();

          // Make sure that we maintian selected tickets open
          // LEAVE COMMENTED
          // if (categories && categories.length) {
          //   for (const category of categories) {
          //     ReferrerModule.addVisibleCategory(category.categoryId);
          //   }
          // }
          return;
        }

        // make sure that we clear all bookings if present
        if (categories && categories.length) {
          NBookingModule.cancelBooking().then(() => {

            // Perform a hard close on failure
            if (failure) {
                // hard close on failure
              closeIframe({
                smtzOp: WidgetOps.CloseWidget,
                wId: widgetId,
                how: 'd',
              });
              return;
            }

            // In case we don't have a failure, make sure that we restart
            // from the initial page of booking
            if (originalUrl) {
              debug('Soft close after cancelling tickets');
              closeIframe();
              location.href = originalUrl;
            }
          });
        } else if (originalUrl) {
          debug('No tickets & closing');
          closeIframe();
          location.href = originalUrl;
          return;
        }
      } else {
        debug('No widget id & closing');
        closeIframe();
      }

      return;
    }

    // handle native integration
    if (AppModule.isNativeIntegration) {
      closeNativeIntegration();
      return;
    }

    // / redirect incase we came from an organizer site
    if (ReferrerModule.topLevelDomainUrl) {
      window.open(`${ReferrerModule.topLevelDomainUrl}`, '_self');
      return;
    }

    // redirect incase we came from an external site
    if (ReferrerModule.isExternalReferrer) {
      debug('redirecting to', ReferrerModule.getReferrerSite);
      return window.location.href = ReferrerModule.getReferrerFull;
    }

    const productShortUrl = ProductModule.productShortUrl;
    debug('Status', status);
    if (status !== 'success') {
      NBookingModule.cancelBooking().then(() => {
        NBookingModule.clear();
      });
    } else {
      NBookingModule.clear();
    }
    this.$router.push(`/product/${productShortUrl}`);
  }
}

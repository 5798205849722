



























































import { Vue, Component, Mixins } from 'vue-property-decorator';
import Select from '@/components/presentational/NewSelect.vue';
import { EVSelect } from '@/models/events';
import NewButton from '@/components/presentational/NewButton.vue';
import RetailMixin from '@/mixins/Retail';

@Component({
  components: {
    Select,
    NewButton,
  },
})
export default class AddonFullCard extends Mixins<RetailMixin>(RetailMixin) {
  private EVSelect = EVSelect;
  private mounted() {
    this.isFullCartAddon = true;
  }

  private closeCard() {
    if (this.isDirty) {
      this.selectedQuantity = 0;
      this.selectedKey = 0;
    }
    this.isSoldOut = false;
    this.$emit('close');
  }
}














/**
 * Renders slots
 *
 * @Prop slots {ITimeslotsEntity[]}: Array of slots to be rendered
 * @Prop ticket {ITicket}: ticket containing these slots;
 */
import { Vue, Component, Prop } from 'vue-property-decorator';
import dayjs from 'dayjs';
import OneDaySlots from './OneDaySlots2.vue';
import { ITicket, ITimeslotsEntity } from '../../../models/store/booking';
import { dateSortComp } from '../../../utils/helpers';
import {ESlotsHours} from '@/models/events';

@Component({
  components: {
    OneDaySlots,
  },
})
export default class Slots extends Vue {
  @Prop() public ticket!: ITicket;
  @Prop() public slots!: ITimeslotsEntity[];
  @Prop({default: ''}) public hour!: string;
  @Prop({default: ''}) public ticketError!: string;

  private ESlotsHours = ESlotsHours;

  /**
   * Groups slots by day
   */
  get slotsByDay(): Array<{day: string, slots: ITimeslotsEntity[]}> {
    const slotObj: {[s: string]: ITimeslotsEntity[]} = {};

    // Collector array of objects
    const dailySlots: Array<{day: string, slots: ITimeslotsEntity[]}> = [];

    for (const slot of this.slots) {
      const dayString = dayjs(slot.startDateTime).format('DD-MM-YYYY');

      // Init array of that day if not present
      if (!slotObj[dayString]) {
        slotObj[dayString] = [];
      }

      slotObj[dayString].push(slot);
    }

    // transform object to array
    for (const day in slotObj) {
      if (!day) {
        continue;
      }

      dailySlots.push({day, slots: slotObj[day]});
    }
    return dailySlots.sort((daySlot1, daySlot2) => {
      return dateSortComp(daySlot1.day, daySlot2.day);
    });
  }

  private selectHour(hour: string) {
    this.$emit(ESlotsHours.HourSelected, hour);
  }
}

const documentHTML: HTMLElement = document.getElementsByTagName('html')[0];
// const documentBody: HTMLElement = document.getElementsByTagName('body')[0];
const stopScrollClass = 'stop-scrolling';
const hideScrollClass = 'hide-scroll';

export function stopScrolling() {
  documentHTML.classList.add(stopScrollClass);
}

export function allowScrolling() {
  documentHTML.classList.remove(stopScrollClass);
}

export function hideScrollBar() {
  documentHTML.classList.add(hideScrollClass);
}

export function showScrollBar() {
  documentHTML.classList.remove(hideScrollClass);
}

export function setTransparentSite() {
  documentHTML.style.background = 'transparent';
}

export function setNonTransparentSite() {
  documentHTML.style.background = '';
}

// Common styles required by embeddable widgets
export function setEmbedableWidgetStyles() {
  setTransparentSite();
  hideScrollBar();
}

export function unsetEmbedableWidgetStyles() {
  setNonTransparentSite();
  showScrollBar();
}

export function enableQuasarStyling() {
  const body: HTMLElement = document.getElementsByTagName('body')[0];

  body.classList.add('smtz-q-app');
}

export function disableQuasarStyling() {
  const body: HTMLElement = document.getElementsByTagName('body')[0];

  body.classList.remove('smtz-q-app');
}

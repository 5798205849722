


















































































































































import { Vue, Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import Timeout from 'await-timeout/dist/es5';
import Methods from './PaymentMethods.vue';
import { IBookingTransactionParams, ICustomPaymentMethod } from '@/models/booking';
import { IProduct } from '@/models/store/product';
import { AppModule, NBookingModule, NNoty, OrganizerModule, ProductModule, ReferrerModule } from '@/utils/storemodules';
import { IPreBookingRes, IBookingPayments, IPaymentMethod, IConfirmBookingData, ITicket, TicketType, ChargeFlow } from '@/models/store/booking';
import { required, maxLength } from 'vuelidate/lib/validators';
import { postBookingTransactionAdyen, loadPaymentMethods, authorizedSession } from '@/api/booking';
import { confirmBooking, bookingTransactionStripe, postBookingFree } from '@/api/booking';
import { HttpStatus } from '@/utils/status';
import VueScript from 'vue-script2';
import Debug from 'debug';
import NewButton from '@/components/presentational/NewButton.vue';
import PromoInput from './PromoInput.vue';
// import AccessCode from '@/components/booking/AccessCode.vue';
import { bookingFlowPaymentTracking, bookingFlowCheckoutProgress } from '../../../utils/tracking';
import { getPrimaryColor } from '@/utils/colors';
import { DEFAULT_PAYMENT_METHOD, ORG_PRIMARY_COLOR, SITE_URL } from '@/config/constants';
import { destroyCart, inIframe } from '@/utils/iframe';
import { AdyenStatus, PaymentMethodsTypes, QueryParameters, BookingPaymentStatusName, BookingPaymentStatus } from '@/models/enums';
import { IdealTestIssuers, IdealProdIssuers } from '@/models/payments';
import { MembershipType, MemType } from '@/models/memberships';
import { SiteLangs } from '@/models/site';
import { getFbPixelTracking, loadCssFile } from '@/utils/helpers';
import { isInProduction } from '@/utils/platform';
import AdyenCheckout from '@adyen/adyen-web';
import { bookingDecorateErrorMessage } from '@/utils/debug';
import { disableQuasarStyling, enableQuasarStyling } from '@/utils/styles';
import { LoggerService, postLogCall } from '@/services/log';
import { throttle } from 'quasar';
import { IOpenPageOp, IWidgetOp, WidgetOps } from '@/models/iframe';
import TicketHelperMixin from '@/mixins/TicketHelper';
import { isSidePanelWidget } from '@/utils/helpers';

const debug = Debug('smeetz:booking');
const containerId = 'payment-form';

@Component({
  components: {
    NewButton,
    PromoInput,
    // AccessCode,
  },
})
export default class PaymentStep extends
Mixins<TicketHelperMixin>(TicketHelperMixin) {
  private paymentMethods: string[] = [];
  private canSubmit: boolean = false;
  private threeDSInProgress: boolean = false;
  private paymentChoose: boolean = true;
  private cardComponent: any = null;
  private method: ICustomPaymentMethod | null = null;
  private currentPaymentMethods: ICustomPaymentMethod[] = [];
  private bookingToken: string = '';
  private bookingId: number = -1;
  private handler: any;
  private timeout: number | undefined;
  private loading: boolean = false;
  private isMembership: boolean = false;
  private isPromoCodePayment: boolean = false;
  private firstPaymentMethod: IPaymentMethod | null = null;
  private paymentMethodsRespose: IBookingPayments | null = null;
  private returnURL: string = '';
  private acceptAgreement: boolean = false;
  private consentErr: boolean = false;
  private sessionId: string = '';
  private availablePaymentMethods: ICustomPaymentMethod[] = [
    {
      name: 'TWINT',
      src: 'img/icons/twint_logo.png',
      desc: 'TWINT',
    },
    {
      name: DEFAULT_PAYMENT_METHOD,
      src: 'img/icons/card.png',
      desc: DEFAULT_PAYMENT_METHOD,
    },
    {
      name: 'postefinance',
      src: 'img/icons/postfinance_e_finance_logo.png',
      desc: 'PostFinance E-Finance',
    },
    {
      name: 'PAYPAL',
      src: 'img/icons/paypal_logo.png',
      desc: 'PayPal',
    },
    {
      name: 'POSTCARD',
      src: 'img/icons/postfinance_logo.png',
      desc: 'PostFinance',
    },
    {
      name: 'INVOICE',
      src: 'img/icons/invoice.png',
      desc: 'new-booking-flow.payment.invoice',
    },
    {
      name: 'BANK_TRANSFER',
      src: 'img/icons/bank_transfer.png',
      desc: 'new-booking-flow.payment.bank',
    },
    {
      name: 'EXTERNAL_VOUCHER',
      src: 'img/icons/voucher.png',
      desc: 'new-booking-flow.payment.voucher',
    },
    {
      name: 'CASH',
      src: 'img/icons/cash.png',
      desc: 'new-booking-flow.payment.cash',
    },
    {
      name: 'APPLEPAY',
      src: 'img/icons/applepay.png',
      desc: 'Apple Pay',
    },
    {
      name: 'GOOGLEPAY',
      src: 'img/icons/google-pay-mark_800.svg',
      desc: 'Google Pay',
    },
    {
      name: 'IDEAL',
      src: 'img/icons/ideal-logo.png',
      desc: 'Ideal',
    },
    {
      name: 'BANCONTACT',
      src: 'img/icons/bancontact-logo.png',
      desc: 'Bancontact',
    },
    {
      name: 'BANCONTACT_MOBILE',
      src: 'img/icons/bancontact-mobile-logo.png',
      desc: 'Bancontact mobile',
    },
  ];

  private nonWalleePayments = [
    'INVOICE', 'BANK_TRANSFER', 'EXTERNAL_VOUCHER', 'CASH',
  ];

  private nonAutoRenewalMembershipPayments = [
    'IDEAL', 'TWINT', 'PAYPAL', 'WECHATPAY_WEB',
  ];

  private openBlockedPaymentDialog: boolean = false;
  // private get requiresAccessCode() {
  //   return NBookingModule.isAccessCodeRequired;
  // }

  private get isDesktop() {
    return AppModule.isDesktop;
  }

  private get isMobile() {
    return AppModule.isMobile;
  }

  private get currency() {
    return ProductModule.productCurrency;
  }

  private get totalPrice() {
    return NBookingModule.recapInfo.total;
  }

  private get taxes() {
    return NBookingModule.recapInfo.taxes;
  }

  private get subTotal() {
    return NBookingModule.recapInfo.subTotal;
  }

  private get hasAutoRenewalTicket() {
    return !!this.ticketsPrices.find((cat) => this.autoRenewal(cat.ticket));
  }

  private isMethodNameDisplayed(paymethod: ICustomPaymentMethod): boolean {
    // Payment method name and icon are displayed if it is not a promo code,
    // or it is a promocode but not selected yet
    return paymethod.type !== this.getPromoCodePaymentType()
    || (paymethod.type === this.getPromoCodePaymentType() && !this.isPromoCodePayment);
  }

  // The payment can become free with 100% promo code
  private get isFree() {
    return this.totalPrice === 0;
  }

  private get bookingRef() {
    return NBookingModule.bookingRes?.bookingRef;
  }

  private setConsentErr() {
    this.consentErr = !this.acceptAgreement;
  }


  private get getShowConsentCheckbox() {
    const ticketsIds = NBookingModule.orderRecap.map((ticket) => ticket.categoryId);
    const ticketTypes: ITicket[] = [];
    let consentType: string = '';

    for (const ticketId of ticketsIds) {
      // retreives the tikcets that we have on booking recap from the filtredTickets
      // Filtered tickets are tickets that the user sees on the booking flow,
      // because there are invisible tickets as well.
      NBookingModule.filteredTickets.forEach((t) => t.categoryId === ticketId && ticketTypes.push(t));
    }

    for (const ticket of ticketTypes) {
      if (ticket.type === TicketType.Membership) {
        consentType = 'consent.membership';
        break;
      } else if (ticket.chargeFlowType !== ChargeFlow.Direct) {
        consentType = 'consent.delayed-pay';
      }
    }

    return consentType;
  }
  /**
   * Returns a string representation of the promo code discount applied
   */
  get promoApplied(): string {
    const { promoDiscount, promoName } = NBookingModule;
    if (!promoDiscount) {
      return '';
    }

    return `-${promoDiscount.toFixed(2)} (${promoName})`;
  }

    private get disabledBtn(): boolean {
    // Adyen payment case
    if (this.isAdyenPayment) {

      // Handle first if we have to give our consent
      if (this.getShowConsentCheckbox) {
        // Enable "buy now" button when user can submit(or product is free)
        // and has accepted the agreement.
        if ((this.canSubmit || this.isFree) && this.acceptAgreement) {
          return false;
        }
      } else {
        // Enable "comfirm" button when product is free
        if (this.isFree || this.canSubmit) {
          return false;
        }
      }

      // Disable button when user can't submit and /or has not accepted the agreement.
      // and when the product is not free
      return true;

      // Non adyen payment case
    } else if (!this.isAdyenPayment) {
      // Enable "buy now" button when user can submit
      if (this.canSubmit) {
        return false;
      }
      // Enable "comfirm" button when product is free
      if (this.isFree) {
        return false;
      }
      // Disable "buy now" button when user can't submit or the product is not free.
      return true;

    } else {
      // Enable button.
      return false;
    }
  }

  private created() {
    // To avoid that the pay function will be called twice
    this.pay = throttle(this.pay, 2000);
  }

// Returns the custom link and custom text accodring to user's language.
  private customLinkWithLang() {
    if (ReferrerModule.customLinksData) {
      const custTexts = ReferrerModule.customLinksData.customLinksText;
      const custUrls = ReferrerModule.customLinksData.customLinksUrl;
      let textContainer: string | null = null;
      let urlContainer: string | null  = null;

      for (const text of custTexts) {
        const localLang = this.$i18n.locale;
        const custText = text[localLang as SiteLangs];
        if (custText) {
          textContainer = custText;
        }
      }

      for (const url of custUrls) {
        const localLang = this.$i18n.locale;
        const custUrl = url[localLang as SiteLangs];

        if (custUrl) {
          urlContainer = custUrl;
        }
      }

      if (textContainer && urlContainer ) {
        return {
          custText: textContainer,
          custUrl: urlContainer,
        };
      }
    }
  }

  private getPromoCodePaymentType() {
    return PaymentMethodsTypes.PROMOCODE;
  }

  get isRequestToBook(): boolean {
    return NBookingModule.isRequestToBook;
  }

  // Are we processing with Adyen
  get isAdyenPayment(): boolean {
    return OrganizerModule.canProcessAdyen;
  }

  get organizerWalleePaymentMethods(): string[] {
    return OrganizerModule.organizerWalleePaymentMethods;
  }

  get params() {
    if (!this.method) {
      return;
    }
    const params: IBookingTransactionParams = {
      paymentMethod: this.method.name,
      bookingToken: this.bookingToken,
      organiser_wallee: this.organizerWalleePaymentMethods.includes(this.method.name) ? 1 : undefined,
    };
    params.qs = this.paramsQs;
    return params;
  }

  get paramsQs() {
    if (!this.method && !this.isFree) {
      return;
    }
    const { _ga } = this.$route.query;
    const sitePrimaryColor = getPrimaryColor();
    const { fbPixelId, gaId, orgId } = OrganizerModule;
    const { gtmId } = ReferrerModule;

    let qs: string = '';

    if (_ga) {
      qs = `_ga=${_ga}`;
    }

    if (sitePrimaryColor !== ORG_PRIMARY_COLOR) {
      qs = qs ?
        `${qs}&ocol=${escape(sitePrimaryColor)}` :
        `ocol=${escape(sitePrimaryColor)}`;
    }
    if (inIframe()) {
      if (AppModule.isCartWidget) {
        qs = qs ?
          `${qs}&wt=cart` :
          `wt=cart`;
      }
      if (AppModule.widgetId) {
        qs = qs ?
          `${qs}&wid=${AppModule.widgetId}` :
          `wid=${AppModule.widgetId}`;
      }
      if (ReferrerModule.customLinksData) {
        const custUrlandText = this.customLinkWithLang();
        if (custUrlandText) {
          qs = qs ?
          `${qs}&custText=${custUrlandText.custText}&custUrl=${custUrlandText.custUrl}` :
          `custText=${custUrlandText.custText}&custUrl=${custUrlandText.custUrl}`;
        }
      }
    }

    if (gaId) {
      qs = qs ?
        `${qs}&${QueryParameters.GAId}=${gaId}` :
        `${QueryParameters.GAId}=${gaId}`;
    }

    if (fbPixelId) {
      qs = qs ?
        `${qs}&${QueryParameters.FBId}=${fbPixelId}` :
        `${QueryParameters.FBId}=${fbPixelId}`;
    }

    if (gtmId) {
      qs = qs ?
        `${qs}&${QueryParameters.GTMID}=${gtmId}` :
        `${QueryParameters.GTMID}=${gtmId}`;
    }

    if (orgId) {
      qs = qs ?
        `${qs}&${QueryParameters.ORGId}=${orgId}` :
        `${QueryParameters.ORGId}=${orgId}`;
    }

    if (getFbPixelTracking()) {
      qs = qs ?
        `${qs}&${getFbPixelTracking()}` :
        `${getFbPixelTracking()}`;
    }

    if (AppModule.isNativeIntegration) {
      qs = qs ?
        `${qs}&${QueryParameters.IntegrationType}=${AppModule.integration}` :
        `${QueryParameters.IntegrationType}=${AppModule.integration}`;
    }

    // set language to make sure that it is set on the confirmation
    // page.
    qs = qs ?
    `${qs}&lang=${this.$i18n.locale}` :
    `lang=${this.$i18n.locale}`;

    return qs;
  }

  get showPayButton() {
    if (!this.method) {
      return true;
    }
    const paymentWithAdyenPayButton = ['GOOGLEPAY', 'APPLEPAY', 'TWINT', 'IDEAL', 'BANCONTACT_MOBILE'];
    return !paymentWithAdyenPayButton.includes(this.method.name);
  }

  private async handlePay() {
    this.loading = true;
    await this.$nextTick();
    this.pay();
  }

  private async makeChoose(method: ICustomPaymentMethod) {
    if (this.loading) {
      return;
    }
    this.method = method;
    LoggerService.info(`Payment method: ${method.name}`);

    // Get a reference to the payment form div
    const previousPayment = document.getElementById(containerId);

    // Remove all child nodes from the div
    if (previousPayment) {
      while (previousPayment.firstChild) {
        previousPayment.removeChild(previousPayment.firstChild);
      }
    }

    if (this.cardComponent) {
      this.cardComponent.unmount();
      // Make sure we null the card component so that we don't
      // consider this as an adyen payment afterwards.
      // L3-2692
      this.cardComponent = null;
    }

    if (method.type === PaymentMethodsTypes.PROMOCODE) {
      this.isPromoCodePayment = true;
      return;
    }

    this.paymentChoose = false;
    this.loading = true;

    if (inIframe() && method.name === 'APPLEPAY' && window.ApplePaySession) {
      LoggerService.info('APPLEPAY inside an iframe');
      if (AppModule.isCartWidget) {
        LoggerService.info(`Destroy cart before redirect to Discover`);
        destroyCart();
      }
      this.retryPaymentInSmeetzDiscover();
      this.loading = false;
      return;
    }

    // INVOICE, CASH, BANK_TRANSFER and EXTERNAL_VOUCHER don't require wallee
    if (this.nonWalleePayments.includes(this.method.name)) {
      this.canSubmit = true;
      this.loading = false;
      return;
    }

    if (this.isAdyenPayment && !this.organizerWalleePaymentMethods.includes(method.name)) {

      const url = new URL(window.location.href);
      const rateId = url.searchParams.get('rateId') || '';

      // TODO BUD-8632 4-1
      // Build return url
      const product = ProductModule.product;
      const productId = product ? product.id : null;
      const returnURL = `${SITE_URL}/${this.$i18n.locale}/product/${productId}/booking/confirmation/success`
        + `?bookingId=${this.bookingId}&bookingToken=${this.bookingToken}&${this.paramsQs}&reload=true`;

      this.returnURL = rateId !== '' ? returnURL + `&rateId=${rateId}` : returnURL;

      const { membership } = NBookingModule;
      if (membership && membership.type === MembershipType.RequiredTicketsMembership) {
        this.returnURL = returnURL + `&reqMemId=${membership.id}`;
      }

      // Create Adyen payment session
      try {
        const data = await postBookingTransactionAdyen({
          bookingId: this.bookingId,
          bookingToken: this.bookingToken,
          returnUrl: returnURL,
          paymentMethod: method.name,
        });

        this.sessionId = data.id;
        // Fetch adyen js & css
        // loadCssFile('https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/5.0.0/adyen.css');
        // await VueScript.load('https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/5.0.0/adyen.js');

        // Show payment method alone.
        this.method = method;
        // Handle Adyen setup and mount
        const configuration = {
          locale: this.$i18n.locale,
          // showPayButton: true,
          environment: isInProduction() ? 'live' : 'test',
          clientKey: process.env.VUE_APP_ADYEN_KEY,
          session: {
            id: data.id, // Unique identifier for the payment session.
            sessionData: data.sessionData, // The payment session data.
          },
          onPaymentCompleted: this.processPaymentResult,
          onError: this.processCheckoutError,
          onActionHandled: (state: any, component: any) => {
            LoggerService.info(`3d Secure method`);
            return;
          },
          onChange: (state: any, component: any) => {
            if (state && state.isValid) {
              this.canSubmit = true;
              return;
            }

            this.canSubmit = false;
          },
          beforeRedirect: (resolve: any, reject: any, redirectData: any) => {
            this.loading = true;
            if (method.name === 'TWINT') {
              LoggerService.info(`BeforeRedirect to Twint`);
              if (AppModule.isCartWidget) {
                LoggerService.info(`Destroy cart before redirect to Twint`);
                destroyCart();
              }
            }
            resolve();
          },
          // Any payment method specific configuration.
          // Find the configuration specific to each payment method:  https://docs.adyen.com/payment-methods
          // For example, this is 3D Secure configuration for cards:
          paymentMethodsConfiguration: {
            card: {
              brands: ['mc', 'visa', 'amex', 'cup', 'diners', 'discover', 'jcb', 'maestro', 'bcmc'],
              hasHolderName: false,
              holderNameRequired: false,
              billingAddressRequired: false,
            },
            googlepay: {
              buttonType: 'pay' as any,
              buttonSizeMode: 'fill' as any,
              configuration: {
                merchantName: OrganizerModule.orgName,
                merchantId: 'BCR2DN4TQ3QYRJ3L',
                gatewayMerchantId: isInProduction() ? 'Smeetz_PP_Live' : 'SmeetzSA854_Smeetz_PP_DEV',
              },
            },
            bcmc_mobile: {
              showPayButton: true,
            },
          },
          paymentMethodsResponse: {
            paymentMethods: [
              {
                details: [
                  {
                    items: isInProduction() ? IdealProdIssuers : IdealTestIssuers,
                    key: 'issuer',
                    type: 'select',
                  },
                ],
                name: 'iDEAL',
                type: 'ideal',
              },
              {
                brands: ['maestro', 'mc', 'visa'],
                configuration: {
                  merchantName: OrganizerModule.orgName,
                  merchantId: '000000000223839',
                },
                name: 'Apple Pay',
                type: 'applepay',
              },
            ],
          },
        };
        // Create an instance of AdyenCheckout using the configuration object.
        // const checkout = await (window as any).AdyenCheckout(configuration);
        try {
          LoggerService.info(`Adyen Checkout with method: ${method.name}`);
          const checkout = await AdyenCheckout(configuration);
          // Temporary Fix: When we are inside a side panel widget and in a desktop
          // we increase the width of the iframe so we can display twint qr code
          if (method.name === 'TWINT' && isSidePanelWidget()) {
            window.parent.postMessage('extendWidth', '*');
          }

          // Create an instance of the Component and mount it to the container created.
          if (method.name === 'GOOGLEPAY') {
            this.cardComponent = checkout.create('googlepay');
            this.cardComponent.isAvailable().then(() => {
              this.cardComponent.mount(`#${containerId}`);
            }).catch((e: any) => {
              NNoty.createNewNoty({
                period: 7000,
                message:  String(this.$t('Google Pay is not available')),
                type: 'error',
              });
            });
          } else {
            this.cardComponent = checkout.create(
            method.name === 'TWINT' ? 'twint' :
            method.name === 'IDEAL' ? 'ideal' :
            method.name === 'APPLEPAY' ? 'applepay' :
            method.name === 'BANCONTACT_MOBILE' ? 'bcmc_mobile' :
            method.name === 'BANCONTACT' ? 'bcmc' : 'card', {
              name: method.name === 'TWINT' ? 'Twint' :
              method.name === 'BANCONTACT_MOBILE' ? 'Payconiq by bancontact' : undefined,
              onConfigSuccess: () => {
                this.loading = false;
              },
          }).mount(`#${containerId}`);
          }
        } catch (error) {
          if (inIframe() && method.name === 'APPLEPAY' && window.ApplePaySession) {
            LoggerService.info('APPLEPAY checkout error', {params: JSON.stringify(error)});
            this.retryPaymentInSmeetzDiscover();
            this.loading = false;
            return;
          }

          enableQuasarStyling();
          this.openBlockedPaymentDialog = true;
          LoggerService.info('Adyen checkout error', {params: JSON.stringify(error)});
          // setTimeout(() => {
          //   throw Error(bookingDecorateErrorMessage(JSON.stringify(error), 'Adyen checkout error'));
          // }, 0);
        }

      } catch (error) {
        debug(error);
        setTimeout(() => {
          const response = error && (error as any).response;
          if (response) {
            LoggerService
              .info('PaymentWarning after postBookingTransactionAdyen', {params: JSON.stringify(response.data)});
          }
          const stringError = JSON.stringify(error);
          LoggerService.info('Error after Initiating payment', {params: stringError});
          throw Error(bookingDecorateErrorMessage(stringError));
        }, 0);
        NNoty.createNewNoty({
          period: 7000,
          message:  String(this.$t('error.error-response-banner')),
          type: 'error',
        });
        this.loading = false;
        return;
      }

      if (method.name === 'PENDING' || method.name === 'BANCONTACT') {
        return;
      }

      if (method.name === 'GOOGLEPAY' || method.name === 'APPLEPAY') {
        if (this.cardComponent && this.cardComponent.isAvailable) {
          this.loading = false;
        } else {
          const waitTime = 5000; // 5 seconds in milliseconds
          const startTime = Date.now();

          const intervalId = setInterval(() => {
            if (this.cardComponent && this.cardComponent.isAvailable) {
              this.loading = false;
              clearInterval(intervalId); // Stop checking if available
            } else {
              const currentTime = Date.now();
              if (currentTime - startTime >= waitTime) {
                this.loading = false; // If 5 seconds have passed, set loading to false
                clearInterval(intervalId); // Stop checking after 5 seconds
              }
            }
          }, 200); // Check every 200 milliseconds
        }
      }

      this.loading = false;
      return;

    }

    try {
      this.paymentMethodsRespose = await loadPaymentMethods(this.bookingId, this.bookingToken, this.params);
      try {
        await VueScript.load((this.paymentMethodsRespose as IBookingPayments).url);
      } catch (error) {
        NNoty.createNewNoty({
          period: 10000,
          message: String(this.$t('error.wallee-slow-internet')),
          type: 'error',
        });

        setTimeout(() => {
          throw Error(bookingDecorateErrorMessage(JSON.stringify(error)));
        }, 0);
        return;
      }

      if ( this.paymentMethodsRespose.paymentMethods && this.paymentMethodsRespose.paymentMethods.length ) {
        this.firstPaymentMethod = this.paymentMethodsRespose.paymentMethods[0];
      }
      await this.displayPaymentMethodBig(this.firstPaymentMethod as IPaymentMethod);
    } catch (err) {
      const response = (err as any).response;
      if (!response) {
        debug(`ERROR: Uknown ${err}`);
        NNoty.createNewNoty({
          period: 4000,
          message: 'Unknown error',
          type: 'error',
        });
        setTimeout(() => {
          throw Error(bookingDecorateErrorMessage(JSON.stringify(err)));
        }, 0);
        return;
      }
      // TODO: Make error component and connect it
      if (response.status === HttpStatus['Not Acceptable']) {
        NNoty.createNewNoty({
          period: 4000,
          message: 'Not Acceptable',
          type: 'error',
        });
        NBookingModule.backToStart();
      } else {
        debug('ERROR: Uknown error');
        NNoty.createNewNoty({
          period: 4000,
          message: 'Unknown error',
          type: 'error',
        });
      }
      setTimeout(() => {
        const isAdyenPayementErrorMsg = 'isAdyenPayment :' + this.isAdyenPayment;
        throw Error(bookingDecorateErrorMessage(JSON.stringify(response), isAdyenPayementErrorMsg));
      }, 0);
    }

  }

  // We will redirect the user to orderSummaryConfirmation if he used a promo code to pay 100%
  // We will hide the 'pay with voucher' option if the discount is > 0 and < 100
  @Watch('totalPrice')
  private async ontotalPriceChange() {
    if (this.totalPrice === 0 && this.isPromoCodePayment) {
      try {
        this.loading = true;
        await postBookingFree(this.bookingId, this.bookingToken);
        const shortUrl = (ProductModule.product as IProduct).short_url;
        const productId = (ProductModule.product as IProduct).id;
        this.$router
          .replace(`/product/${productId}/booking/${shortUrl}/success?bookingId=${this.bookingId}&bookingToken=${this.bookingToken}&${this.paramsQs}&reload=true`);
        await Timeout.set(1000);
        location.reload();
        return;
      } catch (error) {
        NNoty.createNewNoty({
          period: 7000,
          message: String(this.$t('error.error-response-banner')),
          type: 'error',
        });
        setTimeout(() => {
          throw Error(bookingDecorateErrorMessage(JSON.stringify(error)));
        }, 0);
        this.loading = false;
      }
    } else {
      const index = this.currentPaymentMethods.findIndex((e) => e.type === PaymentMethodsTypes.PROMOCODE);
      if (index > 0) {
        this.currentPaymentMethods.splice(index, 1);
      }
    }
  }

  // Payment Handler for Adyen.
  // In the case of an error, payment result is an error object
  // TODO add type definitions to function parameters
  private async processPaymentResult(paymentRes: any, component: any) {
    debug(`Payment complete ${paymentRes.resultCode}`);
    try {
      await postLogCall('INFO', `Payment result: ${JSON.stringify(paymentRes)}`);
    } catch (error) {
      setTimeout(() => {
        throw error;
      }, 0);
    }
    switch (paymentRes.resultCode) {
      // Successful payment
      case AdyenStatus.Authorised:
        this.loading = true;

        // Ensure that cart is destroyed. Otherwise it remains on organiser site
        if (AppModule.isCartWidget) {
          destroyCart();
        }
        // Call api to not cancel tickets if we receive Authorized from Adyen
        if (this.bookingId && this.bookingToken && this.sessionId) {
          const sessionResult = paymentRes.sessionResult;
          try {
            const result = await authorizedSession(this.bookingId, this.bookingToken, this.sessionId, sessionResult);
            LoggerService.info(`Authorized session: ${JSON.stringify(result)}`);
          } catch (error) {
            LoggerService.info(`WARNING: Authorized session failed with sessionId:${this.sessionId} && sessionResult:${sessionResult}`);
            setTimeout(() => {
              throw Error(bookingDecorateErrorMessage(JSON.stringify(error),
                'Authorized session'));
            }, 0);
          }
        }

        this.$router.replace(this.returnURL.replace(this.$SITE_URL, ''));
        await Timeout.set(1000);
        location.reload();
        // window.location.replace(this.returnURL.replace(this.$SITE_URL, ''));
        break;
      case AdyenStatus.Pending:
      case AdyenStatus.Received:
        throw new Error('Pending or Received are not handled for now by adyent');
      case AdyenStatus.Refused:
        // Failed payment
        NNoty.createNewNoty({
          message: this.$t('booking-flow.declined-payment-message') as string,
          period: 8000,
          type: 'error',
        });
        this.loading = false;
        this.changeMethod();
        break;
      default:
        if (paymentRes.name === AdyenStatus.NetworkError) {
           // Network issues
           NNoty.createNewNoty({
             message: this.$t('error.payment.slow-internet') as string,
             period: 8000,
             type: 'error',
           });
         } else if (paymentRes.message === AdyenStatus.ThreeDS2TimedOut) {
           // 3DS timeout
           NNoty.createNewNoty({
             message: this.$t('error.payment.3ds2-timed-out') as string,
             period: 10000,
             type: 'error',
           });
         } else {
           // Error case
           NNoty.createNewNoty({
             period: 4000,
             message: 'Unknown error',
             type: 'error',
           });
           debug(paymentRes);
           setTimeout(() => {
             const errorMessage = `Payment: ${JSON.stringify(paymentRes)}`;
             LoggerService.info(bookingDecorateErrorMessage(bookingDecorateErrorMessage(errorMessage)));
           }, 0);
         }
        this.loading = false;
        this.changeMethod();
    }
  }

  private async processCheckoutError(error: any, component: any) {
    // setTimeout(() => {
    //   throw Error(bookingDecorateErrorMessage(JSON.stringify(error), `Adyen checkout onError`));
    // }, 0);
    LoggerService.info(bookingDecorateErrorMessage(JSON.stringify(error), `Adyen checkout onError`));
    if (error && error.message === AdyenStatus.ThreeDS2TimedOut) {
      // 3DS timeout
      NNoty.createNewNoty({
        message: this.$t('error.payment.3ds2-timed-out') as string,
        period: 10000,
        type: 'error',
      });
      this.loading = false;
      this.changeMethod();
      return;
    }
    switch (error.name) {
      case AdyenStatus.NetworkError:
        // Network issues
        NNoty.createNewNoty({
          message: this.$t('error.payment.slow-internet') as string,
          period: 8000,
          type: 'error',
        });
        break;
      case AdyenStatus.cancel:
        // the shopper cancelled the payment.
        // Only applies for payment methods that allow explicit cancellation in the UI, for example Apple Pay or PayPal.
        NNoty.createNewNoty({
          message: BookingPaymentStatusName[BookingPaymentStatus.CANCELLED] as string,
          period: 8000,
          type: 'error',
        });
        break;
      case AdyenStatus.implementationError:
      case AdyenStatus.error:
        NNoty.createNewNoty({
          message: this.$t('contact-us.on-error-msg') as string,
          period: 5000,
          type: 'error',
        });
        break;
      default:
      // Error case
        NNoty.createNewNoty({
          period: 4000,
          message: 'Unknown error',
          type: 'error',
        });
    }
    this.loading = false;
    this.changeMethod();
  }
  private async displayPaymentMethodBig(item: IPaymentMethod) {

    // Sometimes IframeCheckoutHandler is not present yet,
    // delay method call to one second later
    if (!(window as any).IframeCheckoutHandler) {
      // avoid in case the user clicks multiple times
      debug('checkout handler is not present yet, postponing');
      if (this.timeout) {
        debug('Already clicked, repeating');
        clearTimeout(this.timeout);
        this.timeout = undefined;
      }

      this.timeout = setTimeout(() => {
        this.displayPaymentMethodBig(item);
      }, 1000);

      return;
    }

    this.handler = (window as any).IframeCheckoutHandler(item.id);
    this.handler.setValidationCallback(this.validate);
    this.handler.setInitializeCallback(this.handlerInit);

    this.canSubmit = false;

    await this.$nextTick();
    this.createHandler();
  }

  private handlerInit() {
    this.canSubmit = true;
    this.loading = false;
  }

  private async createHandler() {
    await this.$nextTick();
    this.handler.create(containerId);
  }

  private async validate(validationResult: any) {
    this.loading = false;

    if (!validationResult.success) {
      debug('We have some validation errors errors');
      return;
    }

    debug('Form validated and ready to book');
    // Make sure that we distroy the cart at this stage
    if (AppModule.isCartWidget) {
      destroyCart();
    }
    this.submit();
    // console.log('SUBMIT');
    // this.handler.submit();
  }

  private changeMethod() {
    LoggerService.info(`Changing payment method`);
    disableQuasarStyling();
    if (this.cardComponent) {
      this.cardComponent.unmount();
      this.cardComponent = null;
    }
    this.threeDSInProgress = false;
    this.method = null;
    this.paymentChoose = true;
    this.canSubmit = false;
    this.isPromoCodePayment = false;
  }

  private async mounted() {
    let preSelectedPaymentMethod = '';
    const { bookingId, bookingToken } = this.$route.query as {[s: string]: string};
    const memType = ReferrerModule.memType;
    const customers = NBookingModule.membershipCustomers;

    if (!OrganizerModule.info) {
      await OrganizerModule.fetchOrganiserInfo();
    }

    if (ReferrerModule.paymentMethod) {
      preSelectedPaymentMethod = ReferrerModule.paymentMethod;
    }

    if (inIframe()) {
      const op: IWidgetOp = {
        smtzOp: WidgetOps.requestParentUrl,
        wId: AppModule.widgetId,
      };
      window.parent.postMessage(JSON.stringify(op), '*');
    }

    // This is dead logic
    // TODO delete after BUD-8387
    // Show memberships when needed
    // if (!customers.length && memType && [MemType.MemAndTicket, MemType.MemOnly].includes(memType)) {
    //   this.isMembership = true;
    // }

    bookingFlowCheckoutProgress(NBookingModule.ticketsTracking);
    if ( NBookingModule.bookingRes !== null ) {
      // tslint:disable-next-line:max-line-length
      this.paymentMethods = (NBookingModule.bookingRes as IPreBookingRes).availablePaymentMethods || (NBookingModule.bookingRes as IPreBookingRes).availablePayments;
      // For adyen, retrieve payment methods from organiser
      // TODO: In the future, all payment methods should come from organiser info
      if (this.isAdyenPayment) {
        this.paymentMethods = OrganizerModule.paymentMethods;
      }
      this.bookingToken = NBookingModule.bookingRes.bookingToken || bookingToken;
      this.bookingId = NBookingModule.bookingRes.bookingId || Number(bookingId);
    }
    if (this.hasAutoRenewalTicket) {
      this.paymentMethods = this.paymentMethods.filter(
        (method) => !this.nonAutoRenewalMembershipPayments.includes(method));
    }
    for ( const paymentMethod of this.paymentMethods) {
      for ( const availableMethod of this.availablePaymentMethods) {
        const disableRequestToBook = ['TWINT', 'PAYPAL'];

        if ( paymentMethod ===  availableMethod.name ) {
          if (disableRequestToBook.includes(availableMethod.name) && this.isRequestToBook && this.isAdyenPayment) {
            // We had an issue BOO-717 where request-to-book ticket wasn't successfully paid with TWINT and Adyen
            // Temporary solution is to hide until it gets fixed from Backend
          } else if (availableMethod.name === 'APPLEPAY') {
            if (window.ApplePaySession) {
              this.currentPaymentMethods.push(availableMethod);
            }
          } else {
            this.currentPaymentMethods.push(availableMethod);
          }
          continue;
        }
      }
    }
    // Now lets add promo code as a payment method
    // this.currentPaymentMethods.push({
    //   // name: String(this.$t('booking-flow.label-pay-with-voucher')),
    //   name: 'VOUCHER',
    //   src: 'img/icons/voucher.png',
    //   desc: String(this.$t('booking-flow.label-pay-with-voucher')),
    //   type: PaymentMethodsTypes.PROMOCODE});

    // Card method is represented by DEFAULT_PAYMENT_METHOD cause we don't know which exact card is the user choosing.
    // Once backend receives payment webhook, the backend will set the payment method from Adyen.
    this.currentPaymentMethods
      .unshift(this.availablePaymentMethods
      .find((method) => method.name === DEFAULT_PAYMENT_METHOD) as ICustomPaymentMethod);

    if (this.currentPaymentMethods && this.currentPaymentMethods.length > 0) {
      if (preSelectedPaymentMethod) {
        const preSelected = this.currentPaymentMethods.find((payment) => payment.name === preSelectedPaymentMethod);
        if (preSelected) {
          this.makeChoose(preSelected);
          return;
        }
      }
      this.makeChoose(this.currentPaymentMethods[0]);
    }
  }

  private async submit() {
    const transactionId: number = (this.paymentMethodsRespose as IBookingPayments).transactionId;
    const paymentMethod: string = (this.firstPaymentMethod as IPaymentMethod).name;
    const data: IConfirmBookingData = {
      bookingId: this.bookingId,
      bookingToken: this.bookingToken,
      transactionId,
      paymentMethod,
    };

    this.loading = true;
    try {
      // TODO delete this part after testing server side cart
      // preserve the current booking cart so we can retrieve it when we
      // get a failure redirect
      // NBookingModule.persistBooking();

      // NOT needed since query parameter is going to come back on success page
      // storeGADecoration(this.$route);
      // Let wallee handle the submit
      this.handler.submit();
    } catch (err) {
      LoggerService.info(`Error when submitted payment: ${JSON.stringify(err)}`);
      const errResponse = (err as any).response;
      if (!errResponse) {
        debug('ERROR: Unkown request error');
        NNoty.createNewNoty({
          period: 4000,
          message: 'Unkown request error',
          type: 'error',
        });
        throw Error(bookingDecorateErrorMessage(JSON.stringify(err)));
        // return;
      }

      if (errResponse.status === HttpStatus.Conflict) {
        debug('ERROR: Must go to first booking step');
        NBookingModule.backToStart();
      } else {
        debug('We have a payment error');
        NNoty.createNewNoty({
          period: 4000,
          message: 'Payment error',
          type: 'error',
        });
        throw Error(bookingDecorateErrorMessage(JSON.stringify(err)));
      }
    } finally {
      this.loading = false;
    }
  }

  private async pay() {
    bookingFlowPaymentTracking(NBookingModule.ticketsTracking);
    const shortUrl = (ProductModule.product as IProduct).short_url;
    const productId = (ProductModule.product as IProduct).id;

    if (this.getShowConsentCheckbox) {
      if (!this.acceptAgreement && this.isAdyenPayment) {
        this.consentErr = true;
        return;
      }
    }

    // Handle Adyen payment when component is present
    // Make sure that we don't do this check for free bookings because the
    // cardComponent could be present in the cardComponent field.
    if (this.cardComponent && !this.isFree) {
      this.loading = true;
      LoggerService.info(`Submitting card info`);
      this.cardComponent.submit();

      // In case we are in a 3d Secure method, let's load for a maximum of 6 seconds
      // LoggerService.info(`3d Secure method`);
      // await Timeout.set(3000);
      // LoggerService.info(`Loaded for 3 seconds`);
      this.loading = false;
      this.canSubmit = false;
      this.threeDSInProgress = true;
      enableQuasarStyling();
      return;
    }

    // Handling free booking
    if (this.isFree) {
      try {
        this.loading = true;
        await postBookingFree(this.bookingId, this.bookingToken);
        // Ensure that cart is destroyed. Otherwise it remains on organiser site
        if (AppModule.isCartWidget) {
          destroyCart();
        }

        // Reload site with a success page link.
        this.$router
          .replace(`/product/${productId}/booking/${shortUrl}/success?bookingId=${this.bookingId}&bookingToken=${this.bookingToken}&${this.paramsQs}&reload=true`);
        await Timeout.set(1000);

        location.reload();
        return;
      } catch (error) {
        NNoty.createNewNoty({
          period: 7000,
          message: String(this.$t('error.error-response-banner')),
          type: 'error',
        });
        this.loading = false;
        throw Error(bookingDecorateErrorMessage(JSON.stringify(error)));
      }
    }

    const method = this.method;
    if (!method) {
      return;
    }

    this.loading = true;

    // Wallee payment
    if (!this.nonWalleePayments.includes(method.name)) {
      this.handler.validate();
      return;
    }

    // Non wallee methods
    this.canSubmit = false;

    try {
      await postBookingFree(this.bookingId, this.bookingToken, {paymentMethod: method.name});
      // tslint:disable-next-line:max-line-length
      // this.$router.replace(`/product/${productId}/booking/${shortUrl}/success?bookingId=${this.bookingId}&bookingToken=${this.bookingToken}`, () => location.reload());
      this.$router.replace(`/product/${productId}/booking/${shortUrl}/success?bookingId=${this.bookingId}&bookingToken=${this.bookingToken}&${this.paramsQs}&reload=true`);
      setTimeout(() => {
        location.reload();
        this.loading = false;
      }, 1000);
    } catch (error) {
      NNoty.createNewNoty({
        period: 7000,
        message: String(this.$t('error.error-response-banner')),
        type: 'error',
      });
      this.loading = false;
      throw Error(bookingDecorateErrorMessage(JSON.stringify(error)));
    } finally {
      this.canSubmit = true;
    }
  }
  private closeBlockedPaymentDialog() {
    this.openBlockedPaymentDialog = false;
    disableQuasarStyling();
  }

  private retryPaymentInSmeetzDiscover() {
    // Get the top-level domain URL
    const topLevelDomainUrl = inIframe() && ReferrerModule.parentUrl !== '' ? ReferrerModule.parentUrl : null;
    const product = ProductModule.product;
    const productId = product ? product.id : null;
    const productSlug = product ? product.short_url : null;
    const methodName = this.method ? this.method.name : null;
    const link = `${SITE_URL}/${this.$i18n.locale}/product/${productId}/booking/${productSlug}?bookingId=${this.bookingId}&bookingToken=${this.bookingToken}&fromWidget=true&paymentMethod=${methodName}${topLevelDomainUrl && inIframe() ? `&topLevelDomainUrl=${topLevelDomainUrl}` : ''}`;
    if (inIframe()) {
      const op: IOpenPageOp = {
        smtzOp: WidgetOps.sendOpenPage,
        wId: AppModule.widgetId,
        data: {
          url: link,
        },
      };
      window.parent.postMessage(JSON.stringify(op), '*');
      return;
    }
    try {
      window.open(link, '_self');
    } catch (error) {
      LoggerService.info('Trying to open window error:', {params: JSON.stringify(error)});
    }
    if (this.openBlockedPaymentDialog) {
      this.closeBlockedPaymentDialog();
    }
  }
}

















import { Component, Vue, Prop } from 'vue-property-decorator';
import LocalRegisterForm from '@/components/LocalRegisterForm.vue';
import LocalLoginForm from '@/components/LocalLoginForm.vue';

@Component({
  components: {
    LocalRegisterForm,
    LocalLoginForm,
  },
})
export default class LocalAuth extends Vue {

  @Prop({ default: 'register' })
  public authType!: string;
}


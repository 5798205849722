

































import { Vue, Prop, Component } from 'vue-property-decorator';
import { IGQLProductSlotState } from '@/models/store/booking';
import { NBookingModule, RBookingModule } from '@/utils/storemodules';
import { getPrimaryColor, hexToRGBA } from '@/utils/colors';
import booking from '@/store/modules/booking';

@Component
export default class TicketCard extends Vue {
  @Prop({ default: {}}) private ticket!: IGQLProductSlotState;

  private get booking() {
    return NBookingModule.bookingSummary?.data.ProductSlotBookingRead;
  }
  private get attendee() {
    return RBookingModule.attendees.find((item) => item.pssId === this.ticket.Id);
  }
  private get selected() {
    return !!RBookingModule.selectedSlots.find((item) => item.Id === this.ticket.Id);
  }

  private backgroundColor() {
    return hexToRGBA(getPrimaryColor(), '0.03');
  }
  private selectTicket() {
    if (!this.selected) {
      RBookingModule.setCurrentSlots({selectedSlots: [this.ticket], add: true});
    } else {
      RBookingModule.setCurrentSlots({selectedSlots: [this.ticket], add: false});
    }
  }
}

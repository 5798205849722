import { client } from '@/utils/graphqlClient/graphql-shipping-client';
import { GetRatesQuery, GetRatesQueryVariables, MutationCreateShippingArgs, CreateShippingInput } from '@/types/gql/generatedShipping/graphql';
import { GET_Rates, CREATE_Shipping } from './gqlShipping/rates.graphql';

/**
 * Returns shipping rates
 *
 */
export async function getShippingRates(
  groupId: number, toCountry: string, toPostalCode: string, bookingReference: string) {

  const res = await client.query<GetRatesQuery, GetRatesQueryVariables>(
    GET_Rates,
    { group_id: groupId, to_country: toCountry, to_postal_code: toPostalCode, bookingReference }).toPromise();

  return res.data?.getRates;
}

/**
 * Create a parcel in sendCloud
 *
 */
export async function createShipping(data: CreateShippingInput) {

  const res = await client.mutation<MutationCreateShippingArgs>(
    CREATE_Shipping,
    { data }).toPromise();

  return res.data;
}






import { Component, Vue } from 'vue-property-decorator';
import { ModalStatus } from '@/models/definitions';
import { ModalModule, NModalModule } from '@/utils/storemodules';

@Component({
  components: {
  },
})
export default class DeleteAccount extends Vue {
  // @ModalModule.Mutation('setStatus') private setStatus: any;

  private mounted() {
    // Change route with given query params
    const route = this.$route;
    this.$router.push({
      path: '/',
      query: route.query,
    });

    // show modal
    // this.setStatus(ModalStatus.DeleteAccountVerified);
    NModalModule.setStatus(ModalStatus.DeleteAccountVerified);
  }
}

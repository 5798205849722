import { client } from '@/utils/graphqlClient/graphql-shipping-client';
import { GET_UserMemberships } from './gqlShipping/memberships.graphql';
import { GetUserMembershipsQuery, GetUserMembershipsQueryVariables, UserMembership } from '@/types/gql/generatedShipping/graphql';

/**
 * Returns user memberships vouchers
 *
 */
export async function getUserMemberships(
  token: string) {

  const fetchOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const res = await client.query<GetUserMembershipsQuery, GetUserMembershipsQueryVariables>(
    GET_UserMemberships, {}, {fetchOptions}).toPromise();

  return res.data?.getUserMemberships as UserMembership[];
}









































import { Vue, Component, Prop } from 'vue-property-decorator';
import NewButton from '@/components/presentational/NewButton.vue';
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

@Component({
  components: {
    NewButton,
    VueCropper,
  },
})
export default class ImageTypeField extends Vue {
  @Prop({ default: '' }) private src!: string;
  @Prop({ default: false }) private loadingBtn!: boolean;
  private cropper: any = {};
  private croppedImg: any = null;
  private fileToSend: string | File | null = null;

  private async mounted() {
    this.cropper = this.$refs.cropper;
  }

  private back() {
    this.cropper.cropper.disabled = false;
    this.croppedImg = null;
    (this.cropper as any).replace(this.src);
  }

  private cropImage() {
    this.croppedImg = this.cropper.getCroppedCanvas().toDataURL();
    this.fileToSend = this.croppedImg;
    (this.cropper as any).replace(this.croppedImg);
    this.cropper.cropper.disabled = true;
  }
  // Use it after selecting new image
  private filetoDataURL(file: File) {
    const reader = new FileReader();
    reader.onload = (event) => {
      this.src = (event.target as FileReader).result as string;
      this.fileToSend = (event.target as FileReader).result as string;
      (this.cropper as any).replace((event.target as FileReader).result);
    };
    reader.readAsDataURL(file);
  }
  // to send image after cropping
  private dataURLtoFile(dataurl: string, filename: string) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)![1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  private sendFile() {
    if (this.fileToSend) {
      const dataUrl = this.fileToSend as string;
      // Image format always generated as .png
      this.fileToSend = this.dataURLtoFile(dataUrl, 'image.png') as File;
      this.$emit('updateChange', this.fileToSend);
    } else {
      // console.log('no file to be sent');
    }
  }
}

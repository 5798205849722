import Vue from 'vue';
import Debug from 'debug';
import { Mixin } from 'vue-mixin-decorator';
import {
  IExportPricesEntity, IMembershipCustomer, InformationType,
  IPostBookingField, IPriceCount, IProductSlotStatesEntity, IServerBooking, ITicket, ITicketField,
} from '@/models/store/booking';
import { didCustomerBookAlready, didCustomerBookThisCategory, didCustomerBookSimilarPrice } from '@/utils/membership';
import { AppModule, NBookingModule, NNoty, ReferrerModule } from '@/utils/storemodules';
import { Loading } from 'quasar';
import { disableQuasarStyling, enableQuasarStyling } from '@/utils/styles';
import { MembershipType } from '@/models/memberships';
import { fetchServerBooking, getBookingFields, postBookingField } from '@/api/booking';
import { getCartBookingInfo, sendCartBookingInfo } from '@/utils/iframe';
import { addRegularTicketsToCustomers, isRealMembershipDiscount } from '@/utils/membership-utils';

const debug = Debug('smeetz:membership');

@Mixin
export default class MembershipBooking extends Vue {
  public canCustomerBuyThisCategoryPrice(
    customer: IMembershipCustomer, price: IExportPricesEntity, category: ITicket) {
    const userAlreadyBookedThisTicket = didCustomerBookThisCategory(customer, category);
    if (userAlreadyBookedThisTicket) {
      return false;
    }
    // - If user already booked another price
    if (didCustomerBookAlready(customer)) {
      // can buy only if he bought a similar price before
      const notRightPriceForCustomer = !didCustomerBookSimilarPrice(customer, price);
      if (notRightPriceForCustomer) {
        return false;
      }
    }
    // It is valid
    return true;
  }

  // Returns the pssId that we got after applying the membership
  // and before adding it to the membershipCustomers
  public getNewestPssId(): number | undefined {
    const {membershipCustomers} = NBookingModule;
    const appliedMembership = NBookingModule.membershipApplied;
    const updatedPSSList = appliedMembership?.productSlotStates.map((p) => p.id);
    // now look for the newest pss by looking for the one from updatedPSSList
    // that is inside membrshipCustomers already
    const customersExistingPSSList = Array.prototype.concat.apply([],
    membershipCustomers.map((c) => c.tickets.map((t) => t.pssId)));

    const diffPSS = updatedPSSList?.filter((pss) => !customersExistingPSSList.includes(pss));
    // newest PSS
    const newestPSS = diffPSS !== undefined ? diffPSS[0] : undefined;
    return newestPSS;
  }

  // Returns a non assigned pss Id
  public getNonAssignedPssId(fetchedBooking: IServerBooking): number | undefined {
    const {membershipCustomers} = NBookingModule;
    const productSlotStates = (fetchedBooking as any).slots || [];
    // We get first all the PSS
    const allPss = productSlotStates.map((productSlotState: any) => {
      return {
        seat: productSlotState.seat,
        priceId: productSlotState.priceId,
        priceName: productSlotState.priceName,
        timeslotId: productSlotState.timeSlotId || productSlotState.timeslotId,
        pssId: productSlotState.id || productSlotState.productSlotStateId,
      };
    });
    const customersExistingPSSList = Array.prototype.concat.apply([],
      membershipCustomers.map((MemCustomer) => MemCustomer.tickets.map((t) => t.pssId)));

    // Then we remove from that list the ones that were added to customer before
    const diffPSS = allPss?.filter((pss: any) => !customersExistingPSSList.includes(pss.pssId));

    // newest PSS
    const newestPSS = diffPSS.length > 0 ? diffPSS[0].pssId : undefined;
    return newestPSS;
  }

  public async addToMembership(priceCount: IPriceCount, count = 1) {
    debug('hi from addToMembership');
    debug('priceCount', priceCount);
    debug('Invoking add to membership');

    if (Loading.isActive) {
      Loading.hide();
      disableQuasarStyling();
    }
    const categoryId = priceCount.ticket.categoryId;
    const timeSlotId = priceCount.slot.id;
    const priceId = priceCount.price.priceId;
    const priceName = priceCount.price.priceName;
    const priceCategory = priceCount.price.priceCategory;
    const seat = priceCount.seat as string;

    const memType = ReferrerModule.memType;
    // TODO remove next comment when BUD-8305 is tested
    // if (!memType) {
    if (!NBookingModule.isCustomerBooking) {
      return;
    }

    const {membershipCustomers, membership} = NBookingModule;

    // TODO remove next comment when BUD-8305 is tested
    // if (!membership || !membershipCustomers.length) {
    //   return;
    // }

    const fetchedBooking = await fetchServerBooking(NBookingModule.bookingId, NBookingModule.bookingToken);

    if (membershipCustomers.length === 1) {
      // No need to show customers popup if we have only one customer
      this.addToMembership_oneCustomerCase(priceCount, fetchedBooking);
      return;
    }

    const memName = membership ? membership.name : '';

    const opts: Array<{label: string, value: number}> = [];
    let i = 0;
    // let invalidIndex = 999;
    // for (let i = 0; i < membershipCustomers.length; i++) {
    //   opts.push({
    //     label: `Add ticket to "${memName} #${i}"`,
    //     value: i,
    //   });
    // }

    // TODO remove next comments once BUD-8305 is tested
    // if (membership.type === MembershipType.Individual) {
    for (const customer of membershipCustomers) {
      // Customer can't buy if he already has bought this category
      // if (customer.tickets.find((ticket) => ticket.categoryId === categoryId)) {
      //   invalidIndex = i;
      // }
      const {firstName, lastName} = customer;
      opts.push({
        label: `${firstName} ${lastName}`,
        value: i,
      });
      i++;
    }
    // } else if (membership.type === MembershipType.Family) {
    //   for (const customer of membershipCustomers) {
    //     // Customer can't buy if he already has bought this category
    //     // if (customer.tickets.find((ticket) => ticket.categoryId === categoryId)) {
    //     //   invalidIndex = i;
    //     // }
    //     const {firstName, lastName} = customer;
    //     opts.push({
    //       label: `${firstName} ${lastName}`,
    //       value: i,
    //     });
    //     i++;
    //   }
    // }

    let counter = 0;
    // for (let c = 0; c < priceCount.count; c++) {
    for (let c = 0; c < (count || 1); c++) {

      enableQuasarStyling();
      counter++;

      // Wrap each dialogue invokation with a promise so that we can ensure showing dialogues 1 by 1
      // TODO: Now, this submits custom fields and applies membership discount each time a dialogue
      // is shown. This is not ideal, what we can do is capture all the customers at once, then we
      // submit the custom fields of all customers at once and we apply membership 1 time instead of
      // {count} times!!!.
      const p = new Promise((resolve, reject) => {

        this.$q.dialog({
          title: this.$t('membership.update') as string,
          message: membership && (membership.type === MembershipType.Individual) ?
            this.$t('membership.update2') as string : this.$t('membership.update3') as string,
          options: {
            type: 'radio',
            model: (0 as any),
            // inline: true
            items: opts,
            isValid: (data: any) => {
              // const customer = membershipCustomers[data];
              // return !(customer.tickets.find((t) => t.categoryId === categoryId));
              // If the customer has already booked this ticket then return not valid

              const customer = membershipCustomers[data];
              debug('customer', customer);
              let userAlreadyBookedThisTicket;
              if (membership && membership.type === MembershipType.RegularMembership) {
                userAlreadyBookedThisTicket =
                  (customer.tickets.find((t) => t.categoryId === categoryId && t.priceId === priceId));
              } else {
                userAlreadyBookedThisTicket = (customer.tickets.find((t) => t.categoryId === categoryId));
              }
              if (userAlreadyBookedThisTicket) {
                return false;
              }

              if (membership && membership.type !== MembershipType.RegularMembership) {
                // - If user already booked another price
                //   - if previous price is not the same as the current price
                if (customer.tickets && customer.tickets.length > 0) {
                  const notRightPriceForCustomer = !(customer.tickets.find((t) => t.priceName === priceName));
                  //     - Not valid
                  if (notRightPriceForCustomer) {
                    return false;
                  }
                }
              }

              // It is valid
              return true;
            },
          },
          // cancel: true,
          persistent: true,
        }).onOk(/*data*/async (data: number) => {
          const dialog = this.$q.dialog({
            // message: 'Uploading... 0%',
            progress: true, // we enable default settings
            persistent: true, // we want the user to not be able to close it
            ok: false, // we want the user to not be able to close it
          });
          const bookingId = NBookingModule.bookingId;
          const bookingToken = NBookingModule.bookingToken;
          if (!bookingId || !bookingToken) {
            return;
          }

          // Fetch ticket fields.
          const customFields = await getBookingFields(bookingId, bookingToken);
          const ticketFields = customFields.ticket_fields as ITicketField[];

          const customer = membershipCustomers[data];
          const { firstName, lastName } = customer;

          const answers: IPostBookingField[] = [];

          // Post firstName answer
          for (const field of ticketFields) {
            if (field.informationType === InformationType.FirstName && !field.answer) {
              answers.push({
                // answer: `${memName} #${data}`,
                answer: firstName,
                pss: field.pssId,
                fieldId: field.fieldId,
              });
              // await postBookingField(bookingId, bookingToken, []);
              break;
            }
          }

          // Post last name answer
          for (const field of ticketFields) {
            if (field.informationType === InformationType.LastName && !field.answer) {
              // Post firstName answer
              answers.push({
                // answer: `${memName} #${data}`,
                answer: lastName,
                pss: field.pssId,
                fieldId: field.fieldId,
              });
              // await postBookingField(bookingId, bookingToken, []);
              break;
            }
          }

          // Check ticket field with information type equal to 20 & doesn't have an answer
          for (const field of ticketFields) {
            if (field.informationType === InformationType.MembershipInfo && !field.answer) {

              // Post membership name as answer;
              answers.push({
                // answer: `${memName} #${data}`,
                answer: membership && (membership.type === MembershipType.Family) ?
                  `${membershipCustomers[0].lastName}` : `${firstName} ${lastName}`,
                pss: field.pssId,
                fieldId: field.fieldId,
              });
              // await postBookingField(bookingId, bookingToken, []);

              // if (this.$route.query.applmem) {
              // await applyMemDiscount({
              //   bookingId, bookingToken, memId: membership.id,
              // });
              // }
            }
          }

          try {
            if (answers.length && membership && membership.type !== MembershipType.RegularMembership) {
              await postBookingField(bookingId, bookingToken, answers);
            }

            if (NBookingModule.isMembershipBooking &&
              membership && isRealMembershipDiscount(membership)) {
              await NBookingModule.applyMembership();
            }
           // Logic for adding many tickets to each customer
            if (membership && membership.type === MembershipType.RegularMembership) {
              // tslint:disable-next-line:max-line-length
              const customerTickets = addRegularTicketsToCustomers(membershipCustomers, membership, fetchedBooking, categoryId, priceCategory);
              customer.tickets = customer.tickets.concat(customerTickets);
            } else {
              // TODO, get the pssId directly from the custom field
              let pssId = this.getNewestPssId();
              if (!pssId && membership && membership.type === MembershipType.RequiredTicketsMembership) {
                pssId = this.getNonAssignedPssId(fetchedBooking);
              }
              customer.tickets.push(
                {
                  categoryId,
                  seat,
                  priceId,
                  priceName,
                  timeSlotId,
                  pssId,
                  priceCategory,
                },
              );
              await NBookingModule.fetchBooking({ bookingToken, bookingId, includePackages: true });
            }

            // Todo, Generalize to have this method working for seating plans
            // this.updateChartConfig();
            if (AppModule.isCartWidget) {
              sendCartBookingInfo(getCartBookingInfo());
            }
            counter--;
            debug('Counter is ', counter);
          } catch (error) {
            NNoty.createNewNoty({
              period: 4000,
              message: String(this.$t('error.error-response-banner')),
              type: 'error',
            });
            reject(error);
            throw error;
          } finally {
            dialog.hide();
            disableQuasarStyling();
          }
          resolve(null);
          return;
        }); // onOk
      }); // Promise

      try {
        await p;
      } catch (error) {
        disableQuasarStyling();
        if (Loading.isActive) {
          Loading.hide();
        }
      }
    } // forLoop
  }

  public async addToMembership_oneCustomerCase(priceCount: IPriceCount, fetchedBooking: IServerBooking) {
    const categoryId = priceCount.ticket.categoryId;
    const timeSlotId = priceCount.slot.id;
    const priceId = priceCount.price.priceId;
    const priceName = priceCount.price.priceName;
    const priceCategory = priceCount.price.priceCategory;
    const seat = priceCount.seat as string;
    const {membershipCustomers, membership} = NBookingModule;

    // Remove next comment once BUD-8305 is tested
    // if (!membership || !membershipCustomers.length) {
    if (!NBookingModule.isCustomerBooking) {
      return;
    }

    const bookingId = NBookingModule.bookingId;
    const bookingToken = NBookingModule.bookingToken;
    if ( !bookingId || !bookingToken) {
      return;
    }

    // Fetch ticket fields.
    const customFields = await getBookingFields(bookingId, bookingToken);
    const ticketFields = customFields.ticket_fields as ITicketField[];

    const customer = membershipCustomers[0];
    const { firstName, lastName } = customer;

    const answers: IPostBookingField[] = [];

    // Push firstName & lastName é membership name answers if they aren't there already
    for (const field of ticketFields) {
      if (field.answer && field.answer !== '' && field.answer !== ' ') {
        continue;
      }
      if (field.informationType === InformationType.FirstName) {
        answers.push({
          answer: firstName,
          pss: field.pssId,
          fieldId: field.fieldId,
        });
      }
      if (field.informationType === InformationType.LastName) {
        answers.push({
          answer: lastName,
          pss: field.pssId,
          fieldId: field.fieldId,
        });
      }
      if (field.informationType === InformationType.MembershipInfo) {
        answers.push({
          answer: membership && (membership.type === MembershipType.Family) ?
          `${membershipCustomers[0].lastName}` : `${firstName} ${lastName}`,
          pss: field.pssId,
          fieldId: field.fieldId,
        });
      }
    }

    try {
      if (answers.length) {
        await postBookingField(bookingId, bookingToken, answers);
      }

      // Apply membership only if we are dealing with a memebership
      if (NBookingModule.isMembershipBooking && membership && isRealMembershipDiscount(membership)) {
        await NBookingModule.applyMembership();
      }

      // TODO, get the pssId directly from the custom field
      let pssId = this.getNewestPssId();
      if (!pssId && membership && membership.type === MembershipType.RequiredTicketsMembership) {
        pssId = this.getNonAssignedPssId(fetchedBooking);
      }
      customer.tickets.push(
        {
          categoryId,
          seat,
          priceId,
          priceName,
          timeSlotId,
          pssId,
          priceCategory,
        },
      );

      await NBookingModule.fetchBooking({bookingToken, bookingId, includePackages: true});
      if (AppModule.isCartWidget) {
        sendCartBookingInfo(getCartBookingInfo());
      }
    } catch (error) {
      NNoty.createNewNoty({
        period: 4000,
        message: String(this.$t('error.error-response-banner')),
        type: 'error',
      });
      throw error;
    }
  }

  public async removeMembershipCustomerTicket(priceCount: IPriceCount, count = 1) {
    // We should know if we are removing addon or a main ticket in case of regular membership
    const { ticket, price, slot, packagePriceId } = priceCount;

    const isRegularMembership = NBookingModule.membership &&
      NBookingModule.membership.type === MembershipType.RegularMembership;

    // For each ticket removed, remove 1 from a customer; PS: Regular membership not concerned
    for (let i = 0; i < count; i++) {
      const customer = NBookingModule.membershipCustomers.find((cust) => {
        const tickets = cust.tickets;
        return tickets.find((t) => {
          return isRegularMembership && packagePriceId ?
            t.timeSlotId === slot.id : t.categoryId === ticket.categoryId && t.priceId === price.priceId;
        });
      });

      if (!customer) {
        throw new Error('Can\'t find a customer who already selected this ticket');
      }

      if (!isRegularMembership) {
        const deleted = customer.tickets.splice(customer.tickets.findIndex((t) => {
          return t.categoryId === ticket.categoryId && t.priceId === price.priceId;
        }), 1);
        if (!deleted.length) {
          throw new Error('Didn\'t find a customer ticket to delete');
        }
      } else {
        if (packagePriceId) {
          customer.tickets = customer.tickets.filter((t) => t.timeSlotId !== slot.id);
        }
      }
    }

    const { bookingId, bookingToken } = NBookingModule;


    // Apply membership only when we have a memebership selected
    enableQuasarStyling();
    Loading.show();
    const {membership} = NBookingModule;
    if (membership && isRealMembershipDiscount(membership)) {
      await NBookingModule.applyMembership();
      await NBookingModule.fetchBooking({ bookingToken, bookingId, includePackages: true });
    }
    if (AppModule.isCartWidget) {
      sendCartBookingInfo(getCartBookingInfo());
    }
    if (Loading.isActive) {
      Loading.hide();
      disableQuasarStyling();
    }
  }
}

import dayjs, { Dayjs } from 'dayjs';
import { ICategory, ISlot, ISlotDates, ITicket } from '@/models/store/booking';
import { inIframe } from './iframe';
import debug from './debug';
import { isInInstagramApp, isInFacebookApp, isIos } from './platform';
import moment from 'moment';
import { constants } from '@/config/constants.ts';
import { listCountries } from '@/api/common';
import Debug from 'debug';
import { OrganizerModule, ReferrerModule, NBookingModule, AppModule } from './storemodules';
import randomstring from 'random-string';
import { LoggerService } from '@/services/log';

export function defineLanguage() {
  let userLang: string | undefined;
  try {
    userLang = navigator.language;
  } catch (error) {
    userLang = 'en';
  }

  userLang = userLang.toLowerCase();

  // If url contain lang parameter - return it for language choose
  const langParameter = getParameterByName('lang');
  if (langParameter === 'de' ||
      langParameter === 'fr' ||
      langParameter === 'en' ||
      langParameter === 'it' ||
      langParameter === 'nl' ||
      langParameter === 'es' ||
      langParameter === 'hu') {
    return langParameter;
  }

  let language;
  try {
    if (!inIframe() && sessionStorage.getItem('language')) {
      language = sessionStorage.getItem('language');
    } else if (userLang.indexOf('fr') !== -1) {
      language = 'fr';
    } else if (userLang.indexOf('de') !== -1) {
      language = 'de';
    } else if (userLang.indexOf('it') !== -1) {
      language = 'it';
    } else if (userLang.indexOf('nl') !== -1) {
      language = 'nl';
    } else if (userLang.indexOf('es') !== -1) {
      language = 'es';
    } else if (userLang.indexOf('hu') !== -1) {
      language = 'hu';
    } else {
      language = 'en';
    }
  } catch (error) {
    language = 'en';
  }
  return language;
}

export function getLanguageInt(language: string) {
  const languageInt: any = {
    fr: 1,
    de: 3,
    it: 4,
    en: 2,
    nl: 6,
    es: 7,
  };
  return languageInt[language] || languageInt.en;
}

/**
 *
 * @param birthdate date in format "day/month/year" (dd/mm/yyyy)
 *
 * returns date in format "year-month-day"
 */
export function fromSlashDate(birthdate: string) {
  if (birthdate) {
    const [day, month, year] = birthdate.split('/');
    return `${year}-${month}-${day}`;
  } else { return ''; }
}

/**
 *
 * @param birthdate date in format "year-month-day" (dd/mm/yyyy)
 *
 * returns date in format "day/month/year"
 */
export function fromDashDate(birthdate: string) {
  if (birthdate) {
    const [year, month, day] = birthdate.split('-');
    return `${day}/${month}/${year}`;
  } else { return ''; }
}

/**
 * Transforms object to url encoded form data
 */

export function dataToParams(data: any) {
  const params = new URLSearchParams();

  for (const key in data) {
    if (key) {
      params.append(key, data[key]);
    }
  }

  return params;
}

/**
 * Checks whether we are in an iframe or not
 */

// export function inIframe() {
//   try {
//     return window.self !== window.top;
//   } catch (e) {
//     return true;
//   }
// }

/**
 * Cleans seatsio chart. Returns a promise when all is clear
 * @param destroyOnly: destroys only charts without releasing seats
 */
export function destroyCharts(destroyOnly: boolean) {
  return new Promise<void>((resolve, reject) => {
    const win = window as any;

    // avoid destroying if no charts are present
    const charts = win.seatsio && win.seatsio.charts;
    if (!charts || !charts.length) {
      debug('returning from destroy charts');
      return resolve();
    }

    let chartCount = 0;
    for (const chart of charts) {
      chartCount++;

      // Destroying charts without releasing seats
      if (destroyOnly) {
        chart.destroy();
        chartCount--;

        if (chartCount === 0) {
          resolve();
        }
        continue;
      }

      // Unhold pre selected seats before destroying the chart.
      chart.clearSelection(() => {
        chart.destroy();
        chartCount--;

        if (chartCount === 0) {
          resolve();
        }
      });
    }
  });
}

// checks if a seating plan chart is present
export function isChartPresent() {
  const win = window as any;
  const charts = win.seatsio && win.seatsio.charts;

  return !!charts;
}

/**
 * Checks if a category is a seating category that contains seating subProducts
 */
export function isSeatParentCategory(cat: ICategory | ITicket) {
  return cat.type === 3;
}

/**
 * Checks if a category is a seating category itself
 */
export function isSeatCategory(cat: ICategory) {
  return cat.type === 31;
}

export function isSlotFromDate(slot: ISlot, dates: ISlotDates) {
  return dayjs(dates.startDateTime).isSame(dayjs(slot.startDateTime)) &&
    dayjs(dates.endDateTime).isSame(dayjs(slot.endDateTime));
}

export function slotSortComp(slot1: ISlot, slot2: ISlot) {
  const day1 = dayjs(slot1.startDateTime);
  const day2 = dayjs(slot2.startDateTime);

  if (day1.isSame(day2)) {
    return 0;
  } else if (day1.isBefore(day2)) {
    return -1;
  }

  return 1;
}
/**
 * Sorting function for comparing dates
 */
export function dateSortComp(date1: string | number | Date, date2: string | number | Date) {
  const day1 = dayjs(date1);
  const day2 = dayjs(date2);

  if (day1.isSame(day2)) {
    return 0;
  } else if (day1.isBefore(day2)) {
    return -1;
  }

  return 1;
}

/**
 * Sorting function for comparing arrays of objects that contain a date
 * We assume that each array is filled and has objects of same date
 */
export function dateSortCompArrays<T>(arr1: T[], arr2: T[], field: string) {

  const day1 = dayjs((arr1[0] as any)[field]);
  const day2 = dayjs((arr2[0] as any)[field]);

  if (day1.isSame(day2)) {
    return 0;
  } else if (day1.isBefore(day2)) {
    return -1;
  }

  return 1;
}

export function dateSortCompArraysGen(field: string) {
  // tslint:disable-next-line
  return function () {
    return dateSortCompArrays(arguments[0], arguments[1], field);
  };
}
/**
 * Checks if 2 dates are the same date
 * @param day1
 * @param day2
 */
export function isSameDay(day1: Dayjs, day2: Dayjs) {
  return day1.isSame(day2, 'day');
}

export function hideHubspotChat() {
  const element = document.getElementById('hubspot-messages-iframe-container');

  if (element) {
    element.style.visibility = 'hidden';
  }
}

export function showHubspotChat() {
  const element = document.getElementById('hubspot-messages-iframe-container');

  if (element) {
    element.style.visibility = 'visible';
  }
}

export function openLegalPage(this: any) {
  const url = constants.legal_main[this.$i18n.locale];
  window.open(url, '_blank');
}

export function validateOnDelay(this: any, field: string, value: any) {
  const touchMap = new WeakMap();

  (this as any)[field] = value;
  // Reset field validation
  this.$v[field].$reset();
  // If user change input, clear timeout
  if (touchMap.has(this.$v[field])) {
    clearTimeout(touchMap.get(this.$v[field]));
  }
  // If user doesn't change field for 1000ms - make field dirty(activate validation)
  touchMap.set(this.$v[field], setTimeout(this.$v[field].$touch, 2000));
}

export function getParameterByName(name: string, url: string = window.location.href) {
  name = name.replace(/[\[\]]/g, '\\$&');

  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');

  const results = regex.exec(url);

  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
// Check that data exist and it !== null
export function checkForExist(data: any) {
  return data && data !== null ? true : false;
}

export function hideFbLogin() {
  return (isInFacebookApp() && !isIos()) || isInInstagramApp();
}

export function hideGmailLogin() {
  return isInInstagramApp() || isInFacebookApp();
}

export function numberWithApostrophes(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\'');
}

export function productNearestDate(array: any[]) {
  if (Array.isArray(array) && array.length) {
    const dateNow = moment().utcOffset(0);
    dateNow.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    dateNow.format();
    const currentDate = dateNow.toISOString().slice(0, 19) + '+00:00';
    for (const item of array) {
      if (!Array.isArray(item.dates)) {
        continue;
      }
      const nearestDate = item.dates.find((date: string) => {
        return currentDate === date || moment(currentDate).isBefore(date);
      });
      item.dates = nearestDate;
      item.date = nearestDate;
    }
    return array;
  }

  return [];
}

export function setMainColors(color: string) {
  const root: any = document.querySelector(':root');
  root.style.setProperty('--primary-color', color);
  root.style.setProperty('--org-primary', color);
  OrganizerModule.setOrgColor(color);
}

export function setLangForMeta(lang: string) {
  switch (lang) {
    case 'fr': return 'fr_FR';
    case 'de': return 'de_DE';
    case 'it': return 'it_IT';
    case 'nl': return 'nl_BE';
    case 'es': return 'es-ES';
    case 'hu': return 'hu-HU';
    default: return 'en_GB';
  }
}

// Returns a promise of an array of countries with their names and codes
export async function fillCountries(language: string = 'en'): Promise<Array<{ name: string, code: string }>> {
  return await listCountries(language).then(({ countries }) => {
    if (!Array.isArray(countries)) {
      return [];
    } else {
      return countries;
    }
  });
}

export function getCountryCode(
  countries: Array<{ name: string, code: string }> | undefined,
  countryName: string): string {
  // We passed countries as an available array of countries as an argument to avoid
  // making an API call everytime this function is called,
  // we know we would have already gotten a list of countries somewhere before calling this function.
  if (countries) {
    return countries.filter((country) => country.name === countryName).map((country) => country.code)[0];
  }
  return '';
}

export function getCountryName(
  countries: Array<{ name: string, code: string }> | undefined,
  countryCode: string): string {
  // Same principle as in getCountryCode() function
  if (countries) {
    return countries.filter((country) => country.code === countryCode).map((country) => country.name)[0];
  }
  return '';
}

// Takes an array of countries and returns their names only in an array
export function getCountriesNames(countries: Array<{ name: string, code: string }> | undefined): string[] {
  if (countries) {
    return countries.map((country) => {
      return country.name;
    });
  }
  return [];
}

// Checks if the organiser is equilibre !!
export function isEquilibre(orgId: number) {
  return [16192, 18773].includes(orgId);
}

// Format the Categories list we get from Google Sheet into a formulated json
export function formateCategories(rows: any) {
  const formattedData: any[] = [];

  rows.map((row: any) => {
    formattedData.push({
      name: row.row[0] ? row.row[0].v : '',
      img: row.row[1] ? row.row[1].v : '',
      translations: {
        fr: row.row[2] ? row.row[2].v : '',
        de: row.row[3] ? row.row[3].v : '',
        it: row.row[4] ? row.row[4].v : '',
        ar: row.row[5] ? row.row[5].v : '',
      },
    });
  });

  return formattedData.filter((category) => category.name !== '' && category.name !== 'Name');
}

// Format the Categories list we get from Google Sheet into a formulated json
export function formateRegions(rows: any) {
  const formattedData: any[] = [];

  rows.map((row: any) => {
    formattedData.push({
      label: row.row[0] ? row.row[0].v : '',
      value: row.row[0] ? row.row[0].v : '',
      img: row.row[1] ? row.row[1].v : '',
      location: { center: row.row[2] ? row.row[2].v : '', distance: 30, unit: 'km' },
      translations: {
        fr: row.row[3] ? row.row[3].v : '',
        de: row.row[4] ? row.row[4].v : '',
        it: row.row[5] ? row.row[5].v : '',
        ar: row.row[6] ? row.row[6].v : '',
      },
    });
  });

  // Going to add the word 'All' with the list of locations. Why?
  // User may want to unselect all radio buttons of Regions, he can do this by selecting 'All'.
  // But in the list of Regions shown in mobile device we will remove this ignored location ('All')

  formattedData.unshift({
    label: 'All',
    value: '',
    img: '',
    location: { center: '', distance: -1, unit: 'km' },
    translations: {
      fr: 'All',
      de: 'All',
      it: 'All',
      ar: 'All',
    },
  });

  return formattedData.filter((region) => region.label !== '' && region.label !== 'Name');
}

// Format the Organizer of the Month we get from Google Sheet into a formulated json
export function formateOrganizerOfTheMonth(rows: any) {
  const formattedOrganizer: any = {};

  formattedOrganizer.id = rows[0].row[0] ? rows[0].row[0].v : '';
  formattedOrganizer.name = rows[0].row[1] ? rows[0].row[1].v : '';
  formattedOrganizer.city = rows[0].row[2] ? rows[0].row[2].v : '';
  formattedOrganizer.country = rows[0].row[3] ? rows[0].row[3].v : '';
  formattedOrganizer.coming_activities = rows[0].row[4] ? rows[0].row[4].v : '';
  formattedOrganizer.img = rows[0].row[5] ? rows[0].row[5].v : '';
  formattedOrganizer.color = rows[0].row[6] ? rows[0].row[6].v : '';
  formattedOrganizer.organiser_slug_name = rows[0].row[7] ? rows[0].row[7].v : '';
  formattedOrganizer.activities = [];
  formattedOrganizer.translations = {
    city: {
      fr: rows[0].row[8] ? rows[0].row[8].v : '',
      de: rows[0].row[10] ? rows[0].row[10].v : '',
      it: rows[0].row[12] ? rows[0].row[12].v : '',
      ar: rows[0].row[14] ? rows[0].row[14].v : '',
    },
    country: {
      fr: rows[0].row[9] ? rows[0].row[9].v : '',
      de: rows[0].row[11] ? rows[0].row[11].v : '',
      it: rows[0].row[13] ? rows[0].row[13].v : '',
      ar: rows[0].row[15] ? rows[0].row[15].v : '',
    },
  };

  for (let i = 4; i <= 5; i++) {
    formattedOrganizer.activities.push(
      {
        id: rows[i].row[0] ? rows[i].row[0].v : '',
        city: rows[i].row[1] ? rows[i].row[1].v : '',
        country: rows[i].row[2] ? rows[i].row[2].v : '',
        // We'll take 'f' property instead of 'v' because this is a Date format in the sheet
        // and we get Date(1/1/2022) in the 'v' response
        // whereas we get '1/1/2022' as 'f' value
        dates: rows[i].row[3] ? new Date(rows[i].row[3].f).toISOString() : '',
        category: rows[i].row[4] ? rows[i].row[4].v : '',
        product_name: rows[i].row[5] ? rows[i].row[5].v : '',
        cover_picture: rows[i].row[6] ? rows[i].row[6].v : '',
        currency: rows[i].row[7] ? rows[i].row[7].v : '',
        advertised_price: rows[i].row[8] ? rows[i].row[8].v : '',
        offer: rows[i].row[9] ? rows[i].row[9].v : '',
        organiser_name: formattedOrganizer.name,
        organiser_slug_name: formattedOrganizer.organiser_slug_name,
        organiser_color: formattedOrganizer.color,
        branding_smeetz_primary_color: formattedOrganizer.color,
        short_name: rows[i].row[10] ? rows[i].row[10].v : '',
        translations: {
          city: {
            fr: rows[i].row[11] ? rows[i].row[11].v : '',
            de: rows[i].row[16] ? rows[i].row[16].v : '',
            it: rows[i].row[21] ? rows[i].row[21].v : '',
            ar: rows[i].row[26] ? rows[i].row[26].v : '',
          },
          country: {
            fr: rows[i].row[12] ? rows[i].row[12].v : '',
            de: rows[i].row[17] ? rows[i].row[17].v : '',
            it: rows[i].row[22] ? rows[i].row[22].v : '',
            ar: rows[i].row[27] ? rows[i].row[27].v : '',
          },
          category: {
            fr: rows[i].row[13] ? rows[i].row[13].v : '',
            de: rows[i].row[18] ? rows[i].row[18].v : '',
            it: rows[i].row[23] ? rows[i].row[23].v : '',
            ar: rows[i].row[28] ? rows[i].row[28].v : '',
          },
          product_name: {
            fr: rows[i].row[14] ? rows[i].row[14].v : '',
            de: rows[i].row[19] ? rows[i].row[19].v : '',
            it: rows[i].row[24] ? rows[i].row[24].v : '',
            ar: rows[i].row[29] ? rows[i].row[29].v : '',
          },
          offer: {
            fr: rows[i].row[15] ? rows[i].row[15].v : '',
            de: rows[i].row[20] ? rows[i].row[20].v : '',
            it: rows[i].row[25] ? rows[i].row[25].v : '',
            ar: rows[i].row[30] ? rows[i].row[30].v : '',
          },
        },
      },
    );
  }

  return formattedOrganizer;
}

/**
 * Takes a number and returns its 'x' dicimal digits
 */
export function getDecimalDigits(n: number, x: number) {
  // n: 7.590 and x: 2 --> .59
  return (n % Math.floor(n)).toFixed(x).slice(1);
}

/**
 * Loads a css file using it's link.
 * Skips loading the file if the file was already loaded.
 *
 * @param href : css file link
 */
export function loadCssFile(href: string) {
  // Skip if file was already loaded
  if (document.querySelector(`link[href="${href}"]`)) {
    return;
  }

  // Create link tag
  const link = document.createElement('link');
  link.setAttribute('href', href);
  link.setAttribute('type', 'text/css');
  link.setAttribute('rel', 'stylesheet');
  link.setAttribute('media', 'screen,print');

  // Add link tag to head
  document.getElementsByTagName('head')[0].appendChild(link);
}

export function getCookies(): any {
  return document.cookie.split(';').map((cookie) => cookie.split('='))
  .reduce((accumulator, [key, value]) => ({ ...accumulator, [key.trim()]: decodeURIComponent(value) }), {} );
}

export function getFbp() {
  return ReferrerModule.fbp || getCookies()._fbp || undefined;
}

export function getFbc() {
  if (inIframe()) {
    return ReferrerModule.fbc ? ReferrerModule.fbc : undefined;
  } else {
    return getCookies()._fbc ? getCookies()._fbc :
    ReferrerModule.fbclid ? `fb.1.${Date.now()}.${ReferrerModule.fbclid}` : undefined;
  }
}

export function getFbPixelTracking() {
  if (getFbp() && getFbc()) { return `fbp=${getFbp()}&fbc=${getFbc()}`; }
  if (getFbp()) { return `fbp=${getFbp()}`; }
  return undefined;
}

export function genUID() {
  return randomstring({
    length: 20,
  });
}

export function logInfo(message: string, data?: Record<any, any>) {
  if (!data) {
    LoggerService.info(message);
    return;
  }

  LoggerService.info(message, { params: JSON.stringify(data, null, 2) });
}

export function isRealMobile(): boolean {
  let check: boolean = false;
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    check = true;
  }
  return check;
}

export function disableScrolling() {
  document.documentElement.style.overflow = 'hidden';
}

export function  enableScrolling() {
  document.documentElement.style.overflow = '';
}

export function buildOrderB2bUrl() {
  const bookingRef = NBookingModule.bookingRes?.bookingRef;
  const groupId = OrganizerModule.id;
  const bookingId = NBookingModule.bookingRes?.bookingId;
  const env = process.env.VUE_APP_BUILD_ENV;
  let domain = '';
  if (env === 'production') {
    domain = 'https://app.smeetz.com';
  } else if (env === 'staging') {
    domain = 'https://test-app.smeetz.com';
  } else {
    domain = 'https://dev-app.smeetz.com';
  }

  const url = `${domain}/order/${bookingRef}/overview?bookingId=${bookingId}&lang=en&groupId=${groupId}`;
  return url;
}

// To check if the widget is a side panel
// we check if it has a mobile size but not a mobile user agent
export function isSidePanelWidget() {
  if (!inIframe()) {
    return false;
  }

  const isMobile = AppModule.isMobile;

  if (isMobile && !isRealMobile()) {
    return true;
  }

  return false;
}

export function setVisibleTicketsOnStorage(categoryIds: string) {
  if (!inIframe()) {
    return;
  }

  localStorage.setItem('categories', categoryIds);
}

export function getVisibleTicketsFromStorage() {
  const tickets = localStorage.getItem('categories');
  // if we have ticket we return an array of categoryIds
  if (tickets) {
    return tickets.split(',').map((cat) => Number(cat));
  }
  return [];
}

export function removeVisibleTicketsFromStorage() {
  localStorage.removeItem('categories');
}





















































import { Component, Vue } from 'vue-property-decorator';
import { AppModule } from '@/utils/storemodules';
import { NNoty } from '@/utils/storemodules';
import { postEmailPreference, patchEmailPreference } from '@/api/common';
import { RouteNames } from '@/models/enums';

@Component
export default class UnsubscribeCustomer extends Vue {
  private loading: boolean = true;
  private eaReceiverId: any = null;
  private eaReceiverEmail: any = null;
  private isFormChanged = false;
  private inputData = {
    marketingEmail: true,
    transactionalEmail: false,
  };

  private get isMobile() {
    return AppModule.isMobile;
  }

  private get isTablet() {
    if (AppModule.isTablet && window.innerWidth < 968 || AppModule.isMobile) {
      return true;
    }
  }

  private created() {
    const { eaReceiverId, eaReceiverEmail } = this.$route.query;

    this.eaReceiverId = Number(eaReceiverId);
    this.eaReceiverEmail = eaReceiverEmail;
  }

  private async mounted() {
    try {
      const params = {
        eaReceiverId: this.eaReceiverId,
        eaReceiverEmail: this.eaReceiverEmail,
      };

      const response = await postEmailPreference(params);

      this.inputData.marketingEmail = !response?.data?.emailPreference?.isEmailMarketingOrganiser;
      this.loading = false;
    } catch (err) {
      this.loading = false;

      this.resetForm();
      this.$router.push({ name: RouteNames.PageNotFound });
    }
  }

  private onChange() {
    this.isFormChanged = true;
  }

// Clears form
  private resetForm() {
    this.inputData = {
      marketingEmail: true,
      transactionalEmail: false,
    };

    this.eaReceiverId = null;
    this.eaReceiverEmail = null;
    this.isFormChanged = false;

    (this.$refs.qForm as any).reset();
  }

  private async onSubmit() {
    try {
      this.loading = true;

      const params = {
        eaReceiverId: this.eaReceiverId,
        eaReceiverEmail: this.eaReceiverEmail,
        isEmailMarketingOrganiser: Number(!this.inputData.marketingEmail),
      };

      const response = await patchEmailPreference(params);

      this.inputData.marketingEmail = !response?.data?.emailPreference?.isEmailMarketingOrganiser;
      this.loading = false;

      NNoty.createNewNoty({
        message: this.$t('unsubscribe.on-success-msg') as string,
        period: 5000,
        type: 'success',
      });
    } catch (err) {
      this.loading = false;

      this.resetForm();
      this.$router.push({ name: RouteNames.PageNotFound });
    }
  }
}






import { Component, Vue } from 'vue-property-decorator';
import { ModalModule, NModalModule } from '@/utils/storemodules';
import { ModalStatus } from '@/models/definitions';

@Component({})
export default class ResetPass extends Vue {

  // @ModalModule.Mutation('setStatus') private setStatus: any;

  private created() {
    const route = this.$route;

    this.$router.push({
      path: '/',
      query: route.query,
    });

    // this.setStatus(ModalStatus.ChangePass);
    NModalModule.setStatus(ModalStatus.ChangePass);
  }
}

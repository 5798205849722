import Cookies from 'js-cookie';
import { ILoginResponse, IUserInformations } from '@/models/store/auth';
import { TRACK_ID } from '@/config/constants';
import jwtDecode from 'jwt-decode';

const msPerYear = 31536000 * 1000;
const cookieOptions = {
  expires: 365,
};

const TokenExpiry = 'expiresIn';
const Token = 'accessToken';
const TokenRefresh = 'refreshToken';
const tmp = 'Temp';


// Returns access token
export function getToken(temp: boolean = false): string {
  return Cookies.get(Token + isTemp(temp));
}

// Returns the token expiry in milliseconds
// from current date. The answer could be negative, which means
// that the token has expired.
//
// ex: expiryDate - Date.now()
export function getTokenExpiry(temp: boolean = false): number {
  return (new Date(Cookies.get(TokenExpiry + isTemp(temp)))).getTime() - Date.now();
}

// Returs refresh token
export function getTokenRefresh(temp: boolean = false): string {
  return Cookies.get(TokenRefresh + isTemp(temp));
}

// Sets cookie token, refresh token and token expiry
export function setTokenObj(tokenObject: ILoginResponse, temp: boolean = false) {

  // set new expiry date
  const expiresAt = new Date(Date.now() + tokenObject.expires_in * 1000);

  Cookies.set(TokenExpiry + isTemp(temp), expiresAt, cookieOptions);
  Cookies.set(Token + isTemp(temp), tokenObject.access_token, cookieOptions);
  Cookies.set(TokenRefresh + isTemp(temp), tokenObject.refresh_token, cookieOptions);

  return;
}

// Returns token, token expiry date and refresh token
export function getTokenObj(temp: boolean = false): ILoginResponse {
  return {
    access_token: getToken(temp),
    expires_in: getTokenExpiry(temp),
    refresh_token: getTokenRefresh(temp),
  };
}

// Clears the token related cookies
export function removeToken(temp: boolean = false) {
  Cookies.remove(Token + isTemp(temp));
  Cookies.remove(TokenExpiry + isTemp(temp));
  Cookies.remove(TokenRefresh + isTemp(temp));
}

// returns a string that should be postfixed to the cookie name
// based on whether the cookie is temporary or not
// ex: expiresInTemp
function isTemp(temp: boolean): string {
  return temp ? tmp : '';
}

/**
 * Returns the tracking id if set
 */
export function getTrackId(): string {
  return Cookies.get(TRACK_ID) || '';
}

export function stripTrackId(trackId: string) {
  const unescapedId = unescape(trackId);
  return unescapedId.slice(2, 18);
}

/* Helper function to extract user information from smeetz access token recived from tracker*/
export function extractUserInfoFromSmtzToken(token: string) {
  const decodedToken = jwtDecode(token) as IUserInformations;
  if (!decodedToken) {
    return;
  }
  const { firstName, lastName, email, groupId } = decodedToken;
  return { firstName, lastName, email, groupId };
}

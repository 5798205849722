



























































import {Vue, Component, Prop} from 'vue-property-decorator';
import { email, required, maxLength } from 'vuelidate/lib/validators';
import { Validator } from '@/utils/validators';
import Recaptcha from '@/components/Recaptcha.vue';

@Component({
  components: {
    Recaptcha,
  },
  validations: {
    email: {
      required,
      email,
      maxLength: maxLength(255),
    },
  },
})
export default class Newsletter extends Vue {
  @Prop({default: false})
  public noBackground!: boolean;

  private el: HTMLElement | null = document.getElementById('recaptchaResponse');
  // private recaptchaResponse: Recaptcha = new Recaptcha();
  private recapt: string = '';

  private email: string = '';
  private imgBackground: string = '/assets/img/rasterized/640px/newsletter/newsletter_mailbox_illustration.png';

/*
  public consoleLog() {
    return !this.enableSubmit;
    // return this.recaptchaResponse.recaptchaVerify();
  }
*/

  get enableSubmit() {
    return this.recapt && this.email && !this.$v.$error;
    // return this.recaptchaResponse.recaptchaVerify() && !this.$v.$error;
    // return this.recaptchaResponse.onVerify() && !this.$v.$error;
    // return this.email && !this.$v.$error ;
  }

  private respRecaptcha(response: any): any {
    this.recapt = response;
  }

  private async submit() {
    return this.recapt;
    if (!this.enableSubmit) {
      return 'Disabled';
    } else {
      return 'Ok';
    }
  }

  // private mounted() {
  //   console.log(this.recapt);
  // }

}

// const resp: Newsletter = new Newsletter();
// console.log(resp.consoleLog());

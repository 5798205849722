

















/*
 * This is just a component that will override some styling issues
 * that we had in the old site.
 */
import { Component, Vue, Watch } from 'vue-property-decorator';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
  'beforeRouteLeave',
]);
import ModalOverlay from '@/components/modal/ModalOverlay.vue';
import NewModalOverlay from '@/components/modal/NewModalOverlay.vue';
import { ModalStatus } from '../models/definitions';
import { NModalModule, NNoty } from '../utils/storemodules';
import Noty from 'noty';
import request from '@/utils/request';
import langs from '@/quasar/langs';
import { isIE } from '@/utils/platform';

const statusesForNewModal = [
  ModalStatus.ReportActivity,
  ModalStatus.NewLogin,
  ModalStatus.NewRegistration,
  ModalStatus.NewEmailConfirmation,
  ModalStatus.NewForgotPassword,
  ModalStatus.NewResetPassword,
  ModalStatus.bookingTimeEnd,
  ModalStatus.ContactOrganizer,
  ModalStatus.CropImage,
  ModalStatus.policyAgreement,
];

@Component({
  components: {
    ModalOverlay,
    NewModalOverlay,
  },
})
export default class QSite extends Vue {
  // this one is needed to access the enum from the html
  private ModalStatus = ModalStatus;
  private documentBody: HTMLElement = document.getElementsByTagName('body')[0];

  private get getModalStatus(): ModalStatus {
    return NModalModule.status;
  }

  private get newModalStatuses() {
    return statusesForNewModal.includes(this.getModalStatus);
  }

  private get newNotyStatus() {
    return NNoty.isNotyShown;
  }

  @Watch('newNotyStatus')
  private onErrorShow(val: boolean) {
    if ( val === true ) {
      new Noty({
        type: NNoty.errorState.type,
        text: NNoty.errorState.message,
        layout: 'topRight',
        timeout: NNoty.errorState.timeout,
        theme: 'metroui',
        progressBar: false,
        closeWith: ['click'],
        killer: true,
      }).show();
      NNoty.showNoty();
    }
  }

  @Watch('$store.state.auth.lang')
  private onLangChanged(newLang: 'en' | 'de' | 'fr' | 'it') {

    // ensure that we are using a supported language
    if (!(['en', 'fr', 'de', 'it'].includes(newLang))) {
      // tslint:disable-next-line
      this.setQLang(langs['en']);
    } else {
      this.setQLang(langs[newLang]);
    }
  }

  private created() {
    this.documentBody.classList.add('smtz-q-app');
    const { locale } = this.$route.params;
    if ( locale ) {
      this.$i18n.locale = locale;
      this.setQLang((langs as any)[locale]);
      const HTML_TAG = document.getElementsByTagName('html');
      (HTML_TAG as any)[0].lang = this.$i18n.locale;
      request.defaults.baseURL = (request.defaults.baseURL as string).slice(0, -3) + this.$i18n.locale + '/';
    }

    if (isIE()) {
      return this.$router.push(`/${this.$i18n.locale}/unsup`);
    }
  }

  private beforeDestroy() {
    this.documentBody.classList.remove('smtz-q-app');
  }

  private setQLang(lang: any) {
    try {
      this.$q.lang.set(lang);
    } catch (err) {
      // Requested Quasar Language Pack does not exist,
      // let's not break the app, so catching error
    }
  }
}










import { ICalendarLegend } from '@/models/site';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class CalendarLegend extends Vue {
  @Prop({default: null}) public legends!: ICalendarLegend[];
}


















import { Vue, Component, Prop } from 'vue-property-decorator';
import { Mixins } from 'vue-mixin-decorator';
import { IGQLProductSlotBookingRead } from '@/models/store/booking';
import DateHelperMixin from '@/mixins/DateHelper';
import { AppModule, RBookingModule } from '@/utils/storemodules';
import NewButton from '@/components/presentational/NewButton.vue';
import TicketsCard from '../MyBooking/TicketsCard.vue';
import { RefundSteps } from '@/store/modules/refundBooking/constants';


@Component({
  components: {
    TicketsCard,
    NewButton,
  },
})
  export default class SelectTickets extends Mixins<DateHelperMixin>(
  DateHelperMixin,
)  {

    private get organizerName(): string {
      return this.booking.ProductSlotStateList[0].ProductSlotCategoryRead.ProductRead.GroupRead.Name;
    }
    private get isSelectedSlots() {
      return !!RBookingModule.selectedSlots.length;
    }
    private get slotsGroupedByPriceName() {
     return RBookingModule.slotsGroupedByPriceName;
    }
    get isMobile() {
      return AppModule.isMobile;
    }

    @Prop({ default: {} }) private booking!: IGQLProductSlotBookingRead;

    private goRefundSummary() {
      RBookingModule.setCurrentStep(RefundSteps.RefundSummary);
    }
  }



















































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Mixins } from 'vue-mixin-decorator';
import Debug from 'debug';
import Calendar from '@/components/presentational/Calendar.vue';
import Select from '@/components/presentational/NewSelect.vue';
import { EVCalendar, EVSelect } from '@/models/events';
import dayjs from 'dayjs';
import { ITimeTicketSlots, ITicketSlots, IDayTicketSlots, ITicketCount } from '@/models/store/gbooking';
import { GBookingModule, ReferrerModule } from '@/utils/storemodules';
import { canSlotHandleCount } from '@/utils/gbooking';
import DateHelperMixin from '@/mixins/DateHelper';
import { setWidgetHeight } from '@/utils/iframe';

const debug = Debug('smeetz:gbooking');

@Component({
  components: {
    Calendar,
    Select,
  },
})
export default class GroupDate extends Mixins<DateHelperMixin>(DateHelperMixin) {
  @Prop() public openDate!: Date;
  // @Prop({default: () => {return {}}})
  // @Prop({default: () => ({})}) public daysTicketsSlots!: IDayTicketSlots;
  // private isTicketSelected = GBookingModule.isTicketSelected;
  private EVCalendar = EVCalendar;
  private EVSelect = EVSelect;
  private slot: string = '';
  // private ticketCounts = GBookingModule.ticketCounts;

  get ticketCounts() {
    return GBookingModule.ticketCounts;
  }

  get isTicketSelected() {
    return GBookingModule.isTicketSelected;
  }

  get daysTicketsSlots() {
    return GBookingModule.daysTicketsSlot;
  }

  get minimumDate() {
    // minimum showing date should be either today or mFrom day.
    const mFromDate = ReferrerModule.mFrom ? dayjs(ReferrerModule.mFrom) : dayjs().subtract(1, 'd');
    return mFromDate.toDate();
  }

  get maximumDate(): Date | null {
    // maximum showing date comes from mTo parameter
    const mToDate = ReferrerModule.mTo ? dayjs(ReferrerModule.mTo).toDate() : null;
    return mToDate;
  }

  get chosenDate() {
    return GBookingModule.chosenDate;
  }

  get availabilities() {
    const times: string[] = [];

    if (GBookingModule.dayTimes.length === 1) {
      this.slot = GBookingModule.dayTimes[0];
    }

    for (const time of GBookingModule.dayTimes) {
      times.push(this.currentDate(time));
    }
    // Sorts the hours of one date.
    times.sort((dateOne, dateTwo) => {
      // Here we slice the date to retreive only the hour from the date then
      // we pass an arbitrary date because the string date we have in the array
      // can't be used generate a Date.
      return dayjs('1970/01/01 ' + dateOne.slice(19)).diff('1970/01/01 ' + dateTwo.slice(19));
    });
    return times;
  }

  get availableDates(): Date[] {
    const dates: Date[] = [];
    if (!this.isTicketSelected) {
      return dates;
    }
    const daysTicketsSlots = this.daysTicketsSlots;
    const count = this.ticketCounts;
    for (const day in daysTicketsSlots) {
      if (!day) {
        continue;
      }

      const daySlots = this.daysTicketsSlots[day];
      for (const timeSlot in daySlots) {
        if (!timeSlot) {
          continue;
        }

        const slotTickets = daySlots[timeSlot];
        if (canSlotHandleCount(slotTickets, count)) {
          dates.push(dayjs(day).toDate());
        }
      }
    }

    return dates;
  }

  get timeSlot() {
    const timeSlots = GBookingModule.timeSlots[0];
    const timeSlot = timeSlots.find((slot) => slot.startDateTime === this.slot);
    return timeSlot;
  }

  get startDate() {
    this.chooseTime(this.slot);
    return this.currentDate(String(this.timeSlot?.startDateTime));
  }

  get endDate() {
    return this.currentDate(String(this.timeSlot?.endDateTime));
  }

  private onDateChange(date: Date) {
    // tslint:disable-next-line
    // console.log(date);
    GBookingModule.setDate(date);

    // delay a bit to give time for new elements to be rendered
    setTimeout(() => {
      setWidgetHeight();
    }, 300);
  }

  private chooseTime(time: string) {
    for (const t of GBookingModule.dayTimes) {
      if ((this.currentDate(t)) === time) {
        GBookingModule.setStartTime(t);
      }
    }
  }

  private onMonthChange(date: string) {
    this.$emit(EVCalendar.MonthChanged, dayjs(date).format('YYYY-MM'));
  }

}

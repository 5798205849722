



















































































































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class ProductHighlights extends Vue {
  @Prop({default: null}) private highlightsData!: any;

  private getHighlights(highlight: string) {
    if ( this.highlightsData ) {
      let dataString = '';
      for ( const data of Object.keys(this.highlightsData[highlight])) {
        const word = (this.highlightsData[highlight][data] as string).replace(/ /g, '-').toLowerCase();
        const translationWord = this.$t(`highlights.${word}`);
        dataString = dataString.concat(translationWord + ', ');
      }
      return dataString.slice(0, -2);
    }
  }

  private highlightExist(highlightName: string) {
    if ( this.highlightsData ) {
      const highlight = this.highlightsData[highlightName];
      return highlight && highlight.length !== 0;
    }
  }
}





























































































































import { Component, Vue } from 'vue-property-decorator';
import { email, required, minLength, maxLength, between, sameAs } from 'vuelidate/lib/validators';
import { Validator } from '@/utils/validators';
import { AuthModule, ModalModule } from '@/utils/storemodules';
import { Status } from '@/utils/status';
import { userIdentityTracking } from '@/utils/tracking';
import { ModalStatus } from '@/models/definitions';
import Debug from 'debug';
const debug = Debug('smeetz:auth');

@Component({
  validations: {
    email: {
      required,
      email,
      maxLength: maxLength(255),
    },
    password: {
      minLength: minLength(8),
      hasUpperAndLower: Validator.hasUpperAndLower,
      hasNum: Validator.hasNumbers,
      hasSpecial: Validator.hasSpecialCharacter,
    },
  },
})
export default class LocalLoginForm extends Vue {
  private invalidLogin: boolean = false;
  private isLoading: boolean = false;

  private email: string = '';
  private password: string = '';

  @AuthModule.Action('login') private login: any;
  @ModalModule.Mutation('setStatus') private setStatus: any;

  get enableSubmit() {
    return !this.$v.$error && this.password;
  }

  private clearInvalid() {
    this.invalidLogin = false;
  }

  private async submit() {
    if (!this.enableSubmit) {
      return;
    }
    this.isLoading = true;

    // Submit logic goes here
    try {

      userIdentityTracking({ email: this.email });

      await this.login({
        username: this.email,
        password: this.password,
      });

      this.$router.push('/');
    } catch (err) {
      const response = err.response;
      this.isLoading = false;
      if (!response) {
        debug('No response');
        return;
      }

      if (Status[response.status] === 'Bad Request') {
        debug('Invalid credentials');
        this.invalidLogin = true;
        return;
      } else if (Status[response.status] === 'Unauthorized') {
        debug('Unconfirmed user, send him another email');
        this.$router.push({
          path: '/',
          query: {
            email: this.email,
          },
        });
        this.setStatus(ModalStatus.EmailConf);
        return;
      }

      debug('ooops Nothing here');
    }
    this.isLoading = false;
  }

  private forgottenPass() {
    this.$router.push('/');
    this.setStatus(ModalStatus.ForgotPass);
  }
}

import { VuexModule, Module, MutationAction, Action, Mutation } from 'vuex-module-decorators';
import { getCategoriesList, getRegionsList, getOrganizerOfTheMonth } from '@/api/common';
// import { IProduct, IPicturesEntity } from '@/models/store/booking';
import { IRegionState, IActivity, IProductCategory } from '@/models/store/region';
import { client } from '@/utils/searchConfig';
import moment from 'moment';
import { productNearestDate, formateCategories, formateRegions, formateOrganizerOfTheMonth } from '@/utils/helpers';
import { ProductSearchCity } from '@/models/store/products';

@Module({ name: 'region', namespaced: true })
class Region extends VuexModule implements IRegionState {
  public bestDeals: IActivity[] = [];
  public topActivities: IActivity[] = [];
  public recommendedForYou: IActivity[] = [];
  public activitiesForWeather: IActivity[] = [];
  public happeningToday: IActivity[] = [];
  public organizerOfMonth: IActivity[] = [];
  public exploreZurich: IActivity[] = [];
  public categories: IProductCategory[] = [];
  public regions: ProductSearchCity[] = [];
  public monthOrganizer: any = null;

  public get getBestDeals() {
    return this.bestDeals;
  }

  public get getTopActivities() {
    return this.topActivities;
  }

  public get getRecommendedForYou() {
    return this.recommendedForYou;
  }

  public get getActivitiesForWeather() {
    return this.activitiesForWeather;
  }

  public get getHappeningToday() {
    return this.happeningToday;
  }

  public get getExploreZurich() {
    return this.exploreZurich;
  }

  public get getRegions() {
    return this.regions;
  }

  public get getCategories() {
    const categories: IProductCategory[] = [
      {
        name: 'class',
        translations: { en: 'Class', fr: 'Cours', de: 'Klasse', it: 'Lezione', ar: '' },
        img: '',
      },
      {
        name: 'experience',
        translations: { en: 'Experience', fr: 'Expérience', de: 'Experience', it: 'Esperienza', ar: '' },
        img: '',
      },
      {
        name: 'nightlife',
        translations: { en: 'Nightlife', fr: 'Vie nocturne', de: 'Nachtleben', it: 'Vita Notturna', ar: '' },
        img: '',
      },
      {
        name: 'festival',
        translations: { en: 'Festival', fr: 'Festival', de: 'Festival', it: 'Festival', ar: '' },
        img: '',
      },
      {
        name: 'fitness',
        translations: { en: 'Fitness', fr: 'Fitness', de: 'Fitness', it: 'Fitness', ar: '' },
        img: '',
      },
      {
        name: 'adrenaline',
        translations: { en: 'Adrenaline', fr: 'Adrénaline', de: 'Adrenalin', it: 'Adrenalina', ar: '' },
        img: '',
      },
      {
        name: 'event',
        translations: { en: 'Event', fr: 'Evénement', de: 'Veranstaltung', it: 'Evento', ar: '' },
        img: '',
      },
      {
        name: 'art',
        translations: { en: 'Art', fr: 'Art', de: 'Kunst', it: 'Arte', ar: '' },
        img: '',
      },
      {
        name: 'sport',
        translations: { en: 'Sport', fr: 'Sport', de: 'Sport', it: 'Sport', ar: '' },
        img: '',
      },
      {
        name: 'family',
        translations: { en: 'Family', fr: 'Famille', de: 'Familie', it: 'Famiglia', ar: '' },
        img: '',
      },
      {
        name: 'theme_park',
        translations: { en: 'Theme Park', fr: 'Parc d’attractions', de: 'Themenpark', it: 'Parco di divertimenti', ar: '' },
        img: '',
      },
    ];
    return categories;

    // --->important<---: WHEN DONE WITH GOOGLE SHEET JUST COMMENT THE ABOVE AND UNCOMMENT THE BELOW
    // TO MAKE GOOGLE SHEET IS THE SOURCE OF DATA OF CATEGORIES
    // return this.categories;
  }

  public get getMonthOrganizer() {
    return this.monthOrganizer;
  }

  @Mutation
  public setBestDeals(data: IActivity[]) {
    this.bestDeals = this.bestDeals.concat(data);
  }

  @Mutation
  public setTopActivities(data: IActivity[]) {
    this.topActivities = this.topActivities.concat(data);
  }

  @Mutation
  public setRecommendedForYou(data: IActivity[]) {
    this.recommendedForYou = this.recommendedForYou.concat(data);
  }

  @Mutation
  public setActivitiesForWeather(data: IActivity[]) {
    this.activitiesForWeather = this.activitiesForWeather.concat(data);
  }

  @Mutation
  public setHappeningToday(data: IActivity[]) {
    this.happeningToday = data;
  }

  @Mutation
  public setOrganizerOfMonths(data: IActivity[]) {
    this.organizerOfMonth = this.organizerOfMonth.concat(data);
  }

  @Mutation
  public setExploreZurich(data: IActivity[]) {
    this.exploreZurich = data;
  }

  @Mutation
  public addDataToHappeningToday(data: IActivity[]) {
    this.happeningToday = this.happeningToday.concat(data);
  }

  @Mutation
  public setCategories(data: any[]) {
    this.categories = data;
  }

  @Mutation
  public setRegions(data: any[]) {
    this.regions = data;
  }

  @Mutation
  public setMonthOrganizer(data: any[]) {
    this.monthOrganizer = data;
  }

  @Action({ rawError: true })
  public async fetchCategories() {
    // we'll fetch list of categories from a google sheet
    try {
      const response = await getCategoriesList();

      if (response) {
        let categories: any[] = [];
        const rows: any[] = response.table.rows.map((r: any) => {
          return { row: r.c };
        });

        categories = formateCategories(rows);
        this.setCategories(categories);
      } else {
        throw Error('FetchCategories: error');
      }
    } finally {
      // this.setLoading(false);
    }
  }

  @Action({ rawError: true })
  public async fetchRegions() {
    // we'll fetch list of regions from a google sheet
    try {

      const regions = [
        {
          label: 'All',
          value: '',
          img: '',
          location: { center: '', distance: -1, unit: 'km' },
          translations: {
            fr: 'All',
            de: 'All',
            it: 'All',
            ar: 'All',
          },
        },
        {
          label: 'Lausanne',
          value: 'Lausanne',
          img: 'https://media.istockphoto.com/photos/view-of-downtown-lausaunne-switzerland-picture-id1001600914?k=20&m=1001600914&s=612x612&w=0&h=SWi0tqx3B2Mn_XFHfhhbihZilLJR4znE8W95xAS_WKo=',
          location: {
            center: '46.5197, 6.6323',
            distance: 30,
            unit: 'km',
          },
          translations: {
            fr: 'FR Lausanne',
            de: 'DE Lausanne',
            it: 'IT Lausanne',
            ar: 'AR Lausanne',
          },
        },
        {
          label: 'Fribourg',
          value: 'Fribourg',
          img: 'https://media.istockphoto.com/photos/old-city-in-central-switzerland-picture-id180822290?k=20&m=180822290&s=612x612&w=0&h=uQp00wDxRJmYLFN2_j_m3VwtFgP34otZX_8VogAZptE=',
          location: {
            center: '46.8065, 7.1620',
            distance: 30,
            unit: 'km',
          },
          translations: {
            fr: 'FR Fribourg',
            de: 'DE Fribourg',
            it: 'IT Fribourg',
            ar: 'AR Fribourg',
          },
        },
        {
          label: 'Villars',
          value: 'Villars',
          img: 'https://media.istockphoto.com/photos/amazing-view-on-zermatt-famous-ski-resort-in-swiss-alps-with-aerial-picture-id657252756?k=20&m=657252756&s=612x612&w=0&h=KOfNdVYvfzRtwzSFEmfDniN129bWIaKd8Dw8SCiVpS0=',
          location: {
            center: '46.2984, 7.0550',
            distance: 30,
            unit: 'km',
          },
          translations: {
            fr: 'FR Villars',
            de: 'DE Villars',
            it: 'IT Villars',
            ar: 'AR Villars',
          },
        },
        {
          label: 'Zurich',
          value: 'Zurich',
          img: 'https://media.istockphoto.com/photos/zurich-cityscape-switzerland-picture-id459401895?k=20&m=459401895&s=612x612&w=0&h=4Q5D68tDQpGcruWX6fYf9F9ByzdFmuAaE1CwgQ_Il7I=',
          location: {
            center: '47.3769, 8.5417',
            distance: 30,
            unit: 'km',
          },
          translations: {
            fr: 'FR Zurich',
            de: 'DE Zurich',
            it: 'IT Zurich',
            ar: 'AR Zurich',
          },
        },
        {
          label: 'Bern',
          value: 'Bern',
          img: 'https://media.istockphoto.com/photos/bern-minster-switzerland-picture-id622195298?k=20&m=622195298&s=612x612&w=0&h=dMUJUPWYeUVMv3VXP6_51illo4MLKyNKqrQzREkervk=',
          location: {
            center: '46.9480, 7.4474',
            distance: 30,
            unit: 'km',
          },
          translations: {
            fr: 'FR Bern',
            de: 'DE Bern',
            it: 'IT Bern',
            ar: 'AR Bern',
          },
        },
        {
          label: 'Geneva',
          value: 'Geneva',
          img: 'https://media.istockphoto.com/photos/aerial-view-of-leman-lake-geneva-city-in-switzerland-picture-id530487878?k=20&m=530487878&s=612x612&w=0&h=TJNIOi9GYAnks9tl9RjvlYKdtBcWQVlPB-ZiPiT8ryE=',
          location: {
            center: '46.2044, 6.1432',
            distance: 30,
            unit: 'km',
          },
          translations: {
            fr: 'FR Geneva',
            de: 'DE Geneva',
            it: 'IT Geneva',
            ar: 'AR Geneva',
          },
        },
        {
          label: 'Lucerne',
          value: 'Lucerne',
          img: 'https://media.istockphoto.com/photos/aerial-view-of-lucerne-old-town-picture-id1150045914?k=20&m=1150045914&s=612x612&w=0&h=zzk0_rZETBJgadaTr1DaWORkjAKib95fxEvQ-HhTeZU=',
          location: {
            center: '47.0502, 8.3093',
            distance: 30,
            unit: 'km',
          },
          translations: {
            fr: 'FR Lucerne',
            de: 'DE Lucerne',
            it: 'IT Lucerne',
            ar: 'AR Lucerne',
          },
        },
      ];
      this.setRegions(regions);

      // --->important<---: WHEN DONE WITH GOOGLE SHEET JUST COMMENT THE ABOVE AND UNCOMMENT THE BELOW
      // TO MAKE GOOGLE SHEET IS THE SOURCE OF DATA OF REGIONS

      // const response = await getRegionsList();
      // if (response) {
      //   let regions: any[] = [];
      //   const rows: any[] = response.table.rows.map((r: any) => {
      //     return { row: r.c };
      //   });

      //   regions = formateRegions(rows);
      //   debug('regions', regions);
      //   this.setRegions(regions);
      // } else {
      //   throw Error('FetchRegions: error');
      // }
    } finally {
      // this.setLoading(false);
    }
  }

  @Action({ rawError: true })
  public async fetchMonthOrganizer() {
    // we'll fetch the organizer of the month from a google sheet
    try {
      const response = await getOrganizerOfTheMonth();

      if (response) {
        let organizer: any[] = [];
        const rows: any[] = response.table.rows.map((r: any) => {
          return { row: r.c };
        });

        organizer = formateOrganizerOfTheMonth(rows);
        this.setMonthOrganizer(organizer);
      } else {
        throw Error('FetchMonthOrganizer: error');
      }
    } finally {
      // this.setLoading(false);
    }
  }

  @Action({ rawError: true })
  public async fetchAppSearch(payload: any) {
    const dateNow = moment().utcOffset(0);
    dateNow.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    dateNow.format();
    const currentDate = dateNow.toISOString();
    if (payload.type !== 'HappeningToday' && !payload.options.filters) {
      Object.assign(payload.options, {
        filters: {
          dates: {
            from: currentDate,
          },
        },
      });
    }
    await client
      .search(payload.searchQuery ? payload.searchQuery : '', payload.options)
      .then((resultList: any) => {
        const arrayForData: any = [];
        resultList.results.forEach((result: any) => {
          Object.keys(result.data).forEach((parameter: any) => {
            // tslint:disable-next-line:max-line-length
            if ((result && result.data && result.data[parameter]) &&
            result.data[parameter].raw ||
            result.data[parameter].raw === '' ||
            result.data[parameter].raw === 0 ||
            result.data[parameter].raw === null) {
              const rawData = result.data[parameter].raw;
              result.data[parameter] = rawData;
            }
          });
          arrayForData.push(result.data);
        });
        const nearestProductsWithOneDate = productNearestDate(arrayForData);
        // tslint:disable-next-line:max-line-length
        const sortedByDateProducts = (nearestProductsWithOneDate as any[]).sort((a, b) => moment(a.dates).format('x') - moment(b.dates).format('x'));
        if (payload.type === 'HappeningToday' && this.happeningToday.length) {
          this.addDataToHappeningToday(sortedByDateProducts);
        } else {
          // @ts-ignore
          this[`set${payload.type}`](sortedByDateProducts);
        }

      })
      .catch((error: any) => {
        throw Error(`fetchAppSearch: ${error.message}`);
      });
  }
}
export default Region;

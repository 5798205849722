import { client } from '@/utils/graphqlClient/graphql-client';
import {
  GetProductsTicketsQuery,
  GetProductsTicketsQueryVariables,
} from '@/types/gql/generated/graphql';
import { GET_ProductsTickets } from './gql/ticket.graphql';
import { ProductTicket } from '@/types/gql/product';

/**
 * Returns overview of a given retails
 *
 * @param ids retails ids
 */
export async function getProductsTickets(ids: number[]) {
  // transform ids to array of strings
  const stringIds = ids.map((id) => String(id));
  const res = await client.query<GetProductsTicketsQuery, GetProductsTicketsQueryVariables>(
    GET_ProductsTickets,
    { ids: stringIds }).toPromise();

  return res.data?.tickets as ProductTicket[];
}

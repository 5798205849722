import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { ITicketPriceCount, ITicketCount, IDayTicketSlots, ITimeTicketSlots, ITicketSlots } from '@/models/store/gbooking';
import dayjs from 'dayjs';
import { canSlotHandleCount } from '@/utils/gbooking';
import { ITimeslotsEntity } from '@/models/store/booking';

@Module({ namespaced: true, name: 'gbooking' })
export default class GBooking extends VuexModule {
  // public priceCounts: ITicketPriceCount[] = [];
  public ticketCounts: ITicketCount = {};
  public daysTicketsSlot: IDayTicketSlots = {};
  public chosenDate: Date | null = null;
  public startTime: string | null = null;
  public timeSlots: ITimeslotsEntity[] [] = [];
  // checks whether a ticket price was selected or not
  public get isTicketSelected() {
    let selected = false;
    const ticketCounts = this.ticketCounts;
    for (const key in ticketCounts) {
      if (!key) {
        continue;
      }
      const ticketCount = ticketCounts[key];
      if (ticketCount && ticketCount.count) {
        selected = true;
        break;
      }
    }

    return selected;
  }

  get chosenDayTicketSlots(): ITimeTicketSlots | null {
    if (!this.chosenDate) {
      return null;
    }

    const dayTicketSlots = this.daysTicketsSlot[dayjs(this.chosenDate).format('YYYY-MM-DD')];
    if (!dayTicketSlots) {
      return null;
    }

    return dayTicketSlots;
  }

  get canBook(): boolean {
    if (!this.isTicketSelected || !this.chosenDayTicketSlots) {
      return false;
    }

    return true;
  }

  get total(): number {
    let total = 0;
    if (!this.isTicketSelected || !this.chosenDayTicketSlots) {
      return total;
    }

    const ticketCounts = this.ticketCounts;

    for (const ticketId in ticketCounts) {
      if (!ticketId) {
        continue;
      }

      // Ticket total is equal to the total of each price
      const ticketCount = ticketCounts[ticketId];
      for (const price of Object.values(ticketCount.prices)) {
        total += price.count * Number(price.price.priceValue);
      }

    }

    return total;
  }

  get ticketSlots(): ITicketSlots | null {
    const chosenDay = this.chosenDayTicketSlots;
    const startTime = this.getChosenTime;
    if (!chosenDay || !startTime) {
      return null;
    }

    const ticketSlots = chosenDay[startTime];
    if (!ticketSlots) {
      return null;
    }

    return ticketSlots;
  }

  get dayTimes(): string[] {
    const chosenDay = this.chosenDayTicketSlots;
    const ticketCount = this.ticketCounts;

    if (!chosenDay) {
      return [];
    }

    if (!this.isTicketSelected) {
      return [];
    }

    const times: string[] = [];
    for (const key in chosenDay) {
      if (!key) {
        continue;
      }
      const ticketsTime = chosenDay[key];
      if (canSlotHandleCount(ticketsTime, ticketCount)) {
        times.push(key);
      }
    }

    return times;
  }

  get getChosenTime(): string | undefined {
    return this.startTime || this.dayTimes[0];
  }

  @Mutation
  public setTicketCounts(count: any) {
    this.ticketCounts = count;
  }

  @Mutation
  public setDayTicketSlots(dayTicketSlots: IDayTicketSlots) {
    this.daysTicketsSlot = dayTicketSlots;
  }

  @Mutation
  public setDate(date: Date) {
    this.chosenDate = date;
  }

  @Mutation
  public setStartTime(startTime: string | null) {
    this.startTime = startTime;
  }

  @Mutation
  public setTimeSlots(timeSlots: ITimeslotsEntity [][]) {
    this.timeSlots = timeSlots;
  }
}











































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import OrderSummary from './OrderSummary.vue';
import PersonalDetails from './PersonalDetails.vue';
import SocialsLogin from './SocialsLogin.vue';
import LoginDialog from './LoginDialog.vue';
import CheckoutBasket from './CheckoutBasket.vue';
import { NNoty, UserModule, NBookingModule, ProductModule, AppModule, OrganizerModule, NModalModule } from '@/utils/storemodules';
import NewButton from '@/components/presentational/NewButton.vue';
import AccessCode from '@/components/booking/AccessCode.vue';
import Membership from '@/components/booking/Membership.vue';
import { EPersonalDetails } from '@/models/events';
import { ModalStatus } from '@/models/definitions';
import { bookingFlowInitCheckoutTracking } from '../../../utils/tracking';
import { getElementById, scrollToElement } from '@/utils/dom';
import OrderDeliveryInformation from './OrderDeliveryInformation.vue';
import { bookingDecorateErrorMessage } from '@/utils/debug';
import {inIframe} from '@/utils/iframe';
import { isRequiredTicketsMembership } from '@/utils/membership';
import { Loading } from 'quasar';
import { disableQuasarStyling, enableQuasarStyling } from '@/utils/styles';
import { isRealMembershipDiscount } from '@/utils/membership-utils';
import { LoggerService } from '@/services/log';
import { constants } from '@/config/constants.ts';
@Component({
  components: {
    OrderSummary,
    PersonalDetails,
    SocialsLogin,
    LoginDialog,
    NewButton,
    AccessCode,
    Membership,
    OrderDeliveryInformation,
    CheckoutBasket,
  },
})
export default class CheckoutStep extends Vue {
  @Prop({default: 0}) private windowWidth!: number;
  private isOpenLoginDialog: boolean = false;
  private isAlreadyLoggedIn: boolean = false;
  private isValidated: boolean = false;
  private isValidatedDelivery: boolean = false;
  private isSendData: boolean = false;
  private isSendDeliveryData: boolean = false;
  private isLoading = false;
  private EPersonalDetails = EPersonalDetails;
  private isMembership: boolean = false;
  private legalLink = '';

  private get requiresAccessCode() {
    return NBookingModule.isAccessCodeRequired;
  }

  private get isSkipedLogin() {
    return NBookingModule.isUserGuest;
  }

  private get isLogged() {
    return UserModule.isLogedIn;
  }

  private get isMobile() {
    return AppModule.isMobile;
  }

  private get showConfirmPopup() {
    return !(!this.isValidated ||
      (!this.isValidatedDelivery && this.isDelivery ||
      this.isDelivery && !this.isShippingMethodSelected))
      && this.isMobile;
  }

  private get isOrgUserLoggedIn() {
    return UserModule.isOrgUserLoggedIn;
  }

  private get isAuthWindowClosed() {
    return UserModule.authWindowClosed;
  }

  private get isHybridIntegration() {
    return AppModule.isHybridIntegration;
  }

  private orgUserLoggedIn() {
    enableQuasarStyling();
    Loading.show();
  }

  private get isDelivery() {
    return NBookingModule.dynamicShippingTickets.length > 0;
  }

  private get isFixedDelivery() {
    return NBookingModule.shippingTickets.length > 0;
  }

  private get user() {
    return UserModule.user;
  }

  private get isShippingMethodSelected() {
    const dynamicShippingTicketAdded = NBookingModule.bookingRes ?
      NBookingModule.bookingRes.bookingRecap.categories?.filter(
        (t) => t.isShipping === true && t.priceValue > 1) : null;

    return dynamicShippingTicketAdded && dynamicShippingTicketAdded.length > 0;
  }

  private get isRealMembershipDiscount() {
    const membership = NBookingModule.membership;
    return isRealMembershipDiscount(membership);
  }

  // Hide smeetz policies on iframe
  private get hideSmeetzPolicy() {
    return inIframe();
  }

  private get organizerInfo() {
    return NBookingModule.getOrganizerInfo;
  }

  private get policiesTermsWording(): string[] {
    return String(this.$t('new-booking-flow.checkout.personal-details-terms-with-cancelation')).split('||');
  }

  private openPolicyModal() {
    NModalModule.setStatus(ModalStatus.policyAgreement);
  }

  private openLoginDialog() {
    this.isOpenLoginDialog = true;
  }

  private closeLoginDialog() {
    this.isOpenLoginDialog = false;
  }

  private async mounted() {
    this.legalLink =  constants.legal_main[this.$i18n.locale];
    if (this.isLogged) {
      this.isAlreadyLoggedIn = true;
    }

    // TODO remove next comment once BUD-8305 is tested
    // const customers = NBookingModule.membershipCustomers;
    // if (customers.length) {
    if (NBookingModule.membership) {
      this.isMembership = true;
    }
    bookingFlowInitCheckoutTracking(NBookingModule.ticketsTracking);
  }

  // watch for isOrgUserLoggedIn to stop the loading
  @Watch('isOrgUserLoggedIn')
  private stopLoading() {
    if (Loading.isActive) {
      Loading.hide();
      disableQuasarStyling();
    }
  }

  @Watch('isAuthWindowClosed')
  private stopLoadingIfWindowClosed() {
    if (Loading.isActive) {
      Loading.hide();
      disableQuasarStyling();
    }
    UserModule.closedAuthWindow(false);
  }

  private onValidateFields(status: boolean) {
    this.isValidated = status;
  }

   private onValidateDeliveryFields(status: boolean) {
    this.isValidatedDelivery = status;
  }

  private async submit() {
    // If we are in a locarno booking we check if piazza grande prices were added
    const groupId = OrganizerModule.id;
    const isLocarno = [19017].includes(Number(groupId));
    if (isLocarno) {
      const mainTicketSeated = NBookingModule.piazzaSeatingPlanPrices;
      const mainTicketSeatedIds = Object.keys(mainTicketSeated);
      const bookingRecapSeated = NBookingModule.recapCategories.filter((cat) =>
        mainTicketSeatedIds.includes(cat.priceId + '_' + cat.timeSlotId) && cat.seat === null);
      const quantityAddedOfSeatedPrices = bookingRecapSeated.reduce((total, cat) => {
          return total + Number(cat.quantity);
        }, 0);

      const quantityAddedOfSeats = NBookingModule.recapCategories.reduce((total, cat) => {
          return cat.seat ? total + Number(cat.quantity) : total;
        }, 0);

      if (quantityAddedOfSeats < quantityAddedOfSeatedPrices) {
        NBookingModule.setPiazzaGrandeValidation(true);
        ProductModule.setRequiredNumberPiazzaSeats(quantityAddedOfSeatedPrices);
        const showId = mainTicketSeated[bookingRecapSeated[0].priceId + '_' + bookingRecapSeated[0].timeSlotId].showId;
        await ProductModule.fetchTicketsAddon({ids: [showId], isPiazzaAddon: true});
        LoggerService.info(`VALIDITY: Open seating plan ticket view to add missing seats`);
        NBookingModule.stepBack();
        return;
      }
      NBookingModule.setPiazzaGrandeValidation(false);
    }
    // If this activity contains ticket addons in second step we validate basket
    if (ProductModule.activityTicketAddons.length > 0) {
      const ticketsWithoutMin = NBookingModule.ticketsWithoutMinReached;
      if (ticketsWithoutMin.length) {
        for (const categoryId of ticketsWithoutMin) {
          NBookingModule.addPackageError({packageId: categoryId});
        }
        NBookingModule.stepBack();
        const firstTicket = ticketsWithoutMin[0];
        const element = getElementById(`ticket-${firstTicket}`);
        if (element) {
          scrollToElement(element);
        }
        return;
      }
    }
    this.isLoading = true;

    if (this.isDelivery) {
      // check retail's weight
      const bookingRes = NBookingModule.bookingRes;
      if (!bookingRes || !bookingRes.bookingRecap.categories) {
        return;
      }
      const hasWeightZero = bookingRes.bookingRecap.categories.find((cat) => cat.retailOptionId && cat.weight === 0);
      if (hasWeightZero) {
        NNoty.createNewNoty({
          period: 4000,
          message: String(this.$t('new-booking-flow.checkout.item-not-delivrable-error')),
          type: 'error',
        });
        const errorMessage = `Retail with weight set to 0: ${JSON.stringify(bookingRes.bookingRecap.categories)}`;
        this.onError(errorMessage);
        setTimeout(() => {
          throw Error(bookingDecorateErrorMessage(errorMessage));
        }, 0);
      }
    }

    if ( this.isValidated && this.isValidatedDelivery) {
      this.isSendDeliveryData = true;
    } else if (this.isValidated) {
      this.isSendData = true;
    }
  }

  private onError(err: any) {
    this.isLoading = false;
    this.isSendData = false;
    if (this.isValidatedDelivery) {
      this.isSendDeliveryData = false;
    }
  }

  private touch() {
    if (this.isDelivery && !this.isValidatedDelivery) {
      (this.$refs.orderDeliveryInformation as any).touchFields();
      const element = document.getElementById('order-delivery');
      if (element) {
        scrollToElement(element);
      }
    }
    (this.$refs.personaldetails as any).touchFields();
  }

}

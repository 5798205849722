import { client } from '@/utils/graphqlClient/graphql-client';
import {
  GetTicketDonationListQuery,
  GetTicketDonationListQueryVariables,
} from '@/types/gql/generated/graphql';
import { GET_TicketDonationList } from './gql/donation.graphql';
import { IDonationTicket } from '@/types/db/product-category';

/**
 * Returns a list of donation that are linked to categoryIds
 *
 * @param categoryIds ticket ids
 */
export async function getLinkedDonations(categoryIds: number[]) {

  const res = await client.query<GetTicketDonationListQuery, GetTicketDonationListQueryVariables>(
    GET_TicketDonationList,
    { categoryIds: `(CategoryId,in,${categoryIds.join(',')})` }).toPromise();

  return res.data?.TicketDonationList as IDonationTicket[];

}

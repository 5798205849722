










































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { AuthModule, OrganizerModule, ProductModule } from '@/utils/storemodules';
import dayjs from 'dayjs';
import { constants } from '@/config/constants.ts';
import LangMixin from '@/mixins/Lang';
import { Mixins } from 'vue-mixin-decorator';
import Debug from 'debug';
import { RouteNames } from '@/models/enums';
import { IProduct } from '@/models/store/product';




@Component({})
export default class ProductFooter extends Mixins(LangMixin) {
  @Prop({default: false}) private isMobile!: boolean;
  private year: string = dayjs().format('YYYY');
  private paymentMethods: string[] = [];
  private socials = [
    {
      site: 'instagram',
      link: 'https://www.instagram.com/smeetz.io/',
      img: `img/rasterized/icons/instagram_new.svg`,
    },
    {
      site: 'facebook',
      link: 'https://www.facebook.com/smeetz.io',
      img: `img/rasterized/icons/Facebook_new.svg`,
    },
    {
      site: 'linkedin',
      link: constants.social_link.linkedin,
      img: `img/rasterized/icons/LinkedIn_new.svg`,
    },
  ];

  private linksTitles: any = ['column1', 'column2', 'column3'];

  private async mounted() {
    if (this.isProductPage) {
      this.paymentMethods = await this.getOrganizerPaymentMethods().then((a) => a);
    } else {
      // this footer is not used in the product page, it is somewhere else
      this.paymentMethods = this.predefinedPaymentMethods();
    }
  }

  private get links() {
    return {
      column1: {
        items: 3,
        data: this.discoverLinks,
      },
      column2: {
        items: 4,
        data: this.companyLinks,
      },
      column3: {
        items: 3,
        data: this.languages,
      },
    };
  }

  get isProductPage(): boolean {
    return this.$route.name === RouteNames.Product || this.$route.name === RouteNames.ChannelProduct;
  }

  // returns a list of pre-defined payment methods if we're not
  // in the product page or the organizer page,
  // otherwise, we return the organizer payment methods
  private predefinedPaymentMethods(): string[] {
    return ['Mastercard.svg', 'Visa.svg', 'postfinance_logo.png', 'twint_logo.png'];
  }

  private async getOrganizerPaymentMethods() {
    const product: IProduct | null = ProductModule.product;
    if (product) {
      const organizer = (product as IProduct).organiser;

      const organiserSlug = organizer?.slugName;
      if (organiserSlug) {
        await OrganizerModule.getOrganizerInfo(organiserSlug);
      }
      const paymentsLogos = OrganizerModule.paymentsLogos;
      return paymentsLogos;
    }
    return [];
  }


  private get discoverLinks() {
    return [
      {
        name: this.$t('new-footer.column1-row1'),
        url: constants.social_link.smeetz,
      },
      // {
      //   name: this.$t('new-footer.column1-row2'),
      //   url: '#',
      // },
      {
        name: this.$t('new-footer.column1-row3'),
        url: constants.blog[this.$i18n.locale],
      },
    ];
  }

  private get companyLinks() {
    return [
      {
        name: this.$t('new-footer.column2-row1'),
        url: constants.company[this.$i18n.locale],
      },
      {
        name: this.$t('new-footer.column2-row2'),
        url: constants.support[this.$i18n.locale],
      },
      {
        name: this.$t('new-footer.column2-row3'),
        url: constants.business[this.$i18n.locale],
      },
      {
        name: this.$t('new-footer.column2-row4'),
        url: constants.legal_main[this.$i18n.locale],
      },
    ];
  }

  private goToLanding() {
    this.$router.push('/');
  }
}

import { VuexModule, Module, Mutation } from 'vuex-module-decorators';
import { ModalStatus } from '@/models/definitions';

// Adding the n to make sure it's a new modal
@Module({ name: 'nmodal', namespaced: true })
class Modal extends VuexModule {
  public status: ModalStatus = ModalStatus.Hidden;
  public popupTitle: string | null = null;
  public popupDescription: string | null = null;
  public cropUrl: File | null = null;

  get getCropFile() {
    return this.cropUrl;
  }

  get getPopupTitle() {
    return this.popupTitle;
  }

  get getPopupDescription() {
    return this.popupDescription;
  }

  @Mutation
  public setPopupTitle(popupTitle: string | null) {
    this.popupTitle = popupTitle;
  }

  @Mutation
  public setPopupDescription(popupDescription: string | null) {
    this.popupDescription = popupDescription;
  }

  @Mutation
  public setStatus(status: ModalStatus) {
    this.status = status;
  }

  @Mutation
  public setCropFile(payload: File | null) {
    this.cropUrl = payload;
  }

  @Mutation
  public setAuthType(payload: string) {
    // Calling modal module setAuthType
    return this.context.commit('modal/setAuthType', payload, { root: true });
  }
}

export default Modal;


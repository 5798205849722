








































import { Vue, Component } from 'vue-property-decorator';
import OrderSummary from './OrderSummary.vue';
import { NBookingModule, ProductModule} from '@/utils/storemodules';
import { Mixins } from 'vue-mixin-decorator';
import DonationMixin from '@/mixins/Donation';

@Component({
   components: {
    OrderSummary,
   },
})
export default class CheckoutBasket extends Mixins<DonationMixin>(DonationMixin) {
    private showBasket = false;

    get recapInfo() {
        return NBookingModule.recapInfo;
    }

    get total() {
        return this.recapInfo.total;
    }

    get currency() {
        return ProductModule.productCurrency;
    }

    get totalItemsQuantity() {
        let quantity = 0;
        NBookingModule.recapCategories.forEach((ticket) => {
            quantity += Number(ticket.quantity);
        });
        return quantity;
    }

    get itemText() {
        if (this.totalItemsQuantity <= 1) {
            return this.$t('new-booking-flow.checkout.order-summary-item');
        } else {
            return this.$t('new-booking-flow.checkout.order-summary-items');
        }

    }

    get viewOrderText() {
        if (this.showBasket) {
         return this.$t('new-booking-flow.checkout.order-summary-hide-order');
        }   else {
            return this.$t('new-booking-flow.checkout.order-summary-view-order');
        }
    }

    private showBasketOn() {
        this.showBasket = !this.showBasket;
        // When the basket opens we have to make sure that the screen stop scrolling
        // the scrolling will enabled only inside the Basket
        // afari overlooks "overflow: hidden" when applied to HTML so we Target the app
        // as well
        const newBooking: HTMLElement | null =  document.querySelector('.new-booking');
        if (this.showBasket) {
           document.documentElement.style.overflow = 'hidden';
           if (newBooking) {
                newBooking.style.position = 'fixed';
           }
        } else {
            // when closing the order summary enable back the scrolling
            document.documentElement.style.overflow = '';
            if (newBooking) {
                newBooking.style.position = '';
            }
        }
    }


    // If we have Donation we display automatically order summary
    private async created() {
    // We check if there are donation(s) linked to added tickets
        await this.checkDonation();
        if (this.donationCategory && this.total !== 0 && !ProductModule.viewedDonation) {
            this.showBasketOn();
        }
    }

}
























import { Vue, Component, Prop } from 'vue-property-decorator';
import NewButton from '@/components/presentational/NewButton.vue';

@Component({
  components: {
    NewButton,
  },
})
export default class BecomeProvider extends Vue {
  private goToProPage() {
    window.open(`https://pro.smeetz.com/${this.$i18n.locale}/`, '_blank');
  }
}

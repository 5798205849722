








































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import NewButton from '@/components/presentational/NewButton.vue';
import Select from '@/components/presentational/NewSelect.vue';
import DateFilter from './DateFilter.vue';
import { ProductsModule, RegionModule } from '@/utils/storemodules';
import ListItemsFilter from './ListItemsFilter.vue';
import { getElementsByClassName } from '@/utils/dom';
import { ProductLocation, ProductSearchCity } from '@/models/store/products';

@Component({
  components: {
    NewButton,
    Select,
    DateFilter,
    ListItemsFilter,
  },
})
export default class ModalFilters extends Vue {
  @Prop({ default: () => [] }) private regionsItems!: any[];
  @Prop({ default: () => [] }) private categoriesItems!: any[];
  private currentSort: string = '';
  // Modal parameters
  // private sortBy: string[] = ['Most popular', 'Price(Lowest first)'];
  private currentCategory: string = '';
  private currentRegion: string = '';
  private showFilters = false;
  private pickedLocation: any = null;
  private pickedCategories?: any[] = ProductsModule.smeetzCategory;

  get sortBy() {
    // return ['Most popular', 'Price(Lowest first)'];
    return [this.$t('field.most-popular'), this.$t('field.price-sort-asc')];
  }

  get sortMap() {
    return {
      [this.$t('field.most-popular') as string]: ['average_occupancy', 'desc'],
      [this.$t('field.price-sort-asc') as string]: ['advertised_price', 'asc'],
    };
  }

  private setSelectValue(item: string) {
    this.$emit('sortBy', item);
    this.currentSort = item;
    const sortArray = (this.sortMap as any)[item];

    ProductsModule.setOptions({
      sort: sortArray[0],
      sortDirection: sortArray[1],
    });
  }

  private submit() {
    ProductsModule.search();
    this.$emit('close');
  }

  private async resetFilters() {
    ProductsModule.setQuery('');
    ProductsModule.setProductLocation();
    ProductsModule.setFrom(null);
    ProductsModule.setTo(null);
    ProductsModule.setSmeetzCategory([]);
    await ProductsModule.search();
  }

  // get regions() {
  //   const lang = this.$i18n.locale ;
  //   return RegionModule.regions.map((r) => {
  //     return { label: lang === 'en' ? r.label : r.translations[lang], value: r.value };
  //   });
  // }

  get handledRegionsItems() {
    // we'll take the Prop regionsItems and remove the ignored location from the list of locations
    // because in mobile device we're showing a list, not a radio buttons list
    // so we don't really need that ignoredLocation with the word 'All' or 'Regions'
    return this.regionsItems.filter((r) => r.value !== '');
  }

  private async setRegion(pickedLocation: string) {
    ProductsModule.setProductLocation(
      RegionModule.regions.find((r) => r.value === pickedLocation)?.location,
    );
    await ProductsModule.search();
  }

  private async setCategories(pickedCategories: any[]) {
    ProductsModule.setSmeetzCategory(pickedCategories);
    await ProductsModule.search();
  }

  // Watcher invoked when changing location
  // We will consier only the one coming from Restor Filters btn
  @Watch('$store.state.products.productLocation')
  private onProductLocationChange(newRegion: ProductLocation) {
    if (newRegion.center === ProductsModule.ignoredLocation.center) {
      // going to assign ignoredLocation
      this.pickedLocation = {
        value: ProductsModule.ignoredLocation,
        label: this.$t('field.regions') as string,
      };
      return;
    }

    const searchCity: ProductSearchCity | undefined = RegionModule.regions.find(
      (r) =>
        r.location.center === newRegion.center &&
        r.location.unit === newRegion.unit &&
        r.location.distance === r.location.distance,
    );
    if (!searchCity) {
      return;
    }
    this.pickedLocation = {
        label: searchCity.label,
        value: searchCity.location,
      };
  }

  @Watch('$store.state.products.smeetzCategory')
  private onSmeetzCategoryChange() {
    this.pickedCategories = ProductsModule.smeetzCategory;
  }

  private created() {
    const currentPickedLocation = RegionModule.regions.find(
    (r) => r.location === ProductsModule.productLocation);

    if (!currentPickedLocation) {
      return;
    } else {
      this.pickedLocation = {
        label: currentPickedLocation.label,
        value: currentPickedLocation.location,
      };
    }
  }
}

import { addWaiversToBooking } from '@/api/booking';
import { PriceCategory } from '@/models/enums';
import {
  IRecapSlot, ISlot, ITimeslotsEntity,
  ITicket, IExportPricesEntity, TicketType,
  TicketAccessType, IPriceEntity, HideTicketDateTime, IServerRecapCategory, IFrontRecapTicket, Iwaivers,
} from '@/models/store/booking';
import { dateSortComp } from './helpers';
import { AppModule, NBookingModule } from './storemodules';


const ChildArray = ['Tarif enfant'];

/**
 * checks whether the following slot has some selected tickets;
 * @param slot slot to be checked for the presence of tickets
 */
export function doesSlotHaveTickets(slot: IRecapSlot) {
  // slot must contain categories
  if (!(slot.categories && slot.categories.length)) {
    return false;
  }

  // if any category has a selected ticket, then this slot contains tickets
  let ticketFound = false;
  for (const category of slot.categories) {
    if (category.ticketPrices.length) {
      ticketFound = true;
      break;
    }
  }

  return ticketFound;
}

/**
 * Checks whether the slot has some selected categories
 * @param slot slot to be checked for the presence of selected categories
 */
export function doesSlotHaveCategories(slot: ISlot) {
  // quit if no categories are present
  if (!(slot.categories && slot.categories.length)) {
    return false;
  }

  // check if any cateogry is selected
  for (const category of slot.categories) {
    if (category.select) {
      return true;
    }
  }

  return false;
}

export function ticketSelectedSlots(ticket: ITicket): ITimeslotsEntity[] {
  const slots: ITimeslotsEntity[] = [];

  if (!ticket.slots) {
    return slots;
  }

  for (const slotId in ticket.slots) {
    if (slotId) {
      slots.push(ticket.slots[slotId]);
    }
  }

  return slots.sort((slot1, slot2) => {
    return dateSortComp(slot1.startDateTime, slot2.startDateTime);
  });
}

export function slotSelectedPrices(slot: ITimeslotsEntity): IExportPricesEntity[] {
  const selectedPrices: IExportPricesEntity[] = [];

  if (!slot.export_prices) {
    return selectedPrices;
  }

  for (const price of slot.export_prices) {
    if (price.count) {
      selectedPrices.push(price);
    }
  }

  return selectedPrices;
}


export function hasPriceGradient(ticket: ITicket): boolean {
  if (ticket && ticket.gradientType === 2) {
    return true;
  }
  return false;
}

export function hasOccupancyGradient(ticket: ITicket): boolean {
  if (ticket && ticket.gradientType === 1) {
    return true;
  }
  return false;
}

export function getPriceColor(ticket: ITicket, price: number): string {
  // at this point NumberGradient and ColorGradient are supplied
  // but just in case they aren't we'll return empty string
  // which is invalid color, the price then will take the inherited color
  if (ticket.highNumberGradient && price >= ticket.highNumberGradient) {
    return ticket.highColorGradient || '';
  } else if (ticket.mediumNumberGradient && price >= ticket.mediumNumberGradient) {
    return ticket.mediumColorGradient || '';
  } else {
    return ticket.lowColorGradient || '';
  }
}

/**
 * Checks whether ticket has gradient functionality
 * @param ticket
 */
export function hasGradient(ticket: ITicket): boolean {

  // We'll return true if we have Price Level or Occupancy Level gradient
  if (ticket && (ticket.gradientType === 2 || ticket.gradientType === 1)) {
    return true;
  }

  // No gradient when no timeslots are present
  const timeslots = ticket.categoryInfo.timeslots;
  if (timeslots.length === 0) {
    return false;
  }

  // When activated. Gradient takes a value between 0 to 1.
  const gradient = timeslots[0].gradient;

  // Gradient is null if not activated
  if (gradient === null || gradient === undefined) {
    return false;
  }
  return true;
}

export function isDisplayOnCalendar(ticket: ITicket): boolean {
  if (ticket.displayOnCalendar) {
    return true;
  }
  return false;
}

export function isCalendarTicket(ticket: ITicket): boolean {
  const { dates = [], timeslots = [], isCalendar = false } = ticket.categoryInfo;
  if (isCalendar || dates.length > 6 || timeslots.length >= 9) {
    return true;
  }

  return false;
}

export function isSoldOutTicket(ticket: ITicket): boolean {
  const slots = ticket.categoryInfo.timeslots;

  if (!slots || !Array.isArray(slots) || (slots.length === 0)) {
    return true;
  }

  return false;
}

export function isGiftTicket(ticket: ITicket): boolean {
  return ticket.type === TicketType.Gift;
}

export function isSeatingTicket(ticket: ITicket): boolean {
  return ticket.type === TicketType.Seat || ticket.type === TicketType.SeatedMembership;
}

export function isMembershipVoucher(ticket: ITicket): boolean {
  return ticket.type === TicketType.MembershipVoucher;
}

export function isMembershipTicket(ticket: ITicket): boolean {
  const membershipTickets = [
    TicketType.Membership,
    TicketType.MembershipVoucher,
    TicketType.SeatedMembership,
  ];

  return membershipTickets.includes(ticket.type);
}

export function isExpiry2Membership(ticket: ITicket): boolean {
  const membership = ticket.membership;
  if (!isMembershipTicket(ticket) || !membership) {
    return false;
  }

  if (membership.expiryType === 2) {
    return true;
  }

  return false;
}

export function ticketRequiresCode(ticket: ITicket): boolean {
  return ticket.requiredBeforeBooking === TicketAccessType.CodeRequired;
}

// Checks whether the price requires access code.
export function priceRequiresCode(priceId: number, ticket: ITicket): boolean {
  // Get all ticket prices.
  let prices: IExportPricesEntity[] | IPriceEntity[] = [];

  if (isSeatingTicket(ticket) && ticket.seatingSubProducts) {
    // for (const seatTicket of ticket.seatingSubProducts) {
    //   const price = seatTicket && seatTicket.price;

    //   if (price && price.length) {
    //     (prices as IExportPricesEntity[]).push(...price);
    //   }
    // }
    prices = seatingPrices(ticket);
  } else {
    const price = ticket.price;
    if (price && price.length) {
      (prices as IPriceEntity[]).push(...price);
    }
  }

  const foundPrice = (prices as IExportPricesEntity[]).find((p) => {
    return priceId === Number(p.priceId);
  });

  // No price found
  if (!foundPrice) {
    return false;
  }

  // is price blocked by code?
  return foundPrice.blockedWithCode;
}

// Returns the link to purchase the access code for the blocked price
export function priceRequiresCodeLink(priceId: number, ticket: ITicket): string {

  // For seating tickets, iterate over sub tickets and check if price is blocked with code.
  if (isSeatingTicket(ticket) && ticket.seatingSubProducts) {
    for (const seatTicket of ticket.seatingSubProducts) {
      const price = seatTicket && seatTicket.price;

      if (price && price.length) {
        for (const prix of price) {
          if ((prix.priceId === priceId && prix.blockedWithCode)) {
            // Return the link of the parent category.
            // TODO: Check with backend if we should get the link from the sub category
            return ticket.requiredBeforeBookingLink || '';
          }
        }
      }
    }
  } else {
    const price = ticket.price.find((p) => p.priceId === priceId);
    if (price && price.blockedWithCode) {
      return ticket.requiredBeforeBookingLink || '';
    }
  }

  // No prices requiring code. Leave
  return '';
}

// Returns All the prices of a seating category.
export function seatingPrices(ticket: ITicket): IExportPricesEntity[] {
  const prices: IExportPricesEntity[] = [];
  const subProducts = ticket.seatingSubProducts;
  if (!isSeatingTicket(ticket) || !subProducts || !subProducts.length) {
    return prices;
  }

  for (const seatCategory of subProducts) {
    if (seatCategory) {
      prices.push(...seatCategory.price);
    }
  }

  return prices;
}

// Returns the package id
export function genPackageId(timeSlotId: string, priceId: string = '') {
  return `${timeSlotId}:${priceId}`;
}

// Main ticket id made for package prices. it's a combination of
// 'timeslotId:priceId'
export function genMainTicketId(timeSlotId: string, priceId: string) {
  return genPackageId(timeSlotId, priceId);
}

/**
 * Returns the color based on the gradient
 * @param gradient {number} gradient for generating the color
 */
export function colorByGradient(gradient: number) {
  if (gradient > 0.8) {
    return AppModule.primaryWithOpacity(0.65);
  } else if (gradient > 0.5) {
    return AppModule.primaryWithOpacity(0.3);
  } else {
    return AppModule.primaryWithOpacity(0.1);
  }
}

export function seatParentTicket(categoryId: number): null | ITicket {
  const tickets = NBookingModule.fetchedTickets;

  for (const ticket of tickets) {
    const { seatingSubProducts } = ticket;

    if (seatingSubProducts && seatingSubProducts.length) {
      for (const seatCategory of seatingSubProducts) {
        if (seatCategory.categoryId === categoryId) {
          return ticket;
        }
      }
    }
  }

  return null;
}

// Take a ticket type and checks whether we should hide its dates in UI or not
export function hideDateTime(ticket: ITicket | IFrontRecapTicket): boolean {
  return ticket.hideDateTime === HideTicketDateTime.HideBoth
    || ticket.type === TicketType.Gift
    || ticket.type === TicketType.Merchandising
    || ticket.type === TicketType.MembershipVoucher
    || ticket.type === TicketType.Membership
    || ticket.type === TicketType.SeatedMembership;
}

export function hideEndDateTime(ticket: ITicket | IFrontRecapTicket): boolean {
  return ticket.hideDateTime === HideTicketDateTime.HideEndDate;
}

export function hideStartDateTime(ticket: ITicket | IFrontRecapTicket): boolean {
  return ticket.hideDateTime === HideTicketDateTime.HideStartDate;
}

export function isChildPriceCategory(pcategory: PriceCategory) {
  return pcategory === PriceCategory.Child;
}

export function isChildPriceName(priceName: string): boolean {
  return ChildArray.includes(priceName);
}

export function isNonChildPriceName(priceName: string): boolean {
  return !ChildArray.includes(priceName);
}

export function isAdultPriceCategory(pcategory: PriceCategory) {
  return pcategory === PriceCategory.Adult;
}

export function isNonChildPriceCategory(pcategory: PriceCategory) {
  return pcategory !== PriceCategory.Child;
}

/**
 * Returns all the prices that are in the cart
 * @param categories server recap categories
 * @returns
 */
export function allBookedPrices(categories: IServerRecapCategory[])
: Array<{priceName: string, priceId: number}> {
  return categories.map((c) => {
    return { priceName: c.priceName, priceId: Number(c.priceId) };
  });
}

export async function waiverListener(data: Iwaivers[]) {
  window.addEventListener('message', (event) => {
    if (event.data && typeof event.data === 'string' && event.data.includes('smtzOp')) {
      const op: any = JSON.parse(event.data);
      if (op.smtzOp === 'waiver-signed') {
          addWaiversToBookingCall(data);
      }
    }
  }, false);
}

export async function addWaiversToBookingCall(data: Iwaivers[]) {
  try {
    await addWaiversToBooking(data);
  } catch (error) {
    setTimeout(() => {
      throw error;
    }, 0);
  }
}

import { IMembershipDiscounts, IPostMembershipData } from './memberships';
import { IMembershipCustomer, IServerBookingRecap } from './store/booking';

export enum WidgetOps {
  AddProduct = 'add-product',
  CloseWidget = 'close-w',
  CartInfo = 'cart-info',
  CartInfoWidget = 'cart-info-widget',
  CartInfoRequest = 'cart-info-request',
  OpenCart = 'open-cart',
  DestroyCart = 'dest-cart',
  Resize = 'resize',
  RequestMebership = 'req-membership',
  MembershipInfo = 'member-info',
  StatusUpdate = 'stat-up',
  ContinueShopping = 'cont-shop',
  sendGAData = 'send-ga-data',
  sendReloadPage = 'reload-page',
  sendOpenPage = 'open-page',
  requestParentUrl = 'request-parent-url',
  sendParentUrl = 'send-parent-url',
  setWidgetHeight = 'set-height',
  sendCustomLinksInfo = 'custom-links-info',
  requestCustomLinksInfo = 'request-customLink-info',
  InvokeOnSite = 'invoke-on-site',
  DataLayerPush = 'dataLayerPush',
  RequestKeycloakAuth = 'request-keycloak-auth',
  SmtzAccessToken = 'smtz-access-token',
  CloseChildWindow = 'close-child-window',
  SendTrackingStatus = 'tracking-status',
}

export enum WidgetStatus {
  Loading = 'loading',
  Loaded = 'loaded',
}

export interface IWidgetOp {
  smtzOp: WidgetOps; // Widget operation
  wId: string; // widget id
}

// Close widget interface
export interface ICloseOp extends IWidgetOp {
  how: 'h' /*hide*/ | 'd' /*destroy iframe*/;
}

export interface IParentUrlOp extends IWidgetOp {
  data: {
    parentUrl: string;
  };
}

export interface IOpenPageOp extends IWidgetOp {
  data: {
    url: string;
  };
}

export interface IAddProdOp extends IWidgetOp {
  data: {
    priceId: number;
    categoryId: number;
    productId: number;
    count: number;
    tcart?: string;
    categoryIds?: number[];
  };
}

export interface ICartInfoOp extends IWidgetOp {
  data: {
    bookingId: number;
    bookingToken: string;
    bookingRecap: IServerBookingRecap;
    holdingTime: string;
    membershipCustomers?: IMembershipCustomer[] | null;
    membership?: IMembershipDiscounts | null;
    holdToken: string | null;
    linkedRetailIds?: number[];
    linkedTicketIds?: number[];
    linkedTicketIdsMin?: number[];
    linkedTicketIdsMax?: number[];
    stash?: any; // TODO @Riyadh typedefs after release
  };
}

export interface IMembershipInfoOp extends IWidgetOp {
  data: {
    membershipCustomers: IMembershipCustomer[];
    membership: IMembershipDiscounts;
  };
}

export interface IResizeOp extends IWidgetOp {
  how: 'shrink' | 'expand';
}

export interface IWidgetStatusOp extends IWidgetOp {
  data: {
    status: WidgetStatus;
  };
}

export interface IGADataOps extends IWidgetOp {
  data: {
    collable: string;
    args: any[];
  };
}

export interface IInvokeOp extends IWidgetOp {
  data: {
    collable: string;
    args: any[];
  };
}

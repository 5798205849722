





















import { Vue, Component, Prop } from 'vue-property-decorator';
import LoginSignupLink from '@/components/presentational/LoginRegisterLink.vue';
import FacebookLogin from '@/components/auth/FacebookLogin.vue';
import GoogleAuth from '@/components/auth/GoogleAuth.vue';
import SwissPassAuth from '@/components/auth/SwissPassAuth.vue';

@Component({
  components: {
    LoginSignupLink,
    FacebookLogin,
    GoogleAuth,
    SwissPassAuth,
  },
})
export default class SocialAuth extends Vue {
  @Prop({ default: 'register' }) public authType!: string;
  @Prop({ default: true }) public showTitle!: boolean;
  @Prop({ default: true }) public includeSwissPass!: boolean;
  @Prop({ default: true }) public lowerHr!: boolean;
}



















































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Mixins } from 'vue-mixin-decorator';
import DateHelperMixin from '@/mixins/DateHelper';
import TicketHelperMixin from '@/mixins/TicketHelper';
import AcompteMixin from '@/mixins/Acompte';
import { ProductModule, BookingModule, NBookingModule, OrganizerModule } from '../../../utils/storemodules';
import { IProductSlotStatesEntity, IServerRecapCategory, IServerBookingRecap, IRecapInfo,
 ITicket, IBookingField, ITicketField, InformationType, IModiServerRecapCategory } from '../../../models/store/booking';
import { dateSortComp, fillCountries, getCountryName } from '../../../utils/helpers';
import { hideDateTime, hideEndDateTime, hideStartDateTime,
  isExpiry2Membership, isGiftTicket, isMembershipTicket, waiverListener } from '../../../utils/booking';
import ToolTipIcon from '@/components/presentational/ToolTipIcon.vue';
import { getBookingFields, gql_fetchBookingWaivers } from '@/api/booking';
import { getPrimaryColor, hexToRGBA } from '@/utils/colors';
import { disableQuasarStyling, enableQuasarStyling } from '@/utils/styles';
import NewButton from '@/components/presentational/NewButton.vue';
import { Dialog } from 'quasar';
import { createShipping } from '@/api/rates';
import { CreateShippingInput, ParcelItemInput } from '@/types/gql/generatedShipping/graphql';
import { bookingDecorateErrorMessage } from '@/utils/debug';
import { round } from '@/utils/jsHelpers';
import { BookingStatus, CategoryType, MembershipRenewalSettingDurationType } from '@/models/enums';
import { getMembershipOverview } from '@/api/membership';
import { LoggerService } from '@/services/log';
import { getLanguageList } from '@/api/common';


interface IConfTicketPrice {
  name: string;
  startDateTime: string;
  endDateTime: string;
  count: number;
  priceName: string;
  priceDescription?: string;
  priceValue: number;
  type: number;
}

@Component({
  components: {
    ToolTipIcon,
    NewButton,
  },
})
export default class OrderSummaryConfirmation extends
Mixins<TicketHelperMixin & AcompteMixin>(TicketHelperMixin, AcompteMixin) {
  @Prop({default: 0}) private windowWidth!: number;
  @Prop({default: false}) private inBasket!: boolean;
  private bookingFields: IBookingField[] = [];
  private ticketFields: ITicketField[] = [];
  private ticketFieldsWithCount:
  Array<{count: number, pssId: number, timeSlotId?: string, priceId: string, ticketCustomFields: ITicketField[]}> = [];
  private waivers: string[] = [];
  private openWaiversDialog: boolean = false;
  private countries: Array<{ name: string; code: string }> | undefined;
  private languages: Array<{ name: string; code: string }> | undefined;
  private requiredMembership: any = null;

  private async created() {
    await NBookingModule.fetchProductsTickets([ProductModule.products[0].id]);
  }

  private isTicketExpiry2Membership(cat: IModiServerRecapCategory) {
    if (!cat.ticket) {
      return;
    }
    return isExpiry2Membership(cat.ticket);
  }

  private isTicketShowDate(cat: IModiServerRecapCategory) {
    if (!cat.ticket) {
      return;
    }
    return !hideDateTime(cat.ticket);
  }

  private isTicketEndHidden(cat: IModiServerRecapCategory) {
    if (!cat.ticket) {
      return;
    }
    return hideEndDateTime(cat.ticket);
  }

  private isTicketStartHidden(cat: IModiServerRecapCategory) {
    if (!cat.ticket) {
      return;
    }
    return hideStartDateTime(cat.ticket);
  }

  private membAutoRenewal(cat: IModiServerRecapCategory) {
    if (!cat.ticket) {
      return '';
    }
    const membership = cat.ticket.membership;
    if (!isMembershipTicket(cat.ticket) || !membership) {
      return '';
    }
    if (membership.autoRenew) {
      return this.$t('common.true');
    }
    return this.$t('common.false');
  }

  private membGuests(cat: IModiServerRecapCategory) {
    if (!cat.ticket) {
      return;
    }
    const membership = cat.ticket.membership;
    if (!isMembershipTicket(cat.ticket) || !membership) {
      return;
    }
    return membership.guests;
  }

  private membMinDuration(cat: IModiServerRecapCategory) {
    if (!cat.ticket) {
      return 0;
    }
    const membership = cat.ticket.membership;
    if (!isMembershipTicket(cat.ticket) || !membership) {
      return 0;
    }

    if (membership.membershipRenewalSetting) {
      return membership.membershipRenewalSetting.durationLength;
    }

    const { periodLength, numberMandatoryPeriod } = membership;
    return periodLength * numberMandatoryPeriod;
  }

  private membUnit(cat: IModiServerRecapCategory) {
    if (!cat.ticket) {
      return '';
    }
    const membership = cat.ticket.membership;
    if (!isMembershipTicket(cat.ticket) || !membership) {
      return '';
    }

    if (membership && membership.membershipRenewalSetting) {
      const durationType = membership.membershipRenewalSetting.durationType;
      switch (durationType) {
        case MembershipRenewalSettingDurationType.Days:
            return this.membMinDuration(cat) > 1 ? this.$t('date.label-days') : this.$t('date.label-day');
        case MembershipRenewalSettingDurationType.Weeks:
            return this.membMinDuration(cat) > 1 ? this.$t('date.label-weeks') : this.$t('date.label-week');
        case MembershipRenewalSettingDurationType.Months:
            return  this.membMinDuration(cat) > 1 ? this.$t('date.months') : this.$t('date.month');
        case MembershipRenewalSettingDurationType.Years:
            return this.membMinDuration(cat) > 1 ? this.$t('date.years') : this.$t('date.year');
        default:
            return '';
    }
    }

    return membership.periodLength > 1 ? this.$t('date.months') : this.$t('date.month');
  }

  get isMultiProducts(): boolean {
    // Had to build an array of productId because ticketsPrices[i].productId wasn't accepted
    const productIdsArray: string[] = [];
    for (const t of this.ticketsPrices) {
      productIdsArray.push(Object.entries(t).filter((p) => p[0] === 'productId')[0][1]);
    }
    for (const e of productIdsArray) {
      if (productIdsArray.filter((t) => e !== t).length > 0) {
        return true;
      }
    }
    return false;
  }

  get shippingTickets() {
    const bookingRes = NBookingModule.bookingRes;
    if (!bookingRes || !bookingRes.bookingRecap.categories) {
      return null;
    }
    const tickets = bookingRes.bookingRecap.categories;
    const shippingProductCategory = tickets.filter((c) => c.isShipping === true);
    if (shippingProductCategory.length < 1) { return null; }
    return shippingProductCategory;
  }

  get subTotal() {
    if (this.donationTicket.length > 0) {
      return this.recapInfo.subTotal - this.donationTicket[0].priceValue;
    }
    return this.recapInfo.subTotal;
  }

  get ticketsPricesUI() {
    return this.ticketsPrices.filter((i) => i.isShipping === false && i.categoryType !== CategoryType.Donation);
  }

  get donationTicket() {
    return this.ticketsPrices.filter((i) => i.categoryType === CategoryType.Donation);
  }

  private animateFieldsArea(fieldsAreaId: string, fieldsArrowId: string) {
    // lets animate the fields area
    const fieldsArea = document.getElementById(fieldsAreaId);
    if (!fieldsArea?.classList.replace('custom-fields-open', 'custom-fields-close')) {
      fieldsArea?.classList.replace('custom-fields-close', 'custom-fields-open');
    }
    // lets animate the arrow
    const fieldsArrow = document.getElementById(fieldsArrowId);
    if (!fieldsArrow?.classList.replace('active-arrow', 'inactive-arrow')) {
      fieldsArrow?.classList.replace('inactive-arrow', 'active-arrow');
    }

    // if it is the tickets custom fields we'll increase/decrease borderRadius
    const customFieldsWrapper = fieldsArea?.parentElement;
    if (!customFieldsWrapper?.classList.replace('custom-fields-wrapper-closed', 'custom-fields-wrapper-opened')) {
      customFieldsWrapper?.classList.replace('custom-fields-wrapper-opened', 'custom-fields-wrapper-closed');
    }
  }

  private async mounted() {
    LoggerService.info(`Confirmation page`);
    if (NBookingModule.bookingRes) {
      const bookingState = NBookingModule.bookingRes.bookingState;
      LoggerService.info(`Booking state in confirmation page: ${bookingState}`);
      if (!OrganizerModule.id) {
        const organizer = NBookingModule.bookingRes.product.organiser;
        const organizerSlugName = organizer?.slugName;
        if (organizerSlugName) {
          await OrganizerModule.getOrganizerInfo(organizerSlugName);
        }
      }
      // Call the function to check balance and potentially trigger alerts
      this.checkBookingBalanceAndAlert();
    }
    const { bookingId, bookingToken } = this.$route.query;
    // fetch custom fields
    try {
      const fieldsResponse = await getBookingFields(Number(bookingId), bookingToken as string);
      this.bookingFields = fieldsResponse.booking_fields || [];
      const retrieveCountries = this.bookingFields.length > 0 ?
        this.bookingFields.find((t) => t.informationType === 11 ) : false;
      if (retrieveCountries) {
        this.countries = await fillCountries(this.$i18n.locale);
      }
      const retrieveLanguages = this.bookingFields.length > 0 ?
        this.bookingFields.find((t) => t.informationType === 26 ) : false;

      if (retrieveLanguages) {
        const response = await getLanguageList();
        if (response && response.data && response.data.languages && response.data.languages.length > 0) {
          this.languages = response.data.languages;
        }
      }
      if (fieldsResponse.ticket_fields && fieldsResponse.ticket_fields.length > 0) {
        this.ticketFields = this.ascendingOrder(fieldsResponse.ticket_fields);
        this.ticketFieldsWithCount = this.prepareTicketCustomFieldsWithTicketCounting(this.ticketFields);
      } else {
        this.ticketFields = [];
      }
    } catch (err) {
      throw err;
    }
    // fetch required membership if present
    const { reqMemId } = this.$route.query as {[s: string]: string};
    if (reqMemId) {
      this.requiredMembership = await getMembershipOverview(reqMemId);
    }
    // fetch waivers
    try {
      const data = await gql_fetchBookingWaivers(String(bookingId), String(bookingToken));
      const waivers = data ? data[0].pssList[0] : null;
      if (waivers && waivers.category.WaiverTemplate && waivers.WaiverList.length === 0) {
        this.waivers = [waivers.category.WaiverTemplate];
        enableQuasarStyling();
        this.openWaiversDialog = true;
      }
    } catch (err) {
      setTimeout(() => {
        LoggerService.info(bookingDecorateErrorMessage(JSON.stringify(err), 'Waivers error'));
      }, 0);
    }
    // creation of parcel from front end
    const bookingRes = NBookingModule.bookingRes;
    const { rateId } = this.$route.query as {[s: string]: string};
    if (!bookingRes || !bookingRes.bookingRecap.categories || !rateId) {
      return null;
    } else {
      const firstName = this.bookingFields.filter((t) => t.informationType === InformationType.FirstName)[0].answer || '';
      const lastName = this.bookingFields.filter((t) => t.informationType === InformationType.LastName)[0].answer || '';
      const address = this.bookingFields.filter((t) => t.informationType === InformationType.Address)[0].answer || '';
      const city = this.bookingFields.filter((t) => t.informationType === InformationType.City)[0].answer || '';
      const postalCode = this.bookingFields.filter((t) => t.informationType === InformationType.Zip)[0].answer || '';
      const phone = this.bookingFields.filter((t) => t.informationType === InformationType.Phone)[0].answer || '';
      const country = this.bookingFields.filter((t) => t.informationType === InformationType.Country)[0].answer || '';
      const houseNumber = this.bookingFields.filter((t) => t.informationType === InformationType.Other)[0].answer || '';

      const items = bookingRes.bookingRecap.categories.reduce((filtered: any, t) => {
        if (t.retailOptionId && !t.isShipping) {
          filtered.push({
            weight: String(t.weight),
            quantity: t.quantity,
            description: t.categoryName,
            value: t.priceValue,
          });
        }
        return filtered;
      }, []) as ParcelItemInput[];

      const totalWeight = items.map((item) =>
      Number(item.quantity) * Number(item.weight))
      .reduce((a, b) => a + b, 0);

      const createShippingPayload = {
        group_id: OrganizerModule.id != null ? OrganizerModule.id : 1,
        name: `${firstName} ${lastName}`,
        address,
        house_number: houseNumber,
        city,
        postal_code: postalCode,
        telephone: phone,
        email: bookingRes.bookingEmail,
        country,
        shipment: {
          id: Number(rateId),
        },
        weight: round(totalWeight, 6),
        bookingReference: bookingRes.bookingRef,
        total_order_value_currency: bookingRes.bookingRecap.currency,
        total_order_value: String(bookingRes.bookingRecap.total - bookingRes.bookingRecap.shipping),
        parcel_items: items,
      } as CreateShippingInput;

      try {
        await createShipping(createShippingPayload);
      } catch (error) {
        setTimeout(() => {
          throw Error(bookingDecorateErrorMessage(JSON.stringify(error), 'Create shipping parcel error'));
        }, 0);
      }
    }
  }

  // Will sort the custom fields based on their productTimeSlotId
  private ascendingOrder(customFields: ITicketField[]) {
    return customFields.sort((a: ITicketField, b: ITicketField) => {
      return a.pssId - b.pssId;
    });
  }

  private customFieldsBgColor() {
    return hexToRGBA(getPrimaryColor(), '0.05');
  }

  private customFieldsBorder() {
    return '1px dashed ' + hexToRGBA(getPrimaryColor(), '0.3');
  }

  private customFieldsGradient() {
    return 'linear-gradient(to right, ' + hexToRGBA(getPrimaryColor(), '0.05') + ', transparent)';
  }

  // Starting from ticket_fields array this function will return
  // an array of objects, where every object has an array of custom fields
  // related to a given productSlotState of a given timeSlot, with the counter of
  // this custom fields group inside the same timeSlot (in case we have qty>1 from same timeSlot)
  private prepareTicketCustomFieldsWithTicketCounting(customFields: ITicketField[]):
  Array<
  {count: number,
  pssId: number,
  timeSlotId?: string,
  priceId: string,
  seat?: string,
  ticketCustomFields: ITicketField[]}
  > {
    const pssList = NBookingModule.bookingRes?.slots || [];

    if (customFields.length < 2) {
      // There is only one custom field, no need to sort and treat
      return [
        { count: 1,
          pssId: customFields[0].pssId,
          timeSlotId: customFields[0].timeSlotId,
          priceId: customFields[0].priceId,
          seat: pssList.find((s) => s.productSlotStateId === customFields[0].pssId)?.seat,
          ticketCustomFields: customFields},
          ];
    }


    const arr: ITicketField[] = this.ascendingOrder(customFields);

    // 'count' property is used to count tickets with custom fields from the same timeSlot,
    // it will be helpful when displaying
    const treatedCustomFields:
    Array<{
      count: number,
      pssId: number,
      timeSlotId?: string,
      priceId: string,
      seat?: string,
      ticketCustomFields: ITicketField[],
      }> = [];
    let intermediateTreatment: ITicketField[] = [];

    for (let i = 0; i < arr.length; i++) {
      intermediateTreatment.push(arr[i]);
      if (i + 1 < arr.length) {
        if (customFields[i].pssId === customFields[i + 1].pssId) {
        // next custom field is for the same ticket of the same custom field, lets do nothing
        } else {
          // next custom field is for a different ticket, lets push intermediateTreatment to the array of arrays
          // but first lets count the ticket number

          const slot = pssList.find((s) => s.productSlotStateId === intermediateTreatment[0].pssId);
          const tsId = intermediateTreatment[0].timeSlotId;
          const c = treatedCustomFields.filter((t) =>
          t.timeSlotId === tsId && (slot?.seat ? slot.seat === t.seat : true)).length;
          treatedCustomFields.push(
            {count: c + 1,
            pssId: intermediateTreatment[0].pssId,
            timeSlotId: intermediateTreatment[0].timeSlotId,
            priceId: intermediateTreatment[0].priceId,
            seat: slot?.seat,
            ticketCustomFields: intermediateTreatment},
            );
          // lets empty the intermediate array
          intermediateTreatment = [];
        }
      } else {
        // so this is the last custom field
        const slot = pssList.find((s) => s.productSlotStateId === intermediateTreatment[0].pssId);
        const tsId = intermediateTreatment[0].timeSlotId;
        const c = treatedCustomFields.filter((t) =>
        t.timeSlotId === tsId && (slot?.seat ? slot.seat === t.seat : true)).length;
        treatedCustomFields.push(
          {count: c + 1,
          pssId: intermediateTreatment[0].pssId,
          timeSlotId: intermediateTreatment[0].timeSlotId,
          priceId: intermediateTreatment[0].priceId,
          seat: slot?.seat,
          ticketCustomFields: intermediateTreatment},
          );
        intermediateTreatment = [];
      }
    }

    return treatedCustomFields;
  }


  private get currency() {
    return ProductModule.productCurrency;
  }

  get recapInfo(): IRecapInfo {
    return NBookingModule.recapInfo;
  }

  get hideDate() {
    return (ticketPrice: IConfTicketPrice) => {
      // Experimental feature. Remove this ignore later
      // @ts-ignore
      return isGiftTicket(ticketPrice as ITicket) || isMembershipTicket(ticketPrice as ITicket);
    };
  }

  // Hiding end date time for equilibre for now
  private get isEndHidden() {
    const orgId = OrganizerModule.id;
    if (orgId && orgId === 16192) {
      return true;
    } else {
      return false;
    }
  }

  private signWaiver() {
    const bookingId = Number(this.$route.query.bookingId);
    const waivers =  this.waivers.map((t) => {
      return {
        waiverId: Number(t),
        bookingId,
        };
    });
    waiverListener(waivers);
    // @ts-ignore
    _smtz('open-waiver', 1 * new Date(), {tempid: this.waivers[0]});
    this.closeWaiversDialog();
  }

  private closeWaiversDialog() {
    this.openWaiversDialog = false;
    disableQuasarStyling();
  }
  private getCountry(countryCode: string) {
    return getCountryName(this.countries, countryCode);
  }

  private getLanguage(languageCode: string) {
    if (this.languages) {
      return this.languages.find((lang: any) => lang.code === languageCode)?.name;
    }
  }
  // Function to perform the balance alert check
  private async checkBookingBalanceAndAlert() {
    // Retrieve booking details
    const { bookingId, bookingToken } = NBookingModule;
    if (NBookingModule.bookingRes) {
      let balance = NBookingModule.bookingRes?.bookingRecap.balance;
      const isRequestToBook = NBookingModule.isRequestToBook;
      const bookingState = NBookingModule.bookingRes.bookingState;

      const isAlertNeeded = !this.isAcompteBooking &&
        !isRequestToBook &&
        balance &&
        bookingState === BookingStatus.Completed;

      if (isAlertNeeded) {
        // Check if there is a PayPal accounting entry
        const paypalAccounting = NBookingModule.bookingRes.bookingRecap.accounting.find((acc) =>
          acc.paymentMethod === 'PAYPAL' &&
          acc.state === 3 &&
          acc.transactionType === 1);

        if (paypalAccounting) {
          // Deduct the accounting value from the balance
          balance -= paypalAccounting.accountingValue;
        }

        if (balance > 0) {
          // If balance is positive, wait for 3 seconds and retry fetching booking details
          await new Promise((resolve) => setTimeout(resolve, 3000));
          await NBookingModule.fetchBooking({ bookingToken, bookingId });

          // Re-check balance after retry
          balance = NBookingModule.bookingRes?.bookingRecap.balance;

          if (balance > 0) {
            // If balance is still positive after retry, log the warning
            LoggerService.info(`WARNING: Completed Booking with positive balance`);
          }
        } else if (balance < 0) {
          // If balance is already negative, log the warning
          LoggerService.info(`WARNING: Completed Booking with negative balance`);
        }
      }
    }
  }
}

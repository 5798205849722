import { MembershipSetting, PSSStatus } from '@/models/enums';
import { BookingPSS } from '@/types/gql/ticket';
// Active if not abandoned or cancelled
export function isActiveTicket(pss: BookingPSS) {
  const nonActiveStates = [PSSStatus.BookingStatusAbandoned, PSSStatus.BookingStatusCancelled];
  return !nonActiveStates.includes(pss.BookingStatus as number);
}
// main ticket if mainticket is null
export function isMainTicket(pss: BookingPSS) {
  return !pss.MainTicketId;
}
export function isAddon(pss: BookingPSS) {
  return !isMainTicket(pss);
}

export const RequiresBookingCodes = [MembershipSetting.RequiresMembership, MembershipSetting.RequiresMembershipZero];

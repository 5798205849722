

































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import Ticket from './Ticket.vue';
import {IMembershipCustomer } from '../../../models/store/booking';
import NewButton from '@/components/presentational/NewButton.vue';
import { NBookingModule, ProductModule } from '@/utils/storemodules';
import { CategoryType } from '@/models/enums';
import { BookingSteps } from '@/store/modules/newBooking/constants';

@Component({
  components: {
    Ticket,
    NewButton,
  },
})
export default class TicketSelectionView extends Vue {
  @Prop({default: 0}) public windowWidth!: number;
  @Prop({default: null}) private ticket!: any;
  private minTicketValidation = 0;
  private maxTicketValidation = 0;
  private error: string = '';
  private priceIdsToDisplay: number[] = [];
  private categoryIdsToDisplay: number[] = [];

  get isPiazzaGrandeValidation(): boolean {
    return NBookingModule.piazzaGrandeValidation;
  }

  get customCustomers(): IMembershipCustomer[]  {
    return NBookingModule.membershipCustomers || [];
  }

  get bookingToken(): string {
    return NBookingModule.bookingToken;
  }

  private mounted() {
    if (!this.ticket) {
      this.$emit('close');
      return;
    }
    // Variables added just for test (to be removed)
    const { priceIdsToDisplay, oneCustomer, min, max } = this.$route.query as {[s: string]: string};
    // We show only the linked Categories
    // tslint:disable-next-line:max-line-length
    this.categoryIdsToDisplay = ProductModule.addonTicketsShowsPrices && ProductModule.addonTicketsShowsPrices[this.ticket.categoryId] ?
      ProductModule.addonTicketsShowsPrices[this.ticket.categoryId].map((addonPrice) => addonPrice.categoryId) : [];

    // We show only the linked prices
    // tslint:disable-next-line:max-line-length
    this.priceIdsToDisplay = ProductModule.addonTicketsShowsPrices && ProductModule.addonTicketsShowsPrices[this.ticket.categoryId] ?
      ProductModule.addonTicketsShowsPrices[this.ticket.categoryId].map((addonPrice) => addonPrice.priceId) : [];

    if (oneCustomer) {
      this.customCustomers.length = 1;
    }
    if (min) {
      this.minTicketValidation = Number(min);
    }
    if (max) {
      this.maxTicketValidation = Number(max);
    }
  }

  private checkValidation() {
    if (ProductModule.requiredNumberPiazzaSeats) {
      // Check validation
      const requiredNumber = ProductModule.requiredNumberPiazzaSeats;
      const addedTicket = NBookingModule.recapCategories.filter((cat) => cat.categoryType === CategoryType.SeatingPlan);
      if (addedTicket.length < requiredNumber) {
        this.error = requiredNumber === 1 ? this.$t('new-booking-flow.order.ticket-min-required-single') as string :
          (this.$t('new-booking-flow.order.ticket-min-required') as string).replace('XXX', String(requiredNumber));
        return;
      }
      NBookingModule.stepTo(BookingSteps.Checkout);
      return;
    }
    // Check if a custom customer have no ticket
    const validationCheck = this.customCustomers.filter((t: IMembershipCustomer) => t.tickets.length === 0);
    // min validation taken in consideration only if we have one customer
    const min = this.minTicketValidation;
    // If we have more than one customer, and at least one of them has no ticket we show error
    if (this.customCustomers.length > 1 && validationCheck.length > 0) {
      this.error = 'You have to select a seat for each customer';
      return;
    } else if (this.customCustomers.length === 1 &&
      this.customCustomers[0].tickets.length < min) {
      this.error = min === 1 ? this.$t('new-booking-flow.order.ticket-min-required-single') as string :
        (this.$t('new-booking-flow.order.ticket-min-required') as string).replace('XXX', String(min));
      return;
    }
    // Go back to first step of booking flow
    this.$emit('close');
    return;
  }

  @Watch('bookingToken')
  private async onBookingUpdated() {
    this.error = '';
  }

  @Watch('customCustomers', {deep: true})
  private onCustomerchange() {
    this.error = '';
  }

}


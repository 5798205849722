






















































import { Vue, Component } from 'vue-property-decorator';
import { UserModule, NModalModule } from '@/utils/storemodules';
import { openLegalPage, validateOnDelay } from '@/utils/helpers';
import { ModalStatus } from '@/models/definitions';
import { email, required, maxLength } from 'vuelidate/lib/validators';
import Debug from 'debug';
import { Status } from '@/utils/status';

const debug = Debug('smeetz:auth');

@Component({
  validations: {
    email: {
      required,
      email,
      maxLength: maxLength(255),
    },
  },
})
export default class NewForgotPassword extends Vue {
  private email: string = '';
  private isLoading: boolean = false;
  private invalidLogin: boolean = false;

  private goToRegisterForm() {
    NModalModule.setStatus(ModalStatus.NewRegistration);
  }

  private goToLegalPage() {
    openLegalPage.call(this);
  }

  private hideModal() {
    NModalModule.setStatus(ModalStatus.Hidden);
  }

  private get canSubmit() {
    return !this.$v.$invalid && !this.invalidLogin;
  }


  private onInputChange(field: string, value: string) {
    validateOnDelay.call(this, field, value);
  }

  private async submit() {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }

    // Indicate that we are loading (on button)
    this.isLoading = true;


    try {
      await UserModule.resetPass(this.email);
      await UserModule.setUserEmail(this.email);
      NModalModule.setStatus(ModalStatus.NewResetPassword);
      debug('password reset was sent');
    } catch (err) {
      debug(`reset pass failed: ${err}`);
    }
    this.isLoading = false;
  }
}



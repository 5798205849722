














































































































































import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import dayjs from 'dayjs';
import Timeout from 'await-timeout/dist/es5';
import { fetchTicketSlots } from '@/api/booking';
import { ITicket, ITimeslotsEntity, IMonthsGradients, IMonthGradient,
  IDatesEntity, ITicketSlotsFetch, IMembershipCustomer } from '../../../models/store/booking';
import { ICalendarLegend } from '@/models/site';
import Tag from '@/components/presentational/TicketTag.vue';
import SlotsFilter from './SlotsFilter2.vue';
import Slots from './Slots.vue';
import { ESlotsHours, EVSlotsFilter } from '@/models/events';
import { isSameDay } from '../../../utils/helpers';
import { isCalendarTicket, isSoldOutTicket, isSeatingTicket, hasOccupancyGradient } from '../../../utils/booking';
import { ProductModule, AppModule, NBookingModule, ReferrerModule, OrganizerModule } from '../../../utils/storemodules';
import SeatComponent from './SeatComponent.vue';
import SlotsHours from './SlotsHours.vue';
import { IProduct } from '@/models/store/product';
import { isObjectEmpty } from '@/utils/jsHelpers';
import { getPrimaryWithOpacity } from '@/utils/colors';
import { debugGradient } from '@/utils/debug';
import { getElementById } from '@/utils/dom';
import Debug from 'debug';
import { disableQuasarStyling, enableQuasarStyling } from '@/utils/styles';
import { closeIframe, shrinkIfChartIsPresent } from '@/utils/iframe';
import { BookingSteps } from '@/store/modules/newBooking/constants';
import { smeetzTracker } from '@/utils/tracking';
import { EventType } from '@/models/enums';
import { MembershipType } from '@/models/memberships';
import { didAllCustomersBookRequiredMembershipTickets, isRequiredTicketsMembership } from '@/utils/membership';
import { TicketsShowsPrices } from '@/types/app/product';
import { IPackagePrice } from '@/models/store/packages';
const debug = Debug('smeetz:seemorebutton');

@Component({
  components: {
    SlotsFilter,
    Tag,
    Slots,
    SeatComponent,
    SlotsHours,
  },
})
export default class Ticket extends Vue {
  @Prop() public ticket!: ITicket;
  @Prop({default: false}) public insideTicketSelectionView!: boolean;
  @Prop({default: false}) public hideBackButton!: boolean;
  @Prop({default: () => []}) public customCustomers!: IMembershipCustomer[];
  @Prop({default: 0}) public maxTicketValidation!: number;
  @Prop({default: () => []}) public priceIdsToDisplay!: number[];
  @Prop({default: () => []}) public categoryIdsToDisplay!: number[];
  public selectedDates: Date[] = [];
  public selectedSlot: ITimeslotsEntity[] | null = null;
  private ESlotsHours = ESlotsHours;
  private EVSlotsFilter = EVSlotsFilter;
  private slots: ITimeslotsEntity[] | null = null;
  private loading = false;
  private isSeatingTicket: boolean = false;
  private hour = '';
  private userChosenMonth: Date | null = null;
  private editTicket: boolean = this.isEditedTicket;

  // daily gradients for each month
  private monthsGradient: IMonthsGradients = {};
  // Does this ticket have gradients enabled
  private hasOccupancyGradient = false;

  // Does this ticket have a long description
  private isChangeble: boolean = false;
  // Is the long description fully displayed or is it cut
  private isFullShow: boolean = false;
  // Horizon Kehops Tickets
  private HKTickets = [95118, 92926, 92926, 96109];

  get currency() {
    return ProductModule.productCurrency;
  }

  // Force show of full description
  get showFullDescription() {
    const fbaGroupIds = [19547, 19578, 19581, 19582, 19583, 19584, 19585, 19586];
    const groupId = OrganizerModule.id || OrganizerModule.orgId;
    return fbaGroupIds.includes(Number(groupId));
  }

  // Cut the ticket.longInfo if it is long
  get handleDescription(): string {
    if (this.ticket.longInfo !== null) {
      // Display the entire text for HK tickets
      if (this.HKTickets.includes(Number(this.ticket.categoryId))) {
        return this.ticket.longInfo;
      }
      // Show the See More button if textLimit is reached
      const textLimit = 280;
      // Useful when ticket.longInfo is too near to textLimit
      let cutTextLimit = textLimit - 20;

      // Check how long is pure text in string
      const pureText = this.ticket.longInfo.replace(/<[^>]*>?/gm, '');

      // Getting the first html tag length (named: firstTextIndex)
      // Why?
      // we need to make sure that the first html tag isn't long enough to the point
      // where we're pushed to show '...see more' without any other content

      // The 'now' value is going to be used a separator to get an array of contents
      const now = Date.now().toString();
      const pureTextMatches = this.ticket.longInfo.replace(/<[^>]*>?/gm, now).split(now);
      const firstText = pureTextMatches.find((t) => t !== '' && t !== '\n');
      let firstTextIndex = 0;
      if (firstText) {
        firstTextIndex = this.ticket.longInfo.indexOf(firstText);
      }

      // if firstTextIndex is already beyond the cutTextLimit
      // it means the first html tag has length greater than the limit itself
      // In this case we'll not handle this.ticket.longInfo
      // but we'll handle it without its first tag
      // But why we're actually comparing to the half of cutTextLimit?
      // bcuz we're making sure to display one line of text before the see more button
      // if we compare to absolutely cutTextLimit we can have cases where '...see more' button
      // is shown after 1 letter only
      let descriptionToHandle: string = '';
      if (firstTextIndex >= cutTextLimit / 2) {
        descriptionToHandle = this.ticket.longInfo.slice(firstTextIndex);
      } else {
        descriptionToHandle = this.ticket.longInfo;
      }
      // If pure text > textLimit symbols and text is cutted
      if ( pureText.length > textLimit && !this.isFullShow && !this.showFullDescription) {
        this.isChangeble = true;
        // return description pure text + html text + '...' in the end
        const btnText = this.$t('new-booking-flow.order.see-more-button');
        const btnId = this.ticket.categoryId + 'SeeMoreBtn';

        let handled = descriptionToHandle.slice(0, cutTextLimit);
        // Make sure that the last word in handled text is a complete word from discreption
        const descriptionWords = descriptionToHandle.replace(/<[^>]*>?/gm, '').split(' ');
        const handledWords = handled.replace(/<[^>]*>?/gm, '').split(' ');
        const lastWord = handledWords[handledWords.length - 1];
        if (!descriptionWords.includes(lastWord)) {
          cutTextLimit -= lastWord.length;
          handled = handled.slice(0, cutTextLimit);
        }

        // lets make sure that the last html tag is closed if it is <a>
        // otherwise we risk having our '... See More' button inside a link
        // which the organizer added to the description, in this case the See More
        // button click will redirect the user to the link added by the organizer
        // or, second case, we risk having our link inside <str> tag and be displayed as a string not a link
        if (this.isMissingClosingTag(handled, 'a') || this.isMissingClosingTag(handled, 'str')
         || this.isMissingClosingTag(handled, 'br')) {
          handled = handled + '</a><br><a class="see-more-button" id="' + btnId + '"> ...' + btnText + '</a>';
        } else {
          handled = handled + '<a class="see-more-button" id="' + btnId + '"> ...' + btnText + '</a>';
        }

        return handled;
      } else {
        return this.ticket.longInfo;
      }
    } else {
      return '';
    }
  }

  get ticketError(): string {
    const parentCategory = NBookingModule.parentCategory;
    // one example of packageErrors related to min qty validations (ticket and price)
    // packageErrors {
    // parentCategoryId: 'min'
    // 12345: 'min'
    // }
    // packageErrors {
    // parentCategoryId: 'childCategoryId_priceIs/seated-price-min'
    // 12345: '12346_78910/seated-price-min'
    // }
    const err = NBookingModule.packageErrors[this.ticket.categoryId];
    // return empty string if there is no ticket errors and no price errors
    if (!err) {
      return '';
    } else if (err === 'min') {

      // if we are dealing with required tickets membership
      const membership = NBookingModule.membership;

      if (!!membership && isRequiredTicketsMembership(membership) && !this.insideTicketSelectionView) {
        const customers = NBookingModule.membershipCustomers;

        if (!didAllCustomersBookRequiredMembershipTickets(customers, membership)) {
          return this.$t('error.membership-shows-required') as string;
        }

        return '';
      }
      // If we are in second step (hideAddons === false)
      // we check ticket min booking validation of the ticket addons

      const min = ProductModule.hideAddons === true ?
        NBookingModule.ticketsMin[this.ticket.categoryId] :
        NBookingModule.addonTicketsMinRequired[this.ticket.categoryId];
      if (min === 1) {
        return this.$t('new-booking-flow.order.ticket-min-required-single') as string;
      }

      return (this.$t('new-booking-flow.order.ticket-min-required') as string).replace('XXX', String(min));
      } else if (err.includes('seated-price-min')) {
      // so we have errors of the form: parentCategoryId: 'childCategoryId_priceId/seated-price-min'
      const constuctedId = err.split('/')[0];
      const priceId = constuctedId.split('_')[1];
      const min = NBookingModule.pricesMin[constuctedId].minQty;

      return (this.$t('new-booking-flow.order.price-min-required') as string)
      .replace('XX', String(min)).replace('PRICEXXX', this.childCategoryPriceName(priceId));
    } else if (err.includes('seated-price-group-min')) {
      // so we have errors of the form: parentCategoryId: 'priceId/seated-price-group-min'
      // N.B: In here we have not validated only one price min qty, but we have validated prices group qty
      const priceId = err.split('/')[0];
      const min = NBookingModule.pricesSetMin[this.ticket.categoryId].minTotalQty;

      return (this.$t('new-booking-flow.order.price-min-required') as string)
      .replace('XX', String(min)).replace('PRICEXXX', this.childCategoryPriceName(priceId));
    } else if (err.includes('no-orphan-seat-selection-validator')) {
      return (this.$t('new-booking-flow.order.ticket-no-orphan-seats') as string);
    }

    return '';
  }

  private childCategoryPriceName(priceId: string): string {
    let name: string = '';
    const prices =  this.ticket.seatingSubProducts?.map((s) => s.price.map((p) => p));
    if (!prices) {
      return name;
    }
    const flattenedPrices = Array.prototype.concat.apply([], prices);
    name = flattenedPrices.find((p) => String(p.priceId) === priceId).priceName;
    return name;
  }

  private isMissingClosingTag(text: string, anchorName: string): boolean {
    // string does not have an opening tag
    if (text.indexOf('<' + anchorName) === -1) { return false; }

    // string has an opening tag, lets see if it has a closing tag
    const afterOpening = text.substr(text.indexOf('<' + anchorName) + ('<' + anchorName).length);
    if (afterOpening.includes('</' + anchorName + '>')) {
      const afterClosing = afterOpening.substr(afterOpening.indexOf('</' + anchorName + '>')
      + ('</' + anchorName + '>').length);
      return this.isMissingClosingTag(afterClosing, anchorName);
    } else {
      return true;
    }
  }

  get isMultiProducts(): boolean {
    return  ProductModule.isMultipleProducts;
  }

  get isCartWidget(): boolean {
    return AppModule.isCartWidget;
  }

  get products() {
    return ProductModule.products;
  }

  get productName(): string {
    return this.products.filter((p) => p.id === this.ticket.productId).map((p) => p.name)[0] || '';
  }

  get showsPrices() {
    return ProductModule.showsPrices;
  }

  private toggleFullShow() {
    this.isFullShow = !this.isFullShow;
  }

  get hasSlots(): boolean {
    if (this.ticket.categoryInfo.timeslots.length) {
      return true;
    }

    // sometimes, we have an empty array but there are dates
    // with public count, so let's check that
    return !!(this.ticket.categoryInfo.dates.find((d) => d.publicCount === '1'));
  }

  get selectedDaysSlots(): ITimeslotsEntity[] {
    // return first slot for single slot ticket
    const slots = this.slots || this.ticket.categoryInfo.timeslots;
    const product = ProductModule.product;

    if (slots.length === 1) {
      return [slots[0]];
    }

    // For non calendar tickets with multiple slots happening on the same day
    // we show nothing.
    // To avoid this, we will return all the slots for single day slots
    const days = this.ticket.categoryInfo.dates;
    if (days.length === 1 && (days[0].publicCount === '1') && slots.length) {
    // if (product && (product.id === 53851) && slots.length) {
      return slots;
    }

    const filterDates = this.selectedDates;

    // no date is selected yet, select first date
    if (filterDates.length === 0) {
      return [];
    }

    // returns slots for selected dates
    const filteredSlots = slots.filter((slot) => {
      const slotDate = dayjs(slot.startDateTime);
      for (const date of filterDates) {
        if (isSameDay(slotDate, dayjs(date))) {
          return slot;
        }
      }
    });
    return filteredSlots;
  }

  get hasSelectedDates(): boolean {
    return this.selectedDates && this.selectedDates.length > 0;
  }

  get isMobile() {
    return AppModule.isMobile;
  }

  get hasSelectedHour(): boolean {
    // TODO
    // I- Prices are displayed thru Slots.vue component
    // II- this.hour gets a value thru user clicking on one of the hourSlots displayed inside SlotsHours.vue component
    // which is always not used inside Mobile
    // (check SlotsFilter.vue component: it has this: If Mobile: Show Calendar + SlotsHours Else Show Calendar Only)
    // III- this.hour in Mobile can have a value if user clicks on one of the hours displayed
    // inside Slots.vue component,
    // but Slots.vue component will need this getter to be displayed (this component is used inside Mobile only)
    // but this getter will always return false because the user cannot select an hour
    // from SlotsHours.vue (not used in Mobile) and cannot select an hour from Slots.vue
    // (not displayed because of this getter)
    // IV- So in Mobile (with old commented condition below created to fix L3-852) it is a closed false condition
    // that will never let Slots.vue gets displayed
    // 1- Temp Solution: Comment below condition without removing it and write a new one where isMobile is considered
    // 2- Right Solution: Think about replacing the below way of displaying Calendar/days/hours:
    // Ticket.vue --> SlotsFilter2.vue --> (Calendar.vue + SlotsHours.vue) OR (Calendar.vue + below line)
    // Ticket.vue --> Slots.vue
    // return (this.slots && this.slots.length === 1) ? true : (this.hour ? true : false);
    return (this.slots && this.slots.length === 1) ? true : ((this.hour && !this.isMobile) ? true : false);
  }

  get isCalendarTicket(): boolean {
    return isCalendarTicket(this.ticket);
  }

  get showFilter(): boolean {
    // show filter for calendar tickets
    if (isCalendarTicket(this.ticket)) {
      return true;
    }

    // show filter for recurring seating plan if one date and more than one timeslot
    const slotsArray = this.slots || this.ticket.categoryInfo.timeslots;
    if (this.ticket.isRecurringSeat && slotsArray.length > 1) {
      return true;
    }

    // No filters for single slot tickets
    const slots = this.slots || this.ticket.categoryInfo.timeslots;
    if (slots.length <= 1) {
      return false;
    }

    // Make sure that all slots aren't from a single day
    // const day1 = dayjs(this.selectedDaysSlots[0].startDateTime);
    const day1 = dayjs(slots[0].startDateTime);
    for (const slot of slots) {
      if (!isSameDay(day1, dayjs(slot.startDateTime))) {
        return true;
      }
    }

    // All slots are from same day.
    // No need to show filters
    return false;
  }

  get isSoldOut(): boolean {
    // return isSoldOutTicket(this.ticket);
    const categoryInfo = this.ticket.categoryInfo;
    if (categoryInfo.onlineStatus === 'available_for_sales' ||
        categoryInfo.onlineStatus === 'not_for_sales') {
      return false;
    }

    return true;
  }

  get soldOutOnly(): boolean {
    const categoryInfo = this.ticket.categoryInfo;
    if (categoryInfo.onlineStatus === 'sold_out') {
      return true;
    }

    return false;
  }

  get ticketStatus(): string {
    if (!this.isSoldOut) {
      return '';
    }
    const status = this.ticket.categoryInfo.onlineStatus;
    if (status === 'sold_out') {
      return this.$t('button.sold-out') as string;
    } else if (status === 'available_for_sales') {
      return this.$t('common.available-for-sales') as string;
    } else if (status === 'not_started') {
      return this.$t('common.sales-not-started') as string;
    } else if (status === 'not_for_sales') {
      return this.$t('common.not-for-sales') as string;
    }

    return this.$t('common.sales-ended') as string;
  }

  get lowDemandColor() {
    return this.ticket.lowColorGradient;
  }

  get midDemandColor() {
    return this.ticket.mediumColorGradient;
  }

  get highDemandColor() {
    return this.ticket.highColorGradient;
  }

  // Some legends to be shown on the calenar to explain
  // color meaning
  get legends(): ICalendarLegend[] | null {
    if (!this.hasOccupancyGradient || !this.hasColors) {
      return null;
    }

    return [
      {text: this.$t('new-booking-flow.order.low-demand') as string, color: this.lowDemandColor},
      {text: this.$t('new-booking-flow.order.medium-demand') as string, color: this.midDemandColor},
      {text: this.$t('new-booking-flow.order.high-demand') as string, color: this.highDemandColor},
    ];
  }

  get hasColors(): boolean {
    if (this.lowDemandColor && this.midDemandColor && this.highDemandColor) {
      return true;
    }
    return false;
  }

  get shows(): ITicket[] {
    return ProductModule.showsAddons;
  }

  get isEditedTicket(): boolean {
   return !!(NBookingModule.editedTicketIds?.ticketId === this.ticket.categoryId);
  }
  private created() {
    // Flag ticket as a ticket with gradient if possible
    if (hasOccupancyGradient(this.ticket)) {
      this.hasOccupancyGradient = true;
    }

    // For memebership bookings
    // don't allow user to book if ticket group is not part of the current memebership
    const membership = NBookingModule.membership;
    // TODO: remove next comment if new membership logic is tested
    // if (ReferrerModule.isMembershipOnly && membership && membership.ticketGroup) {
    if (membership && membership.ticketGroup) {
      let groups = this.ticket.ticketGroups.map((g) => g.id);
      const seatingSubProducts = this.ticket.seatingSubProducts;

      // check seating seatingSubProducts groups
      if (seatingSubProducts && seatingSubProducts.length) {
        for (const seatCat of seatingSubProducts) {
          if (seatCat.ticketGroups && seatCat.ticketGroups.length) {
            groups = groups.concat(seatCat.ticketGroups.map((g) => g.id));
          }
        }
      }

      debug('Confirming that membership is included in', groups);
      if (!groups.includes(membership.ticketGroup.id) && membership.type !== MembershipType.RequiredTicketsMembership) {
        // alert('not included');
        enableQuasarStyling();
        this.$q.dialog({
          title: this.$t('common.warning') as string,
          message: (this.$t('membership.cant-include') as string).replace('xx', membership.name),
          persistent: true,
        }).onOk(() => {
          if (AppModule.isCartWidget) {
            debug('soft close');
            shrinkIfChartIsPresent();
            closeIframe();
            NBookingModule.stepTo(BookingSteps.Order);
            ReferrerModule.setVisibleCategories();

            // Make sure that we maintian selected tickets open
            // LEAVE COMMENTED
            // if (categories && categories.length) {
            //   for (const category of categories) {
            //     ReferrerModule.addVisibleCategory(category.categoryId);
            //   }
            // }
          }
          disableQuasarStyling();
        });
        return;
      }
    }
  }

  private async onDatesSelected(dates: Date[]) {
    // ensure that previous hour is cleared
    this.hour = '';
    this.selectedSlot = null;
    this.selectedDates = dates;
    // object that has the data we send for smeetz tracking
    // this data will be used for dynamic pricing.
    const smtzTrackingData = {
      catId: this.ticket.categoryId,
      pId: this.ticket.productId,
      date: 1 * Number(dates[0]),
    };

    smeetzTracker(EventType.availClicked, smtzTrackingData);

    // If ticket is a calendar ticket. fetch tickets for that day;
    if (!isCalendarTicket(this.ticket)) {
      return;
    }
    await this.fetchTicketSlots(dates[0]);


    this.onMonthChange(dates[0]);
  }

  /**
   * Updates the calendar view to show gradient colors on booking days
   */
  private updateGradients(month: string) {
    const slotsFilter = this.$refs['slots-filter'] as HTMLElement;
    if (!slotsFilter) {
      return;
    }

    // These are the cells on the calendar. We retrieve the selected cell too because
    // we want to make sure that it doesn't receive the gradient color because it already
    // has organiser color.
    const daysCells =
      slotsFilter.querySelectorAll('.day.cell:not(.blank):not(.disabled):not(.selected)');
    const selectedCell =
      slotsFilter.querySelectorAll('.day.cell.selected')[0];

    const monthGradients = this.monthsGradient[month];
    if (!monthGradients || isObjectEmpty(monthGradients)) {
      debugGradient(`No gradients set for ${month}, skipping`);
      return;
    }

    // different gradient colors based on occupancy
    // The higher the demand, the darker is the color

    daysCells.forEach((cell) => {
      const day = Number(cell.textContent);
      const htmlElement = cell as HTMLElement;
      if (!day) {
        return;
      }
      const dayGradient = monthGradients[day];
      if (!dayGradient && (dayGradient !== 0)) {
        return;
      }

      if (dayGradient * 100 > this.ticket.highNumberGradient) {
        htmlElement.style.backgroundColor = this.highDemandColor;
      } else if (dayGradient * 100 > this.ticket.mediumNumberGradient) {
        htmlElement.style.backgroundColor = this.midDemandColor;
      } else {
        htmlElement.style.backgroundColor = this.lowDemandColor;
      }
    });

    // Make sure that selected cell has Smeetz or organiser color
    if (selectedCell) {
      ((selectedCell as HTMLElement).style.backgroundColor as any) = null;
    }
  }

  /**
   * Calculates the daily gradient for the given month of date parameter.
   * Skips if the month represented by date has already been populated
   */
  private populateGradients(date: Date) {
    const dateObj = dayjs(date);
    const myMonth = date.getMonth(); // knowing that January returns 0, it is zero indexed
    const month = dateObj.format('YYYY-MM');

    // The daily gradient colors for this month
    const monthGradient: IMonthGradient = {};

    // 1- lets get all the dates of the current month only
    const currentMonthDates: IDatesEntity[] =
    this.ticket.categoryInfo.dates.filter((d) => new Date(d.startDate).getMonth() === myMonth);
    // 2- lets calculate the gradients of each day using its 'booked' and 'capacity' values
    for (const d of currentMonthDates) {
      monthGradient[Number(d.startDate.slice(-2))] =
      Number(d.capacity) === 0 ? 0 : Number(d.booked) / Number(d.capacity);
    }

    this.monthsGradient[month] = monthGradient;
  }

  private async updateCalendar(date: Date) {
    // Who calls this function?
    // User clicks on a month OR SlotsFilter2.vue/mounted()
    // Do gradient calculations only for gradient tickets
    if (!this.hasOccupancyGradient) {
      return;
    }

    const dateObj = dayjs(date);
    const month = dateObj.format('YYYY-MM');

    // populate the gradients for the given month
    this.populateGradients(date);

    // overlay the calendar cells with gradients colors
    // Delay was needed so that if a month was already
    // fetched, the coloring happens after the render.
    // Need to investigate why is this happening.
    await Timeout.set(0);
    this.updateGradients(month);
  }

  private async onMonthChange(month: Date | any) {
    // Who calls this function?
    // User clicks on a month OR SlotsFilter2.vue/mounted()

    // The argument can be of type Date (e.g: next month click)
    // and can be of type any (e.g: month click in MonthPicker)


    const date = month.timestamp ? new Date(month.timestamp) : month;
    this.userChosenMonth = date;

    // lets not fetch dates of Next/Previous month if we already have them
    if (this.haveDatesAlready(date)) {

      // ensure that we update occupancy colors
      if (hasOccupancyGradient(this.ticket)) {
        this.updateCalendar(date);
      }
      return;
    }

    const fetchData: ITicketSlotsFetch = {
      productId: this.ticket.productId,
      categoryId: this.ticket.categoryId,
      from: dayjs(date).format('YYYY-MM-DD'),
      to: '',
    };


    this.loading = true;
    const response = await NBookingModule.fetchTicketDates(fetchData);

    this.updateCalendar(date);
    this.loading = false;
  }

  private haveDatesAlready(monthFirstDay: Date): boolean {
    // if we have the first date of the month then we have all dates of that month

    const formattedDay = dayjs(monthFirstDay).format('YYYY-MM-DD');

    const monthFirstDayIndex = NBookingModule.prodsTickets && this.ticket.productId &&
      NBookingModule.prodsTickets[this.ticket.productId] ?
      NBookingModule.prodsTickets[this.ticket.productId].find(
      (t) => t.categoryId === this.ticket.categoryId)?.categoryInfo.dates.findIndex(
        (d) => d.startDate === formattedDay) : null;

    if (monthFirstDayIndex && monthFirstDayIndex > -1) {
      return true;
    }
    return false;
  }

  private mounted() {
    const btnId = this.ticket.categoryId + 'SeeMoreBtn';
    getElementById(btnId)?.addEventListener('click', this.toggleFullShow);

    if ( isSeatingTicket(this.ticket) && !this.isSoldOut ) {
      this.isSeatingTicket = true;
    }
  }

  private async fetchTicketSlots(day: Date) {
    // fetch slots for the selected day
    // const productId = (ProductModule.product as IProduct).id;
    const productId = this.ticket.productId;
    const categoryId = this.ticket.categoryId;
    const from = dayjs(day).format('YYYY-MM-DD');
    // to should be "from" at 23:59:59. But despite that, I always get a slot
    // from another day. So, I am just gonna request both days.
    const to = dayjs(day).add(1, 'day').format('YYYY-MM-DD');
    this.loading = true;
    try {
      const slots = await fetchTicketSlots({productId, categoryId, from, to});
      // ensure that previous hour is cleared
      this.hour = '';
      // I couldn't get the backend to select only slots from "from",
      // so I am ensuring that only slots on similar day as "from" are returned :)
      // A ticket needs to be created though, I am doing this cause we don't have time
      // to wait for backend team.
      this.slots = slots.filter((slot) => {
        return dayjs(slot.startDateTime).format('YYYY-MM-DD') === from;
      });
    } finally {
      this.loading = false;
    }
  }

  private selectHour(hour: string) {
    let slots = this.slots;
    if (!this.isCalendarTicket && !this.slots && this.isSeatingTicket && this.hasSlots) {
      slots = this.selectedDaysSlots;
    }
    if (hour && slots) {
      // get the time slot that has the slected hour as a start date.
      this.selectedSlot = slots.filter((slot) => slot.startDateTime.substring(11, 16) === hour);

      // object that has the data we send for smeetz tracking
      // this data will be used for dynamic pricing.
      const smtzTrackingData = {
        catId: this.ticket.categoryId,
        pId: this.ticket.productId,
        tsId: this.selectedSlot[0].id,
        tsTime: 1 * Number(new Date(this.selectedSlot[0].startDateTime)),
      };

      smeetzTracker(EventType.timeSlotClicked, smtzTrackingData);
    }

    this.hour = hour;
  }

  private open(show: ITicket) {
    NBookingModule.remPackageError(String(this.ticket.categoryId));
    this.$emit('open', show);
  }

  private close() {
    this.$emit('close');
  }

  private isShowSelected(ticket: ITicket) {
    const cartCategories = NBookingModule.recapCategories;
    const membershipCustomers = NBookingModule.membershipCustomers;

    // Make sure that the quantity of this ticket selected corresponds to the number of customers
    const foundTicketsQuantities = cartCategories.
      filter((cat) => cat.categoryId === ticket.categoryId || cat.seatingMainSubProductId === ticket.categoryId)
      .map((cat) => Number(cat.quantity));
    const totalTickets = foundTicketsQuantities.reduce((total, currentTicketQuantity) => {
      return total + currentTicketQuantity;
    }, 0);

    return totalTickets === membershipCustomers.length;
  }
  // on mobile we scroll to slots
  private scrollToSlots() {
    const categoryElement: HTMLElement = document.getElementById(`ticket-${this.ticket.categoryId}`) as HTMLElement;
    if (categoryElement) {
       const elementToScrollTo: HTMLElement = categoryElement.querySelector('.slots-filter') as HTMLElement;
       elementToScrollTo?.scrollIntoView({block: 'start', behavior: 'smooth'});
    }
  }
  // For calendar ticket we display the time slot that we want to edit
  @Watch('isEditedTicket', {immediate: true})
  private async editedTicket() {
    // Get the edited ticket
    if (!this.isEditedTicket || !this.isCalendarTicket) {
      return;
    }
    const editedTicket = NBookingModule.recapCategories.find((ticket) =>
    ticket.timeSlotId === NBookingModule.editedTicketIds?.timeSlotId);
    if (editedTicket) {
      // Get the date of the edited ticket
      const date: Date = new Date(editedTicket.startDateTime);
      this.userChosenMonth = date;
       // Fetch the slots for the edited date
      await this.onDatesSelected([date]);
      // display the chosen month
      this.selectedDates = [date];
      const slot = this.slots?.find((s) => s.id === editedTicket.timeSlotId);
      if (slot) {
        const hour = dayjs(slot.startDateTime).format('HH:mm');
        // Get the edited hour
        this.hour = hour;
      }
    }
  }
}

import 'url-search-params-polyfill';
if (!Object.entries) {
  // tslint:disable-next-line
  Object.entries = function( obj:any ) {
    const ownProps = Object.keys( obj );
    let i = ownProps.length;
    const resArray = new Array(i); // preallocate the Array
    while (i--) {
      resArray[i] = [ownProps[i], obj[ownProps[i]]];
    }

    return resArray;
  };
}


// reload if we have a wallee redirect, because this will hurt analytics by setting
// wallee as a referrer
// const referrer = document.referrer;
// if (referrer && referrer.includes('wallee.com')) {
//   location.href = location.href;
// }

import Vue from 'vue';
import Meta from 'vue-meta';
import Vuelidate from 'vuelidate';
import App from './App.vue';
import VueGtm from 'vue-gtm';
import * as VueGoogleMaps from 'vue2-google-maps';

import router from './router';
import store from './store';
import i18n from './locales';
import { isInProduction } from './utils/platform';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import Autocomplete from '@trevoreyre/autocomplete-vue';
// import 'animate.css';
import 'vue2-animate/dist/vue2-animate.min.css';
import '@adyen/adyen-web/dist/adyen.css';
// Quasar import
import './quasar';
import { SITE_URL } from './config/constants';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

// import './styles/styles.scss';
// import './registerServiceWorker';

Vue.config.productionTip = false;


// Google maps options
const googleMapsOption = {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
    libraries: 'places',
  },
};

// Sentry init (should work only on production)
if (isInProduction()) {
  Sentry.init({
    Vue,
    environment:  process.env.VUE_APP_BUILD_ENV,
    release: process.env.VUE_APP_VERSION,
    dsn: process.env.VUE_APP_SENTRY_URL,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: [
          'localhost',
        ],
    }),
    ],
    tracesSampleRate: 0,
    tracingOptions: {
    trackComponents: true,
    },
    // Vue specific
    logErrors:  true,
    attachProps: true,
    attachStacktrace: true,
  });
}

Object.defineProperty(Vue.prototype, '$BASE_URL', {
  value: process.env.BASE_URL,
});
Object.defineProperty(Vue.prototype, '$S_URL', {
  value: process.env.STATICS_URL || 'https://smeetz.com',
});
Object.defineProperty(Vue.prototype, '$SITE_URL', {
  value: SITE_URL || 'https://smeetz.com',
});

Vue.use(VueGtm, {
  id: process.env.VUE_APP_GTM_ID, // Your GTM ID
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false (optional)
  debug: !isInProduction(), // Whether or not display console logs
  // vueRouter: router, // Pass the router instance to automatically sync with router (optional)
});

Vue.use(Meta);
Vue.use(Vuelidate);
Vue.use(VueGoogleMaps, googleMapsOption);
Vue.use(VueAwesomeSwiper);
Vue.use(Autocomplete);


new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

import Vue from 'vue';
import Debug from 'debug';
import { Mixin } from 'vue-mixin-decorator';
import { NBookingModule } from '@/utils/storemodules';

const debug = Debug('smeetz:acompte');

@Mixin
export default class Acompte extends Vue {

  public get isAcompteBooking(): boolean {
    return NBookingModule.isAcompteBooking;
  }

  public get toBeChargedOnSiteAmount() {
    return NBookingModule.toBeChargedOnSiteAmount;
  }

  public get isAcompteEqualToTotal(): boolean {
    return NBookingModule.isAcompteEqualToTotal;
  }
}

import { ISmeetzPicture } from '@/types/db/product-category';


export const CARD_IMAGE_WIDTH = '250';
export const CARD_IMAGE_HEIGHT = '250';
export const CARD_IMAGE_MEASUREMENT_UNIT = 'px';

/**
 *
 * Takes a picture metadata and returns a url to image proxy
 *
 */
export function createImageproxyUrl(payload: ISmeetzPicture | undefined) {
  if (!payload) {
    return '';
  }
  const { metaData, name } = payload;

  const imgProxyUrlStart = process.env.VUE_APP_IMAGEPROXY_URLSTART || '';
  const metadataURL = getMetadataUrl(metaData);
  const base64url = geEncodedUrl(name);

  const imgProxyUrl = imgProxyUrlStart + metadataURL + base64url;
  return imgProxyUrl;
}

export function getMetadataUrl(metadata: string) {
  // console.log(JSON.parse(metadata));
  // resize with filling option to XXXxYYY with enlarging if it is originally smaller, and without gravity
  const metadataUrl =
    '/rs:fill:' + CARD_IMAGE_WIDTH + ':' + CARD_IMAGE_HEIGHT + ':1/g:no/';
  return metadataUrl;
}

export function geEncodedUrl(pictureUrl: string) {
  const s3url = process.env.VUE_APP_S3URL || '';
  const encodedUrl =
    btoa(s3url + 'retail/' + pictureUrl)
      .replace(/=/g, '')
      .replace(/\+/g, '-')
      .replace(/\//g, '_') + '.png';
  return encodedUrl;
}

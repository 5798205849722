import { VuexModule, Module, MutationAction, Action, Mutation } from 'vuex-module-decorators';
import { fetchProduct, fetchProducts, fetchTicketsAddons, postProductGroupId, postReportActivity } from '@/api/booking';
import { IProduct, IPicturesEntity } from '@/models/store/product';
import { IProductState } from '@/models/store/product';
import { AppModule, NBookingModule, ReferrerModule } from '@/utils/storemodules';
import Debug from 'debug';
import { getRetailOverview } from '@/api/retail';
import { IDonationTicket, IProductCategory } from '@/types/db/product-category';
import { IExportPricesEntity, ITicket, ITimeslotsEntity } from '@/models/store/booking';
import { TicketsAllowedPrices, TicketsShowsPrices } from '@/types/app/product';
import { IPackagePrice } from '@/models/store/packages';
import { isRequiredTicketsMembership, requiredTicketsMemberships } from '@/utils/membership';
import { timeslotsToDates } from '@/utils/tickets';
const debug = new Debug('smeetz:productname');

@Module({ name: 'product', namespaced: true })
class Product extends VuexModule implements IProductState {
  public prod: IProduct | null = null;
  public products: IProduct[] = [];
  public isLoading = false;
  public retails: IProductCategory[] = [];
  public donations: IDonationTicket[] = [];
  public viewedDonation: boolean = false;
  public retailAddons: IProductCategory[] = [];
  public ticketsAddons: ITicket[] = [];
  public showsAddons: ITicket[] = [];
  public piazzaShowsAddons: ITicket[] = [];
  public requiredNumberPiazzaSeats: number = 0;
  public requiredNumberPiazzaSeatsPerCategory: Array<{categoryId: number, maxSeats: number}> = [];
  public hideAddons: boolean = true;
  public displayPrices: Record<number, number> = {
    20664: 75,
    20746: 38,
    195065: 140,
    196755: 90,
    211177: 180,
    211176: 200,
    // 2024 Membership
    244141: 140,
    244140: 90,
  };

  // This will allow ticket selection view to show which prices/categories are available for a given ticket
  public addonTicketsShowsPrices: TicketsShowsPrices = {};
  // Only set if we want to override default info returned from backend
  public currency = '';

  // Current user product group. This is an identifier that allows
  // the backend to know if this user has already seen a product with a given price.
  // Used by Mathias for dynamic pricing.
  public userGroup: string = '';

  get showsPrices() {

    // Just making sure that we don't show the same price 2 times
    // will comment and clean after release
    const showsP = this.addonTicketsShowsPrices;

    const showsWithUniquePriceValue: TicketsShowsPrices = {};
    const values: number[] = [];

    for (const categoryId of Object.keys(showsP)) {
      const categoryPrices: IPackagePrice[] = (showsWithUniquePriceValue as any)[categoryId] = [];

      const prices: IPackagePrice[] = (showsP as any)[categoryId];
      for (const price of prices) {
        if (!categoryPrices.find((p) => p.displayedValue === price.displayedValue)) {
          categoryPrices.push(price);
        }
      }
    }

    return showsWithUniquePriceValue;
  }

  get productPictures(): IPicturesEntity[] {
    let pictures: IPicturesEntity[] = [];
    if (this.product && this.product.pictures) {
      pictures = this.product.pictures;
    }

    return pictures;
  }

  get productDevicePictures() {
    // Remove first item if you don't need product image cover picture
    const filteredArray =  AppModule.isMobile ?
      this.productPictures.filter((slide) =>
        slide.type === 2
        && (slide.coverTypeName === 'None cover'  || slide.coverTypeName === 'Mobile cover')
        && slide.isPrimary === false)
      :
      this.productPictures.filter((slide) =>
        slide.type === 2 &&
        slide.coverTypeName === 'None cover');
    const primaryIndex = filteredArray.findIndex((item: any) => {
      return item.isPrimary;
    });
    // Move isPrimary item to first place
    filteredArray.unshift(filteredArray[primaryIndex]);
    filteredArray.splice(primaryIndex + 1, 1);
    return filteredArray;
  }

  get productImageCover() {
    return AppModule.isMobile ?
      this.productPictures.filter((slide) => slide.type === 3 && slide.coverTypeName === 'Mobile cover') :
      this.productPictures.filter((slide) => slide.type === 2 && slide.isPrimary === true);
  }

  // Returns product slug
  get productShortUrl(): string {
    if (!this.product) {
      return '';
    }

    return this.product.short_url;
  }

  get productCurrency() {
    let currency = '';
    if (this.currency) {
      currency = this.currency;
    } else if ( this.product ) {
      currency = this.product.currency;
    }

    if (currency === 'GBP') {
      return '£';
    } else if (currency === 'USD') {
      return '$';
    } else if (currency === 'EUR') {
      return '€';
    }

    return currency || 'CHF';
  }

  get productCountry(): string {
    const product = this.product;

    if (!product || !product.location) {
      return '';
    }

    const loc = product.location;

    if (!loc.country) {
      return '';
    }

    return loc.country;
  }

  // Sold out products have a status of 2, expired 3
  // not bookable 0
  // bookable = 1
  get productBookingStatus(): number {
    if (!this.product) {
      return 0;
    }

    return this.product.booking.status;
  }

  get isSoldOut(): boolean {
    return this.productBookingStatus !== 1;
  }

  get organiserName(): string {
    const product = this.product;
    if (!product) {
      return '';
    }

    const organiser = product.organiser;
    if (!organiser) {
      return '';
    }

    return organiser.name;
  }

  get organiserSlug(): string {
    const product = this.product;
    if (!product) {
      return '';
    }

    const organiser = product.organiser;
    if (!organiser) {
      return '';
    }

    return organiser.slugName;
  }

  // Returns either first product in list of products or single product if present
  get product(): IProduct | null {
    return this.products[0] || this.prod;
  }
  // Returns either last product in list of products or single product if present
  get lastProduct(): IProduct | null {
    return this.products[this.products.length - 1] || this.prod;
  }

  get isMultipleProducts() {
    return this.products.length > 1;
  }

  get visibleProductCategories(): IProductCategory[] {
    const pcs = this.retails || [];
    return pcs.filter(ReferrerModule.isPCVisible);
  }

  get activityAddons(): IProductCategory[] {
    return this.retailAddons;
  }

  get activityTicketAddons(): ITicket[] {
    return this.ticketsAddons;
  }

  get hasAddonsInASeparateStep(): boolean {
    return this.activityAddons.length > 0 || this.activityTicketAddons.length > 0;
  }

  get addonsTicketsTotalPrice() {
    const membership = NBookingModule.membership;
    if (membership && isRequiredTicketsMembership(membership) && NBookingModule.membershipCustomers.length > 0) {
      const requiredTickets = requiredTicketsMemberships(membership);
      return Object.values(requiredTickets).reduce((total, ticket) => {
        return total + this.displayPrices[ticket.priceId] * Number(ticket.quantity);
      }, 0);
    }
    // We can have more than one price in a show, we take the min price to calculate the total
    // return Object.values(this.addonTicketsShowsPrices).reduce((total, prices) => {
    //   const minPrice = Math.min(...prices.map((price: IPackagePrice) => price.priceValue));
    //   return total + minPrice;
    // }, 0) * NBookingModule.membershipCustomers.length;
  }

  @Mutation
  public setHideAddons(status: boolean) {
    this.hideAddons = status;
  }

  @Mutation
  public setLoading(status: boolean) {
    this.isLoading = status;
  }

  @Mutation
  public setProduct(product: IProduct) {
    this.prod = product;
  }

  @Mutation
  public setCurrency(currency: string) {
    this.currency = currency;
  }

  @Mutation
  public setProducts(products: IProduct[]) {
    this.products = products;
  }

  @Mutation
  public setRetails(retails: IProductCategory[]) {
    this.retails = retails;
  }

  @Mutation
  public setDonations(donations: IDonationTicket[]) {
    this.donations = donations;
  }

  @Mutation
  public setViewedDonation(value: boolean) {
    this.viewedDonation = value;
  }

  @Mutation
  public setRetailAddons(retailAddons: IProductCategory[]) {
    this.retailAddons = retailAddons;
  }

  @Mutation
  public setTicketsAddons(ticketAddons: ITicket[]) {
    this.ticketsAddons = ticketAddons;
  }

  @Mutation
  public setShowsAddons(showsAddons: ITicket[]) {
    this.showsAddons = showsAddons;
  }

  @Mutation
  public setPizzaShowsAddons(piazzaShowsAddons: ITicket[]) {
    this.piazzaShowsAddons = piazzaShowsAddons;
  }

  @Mutation
  public setRequiredNumberPiazzaSeats(requiredNumberPiazzaSeats: number) {
    this.requiredNumberPiazzaSeats = requiredNumberPiazzaSeats;
  }

  @Mutation
  public setRequiredNumberPiazzaSeatsPerCategory(requiredNumberPiazzaSeatsPerCategory:
    Array<{categoryId: number, maxSeats: number}>) {
    this.requiredNumberPiazzaSeatsPerCategory = requiredNumberPiazzaSeatsPerCategory;
  }


  @Mutation
  public setAddonTicketsShowsPrices(showsPrices: TicketsShowsPrices) {
    this.addonTicketsShowsPrices = showsPrices;
  }

  // GET retail by Id
  @Action({ rawError: true })
  public async fetchRetail(ids: number[]) {

    // Handle case when no ids are supplied.
    if (ids.length === 0) {
      return [];
    }

    this.setLoading(true);

    // Ensure that we don't fetch retails (ProductCategories) that are already fetched.
    const fetchedRetails = this.retails || [];
    const fetchedIds = fetchedRetails?.map((pc) => pc.id);
    const toFetchIds = ids.filter((id) => !fetchedIds.includes(id));
    try {
      if (toFetchIds.length) {
        const response = await getRetailOverview(toFetchIds);
        if (response) {
          this.setRetails([...fetchedRetails, ...response]);
        } else {
          throw new Error(`GetRetailOverview: error\n` + `Retail Ids: ${toFetchIds}`);
        }
      }

      return {
        retails: (this.retails || []).filter((pc) => ids.includes(pc.id)),
      };
    } finally {
      this.setLoading(false);
    }
  }

  // GET retail addons by Id
  @Action({ rawError: true })
  public async fetchRetailAddon(ids: number[]) {

    // Handle case when no ids are supplied.
    if (ids.length === 0) {
      return [];
    }

    this.setLoading(true);

    // Ensure that we don't fetch retails (ProductCategories) that are already fetched.
    const fetchedRetailAddons = this.retailAddons || [];
    const fetchedIds = fetchedRetailAddons?.map((pc) => pc.id);
    const toFetchIds = ids.filter((id) => !fetchedIds.includes(id));
    try {
      if (toFetchIds.length) {
        const response = await getRetailOverview(toFetchIds);
        if (response) {
          this.setRetailAddons([...fetchedRetailAddons, ...response]);
        } else {
          throw new Error(`GetRetailOverview: error\n` + `Retail Ids: ${toFetchIds}`);
        }
      }

      return {
        addons: (this.retailAddons || []).filter((pc) => ids.includes(pc.id)),
      };
    } finally {
      this.setLoading(false);
    }
  }

  // GET tickets addons by Id
  @Action({ rawError: true })
  public async fetchTicketsAddon(payload: {
    ids: number[];
    isShowAddons?: boolean,
    isPiazzaAddon?: boolean,
    codes?: string[] }) {
    // Handle case when no ids are supplied.
    if (payload.ids.length === 0) {
      return [];
    }
    this.setLoading(true);
    try {
      const response = await fetchTicketsAddons(payload.ids, payload.codes);
      if (response) {
        if (payload.isShowAddons) {
          this.setShowsAddons(response);
        } else if (payload.isPiazzaAddon) {
          this.setPizzaShowsAddons(response);
        } else {
        // Set Max booking
          if (NBookingModule.linkedTicketIdsMax.length > 0) {
            let index = 0;
            payload.ids.forEach((id) => {
              response[index].maxAddonQuantity = NBookingModule.linkedTicketIdsMax[index];
              response[index].isAddonInSeparateStep = true;
              index++;
            });
          }
          response.forEach((category) => {
            const categoryId = category.categoryId;
            // We check if there is a ticket package linked to this category
            const linkedPackageCategory = NBookingModule.linkedTicketPackageCategory[categoryId];
            if (linkedPackageCategory) {
              /*
              * We take the timeslots from the package
              * In case of a calendar we receive in category info only 9 timeslots
              * And we might not get the timeslots that we got on packages in the timeslots of categoryinfo
              * So we keep the timeslots of price package
              */
              const timeslots = linkedPackageCategory.package_time_slots as any as ITimeslotsEntity[];
              if (timeslots) {
                const packagePrices = linkedPackageCategory.package_prices;
                const packagePricesWithTimeSlot = Object.values(packagePrices).map((price) => {
                  return {
                  ...price,
                  mainTimeSlotId: linkedPackageCategory.mainTimeSlotId,
                  };
                });
                timeslots.forEach((timeslot) => {
                  timeslot.export_prices = packagePricesWithTimeSlot as any;
                });
                category.categoryInfo.dates = timeslotsToDates(timeslots);
                category.categoryInfo.timeslots = timeslots;
              }
            }
          });
          const finalResponse = response.filter((cat) => cat.categoryInfo.timeslots.length > 0);
          this.setTicketsAddons(finalResponse);
        }
      } else {
        throw new Error(`Fetch ticket addons: error\n` + `tickets Ids: ${payload.ids}`);
      }
    } finally {
      this.setLoading(false);
    }
  }

  // GET product data and write it to product state
  // @MutationAction({ mutate: [ 'product' ] })
  @Action({ rawError: true })
  public async fetchProduct(productShortName: string) {
    this.setLoading(true);

    try {
      const response = await fetchProduct(productShortName);

      if (response) {
        this.setProduct(response);
        return {
          product: response,
        };
      } else {
        throw Error('GetProduct: error');
      }
    } finally {
      this.setLoading(false);
    }
  }

  // GET product overview
  @Action({ rawError: true })
  public async fetchProductDateAndInfos(productShortName: string) {
    this.setLoading(true);

    try {
      const response = await fetchProduct(productShortName);

      if (response) {
        return {
          productOverview: response.overview,
        };
      } else {
        throw Error('GetProduct: error');
      }
    } finally {
      this.setLoading(false);
    }
  }

  /**
   * Fetches multiple products.
   *
   * @param productIds : array of product ids
   */
  @Action({ rawError: true })
  public async fetchProducts(productIds: number[]): Promise<IProduct[]> {
    // skip if nothing is supplied
    if (!productIds.length) {
      return [];
    }

    // set loading state
    this.setLoading(true);

    // Fetch only products that haven't been fetched already
    const toFetch: number [] = [];
    const fetchedProducts = this.products;
    for (const id of productIds) {
      if (!fetchedProducts.find((prod) => prod.id === id)) {
        toFetch.push(id);
      }
    }

    try {

      // If all products are present, return them
      if (toFetch.length === 0) {
        return this.products.filter((prod) => productIds.includes(prod.id));
      }
      // fetch products
      const products = await fetchProducts(toFetch, {rm_slots: '1'});

      this.setProducts([...fetchedProducts, ...products]);

      // return products that match what's in productsId
      return products.filter((p) => productIds.includes(p.id));

    } catch (err) { // handle error

      throw err;

    } finally { // when all is done, unset loading state
      this.setLoading(false);
    }
  }

  @Action({ rawError: true })
  public async fetchUserGroup(productId: number) {
    await postProductGroupId(productId);
  }

  @Action
  public async sendReport(data: any) {
    const str = [];
    for (const p in data) {
      if (data.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(data[p]));
      }
    }
    try {
      await postReportActivity(str.join('&'));
    } catch (e) {
      throw Error(`SendReportActivity: ${e.message}`);
    }
  }
}
export default Product;




















import {Vue, Component, Prop} from 'vue-property-decorator';

@Component
export default class Hero extends Vue {
  @Prop({default: 'assets/img/placeholder-img.jpg'}) public image!: string;
  @Prop({default: 'Example'}) public exampleProperty!: string;
  @Prop({default: false}) public isFull!: boolean;

  private heroData =
    {
      page: 'attend',
      imgsrc: '/assets/img/rasterized/icons/instagram.png',
    };

  private msg: string = 'Img for column here';

  private hasPicture() {
    const pictures = this.heroData.imgsrc;
    return Boolean(pictures.length);
  }

  get imageSrc() {
    // const noPicture = 'assets/img/placeholder-img.jpg';
    const pictures = this.heroData.imgsrc;

    if (this.hasPicture()) {
      // console.log('yes');
      // const pictures = this.product.pictures.filter(picture => picture.isPrimary)[0];
      // return pictures ? pictures.url : noPicture;
      return pictures;
    } else {
      // console.log('no');

      // return noPicture;
    }
  }

}


// AUTH

// Modal

/*
 * Booking
 */
// getters
export const GET_SELECTED_CATEGORIES = 'GET_SELECTED_CATEGORIES';
export const GET_TICKETS = 'GET_TICKETS';
export const GET_RECAP = 'GET_RECAP';
export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_PRODUCT_NAME = 'GET_PRODUCT_NAME';
export const GET_PRODUCT_ID = 'GET_PRODUCT_ID';
export const GET_PRODUCT_SHORT_URL = 'GET_PRODUCT_SHORT_URL';
export const GET_CALENDAR_DATES = 'GET_CALENDAR_DATES';
export const GET_GUEST_BOOKING = 'GET_GUEST_BOOKING';
export const GET_BOOKING = 'GET_BOOKING';
export const GET_SERVER_BOOKING = 'GET_SERVER_BOOKING';
export const GET_BOOKING_ID = 'GET_BOOKING_ID';
export const GET_BOOKING_TOKEN = 'GET_BOOKING_TOKEN';
export const GET_BOOKING_FLOW = 'GET_BOOKING_FLOW';
export const GET_BOOKING_FLOW_TYPE = 'GET_FLOW_TYPE';
export const GET_LOADING = 'GET_LOADING';
export const GET_IS_INSTANT_BOOKING = 'GET_IS_INSTANT_BOOKING';
export const GET_RECAP_TOTAL = 'GET_RECAP_TOTAL';
export const GET_DISCOUNT = 'GET_DISCOUNT';
export const GET_FEES = 'GET_FEES';
export const GET_PAYMENT_METHOD = 'GET_PAYMENT_METHOD';
export const GET_FLATTENED_CATEGORIES = 'GET_FLATTENED_CATEGORIES';
export const GET_SELECTED_SLOTS = 'GET_SELECTED_SLOTS';
export const GET_SLOT_BY_DATE = 'GET_SLOT_BY_DATE';
export const GET_TICKETS_TRACKING = 'GET_TICKETS_TRACKING';
export const GET_BOOKING_REFERRER_SITE = 'GET_BOOKING_REFERRER_SITE';
export const GET_PRODUCT_CURRENCY = 'GET_PRODUCT_CURRENCY';
export const GET_PRODUCT_DESCRIPTION = 'GET_PRODUCT_DESCRIPTION';
export const GET_PRODUCT_LOCATION = 'GET_PRODUCT_LOCATION';
export const GET_DP_SLOT = 'GET_DP_SLOT';
export const GET_DP_SLOT_DATA = 'GET_DP_SLOT_DATA';
export const GET_AVOIDED_DP_SLOT = 'GET_AVOIDED_DP_SLOT';
export const GET_IS_DP_BOOKING = 'GET_IS_DP_BOOKING';
export const GET_DP_MAX_TICKETS = 'GET_DYNAMIC_PRICING_MAX_TICKET';
export const GET_DP_PRICE = 'GET_DYNAMIC_PRICING_PRICE';
export const GET_NEXT_PRODUCT_DATE = 'GET_NEXT_PRODUCT_DATE';

// mutations
export const SET_LOADING = 'SET_LOADING';
export const SET_PRODUCT = 'SET_PRODUCT';
export const RESET_BOOKING_STATE = 'RESET_BOOKING_STATE';
export const SET_AVAILABILITIES = 'SET_AVAILABILITIES';
export const SET_CALENDAR = 'SET_CALENDAR';
export const SET_DATE = 'SET_DATE';
export const SET_BOOKING = 'SET_BOOKING';
export const SET_GUEST_BOOKING = 'SET_GUEST_BOOKING';
export const SET_SERVER_BOOKING = 'SET_SERVER_BOOKING';
export const CLEAR_BOOKING = 'CLEAR_BOOKING';
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD';
export const CLEAR_CHARTS = 'STEP_BACK_ASYNC';
export const DESTROY_CHARTS = 'DESTROY_CHARTS';
export const ADD_SLOT = 'ADD_SLOT';
export const REMOVE_SLOT = 'REMOVE_SLOT';
export const SET_DP_SLOT = 'SET_DP_SLOT';
export const ADD_AVOIDED_DP_SLOT = 'ADD_AVOIDED_DP_SLOT';

// actions
export const ADD_TICKET = 'ADD_TICKET';
export const REMOVE_TICKET = 'REMOVE_TICKET';
export const FETCH_PRODUCT = 'FETCH_PRODUCT';
export const FETCH_PRODUCTS = 'FETCH_PRDUCTS';
export const FETCH_AVAILABILITIES = 'FETCH_AVAILABILITIES';
export const FETCH_PRODUCT_CALENDAR = 'FETCH_PRODUCT_CALENDAR';
export const PRE_BOOK = 'MAKE_BOOKING';
export const CANCEL_BOOKING = 'CANCEL_BOOKING';
export const LOAD_PAYMENT_METHODS = 'LOAD_PAYMENT_METHODS';
export const FETCH_SERVER_BOOKING = 'FETCH_SERVER_BOOKING';
export const POST_PROMO_CODE = 'POST_PROMO_CODE';
export const TOGGLE_SLOT = 'TOGGLE_SLOT';
export const PATCH_GUEST_BOOKING = 'PATCH_GUEST_BOOKING';
export const FETCH_RANDOM_SLOT = 'FETCH_RANDOM_SLOT';
export const AVOID_DP_SLOT = 'AVOID_DP_SLOT';


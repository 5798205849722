















import { Component, Vue } from 'vue-property-decorator';
import SocialAuth from '@/components/SocialAuth.vue';
import LocalAuth from '@/components/LocalAuth.vue';
import LoginSignupLink from '@/components/presentational/LoginRegisterLink.vue';
import ModalNav from '@/components/presentational/ModalNav.vue';

@Component({
  components: {
    SocialAuth,
    LocalAuth,
    LoginSignupLink,
    ModalNav,
  },
})
export default class Login extends Vue {
}

































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { ProductModule, NBookingModule, AppModule } from '@/utils/storemodules';
import { Mixins } from 'vue-mixin-decorator';
import Timeout from 'await-timeout/dist/es5';
import { getElementById, scrollIntoView, scrollToElement } from '@/utils/dom';
import OrderSummary from './OrderSummary.vue';
import Select from '@/components/presentational/NewSelect.vue';
import Price from './BasketPrice.vue';
import PromoCode from './NewPromoCode.vue';
import {
  ITicket,
  ITimeslotsEntity, IExportPricesEntity, IRecapInfo,
} from '@/models/store/booking';
import {
  IProduct, IPicturesEntity,
} from '@/models/store/product';
import BookingDoneMixin from '@/mixins/BookingDone';
import DateHelperMixin from '@/mixins/DateHelper';
import AcompteMixin from '@/mixins/Acompte';
import DonationMixin from '@/mixins/Donation';
import { EVPromoCode } from '@/models/events';
import NewButton from '@/components/presentational/NewButton.vue';
import ToolTipIcon from '@/components/presentational/ToolTipIcon.vue';
import { isGiftTicket } from '../../../utils/booking';
import { widgetContShopping } from '@/utils/iframe';
import { disableQuasarStyling, enableQuasarStyling } from '@/utils/styles';
import { Loading } from 'quasar';
import { didAllCustomersBookRequiredMembershipTickets, findMembershipCategories, isRequiredTicketsMembership } from '@/utils/membership';
import { submitCustomersCustomFields } from '@/utils/membership-utils';
import { MembershipType } from '@/models/memberships';
import { areRequiredSecondPageAddonsSelected, openTheAddonShowRelatedToCurrentFetchedCategory } from '@/utils/addon';

interface IMixinInterface extends DateHelperMixin, BookingDoneMixin, AcompteMixin, DonationMixin {}

@Component({
  components: {
    Select,
    Price,
    PromoCode,
    NewButton,
    ToolTipIcon,
    OrderSummary,
  },
})
export default class NewBasket extends Mixins<IMixinInterface>(
  DateHelperMixin,
  BookingDoneMixin,
  AcompteMixin,
  DonationMixin) {
  @Prop({default: 0}) private windowWidth!: number;
  private promoPopped = false;
  private showMobileBasket = false;
  private showPromo = false;
  private EVPromoCode = EVPromoCode;
  private classElem: string = 'stop-scrolling';
  private documentHTML: HTMLElement = document.getElementsByTagName('html')[0];
  private documentBody: HTMLElement = document.getElementsByTagName('body')[0];

  // Watcher invoked when showing / hiding basket
  @Watch('$store.state.nbooking.showBasket')
  private onShowBasketChange(showBasket: boolean) {
    this.showBasket(showBasket);
  }

  private get isEnglish() {
    return this.$i18n.locale === 'en';
  }

  private get isMobile() {
    // return this.windowWidth < 768;
    return true;
  }

  private get product(): IProduct | null {
    return ProductModule.product;
  }

  private get currency() {
    return ProductModule.productCurrency;
  }

  private get isLoading(): boolean {
    return ProductModule.isLoading;
  }

  // Get pictures from product info
  private get productPictures(): IPicturesEntity[] {
    return ProductModule.productPictures;
  }

  private get pictureForMobile(): IPicturesEntity | null {
    return ProductModule.productPictures.find((slide) => slide.type === 4) || null;
  }

  private get pictureForDesktop(): IPicturesEntity | null {
    return ProductModule.productPictures.find((slide) => slide.type === 2) || null;
  }

  private get isEmpty(): boolean {
    // return !this.recapInfo.isFree && NBookingModule.basketTotal === 0;
    const { isFree, total } = this.recapInfo;
    return !this.recapInfo.isFree &&  total === 0;
    // return false;
  }

  private get hasNoAllBookingCodes(): boolean {
    return NBookingModule.hasNoAllBookingCodes;
  }

  private get membershipPriceWasAdded() {
    return NBookingModule.membershipPriceWasAdded;
  }

  private get isUpdating(): boolean {
    return NBookingModule.loading;
  }

  private get selectedTickets(): ITicket[] {
    return NBookingModule.selectedTickets;
  }

  private get ticketSlots(): (ticket: ITicket) => ITimeslotsEntity[] {
    return NBookingModule.ticketSelectedSlots;
  }

  private get selectedPrices(): (prices: IExportPricesEntity[]) => IExportPricesEntity[] {
    return (prices: IExportPricesEntity[]) => {
      return prices.filter((price: IExportPricesEntity) => {
        return !!price.count;
      });
    };
  }

  private get recapInfo(): IRecapInfo {
    return NBookingModule.recapInfo;
  }

  private get fees(): number {
    return this.recapInfo.fees;
  }

  private get discount(): number {
    return this.recapInfo.discount;
  }

  private get numberBookingCodePrices() {
    return NBookingModule.numberBookingCodePrices;
  }

  private get total(): number {
    if (this.isEmpty && this.membershipPriceWasAdded) {
      if (this.numberBookingCodePrices.length === 0) {
        return 0;
      }
      const membershipPrice = this.numberBookingCodePrices[0];
      return Number(membershipPrice.count) * Number(membershipPrice.price.priceValue);
    }
    // return NBookingModule.basketTotal;
    let totalMembershipPrice = 0;
    const membership = NBookingModule.membership;
    if (isRequiredTicketsMembership(membership) && ProductModule.addonsTicketsTotalPrice) {
      totalMembershipPrice = ProductModule.addonsTicketsTotalPrice;
    }
    return this.recapInfo.total >= totalMembershipPrice ? this.recapInfo.total : totalMembershipPrice;
  }

  private get deposit(): number  {
    return this.recapInfo.deposit;
  }

  private get isCartWidget() {
    return AppModule.isCartWidget;
  }

  private get totalWithAcompte() {
    return this.total + this.toBeChargedOnSiteAmount;
  }

  private get acompteMessage() {
    return (this.$t('new-booking-flow.common.deposits-msg') as string).replace('XX', String(this.total.toFixed(2)))
            .replace('XXX', this.currency);
  }

  private get showDate() {
    return (ticket: ITicket) => {
      return !isGiftTicket(ticket);
    };
  }

  private get isNewLoginFlowOpened() {
    return NBookingModule.showLoginView;
  }

  private get hideAddons(): boolean {
    return ProductModule.hideAddons;
  }

  private get buyNowText() {
    // On second step addon german we display a special text for buy button
    if (!this.hideAddons) {
      return this.$t('new-booking-flow.order.basket-buy-button-second-step');
    }
    return this.$t('new-booking-flow.order.basket-buy-button');
  }
  // private async created() {
    // const { short_name } = this.$route.params;
    // await ProductModule.fetchProduct(short_name);

    //  Check if product !== null
    // if ( this.product !== null) {
    //   this.getPicturesUrl();
    // }
  // }

  // called when the promo code is popped on mobile
  private handlePromo(promoPopped: boolean) {
    this.promoPopped = promoPopped;
  }

  private togglePromo() {
    this.showPromo = !this.showPromo;
  }

  private async showBasket(show: boolean) {

    if (AppModule.isCartWidget && !show && NBookingModule.getOpenCart) {
      if (NBookingModule.closeCart) {
        NBookingModule.setOpenCart(false);
        this.close();
      }
      await Timeout.set(200);
    }
    this.showMobileBasket = show;
    NBookingModule.setShowBasket(show);

    // stop window scroll if showing full basket
    if (show) {
      this.stopScrolling();
    } else {
      this.allowScrolling();
    }
  }

  private async next() {
    // Make sure that basket is hidden
    NBookingModule.setShowBasket(false);

    // If Piazza grande seating plan we show it
    if (ProductModule.piazzaShowsAddons.length > 0) {
      const piazzaSeatingPlan = openTheAddonShowRelatedToCurrentFetchedCategory();
      if (piazzaSeatingPlan) {
        this.$emit('open', piazzaSeatingPlan);
        return;
      } else if (NBookingModule.piazzaGrandeValidation) {
        // If Piazza grande show set from validation check
        if (ProductModule.piazzaShowsAddons[0]) {
          this.$emit('open', ProductModule.piazzaShowsAddons[0]);
          return;
        }
      }
    }

    // Make sure that no price has errors
    const missingPrices = NBookingModule.missingReqBookingPrices;
    if (missingPrices.length) {
      for (const price of missingPrices) {
        NBookingModule.addPriceError(String(price.priceId));
      }
      const firstPrice = missingPrices[0];
      const element = getElementById(`price${firstPrice.priceId}`);
      if (element) {
        scrollIntoView(element);
      }
      return;
    }

    // Make sure that all tickets in 2 step reach their min
    const ticketsWithoutMin = NBookingModule.ticketsWithoutMinReached;
    if (ticketsWithoutMin.length
      && !ProductModule.hideAddons
      && ProductModule.hasAddonsInASeparateStep) {
      for (const categoryId of ticketsWithoutMin) {
        NBookingModule.addPackageError({packageId: categoryId});
      }
      const firstTicket = ticketsWithoutMin[0];
      const element = getElementById(`ticket-${firstTicket}`);
      if (element) {
        scrollIntoView(element);
      }
      return;
    }
    // Make sure that all packages reach their min
    const packagesWithoutMin = NBookingModule.packagesWithoutMinReached;
    if (packagesWithoutMin.length) {
      for (const packageId of packagesWithoutMin) {
        NBookingModule.addPackageError({packageId});
      }
      const firstPackage = packagesWithoutMin[0];
      const element = getElementById(`${firstPackage}`);
      if (element) {
        scrollToElement(element);
      }
      return;
    }

    // Make sure that total qty of packages do not exceed the max
    const packagesWithMaxExceeded = NBookingModule.packagesWithMaxExceeded;
    if (packagesWithMaxExceeded.length) {
      for (const packageId of packagesWithMaxExceeded) {
        NBookingModule.addMaxPackageError(packageId);
      }
      const firstPackage = packagesWithMaxExceeded[0];
      const element = getElementById(`${firstPackage}`);
      if (element) {
        scrollToElement(element);
      }
      return;
    }

    const parentCategory = NBookingModule.parentCategory;

    // 1- Validate tickets min
    const ticketsMin = NBookingModule.ticketsMin;
    // For all the tickets that are in the recap
    const bookingCategories = NBookingModule.recapCategories;

    const cats: {[catId: number]: number} = {};

    // Group seated tickets by category id cause different seats are different
    // recap categories.
    for (const cat of bookingCategories) {
      if (!cats[cat.categoryId]) {
        cats[cat.categoryId] = 0;
      }

      (cats as any)[cat.categoryId] += Number(cat.quantity);
    }

    // tickets that have a min validation should satisfy the min condition
    for (const cat of Object.keys(cats)) {
      const categoryMin = ticketsMin[parentCategory[Number(cat)]];
      const quantity = cats[Number(cat)];

      // ticket doesn't satisfy the condition, scroll to it and show the error.
      if (categoryMin && Number(quantity) < categoryMin) {
        NBookingModule.addPackageError({packageId: (parentCategory as any)[cat]});

        const element = getElementById(`ticket-${(parentCategory as any)[cat]}`);
        const elementToScrollTo = element?.querySelector('.filtered-slots') as HTMLElement;
        if (elementToScrollTo) {
          scrollToElement(elementToScrollTo);
        }
        return;
      }
    }

    // BEGIN PRICE MINIMUM QUANTITY VALIDATION
    // 2- Validate prices min
    const pricesMin = NBookingModule.pricesMin;

    // Now lets initiate an object of ticketId_priceId:qty
    const constructedIds = Object.keys(pricesMin);
    const ticketsPrices: {[ticketIdPriceId: string]: number} = {};
    for (const constructedId of constructedIds) {
      ticketsPrices[constructedId] = 0;
    }
    // Group seated tickets by category id cause different seats are different
    // recap categories.
    for (const cat of bookingCategories) {
      const constructedId: string =
      NBookingModule.parentCategoryForPrices[cat.categoryId]?.toString().concat('_', String(cat.priceId));
      if (!pricesMin[constructedId]) {
        // ticket does not include min validation for this price
        // lets continue in the loop
        continue;
      }

      // so the ticket has price min validation, lets add the qty to what is already in the array
      (ticketsPrices as any)[constructedId] += Number(cat.quantity);
    }


    // prices that have a min validation should satisfy the min condition
    // N.B: ignore prices min validation of other products than the current one

    // lets build current product parentCategoryId_priceId array
    const pricesToCheck = NBookingModule.fetchedTickets.map((t) => t.seatingSubProducts?.map((s) =>
    s.price.map((p) => String(t.categoryId) + '_' + String(p.priceId)).flat()).flat()).flat();
    for (const constructedId of Object.keys(ticketsPrices)) {
      if (pricesToCheck.indexOf(constructedId) < 0) {
        // this parentCategoryId_priceId does not belong to current product, lets ignore it and move on
        continue;
      }

      const priceMin = pricesMin[constructedId].minQty;

      const isPriceOptional = pricesMin[constructedId].optional;

      const quantity = ticketsPrices[constructedId];


      // if the price is optional and qty = 0, then don't check its min qty validaity
      // if the price is optional and qty > 0, then check its min qty validaity
      // if the price is mendatory then always check its min qty validity
      if ((priceMin && Number(quantity) < priceMin && isPriceOptional === false)
      || (priceMin && Number(quantity) < priceMin && Number(quantity) > 0 && isPriceOptional === true)) {
        // lets show the min validation error of the price X that is related to the ticket Y
        // in the area of the parentTicket Z
        const parentTicketId: number = Number(constructedId.split('_')[0]);
        const error: string = 'seated-price-min';
        // the error in the case of price min qty validation  going to be like:
        // 'parentcaegoryId': 'childCategoryid_priceId/errorType'
        // TODO:currently it shows only one price error if we have more, when the user satisfies first price validation
        // it shows the second price qty validation.
        // we can show 2 vaidations msgs of 2 prices but it requires time
        // TODO: current implementation of NBookingModule.packageErrors requires price min qty validation error
        // to be overriden by category min qty validation error
        // so if there are category min validation and its prices min validation in the same time, the user
        // will see category msgs first then when satisfied he will see price validations msgs
        // NBookingModule.addPackageError({packageId: (parentCategory as any)[childTicketId],
        NBookingModule.addPackageError({packageId: parentTicketId.toString(),
        error: constructedId + '/' + error});

        // const element = getElementById(`ticket-${(parentCategory as any)[childTicketId]}`);
        const element = getElementById(`ticket-${parentTicketId.toString()}`);
        if (element) {
          scrollToElement(element);
        }
        return;
      }
    }

    // END PRICE MINIMUM QUANTITY VALIDATION


    // BEGIN PRICES GROUP MINIMUM QUANTITY VALIDATION
    // 3- Validate prices group total min qty
    // What is the difference between this validation and the one in point (2-)
    // The previous point validate one price min qty. This point validate a set of prices min total qty
    const pricesSetMin = NBookingModule.pricesSetMin;

    // lets get all parentTickets that have this type of validation
    const parentTicketsIds = Object.keys(pricesSetMin);

    const currentBookingCategories = NBookingModule.fetchedTickets.map((c) => c.categoryId);
    for (const parentId of parentTicketsIds) {
      if (currentBookingCategories.indexOf(Number(parentId)) < 0) {
        // this categoryId does not belong to current booking, lets ignore it and move on
        continue;
      }
      let sumBooked = 0;

      // From the recap calculate the sum of tickets booked from prices that are considered in Prices Set Min Validation
      // of the current parentTicket
      bookingCategories.filter((c) =>
      NBookingModule.parentCategoryForPrices[c.categoryId] === Number(parentId)
      && pricesSetMin[parentId].pricesIds.indexOf(String(c.priceId)) >= 0).forEach((ourTicket) =>
      sumBooked = sumBooked + Number(ourTicket.quantity));


      // if the prices group is optional and qty = 0, then don't check its min qty validaity
      // if the prices group is optional and qty > 0, then check its min qty validaity
      // if the prices group is mendatory then always check its min qty validity
      if ((sumBooked > 0 && sumBooked < pricesSetMin[parentId].minTotalQty && pricesSetMin[parentId].optional)
      || (sumBooked < pricesSetMin[parentId].minTotalQty && !pricesSetMin[parentId].optional)) {
        // lets show the min validation error of the pricesIds A and B (but have the same priceName)
        // that is related to the ticket X in the area of the parentTicket Y

        // // if prices do not have the same name cancel the validation
        // if (this.hasDiffPriceNames(pricesSetMin[parentId].pricesIds)) {
        //   // ignore this group of prices and move to the next one
        //   continue;
        // }

        const error: string = 'seated-price-group-min';
        // the error in the case of prices set min qty validation  going to be like:
        // 'parentcaegoryId': 'priceId/errorType'
        NBookingModule.addPackageError({packageId: parentId,
        error: pricesSetMin[parentId].pricesIds[0] + '/' + error});

        const element = getElementById(`ticket-${parentId}`);
        if (element) {
          scrollToElement(element);
        }

        return;
      }
    }

    // In case the no orphan seat validation is activated
    if (NBookingModule.getSeatSelectionValidators.length > 0) {
      const error: string = 'no-orphan-seat-selection-validator';
      NBookingModule.addPackageError({packageId: NBookingModule.getSeatSelectionValidators[0].toString(), error});
      const element = getElementById(`ticket-${NBookingModule.getSeatSelectionValidators[0]}`);
      if (element) {
        scrollToElement(element);
      }
      return;
    }

    // END PRICES GROUP MINIMUM QUANTITY VALIDATION

    // Check if we have addons
    if (ProductModule.hasAddonsInASeparateStep && ProductModule.hideAddons === true && !NBookingModule.getisCartInfo) {
        window.scrollTo(0, 0);
        this.$emit('showAddons');
        return;
    }

    // Ensure that all customers in a required tickets memebership bought tickets
    const membership = NBookingModule.membership;
    const membershipCustomers = NBookingModule.membershipCustomers;
    if (
      isRequiredTicketsMembership(membership) &&
      !didAllCustomersBookRequiredMembershipTickets(
        membershipCustomers,
        membership,
      )
    ) {
      const membershipCategories = findMembershipCategories(NBookingModule.bookingRes);
      if (membershipCategories?.length) {
        // It's safe to take the first one cause in required ticket memberships, we book only 1 membership
        const membershipCategory = membershipCategories[0];

        NBookingModule.addPackageError({
          packageId: String(membershipCategory.categoryId),
          error: 'min',
        });
        const element = getElementById(`ticket-${membershipCategory.categoryId}`);
        if (element) {
          scrollToElement(element);
        }
        return;
      }
    }

    if (this.recapInfo.isFree || this.total > 0) {
      // we post custom fields here only for regular membership
      if (membership && membership.type === MembershipType.RegularMembership) {
        this.stopScrolling();
        enableQuasarStyling();
        Loading.show();
        try {
          await submitCustomersCustomFields();
        } catch (error) {
          setTimeout(() => {
            throw error;
          }, 0);
        } finally {
          Loading.hide();
          disableQuasarStyling();
          this.allowScrolling();
        }
      }
      this.allowScrolling();
      if (this.showMobileBasket && this.donationCategory) {
        ProductModule.setViewedDonation(true);
      }
      NBookingModule.stepForward();
    }
  }

  // private hasDiffPriceNames(priceIds: string[]): boolean {
  //   const diffNames = false;
  //   const prices = NBookingModule.fetchedTickets.map((t) =>
  //   t.seatingSubProducts?.map((s) => s.price.map((p) => p))).flat().flat();
  //   const names = prices.filter((p) => priceIds.indexOf(String(p?.priceId)) >= 0).map((p) => p?.priceName);
  //   for (let i = 0; i < names.length; i++) {
  //     for (let j = 0; j < names.length; j++) {
  //       if (names[i] !== names[j]) {
  //         return true;
  //       }
  //     }
  //   }
  //   return diffNames;
  // }

  private stopScrolling(): void {
    this.documentHTML.classList.add(this.classElem);
    // this.documentBody.classList.add(this.classElem);
  }

  private allowScrolling(): void {
    this.documentHTML.classList.remove(this.classElem);
    // this.documentBody.classList.remove(this.classElem);
  }

  private contShopping() {
    // If Piazza grande seating plan we show it and we haven't selected
    // all of it's required addons
    if (
      !areRequiredSecondPageAddonsSelected() &&
      (ProductModule.piazzaShowsAddons.length > 0)
    ) {
      const piazzaSeatingPlan = openTheAddonShowRelatedToCurrentFetchedCategory();
      if (piazzaSeatingPlan) {
        this.$emit('open', piazzaSeatingPlan);
        return;
      }
    }

    // for piazza grande, if we selected what's required, let's end it here
    // this way, we can select other shows
    if (ProductModule.piazzaShowsAddons.length > 0) {
      ProductModule.setAddonTicketsShowsPrices({});
      ProductModule.setPizzaShowsAddons([]);
      ProductModule.setRequiredNumberPiazzaSeats(0);
      ProductModule.setRequiredNumberPiazzaSeatsPerCategory([]);
    }

    this.showBasket(false);
    widgetContShopping();
  }

}

















































import { Vue, Component } from 'vue-property-decorator';
import { AppModule } from '@/utils/storemodules';
import { GET_SCROLL_Y } from '@/store/modules/app/constants';

@Component({})
export default class DynamicPricing extends Vue {
  private active = false;
  private links = [
    {name: 'best-deals', route: ''},
    // {name: 'Wishlist', route: ''},
    {name: 'smeetz', route: '/'},
    // {name: 'Register', route: '/register'},
  ];
  private selectedLink: string = '';


  get transparent() {
    return AppModule.getScrollY < 269;
  }

  private mounted() {
    this.selectedLink = (this.$t('dynamic.best-deals') as string);
  }

  private toggle() {
    this.active = !this.active;
  }
}

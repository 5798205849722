// common
import i18n from '@/locales';
import mixpanel from 'mixpanel-browser';

// modules
import {
  OrganizerModule,
  UserModule,
  AppModule,
  NBookingModule,
} from '@/utils/storemodules';

// helpers
import { inIframe } from '@/utils/iframe';
import { isInProduction } from '@/utils/platform';
import { BookingSteps } from '@/store/modules/newBooking/constants';
import { buildMixpanelCartList } from '@/utils/tracking';

// consts
export enum EVENTS {
  UserIdentifier = 'user-identifier',
  OpenWidget = 'open-widget',
  Order = 'order',
  Checkout = 'checkout',
  Payment = 'payment',
  Confirmation = 'confirmation',
  DownloadTickets = 'download-tickets',
  UserOnLandingPage = 'user-on-landing-page',
  AddToCart = 'add-to-cart',
  ViewItemsList = 'view-items-list',
}

export interface IUserIdentifier {
  userId?: number | null;
  userName: string;
  userEmail: string;
}

const MESSAGE_MAPPER: any = {
  [EVENTS.UserIdentifier]: 'User identifier',
  [EVENTS.OpenWidget]: 'Open widget',
  [EVENTS.Order]: 'Order',
  [EVENTS.Checkout]: 'Checkout',
  [EVENTS.Payment]: 'Payment',
  [EVENTS.Confirmation]: 'Confirmation',
  [EVENTS.DownloadTickets]: 'Download tickets',
  [EVENTS.UserOnLandingPage]: 'User on landing page',
  [EVENTS.AddToCart]: 'Add To Cart',
  [EVENTS.ViewItemsList]: 'View Items List',
};

interface IMixpanelService {
  init(): void;
  track(event: string, data: any): void;
}

/**
 * The service which provide tracking event in Mixpanel
 *
 * @class
 */
class MixpanelService implements IMixpanelService {
  private tokenMixpanel = String(process.env.VUE_APP_MIXPANEL_TOKEN);
  private env = process.env.VUE_APP_BUILD_ENV;
  private isWidget: boolean = inIframe();
  private isProd = isInProduction();
  private initParams = {
    env: this.env,
    app: this.isWidget ? 'widget' : 'b2c',
  };

  public init() {

      mixpanel.init(this.tokenMixpanel, {
        debug: !this.isProd,
        track_pageview: false,
        persistence: 'localStorage',
      });

      this.registerProperties();

  }

  /**
   * Function that track all events
   *
   * @returns {void}
   */
  public track(event: string, data: any) {

      this.handler(event, data);

  }

  // Register properties
  private registerProperties() {
    // register super properties
    mixpanel.register({
      ...this.initParams,
    });
  }

  /**
   * Function that subscribe to all events related to Mixpanel
   *
   * @param {string} event: event
   * @param {object} data: data
   *
   * @returns {void}
   */
  private handler(event: string, data: any) {
    const mainParams = {
      groupId: OrganizerModule.id || 'None',
      groupName: OrganizerModule.orgName || 'None',
      lang: i18n.locale,
      sessionId: AppModule?.appSessionId,
      userEmail: UserModule.userEmail || 'None',
      ...(this.isWidget && { widgetId: AppModule?.widgetId || 'None' }),

      bookingId: String(NBookingModule.bookingId || 'None'),
      bookingRef: String(NBookingModule.bookingRes?.bookingRef || 'None'),
      bookingTotal : NBookingModule.bookingRes?.bookingRecap?.total || 'None',
      bookingCurrency : NBookingModule.bookingRes?.bookingRecap?.currency || 'None',
      paymentProcessor: OrganizerModule.getInfo != null ? OrganizerModule.getInfo.groupInfo.paymentProcessor : 'None',
      control_group: NBookingModule.getDpTrackingData.control_group ?? 'None',
      strategy_type_name: NBookingModule.getDpTrackingData.strategy_name ?? 'None',
      cart: buildMixpanelCartList() ?? 'None',
    };

    if (event === EVENTS.UserIdentifier) {
      const { userId = null, userName, userEmail } = data as IUserIdentifier;

      // mixpanel.identify must be called in order to associate the profile properties you set
      // with that user. You only need to call it once per page load for a given user.
      if (userId) {
        mixpanel.identify(String(userId));
      } else {
        mixpanel.identify();
      }

      // set user properties
      mixpanel.people.set({
        $email: userEmail || 'None',
        $name: userName,
        lang: i18n.locale,
      });
    } else {
      mixpanel.track(MESSAGE_MAPPER[event], { ...mainParams, event });
    }
  }
}

export default new MixpanelService();

export function parseBookingStep(step: BookingSteps) {
  switch (step) {
    case BookingSteps.Order: return EVENTS.Order;
    case BookingSteps.Checkout: return EVENTS.Checkout;
    case BookingSteps.Confirmation: return EVENTS.Confirmation;
    case BookingSteps.Payment: return EVENTS.Payment;
    default: return EVENTS.Order;
  }
}







































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { ProductsModule, RegionModule } from '@/utils/storemodules';
import Calendar from '@/components/presentational/Calendar.vue';
import { client } from '@/utils/searchConfig';
import dayjs from 'dayjs';
import { EVCalendar } from '@/models/events';

@Component({
  components: {
    Calendar,
  },
})
export default class CalendarInput extends Vue {
  @Prop() public options!: any;
  @Prop() public propDate!: Date;
  private date: Date | null = null;
  private proxyDate: string | Date | null = null;

  get dateLabel() {
    if (this.date) {
      return dayjs(this.date).format('YYYY-MM-DD');
    }

    return '';
  }

  private created() {
    if (this.propDate) {
      this.date = this.propDate;
    }
  }
  /**
   * Opens the calendar view
   */
  private showCalendar() {
    const component: any = this.$refs.calendarComponent;
    component.showCalendar();
  }

  private updateProxy() {
    this.proxyDate = this.date ? dayjs(this.date).format('YYYY/MM/DD') : dayjs().format('YYYY/MM/DD');
  }

  private save() {
    if (this.proxyDate) {
      this.date = dayjs(this.proxyDate).toDate();
      this.$emit(EVCalendar.DateSelected, this.date);
    }
  }
}

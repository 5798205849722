


























import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class RatingStars extends Vue {
  // private rating: number = 4.5;
  @Prop({ default: 5 }) public rating!: number;
  private total: number = 5;

}

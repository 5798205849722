






import { Component, Vue, Watch } from 'vue-property-decorator';
import { AuthModule, AppModule } from '@/utils/storemodules';
import { defineLanguage } from '@/utils/helpers';
import { iframeInit, siteInit } from '@/utils/siteInit';
import { IUser } from './models/definitions';
import request, { requestb2bAPI } from '@/utils/request';
import { setBookingColors } from '@/utils/colors';
import { IWidgetStyles } from './models/styles';
import * as Sentry from '@sentry/vue';
import Mixpanel, { EVENTS, IUserIdentifier } from '@/services/mixpanel';
import Debug from 'debug';
const debug = Debug('smeetz:auth');

@Component({})
export default class App extends Vue {

  @AuthModule.Getter('isLoggedIn') private isLoggedIn!: boolean;
  @AuthModule.Mutation('setLang') private setLanguage: any;

// We'll change the color value in css whenever state.primaryColor is changed
  @Watch('$store.state.app.primaryColor', {immediate: true})
  public onPrimaryColorChanged(newVal: string, oldVal: string) {
    // lets now change css color using the new value of state.app.primaryColor
    setBookingColors({smeetzPrimaryColor: newVal});
  }

  @Watch('$store.state.auth.lang')
  private onLangChanged(newLang: string) {

    // ensure that we are using a supported language
    if (!(['en', 'fr', 'de', 'it', 'nl', 'es', 'hu'].includes(newLang))) {
      this.$i18n.locale = 'en';
    } else {
      this.$i18n.locale = newLang;
    }
    if (
      this.$route.path.includes('/en/') ||
      this.$route.path.includes('/fr/') ||
      this.$route.path.includes('/de/') ||
      this.$route.path.includes('/it/') ||
      this.$route.path.includes('/nl/') ||
      this.$route.path.includes('/es/') ||
      this.$route.path.includes('/hu/')
    ) {
      // tslint:disable-next-line:max-line-length
      this.$router.replace({ params: {...this.$route.params, locale: this.$i18n.locale}, query: {...this.$route.query}});
      const HTML_TAG = document.getElementsByTagName('html');
      (HTML_TAG as any)[0].lang = this.$i18n.locale;
    }
    request.defaults.baseURL = (request.defaults.baseURL as string).slice(0, -3) + this.$i18n.locale + '/';
    requestb2bAPI.defaults.baseURL = (request.defaults.baseURL as string).slice(0, -3) + this.$i18n.locale + '/';
  }

  @Watch('$store.state.auth.user')
  private onUserChange(user: IUser) {
    if (user) {
      // Set the user on sentry
      Sentry.setUser({
        id: user.id,
        email: user.emails[0].emailAddress,
        firstname: user.firstName,
      });

      // User Tracking
      const logData: IUserIdentifier = {
        userId: Number(user.id),
        userName: user.fullname,
        userEmail: user.emails[0].emailAddress,
      };
      Mixpanel.track(EVENTS.UserIdentifier, logData);
    }
  }

  private mounted() {
    // Initialize Mixpanel
    Mixpanel.init();

    iframeInit();
    siteInit();

    // set language if user isn't logged in
    if (!this.isLoggedIn) {
      this.setLanguage(defineLanguage());
    }
  }

}

import Vue from 'vue';
import axios from 'axios';
import store from '@/store';
import router from '@/router';
import { getToken, getTokenExpiry } from '@/utils/token';
import { loginWithSmtzToken } from '@/api/auth';

import Debug from 'debug';
import { AuthModule, OrganizerModule, UserModule } from './storemodules';
import { LoggerService } from '@/services/log';

const debug = Debug('smeetz:auth');

const apiURL = process.env.VUE_APP_API;
const esURL = process.env.VUE_APP_ES_API; // elastic search url
const trackURL = process.env.VUE_APP_TRACK_API;
const graphQL = process.env.VUE_APP_GQL_URL;
const api2URL = process.env.VUE_APP_API2;
const logURL = process.env.VUE_APP_LOG_API;

export const requestBasic = axios.create();
const request = axios.create({
  baseURL: apiURL,
  headers: {
    'X-api-key': process.env.VUE_APP_X_API_KEY,
    // 'Content-Type': 'application/x-www-form-urlencoded',
  },
});
export const requestb2b = axios.create({
  baseURL: apiURL.replace('b2c', 'b2b'),
  headers: {
    'X-api-key': process.env.VUE_APP_X_API_KEY,
    // 'Content-Type': 'application/x-www-form-urlencoded',
  },
});
export const requestb2bAPI = axios.create({
  baseURL: apiURL.replace('b2c', 'b2b2'),
  headers: {
    'X-api-key': process.env.VUE_APP_X_API_KEY,
    // 'Content-Type': 'application/x-www-form-urlencoded',
  },
});
export const requestGraphql = axios.create({
  baseURL: graphQL,
  headers: {
    'Content-Type': 'application/json',
  },
});
export const request2 = axios.create({
  baseURL: api2URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const logRequest = axios.create({
  baseURL: logURL,
  headers: {
    'Content-Type': 'application/json',
  },
});
// Set default headers between different http methods

request.interceptors.request.use(async (config) => {
  debug(`Making request with the following config: ${config}`);

  // A user is considered logged based on the following order of checks
  // 1 - store auth getter isLoggedIn returns true
  // 2 - an accessToken cookie is set
  // 3 - store auth getter getToken return a token.
  //
  // If none of the above is true, then the user is not considered
  // logged in
  const loggedIn = store.getters['auth/isLoggedIn']
    || getToken()
    || store.getters['auth/getToken'];

  // Set 'Authorization' token for logged in users
  if (loggedIn) {
    // refresh the token if it expired
    if (getTokenExpiry() <= 0) {
      debug('We have an expired token, Attempting to refresh');
      await store.dispatch('auth/refreshToken');
    }

    // Add token to header
    debug(`User is logged in. Going to add header`);
    const token = store.getters['auth/getToken'] || getToken();
    config.headers.Authorization = `Bearer ${token}`;
  }

  // Add timing metadata to the request
  // Capture the request time and store it in the config object
  (config as any).metadata = { requestStartTime: Date.now() };

  return config;
}, (error) => {

  // report to bugsnag if present
  // if (Vue.bugsnagClient) {
  //   Vue.bugsnagClient.notify(error);
  // }

  return Promise.reject(error);
});

requestb2b.interceptors.request.use(async (config) => {
  debug(`Making request with the following config: ${config}`);

  // A user is considered logged based on the following order of checks
  // 1 - store auth getter isLoggedIn returns true
  // 2 - an accessToken cookie is set
  // 3 - store auth getter getToken return a token.
  //
  // If none of the above is true, then the user is not considered
  // logged in
  const loggedIn = store.getters['auth/isLoggedIn']
    || getToken()
    || store.getters['auth/getToken'];

  // Set 'Authorization' token for logged in users
  if (loggedIn) {
    // refresh the token if it expired
    if (getTokenExpiry() <= 0) {
      debug('We have an expired token, Attempting to refresh');
      await store.dispatch('auth/refreshToken');
    }

    // Add token to header
    debug(`User is logged in. Going to add header`);
    const token = store.getters['auth/getToken'] || getToken();
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}, (error) => {

  // report to bugsnag if present
  // if (Vue.bugsnagClient) {
  //   Vue.bugsnagClient.notify(error);
  // }

  return Promise.reject(error);
});

request.interceptors.response.use((response) => {
  // Calculate the latency
  const requestStartTime = (response.config as any).metadata.requestStartTime;
  const latency = Date.now() - requestStartTime;
  LoggerService.info(`Request for ${response.config.url} took ${latency}ms`, {
    value: latency,
    response: response.data,
    request: response.request,
  });

  return response;
}, (error) => {
  // report to bugsnag if present
  // if (Vue.bugsnagClient) {
  //   Vue.bugsnagClient.notify(error);
  // }

  return Promise.reject(error);
});

requestGraphql.interceptors.request.use(async (config) => {
  debug(`Making request with the following config: ${config}`);

  // A user is considered logged based on the following order of checks
  // 1 - store userModule getter isCustomAuthLoggedIn returns true
  //
  // If none of the above is true, then the user is not considered
  // logged in
  const isCustomAuthLoggedIn = UserModule.isCustomAuthLoggedIn;
  const customAuthToken = UserModule.customAuthToken;

  let resAuthToken: string = '';
  // We fetch the graphQL auth token if we are in smeetz.com
  // this auth token is used in the hearders of axios requests.
  if (!customAuthToken) {
    const smtzToken = store.getters['auth/getToken'];
    if (smtzToken) {
      // Gets the qraphQL auth token.
      const res = await loginWithSmtzToken(smtzToken);
      resAuthToken = res.token;
      UserModule.setCustomAuthToken(resAuthToken);
    }
  }

  const loggedIn = store.getters['auth/isLoggedIn']
    || getToken()
    || store.getters['auth/getToken'];

  // Because user can use this request from Smeetz website and from a widget as well
  if (isCustomAuthLoggedIn || (loggedIn && UserModule.getCustomAuthToken)) {
    debug(`User is logged in. Going to add header`);
    const token = UserModule.getCustomAuthToken;
    config.headers['smtz-auth'] = token;
  }

    // Add timing metadata to the request
  // Capture the request time and store it in the config object
  (config as any).metadata = { requestStartTime: Date.now() };

  return config;
}, (error) => {

  // report to bugsnag if present
  // if (Vue.bugsnagClient) {
  //   Vue.bugsnagClient.notify(error);
  // }

  return Promise.reject(error);
});

requestGraphql.interceptors.response.use((response) => {
  // Calculate the latency
  const requestStartTime = (response.config as any).metadata.requestStartTime;
  const latency = Date.now() - requestStartTime;

  try {
    // Get the operation name from the request payload
    const regex = /query\s*{\s*\\n\s*([a-zA-Z0-9_]+)/;
    const match = response.config.data.match(regex);
    const operationName = match ? match[1] : null;
    LoggerService.info(`Request for ${operationName} took ${latency}ms`, {
      value: latency,
      response: response.data,
      request: response.request,
    });
  } catch (error) {
    // tslint:disable-next-line:no-console
    console.error('Error while logging request latency', error);
  }

  return response;
}, (error) => {
  return Promise.reject(error);
});

/*requestCustomAuth.interceptors.request.use(async (config) => {
  debug(`Making request with the following config: ${config}`);

  // A user is considered logged based on the following order of checks
  // 1 - store userModule getter isCustomAuthLoggedIn returns true
  //
  // If none of the above is true, then the user is not considered
  // logged in
  const loggedIn = UserModule.isCustomAuthLoggedIn;

  // Set 'Authorization' token to header for logged in users
  if (loggedIn) {
    debug(`User is logged in. Going to add header`);
    const token = UserModule.getCustomAuthToken;
    config.headers['smtz-auth'] = token;
  }
  return config;
}, (error) => {

  // report to bugsnag if present
  if (Vue.bugsnagClient) {
    Vue.bugsnagClient.notify(error);
  }

  return Promise.reject(error);
});*/

request2.interceptors.response.use((response) => {
  return response;
}, (error) => {
  // report to bugsnag if present
  // if (Vue.bugsnagClient) {
  //   Vue.bugsnagClient.notify(error);
  // }

  return Promise.reject(error);
});

export const esRequest = axios.create({
  baseURL: esURL,
  auth: {
    username: process.env.VUE_APP_ES_USER,
    password: process.env.VUE_APP_ES_PASSWORD,
  },
});

esRequest.interceptors.request.use((config) => {
  // const token = window.btoa(`${process.env.VUE_APP_ES_USER}:${process.env.VUE_APP_ES_PASSWORD}`);
  // config.headers.Authorization = `Basic ${token}`;
  // console.log(config);
  return config;
}, (error) => {
  // report to bugsnag if present
  // if (Vue.bugsnagClient) {
  //   Vue.bugsnagClient.notify(error);
  // }

  return Promise.reject(error);
});

esRequest.interceptors.response.use((response) => {
  return response;
}, (error) => {
  // report to bugsnag if present
  // if (Vue.bugsnagClient) {
  //   Vue.bugsnagClient.notify(error);
  // }

  return Promise.reject(error);
});

// Tracking client
export const trRequest = axios.create({
  baseURL: trackURL,
  withCredentials: true,
});

trRequest.interceptors.request.use((config) => {
  // const token = window.btoa(`${process.env.VUE_APP_ES_USER}:${process.env.VUE_APP_ES_PASSWORD}`);
  // config.headers.Authorization = `Basic ${token}`;
  // console.log(config);
  return config;
}, (error) => {
  // report to bugsnag if present
  // if (Vue.bugsnagClient) {
  //   Vue.bugsnagClient.notify(error);
  // }

  return Promise.reject(error);
});

trRequest.interceptors.response.use((response) => {
  return response;
}, (error) => {
  // report to bugsnag if present
  // if (Vue.bugsnagClient) {
  //   Vue.bugsnagClient.notify(error);
  // }

  return Promise.reject(error);
});

export default request;

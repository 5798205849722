


































































































































































import { Vue, Component } from 'vue-property-decorator';
import Datepicker from 'vuejs-datepicker';
import moment from 'moment';
import Inputmask from 'inputmask';
// import * as VueGoogleMaps from 'vue2-google-maps'
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import { AuthModule, NModalModule, NNoty } from '@/utils/storemodules';
import { helpers } from 'vuelidate/lib/validators';
import { Validator } from '@/utils/validators';
import ModalButton from '@/components/modal/ModalButton.vue';
import Select from '@/components/presentational/Select.vue';
import { constants } from '@/config/constants.ts';
import { fromSlashDate, fromDashDate } from '@/utils/helpers';
import { ModalStatus } from '@/models/definitions';

// Custom validator: Checks that the user is at least 13 years old
const currentDate = moment(new Date()).startOf('day');
const maxDate = helpers.withParams(
  {type: 'maxDate', value: currentDate.subtract(13, 'years').format('DD/MM/YYYY')},
  (value: string) => moment(value, 'DD/MM/YYYY', true).isBefore(currentDate),
);

@Component({
  components: {
    Datepicker,
    ModalButton,
    Select,
  },
  validations: {
    firstname: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(255),
      invalid: Validator.validName,
    },
    lastname: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(255),
      invalid: Validator.validName,
    },
    birthdate: {
      required,
      maxDate,
    },
    // location: {
    //   required,
    // },
  },
})
export default class EditPersonalDetails extends Vue {
  private firstname: string = '';
  private lastname: string = '';
  private birthdate: string = '';
  private location: string = '';
  private markers: any = [];
  private lat: number = Number(constants.defaultGeolocationArr.latitude);
  private lng: number = Number(constants.defaultGeolocationArr.longitude);
  private center: object = {lat: this.lat, lng: this.lng};
  private places: any = [];
  private currentPlace: any = null;
  private langChanged = false;
  private languages = [
    {
      key: 'first',
      value: 'English',
      lang: 'en',
    },
    {
      key: 'second',
      value: 'Français',
      lang: 'fr',
    },
    {
      key: 'third',
      value: 'Deutsch',
      lang: 'de',
    },
    {
      key: 'Fourth',
      value: 'Italiano',
      lang: 'it',
    },
  ];

  @AuthModule.Getter('getLanguageName') private getLang!: string;
  @AuthModule.Getter('getBirthdate') private getBirthdate!: string;
  @AuthModule.Getter('getLocation') private getLocation: any;
  @AuthModule.Getter('firstName') private getFirstName!: string;
  @AuthModule.Getter('lastName') private getLastName!: string;
  @AuthModule.Mutation('setLang') private setLang: any;
  @AuthModule.Action('updateUser') private updateUser: any;

  get enableSubmit() {
    return !this.$v.$anyError &&
    !this.$v.$invalid &&
    (
      this.$v.$anyDirty ||
      (this.currentPlace && this.currentPlace.place_id) ||
      this.langChanged
    );
}

  get locationName() {
    if (this.currentPlace) {
      return this.currentPlace.formatted_address;
    }

    const loc: any = this.location;
    if (!(loc && loc.city && loc.country)) {
      return '';
    }

    return `${loc.city}, ${loc.country}`;
  }

  private mounted() {
    Inputmask({alias: 'datetime', inputFormat: 'dd/mm/yyyy'}).mask(document.getElementById('birthdate'));
    this.geolocate();
    this.firstname = this.getFirstName;
    this.lastname = this.getLastName;
    this.location = this.getLocation;

    const birthdate = this.getBirthdate;
    if (birthdate) {
      this.birthdate = fromDashDate(this.getBirthdate);
    }
  }

  private setPlace(place: any) {
    this.currentPlace = place;
    this.addMarker();
  }

  private addMarker() {
    if (this.currentPlace) {
      const marker = {
        lat: this.currentPlace.geometry.location.lat(),
        lng: this.currentPlace.geometry.location.lng(),
      };
      this.markers.push({ position: marker });
      this.places.push(this.currentPlace);
      this.center = marker;
    }
  }

  private geolocate() {
    navigator.geolocation.getCurrentPosition((position) => {
      this.center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
    });
  }

  private changeLang(lang: any) {
    this.setLang(lang.lang);
    this.langChanged = true;
  }

  private resetProperties() {
    this.langChanged = false;
  }

  private async submit() {
    if (!this.enableSubmit) {
      return;
    }

    const currentPlace = this.currentPlace;
    const button = this.$refs.button as any;
    button.executing(true);

    const obj: any =  {
      birthDate: fromSlashDate(this.birthdate),
      firstName: this.firstname,
      lastName: this.lastname,
    };

    if (currentPlace && currentPlace.place_id) {
      obj.googlePlaceId = currentPlace.place_id;
    }

    try {
      await this.updateUser(obj);
    } catch (err) {
      button.executing(false);
      NNoty.createNewNoty({
        period: 4000,
        message: String(this.$t('error.error-response-banner')),
        type: 'error',
      });
      return;
    }

    button.executing(false);
    this.resetProperties();
    NModalModule.setStatus(ModalStatus.Hidden);
  }
}












































































/**
 * @Prop showMobileBasket {boolean}: indicates whether the mobile basket
 * is visible or not.
 *
 * Events: EVPromoCode
 */
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { required, maxLength } from 'vuelidate/lib/validators';
import { Validator } from '@/utils/validators';
import { EVPromoCode } from '../../../models/events';
import Debug from 'debug';
import { AccountingEntity, IMembershipCustomer } from '@/models/store/booking';
import {  AppModule, NBookingModule, NNoty, ReferrerModule } from '@/utils/storemodules';
import { sendCartBookingInfo, getCartBookingInfo } from '@/utils/iframe';
import { cancelPSS } from '@/api/booking';
import { BookingSteps } from '@/store/modules/newBooking/constants';
import { IMembershipProductSlotStates } from '@/models/memberships';
import { Loading } from 'quasar';
import { disableQuasarStyling, enableQuasarStyling } from '@/utils/styles';
import { destroyCharts } from '@/utils/helpers';
import { smeetzTracker } from '@/utils/tracking';
import { EventType } from '@/models/enums';
const debug = new Debug('smeetz:promocode');

@Component({
  validations: {
    promoCode: {
      required,
      maxLength: maxLength(255),
      invalid: Validator.numbersLettersSpecialCharacters,
    },
  },
})
export default class BasketPromoCode extends Vue {
  @Prop({default: false}) public showMobileBasket!: boolean;
  private promoCode: string = '';
  private showPromo: boolean = false;
  private responseError: boolean = false;
  private loading: boolean = false;
  private basketVisible: boolean = false;

  private get hasError() {
    return this.$v.promoCode.$error || this.responseError;
  }

  private get isDisabled() {
    return this.promoCode.length === 0;
  }

  private get isCartWidget() {
    return AppModule.isCartWidget;
  }

  // @Watch('promoCode')
  // public onPromoCodeChanged(val: string) {
  //   (this.$v.promoCode.$model as any) = val.toUpperCase();
  // }

  private togglePromo() {
    this.showPromo = !this.showPromo;
    this.$emit(EVPromoCode.Popped, this.showPromo);
  }
  // Clears all the tickets form the basket
  private async clearBasket() {
    enableQuasarStyling();
    Loading.show();
    try {
      const { bookingId, bookingToken } = NBookingModule;
      await NBookingModule.fetchBooking({bookingToken, bookingId});
      const bookingRes = NBookingModule.bookingRes;

      if (!bookingRes) {
        throw new Error('No booking object');
      }

      const categories = bookingRes.bookingRecap.categories;
      if (!categories) {
        throw new Error('No categories are found in the booking');
      }

      const slots = bookingRes.slots;
      const pssTickets: number[] = [];
      if (!slots || !slots.length) {
        throw new Error('No pss were found');
      }

      const pssList = slots.map((p) => p.productSlotStateId);
      if (!pssList.length) {
        throw new Error('Category with no matching pss');
      }

      let releaseSeat: number = 0;
      for ( const slot of slots ) {
        if ( slot.seat ) {
          releaseSeat = 1;
        }
        break;
      }

      const payload = {
        bookingId,
        bookingToken,
        releaseSeats: releaseSeat as (1 | 0),
        tickets: pssList,
      };

      await cancelPSS(payload);
      await NBookingModule.fetchBooking({bookingToken, bookingId});

      for (const category of categories) {
        // object that has the data we send for smeetz tracking
        // this data will be used for dynamic pricing.
        const smtzTrackingData = {
          catId: category.categoryId,
          pId: category.productId,
          prId: Number(category.priceId),
          prVal: category.priceValue,
          qt: Number(category.quantity),
        };
        smeetzTracker(EventType.ticketRemoved, smtzTrackingData);
      }

      // TODO remove comment after BUD-8305 is tested
      // if ( ReferrerModule.isMembershipOnly ) {
      if ( NBookingModule.isCustomerBooking ) {

        if (NBookingModule.membership) {
          NBookingModule.setMembershipApplied(null);
          const membershipPSS: IMembershipProductSlotStates[] = [];
          NBookingModule.setMembershipPSS(membershipPSS);
        }

        const membershipClients: IMembershipCustomer[] = [];
        const {membershipCustomers} = NBookingModule;
        for (const customer of membershipCustomers) {
          membershipClients.push({...customer, tickets: [] });
        }
        NBookingModule.setMembCustomers(membershipClients);
      }

      if ( AppModule.isCartWidget ) {
        sendCartBookingInfo(getCartBookingInfo());
      }

      // destroy all visible charts
      if (NBookingModule.renderedChartRef !== null) {
        debug('Destroying visible chart after clearing basket');
        destroyCharts(true);
        NBookingModule.setRenderedChartRef(null);
      }

      if ( Loading.isActive ) {
        Loading.hide();
        disableQuasarStyling();
      }

      // Go back to order page after we removed all categories
      if ( !this.basketVisible ) {
        NBookingModule.setShowBasket(false);
        } else {
        NBookingModule.stepTo(BookingSteps.Order);
      }

      return;
    } catch (error) {
      if ( Loading.isActive ) {
        Loading.hide();
        disableQuasarStyling();
      }
      throw error;
    }
  }


  private toggleBasket() {
    // this.basketVisible = !this.basketVisible;
    // this.$emit(EVPromoCode.BasketVisible, this.basketVisible);
    this.$emit(EVPromoCode.BasketVisible, !this.showMobileBasket);
  }

  private async sendPromoCode() {
    if (this.promoCode.length === 0) {
      return;
    }

    this.loading = true;
    try {
      await NBookingModule.sendPromoCode(this.promoCode);
      this.togglePromo();

      const isDiscountAppliable = this.isDiscountNotAreadyUsed();
      const nnotyType = isDiscountAppliable ? 'success' : 'error';
      const nnotyMessage = isDiscountAppliable ? this.$t('new-booking-flow.common.code-applied') as string
      : this.$t('new-booking-flow.common.code-not-applied') as string;

      NNoty.createNewNoty({
        type: nnotyType,
        period: 5000,
        message: nnotyMessage,
      });
    } catch (err) {
      NNoty.createNewNoty({
        type: 'error',
        period: 5000,
        message: this.$t('booking-flow.expired-or-not-existing-code') as string,
      });
      this.responseError = true;
    } finally {
      this.loading = false;
    }
  }

  private isDiscountNotAreadyUsed(): boolean {
    // To make sure a promo code is not already used it needs to have
    // promo_code_discount > 0 and accounting.accountingValue > 0
    // Why we need to check accounting.accountingValue?
    // One scenario: user can enter a valid promocode, then directly enter an already used promocode.
    // He will get a valid promocode message
    // We should prevent that by always considering acountingValue of accounting array
    const accountings: AccountingEntity[] | undefined = NBookingModule.bookingRes?.bookingRecap.accounting;

    const promoCodeAccounting = accountings?.filter((acc) => {
      return acc.promo_code_code.toLowerCase() === this.promoCode.toLowerCase();
    });

    if (!promoCodeAccounting || promoCodeAccounting.length === 0) {
      // Our promo code isn't mentioned in the array --> Not Found
      // This probably will never be reached, but we have to check.
      // Why never reached? Because NBookingModule.sendPromoCode(this.promoCode); would have thrown
      // an error before even calling this method
      throw new Error(this.$t('booking-flow.expired-or-not-existing-code') as string);
    }

    // let b: boolean = promoCodeAccounting?.some((e) => e.accountingValue > 0);
    // A promo code is applied if:
    // 1- counts as discount
    // OR
    // 2- counts as giftcard
    // AND
    // 3- not already applied
    // Why using some() and not every()? Because in SICTIC case we had
    // accounting array of 8 elements for the same promo code in 1 trial
    // all with accountingValue = 0 except one.
    // return ((NBookingModule.promoDiscount > 0 || NBookingModule.recapInfo.discount > 0)
    //   && promoCodeAccounting.some((p) => p.accountingValue > 0)) ? true : false;
    //
    //
    // UPDATES: since Voucher and GiftCard have been shown as Payment from backend
    // The condition above stopped working. The below works (more info: BUD-8593)
    return ((NBookingModule.promoDiscount > 0 || NBookingModule.recapInfo.discount > 0)
      || promoCodeAccounting.some((p) => p.accountingValue > 0)) ? true : false;
  }
}

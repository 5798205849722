import { AcompteType, BookingStatus, CategoryStatus, CategoryType, MembershipSetting, PriceCategory, SeatType } from '../enums';
import { IGQLGroupReadData } from '../organizer';
import { IOrganizerColors } from '../styles';
import { IPackages } from './packages';
import { ILocation, IProduct } from './product';

export enum BookingSteps {
  DateTime, Category, Attendees, Checkout,
}

interface Iga4Item extends Gtag.Item {
  currency?: string | undefined;
}

export interface IBookingState {
  steps: BookingSteps[];
  step: BookingSteps;
  flow: number;
  instantBooking: boolean;
  product: any;
  date?: Date;
  slots: any[];
  slot: any;
  selectedSlots: ISlot[];
  availabilities: IAvailabilities;
  // selectedCategories: ICategory[];
  categories: ICategory[];
  loading: boolean;
  calendar: IProductCalendar;
  booking?: IPreBookingRes;
  guestBooking?: IGuestBooking;
  serverBooking?: IServerBooking;
  paymentMethod?: IPaymentMethod;
  dpSlot?: IDPSlot;
  avoidedDPSlots: IDPSlotWReason[];
  wishListDPSlots: IDPSlot[];
  dpSlotTTL: any;
  dpSlotRating: any;
}


// Represents availabilitties level1
export interface IAvailabilities {
  dates?: IAvailableDates[] | null;
}

export interface IAvailableDates {
  date: string;
  summarizedSlots?: ISlot[] | null;
}

export interface ISlotDates {
  startDateTime: string;
  endDateTime: string;
}

export interface ISlot {
  schedule: ISchedule;
  startDateTime: string;
  endDateTime: string;
  publicCount: number | string;
  categories?: ICategory[] | null;
  shortInfo?: null;
  bookable: boolean;
}

export interface ISchedule {
  id: number;
  info?: null;
}

export interface ICategory {
  startDateTime: string; // Not on api
  endDateTime: string; // Not on api
  publicKey: string;
  eventKey: string;
  selectOnePrice: boolean;
  categoryId: number;
  categoryName: string;
  longInfo: string;
  categoryType: number;
  type: number;
  standardPrice: number;
  standardPriceCcy: string;
  publicCount: number;
  price?: ITicketPrice[] | null;
  optional_sub_products?: Array<null> | null;
  count?: number; // Not on api
  select?: boolean; // Not on api
  // ticketSelect?: ITicketPrice[];
  seating_chart_parent_product?: null;
  code?: string | null;
  seatsCat?: string;
  seatingSubProducts?: ICategory[] | null;
  holdToken?: string;
  computed_prices?: IComputedPrice[] | null;
}

export interface IComputedPrice {
  computedPriceId: number;
  dynamicPriceId: number;
  timeSlotId: number;
  priceId: number;
  priceValue: number;
  endValidity: string;
}

export interface ITicketPrice {
  priceCcy: string;
  priceBasisName: string;
  priceId: number;
  priceType: number;
  priceName: string;
  priceValue: string;
  priceDescription: string;
  priceBasis: number;
  priceTaxes?: Array<null> | null;
  minBooking: number;
  maxBooking: number;
  count: number; // This doesn't exist on api
  key: string; // used to force rerendering
  seats?: string[]; // Not on api (holds seats label)
  computedPrice?: IComputedPrice; // Not on api, added in the app
}

export interface IAddTicketPrice {
  ticket: ITicketPrice | { priceId: number };
  categoryId: number;
  dates: ISlotDates;
  seat?: string;
}

export interface IGuestCheckoutUser {
  firstName: string;
  lastName: string;
  email: string;
  confirmEmail: string;
  address: string;
  zipCode: string;
  city: string;
  country: string;
  termsOptin: string;
  emailMarketingSmeetz: string;
  emailMarketingOrganiser?: string;
}

export interface IRecapPrice {
  id: number;
  name: string;
  count: number;
  total: number;
  key: string; // used to force re-rendering
  seats?: string[]; // used for seats categories
  price?: number; // unitPrice
  computedPrice?: IComputedPrice;
}

export interface IRecapCategory {
  name: string;
  ticketPrices: IRecapPrice[];
  id: number;
  holdToken?: string; // hold token of seating chart
  startDateTime: string;
  endDateTime: string;
  slotId: number;
}

export interface IRecap {
  name: string;
  start?: Date;
  end?: Date;
  location: ILocation;
  categories: IRecapCategory[];
  subTotal: number;
  discount: number;
  paymentFees: number;
  total: number;
  currency: string;
  slots: IRecapSlot[]; // Not from api
}

export interface IRecapSlot {
  startDateTime: string;
  endDateTime: string;
  categories: IRecapCategory[];
  key: string;
  schedule: ISchedule;
}

export interface ITicket {
  publicKey: string;
  eventKey?: Array<string | null> | null;
  isRecurringSeat: boolean;
  categoryId: number;
  categoryName: string;
  longInfo: string;
  description: string;
  categoryType: number;
  type: number;
  membership: IMembership | null;
  maxBooking: number;
  minQuantityPerBooking: number;
  price: IPriceEntity[];
  code?: null;
  visible?: boolean;
  categoryInfo: ICategoryInfo;
  seatingMainSubProductId?: number;
  seatingSubProducts?: ISeatingSubProductsEntity[] | null;
  tag: number;
  tagCustom: string;
  gradientType?: number;
  ticketGroups: Array<{
    id: number;
  }>;
  timeSlotDisplayOption: ITimeslotDisplayOption | null;
  hideDateTime: number;
  sortOrder: number;

  // ticket access protection
  requiredBeforeBooking: TicketAccessType;
  requiredBeforeBookingLink: null | string;

  // gradient settings
  displayOnCalendar: boolean;
  lowNumberGradient: number;
  lowColorGradient: string;
  mediumNumberGradient: number;
  mediumColorGradient: string;
  highNumberGradient: number;
  highColorGradient: string;


  // The following is not from the api

  // These are the selected slots
  slots: { [s: string]: ITimeslotsEntity };

  // Not from api. Added on frontend
  productId: number;
  chargeFlowType?: number;

  // Add-ons in a separate step
  isAddonInSeparateStep?: boolean;
  maxAddonQuantity?: number;

  timeslots: ITimeslotsEntity[];

  // Teams Settings
  teamSettings: ITeamSetting[];

  // Not from api. Added as a flag to mark tickets with dates not fetched yet
  isFetching: boolean;
}

export interface ITimeslotDisplayOption {
  availability_show_enabled: boolean;
  availability_display_as: null;
  availability_display_position: null;
  availability_high_badge_color_gradient: null;
  availability_high_text_color_gradient: null;
  availability_high_number_gradient: null;
  availability_low_badge_color_gradient: null;
  availability_low_number_gradient: null;
  availability_low_text_color_gradient: null;
  availability_medium_badge_color_gradient: null;
  availability_medium_text_color_gradient: null;
  availability_medium_number_gradient: null;
  price_show_enabled: boolean;
  price_high_color_gradient: null;
  price_high_number_gradient: null;
  price_low_color_gradient: null;
  price_low_number_gradient: null;
  price_medium_color_gradient: null;
  price_medium_number_gradient: null;
  fomo_strategy_show_enabled: boolean;
  hurry_up_activate_at: number | null;
  hurry_up_strategy_type: number | null;
  hurry_up_text_color: string | null;
  hurry_up_badge_color: string | null;
  sold_out_strategy_type: number | null;
  sold_out_text_color: string | null;
  sold_out_badge_color: string | null;
  show_end_time: boolean;
  hide_new_time_slot_design: boolean;
}

export interface IDatePrice {
  date: Date;
  price: number;
  priceColor: string;
}


export interface IMembership {
  id: number;
  expiryType: number;
  type: number;
  status: number;
  expiryDate?: string | null;
  startDate?: string | null;
  renewDate?: string | null;
  autoRenew: boolean;
  periodLength: number;
  numberMandatoryPeriod: number;
  guests: number;
  name?: string | null;
  timeZone: string;
  membershipRenewalSetting: IMembershipRenewalSetting | null;
}

export interface IMembershipRenewalSetting {
  activeOnDunning: boolean;
  archived: boolean;
  dunPeriod: number;
  durationLength: number;
  durationType: number;
  id: number;
  paymentReminder: string;
  renewalReminder: string;
}

export interface IPriceEntity {
  availability?: number[] | null;
  priceCcy: string;
  priceBasisName: string;
  priceId: number;
  priceType: number;
  priceName: string;
  priceValue: string;
  priceDescription: string;
  priceBasis: number;
  priceTaxes?: Array<null> | null;
  minBooking: number;
  maxBooking: number;
  blockedWithCode: boolean;
  sortPrice: number;
}

export interface ICategoryInfo {
  timeslots: ITimeslotsEntity[];
  dates: IDatesEntity[];
  onlineStatus: 'available_for_sales' | 'not_started' | 'not_for_sales' | 'sold_out' | 'sales_ended';
  // Added by frontend to overcome the issue of calendar tickets
  // showing up as non calendar after we fetch dates L3-763
  isCalendar?: boolean;
}

export interface ITimeslotsEntity {
  id: number;
  startDateTime: string;
  endDateTime: string;
  publicCount: number;
  booked: number;
  export_prices?: IExportPricesEntity[] | null;
  gradient?: number;
  bookable?: number;
  eventKey: string;

  // Not from api
  // the following are prices with their count
  prices?: { [s: string]: IExportPricesEntity };
}

export interface IMonthGradient {
  [day: string]: number;
}
export interface IMonthsGradients {
  [month: string]: IMonthGradient;
}

export interface IExportPricesEntity {
  computedPriceId?: null;
  dynamicPriceId?: null;
  priceId: number;
  parentId?: number;
  priceValue: number;
  priceName: string;
  priceDescription: string;
  priceBasis: number;
  minBooking: number;
  maxBooking: number;
  quantityLeft: number;
  blockedWithCode: boolean;
  priceStatus?: number;
  priceCategory: PriceCategory;
  sortPrice?: number;
  priceType?: number;

  // Added for required membership prices
  membershipSetting?: number;

  // Acompte (deposit) information
  acompteId?: number;
  acomptePercentage?: number; // percentage
  acomptePriceValue?: number; // displayed deposit value
  acompteType?: AcompteType;
  acompteValue?: number;

  // Not from api
  // Number of ticketPrice selected
  count: number;

  // only for dynamic seating pricing
  validComputedPriceId?: number;
  validDynamicPriceId?: number;

  // Added for membership's addons
  productName?: string;

  categoryId?: number;
}

export interface ITicketSlotsFetch {
  from: string;
  to: string;
  productId: number;
  categoryId: number;
}

export interface IDatesEntity {
  startDate: string;
  publicCount: string;
  // Why the below properties are optional?
  // Because we have just added them,
  // and this interface is already used in contexts
  // where we don't need these properties
  gradientType?: string;
  capacity?: string;
  booked?: string;
  maxPrice?: string;
  minPrice?: string;
}
export interface ISeatingSubProductsEntity {
  publicKey: string;
  eventKey?: Array<null> | null;
  categoryId: number;
  categoryName: string;

  // the name of the category seat on the seating plan
  seatsCat?: string;
  longInfo: string;
  description: string;
  categoryType: number;
  type: number;
  maxBooking: number;
  price: IExportPricesEntity[];
  code?: null;
  categoryInfo: ICategoryInfo;
  seatingSubProducts?: Array<null> | null;
  priceDescription: string;
  ticketGroups: Array<{
    id: number;
  }>;
}

export interface ISelectSlotPayload {
  slot: ITimeslotsEntity;
  ticketId: number;
}

export interface ITicketCount {
  price: IExportPricesEntity;
  slotId: number;
  ticketId: number;
  count: number;
}

export interface IPriceCount {
  price: IExportPricesEntity;
  slot: ITimeslotsEntity;
  count: number;
  ticket: ITicket;
  seat?: string; // Seat id on seat io
  holdToken?: string;
  seatCategoryId?: number;
  mainTicket?: string; // package main price info
  packageCategoryId?: number;
  packagePriceId?: number;
  codes?: string[];
}

export interface IPriceRetail {
  retailPriceId: number;
  count: number;
  retailOptionId: number;
  categoryId: number;
  packagePriceId?: number;
  mainTicket?: string;
  priceId?: number;
}

export interface IPriceCountLite {
  // price: IExportPricesEntity;
  productId?: number;
  timeSlotId: number;
  ticketId: number;
  priceId: number;
  priceValue?: number;
  count: number;
  computedPriceId?: number;
  validComputedPriceId?: number;
  holdingTime?: number;
  seat?: string; // Seat id on seat io
  holdToken?: string;
  seatCategoryId?: number;
  mainTicket?: string; // package main price info
  packageCategoryId?: number;
  packagePriceId?: number;
}

export interface ICartPrice {
  productIds: number[];
  categoryIds: number[];
  priceId?: number;
  count?: number;
  retailOptionId?: number;
}

export interface IPriceCountQueue {
  priceCount: IPriceCount;
  cb: (e: any | null, priceCount?: IPriceCount) => void;
}

export interface ITicketBasket {
  id: number;
  name: string;
  slots: ITimeslotsEntity[];
}

export interface ISlotOrder {
  categoryId: number;
  priceId: number;
  quantity: number;
  timeSlot: number;

  // only for dynamic pricing
  priceValue?: number;
  computedPriceId?: number;

  // for packages (main price info)
  mainTicket?: string;
  packagePriceId?: number;
  groupId?: number;

  // dynamic shipping
  rateToken?: string;
}

export interface ISlotOrderDonation {
  categoryId: number;
  quantity: number;
}

export interface ISlotOrderRetail {
  categoryId?: number;
  priceId: number;
  quantity: number;
  retailOptionId: number;
  groupId?: number;
  packagePriceId?: number;
  mainTicket?: string;
}

export interface IRecapInfo {
  total: number;
  totalAcompte: number;
  fees: number;
  subTotal: number;
  discount: number;
  deposit: number;
  isFree: boolean;
  bookingEmail?: string;
  shipping: number;
  taxes: ITax[];
  payments: number;
}

export interface ITax {
  id: number;
  isIncluded: boolean;
  name: string;
  rate: number;
  value: number;
}

export interface IPatchBookingOrderPayload {
  bookingId: number;
  bookingToken: string;
  slots: ISlotOrder[];
  bookingReferrer: string;
  // booking channel related
  utmChannel: 'b2c' | 'b2b' | 'iFrame';
  // analytics related
  utmSource?: string;
  utmMedium?: string;
  utmCampaign?: string;
  // dynamic shipping
  rateId?: number;
  countryCode?: string;
}

export interface IPatchBookingOrderDonationPayload {
  bookingId: number;
  bookingToken: string;
  slots: ISlotOrderDonation[];
  bookingReferrer: string;
  // booking channel related
  utmChannel: 'b2c' | 'b2b' | 'iFrame';
  // analytics related
  utmSource?: string;
  utmMedium?: string;
  utmCampaign?: string;
  // dynamic shipping
  rateId?: number;
  countryCode?: string;
}

export interface IPatchBookingOrderRetailPayload {
  bookingId: number;
  bookingToken: string;
  slots: ISlotOrderRetail[];
  bookingReferrer: string;
  utmChannel: 'b2c' | 'b2b' | 'iFrame';
  utmSource?: string;
  utmMedium?: string;
  utmCampaign?: string;
  // dynamic shipping
  rateId?: number;
  countryCode?: string;
}

/*************************
 * PRODUCT shortname defs
 *************************/
// export interface IProduct {
//   slotsProposals?: ISlotsProposalsEntity[] | null;
//   overview: IOverview;
//   owners?: IOwnersEntity[] | null;
//   booking: IBooking;
//   branding: IOrganizerColors;
//   booking_flow?: number;
//   booking_status?: number; // 1 === Bookable
//   id: number;
//   short_url: string;
//   description: string;
//   name: string;
//   status: number;
//   privacy: number;
//   illustration?: null;
//   contactPhone: string;
//   contactWebsite: string | null;
//   contactEmail: string;
//   userPaymentFee: boolean;
//   tags?: ITagsEntity[] | null;
//   pictures?: IPicturesEntity[] | null;
//   location: ILocation;
//   policies?: IPoliciesEntity[] | null;
//   isInstantBooking: boolean;
//   time_zone: string;
//   isIdivTax: boolean;
//   vatId?: null;
//   vatRate: number;
//   holdingTime: number;
//   organiser?: IOrganiser | null;
//   currency: string;
//   fbPixelId?: string | null;
//   googleTrackingId?: string | null;
//   teaser: string;
//   languages: ILanguage[];
//   productHighlights: IProductHighlights;
//   facebook_website: string | null;
//   instagram_website: string | null;
//   linkedin_website: string | null;
//   youtube_website: string | null;
// }

// export interface IProductHighlights {
//   seasonName: IStringObject;
//   dayOfWeekNames: IStringObject;
//   timeOfDayNames: IStringObject;
//   weatherNames: IStringObject;
//   targetCustomerNames: IStringObject;
//   transportTypeNames: IStringObject;
// }

// export interface IStringObject {
//   [index: number]: string;
// }

// export interface ILanguage {
//   code: string;
//   name: string;
//   native_name: string;
// }
// export interface ISlotsProposalsEntity {
//   schedule: ISchedule;
//   startDateTime: string;
//   endDateTime: string;
// }
// export interface IOverview {
//   nextDateTime: INextDateTime;
//   lastDateTime: INextDateTime;
//   rangePrice: IRangePrice;
// }
// export interface INextDateTime {
//   startDateTime: string;
//   endDateTime: string;
// }
// export interface IRangePrice {
//   fromPrice: number;
//   fromPriceCurrency: string;
// }
// export interface IOwnersEntity {
//   id: number;
//   following_number: number;
//   follower_number: number;
//   firstName: string;
//   lastName: string;
//   birthDate: string;
//   gender: string;
// }
// export interface IBooking {
//   status: number;
//   flow: number;
// }
// export interface ITagsEntity {
//   id: number;
//   name: string;
// }
// export interface IPicturesEntity {
//   id: number;
//   type: number;
//   typeName: string;
//   isPrimary: boolean;
//   dimensions: IDimensions;
//   groupHash: string;
//   url: string;
//   coverTypeName: string;
// }
// export interface IDimensions {
//   h: number;
//   w: number;
// }
// export interface ILocation {
//   name: string;
//   address: string;
//   zip: string;
//   city: string;
//   country: string;
//   lat: string;
//   lng: string;
// }
// export interface IPoliciesEntity {
//   title: string;
//   description: string;
//   type?: number | null;
// }
// export interface IOrganiser {
//   name: string;
//   conversionId: string | null;
//   conversionLabel: string | null;
//   slugName: string;
//   bookingPolicy: string;
// }

/**
 * END OF PRODUCT DEFS
 */

export interface IAvailabilitiesRange {
  from: string;
  to?: string;
  level: number;
  productId?: number;
}

export interface IProductCalendar {
  dates?: ICalendarDate[] | null;
}

export interface ICalendarDate {
  date: string;
  publicCount: string;
}

export interface IGuestBooking {
  NumberProductSlotStates: number;
  isInstantBooking: boolean;
  bookingId: number;
  product: IProduct;
  bookingRef: string;
  bookingState: number;
  email: string;
  first_name: string;
  last_name: string;
  city: string;
  country: string;
  insertDateTime: string;
  bookingMessage: string;
  bookingToken: string;
  bookingRecap?: null;
  transactionInfo?: null;
  overDateTime: string;
}

// export interface Product {
//   name: string;
//   location: ILocation;
//   organiser?: IOrganiser | null;
// }

/**
 * Typedefs related to prebooking response
 */
export interface IPreBookingRes { // Response
  NumberProductSlotStates: number;
  isInstantBooking: boolean;
  bookingId: number;
  product: IProduct;
  bookingRef: string;
  bookingState: BookingStatus;
  productSlotStates: IProductSlotStatesEntity[];
  email: string;
  first_name: string;
  last_name: string;
  city?: null;
  country?: null;
  insertDateTime: string;
  bookingMessage: string;
  bookingToken: string;
  bookingEmail: string;
  bookingRecap: IServerBookingRecap;
  transactionInfo?: null;
  overDateTime: string;
  availablePaymentMethods: string[];
  emailMarketingSmeetz: string;
  emailMarketingOrganiser?: string;
  termsOptin: string;
  bookingReferrer?: string;
  availablePayments: string[];
  slots: IProductSlotStatesEntity[];
  packages: IPackages;
}

export interface IPreBookingPaymentStatusRes { // Response
  bookingId: number;
  bookingToken: string;
  reason: string;
  status: number;
}
// This is the part that is returned from the original
// interfaces which contain more data
// export interface Product {
//   name: string;
//   location: Location;
//   organiser: Organiser;
// }
// export interface Location {
//   name: string;
//   address: string;
//   zip: string;
//   city: string;
//   country: string;
// }
// export interface Organiser {
//   name: string;
// }

export interface IServerBookingRecap {
  categories?: IServerRecapCategory[];
  accounting: (AccountingEntity)[];
  currency: string;
  subtotal: number;
  paymentFees: number;
  discount: number;
  deposit: number;
  shipping: number;
  total: number;
  totalAcompte: number;
  payOnSite: number;
  voucher: number;
  payments: number;
  toBeCharged: number;
  refund: number;
  refund_accounting: number;
  promo_code_discount: number;
  balance: number;
  bookingFlowTotal: number;
  taxes: ITax[];
}

// modified type to add ticket to the object
export interface IModiServerRecapCategory extends IServerRecapCategory {
  ticket: ITicket;
}

export interface IServerRecapCategory {
  priceName: string;
  priceValue: number;
  categoryName: string;
  quantity: string;
  startDateTime: string;
  endDateTime: string;
  categoryId: number;
  priceId: number;
  timeSlotId: number;
  seat: string;
  bookingStatus: CategoryStatus;
  productId: number;
  productName: string;
  mainTicket?: string; // available only on packages and equal to `timeslotId:priceId`
  hideDateTime: HideTicketDateTime;
  chargeFlowType: number;

  // Acompte specif
  totalAcompte: number;
  toBeCharged: number;
  acompteId: number | null;

  priceValueDefault: number; // added by frontend when a membership discount is applied
  retailOptionId?: number;
  retailOptionName?: string;

  seatingMainSubProductId?: number;

  waiverTemplate?: string;
  isDeliverable: number;
  isShipping: boolean;
  weight?: number;
  categoryType: CategoryType;
  membershipSetting: MembershipSetting;
  priceCategory: number;
}

export interface AccountingEntity {
  promo_code_code: string;
  accountingSection: string;
  accountingValue: number;
  id: number;
  accountingEntries: (AccountingEntriesEntity)[];
  paymentMethod: string;
  note?: null;
  payment_method_info: string;
  state: number;
  payment_receivable_date: string;
  currency: string;
  message?: null;
  is_deposit: number;
  transactionType: number;
}

export interface AccountingEntriesEntity {
  ticketBarCode: string;
  value: number;
}

export interface IFrontRecapTicket {
  categoryName: string;
  startDateTime: string;
  endDateTime: string;
  categoryId: number;
  timeSlotId: number;
  prices: IServerRecapCategory[];
  seat?: string;
  hideDateTime: HideTicketDateTime;
  type: undefined;
  retailOptionId?: number;
  retailOptionName?: string;
  categoryType: number;
  isRetail?: boolean;
}

export interface ICustomerTicketRecap extends Partial<IFrontRecapTicket> {
  firstname?: string;
  lastname?: string;
  fullname?: string;
  pssId?: number;
  priceId?: number;
  productId?: number;
  seatType?: SeatType;
  displayRemoveButton: boolean;
  productName?: string;
  ticket?: ITicket | undefined;
}

export interface IProductSlotStatesEntity {
  id: number;
  priceName: string;
  categoryName: string;
  productSlotStateId: number;
  mainTicketId?: number;
  timeslotId: number;
  // Backend needs to fix this we get timeslotId when doing get booking
  // and we get timeSlotId when we do post/patch booking
  timeSlotId: number;
  priceId: number;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  seat?: string;
  bookingStatus: CategoryStatus;
}

export interface IPreBookingReq { // request
  productId?: number;
  bookingLanguage: number;
  bookingReferrer: string;
  // main_ticket?: string; // package main price info
  ProductQt: IPreBookProductQt[];
  // booking channel related
  utmChannel: 'b2c' | 'b2b' | 'iFrame';
  // analytics related
  utmSource?: string;
  utmMedium?: string;
  utmCampaign?: string;
}

export interface IPreBookingRetailReq { // request
  productId: number;
  bookingLanguage: number;
  bookingReferrer: string;
  // main_ticket?: string; // package main price info
  ProductQt: IPreBookProductQtRetail[];
  // booking channel related
  utmChannel: 'b2c' | 'b2b' | 'iFrame';
  // analytics related
  utmSource?: string;
  utmMedium?: string;
  utmCampaign?: string;
}

export interface IPreBookProductQt {
  startDateTime?: string;
  endDateTime?: string;
  categoryId: number;
  quantity: number;
  priceId: number;
  holdToken?: string;
  type?: number;
  seat?: string;
  priceValue?: number;
  timeSlot?: number;
  dpSlot?: number;
  computedPriceId?: number; // required for dynamic pricing booking
  mainTicket?: string; // maintimeslot:mainPriceId for package
  packagePriceId?: number;
  codes?: string[];
}

export interface IPreBookProductQtRetail {
  categoryId?: number;
  quantity: number;
  priceId?: number;
  retailOptionId?: number;
  groupId?: number;
}

// The following is received after a success or failure booking redirect
export interface IServerBooking {
  product: IProduct;
  startDateTime: string;
  endDateTime: string;
  bookingState: number;
  bookingStateName: string;
  bookingRef: string;
  pssCount: number;
  slots?: IServerSlot[] | null;
  bookingRecap: IServerBookingRecap;
  bookingEmail: string;
  isInstantBooking: boolean;
  bookingReferrer: string;
  transactionInfo?: IBookingPayments;
}

export interface IServerBookingPaymentStatus {
  status: number;
  reason: string;
}

export interface IServerSlot {
  productSlotStateId: number;
  fullName?: null;
  priceType: number;
  categoryName: string;
  priceValue: number;
  priceCcy: string;
  priceName: string;
  swisspass: {};
  qrCodeData: QrCodeData;
}
// export interface Swisspass {
// }
export interface QrCodeData {
  pss: number;
  tok: string;
}

/**********************
 * Payments type defs
 **********************/
export interface IBookingPayments {
  transactionId: number;
  url: string;
  userPaymentFees: boolean;
  paymentMethods?: IPaymentMethod[] | null;
}
export interface IPaymentMethod {
  dataCollectionType: string;
  description: string;
  id: number;
  imageResourcePath?: {};
  name: string;
  oneClickPaymentMode: string;
  paymentMethod: number;
  resolvedDescription: IResolvedDescriptionOrResolvedTitle;
  resolvedImageUrl: string;
  resolvedTitle: IResolvedTitle;
  state: string;
  sortOrder: number;
  title: string;
  paymentFees: number;
}

export interface IResolvedDescriptionOrResolvedTitle {
  'de-DE': string;
  'fr-FR': string;
  'en-US': string;
}

export interface IResolvedTitle {
  'de-DE'?: string | null;
  'fr-FR'?: string | null;
  'en-US': string;
}

export interface IConfirmBookingData {
  bookingToken: string;
  bookingId: number;
  paymentMethod: string;
  transactionId: number;
}

export interface IServerBookingData {
  bookingToken: string;
  bookingId: number;
  includePackages?: boolean;
}

export interface IPromoCodeData extends IServerBookingData {
  code: string;
}

export interface IStripeBookingData {
  bookingId: number;
  bookingToken: string;
  paymentData: {
    paymentProvider: number;
    paymentToken: string;
    paymentDescription: string;
  };
}

export interface IFieldsResponse {
  booking_fields?: IBookingField[] | null;
  ticket_fields?: ITicketField[] | null;
}

export interface IBookingField {
  fieldId: number;
  questionType: number;
  informationType: number;
  question: string;
  list?: string[] | null;
  answer?: string | null;
  position: number;
  productId: string;
  validation: boolean;
  answerExample: string;
}

export interface ITicketField {
  fieldId: number;
  questionType: number;
  informationType: number;
  question: string;
  list?: null;
  position: number;
  productId: string;
  answerExample: string;
  validation: boolean;
  answer?: null;
  categoryId?: string | null;
  pssId: number;
  priceId: string;
  timeSlotId?: string;
  priceName: string;
  seat?: string;
  // formPosition doesn't come from the backend
  // we set it on the frontend to indentify the position
  // of the ticket field on the form
  formPosition?: number;
}


export interface IPostBookingField {
  fieldId: number;
  answer?: string | string[] | File;
  pss?: number;
}

export interface IPostBookingFile {
  fieldId: number;
  answer: File;
  pss?: number;
}

// export interface IPostTicketField extends IPostBookingField {
//   pss: number;
// }

export interface ITrackingData {
  id?: string | number;
  name: string;
  brand: string;
  currency: string;
  price?: number;
  position?: number;
  list?: string;
  fbPixelId?: string | null;
  googleTrackingId?: string | null;
  gtmId?: string | null;
  googleAdsConversionId?: string;
  googleAdsConversionLabel?: string;
  category?: string;
  quantity?: number;
  totalPrice?: number;
  priceName?: string;
}

// Smeetz tracking data
export interface ISmtzTrackingData {
  catId: number; // Category id.
  pId?: number; // Product id.
  date?: number; // The date that the user has selected from the calendar.
  prId?: number; // Price id.
  prVal?: number; // Pcide value.
  qt?: number; // The quatity of tickets added or removed from basket.
  tsId?: number; // Time slot id.
  tsTime?: number; // The time that user gets when they select a time slot.
  catName?: string; // Category name
}

// GTM dataLayer object
type GTMEvents = 'smtz_add_to_cart'
  | 'smtz_begin_checkout'
  | 'smtz_checkout_progress'
  | 'smtz_payment_info'
  | 'smtz_purchase'
  | 'smtz_user_identity'
  | 'smtz_view_cart'
  | 'smtz_view_item_list'
  | 'smtz_remove_from_cart'
  | 'smtz_dynamicPricing_enabled';

export interface ISmtzGTM {
  event: GTMEvents;
  smeetzLabel: string;
  smeetzCategory: string;
  smeetzAction: string;
  smeetzData: Iga4Item[];
  value?: number;
  transactionId?: string;
}

// This is transactional info needed for analytics on purchase
export interface ITransactionInfo {
  transactionId: string;
  affiliation: string;
  revenue: number;
  tax: number;
  coupon?: string;
}

export interface IDPSlot {
  _index: string;
  _type: string;
  _id: string;
  _score: number;
  _source: IDPSlotSource;
}

export interface IDPSlotWReason {
  _index: string;
  _type: string;
  _id: string;
  _score: number;
  _source: IDPSlotWReasonSource;
}

export interface IDPSlotSource {
  price_value: number;
  end_validity: string;
  max_number_ticket: number;
  dynamic_pricing_setup_id: number;
  time_slot_id: number;
  type: number;
  is_displayed: boolean;
  is_available: boolean;
  product_id: number;
  product_name: string;
  category_id: number;
  price_id: number;
  start_date_time: string;
  schedule_id: number;
}

export interface IDPSlotWReasonSource {
  price_value: number;
  end_validity: string;
  max_number_ticket: number;
  dynamic_pricing_setup_id: number;
  time_slot_id: number;
  type: number;
  is_displayed: boolean;
  is_available: boolean;
  product_id: number;
  product_name: string;
  category_id: number;
  price_id: number;
  start_date_time: string;
  schedule_id: number;
  reason?: string;
}

export interface IBookingPaymentChoose {
  name: string;
}

export interface IPss {
  tickets?: IPssTicket[];
  productName: string;
  productShortUrl: string;
}
export interface IPssTicket {
  ticket_name: string;
  booking_id: number;
  ticket_id: number;
  goer_token: string;
  email: string;
  barcode: string;
  first_name: string;
  last_name: string;
  qrcode_url: string;
}
export interface IAttendee {
  pssId: number;
  fullName: string | undefined;
  email: string | undefined;
  phone: string | undefined;
}

export interface IMembershipCustomer {
  firstName: string;
  lastName: string;
  tickets: ICustomerTickets[];
}

export interface ICustomerTickets {
  categoryId: number;
  seat: string;
  timeSlotId: number;
  priceId: number;
  priceName: string;
  pssId?: number;
  priceCategory: PriceCategory;
}

export interface ISeatPriceType {
  priceId: number;
  seatType: SeatType;
}

/**
 *
 * GraphQL data types
 *
 */

export interface IGQLBookingSummary {
  bookingId: number;
  data: IGQLProductSlotBooking;
}

export interface IGQLProductSlotBooking {
  ProductSlotBookingRead: IGQLProductSlotBookingRead;
}

export interface IGQLProductSlotBookingRead {
  Email: string | null;
  FirstName: string | null;
  Id: number;
  InsertDateTime: string;
  LastName: string | null;
  Phone: string | null;
  Reference: string | null;
  State: number;
  Currency: string | null;
  GoerToken: string | null;
  ProductSlotStateCount: number;
  ProductSlotStateList: IGQLProductSlotState[];
}

export interface IGQLProductSlotState {
  StartDateTime: string;
  EndDateTime: string;
  PriceName: string;
  PriceValue: number;
  Seat: string | null;
  PaymentStatus: number;
  BookingStatus: number;
  Id: number;
  ProductSlotCategoryRead: IGQLProductSlotCategory;
}

export interface IGQLProductSlotCategory {
  CategoryName: string;
  Id: number;
  Type: number;
  ProductId: number;
  ProductRead: IGQLProduct;
  IsUserCancellable: number;
  CancellationPolicyList: ICancellationPolicy[];
}
export interface ICancellationPolicy {
  PolicyName: string;
  CancelEndDurationDays: number;
  RefundPercentage: number;
}

export interface IGQLProduct {
  Name: string;
  Id: number;
  GroupRead: IGQLGroupReadData;
}

export interface Iwaivers {
  waiverId: number;
  bookingId: number;
}

export interface ITeamSetting {
  teamId: number;
  maxParticipants: number;
  countedPsp: string[];
}

export interface IteamInfo {
  categoryId: number;
  teamCount: number;
  maxParticipants: number;
}


/**
 * Enums
 */
/*
const TYPE_TEXT = 1;
const TYPE_DROPDOWN = 2;
const TYPE_LIST = 3;
const TYPE_FILE = 4;
const TYPE_RADIO = 5;
const TYPE_DATE = 6;
const TYPE_LONG_TEXT = 7;
const TYPE_CHECKBOX = 8;
*/
export enum QuestionType {
  text = 1,
  dropdown,
  list,
  file,
  radio,
  date,
  long_text,
}

// INFORMATION TYPE
/*
const INFO_TYPE_OTHER = 1;
const INFO_TYPE_PHONE = 2;
const INFO_TYPE_COMPANY = 3;
const INFO_TYPE_TAX = 4;
const INFO_TYPE_FIRSTNAME = 5;
const INFO_TYPE_LASTNAME = 6;
const INFO_TYPE_EMAIL = 7;
const INFO_TYPE_ADDRESS = 8;
const INFO_TYPE_CITY = 9;
const INFO_TYPE_ZIP = 10;
const INFO_TYPE_COUNTRY = 11;
const INFO_TYPE_LANGUAGE = 12;
const INFO_TYPE_PASSPORT = 13;
const INFO_TYPE_MARKETING = 14;
const INFO_TYPE_PICTURE = 15;
const INFO_TYPE_GOOGLE_PLACE_ID = 16;
*/
export enum InformationType {
  Other = 1,
  Phone,
  Company,
  Tax,
  FirstName,
  LastName,
  Email,
  Address,
  City,
  Zip,
  Country,
  Language,
  Passport,
  Marketing,
  Picture,
  GooglePlaceId,
  BirthDate,
  MembershipInfo = 20,
  LanguageDropdown = 26,
}
export enum BookingFlowType {
  Type0, // one time event
  Type1, // couple of days event
  Type2, // recurring
  GroupByDay,
}

export enum TicketType {
  Gift = 2,
  Seat = 3,
  Merchandising = 6,
  Membership = 14,
  MembershipVoucher = 16,
  SeatedMembership = 17,
}

export enum TicketAccessType {
  NothingRequired = 0,
  CodeRequired = 1,
}

export enum NonWalleePayment {
  Invoice = 'INVOICE',
  BankTransfer = 'BANK_TRANSFER',
  Free = 'FREE',
  Voucher = 'EXTERNAL_VOUCHER',
  Cash = 'CASH',
}

export enum PriceStatus {
  Available = 1,
  NotAvailable = 2,
  SoldOut = 3,
}

export enum HideTicketDateTime {
  NoHiding = 0,
  HideStartDate,
  HideEndDate,
  HideBoth,
}

export enum ChargeFlow {
  Direct = 1,
  Authorized = 2,
  Tokenized = 3,
}

export type ValidAccompCount = 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16;

/**
 * Actions definitions
 */
export type FetchProduct = (productShortName: string) => Promise<IProduct>;
export type PostPromoCode = (payload: IPromoCodeData) => Promise<IServerBooking>;
export type ToggleSlot = (payload: ISlot) => void;














import { Vue, Component } from 'vue-property-decorator';
import { Mixins } from 'vue-mixin-decorator';
import DonationMixin from '@/mixins/Donation';

@Component
export default class BookingDonation extends Mixins<DonationMixin>(DonationMixin) {

}

import { namespace } from 'vuex-class';
import { getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import referrerModule from '@/store/modules/referrer';
import productModule from '@/store/modules/product/index';
import productsModule from '@/store/modules/products/index';
import nbookingModule from '@/store/modules/newBooking/index';
import gbookingModule from '@/store/modules/gBooking/Index';
import rbookingModule from '@/store/modules/refundBooking';
import nnoty from '@/store/modules/newError/index';
import Modal from '@/store/modules/modal/index';
import User from '@/store/modules/user/index';
import app from '@/store/modules/app/index';
import Region from '@/store/modules/region';
import Organizer from '@/store/modules/organizer';

export const ModalModule = namespace('modal');

export const AuthModule = namespace('auth');

export const BookingModule = namespace('booking');

export const ErrorModule = namespace('error');

export const AppModule = getModule(app, store);

export const ReferrerModule = getModule(referrerModule, store);

export const ProductModule = getModule(productModule, store);

export const ProductsModule = getModule(productsModule, store);

export const NBookingModule = getModule(nbookingModule, store);

export const GBookingModule = getModule(gbookingModule, store);

export const RBookingModule = getModule(rbookingModule, store);

export const NModalModule = getModule(Modal, store);

export const NNoty = getModule(nnoty, store);

export const UserModule = getModule(User, store);

export const RegionModule = getModule(Region, store);

export const OrganizerModule = getModule(Organizer, store);


















































































































































/**
 * Renders individual slots
 *
 * @Prop timeSlot {ITimeslotsEntity}: slot to be rendered
 * @Prop ticket {ITicket}: ticket of the current slot
 */
import { Vue, Component, Prop, Watch} from 'vue-property-decorator';
import { fetchTicketSlots } from '@/api/booking';
import { ITimeslotsEntity } from '../../../models/store/booking';
import Price from './SlotPrice.vue';
import Package from './PricePackage.vue';
import Tag from '@/components/presentational/TicketTag.vue';
import dayjs from 'dayjs';
import { NBookingModule, OrganizerModule, ProductModule } from '../../../utils/storemodules';
import { ITicket, IExportPricesEntity} from '@/models/store/booking';
import { IPricePackage } from '@/models/store/packages';
import { EVSlotPrice } from '@/models/events';
import { allBookedPrices, isExpiry2Membership, isMembershipTicket } from '../../../utils/booking';
import { IProduct } from '@/models/store/product';
import DateHelperMixin from '@/mixins/DateHelper';
import { Mixins } from 'vue-mixin-decorator';
import { hideDateTime, hideEndDateTime, hideStartDateTime } from '@/utils/booking';
import { hadAllCustomersBooked } from '@/utils/membership';
import { isRealMembershipDiscount } from '@/utils/membership-utils';
import { RequiresBookingCodes } from '@/utils/booking-utils';
import { AppModule } from '../../../utils/storemodules';
import {isCalendarTicket} from '@/utils/booking';
import { MembershipRenewalSettingDurationType } from '@/models/enums';

@Component({
  components: {
    Price,
    Package,
    Tag,
  },
})
export default class TimeSlot extends Mixins<DateHelperMixin>(DateHelperMixin) {
  @Prop() public timeSlot!: ITimeslotsEntity;
  @Prop() public ticket!: ITicket;
  @Prop({default: ''}) public ticketError!: string;
  @Prop({default: ''}) public scroll!: boolean;
  private slot: ITimeslotsEntity | null = null;
  private EVSlotPrice = EVSlotPrice;
  private isTicketError = this.ticketError;
  // returns true if the chosen slot has prices
  get hasPrices(): boolean {
    if (this.chosenSlot.export_prices && this.chosenSlot.export_prices.length > 0) {
      return true;
    }
    return false;
  }

  get chosenSlot(): ITimeslotsEntity {
    return this.slot || this.timeSlot;
    // const ticket = this.ticket;
    // if (!ticket) {
    //   return this.timeSlot;
    // }

    // // return slot if it was already chosen
    // const chosenSlots = (ticket as ITicket).slots;
    // if (!chosenSlots) {
    //   return this.timeSlot;
    // }

    // return chosenSlots[this.timeSlot.id] || this.timeSlot;
  }

  get slotPrices(): IExportPricesEntity[] {
    const slot = this.chosenSlot;

    if (!slot) {
      return [];
    }

    const prices = slot.export_prices || [];

    // In membership booking, show only prices that could be
    // bought if all customers had previously bought
    if (NBookingModule.isMembershipBooking) {
      const customers = NBookingModule.membershipCustomers;
      const booking = NBookingModule.bookingRes;
      const categories = booking && booking.bookingRecap && booking.bookingRecap.categories;

      if (hadAllCustomersBooked(customers) && categories) {
        const bookedPrices = allBookedPrices(categories)
          .map((p) => p.priceName); // interested in name only

        // Users can see only the prices that were bought already
        return prices.filter((p) => bookedPrices.includes(p.priceName));
      }
    }

    return prices;
  }

  // Hiding end date time for equilibre for now
  get isEndHidden() {
    const orgId = OrganizerModule.id;
    if ((orgId && orgId === 16192) || hideEndDateTime(this.ticket)) {
      return true;
    } else {
      return false;
    }
  }

  get isStartHidden() {
    return hideStartDateTime(this.ticket);
  }

  get pricePackage() {
    return NBookingModule.pricePackage;
  }

  get startDate() {
    if (isMembershipTicket(this.ticket) && this.ticket.membership) {
      const membership = this.ticket.membership;
      const startDate = membership.startDate;
      if (startDate) {
        return this.currentDate(startDate);
      }
      return this.currentDate(dayjs(new Date()).toString());
    }
    return this.currentDate(this.timeSlot.startDateTime);
  }

  get endDate() {
    const membership = this.ticket.membership;
    if (membership) {
      if (membership.expiryDate) {
        return this.currentDate(membership.expiryDate);
      }
      const startDate = dayjs(membership.startDate || new Date()).add(this.minDuration, 'M');
      return this.currentDate(startDate.toString());
    }

    return this.currentDate(this.timeSlot.endDateTime);
  }

  get showDate() {
    return !hideDateTime(this.ticket);
  }

  get hideEndDate() {
    if (this.isExpiry2Membership) {
      return true;
    }

    return false;
  }

  get isMembershipTicket() {
    return isMembershipTicket(this.ticket);
  }

  get isExpiry2Membership() {
    return isExpiry2Membership(this.ticket);
  }

  get minDuration() {
    const membership = this.ticket.membership;
    if (!isMembershipTicket || !membership) {
      return 0;
    }

    if (membership.membershipRenewalSetting) {
      return membership.membershipRenewalSetting.durationLength;
    }

    const { periodLength, numberMandatoryPeriod } = membership;
    return periodLength * numberMandatoryPeriod;
  }

  get autoRenewal() {
    const membership = this.ticket.membership;
    if (!isMembershipTicket || !membership) {
      return '';
    }

    if (membership.autoRenew) {
      return this.$t('common.true');
    }

    return this.$t('common.not-available');
  }

  get guests() {
    const membership = this.ticket.membership;
    if (!isMembershipTicket || !membership) {
      return;
    }

    return membership.guests;
  }

  get periodLength() {
    const membership = this.ticket.membership;
    if (!isMembershipTicket || !membership) {
      return 0;
    }

    return membership.periodLength;
  }

  get unit() {

    if (!isExpiry2Membership(this.ticket)) {
      return '';
    }

    const membership = this.ticket.membership;
    if (membership && membership.membershipRenewalSetting) {
      const durationType = membership.membershipRenewalSetting.durationType;
      switch (durationType) {
        case MembershipRenewalSettingDurationType.Days:
            return this.minDuration > 1 ? this.$t('date.label-days') : this.$t('date.label-day');
        case MembershipRenewalSettingDurationType.Weeks:
            return this.minDuration > 1 ? this.$t('date.label-weeks') : this.$t('date.label-week');
        case MembershipRenewalSettingDurationType.Months:
            return  this.minDuration > 1 ? this.$t('date.months') : this.$t('date.month');
        case MembershipRenewalSettingDurationType.Years:
            return this.minDuration > 1 ? this.$t('date.years') : this.$t('date.year');
        default:
            return '';
    }
    }
    // return 'Month';
    return this.periodLength > 1 ? this.$t('date.months') : this.$t('date.month');

    // const periodLength = this.periodLength;
    // if (periodLength === 12) {
    //   return 'Year';
    // } else if (periodLength === 6) {
    //   return 'Simester';
    // } else if (periodLength === 3) {
    //   return 'Trimester';
    // } else {
    //   return 'Month';
    // }
  }

  get orderedPrices() {
    // Order prices based on their sortPrice value
    const prices = this.slotPrices;

    let orderedPrices = prices?.sort((a, b) => {
      let comparaison = 0;
      if (a.sortPrice !== undefined && b.sortPrice !== undefined) {
        comparaison = a.sortPrice - b.sortPrice;
      } else if (!a.sortPrice) {
        comparaison = 1;
      } else if (!b.sortPrice) {
        comparaison = -1;
      }

      // if two prices have the same sortPrice we'll order them alphabetically
      if (comparaison === 0) {
        comparaison = a.priceName[0] === b.priceName[0] ? 0 : a.priceName[0] < b.priceName[0] ? -1 : 1;
      }
      return comparaison;
    });

    // Hide certain prices for CO2 if membership type 1 || 2
    const { membership } = NBookingModule;
    const orgId = OrganizerModule.id;
    const pricesToHide = ['Abon. soutien', 'AG Culturel', 'Carte Culture PT',
      'Carte Culture TR', 'Comédien', 'Invité',
      'PassBienvenue', 'Abon. découverte PT', 'Abon. découverte TR'];
    if (membership && isRealMembershipDiscount(membership) && orgId && orgId === 18875) {
      orderedPrices = orderedPrices.filter((price) => !pricesToHide.includes(price.priceName));
    }

    return orderedPrices;
  }

  private get splitDisplayOfMembershipPrices() {
    const hasMembershipPrices =
      this.orderedPrices
        .find((price: IExportPricesEntity) => RequiresBookingCodes.includes(Number(price.membershipSetting)));
    if (hasMembershipPrices) {
      return true;
    }
    return false;
  }

  private get splitedOrderedPrices() {
    if (this.splitDisplayOfMembershipPrices) {
      const firstArray =
        this.orderedPrices
          .filter((price) => !RequiresBookingCodes.includes(Number(price.membershipSetting)));
      const secondArray =
        this.orderedPrices
          .filter((price) => RequiresBookingCodes.includes(Number(price.membershipSetting)));
      return [firstArray, secondArray];
    }
    return [];
  }

  private get isPiazzaGrande() {
    // TODO @PIAZZA
    // const piazzaGrandeProductIds = [63100, 37871];
    const piazzaGrandeProductIds = [37871, 63337, 63100, 64621];
    return piazzaGrandeProductIds.includes(this.ticket.productId);
  }

  private get showTeamInfo() {
    const teamSettigs = this.ticket.teamSettings;
    if (teamSettigs && teamSettigs.length > 0) {
      return true;
    }
    return false;
  }
  private get numberofTeams() {
    const teamInfo = NBookingModule.teamInfoPerCategory.find((info) => info.categoryId === this.ticket.categoryId);
    if (teamInfo && teamInfo.maxParticipants && teamInfo.teamCount) {
      const numberofTeams = Math.ceil(teamInfo.teamCount / teamInfo.maxParticipants);
      return numberofTeams;
    }
    return 0;
  }

  private get maxParticipants() {
    const teamSettigs = this.ticket.teamSettings;
    const maxParticipants = teamSettigs && teamSettigs[0]?.maxParticipants;
    return maxParticipants;
  }


  private get isMobile() {
    return AppModule.isMobile;
  }

  private get enableNewCalendarDesign() {
    return NBookingModule.enableNewCalendarDesign;
  }

  private get startLabelText() {
    // Anniversaire Product of Fort Boyard
   if ([63413, 65365, 65471, 65496, 65497, 65498, 65499, 65500].includes(this.ticket.productId)) {
    return this.$t('new-booking-flow.common.start-label-fort-boyard');
   } else {
    return this.$t('new-booking-flow.common.start-label');
   }
  }

  private get isFlipLabProduct() {
    const FlipLabLocationsId = [19319, 19543, 19544, 19545, 19546];
    if (FlipLabLocationsId.includes(Number(OrganizerModule.id))) {
      return true;
    }
    return false;
  }

  private async updateSlot(slotId: number) {
    this.fetchTicketSlots(dayjs(this.timeSlot.startDateTime).toDate());
  }

  private displayAddons(pricePackage: IPricePackage | null ) {
    if (pricePackage === null ) {return false; }

    const categories = Object.values(pricePackage.package_categories);

    return categories.some((c) => c.package_time_slots && c.package_time_slots.length > 0);
  }

  private async fetchTicketSlots(day: Date) {
    // fetch slots for the selected day
    const productId = (ProductModule.product as IProduct).id;
    const categoryId = this.ticket.categoryId;
    const from = dayjs(day).format('YYYY-MM-DD');
    const to = dayjs(day).add(1, 'day').format('YYYY-MM-DD');
    // this.isLoading = true;
    try {
      const slots = await fetchTicketSlots({productId, categoryId, from, to});
      if (!slots || !Array.isArray(slots)) {
        return;
      }
      let slotFound = false;
      for (const slot of slots) {
        if (slot.id === this.timeSlot.id) {
          this.slot = slot;
          slotFound = true;
          break;
        }
      }

      // Slot is not found, No more spots are left
      if (!slotFound) {
        const slot = this.chosenSlot;
        slot.publicCount = 0;
      }
    } catch (error) {
      // this.isLoading = false;
      throw error;
    }
  }

  private scrollToPrice() {
    const categoryElement: HTMLElement = document.getElementById(`ticket-${this.ticket.categoryId}`) as HTMLElement;
    if (categoryElement) {
       const elementToScrollTo: HTMLElement = categoryElement.querySelector('.filtered-slots') as HTMLElement;
       elementToScrollTo?.scrollIntoView({block: 'start', behavior: 'smooth'});
    }
  }

  private mounted() {
    if ((isCalendarTicket(this.ticket) && !this.enableNewCalendarDesign) || this.scroll) {
       this.scrollToPrice();
    }
  }

  @Watch('ticketError')
    private showWarining() {
      this.isTicketError = this.ticketError;
    }

}

import { gql } from 'graphql-tag';

/**
 * Fetches shipping rates
 *
 */
// tslint:disable-next-line
export const GET_Rates = gql`
  query GetRates($group_id: Int!, $to_country: String!, $to_postal_code: String!, $bookingReference: String!) {
    getRates(group_id: $group_id, to_country: $to_country, to_postal_code: $to_postal_code, bookingReference: $bookingReference) {
      id
      name
      carrier
      minWeight: min_weight
      maxWeight: max_weight
      servicePointInput: service_point_input
      countries {
        id
        name
        price
        iso_2
      }
      rateToken
    }
  }
`;

/**
 * Mutation create shipping parcel
 *
 */
// tslint:disable-next-line
export const CREATE_Shipping = gql`
  mutation createShipping($data: CreateShippingInput!) {
    createShipping(data: $data) {
     id
    }
  }
`;









































































































































































/**
 * Component used as a date selector. If a ticket has more than
 * 10 timeslots, it shows a calendar selector. Otherwise, dates
 * are layed out in rectangular form.
 *
 * @Prop ticket {ITicket}: ticket information
 *
 * @emit datesSelected date[]: Array of selected dates
 */
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { getDecimalDigits } from '@/utils/helpers';
import { Mixins } from 'vue-mixin-decorator';
import Calendar from '@/components/presentational/Calendar.vue';
import Tag from '@/components/presentational/TicketTag.vue';
import SlotsHours from './SlotsHours.vue';
import NewSlotsHours from './SlotsHours2.vue';
import { EVCalendar, EVSlotsFilter, ESlotsHours } from '@/models/events';
import { ITicket, IDatesEntity, ITimeslotsEntity, IDatePrice, IPriceEntity} from '../../../models/store/booking';
import dayjs from 'dayjs';
import moment from 'moment';
import { hasPriceGradient, isCalendarTicket, getPriceColor, hasOccupancyGradient } from '../../../utils/booking';
import { ReferrerModule, ProductModule, AppModule, OrganizerModule, NBookingModule } from '../../../utils/storemodules';
import DateHelperMixin from '@/mixins/DateHelper';
import { dateSortComp } from '@/utils/helpers';
import { ICalendarLegend } from '@/models/site';
import CalendarLegend from './CalendarLegend.vue';
import NewButton from '@/components/presentational/NewButton.vue';
import { getControlGroupUserList } from '@/api/controlGroupUser';

@Component({
  components: {
    Calendar,
    Tag,
    SlotsHours,
    NewSlotsHours,
    CalendarLegend,
    NewButton,
  },
})

export default class SlotsFilter extends Mixins<DateHelperMixin>(DateHelperMixin) {
  @Prop() public ticket!: ITicket;
  @Prop() public slots!: ITimeslotsEntity;
  @Prop({default: ''}) public hour!: string;
  @Prop({default: false}) public isPackage!: boolean;
  @Prop() public slotDates!: Date[];
  @Prop({default: null}) public legends!: ICalendarLegend[];
  @Prop({default: null}) public userChosenMonth!: Date;
  private calendarDate: Date | null = null;
  private calendarDatePopup: Date | null = null;
  private EVCalendar = EVCalendar;
  private ESlotsHours = ESlotsHours;
  private selectedDates: {[s: string]: IDatesEntity} = {};
  private toDate: Date | null = null;
  // private availableDates: Date[] = this.getAvailableDates();
  private selectedHour = '';
  private referenceDate: Date | null = null;
  private showDatePopup = false;
  // private openDate: Date = this.availableDates[0];

  get isMobile() {
    return AppModule.isMobile;
  }

  get enableNewCalendarDesign() {
    return NBookingModule.enableNewCalendarDesign;
  }

  get newTimeslotsDesign(): boolean {
    const designEnabledGroups = [1, 19066, 19560, 19567, 19572, 19590, 19610];
    const groupId = OrganizerModule.id || OrganizerModule.orgId;
    if (designEnabledGroups.includes(Number(groupId))) {
      return true;
    }
    return ReferrerModule.newTimeslots ? true : false;
  }

  get inlineCalendar() {
    // we inline the calendar only starting from tablet
    return !(AppModule.isMobile);
  }
  /**
   * Checks whether a ticket is a calendar ticket or not.
   * Calendar tickets contain 10 or more slots;
   */
  get isCalendarTicket(): boolean {

    // days are already set, forget about it
    if (this.slotDates && (this.slotDates.length <= 7)) {
      return false;
    }
    // if we set mFrom or mTo, then we have to show a calendar
    return (!!ReferrerModule.mFrom) || (!!ReferrerModule.mTo) || isCalendarTicket(this.ticket);
    // return this.ticket.categoryInfo.timeslots.length >= 10;
    // return true;
  }

  get chosenCalendarDate(): Date {
    if (this.calendarDate) {
      return this.calendarDate;
    }

    // return first timeslot date
    const timeSlot = this.ticket.categoryInfo.timeslots[0];
    if (timeSlot) {
      return dayjs(timeSlot.startDateTime).toDate();
    }

    return new Date();
  }

  /**
   * Returns a date in the form of
   */
  get formattedCalendarDate(): string {
    if (this.calendarDate) {
      return dayjs(this.calendarDate).format('DD.MM.YYYY');
    } else {
      return '';
    }
  }

  /**
   * Non calendar slots
   */
  get nonCalendarSlots(): Array<{
    selected: boolean,
    info: IDatesEntity,
    displayDate: string;
  }> {
    const dates = [];

    // if we already have slot dates (package case)
    if (this.slotDates) {
      for (const date of this.slotDates) {
        const selected = !!this.selectedDates[date.toString()];
        const day = dayjs(date);
        const dayString = day.format('ddd');
        const dateString = day.format('DD MMM YYYY');

        dates.push({
          selected,
          info: {
            startDate: date.toString(),
            publicCount: '1',
          },
          displayDate: `${dayString}, ${dateString}`,
        });
      }

      return dates;
    }

    for (const date of this.ticket.categoryInfo.dates) {
      const selected = !!this.selectedDates[date.startDate];
      const day = dayjs(date.startDate);
      const dayString = day.format('ddd');
      const dateString = day.format('DD MMM YYYY');

      dates.push({
        selected,
        info: date,
        displayDate: `${dayString}, ${dateString}`,
      });
    }

    return dates;
  }

  @Watch('$store.state.auth.lang')
  public async onLangChanged(newLang: string) {
    if (this.calendarDate) {
      this.onSelect(this.calendarDate);
    }
  }
  @Watch('userChosenMonth', {immediate: true})
  public editedDate() {
    if (this.isEditedTicket) {
      this.calendarDate = this.userChosenMonth;
    }
  }

  /**
   *
   */
  private async mounted() {
    // starting date on calendar is the first date with available seats
    const dates = this.ticket.categoryInfo.dates;
    let d: null | Date = null;
    for (const date of dates) {
      if (date.publicCount === '1') {
        // this.toDate = dayjs(date.startDate).toDate();
        const datejs = dayjs(date.startDate);
        if (!d) {
          d = datejs.toDate();
          continue;
        }
        if (datejs.isBefore(d)) {
          d = datejs.toDate();
        }
      }
    }

    // check the minimum date that we should show. if present, it has higher priority
    const mFrom = ReferrerModule.mFrom;
    const mFromDate = mFrom && dayjs(mFrom);
    if (d && mFromDate && dayjs(d).isBefore(mFromDate)) {
      d = mFromDate.toDate();
    }

    this.toDate = d;
    this.referenceDate = d;

    // select first date for non calendar package slots
    if (this.slotDates && this.slotDates.length && !this.isCalendarTicket) {
      const slotDate = this.slotDates[0];
      this.selectDate({
        startDate: slotDate.toString(),
        publicCount: '1',
      });
      return ;
    }

    // By default should select the first date
    const hasSlots = this.ticket.categoryInfo.timeslots.length > 0;

    // avoid if no slots are available
    if (!hasSlots) {
      return;
    }

    const slot = this.ticket.categoryInfo.timeslots[0];
    const dateInfo = this.ticket.categoryInfo.dates[0];

    if (!this.isCalendarTicket) {
      this.selectDate(dateInfo);
    }
    // We preselect first date if new calendar design
    if (this.enableNewCalendarDesign && this.availableDatesToShow.length > 0 && this.isCalendarTicket
     && !this.isEditedTicket) {
      this.onSelect(this.availableDatesToShow[0].date, true);
    }

    if (this.isPackage && this.isCalendarTicket && this.availableDates && this.availableDates.length > 0) {
      this.onSelect(this.availableDates[0]);
    }

    const newCalendar = ReferrerModule.newCalendar;
    if (newCalendar) {
      const numberOfDatesToShow = ReferrerModule.numDaysNewCalendar ? Number(ReferrerModule.numDaysNewCalendar) : 5;
      if (this.availableDates && this.availableDates.length < numberOfDatesToShow) {
        // Get the current date
        const currentDate = new Date();
        // Set the date to the 1st day of the current month
        currentDate.setDate(1);
        // Move to the next month
        currentDate.setMonth(currentDate.getMonth() + 1);
        this.onMonthChange(currentDate);
      }
    }
  }
  /**
   * Opens the calendar view
   */
  private showCalendar() {
    const component: any = this.$refs.calendarComponent;
    component.showCalendar();
  }

  private getDecimalDigits(n: number, x: number) {
    return getDecimalDigits(n, x);
  }

  private getDay(date: Date) {
    moment.locale(this.$i18n.locale);
    const dayName = moment(date).format('dddd').toLowerCase();
    return  dayName.charAt(0).toUpperCase() + dayName.slice(1);
  }

  private getDayDate(date: Date) {
    const dayDate = moment(date).format('MMM D');
    return dayDate.charAt(0).toUpperCase() + dayDate.slice(1);
  }

  /**
   * Handles selection calendar on popup
   */
  private onSelectDatePopup(date: Date) {
    this.calendarDatePopup = date;
  }

  private selectDatePopup() {
    if (!this.calendarDatePopup) { return; }
    this.showDatePopup = false;
    this.onSelect(this.calendarDatePopup);
    this.calendarDatePopup = null;
  }

  private closeCalendarPopup() {
    this.showDatePopup = false;
    this.calendarDatePopup = null;
    (this.$refs.calendarComponentPopup as any).resetSelectedDate(this.calendarDate);
  }

  /**
   * Handles calendar date changes
   */
  private onSelect(date: Date, noScroll: boolean = false) {
    this.calendarDate = date;
    if (!this.availableDatesToShow.find((d) => d.date === date)) {
      this.referenceDate = date;
    }
    this.$emit(EVSlotsFilter.DatesSelected, [date]);
    this.selectHour('');
    if (this.isMobile && (!noScroll)) {
      this.$emit(EVSlotsFilter.ScrollToSlots);
    }
  }

  /**
   * Handle non calendar selects
   */
  private selectDate(info: IDatesEntity) {
    /* Previous implementation for multiple dates selection
    // toggle the date
    if (this.selectedDates[info.startDate]) {
      delete this.selectedDates[info.startDate];
    } else {
      this.selectedDates[info.startDate] = info;
    }*/

    this.selectedDates = {[info.startDate]: info};

    // emit the dates
    const dates: Date[] = [];
    for (const startDate in this.selectedDates) {
      if (!startDate) { continue; }

      dates.push(dayjs(startDate).toDate());
    }

    this.ticket.categoryInfo.dates = [...this.ticket.categoryInfo.dates];
    this.$emit(EVSlotsFilter.DatesSelected, dates);
  }

  private selectHour(hour: string) {
    this.selectedHour = hour;
    this.$emit(EVSlotsFilter.HourSelected, hour);
  }

  private onMonthChange(date: Date) {
    this.$emit(EVSlotsFilter.MonthChanged, date);
  }
  // Emit updateCalendar when The calendar is mounted
  private calendarMounted() {
    if (this.isCalendarTicket &&
      this.ticket.categoryInfo.dates &&
      this.ticket.categoryInfo.dates.length) {
      const dateInfo = this.ticket.categoryInfo.dates[0];
      // We'll fire the onMonthChange() function in the parent Component
      // so that we get the calendar with the gradients in the first load
      // We wait till the date picker mount to emit the updatedCalendar
      this.$emit(EVSlotsFilter.UpdateCalendar, new Date(dateInfo.startDate));
    }
  }
  // Returns dates with available slots
  get availableDates(): Date[] {
    const dates = this.ticket.categoryInfo.dates;

    // check the minimum date that we should show. if present, it has higher priority
    const mFrom = ReferrerModule.mFrom;
    const mFromDate = mFrom && dayjs(mFrom);
    const mTo = ReferrerModule.mTo;
    const mToDate = mTo && dayjs(mTo);

    return this.slotDates && this.slotDates.length ?
      this.slotDates :
      dates.filter((date) => {
        // date should be after minimum date and before maximum date
        if ((mFromDate && dayjs(date.startDate).isBefore(mFromDate))
        || (mToDate && dayjs(date.startDate).isAfter(mToDate))) {
          return false;
        }

        return date.publicCount === '1';
      }).sort((date1, date2) => {
        return dateSortComp(dayjs(date1.startDate).toDate(), dayjs(date2.startDate).toDate());
      }).map((date) => {
        return dayjs(date.startDate).toDate();
      });
  }

  // Dates for new calendar display
  get availableDatesToShow(): Array<{ date: Date; minPrice: string | undefined; }> {
    const numberOfDatesToShow = ReferrerModule.numDaysNewCalendar ? Number(ReferrerModule.numDaysNewCalendar) : 5;
    const toDateLocally = this.toDate || this.availableDates[0];
    const referenceDateLocally = this.referenceDate || this.availableDates[0];
    const dates = this.availableDates.filter((date) => dayjs(date) >= dayjs(toDateLocally as Date));
    const selectedIndex = dates.findIndex((date) => date.getTime() === (referenceDateLocally as Date).getTime());
    const startIndex = Math.max(0, selectedIndex - numberOfDatesToShow + 1);
    const endIndex = Math.min(dates.length - 1, startIndex + numberOfDatesToShow - 1);

    const datesWithMinPrice = dates.map((date) => {

      const price = this.ticket.categoryInfo.dates.find((d) =>
        this.getDayDate(dayjs(d.startDate).toDate()) === this.getDayDate(dayjs(date).toDate()));
      return {
        date,
        minPrice: Number(price?.minPrice) > 0 ? price?.minPrice : price?.maxPrice,
      };
    });

    return datesWithMinPrice.slice(startIndex, endIndex + 1);
  }

  // Returns an array of dates and their corresponding smart price
  get pricesForAvailableDates(): IDatePrice[] {
    if (!this.ticket.displayOnCalendar || !hasPriceGradient(this.ticket)) {
      return [];
    }

    // Get smart prices stored inside ticket.categoryInfo.dates
    // for availableDates[] elements and compose an array of IDatePrice[]
    const dates: IDatesEntity[] = this.ticket.categoryInfo.dates;
    return this.availableDates.map((d) => {
      // For each date of availableDates[] find its corresponding inside ticket.categoryInfo.dates
      // and take its smart price 'minPrice'
      const res = dates.find((dd) => dayjs(dd.startDate).toDate().getTime() === d.getTime());
      // By default we show minPrice. If minPrice is 0, we show maxPrice instead
      const p = Number(res?.minPrice) || Number(res?.maxPrice);
      return {
        date: d,
        price: p,
        priceColor: getPriceColor(this.ticket, p),
        };
      });
  }


  get isOccupancyCalendar(): boolean {
    return hasOccupancyGradient(this.ticket);
  }

  get isPricesCalendar(): boolean {
    return hasPriceGradient(this.ticket);
  }

  get currency() {
    return ProductModule.productCurrency;
  }

  get openDate(): Date {
    // returns the date where the calendar should be introduced to the user
    // we used to open it on today
    // then we startd opening the calendar on the first date with availabilities
    // but now since we're fetching dates on NextMonth click and availableDates[] is getting updated accordingly
    // we need to show the month the user clicked on.

    return this.userChosenMonth || this.availableDates[0];
  }

  get isEditedTicket() {
    return !!(NBookingModule.editedTicketIds?.ticketId === this.ticket.categoryId);
  }
}

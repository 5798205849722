































import { Vue, Component, Prop } from 'vue-property-decorator';
import { BookingStatus } from '@/models/store/product';

@Component({})
export default class UnsupBoard extends Vue {
  private browsers = [
    {
      name: 'Google Chrome', file: 'chrome.png',
      link: 'https://www.google.com/chrome/',
    },
    {
      name: 'Mozilla firefox', file: 'firefox.png',
      link: 'https://www.mozilla.org/en-US/firefox/new/'},
    {
      name: 'Safari', file: 'safari.png',
      link: 'https://support.apple.com/downloads/safari',
    },
  ];
}

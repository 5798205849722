

















import { Vue, Component, Prop } from 'vue-property-decorator';
import ImageCard from './ImageCard.vue';

@Component({
  components: {
    ImageCard,
  },
})
export default class CardsSlider extends Vue {
  @Prop({default: null}) private cardsData: any;

  private swiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 30,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  };
}





































import { Vue, Component, Watch } from 'vue-property-decorator';
import CalendarInput from '@/components/presentational/CalendarInput.vue';
import dayjs from 'dayjs';
import { ProductsModule } from '@/utils/storemodules';
import { EVCalendar } from '@/models/events';

@Component({
  components: {
    CalendarInput,
  },
})
export default class DateFilter extends Vue {
  private from: Date | null = null;
  private to: Date | null = null;
  private EVCalendar = EVCalendar;

  private created() {
    this.from = ProductsModule.from;
    this.to = ProductsModule.to;
  }

  // Watcher invoked when changing toDate
  // We will consier only the one coming from Restore Filters btn
  @Watch('$store.state.products.to')
  private onToDateChange(newTo: Date | null) {
    if (!newTo) {
      // lets clear the corresponding calendar
      this.to = newTo;
    }
  }

  // Watcher invoked when changing toDate
  // We will consier only the one coming from Restore Filters btn
  @Watch('$store.state.products.from')
  private onFromDateChange(newFrom: Date | null) {
    if (!newFrom) {
      // lets clear the corresponding calendar
      this.from = newFrom;
    }
  }

  get fromOptions() {
    return (date: any) => {
      const to = ProductsModule.to;
      if (to) {
        return date >= dayjs().format('YYYY/MM/DD') && date < dayjs(to).format('YYYY/MM/DD');
      }

      return date >= dayjs().format('YYYY/MM/DD');
    };
  }

  get toOptions() {
    return (date: any) => {
      const from = ProductsModule.from;
      if (from) {
        return date > dayjs(from).format('YYYY/MM/DD');
      }

      return date > dayjs().format('YYYY/MM/DD');
    };
  }

  private async setFrom(date: Date) {
    ProductsModule.setOptions({
      from: date,
    });
    await ProductsModule.search();
  }

  private async setTo(date: Date) {
    const from = ProductsModule.from;
    ProductsModule.setOptions({
      to: date,
    });

    if (from && dayjs(date).isBefore(from)) {
      ProductsModule.setOptions({
        from: null,
      });
    }
    await ProductsModule.search();
  }
}

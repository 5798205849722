
















import {Vue, Component, Prop} from 'vue-property-decorator';
@Component({})
export default class ToolTipIcon extends Vue {
  @Prop() public icon!: string;
  @Prop({default: ''}) public text!: string;
  @Prop({default: 'top'}) public position!: 'top'|'right'|'left'|'bottom';
  @Prop({default: 'top'}) public mobilePosition!: 'top'|'right'|'left'|'bottom';
  @Prop({default: '20px'}) public size!: string;
  // @Prop() width!: string;
}

export const IdealTestIssuers = [
    {
        id: '1121',
        name: 'Test Issuer',
    },
    {
        id: '1154',
        name: 'Test Issuer 5',
    },
    {
        id: '1153',
        name: 'Test Issuer 4',
    },
    {
        id: '1152',
        name: 'Test Issuer 3',
    },
    {
        id: '1151',
        name: 'Test Issuer 2',
    },
    {
        id: '1162',
        name: 'Test Issuer Cancelled',
    },
    {
        id: '1161',
        name: 'Test Issuer Pending',
    },
    {
        id: '1160',
        name: 'Test Issuer Refused',
    },
    {
        id: '1159',
        name: 'Test Issuer 10',
    },
    {
        id: '1158',
        name: 'Test Issuer 9',
    },
    {
        id: '1157',
        name: 'Test Issuer 8',
    },
    {
        id: '1156',
        name: 'Test Issuer 7',
    },
    {
        id: '1155',
        name: 'Test Issuer 6',
    },
];

export const IdealProdIssuers = [
    {
        id: '0031',
        name: 'ABN AMRO',
    },
    {
        id: '0761',
        name: 'ASN Bank',
    },
    {
        id: '0802',
        name: 'bunq',
    },
    {
        id: '0721',
        name: 'ING Bank',
    },
    {
        id: '0801',
        name: 'Knab',
    },
    {
        id: '0807',
        name: 'N26',
    },
    {
        id: '0021',
        name: 'Rabobank',
    },
    {
        id: '0771',
        name: 'Regiobank',
    },
    {
        id: '0805',
        name: 'Revolut',
    },
    {
        id: '0751',
        name: 'SNS Bank',
    },
    {
        id: '0511',
        name: 'Triodos Bank',
    },
    {
        id: '0161',
        name: 'Van Lanschot Kempen',
    },
    {
        id: '0806',
        name: 'Yoursafe',
    },
];

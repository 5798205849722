












































import { Vue, Component } from 'vue-property-decorator';
import UserSpaceCard from './components/UserSpaceCard.vue';
import { AuthModule, ModalModule, NModalModule } from '@/utils/storemodules';
import { ModalStatus } from '@/models/definitions';
import { fromDashDate } from '@/utils/helpers';

@Component({
  components: {
    UserSpaceCard,
  },
})
export default class UserSpace extends Vue {
  private ModalStatus = ModalStatus;
  private fromDashDate = fromDashDate;

  @AuthModule.Getter('getLanguageName') private lang!: string;
  @AuthModule.Getter('getBirthdate') private birthDate!: string;
  @AuthModule.Getter('getLocation') private location: any;
  @AuthModule.Getter('fullName') private name: any;
  @ModalModule.Mutation('setStatus') private setStatus: any;

  private show(status: ModalStatus) {
    // this.setStatus(status);
    NModalModule.setStatus(status);
  }
}

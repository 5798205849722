import Debug from 'debug';
import dayjs from 'dayjs';
import moment from 'moment';
import { GetterTree } from 'vuex';
import {
  IBookingState, BookingSteps,
  ISlot, ICategory, IRecap, IRecapCategory, IRecapPrice,
  IPreBookingRes, IServerBooking, IPaymentMethod,
  BookingFlowType, ISlotDates, IRecapSlot, ITrackingData,
  IDPSlot, IDPSlotWReason, IDPSlotSource,
} from '@/models/store/booking';
import { IRootState } from '@/models/store';
import {
  GET_PRODUCT, GET_PRODUCT_NAME, GET_CALENDAR_DATES,
  GET_SELECTED_CATEGORIES, GET_RECAP, GET_GUEST_BOOKING,
  GET_PRODUCT_ID, GET_BOOKING, GET_BOOKING_ID,
  GET_BOOKING_TOKEN, GET_LOADING, GET_SERVER_BOOKING, GET_IS_INSTANT_BOOKING,
  GET_PRODUCT_SHORT_URL, GET_BOOKING_FLOW, GET_RECAP_TOTAL,
  GET_DISCOUNT, GET_FEES, GET_PAYMENT_METHOD, GET_BOOKING_FLOW_TYPE,
  GET_FLATTENED_CATEGORIES, GET_SELECTED_SLOTS, GET_SLOT_BY_DATE,
  GET_TICKETS_TRACKING, GET_BOOKING_REFERRER_SITE,
  GET_PRODUCT_CURRENCY, GET_PRODUCT_DESCRIPTION, GET_PRODUCT_LOCATION,
  GET_DP_SLOT, GET_AVOIDED_DP_SLOT, GET_IS_DP_BOOKING,
  GET_DP_MAX_TICKETS, GET_DP_PRICE, GET_DP_SLOT_DATA,
  GET_NEXT_PRODUCT_DATE,
} from './constants';
import { StepName, NextStep, PrevStep } from './values';
import { isSlotFromDate } from '@/utils/helpers';
import { Product } from '@/models/store/bookingClasses';
import { ILocation, IProduct, ISlotsProposalsEntity } from '@/models/store/product';

const debug = Debug('smeetz:booking');

const Getters: GetterTree<IBookingState, IRootState> = {
  // isInstantBooking(state, getters) {
  //   const product: IProduct = getters[GET_PRODUCT];

  //   return product.isInstantBooking;
  //   // return state.instantBooking;
  // },

  [GET_IS_INSTANT_BOOKING](state, getters) {
    return getters.isInstantBooking;
  },

  getCurrentStep(state): BookingSteps {
    return state.step;
  },

  getCurrentStepName(state, getters) {
    return StepName[getters.getCurrentStep];
  },

  getStepName(state) {
    return (name: BookingSteps) => StepName[name];
  },

  getNextStep(state, getters) {
    return NextStep[getters.getCurrentStep];
  },

  getPrevStep(state, getters) {
    return PrevStep[getters.getCurrentStep];
  },

  // Flow 0 products don't have DateTime step
  getFlowSteps(state, getters) {
    const flow: number = getters[GET_BOOKING_FLOW];

    if (flow === BookingFlowType.Type0 || flow === BookingFlowType.GroupByDay) {
      return state.steps.slice(1);
    }

    return state.steps;
  },

  [GET_PRODUCT](state) {
    return state.product;
  },

  [GET_BOOKING_FLOW](state, getters) {
    const product: IProduct = getters[GET_PRODUCT];
    if (!product) {
      return;
    }

    return product.booking && product.booking.flow;
  },

  [GET_BOOKING_FLOW_TYPE](state, getters) {
    return BookingFlowType[getters[GET_BOOKING_FLOW]];
  },

  [GET_PRODUCT_NAME](state, getters) {
    const product: IProduct = getters[GET_PRODUCT];
    return product.name;
  },

  [GET_PRODUCT_ID](state, getters) {
    const product: IProduct = getters[GET_PRODUCT];
    return product.id;
  },

  [GET_CALENDAR_DATES](state) {
    return state.calendar.dates || [];
  },

  getDate({ date, product }): Date | undefined {

    // Return date if it's already set
    if (date) {
      debug('Returning date', date);
      return date;
    }

    // return the first suggested slot in this product
    const slotsProposals = product.slotsProposals;
    if (slotsProposals && slotsProposals.length) {
      const proposal: ISlotsProposalsEntity = slotsProposals[0];

      if (proposal && proposal.startDateTime) {
        debug('Returning date from the following: ', proposal.startDateTime);
        debug('dayjs date from the following: ', dayjs(proposal.startDateTime));
        // return new Date(proposal.startDateTime);
        return dayjs(proposal.startDateTime).toDate();
      }
    }

    return;
  },

  // Returns next product slot date
  [GET_NEXT_PRODUCT_DATE]({ product }): Date | undefined {
    if (!product) {
      return;
    }

    const productOverview = (product as IProduct).overview;
    if (productOverview && productOverview.nextDateTime) {
      return dayjs(productOverview.nextDateTime.startDateTime).toDate();
    }
  },

  getSlots({ date, product, availabilities }, getters) {

    if (availabilities && availabilities.dates) {
      debug('Got availabilities');

      let slots: ISlot[] | null | undefined = [];
      if (getters[GET_BOOKING_FLOW] === 2) {
        slots = (availabilities.dates.length && availabilities.dates[0].summarizedSlots) || [];
      } else {
        for (const d of availabilities.dates) {
          if (d.summarizedSlots) {
            slots.push(...(d.summarizedSlots));
          }
        }
      }

      return slots;
    }

    return [];

    // LEAVE COMENTED, till testing is approved.
    // if (!product) {
    //   return [];
    // }

    // const { slotsProposals } = product;
    // if (!slotsProposals) {
    //   return [];
    // }

    // // Here, no availabilites are fetched yet, so extract slots from
    // // proposed slots in IProduct (Retreived from product shortname).
    // // Return only proposed slots that correspond to selected or first
    // // proposal date
    // const filterDate: Date = getters.getDate;
    // const matches = product
    //   .slotsProposals.filter((prop: ISlotsProposalsEntity) => {
    //     // const proposalDate = new Date(prop.startDateTime);
    //     const proposalDate = dayjs(prop.startDateTime).toDate();
    //     return proposalDate.getTime() === filterDate.getTime();
    //   });

    // debug(`Returning ${matches.length} proposed slots`);
    // return matches;
  },

  getSlot({ slot }): ISlot {
    return slot;
  },

  getCategories(state, getters) {
    // const slot: ISlot = getters.getSlot;
    // if (!slot) {
    //   return [];
    // }

    // return slot.categories;

    // retrieve slots
    const slots: ISlot[] = getters[GET_SELECTED_SLOTS];
    const categories: ICategory[] = [];

    for (const slot of slots) {
      if (slot.categories) {
        categories.push(...slot.categories);
      }
    }
    // return state.categories;
    return categories;
  },

  [GET_SELECTED_CATEGORIES](state, getters): ICategory[] {
    const categories: ICategory[] = getters.getCategories;
    const selectedCat = categories.filter((cat) => cat.select);

    return selectedCat;
  },

  // Returns selected categories where seatingSubProducts replace their parent
  // category.
  [GET_FLATTENED_CATEGORIES](state, getters): ICategory[] {
    const categories: ICategory[] = getters.getCategories;
    // const selectedCat = categories.filter((cat) => cat.select);
    const selectedCat: ICategory[] = [];
    for (const cat of categories) {
      if (!cat.select) {
        continue;
      }

      // categories without seatingSubProducts are selected directly
      const seatingSubProducts = cat.seatingSubProducts;
      if (!seatingSubProducts || !seatingSubProducts.length) {
        selectedCat.push(cat);
        continue;
      }

      // add all seatingSubProducts
      for (const seatCat of seatingSubProducts) {
        selectedCat.push(seatCat);
      }
    }

    return selectedCat;
  },

  // getSelectedCategories(state: IBookingState) {
  //   return state.selectedCategories;
  // },

  [GET_RECAP](state, getters): IRecap {
    let name;
    let start;
    let end;
    const categories: IRecapCategory[] = [];
    let subTotal = 0;
    const discount: number = getters[GET_DISCOUNT];
    const paymentFees: number = getters[GET_FEES];
    const recapSlots: IRecapSlot[] = [];
    let total = 0;

    // Since we are going to support multiple dates, we will
    // store different categories in differenct slots based on
    // startDateTime and endDateTime

    // slots details
    const slots: ISlot[] = getters[GET_SELECTED_SLOTS];
    // just a holder for quick reference
    const recapSlotsObj: { [s: string]: IRecapSlot } = {};

    // Initialize recap slots
    for (const selectedSlot of slots) {
      const startDate = selectedSlot.startDateTime;
      const endDate = selectedSlot.endDateTime;

      const recapSlot: IRecapSlot = {
        startDateTime: startDate,
        endDateTime: endDate,
        categories: [],
        key: String(Math.random()),
        schedule: selectedSlot.schedule,
      };
      recapSlotsObj[startDate + endDate] = recapSlot;
      recapSlots.push(recapSlot);
    }

    // get product and slot info
    const slot: ISlot = getters.getSlot;

    const product: IProduct = getters[GET_PRODUCT];

    name = product.name;
    if (slot) {
      // start = new Date(slot.startDateTime);
      start = dayjs(slot.startDateTime).toDate();
      // end = new Date(slot.endDateTime);
      end = dayjs(slot.endDateTime).toDate();
    }

    // Iterate over selected categories
    // const selectedCategories: ICategory[] = getters[GET_SELECTED_CATEGORIES];
    const selectedCategories: ICategory[] = getters[GET_FLATTENED_CATEGORIES];

    // For each category, fill name and prices info
    for (const cat of selectedCategories) {
      // const categoryName = cat.categoryName;
      // const selectedPrices = cat.price;
      const { categoryName, startDateTime, endDateTime } = cat;
      const prices: IRecapPrice[] = [];

      if (!cat.price) {
        continue;
      }

      for (const price of cat.price) {
        // don't push ticket prices that haven't been selected
        if (!price.count) {
          continue;
        }

        // If dp multiply by dynamic pricing price
        // const ticketTotal = price.count *
        //   (getters[GET_IS_DP_BOOKING] ? getters[GET_DP_PRICE] : Number(price.priceValue));
        const ticketPrice: number = (price.computedPrice ? price.computedPrice.priceValue : Number(price.priceValue));
        const ticketTotal = price.count * ticketPrice;
        const rPrice: IRecapPrice = {
          id: price.priceId,
          name: price.priceName,
          count: price.count,
          total: ticketTotal,
          key: String(Math.random()),
          // price: Number(price.priceValue),
          price: ticketPrice,
        };

        if (price.computedPrice) {
          rPrice.computedPrice = price.computedPrice;
        }

        // Add seats when dealing with a seats category
        if (price.seats) {
          rPrice.seats = price.seats;
        }
        prices.push(rPrice);

        // and update subTotal
        subTotal += ticketTotal;
      }

      // retrieve the slot where this category will be inserted
      const recapSlot = recapSlotsObj[startDateTime + endDateTime];
      // add category to list of recap categories
      const rCat: IRecapCategory = {
        name: categoryName, ticketPrices: prices, id: cat.categoryId,
        slotId: recapSlot.schedule.id, startDateTime, endDateTime,
      };
      if (cat.holdToken) {
        rCat.holdToken = cat.holdToken;
      }
      categories.push(rCat);
      recapSlot.categories.push(rCat);
      // recapSlotsObj[startDateTime + endDateTime].categories.push(rCat);
    } // end of selectedCategories

    total = subTotal;

    // get discount and subtract it from total
    total -= discount;

    // get payment fee and add it to total
    total += paymentFees;

    // populate recap object
    return {
      name, start, end,
      location: product.location,
      categories, subTotal,
      discount, paymentFees, total,
      currency: selectedCategories.length ? selectedCategories[0].standardPriceCcy : '',
      slots: recapSlots,
    };
  },

  [GET_RECAP_TOTAL](state, getters): number {
    const recap: IRecap = getters[GET_RECAP];

    return recap.total;
  },

  [GET_DISCOUNT](state, getters): number {
    const serverBooking: IServerBooking = getters[GET_SERVER_BOOKING];

    if (!serverBooking) {
      return 0;
    }

    return serverBooking.bookingRecap.discount;
  },

  [GET_FEES](state, getters): number {
    const paymentMethod: IPaymentMethod | undefined = getters[GET_PAYMENT_METHOD];

    if (paymentMethod) {
      return paymentMethod.paymentFees || 0;
    }

    return 0;
  },

  [GET_GUEST_BOOKING](state) {
    return state.guestBooking;
  },

  [GET_BOOKING](state): IPreBookingRes | undefined {
    return state.booking;
  },

  [GET_SERVER_BOOKING](state): IServerBooking | undefined {
    return state.serverBooking;
  },

  [GET_BOOKING_REFERRER_SITE](state, getters): string {
    const bookingInfo: IServerBooking = getters[GET_SERVER_BOOKING];

    if (bookingInfo && bookingInfo.bookingReferrer) {
      return bookingInfo.bookingReferrer;
    }

    return '';
  },

  [GET_BOOKING_ID](state, getters): number | undefined {
    const booking: IPreBookingRes = getters[GET_BOOKING];

    if (!booking) {
      return;
    }

    return booking.bookingId;
  },

  [GET_BOOKING_TOKEN](state, getters): string | undefined {
    const booking: IPreBookingRes = getters[GET_BOOKING];

    if (!booking) {
      return;
    }

    return booking.bookingToken;
  },

  [GET_PAYMENT_METHOD](state, getters): IPaymentMethod | undefined {
    return state.paymentMethod;
  },

  [GET_LOADING](state): boolean {
    return state.loading;
  },

  [GET_PRODUCT_SHORT_URL](state): string {
    const product: IProduct = state.product;
    if (!product) {
      return '';
    }

    return product.short_url;
  },

  [GET_SELECTED_SLOTS](state: IBookingState): ISlot[] {
    return state.selectedSlots;
  },

  [GET_SLOT_BY_DATE](state, getters) {
    return (slotDates: ISlotDates) => {
      return (getters[GET_SELECTED_SLOTS] as ISlot[]).find((slot) => isSlotFromDate(slot, slotDates));
    };
  },

  [GET_TICKETS_TRACKING](state, getters): ITrackingData[] {
    const productData: IProduct = getters[GET_PRODUCT];
    const product: Product = new Product(productData);
    const recap: IRecap = getters[GET_RECAP];
    const tickets: ITrackingData[] = [];
    for (const category of recap.categories) {
      for (const price of category.ticketPrices) {
        const ticketData: ITrackingData = {
          id: String(product.id),
          name: product.name,
          brand: product.brand,
          currency: product.currency,
          quantity: price.count,
          category: category.name,
          price: price.price as number,
          position: 0,
          list: '', // TODO: need to confirm this
        };

        if (productData.fbPixelId) {
          ticketData.fbPixelId = productData.fbPixelId;
        }

        // Add google tracking id
        if (productData.googleTrackingId) {
          ticketData.googleTrackingId = productData.googleTrackingId;
        }

        tickets.push(ticketData);
      }
    }
    return tickets;
  },

  [GET_PRODUCT_CURRENCY](state, getters): string {
    const product: IProduct = getters[GET_PRODUCT];

    return product.currency;
  },

  [GET_PRODUCT_DESCRIPTION](state, getters): string {
    const product: IProduct = getters[GET_PRODUCT];

    return product && product.description;
  },

  [GET_PRODUCT_LOCATION](state, getters): ILocation {
    const product: IProduct = getters[GET_PRODUCT];

    return product && product.location;
  },

  [GET_DP_SLOT](state): IDPSlot | undefined {
    return state.dpSlot;
  },

  [GET_DP_SLOT_DATA](state, getters): IDPSlotSource | {} {
    const slot: IDPSlot | undefined = getters[GET_DP_SLOT];

    return slot ? slot._source : {};
  },

  [GET_AVOIDED_DP_SLOT](state): IDPSlotWReason[] {
    return state.avoidedDPSlots;
  },

  [GET_IS_DP_BOOKING](state): boolean {
    return !!state.dpSlot;
  },

  [GET_DP_MAX_TICKETS](state, getters): number {
    const slot = (getters[GET_DP_SLOT_DATA] as IDPSlotSource);
    return slot.max_number_ticket;
  },

  [GET_DP_PRICE](state, getters): number {
    const slot = (getters[GET_DP_SLOT_DATA] as IDPSlotSource);
    // The price is multiplied by 100 to avoid dealing with decimals
    return slot.price_value / 100;
  },
};

export default Getters;

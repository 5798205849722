































import { Vue, Component } from 'vue-property-decorator';
import NewButton from '@/components/presentational/NewButton.vue';
import { AppModule, NBookingModule, OrganizerModule, RBookingModule } from '@/utils/storemodules';
import { RefundSteps } from '@/store/modules/refundBooking/constants';
import { PRIMARY_COLOR } from '@/config/constants';

@Component({
  components: {
      NewButton,
  },
})
export default class RefundConfirmation extends Vue {

get isMobile() {
    return AppModule.isMobile;
}

private get orgColor(): string {
    return OrganizerModule.orgColor !== '' ? OrganizerModule.orgColor : PRIMARY_COLOR;
}


get currentStep(): RefundSteps {
    return RBookingModule.currentStep;
}
private get email(): string {
    return NBookingModule.bookingSummary?.data.ProductSlotBookingRead.Email as string;
}

private get organizerEmail(): string {
    return RBookingModule.organizerEmail;
}
private get formattedConfMsg(): string {
    return String(this.$t('user-space.refund-booking.confirmed-msg2')).replace('XXX', this.email);
}
private created() {
    RBookingModule.setCurrentStep(RefundSteps.Confirmation);
    RBookingModule.setIsRefunded(false);
}
private goHome() {
    // Take the user back to his list of bookings
    if (this.$route.path.includes('org-user')) {
        this.$router.replace(`/org-user/${OrganizerModule.id}/userbookings`);
    } else if (this.$route.path.includes('user-space')) {
        this.$router.replace('/user-space');
    }
}
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"new-button"},[(_vm.htmlType === 'a')?_c('a',{staticClass:"button",class:[{'is-loading': _vm.isLoading}, _vm.type, _vm.getButtonClass],style:({
      'background-color': _vm.color, 
      color: _vm.textColor, 
      'width': _vm.fullWidth, 
      'border': _vm.hasBorder ? ("1px solid " + _vm.borderColor) : '',
      'font-size': _vm.fontSize
    }),attrs:{"disabled":_vm.isDisabled},on:{"click":_vm.click}},[_vm._t("default"),(_vm.template === 'showMore')?_c('div',{staticClass:"arrow-down"}):_vm._e()],2):(_vm.htmlType === 'button')?_c('button',{staticClass:"button",class:[{'is-loading': _vm.isLoading}, _vm.type, _vm.getButtonClass],style:({ 
      'background-color': _vm.color, 
      color: _vm.textColor, 
      'width': _vm.fullWidth, 
      'border': _vm.hasBorder ? ("1px solid " + _vm.borderColor) : '',
      'font-size': _vm.fontSize
    }),attrs:{"disabled":_vm.isDisabled},on:{"click":_vm.click}},[_vm._t("default"),(_vm.template === 'showMore')?_c('div',{staticClass:"arrow-down"}):_vm._e()],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
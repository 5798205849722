































import { Component, Vue } from 'vue-property-decorator';
import Footer from '../components/NewFooter.vue';
import NewNavigation from '../components/NewNavigation.vue';
import {AppModule} from '@/utils/storemodules';
import { Meta } from '@/utils/decorators';
import { setLangForMeta } from '@/utils/helpers';

@Component({
  components: {
    Footer,
    NewNavigation,
  },
})

export default class PageNotFound extends Vue {

  private get isMobile() {
    return AppModule.isMobile;
  }

  private get isTablet() {
    if (AppModule.isTablet && window.innerWidth < 968 || AppModule.isMobile) {
      return true;
    }
  }

  private goHome() {
    this.$router.push('/');
  }

  @Meta
  private metaInfo() {
    return {
      title: this.$t('page-not-found-title'),
      link: [
        { rel: 'canonical', href: location.protocol + '//' + location.host + location.pathname },
      ],
      meta: [
        { hid: 'title', name: 'title', content: this.$t('page-not-found-title')},
        { hid: 'description', name: 'description', content: this.$t('page-not-found-msg2')},
        { property: 'fb:admins', content: '100001288015151' },
        { hid: 'og:title', property: 'og:title',
          content: this.$t('page-not-found-title')},
        { hid: 'og:description', property: 'og:description',
          content: this.$t('page-not-found-msg2') },
        { hid: 'og:type', property: 'og:type', content: 'page' },
        { hid: 'og:url', property: 'og:url', content: location.protocol + '//' + location.host + location.pathname },
        { hid: 'og:image', property: 'og:image',
          content: this.$BASE_URL + 'img/404.png' },
        { hid: 'og:image:alt', property: 'og:image:alt',
          content: '404'},
        { hid: 'og:locale', property: 'og:locale',
          content: setLangForMeta(this.$route.params.locale || this.$i18n.locale)},
      ],
    };
  }
}

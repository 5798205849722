




























import { Vue, Component, Prop } from 'vue-property-decorator';
import CardTag from '@/components/presentational/TicketTag.vue';
import NewButton from '@/components/presentational/NewButton.vue';

@Component({
  components: {
    CardTag,
    NewButton,
  },
})
export default class WeatherRecommend extends Vue {
  @Prop({default: null}) private cardData: any;

}

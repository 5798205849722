import Vue from 'vue';
import { Prop, Watch } from 'vue-property-decorator';
import Debug from 'debug';
import { Mixin } from 'vue-mixin-decorator';
import { NBookingModule, AppModule, ProductModule} from '@/utils/storemodules';
import { IProductCategory, LanguagesType } from '@/types/db/product-category';
import { createImageproxyUrl } from '@/utils/pictures';
import { getLinkedDonations } from '@/api/donation';
import { disableQuasarStyling, enableQuasarStyling } from '@/utils/styles';
import { Loading } from 'quasar';
const debug = Debug('smeetz:acompte');
enum RetailOptionType {
  Default_Retail_Option = 100,
}
// Map retail's types with their label
const typesLabels: Record<number, string> = {
  1: 'Material',
  2: 'Size',
  3: 'Colour',
  4: 'Style',
} as const;
@Mixin
export default class Donation extends Vue {
  // Does the donation have a long description
    private isLongDonationDescription: boolean = false;

    get donationCategory() {
        return ProductModule.donations.length > 0 ? ProductModule.donations[0] : null;
    }

    get donationName(): string {
        if (this.donationCategory && this.donationCategory.donationInfo && this.donationCategory.donationInfo.name) {
         let donationName = this.donationCategory.donationInfo.name;
         if (this.donationCategory.donationInfo.donationTranslationList
           && this.donationCategory.donationInfo.donationTranslationList.length > 0) {
             const translatedMessage = this.donationCategory.donationInfo.donationTranslationList.find(
               (info) => info.locale ===  this.$i18n.locale && info.field === 'name');
             if (translatedMessage && translatedMessage.message) {
               donationName = translatedMessage.message;
             }
         }
         return donationName;
        }
        return '';
    }

    get agreeMessageDonation(): string {
        if (this.donationCategory &&
            this.donationCategory.donationInfo &&
            this.donationCategory.donationInfo.agreeMessage) {
         let agreeMessage = this.donationCategory.donationInfo.agreeMessage;
         if (this.donationCategory.donationInfo.donationTranslationList
           && this.donationCategory.donationInfo.donationTranslationList.length > 0) {
             const translatedMessage = this.donationCategory.donationInfo.donationTranslationList.find(
               (info) => info.locale ===  this.$i18n.locale && info.field === 'agreeMessage');
             if (translatedMessage && translatedMessage.message) {
               agreeMessage = translatedMessage.message;
             }
         }
         return agreeMessage;
        }
        return '';
    }

    get donationDescription() {
        if (this.donationCategory && this.donationCategory.donationInfo && this.donationCategory.donationInfo.message) {
          let message = this.donationCategory.donationInfo.message;
          if (this.donationCategory.donationInfo.donationTranslationList
            && this.donationCategory.donationInfo.donationTranslationList.length > 0) {
              const translatedMessage = this.donationCategory.donationInfo.donationTranslationList.find(
                (info) => info.locale ===  this.$i18n.locale && info.field === 'b2cMessage');
              if (translatedMessage && translatedMessage.message) {
                message = translatedMessage.message;
              }
          }
          return message;
        }
    }

    get thankYouDonationMessage() {
      if (this.donationCategory &&
        this.donationCategory.donationInfo &&
        this.donationCategory.donationInfo.emailMessage) {
        let emailMessage = this.donationCategory.donationInfo.emailMessage;
        if (this.donationCategory.donationInfo.donationTranslationList
          && this.donationCategory.donationInfo.donationTranslationList.length > 0) {
            const translatedMessage = this.donationCategory.donationInfo.donationTranslationList.find(
              (info) => info.locale ===  this.$i18n.locale && info.field === 'emailMessage');
            if (translatedMessage && translatedMessage.message) {
              emailMessage = translatedMessage.message;
            }
        }
        return emailMessage;
      }
  }

    // Cut the donation description if it is long
    get shortDonationDescription(): string {
      if (this.donationDescription) {
        const textLimit = 131;
        // Useful when ticket.longInfo is too near to textLimit
        const cutTextLimit = textLimit - 20;

        // Check how long is pure text in string
        const pureText = this.donationDescription.replace(/<[^>]*>?/gm, '');

        const btnText = this.$t('new-booking-flow.order.see-more-button');
        const btnId = this.donationCategory?.categoryId + 'SeeMoreBtn';

        // If pure text > textLimit symbols and text is cutted
        if ( pureText.length > textLimit) {
          this.isLongDonationDescription = true;
          return this.donationDescription.slice(0, cutTextLimit) +
           '<a class="see-more-button" id="' + btnId + '"> ...' + btnText + '</a>' ;
        } else {
          this.isLongDonationDescription = false;
          return this.donationDescription;
        }
      } else {
        return '';
      }
    }

    public async checkDonation() {
      enableQuasarStyling();
      Loading.show();
      try {
        const categoryIds = NBookingModule.orderRecap.map((ticket) => ticket.categoryId);
        if (categoryIds.length > 0) {
          const ticketDonationList = await getLinkedDonations(categoryIds);
          if (ticketDonationList && ticketDonationList.length > 0) {
            ProductModule.setDonations(ticketDonationList);
          }
        }
      } catch (error) {
        setTimeout(() => {
          throw error;
        }, 0);
      }
      Loading.hide();
      disableQuasarStyling();
    }

}


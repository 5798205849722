





























































import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class Navigation extends Vue {

  private linksTitles: any = ['column1', 'column2', 'column3'];
  private links: any = {
    column1: 4,
    column2: 3,
    column3: 2,
  };

  private socials = [
    {
      site: 'instagram',
      link: 'https://www.instagram.com/smeetz_lausanne/',
      img: '/assets/img/rasterized/icons/instagram.png',
    },
    {
      site: 'facebook',
      link: 'https://www.facebook.com/smeetz.lausanne/',
      img: `/assets/img/rasterized/icons/facebook.png`,
    },
    {
      site: 'linkedin',
      link: 'https://www.linkedin.com/company/smeetz-sa/',
      img: '/assets/img/rasterized/icons/linkedin.png',
    },
  ];

  private handleMenuClick(event: any) {
    event.currentTarget.classList.toggle('is-active');
  }
}

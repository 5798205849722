







































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { email, required, maxLength } from 'vuelidate/lib/validators';
import { UserModule, NModalModule, NBookingModule } from '@/utils/storemodules';
import { ModalStatus } from '@/models/definitions';
import { userIdentityTracking } from '@/utils/tracking';
import { Status } from '@/utils/status';
import Debug from 'debug';
import { openLegalPage } from '@/utils/helpers';

const debug = Debug('smeetz:auth');

@Component({
  validations: {
    email: {
      required,
      email,
      maxLength: maxLength(255),
    },
    password: {
      required,
      maxLength: maxLength(255),
    },
  },
})
export default class LoginDialog extends Vue {
  private invalidLogin: boolean = false;
  private email: string = '';
  private password: string = '';
  private isLoading: boolean = false;
  private emitGoBack() {
    this.$emit('goBack');
  }

  get enableSubmit() {
    return !this.$v.$error && this.password;
  }

  // No signup in booking flow (Confusing clients)
  get canSignup() {
    return !NBookingModule.bookingId;
  }

  get isOpendLoginDialog() {
    return NBookingModule.isOpendLoginDialog;
  }

  private clearInvalid() {
    this.invalidLogin = false;
  }

  private async submit() {
    if (!this.enableSubmit) {
      return;
    }

    this.isLoading = true;

    // Submit logic goes here
    try {
      userIdentityTracking({ email: this.email });

      const answer = await UserModule.login({
        username: this.email,
        password: this.password,
      });
      this.$emit('goNextStep');
    } catch (err) {
      const response = err.response;
      this.isLoading = false;
      if (!response) {
        debug('No response');
        return;
      }

      if (Status[response.status] === 'Bad Request') {
        debug('Invalid credentials');
        this.invalidLogin = true;
        return;
      } else if (Status[response.status] === 'Unauthorized') {
        debug('Unconfirmed user, send him another email');
        await UserModule.sendConfEmail(this.email);
        UserModule.setUserEmail(this.email);
        NModalModule.setStatus(ModalStatus.NewEmailConfirmation);
        return;
      }

      debug('ooops Nothing here');
    }
    this.isLoading = false;
  }

  private forgottenPass() {
    NModalModule.setStatus(ModalStatus.NewForgotPassword);
  }

  private registerAccount() {
    NModalModule.setStatus(ModalStatus.NewRegistration);
  }

  private goToLegalPage() {
    openLegalPage.call(this);
  }

  private mounted() {
    // We use this Flag to go back to checkout in case we click in back arrow in booking nav
    NBookingModule.setOpenLoginDialog(true);
  }

  private beforeDestroy() {
    NBookingModule.setOpenLoginDialog(false);
  }

  @Watch('isOpendLoginDialog')
  private closeDialog() {
    if (!this.isOpendLoginDialog) {
      this.emitGoBack();
    }
  }
}

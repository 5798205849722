









































































































import { Vue, Component } from 'vue-property-decorator';
import { Mixins } from 'vue-mixin-decorator';
import { AppModule, NBookingModule as BookingModule, NBookingModule, ProductModule } from '@/utils/storemodules';
import { inIframe } from '@/utils/iframe';
import BookingDoneMixin from '@/mixins/BookingDone';
import LangMixin from '@/mixins/Lang';
import { BookingSteps } from '@/store/modules/newBooking/constants';

interface IMixinInterface extends LangMixin, BookingDoneMixin {}

export default class BookingNav extends Mixins<IMixinInterface>(BookingDoneMixin, LangMixin) {
  private BookingModule = BookingModule;
  private inIframe = inIframe();

  get showBackButton() {
    if ( ProductModule.hasAddonsInASeparateStep && ProductModule.hideAddons === false ) {
      return true;
    }
    if ( BookingModule.getPreselectObject ) {
      return this.currentStep !== 1 || BookingModule.isLastStep;
    }
    return !(BookingModule.isFirstStep || BookingModule.isLastStep);
  }

  get currentStep(): number {
    return BookingModule.bookingStep;
  }

  get steps(): string[] {
    return BookingModule.bookingSteps;
  }

  // Check whether we are in cart on order page
  get cartView(): boolean {
    if (inIframe() && AppModule.isCartWidget) {
      return BookingModule.bookingStep === BookingSteps.Order;
    }

    return false;
  }

  get showBasket(): boolean {
    return BookingModule.showBasket;
  }

  private stepBack() {
    // In case we are in the login dialog we go back to the checkout
    if (NBookingModule.isOpendLoginDialog) {
      return NBookingModule.setOpenLoginDialog(false);
    }
    return BookingModule.stepBack();
  }

  // Return true if basket is empty
  private get isEmpty(): boolean {
    const { isFree, total } = BookingModule.recapInfo;
    return !isFree &&  total === 0;
  }

  // Go directly to step number
  private stepTo(step: number) {
    const confirmationStep = (BookingSteps.Confirmation as number) + 1;
    const paymentStep = (BookingSteps.Payment as number) + 1;

    if (this.isEmpty || step === confirmationStep || step === paymentStep || BookingModule.isLastStep) { return; }

    BookingModule.stepTo(--step);
  }
}

import Vue from 'vue';
import store from '@/store';
import Router from 'vue-router';
import Timeout from 'await-timeout/dist/es5';
import Home from '@/views/Home.vue';
import NewSite from '@/views/NewSite.vue';
import QSite from '@/views/QSite.vue';
import Discover from '@/views/Discover/index.vue';
import Register from '@/views/Register.vue';
import Login from '@/views/Login.vue';
import Attend from '@/views/Attend/Attend.vue';
import VerifyEmail from '@/views/VerifyEmail.vue';
import ResetPass from '@/views/ResetPass.vue';
import UserSpace from '@/views/UserSpace/Index.vue';
// import Booking from '@/views/Booking/Index.vue';
// import PostBooking from '@/views/PostBooking/Index.vue';
import DeleteAccount from '@/views/DeleteAccount.vue';
import Product from '@/views/Product/Index.vue';
import NewBooking from '@/views/NewBooking/Index.vue';
import GroupBooking from '@/views/GroupBooking/Index.vue';
import ProductList from '@/views/ProductList/Index.vue';
import CalendarProducts from '@/views/CalendarProducts/Index.vue';
import BookingQuestions from '@/views/BookingQuestions/Index.vue';
import DynamicPricing from '@/views/Dynamic/Index.vue';
import TicketConf from '@/views/TicketConfirmation/Index.vue';
import Redirect from '@/views/Redirect.vue';
import Unsup from '@/views/Unsup/Index.vue';
import Debug from 'debug';
import { redirectToOldSite } from './utils/platform';
import { inIframe } from './utils/iframe';
import { hideHubspotChat, showHubspotChat, defineLanguage } from './utils/helpers';
import Region from '@/views/Region/Index.vue';
import Organizer from '@/views/Organizer/Index.vue';
import SearchPage from '@/views/SearchPage/Index.vue';
import i18n from './locales';
import { isAuthRouteHelper } from './utils/auth';
import { UserModule } from './utils/storemodules';
import { RouteNames } from './models/enums';
import ContactSupport from './views/ContactSupport.vue';
import PageNotFound from './views/PageNotFound.vue';
import OrgUserSpace from './views/OrganizerUserSpace/Index.vue';
import BookingSummary from './views/OrganizerUserSpace/BookingSummary.vue';
import RefundBooking from './views/OrganizerUserSpace/components/RefundBooking/Index.vue';
import RefundConfirmation from './views/OrganizerUserSpace/components/RefundBooking/RefundConfirmation.vue';
import UserBookings from '@/views/UserSpace/Bookings.vue';
import UnsubscribeCustomer from '@/views/Unsubscribe/Index.vue';

const debug = Debug('smeetz:dynamic');

Vue.use(Router);

const baseUrl = process.env.BASE_URL;

// tslint:disable-next-line
console.log('Base url is', baseUrl);
const router = new Router({
  mode: 'history',
  // Force the base url to '/' for product builds
  base: baseUrl === '/product/' ? '/' : baseUrl,
  routes: [
    // {
    //   path: '/',
    //   name: 'home',
    //   // component: Home,
    //   component: QSite,
    //   children: [
    //     // {
    //     //   path: '/',
    //     //   beforeEnter: redirectGuard,
    //     // },
    //     {
    //       path: '/user-space',
    //       component: UserSpace,
    //       beforeEnter: redirectGuard,
    //     },
    //     {
    //       path: '/discover',
    //       component: Discover,
    //       beforeEnter: redirectGuard,
    //     },
    //     {
    //       path: '/attend',
    //       component: Attend,
    //     },
    //     {
    //       path: '/verify-email',
    //       component: VerifyEmail,
    //     },
    //     {
    //       path: '/reset-password',
    //       component: ResetPass,
    //     },
    //     {
    //       path: '/delete-account',
    //       component: DeleteAccount,
    //     },
    //   ],
    // },
    {
      path: '/register',
      name: 'register',
      component: Register,
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/:locale?/org-user/:orgId',
      name: RouteNames.OrganizerUserSpace,
      component: OrgUserSpace,
      beforeEnter: (to, from, next) => {
        let locale = to.params.locale;
        if (!['en', 'fr', 'de', 'it', 'nl', 'es', 'hu'].includes(locale)) {
          locale = i18n.locale;
          const urlWithLocale = `/${locale}` + to.fullPath;
          // next(urlWithLocale);
          next({path: urlWithLocale, replace: true});
        }
        next();
      },
      children: [
        {
          path: 'userbookings',
          component: UserBookings,
          name: RouteNames.UserBookings,
        },
        {
          path: 'userbookings/:bookingId',
          component: BookingSummary,
          name: RouteNames.BookingSummary,
        },
        {
          path: 'userbookings/refund-booking/:bookingId',
          component: RefundBooking,
          name: RouteNames.RefundBooking,
          children: [
            {
              path: 'confirmation',
              component: RefundConfirmation,
              name: RouteNames.RefundConfirmation,
            },
          ],
        },
      ],
    },
    // {
    //   path: '/about',
    //   name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ './views/About.vue'),
    // },
    // {
    //   /**
    //    * Booking flow path parmeters
    //    * @param id: Product id
    //    * @param short_name: Product short url
    //    *
    //    * Booking flow query parameters
    //    * @param from: The starting date of the availabilities
    //    * @param to: The ending date of the availabilities (currently not used)
    //    * @param utm_source: The referrer site that brought us to the booking flow.
    //    *                    In case of the booking widget, It's the site embedding the widget.
    //    * @param startDate: The start date time of the date that we want to open the booking flow at.
    //    *                   Combined with endDate, this would allow us to open the booking flow on
    //    *                   particular date.
    //    * @param endDate: the end date time of the date that we want to open the booking flow at.
    //    * @param code: A comma separated list of codes that are needed to show hidden with links tickets.
    //    * @param cat: Acomma separated list of categories that should be pre-selected.
    //    *             this works for flow 0 products only.
    //    */
    //   path: '/product/:id/booking/:short_name',
    //   component: Booking,
    // },
    // {
    //   // path visited after user has bought tickets
    //   path: '/product/booking/:status',
    //   component: PostBooking,
    // },
    // {
    //   path: '/product/list/:ids',
    //   component: ProductList,
    // },
    {
      path: '/:locale(en|de|fr|it|nl|es|hu)?/product/',
      component: NewSite,
      // Before moving to product or booking page we need to check that langauage is present in url.
      // If locale isn't set, we will set it on 'en' by default.
      // If previous route contain locale, we set it
      beforeEnter: (to, from, next) => {
        if (to.params.locale !== 'en'
          && to.params.locale !== 'de'
          && to.params.locale !== 'fr'
          && to.params.locale !== 'it'
          && to.params.locale !== 'nl'
          && to.params.locale !== 'es'
          && to.params.locale !== 'hu'
          && !from.params.locale) {

          // TODO the following line has no effect. Since using path with params, cancels the params.
          // I will remove this when I confirm what I am saying from vue router docs.
          to.params.locale = 'en';

          const urlWithLocale = to.fullPath.replace('/product/', `/${i18n.locale}/product/`);

          // Using replace so that we make sure that when the user clicks on back
          // we don't take him to the previous path (path that triggered this navigation guard).
          // This avoid forcing the user to click on back twice.
          // It also helps so that the widget doesn't block the organiser site.
          return next({ path: urlWithLocale, replace: true });
        }
        next();
      },
      children: [
        {
          path: 'cproducts/:groupId',
          component: CalendarProducts,
        },
        {
          path: 'conf/:pss',
          component: TicketConf,
        },
        {
          path: ':id/gbooking',
          component: GroupBooking,
        },
        {
          name: RouteNames.Product,
          path: ':short_name',
          component: Product,
          // beforeEnter: redirectGuard,
        },
        {
          path: 'list/:ids',
          component: ProductList,
        },
        {
          name: RouteNames.ChannelProduct,
          path: ':channel/:short_name',
          component: Product,
          // beforeEnter: redirectGuard,
        },
        {
          /**
           * Booking flow path parmeters
           * @param id: Product id
           * @param short_name: Product short url
           *
           * Booking flow query parameters
           * @param from: The starting date of the availabilities
           * @param to: The ending date of the availabilities (currently not used)
           * @param utm_source: The referrer site that brought us to the booking flow.
           *                    In case of the booking widget, It's the site embedding the widget.
           * @param startDate: The start date time of the date that we want to open the booking flow at.
           *                   Combined with endDate, this would allow us to open the booking flow on
           *                   particular date.
           * @param endDate: the end date time of the date that we want to open the booking flow at.
           * @param code: A comma separated list of codes that are needed to show hidden with links tickets.
           * @param cat: Acomma separated list of categories that should be pre-selected.
           *             this works for flow 0 products only.
           */
          name: RouteNames.Booking,
          path: ':id/booking/:short_name',
          component: NewBooking,
        },
        {
          name: RouteNames.ChannelBooking,
          path: ':channel/:id/booking/:short_name',
          component: NewBooking,
        },
        {
          path: ':id/booking/:short_name/:status',
          component: NewBooking,
        },
      ],
    },
    {
      path: '/booking/questions',
      component: BookingQuestions,
    },
    {
      path: '/dynamic',
      component: DynamicPricing,
    },
    // The 'new' path is just for current transition to new
    // site
    {
      path: '/new',
      component: NewSite,
      children: [
        {
          path: '/new/product/:short_name',
          component: Product,
        },
        {
          path: '/new/product/:id/booking/:short_name',
          component: NewBooking,
        },
        {
          path: '/new/product/:id/booking/:short_name/:status',
          component: NewBooking,
        },
        {
          path: '/new/region',
          component: Region,
        },
        {
          path: '/new/organizer/:locale(en|de|fr|it|nl|es|hu)?/:organizer_slug',
          component: Organizer,
          // Before moving to organizer page we need to check that langauage is present in url.
          // If locale isn't set, we will set it on 'en' by default
          beforeEnter: (to, from, next) => {
            if (to.params.locale !== 'en'
              && to.params.locale !== 'de'
              && to.params.locale !== 'fr'
              && to.params.locale !== 'it'
              && to.params.locale !== 'nl'
              && to.params.locale !== 'es'
              && to.params.locale !== 'hu'
              && !from.params.locale) {
              to.params.locale = 'en';
              const urlWithLocale = to.fullPath.replace('organizer/', `organizer/${i18n.locale}/`);
              next(urlWithLocale);
            }
            next();
          },
        },
        {
          path: '/new/search/:locale(en|de|fr|it|nl|es|hu)?',
          component: SearchPage,
          // Before moving to organizer page we need to check that langauage is present in url.
          // If locale isn't set, we will set it on 'en' by default
          beforeEnter: (to, from, next) => {
            if (to.params.locale !== 'en'
              && to.params.locale !== 'de'
              && to.params.locale !== 'fr'
              && to.params.locale !== 'it'
              && to.params.locale !== 'nl'
              && to.params.locale !== 'es'
              && to.params.locale !== 'hu'
              && !from.params.locale) {
              to.params.locale = 'en';
              const urlWithLocale = to.fullPath.replace('search', `search/${i18n.locale}`);
              next(urlWithLocale);
            }
            next();
          },
        },
      ],
    },
    {
      path: '/:locale?',
      component: QSite,
      beforeEnter: (to, from, next) => {
        let locale = to.params.locale;
        if (!['en', 'fr', 'de', 'it', 'nl', 'es', 'hu'].includes(locale)) {
          locale = i18n.locale;
          const urlWithLocale = `/${locale}` + to.fullPath;
          // next(urlWithLocale);
          next({path: urlWithLocale, replace: true});
        }
        next();
      },
      children: [
        {
          path: 'search',
          component: SearchPage,
        },
        {
          path: 'region',
          component: Region,
        },
        {
          path: 'org/:organizer_slug',
          component: Organizer,
        },
        // {
        //   path: '/',
        //   beforeEnter: redirectGuard,
        // },
        {
          path: 'user-space',
          component: UserSpace,
          name: RouteNames.UserSpace,
        },
        {
          path: 'user-space/userbookings/:bookingId',
          component: BookingSummary,
          name: RouteNames.BookingSummary,
        },
        {
          path: 'user-space/userbookings/refund-booking/:bookingId',
          component: RefundBooking,
          name: RouteNames.RefundBooking,
          children: [
            {
              path: 'confirmation',
              component: RefundConfirmation,
              name: RouteNames.RefundConfirmation,
            },
          ],
        },

        {
          path: '/discover',
          component: Discover,
          beforeEnter: redirectGuard,
        },
        {
          path: 'attend',
          component: Attend,
        },
        {
          path: 'verify-email',
          component: VerifyEmail,
        },
        {
          path: 'reset-password',
          component: ResetPass,
        },
        {
          path: 'delete-account',
          component: DeleteAccount,
        },
        {
          path: 'unsup',
          component: Unsup,
        },
        {
          path: 'redirect',
          component: Redirect,
          name: 'redirect',
        },
        {
          path: 'unsubscribe',
          component: UnsubscribeCustomer,
          name: RouteNames.UnsubscribeCustomer,
        },
        {
          path: 'contact-support',
          component: ContactSupport,
          name: RouteNames.ContactSupport,
        },
        {
          path: '',
          component: Region,
          name: RouteNames.Home,
        },
      ],
    },
    {
      path: '*',
      component: QSite,
      children: [
        {
          path: '*',
          component: PageNotFound,
          name: RouteNames.PageNotFound,
        },
      ],
    },
  ],
});

router.beforeEach(async (to, from, next) => {

  try {
    await store.dispatch('auth/attemptLogin');
  } catch (err) {
    debug(JSON.stringify(err));
  }
  // Handles old product page routes format
  const langs = ['en', 'it', 'fr', 'de', 'nl', 'es', 'hu'];

  for (const lang of langs) {
    // if (to.fullPath.includes(`/product/${lang}`))
    if (to.fullPath.endsWith(`/product/${lang}`) || to.fullPath.includes(`/product/${lang}/`)) {
      const urlWithLocale = to.fullPath.replace(`/product/${lang}/`, `/${i18n.locale}/product/`);
      return next({ path: urlWithLocale, replace: true });
    }
  }

  // Add language to router if it is product or organizer pages
  if (to.path.includes('/product/') || to.path.includes('/org/') || to.path.includes('/search')) {
    // tslint:disable-next-line:max-line-length
    const currentMainRoute = to.path.includes('/product/') ? 'product' : to.path.includes('/org/') ? 'org' : 'search';
    // if there are locale in 'from' route, and there are no locale in 'to' route
    if (to && from && !to.params.locale && from.params.locale) {
      // set 'to' locale to 'from' locale
      to.params.locale = from.params.locale;
      // replace url to containe locale
      const urlWithLocale = to.fullPath.replace(`${currentMainRoute}`, `${from.params.locale}/${currentMainRoute}`);
      // go to new url
      // router.push(urlWithLocale);
      router.push({path: urlWithLocale, replace: true});
    } else {
      next();
    }
  } else if (isAuthRouteHelper(to.name || '') && !UserModule.isLogedIn) {
    next(
      {
        path: `/${i18n.locale}/`,
        query: {
          redirect: to.fullPath,
          auth: 'true',
        },
      },
    );
  } else {
    next();
  }

  // hide hubspot chat accordingly
  await Timeout.set(3000);
  if (inIframe()) {
    hideHubspotChat();
  } else if (/^\/(product|new\/product|dynamic|booking\/questions)/.test(to.path)) {
    debug('Hiding hubspot chat');
    hideHubspotChat();
  } else {
    showHubspotChat();
    debug('Showing hubspot chat');
  }

  return;
});

/**
 * A navigation guard that redirects the user to old site on uninmplemented pages.
 * @param to
 * @param from
 * @param next
 */
function redirectGuard(to: any, from: any, next: any) {
  redirectToOldSite(to.fullPath);
}

export default router;

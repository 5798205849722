import { IUserBookingsCondition, IUserBookingsPagedCondition } from '@/models/store/user';
import dayjs from 'dayjs';

/**
 * Returns a GraphQl Query that will be used to get the data of a given booking operation
 */
export function getBookingDetailsQuery(bookingId: number) {
  return `
        query {
          ProductSlotBookingRead(id: "${bookingId}") {
            Id
            Reference
            FirstName
            LastName
            Email
            Phone
            InsertDateTime
            State
            GoerToken
            Currency
            ProductSlotStateCount
            ProductSlotStateList {
              Id
              PriceName,
              Seat,
              PaymentStatus,
              BookingStatus,
              PriceValue,
              StartDateTime,
              EndDateTime,
              StartDateTimeTz,
              EndDateTimeTz,
              ProductSlotCategoryRead{
                Id,
                CategoryName,
                Type,
                ProductId,
                IsUserCancellable,
                ProductRead{
                  Id,
                  Name,
                  GroupRead {
                    Id
                    Name
                    brandingList: BrandingList {
                      groupId: GroupId
                      email: EmailAddress
                      phoneNumber: PhoneNumber
                      city: City
                    }
                  }
                }
              }
            }
          }
        }`;
}

/**
 * Returns a GraphQl Query that will be used to get a list of bookings of a given user
 * It can be used also if the user is in an Organizer User Space
 */
export function getUserBookingsQuery(userBookingsCondition: IUserBookingsPagedCondition) {
  const emailCondition: string = `~and(Email,in,${userBookingsCondition.emails})`;
  const organizerIdCondition: string = userBookingsCondition.organizerId ? `~and(SellerGroupId,eq,${userBookingsCondition.organizerId})` : '';
  const whereClause: string = 'where: "(State,eq,3)' + emailCondition + organizerIdCondition + '"';
  // how many rows of bookings to be skipped
  const offset = (userBookingsCondition.currentPage - 1) * userBookingsCondition.rowsPerPage;
  return `
        query {
          # UserBookings are brought based on:
          # 1- the user list of emails
          # 2- and optionally the organizerIdd
          # 3- and the booking state is 3 (confirmed)
          # 4- all sorted by InsertDateTime
          ProductSlotBookingList(${whereClause}, limit:${userBookingsCondition.rowsPerPage}, offset:${offset}, sort:"-InsertDateTime") {
            Id
            Email
            UserId
            State
            SellerGroupId
            InsertDateTime
            GroupRead
            {
              Id,
              Name,
              SlugName
            }
            ProductSlotStateList {
              Id
              StartDateTime,
              ProductSlotCategoryRead{
                Id,
                ProductRead{
                  Id,
                  Name,
                  ProductPictureList {
                    PictureRead {
                      Name,
                      IsPrimary,
                      RealUrl
                    }
                  }
                  LocationRead {
                    City
                    Country
                  }
                }
              }
            }
          }
          ProductSlotBookingCount(${whereClause})
        }
        `;
}

/**
 * Returns a GraphQl Query that will be used to count the number of a user bookings by Organizer
 */
export function countUserBookingsQuery(userBookingsCondition: IUserBookingsCondition) {
  const dateCondition: string = `~and(StartDateTime,ge,${dayjs().format('YYYY-MM-DD')})`;
  const emailCondition: string = `~and(Email,in,${userBookingsCondition.emails})`;
  const organizerIdCondition: string = userBookingsCondition.organizerId ? `~and(SellerGroupId,eq,${userBookingsCondition.organizerId})` : '';
  const whereClause: string = 'where: "(State,eq,3)' + dateCondition + emailCondition + organizerIdCondition + '"';
  return `
        query {
          ProductSlotBookingCount(${whereClause})
        }
        `;
}

/**
 * Returns a GraphQl Query that will be used to count the number of a user bookings by Organizer
 */
export function countUserBookingsQueryThruPSS(userBookingsCondition: IUserBookingsCondition, upcoming = true) {
  const dateCondition: string = `~and(StartDateTime,${upcoming ? 'ge' : 'le'},${dayjs().format('YYYY-MM-DD')})`;
  const emailCondition: string = `~and(Email,in,${userBookingsCondition.emails})`;
  const organizerIdCondition: string = userBookingsCondition.organizerId ? `~and(ProductOwnerGroupId,eq,${userBookingsCondition.organizerId})` : '';
  const whereClause: string = 'smtz_where: "(BookingStatus,eq,3)' + dateCondition
    + emailCondition + organizerIdCondition + '"';
  const fields = 'fields:"BookingId,StartDateTime,BookingStatus,ProductOwnerGroupId,Email"';
  return `
        query {
          ProductSlotStateGroupBy(${fields}, ${whereClause})
          {
            BookingId
          }
        }
        `;
}

/**
 * Returns a GraphQl Query that will get PSS grouped by Booking
 * This query is going to be as first step
 * Second step would be getting the rest of the booking info (product name, picture...)
 * Why 2 steps? Because pagination is based on StartDateTime which is inside PSS
 * and we need to paginate Bookings not PSS
 */
export function getUserPSSGroupedByBookingQuery(userBookingsCondition: IUserBookingsPagedCondition, upcoming = true) {
  const dateCondition: string = `~and(StartDateTime,${upcoming ? 'ge' : 'le'},${dayjs().format('YYYY-MM-DD')})`;
  const emailCondition: string = `~and(Email,in,${userBookingsCondition.emails})`;
  const organizerIdCondition: string = userBookingsCondition.organizerId ? `~and(ProductOwnerGroupId,eq,${userBookingsCondition.organizerId})` : '';
  const whereClause: string = 'smtz_where: "(BookingStatus,eq,3)' + dateCondition
    + emailCondition + organizerIdCondition + '"';
  const fields = 'fields:"BookingId,StartDateTime,PaymentStatus,ProductOwnerGroupId,Email"';
  // how many rows of bookings to be skipped
  const offset = (userBookingsCondition.currentPage - 1) * userBookingsCondition.rowsPerPage;
  return `
        query {
          ProductSlotStateGroupBy(${fields}, ${whereClause}, limit:${userBookingsCondition.rowsPerPage}, offset:${offset},
          sort:"StartDateTime") {
          count
          StartDateTime
          BookingId
        }

        }
        `;

}

/**
 * Returns a GraphQl Query that will get a list of Bookings using their Ids
 */
export function getUserBookins_byBookingIdsQuery(bookingIds: string[]) {
  const whereClause: string = 'where: "(Id,in,' + bookingIds + ')~and(State,eq,3)"';
  return `
        query {
          ProductSlotBookingList(${whereClause}) {
          Id
          GoerToken
          Email
          UserId
          State
          SellerGroupId
          InsertDateTime
          GroupRead
          {
            Id,
            Name,
            SlugName
          }
          ProductSlotStateList {
              Id
              StartDateTime,
              ProductSlotCategoryRead{
                Id,
                ProductRead{
                  Id,
                  Name,
                  ProductPictureList {
                    PictureRead {
                      Name,
                      IsPrimary,
                      RealUrl
                    }
                  }
                  LocationRead {
                    City
                    Country
                  }
                }
              }
            }
        }
      }
        `;
}

// Returns the GraphQl Query that gets the organizer data needed for adyen integration.
export function getOrgInfoForAdyenQuery(orgId: number) {
  return `
  query {
    GroupRead(id: "${orgId}") {
      LegalEntityType
      Name
      RegistrationNumber
      brandingList: BrandingList {
        groupId: GroupId
        email: EmailAddress
        phoneNumber: PhoneNumber
        city: City
      }
    }
  }
  `;
}
// Returns the GraphQl Query that gets the organizer data.
export function getOrgInfo(orgId: number) {
  return `
  query {
    GroupRead(id: "${orgId}") {
      Id
      Name
      shippingMethods: ShippingMethods
      brandingList: BrandingList {
        GroupId
        CompanyName
        WidgetHeaderColor
        SmeetzPrimaryColor
      }
    }
  }
  `;
}

// Returns the GraphQl Query that gets the organizer data.
export function getOrganizerInfoByIdQuery(orgId: number) {
  return `
  query {
    groupInfo: GroupRead(id: "${orgId}") {
      id: Id
      conversionId: ConversionId
      conversionLabel: ConversionLabel
      fbPixelId: FbPixelId
      googleTrackingId: GoogleTrackingId
      paymentProcessor: PaymentProcessor
      dateFormatB2c: DateFormatB2c
      timeFormatB2c: TimeFormatB2c
      weekStartB2c: WeekStartB2c
      branding: BrandingList {
        id: Id
        companyName: CompanyName
        description: Description
        smeetzPrimaryColor: SmeetzPrimaryColor
        widgetButtonColor: WidgetButtonColor
        widgetFont: WidgetFont
        widgetHeaderColor: WidgetHeaderColor
        widgetNavigationColor: WidgetNavigationColor
        widgetTextColor: WidgetNavigationColor
        widgetTitleColor: WidgetTitleColor
        emailAddress: EmailAddress
        facebookWebsite: FacebookWebsite
        instagramWebsite: InstagramWebsite
        linkedinWebsite: LinkedinWebsite
        phoneNumber: PhoneNumber
        website: Website
        youtubeWebsite: YoutubeWebsite
      }
    }
  }
`;
}
// Returns the GraphQl Query that gets the shipping tickets data.
export function getShippingTicketsQuery(pscIds: number[]) {
  const whereClause: string = 'where: "(Id,in,' + pscIds + ')"';
  return `
  query {
    ProductSlotCategoryList(${whereClause}){
      categoryId: Id
      categoryName: CategoryName
      timeslots: TimeSlotList{
        id: Id
        bookable: Bookable
      }
      price: ProductSlotPriceList{
        priceId: Id
        name: PriceName
      }
      isShipping: IsShipping
    }
  }
  `;
}






import { goToHome } from '@/utils/auth';
import { Vue, Component } from 'vue-property-decorator';
import { getUrlFromApiPath } from '@/api/common';

@Component({})
export default class Redirect extends Vue {
  private async created() {
    const { path, apipath } = this.$route.query;
    let redirectUrl = null;

    if (apipath) {
      try {
        const res = await getUrlFromApiPath(apipath as string);

        redirectUrl = res && res.url ? res.url : null;
      } catch (e) {
        goToHome(this.$router);
      }
    } else if (path) {
      redirectUrl = path;
    }

    if (redirectUrl) {
      window.open(redirectUrl as string, '_self');
    } else {
      goToHome(this.$router);
    }
  }
}






























































































































































































import { Component, Vue, Watch} from 'vue-property-decorator';
import Datepicker from 'vuejs-datepicker';
import VueSlider from 'vue-slider-component';

@Component({
  components: {
    Datepicker,
    VueSlider,
  },
})
export default class DiscoverFilter extends Vue {

  public $refs!: {
    fromDateRef: any,
    toDateRef: any,
  };

  // Constants used for UI. May move to store in future
  private maxPrice: number = 250;
  private pricesList: any = [
    { id: 'free', text: 'Free', value: 0 },
    { id: 'under10', text: 'Under 10', value: 10 },
    { id: 'under50', text: 'Under 50', value: 50 },
    { id: 'above50', text: 'Above 50', value: 250 },
  ];
  // values for ui
  private disabledDates = {
    to: new Date(),
  };

  // Values variables
  private fromDate: string = '';
  private toDate: string = '';
  private chosenPrice: number = 1000;
  private priceRange: [number, number] = [0, 250];

  get fromDateVal() {
    if (!this.fromDate) {
      return '';
    }
    if (!this.$refs.fromDateRef) {
      return '';
    }
    return this.$refs.fromDateRef.utils.formatDate(new Date(this.fromDate), 'dd MMM yyyy');
  }

  get toDateVal() {
    if (!this.toDate) {
      return '';
    }
    return this.$refs.toDateRef.utils.formatDate(new Date(this.toDate), 'dd MMM yyyy');
  }

  // Watcher for price range change
  @Watch('chosenPrice')
  private onPriceChanged(val: number, oldVal: number) {
    if (val > 50) {
      this.priceRange = [50, 250];
      return;
    }

    this.priceRange = [0, val];
  }

  private created() {
    const today = new Date();
    this.disabledDates.to.setDate(today.getDate() - 1);
  }

  private mounted() {
    this.fromDate = String(new Date());
  }

  private openDatePicker(dateElement: number): void {
    let component: any;
    if (dateElement === 1) {
      component = this.$refs.fromDateRef;
    } else {
      component = this.$refs.toDateRef;
    }
    component.showCalendar();
    component.$el.querySelector('input').focus();
  }

  private close(): void {
    this.$emit('close');
  }
}



















import { Vue, Component, Watch } from 'vue-property-decorator';
import { NBookingModule, NModalModule } from '@/utils/storemodules';
import { ModalStatus } from '@/models/definitions';
import {inIframe, sendPageReload} from '@/utils/iframe';

@Component
export default class BookingTimerEnd extends Vue {

  private hideModal() {
    NModalModule.setStatus(ModalStatus.Hidden);
    // TODO: clear basket after implementation such functionality
    // NBookingModule.backToStart();
    // Reload the organizer site if we are inside an iframe.
    if (inIframe()) {
      sendPageReload();
      return;
    }
    document.location.reload(true);
  }

}


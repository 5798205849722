




























































import { Vue, Component } from 'vue-property-decorator';
import Hero from '@/components/Hero.vue';
import SmallBox from './presentational/SmallBox.vue';
import ContainerSmeetzItem from '@/components/ContainerSmeetzItem.vue';
import Newsletter from '@/components/Newsletter.vue';
import ModalOverlay from '@/components/modal/ModalOverlay.vue';
import { ModalModule } from '@/utils/storemodules';
import { ModalStatus } from '@/models/definitions';

import {constants} from '@/config/constants.ts';

@Component({
  components: {
    Hero,
    SmallBox,
    ContainerSmeetzItem,
    Newsletter,
    ModalOverlay,
  },
  metaInfo() {
    return {
      title: this.$t('seo.title-attend') as string,
      description: this.$t('seo.description-attend') as string,
    };
  },
})
export default class Attend extends Vue {
  public isModalVisible: boolean = false;

  private page: string = 'attend';
  private loggedIn: boolean = false;
  private buttonHref: string = '';
  private buttonText: string = '';
  private image: string = '/assets/img/rasterized/original/attend/attend_header.jpg';
  private dataSmallBoxes = [
    {
      key: 'first',
      title: 'attend.item1-1',
      text: 'attend.item2-1',
    },
    {
      key: 'second',
      title: 'attend.item1-2',
      text: 'attend.item2-2',
    },
    {
      key: 'third',
      title: 'attend.item1-3',
      text: 'attend.item2-3',
    },
    {
      key: 'fourth',
      title: 'attend.item1-4',
      text: 'attend.item2-4',
    },
  ];
  private containerSmeetzFirst = {
    title: 'attend.item1-1',
    text1: 'attend.item-text1-1',
    text2: 'attend.item-text2-1',
    img: {
      src: '/assets/img/rasterized/original/attend/attend_discover.png',
      srcset_1440: '/assets/img/rasterized/1440px/attend/attend_discover.png 1440w',
      srcset_1080: '/assets/img/rasterized/1080px/attend/attend_discover.png 1080w',
      srcset_750: '/assets/img/rasterized/750px/attend/attend_discover.png 750w',
      srcset_640: '/assets/img/rasterized/640px/attend/attend_discover.png 640w',
      alt: 'Discover activities on Smeetz.',
    },
    button: {
      href: '/discover',
      text: 'button.discover',
      color: 'primary',
    },
  };
  private containerSmeetzSecond = {
    title: 'attend.item1-2',
    text1: 'attend.item-text1-2',
    text2: 'attend.item-text2-2',
    img: {
      src: '/assets/img/rasterized/original/attend/attend_organise_illustration.png',
      srcset_1440: '/assets/img/rasterized/1440px/attend/attend_organise_illustration.png 1440w',
      srcset_1080: '/assets/img/rasterized/1080px/attend/attend_organise_illustration.png 1080w',
      srcset_750: '/assets/img/rasterized/750px/attend/attend_organise_illustration.png 750w',
      srcset_640: '/assets/img/rasterized/640px/attend/attend_organise_illustration.png 640w',
      alt: 'Organize private activities on Smeetz.',
    },
    sectionClass: 'grey-container',
    reverse: true as boolean,
  };
  private containerSmeetzThird = {
    title: 'attend.item1-3',
    text1: 'attend.item-text1-3',
    text2: 'attend.item-text2-3',
    img: {
      src: '/assets/img/rasterized/original/attend/attend_book_illustration.png',
      srcset_1440: '/assets/img/rasterized/1440px/attend/attend_book_illustration.png 1440w',
      srcset_1080: '/assets/img/rasterized/1080px/attend/attend_book_illustration.png 1080w',
      srcset_750: '/assets/img/rasterized/750px/attend/attend_book_illustration.png 750w',
      srcset_640: '/assets/img/rasterized/640px/attend/attend_book_illustration.png 640w',
      alt: 'Book activities on the go with Smeetz.',
    },
  };
  private containerSmeetzFourth = {
    title: 'attend.item1-4',
    text1: 'attend.item-text1-4',
    text2: 'attend.item-text2-4',
    sectionClass: 'grey-container',
    sectionBackground: '/assets/img/rasterized/original/attend/attend_enjoy_illustration.png',
    button: {
      href: '',
      text: '',
      color: 'secondary',
    },
  };

  @ModalModule.Mutation('setStatus') private setStatus: any;

  private mounted() {
    this.buttonData();
  }

  private addRegister() {
    this.setStatus(ModalStatus.Register);
  }

  private buttonData(): any {
    if (this.loggedIn) {
      this.buttonHref = '/user-space';
      this.buttonText = 'button.user-dashboard';
    } else {
      this.buttonText = 'button.sign-up';
    }
  }

  private closeModal(): void {
    this.isModalVisible = false;
  }

  private showModal(): void {
    this.isModalVisible = true;
  }
}

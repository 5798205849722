








/*
 * This is just a component that will override some styling issues
 * that we had in the old site.
 */
import { Component, Vue, Watch } from 'vue-property-decorator';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
  'beforeRouteLeave',
]);
import ModalOverlay from '@/components/modal/ModalOverlay.vue';
import NewModalOverlay from '@/components/modal/NewModalOverlay.vue';
import { ModalStatus } from '../models/definitions';
import { NModalModule, NNoty } from '../utils/storemodules';
import Noty from 'noty';
import request, { requestb2bAPI } from '@/utils/request';

const statusesForNewModal = [
  ModalStatus.ReportActivity,
  ModalStatus.NewLogin,
  ModalStatus.NewRegistration,
  ModalStatus.NewEmailConfirmation,
  ModalStatus.NewForgotPassword,
  ModalStatus.NewResetPassword,
  ModalStatus.bookingTimeEnd,
  ModalStatus.ContactOrganizer,
  ModalStatus.CropImage,
  ModalStatus.policyAgreement,
  ModalStatus.BookingEmailConf,
  ModalStatus.Donation,
  ModalStatus.Description,
];

@Component({
  components: {
    ModalOverlay,
    NewModalOverlay,
  },
})
export default class NewSite extends Vue {
  // this one is needed to access the enum from the html
  private ModalStatus = ModalStatus;

  private get getModalStatus(): ModalStatus {
    return NModalModule.status;
  }

  private get newModalStatuses() {
    return statusesForNewModal.includes(this.getModalStatus);
  }

  private get newNotyStatus() {
    return NNoty.isNotyShown;
  }

  @Watch('newNotyStatus')
  private onErrorShow(val: boolean) {
    if ( val === true ) {
      new Noty({
        type: NNoty.errorState.type,
        text: NNoty.errorState.message,
        layout: 'topRight',
        timeout: NNoty.errorState.timeout,
        theme: 'metroui',
        progressBar: false,
        closeWith: ['click'],
        killer: true,
      }).show();
      NNoty.showNoty();
    }
  }

  private created() {
    const { locale } = this.$route.params;
    if ( locale ) {
      this.$i18n.locale = locale;
      const HTML_TAG = document.getElementsByTagName('html');
      (HTML_TAG as any)[0].lang = this.$i18n.locale;
      request.defaults.baseURL = (request.defaults.baseURL as string).slice(0, -3) + this.$i18n.locale + '/';
      requestb2bAPI.defaults.baseURL = (request.defaults.baseURL as string).slice(0, -3) + this.$i18n.locale + '/';
    }
  }
}

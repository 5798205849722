



































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Hooper, Slide, Pagination as HooperPagination } from 'hooper';
import { IPicturesEntity } from '@/models/store/product';
import { IWidgetData } from '@/models/product';

@Component({
  components: {
    Hooper,
    Slide,
    HooperPagination,
  },
})
export default class ProductImageViewer extends Vue {
  @Prop({default: null}) private slides!: IPicturesEntity[] | null;
  @Prop({default: 0}) private currentPicture!: number;
  @Prop() private widgetData!: IWidgetData;
  @Prop({default: false}) private isMobile!: boolean;
  private isShowWidget: boolean = true;
  private mainSlideWidth: number | null = null;
  private currentSlide: number = 0;
  private itemsToShow: number = 0;

  private get price() {
    if ( this.widgetData.price !== '0' ) {
      return this.widgetData.currency + ' ' + this.widgetData.price;
    } else {
      return 'Free';
    }
  }

  private slideNext() {
    (this.$refs.carousel as any).slideNext();
  }

  private slidePrev() {
    (this.$refs.carousel as any).slidePrev();
  }

  private closeViewer() {
    this.destroyEventListener();
    this.$emit('closeViewer');
  }

  private showWidget() {
    this.isShowWidget = true;
  }

  private hideWidget() {
    this.isShowWidget = false;
  }

  private onWidthChange(payload: any) {
    this.mainSlideWidth = payload.slideWidth > 960 ? 960 : payload.slideWidth;
  }

  private goToBooking() {
    this.destroyEventListener();
    this.$emit('goBooking');
  }

  private getInfo(payload: any) {
    this.currentSlide = payload.currentSlide;
  }

  private eventListenerOnKeyDown(e: any) {
    switch (e.key) {
      case 'ArrowRight':
      this.slideNext();
      break;
      case 'ArrowLeft':
      this.slidePrev();
      break;
    }
  }

  private created() {
    window.addEventListener('keydown', this.eventListenerOnKeyDown);
    this.itemsToShow = this.isMobile ? 1 : window.innerWidth > 1920 ? 3 : 2;
  }

  private destroyEventListener() {
    window.removeEventListener('keydown', this.eventListenerOnKeyDown);
  }

}

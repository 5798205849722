













































import { Vue, Component, Prop } from 'vue-property-decorator';
import NewButton from '@/components/presentational/NewButton.vue';
import { NNoty } from '@/utils/storemodules';
import { Validator } from '@/utils/validators';

@Component({
  components: {
    NewButton,
  },
})
export default class OrganizerCard extends Vue {
  @Prop({default: ''}) private organizerLogo!: string;
  @Prop({default: ''}) private organizerName!: string;

  // Checks whether the logo is an image coming from url
  get isPhotoLogo() {
    return Validator.isValidURL(this.organizerLogo);
  }

  private copyLink() {
    const url = document.createElement('input');
    const text = window.location.href;
    document.body.appendChild(url);
    url.value = text;
    url.select();
    document.execCommand('copy');
    document.body.removeChild(url);
    NNoty.createNewNoty({
      message: 'Succesfully copied to your clipboard',
      period: 4000,
      type: 'success',
    });
  }

  private shareOnFacebook() {
    const text = window.location.href;
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${text}`, '_blank');
  }

  private contact() {
    this.$emit('openContactForm');
  }
}

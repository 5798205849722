








































































































import { Vue, Component, Watch} from 'vue-property-decorator';
import NewButton from '@/components/presentational/NewButton.vue';
import { IGQLProductSlotState } from '@/models/store/booking';
import { NBookingModule, NNoty, OrganizerModule, RBookingModule } from '@/utils/storemodules';
import { refundBooking } from '@/api/booking';
import dayjs from 'dayjs';
import { PRIMARY_COLOR } from '@/config/constants';

interface ICancellationPolicySlot {
  pssId: number;
  PolicyName: string;
  CancelEndDurationDays: number;
  RefundPercentage: number;
}

enum RefundMethods {
  Voucher = 0,
  CreditCard,
}

@Component({
  components: {
      NewButton,
  },
})
export default class RefundSummary extends Vue {
  private isCancelling = false;
  private dialog: boolean = false;
  private showContactOrganizer = false;
  private cancelPoliciesSlots: Array<Partial<ICancellationPolicySlot>> = [];
  private refundMethodSelected = RefundMethods.CreditCard;
  private RefundMethods = RefundMethods;
  private classElem: string = 'stop-scrolling';
  private documentBody: HTMLElement = document.getElementsByTagName('body')[0];


  private get orgColor(): string {
    return OrganizerModule.orgColor !== '' ? OrganizerModule.orgColor : PRIMARY_COLOR;
  }

  private get selectedSlots(): IGQLProductSlotState[] {
    return RBookingModule.selectedSlots;
  }

  private get slotsGroupedByPriceName() {
    return RBookingModule.selectedGroupedSlots;
  }

  private get organizerEmail(): string {
    return RBookingModule.organizerEmail;
  }

  private get totalPrice() {
    let totalPrice = 0;
    totalPrice += this.selectedSlots.map((item: IGQLProductSlotState) => item.PriceValue)
      .reduce((a: number, b: number) => a + b, 0);
    return totalPrice / 100;
  }

  private get refundTotal() {
    let subTotal = 0;
    for (const slot of this.selectedSlots) {
      subTotal += (slot.PriceValue);
      // this.cancelPoliciesSlots?.find((item) => item.pssId === slot.Id)!.RefundPercentage!) / 100;
    }
    return subTotal / 100;
  }

  private get currency() {
    return NBookingModule.bookingSummary?.data.ProductSlotBookingRead.Currency;
  }

  private created() {
    const email = this.selectedSlots.find((s) =>
              s.ProductSlotCategoryRead.ProductRead.GroupRead.brandingList.filter(
                (b: any) => b.email !== null,
              ).length > 0,
          )?.ProductSlotCategoryRead.ProductRead.GroupRead.brandingList.find(
            (b: any) => b.email !== null,
          )?.email || '';
    RBookingModule.setOrganizerEmail(email);
    /*for (const slot of this.selectedSlots) {
      const eventDate = slot.StartDateTime;
      let daysBeforeEvent = dayjs(eventDate).diff(dayjs().format('YYYY-MM-DD'), 'ms')
                             / (1000 * 60 * 60 * 24);
      daysBeforeEvent = Math.floor(daysBeforeEvent);
      const cancelPolicies = slot.ProductSlotCategoryRead.CancellationPolicyList;
      // GET the possiblePolicies
      const possiblePolicies = cancelPolicies.filter((item) =>
       item.CancelEndDurationDays - daysBeforeEvent >= 0);

      // GET the right cancel policy (the one closer to the event) if there are multiple ones
      let cancelPolicySlot = null;
      if (!!possiblePolicies.length) {
        cancelPolicySlot = possiblePolicies.sort((a, b) =>
          a.CancelEndDurationDays - b.CancelEndDurationDays)[0];
        cancelPolicySlot = {
          pssId: slot.Id,
          ...cancelPolicySlot,
        };
      } else {
        cancelPolicySlot = {
          pssId: slot.Id,
          RefundPercentage: 100,
        };
      }
      this.cancelPoliciesSlots.push(cancelPolicySlot);
    }*/
  }

  // Get the length of each group of tickets grouped by the PriceName
  private priceNamesLength(slots: IGQLProductSlotState[]) {
    return slots.reduce((lengths, slot) => {
      lengths[slot.PriceName] = lengths[slot.PriceName] || 0;
      lengths[slot.PriceName] += 1;
      return lengths;
      }, Object.create(null));
  }

  private formatTicketSummary(TicketName: string, length: number) {
    const CategoryName = TicketName.split('/')[1];
    const PriceName = TicketName.split('/')[2];
    return [CategoryName, `${length}x ${PriceName}`].join(' - ');
  }

  private async refundBooking() {
    this.isCancelling = true;
    const bookingId = NBookingModule.bookingId;
    const bookingToken = NBookingModule.bookingToken;
    let tickets: any[] = [];
    tickets = this.selectedSlots.map((item: IGQLProductSlotState) => {
          return {
            ticket: item.Id,
          };
        });
    try {
      await refundBooking(bookingId, bookingToken, tickets, this.refundMethodSelected);
      RBookingModule.setIsRefunded(true);
      this.$router.push(bookingId + '/confirmation');
    } catch (error) {
      this.showErrorNotification(error);
    } finally {
      this.isCancelling = false;
    }
  }

  private selectRefundMethod(refundMethod: number) {
    this.refundMethodSelected = refundMethod;
  }

  private contactOrganizer() {
    const email = this.organizerEmail;
    document.location.href = 'mailto:' + email;
  }

  private showErrorNotification(error: any) {
    const { response: {status, data: { messages }} } = error;
    switch (status) {
      case 400:
        if (messages[0].includes('without sufficient funds')) {
          this.showContactOrganizer = true;
        }
        break;
      case 403:
        if (messages[0].includes('cannot be cancelled')) {
          NNoty.createNewNoty({
            period: 4000,
            message: String(this.$t('user-space.error.booking-not-cancelable')),
            type: 'error',
          });
        }
        break;
      case 404:
        NNoty.createNewNoty({
          period: 4000,
          message: String(this.$t('user-space.error.booking-not-found')),
          type: 'error',
        });
      default:
        NNoty.createNewNoty({
          period: 4000,
          message: String(this.$t('error.error-response-banner')),
          type: 'error',
        });
        throw error;
        break;
        }
    }

  @Watch('dialog')
  private setScrolling(newVal: boolean): void {
    if (!newVal) {
      this.documentBody.classList.remove(this.classElem);
    } else {
      this.documentBody.classList.add(this.classElem);
    }
  }

  private beforeDestroy() {
    this.documentBody.classList.remove(this.classElem);
  }

}

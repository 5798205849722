




















































































































































import { Prop, Component, Vue, Watch } from 'vue-property-decorator';
import { Mixins } from 'vue-mixin-decorator';
import { required, email } from 'vuelidate/lib/validators';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { Validator } from '@/utils/validators';
import Select from '@/components/presentational/NewSelect.vue';
import {
  IPostBookingField,
  IBookingField,
  ITicketField,
  QuestionType,
  InformationType,
} from '@/models/store/booking';
import { getLanguageList, userCountry } from '@/api/common';
import { ModalStatus } from '@/models/definitions';
import {
  NBookingModule,
  NModalModule,
  OrganizerModule,
  ProductModule,
} from '@/utils/storemodules';
import {
  fillCountries,
  getCountryCode,
  getCountriesNames,
  getCountryName,
  isEquilibre,
} from '@/utils/helpers';
import Calendar from '@/components/presentational/Calendar.vue';
import DateHelperMixin from '@/mixins/DateHelper';
import { EVCalendar } from '@/models/events';
import CountryCodes from 'libphonenumber-js/metadata.full.json';
import dayjs from 'dayjs';

@Component({
  components: {
    Select,
    VuePhoneNumberInput,
    Calendar,
  },
  validations() {
    const question: ITicketField = this.$props.quest;
    const validation: any = {};
    const self = this as NewQuestion;

    if (
      question.questionType === QuestionType.text ||
      question.questionType === QuestionType.dropdown ||
      question.questionType === QuestionType.date ||
      question.questionType === QuestionType.long_text
    ) {
      validation.answer = {};

      if (question.validation) {
        validation.answer.required = required;
      }

      // email should be valid
      if (question.informationType === InformationType.Email) {
        validation.answer.email = email;
        // TODO hamza, check validation regex. It blocks valid emails.
        // validation.answer.email = () => {
        //   return self.isValidEmail;
        // };
      }

      // Used to detect invalid phones
      if (question.informationType === InformationType.Phone) {
        validation.answer.phone = () => {
          return self.validPhone;
        };
      }
    } else if (question.questionType === QuestionType.list) {
      validation.listAnswers = {};

      if (question.validation) {
        validation.listAnswers.isListEmpty = Validator.isArrayEmpty;
      }
    } else if (question.questionType === QuestionType.radio) {
      validation.answer = {};

      if (question.validation) {
        validation.answer.required = required;
      }
    } else if (question.questionType === QuestionType.file) {
      // Here we are validating files as inputs, so that we can keep the
      // same api for the component. Note that this doesn't allow us to validate
      // stuff like file type or size.
      validation.answer = {};

      if (question.validation) {
        validation.answer.required = required;
      }
    }

    return validation;
  },
})
export default class NewQuestion extends Mixins<DateHelperMixin>(DateHelperMixin) {
  @Prop() public quest!: IBookingField;
  // field position
  @Prop({ default: 0 }) public position!: number;
  @Prop({ default: false }) public isDelivery!: boolean;
  @Prop({ default: false }) public isFixedDelivery!: boolean;
  @Prop({default: ''}) public preFilledValue!: string;
  public QuestionType = QuestionType;
  public InformationType = InformationType;
  // Answers for list(array)
  private listAnswers: string[] = [];
  // Answers for input and Selector
  private answer: string = '';
  // answer for marketing opt-in
  private answerMarketing: boolean = false;
  private EVCalendar = EVCalendar;

  private validPhone = false;
  private e164Phone = '';

  private selectedFile: any = null;

  // unique string for this component
  private uniqueKey: string = String(Math.random() * Math.random());

  private yesterday: Date = dayjs()
    .subtract(1, 'd')
    .toDate();
  private openDate: Date = new Date(1990, 0, 1);

  private calendarDate: Date | null = null;

  // pecial to Country dropdown
  private countries: Array<{ name: string; code: string }> | undefined;
  private countryAnswer: string = '';
  private defaultCountry: string = '';

  private languagesCodes: Array<{ key: any; value: any }| null>  = [];
  private languages: string[] = [];
  private languageAnswer: string = '';

  private fileMaxSizeMessage!: string;
  private isFile: boolean = false;
  private fileMaxSize: number = 0;
  private canUploadFile: boolean = true;
  private isInvalidFile: boolean | null = null;
  private invalidFileMessage!: string;

  /**
   * For some clients we need to disable country select and set a default country
   * Only when handling shipping
   * We add here dynamicShipping field to check if we have a shipping ticket
   * This way we don't set a default country for these clients and disable the select all the time
   */
  private defaultCountryDelivery: { [groupId: number]: {country: string; dynamicShipping: boolean; } } = {
    19348: {country: 'AT', dynamicShipping: true},
    19329: {country: 'CH', dynamicShipping: false},
  };

  /**
   * Returns a date in the form of
   */
  get countryCode(): string {
    return NBookingModule.countryCode;
  }
  get formattedCalendarDate(): string {
    if (this.calendarDate) {
      return this.currentDate(String(this.calendarDate), true);
    } else {
      return '';
    }
  }

  get isValidEmail(): boolean {
    return Validator.isValidEmail(this.answer);
  }

  get selectList() {
    if (this.quest && this.quest.questionType === QuestionType.dropdown) {
      if (this.quest.list) {
        return this.quest.list.map((val: string) => ({
          key: val,
          value: val,
        }));
      }
    }

    return [];
  }

  get list() {
    // list for radios and list
    const listTypes = [QuestionType.list, QuestionType.radio];
    // if (this.quest && this.quest.questionType === QuestionType.list) {
    if (this.quest && listTypes.includes(this.quest.questionType)) {
      if (this.quest.list) {
        return this.quest.list;
      }
    }

    return [];
  }

  get hasError() {
    const quest = this.quest;
    // For optional phones, validate only when the user has typed a phone
    if (
      quest &&
      !quest.validation &&
      quest.informationType === InformationType.Phone
    ) {
      if (this.answer) {
        return this.$v.$error;
      }
      return false;
    }
    return this.$v.$error;
  }

  get isInvalid() {
    const quest = this.quest;
    // For optional phones, validate only when the user has typed a phone
    if (
      quest &&
      !quest.validation &&
      quest.informationType === InformationType.Phone
    ) {
      if (this.answer) {
        return this.$v.$invalid;
      }
      return false;
    }
    return this.$v.$invalid;
  }

  get fileCrop() {
    if (
      this.quest.questionType === QuestionType.file &&
      this.quest.informationType === InformationType.Passport
    ) {
      return NModalModule.getCropFile;
    }
  }

  get modalStatus() {
    if (this.quest.questionType === QuestionType.file) {
      return NModalModule.status;
    }
  }

  // We check if we are handling a delivery case and default country is set
  get disableCountrySelect() {
    const defaultCountryDeliverySet =
      Object.keys(this.defaultCountryDelivery).find((id) => Number(id) === OrganizerModule.id);
    if (!defaultCountryDeliverySet || !OrganizerModule.id) {
      return false;
    }

    return this.defaultCountryDelivery[OrganizerModule.id].dynamicShipping ?
      defaultCountryDeliverySet && this.isDelivery : defaultCountryDeliverySet && this.isFixedDelivery;
  }

  /**
   * Returns whether is the question is answered or not
   */
  public isQuestionAnswered() {
    // return !!this.answer || this.listAnswers.length > 0 || this.selectedFile;
    return !!this.answer || this.listAnswers.length > 0;
  }

  public getAnswer(): IPostBookingField {
    // if quesion isn't answered, return just field_id
    if (!this.isQuestionAnswered()) {
      return {
        fieldId: this.quest.fieldId,
      };
    }

    let answer: string | string[];
    if (this.quest.questionType === QuestionType.list) {
      answer = this.listAnswers;
    } else if (this.quest.informationType === InformationType.Phone) {
      answer = this.e164Phone;
    } else if (this.quest.questionType === QuestionType.file) {
      // we return the selected file here, because we don't want
      // to resubmit if the file was already submited and no new
      // file is selected. This means that if a file was already selected
      // and submitted, this.answer will be set. So, we don't need to submit
      // again if answer is 'string'. But if answer is a 'File', we know that
      // a new file was selected.
      answer = this.selectedFile;
    } else if (this.quest.informationType === InformationType.Marketing) {
      answer = String(this.answerMarketing);
    } else {
      answer = this.answer;
    }

    return {
      fieldId: this.quest.fieldId,
      answer,
    };
  }
  @Watch('preFilledValue')
  private preFillCustomFiled() {
    if (this.preFilledValue) {
      this.answer = this.preFilledValue;
    } else {
      this.answer = '';
    }
  }
  @Watch('$v.$invalid')
  private onAnswerValidityChange(val: boolean) {
    // Do nothing for list questions
    if (this.quest.questionType === QuestionType.list) {
      this.$emit('change');
      return;
    }

    this.validate();
  }

  @Watch('$v.listAnswers.$invalid')
  private onListValidityChange(val: boolean) {
    // Do nothing for text questions
    if (this.quest.questionType === QuestionType.text) {
      return;
    }

    this.validate();
  }

  @Watch('modalStatus')
  private onModalStatusChanged(val: any) {
    if (
      val === ModalStatus.Hidden &&
      this.fileCrop &&
      (this.fileCrop as any).id === this.selectedFile.id
    ) {
      this.selectedFile = null;
      this.answer = '';
      NModalModule.setCropFile(null);
    }
  }

  @Watch('fileCrop')
  private onFileCropChange(val: any, oldVal: any) {
    if (
      this.selectedFile &&
      val &&
      oldVal &&
      val !== oldVal &&
      val.id === this.selectedFile.id
    ) {
      this.selectedFile = val;
      this.answer = this.selectedFile.name;
      NModalModule.setCropFile(null);
      NModalModule.setStatus(ModalStatus.Hidden);
    }
  }

  // @Watch('selectedFile')
  // private onSelectedFileChange(val: string) {
  //   this.validate();
  // }

  private async mounted() {
    const quest = this.quest;
    if (this.quest.informationType === InformationType.LanguageDropdown && this.quest.list) {
      // get languages
      const response =  await getLanguageList();
      if (response && response.data && response.data.languages && response.data.languages.length > 0) {
        // Mapping quest codes to their corresponding language names
        const mappedList = this.quest.list.map((code: string) => {
          const language = response.data.languages.find((lang: any) => lang.code === code);
          return language ? { key: language.code, value: language.name } : null;
        }).filter(Boolean);
        this.languagesCodes = mappedList;
        this.languages = mappedList.map((val: any) => val.value);
        if (this.languages && this.languages.length) {
          this.languageAnswer = this.languages[0];
          this.answer =  this.languagesCodes.filter((lang) => lang?.value === this.languageAnswer)[0]?.key;
        }
      }
    }
    if (this.quest.informationType === InformationType.Phone
      || this.quest.informationType === InformationType.Country
      || this.quest.informationType === InformationType.BirthDate) {
        if (OrganizerModule.id && isEquilibre(OrganizerModule.id)) {
          this.defaultCountry = 'CH';
        } else {
          const defaultCountry = await userCountry() || 'CH';
          this.defaultCountry = this.disableCountrySelect && OrganizerModule.id ?
            this.defaultCountryDelivery[OrganizerModule.id].country : defaultCountry;
        }
    }

    // If it's an answered phone number we need to get country code from the phone country code
    if (this.quest.informationType === InformationType.Phone && this.quest.answer) {
      this.countries = await fillCountries(this.$i18n.locale);
      const CountryNumberCodes = CountryCodes;
      // remove the + symbole & check for 2 & 21 & 213 possible countryCallingCodes through the loop
      for (let i = 1; i <= 3; i++) {
        const countryCallingCode = this.quest.answer.substr(1, i);
        const possibleCountryCodes = CountryNumberCodes.country_calling_codes[countryCallingCode];
        // check if the countryCallingCode is valid by checking the length of the remaining string
        if (possibleCountryCodes) {
          for (const countryPhoneCode of possibleCountryCodes) {
            // get the possible phone number lengths
            const PossiblePhoneLengthList: number[] = CountryNumberCodes.countries[countryPhoneCode][3];
            const phoneNumber = this.quest.answer;
            // check if the phone number length is valid if we use this countryCode
            if (PossiblePhoneLengthList.find((item) => item === phoneNumber.slice(i + 1).length)) {
              const countryCode = getCountryName(this.countries, countryPhoneCode);
              this.defaultCountry = getCountryCode(this.countries, countryCode);
              break;
            }
          }
        }
      }
    }

    if (this.quest.informationType === InformationType.Country) {
      // need to remove and add another check for questionType is dropdown
      // AFTER b2b part will be completed
      let countriesArray: string[] = [];
      this.countries = await fillCountries(this.$i18n.locale);
      countriesArray = getCountriesNames(this.countries);
      this.quest.list = countriesArray;
      this.quest.questionType = QuestionType.dropdown;
      if (this.quest.answer) {
        this.countryAnswer = getCountryName(this.countries, this.quest.answer);
      } else {
        this.countryAnswer = getCountryName(this.countries, this.defaultCountry);
      }
      this.answer = getCountryCode(this.countries, this.countryAnswer);
      if (this.isDelivery) {
        this.$emit('setCountryCode', this.answer);
      }
    }

    if (this.quest.informationType === InformationType.Marketing) {
      this.quest.questionType = QuestionType.radio;
      this.answer = String(this.answerMarketing);
    }

    if (this.quest.answer) {
      if (this.quest.informationType === InformationType.LanguageDropdown) {
        this.languageAnswer = this.languagesCodes.filter((lang: any) => lang.key === this.quest.answer)[0]?.value;
        this.answer = this.quest.answer;
      } else if (this.quest.informationType === InformationType.Marketing) {
        this.answerMarketing = this.quest.answer === 'true';
      } else if (this.quest.questionType === QuestionType.text) {
        this.answer = this.quest.answer as string;
      } else if (this.quest.questionType === QuestionType.long_text) {
        this.answer = this.quest.answer as string;
      } else if (this.quest.questionType === QuestionType.date) {
        this.answer = this.quest.answer as any;
        this.calendarDate = new Date(this.answer);
      } else if (this.quest.questionType === QuestionType.file) {
        // setting answer to the file that was already submitted.
        this.answer = this.quest.answer as string;
      } else if (this.quest.questionType === QuestionType.list) {
        this.listAnswers = this.quest.answer as any;
      } else if (
        this.quest.questionType === QuestionType.dropdown &&
        this.quest.list
      ) {
        this.answer = this.quest.answer as string;
      } else if (
        this.quest.questionType === QuestionType.radio &&
        this.quest.list
      ) {
        this.answer = this.quest.answer as string;
      } else if (this.quest.questionType === QuestionType.date) {
        this.answer = this.quest.answer;
      }
    } else if (
      this.quest.questionType === QuestionType.radio &&
      this.quest.list &&
      this.quest.list.length
    ) {
      // radio custom fields should have first value selected
      this.answer = this.quest.list[0];
    }


    if (this.quest.questionType === QuestionType.file) {
      this.isInvalidFile = false;
      // We will show file max size message
      this.isFile = true;
      this.fileMaxSize = 10;
      this.fileMaxSizeMessage = (this.$t(
        'error.file-max-size',
      ) as string).replace('X', this.fileMaxSize.toString());
    }

    // run a first validation if necessary
    this.validate();
  }

  // returns a checkbox value that is unique to this question
  private checkboxVal(quest: ITicketField) {
    return this.uniqueKey;
  }

  private setValueDate(item: Date) {
    this.calendarDate = item;
    this.answer = dayjs(item).format('YYYY-MM-DD');
    this.$v.$touch();
    this.validate();
  }

  private setSelectValue(item: any) {
    if (this.quest.informationType === InformationType.LanguageDropdown) {
      this.languageAnswer = item;
      this.answer = this.languagesCodes.filter((lang) => lang?.value === item)[0]?.key;
    } else if (this.quest.informationType === InformationType.Country) {
      // so we're in a case of a dropdown of countries
      // lets make the answer not the country name but the country code
      this.answer = getCountryCode(this.countries, item);

      this.countryAnswer = item;
      if (this.isDelivery) {
        this.$emit('setCountryCode', this.answer);
      }
    } else {
      // this is not a dropdown of countries
      this.answer = item;
    }
    this.$v.$touch();
    this.validate();
  }

  private validate() {
    this.emitValidity();
  }

  private emitValidity() {
    const obj = {
      // ticket fields have form position attached to them
      position: (this.quest as ITicketField).formPosition || this.position,
      valid: !this.isInvalid,
    };

    // Phone validation
    if (this.quest.informationType === InformationType.Phone) {
      // we use answer to check if phone is filled because e164 isn't
      // filled when you have only 1 digit written.
      // if a phone is filled, we check if it's valid
      if (this.e164Phone) {
        obj.valid = obj.valid && this.validPhone && !!this.e164Phone;
      } else if (this.quest.validation) {
        // Here, phone number is not set, and we have to validate because it is required
        obj.valid = false;
      } else {
        // No phone is set & and we don't need to validate it.
        // so let's say all is good.
        obj.valid = true;
      }
    }

    // NOTE: No need for this because file now is validated just like other text inputs.
    // However, this might change as we need to validate file sizes and extensions.
    // else if (this.quest.questionType === QuestionType.file && this.quest.validation) {
    // if (this.selectedFile) {
    // obj.valid = true;
    // } else if (this.answer) { // file was already submitted
    // obj.valid = !this.isInvalid;
    // } else {
    // obj.valid = false;
    // }
    // obj.valid = !!this.selectedFile;
    // }

    this.$emit('validity', obj);
  }

  private onPhoneUpdate(phoneObj: {
    isValid: boolean;
    e164: string;
    phoneNumber: string;
    countryCode: string;
  }) {
    const { isValid, e164, phoneNumber, countryCode } = phoneObj;
    NBookingModule.setCountryCode(countryCode);
    this.validPhone = isValid;
    // When setting 1 digit, e164 is undefined, so we use phoneNumber
    this.e164Phone = e164 || phoneNumber;
    this.emitValidity();
  }

  private selectFile(event: any) {
    this.isInvalidFile = false;
    const fileAllowedSize: number = 10485760; // 10mb;
    const file = event.target.files[0];

    if (this.quest.informationType === InformationType.Passport) {
      // So this is an image
      if (file.type.indexOf('image/') === -1) {
        this.isInvalidFile = true;
        this.invalidFileMessage = this.$t('error.select-image') as string;
        return;
      }
      if (file.size > fileAllowedSize) {
        this.isInvalidFile = true;
        this.invalidFileMessage = this.$t('error.less-then-10mb') as string;
        return;
      }
      file.id = Date.now();
      NModalModule.setCropFile(file);
      NModalModule.setStatus(ModalStatus.CropImage);
    }

    if (this.quest.informationType === InformationType.Picture) {
      // So this is a passport (ID)
      if (file.size > fileAllowedSize) {
        this.isInvalidFile = true;
        this.invalidFileMessage = this.$t('error.less-then-10mb') as string;
        return;
      }
    }

    if (this.quest.informationType === InformationType.Other) {
      // This is a file
      if (file.size > fileAllowedSize) {
        this.isInvalidFile = true;
        this.invalidFileMessage = (this.$t(
          'error.file-less-than-xxmb',
        ) as string).replace('XX', this.fileMaxSize.toString());
        return;
      }
    }

    this.selectedFile = file;
    this.answer = this.selectedFile.name;
  }

  // touch the field to invoke it's validation
  private touch() {
    const type = this.quest.questionType;

    if (type === QuestionType.list) {
      this.$v.listAnswers.$touch();
      return;
    }

    this.$v.answer.$touch();
  }

  /**
   * Opens the calendar view
   */
  private showCalendar() {
    const component: any = this.$refs.calendarComponent;
    component.showCalendar();
  }
}

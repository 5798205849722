































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { ISocialsInfo } from '@/models/store/product';

@Component
export default class ProductSocials extends Vue {
  @Prop({default: null}) private socialsInfo!: ISocialsInfo;
  @Prop({default: true}) private isGorizontal!: boolean;

  private openLink(link: 'website' | 'youtube' | 'instagramm' | 'facebook' | 'linkedin' ) {
    window.open(this.socialsInfo[link] as string, '_blank');
  }

}

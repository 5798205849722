import { Mixins } from 'vue-mixin-decorator';
import dayjs from 'dayjs';
import { Mixin } from 'vue-mixin-decorator';
import { IModiServerRecapCategory, ITicket } from '@/models/store/booking';
import { isMembershipTicket, isGiftTicket, isExpiry2Membership } from '@/utils/booking';
import DateHelperMixin from './DateHelper';
import { NBookingModule } from '@/utils/storemodules';
import { dateSortComp } from '@/utils/helpers';

@Mixin
export default class TicketHelperMixin extends Mixins<DateHelperMixin>(DateHelperMixin) {

  // get hideDate() {
  //   return (ticket: ITicket) => {
  //     // Experimental feature. Remove this ignore later
  //     // @ts-ignore
  //     return isGiftTicket(ticket as ITicket);
  //   };
  // }

  get startDate() {

    return (ticket: ITicket) => {
      if (ticket && isMembershipTicket(ticket) && ticket.membership) {
        const membership = ticket.membership;
        const startDate = membership.startDate;
        if (startDate) {
          return this.currentDate(startDate);
        }
        return this.currentDate(dayjs(new Date()).toString());
      }
      return '';
    };

  }

  get endDate() {

    return (ticket: ITicket) => {
      if (!ticket) {
        return '';
      }
      const membership = ticket.membership;
      if (membership) {
        if (membership.expiryDate) {
          return this.currentDate(membership.expiryDate);
        }
        const startDate = dayjs(membership.startDate || new Date()).add(this.minDuration(ticket), 'M');
        return this.currentDate(startDate.toString());
      }

      return '';
    };
  }

  get showDate() {
    return (ticket: ITicket) => {
      return !isGiftTicket(ticket);
    };
  }

  // get hideEndDate() {
  //   if (this.isExpiry2Membership) {
  //     return true;
  //   }

  //   return false;
  // }

  get isMembershipTicket() {
    return (ticket: ITicket) => {
      if (!ticket) {
        return false;
      }

      return isMembershipTicket(ticket);
    };
  }

  get isExpiry2Membership() {
    return (ticket?: ITicket) => {
      if (!ticket) {
        return false;
      }
      return isExpiry2Membership(ticket);
    };
  }

  get minDuration() {
    return (ticket: ITicket) => {

      if (!ticket) {
        return 0;
      }

      const membership = ticket.membership;
      if (!isMembershipTicket(ticket) || !membership) {
        return 0;
      }

      const { periodLength, numberMandatoryPeriod } = membership;
      return periodLength * numberMandatoryPeriod;
    };
  }

  get autoRenewal() {
    return (ticket: ITicket) => {
      if (!ticket) {
        return '';
      }

      const membership = ticket.membership;
      if (!isMembershipTicket(ticket) || !membership) {
        return '';
      }

      if (membership.autoRenew) {
        return true;
      }

      return false;
    };
  }

  get ticketsPrices(): IModiServerRecapCategory[] {
    const bookingRes = NBookingModule.bookingRes;
    if (!bookingRes) {
      return [];
    }

    const tickets = bookingRes.bookingRecap.categories || [];
    return tickets.map((ticket): IModiServerRecapCategory => {
      let mainTicket: ITicket;
      const flattenedTicket = (NBookingModule.flatTicketById(Number(ticket.categoryId), true) as ITicket);
      const seatingMainSubProductId = flattenedTicket ? flattenedTicket.seatingMainSubProductId : undefined;
      if (seatingMainSubProductId) {
        mainTicket = NBookingModule.flatTicketById(seatingMainSubProductId, true) as ITicket;
      } else {
        mainTicket = NBookingModule.ticketById(Number(ticket.categoryId)) as ITicket;
      }
      return {...ticket, ticket: mainTicket};
    })
    .sort((ticket1, ticket2) => {
      return dateSortComp(ticket1.startDateTime, ticket2.startDateTime);
    });
  }

  get guests() {
    return (ticket: ITicket) => {
      const membership = ticket.membership;
      if (!isMembershipTicket || !membership) {
        return 0;
      }

      return membership.guests;
    };
  }

  get periodLength() {
    return (ticket: ITicket) => {
      const membership = ticket.membership;
      if (!isMembershipTicket || !membership) {
        return 0;
      }

      return membership.periodLength;
    };
  }

  get unit() {
    return (ticket: ITicket) => {
      if (!isExpiry2Membership(ticket)) {
        return '';
      }

      return 'Month';
      // const periodLength = this.periodLength;
      // if (periodLength === 12) {
      //   return 'Year';
      // } else if (periodLength === 6) {
      //   return 'Simester';
      // } else if (periodLength === 3) {
      //   return 'Trimester';
      // } else {
      //   return 'Month';
      // }
    };
  }

}

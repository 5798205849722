import { RouteNames } from '@/models/enums';
import { Route } from 'vue-router';

// Checks whther we are navigating to product page
export function isToProduct(to: Route) {
  const toProduct =
  ([RouteNames.Product, RouteNames.ChannelProduct] as string[])
  .includes(to.name || '');

  return toProduct;
}

// Checks whther we are navigating to booking page
export function isToBooking(to: Route) {
  const toBooking =
  ([RouteNames.Booking, RouteNames.ChannelBooking] as string[])
  .includes(to.name || '');

  return toBooking;
}







































/**
 * Renders a slots for a given day
 *
 * @Prop slots {ITimeslotsEntity}
 * @Prop ticket {ITicket}
 */
import { Vue, Component, Prop, Watch} from 'vue-property-decorator';
import dayjs from 'dayjs';

import TimeSlot from './Slot.vue';
import SlotsHours from './SlotsHours.vue';
import NewSlotsHours from './SlotsHours2.vue';
import { isCalendarTicket } from '@/utils/booking';
import { ITimeslotsEntity, ITicket } from '../../../models/store/booking';
import { ESlotsHours } from '@/models/events';
import { AppModule, OrganizerModule, ReferrerModule } from '@/utils/storemodules';

@Component({
  components: {
    TimeSlot,
    SlotsHours,
    NewSlotsHours,
  },
})
export default class OneDaySlots extends Vue {
  @Prop() public slots!: ITimeslotsEntity[];
  @Prop() public ticket!: ITicket;
  @Prop({default: ''}) public hour!: string;
  @Prop({default: ''}) public ticketError!: string;
  private selectedSlots: {[s: string]: ITimeslotsEntity} = {};
  private ESlotsHours = ESlotsHours;
  private scrollToPrice: boolean = false;

  /**
   * Checks whether a ticket is a calendar ticket or not.
   * Calendar tickets contain 10 or more slots;
   */
  get isCalendarTicket(): boolean {
    return isCalendarTicket(this.ticket);
    // return this.ticket.categoryInfo.timeslots.length >= 10;
    // return true;
  }

  get newTimeslotsDesign(): boolean {
    const designedEnabledGroups = [1];
    const groupId = OrganizerModule.id || OrganizerModule.orgId;
    if (designedEnabledGroups.includes(Number(groupId))) {
      return true;
    }
    return ReferrerModule.newTimeslots ? true : false;
  }

  get slotsTimes(): Array<{time: string, key: string, slot: ITimeslotsEntity}> {
    return this.slots.map((slot) => {
      return {
        time: slot.startDateTime,
        key: slot.startDateTime + slot.endDateTime,
        slot,
      };
    });
  }

  private get isMobile() {
    return AppModule.isMobile;
  }

  get hours(): string[] {
    const hours: string[] = [];
    const hoursObj: {[s: string]: boolean} = {};

    // create object with keys in the format 11:00
    for (const slot of this.slots) {
      hoursObj[dayjs(slot.startDateTime).format('HH:mm')] = true;
    }

    // transform that obj to array
    for (const key in hoursObj) {
      if (!key) {
        continue;
      }

      hours.push(key);
    }

    // sort the array
    return hours.sort((hour1: string, hour2: string) => {
      const h1 = Number(hour1.split(':')[0]);
      const h2 = Number(hour2.split(':')[0]);
      if (h1 < h2) {
        return -1;
      } else if (h1 > h2) {
        return 1;
      }

      return 0;
    });
  }

  get filteredSlots(): ITimeslotsEntity[] {
    const slots = this.slots.filter((slot) => {
      const slotHour = dayjs(slot.startDateTime).format('HH:mm');
      return slotHour === this.hour;
    });

    if (slots.length) {
      return slots;
    }

    // If no slot is selected and there are many slots hours, don't select one by default
    if (this.slots.length > 1) {
    return [];
    }

    // If no slot is selected and there is only one slots hour, select it by default
    return [this.slots[0]];
  }


  private selectTime(slot: ITimeslotsEntity) {
    // each slot is identified by mix of start date time and end
    const keyString = slot.startDateTime + slot.endDateTime;

    // toggle the slot
    if (this.selectedSlots[keyString]) {
      delete this.selectedSlots[keyString];
    } else {
      this.$set(this.selectedSlots, keyString, slot);
    }
  }



  private selectHour(hour: string) {
    this.$emit(ESlotsHours.HourSelected, hour);
    if (this.isMobile) {
      this.scrollToPrice = true;
    }
  }
}

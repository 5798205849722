// USER related interfaces
export interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  fullname: string;
  emails: any;
  phones: any;
  language: any;
  location?: any;
  birthDate?: string;
  googlePaceid?: string;
  userGroups: any;
  paymentMethods: any;
  swisspassCards: any;
}

export interface IUserData {
  firstName: string;
  lastName: string;
  emailAddress: string;
  languageId?: number;
  countryCode?: string;
  phoneNumber?: string;
  gender?: string;
  googlePlaceId?: string;
  password: IPasswordRegister;
}

export interface IOrgUserData {
  firstName: string;
  lastName: string;
  fullname: string;
  emails: any;
}

interface IPasswordRegister {
  first: string;
  last: string;
}

export interface IEmailVerifyData {
  email: string;
  mailToken: string;
}

export interface ICountry {
  name: string;
  code: string;
}

export interface ISelectEntry {
  key: string;
  value: string;
}

export interface IQuestion {
  question_type: string;
  name: string;
  list: string[] | null;
}

// MODAL related  interfaces
export enum ModalStatus {
  // Hidden
  Hidden,
  ForgotPass,
  ChangePass,
  EditPersonalDetails,
  DeleteAccountConf,
  DeleteAccountVerified,
  AddEmail,
  AddPhone,
  MobilePhoneConf,
  Login,
  Register,
  ReportActivity,
  NewLogin,
  NewRegistration,
  NewEmailConfirmation,
  NewForgotPassword,
  NewResetPassword,
  bookingTimeEnd,
  ContactOrganizer,
  CropImage,
  policyAgreement,

  // Shown when a user click on verification link
  EmailVerified,

  // Shown after registration
  EmailConf,

  // Swiss pass related
  SwissPass,

  // Booking email confirmation
  BookingEmailConf,

  // Donation Full description
  Donation,

  // Ticket full descirption,
  Description,
}

export enum SocialService {
  Facebook,
  Google,
  Swisspass,
}

export interface Countries {
  countries?: ICountry[];
}







































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { IPssTicket } from '../../../models/store/booking';
import { AppModule } from '../../../utils/storemodules';

@Component({
})
export default class TicketConfirm extends Vue {
  @Prop() public pssTickets!: IPssTicket;
  private pss: string = '';
  private isLoading = false;

  get headingText(): string {
    return 'Ticket confirmation';
  }

  get applePass() {
    return (ticket: IPssTicket) => {
      // must make sure that all characters are encoded for the redirect url query parameters
      // tslint:disable-next-line
      const subpath = encodeURIComponent(`?mobileType=apple&passToken=${ticket.goer_token}&utm_content=Download mobile pass apple&utm_campaign=${ticket.ticket_name}&utm_source=booking_confirmation&utm_medium=sms`);

      // tslint:disable-next-line
      const p = encodeURI(`${this.$SITE_URL}/redirect?path=${this.passApi}/en/booking/${ticket.booking_id}/ticket/${ticket.ticket_id}/mobile-pass`) + subpath;
      return p;
    };
  }

  get googlePass() {
    return (ticket: IPssTicket) => {
      // must make sure that all characters are encoded for the redirect url query parameters
      // tslint:disable-next-line
      const subpath = encodeURIComponent(`?mobileType=android&passToken=${ticket.goer_token}&utm_content=Download mobile pass apple&utm_campaign=${ticket.ticket_name}&utm_source=booking_confirmation&utm_medium=sms`);

      // tslint:disable-next-line
      const p = encodeURI(`${this.$SITE_URL}/redirect?path=${this.passApi}/en/booking/${ticket.booking_id}/ticket/${ticket.ticket_id}/mobile-pass`) + subpath;
      return p;
    };
  }

  get passApi(): string {
    const env = AppModule.env;
    if (env === 'dev') {
      return 'https://api.smeetz.com/smeetz-web-localized/dev';
    } else if (env === 'test') {
      return 'https://api.smeetz.com/smeetz-web-localized/test';
    }

    return 'https://api.smeetz.com/smeetz-web-localized/prod';
  }

  private created() {
    const pss = this.$route.params.pss;
    if (pss) {
      this.pss = pss;
    }
  }
}











































































































































































































import Timeout from 'await-timeout/dist/es5';
import { fetchServerBooking, fetchTicketsAddons, getBookingFields, getGroupMemberships } from '@/api/booking';
import { EVSelect } from '@/models/events';
import { MemType, IMembershipDiscounts, IMembershipProductSlotStates, MembershipType } from '@/models/memberships';
import { ReferrerModule, NBookingModule, OrganizerModule, AppModule, ProductModule } from '@/utils/storemodules';
import { Vue, Component } from 'vue-property-decorator';
import Select from '@/components/presentational/NewSelect.vue';
import { IMembershipCustomer, IProductSlotStatesEntity, ITicketField } from '@/models/store/booking';
import debug from '@/utils/debug';
import { isSeatParentCategory } from '@/utils/helpers';
import { getTicketGroupMemberships } from '@/api/ticket';
import { Loading, debounce } from 'quasar';
import { disableQuasarStyling, enableQuasarStyling } from '@/utils/styles';
import { addRegularTicketsToCustomers, RealMembershipDiscountTypes } from '@/utils/membership-utils';

enum BookingTypes {
  Individual = 1,
  Membership = 2,
}

@Component({
  components: {
    Select,
  },
})
export default class MembershipStepper extends Vue {
  private step = 1;
  private memId: number | null = null;
  private loading: boolean = false;
  private btnLoading = false;
  private memType: MemType | null = null;
  private bookingChoice = BookingTypes.Membership;
  private memIds: number[] = [];
  private responseError: boolean = false;
  private discounts: IMembershipDiscounts[] = [];
  private prodSlotStates: IMembershipProductSlotStates[] = [];
  private EVSelect = EVSelect;
  private count = 1;
  private memOptions = [
    {label: '1', value: 1},
    {label: '2', value: 2},
    {label: '3', value: 3},
    {label: '4', value: 4},
  ];

  // For now this is hard coded for Family membership
  // in future MinCustomer By Membership can be set in backend
  private customersLimitByMembership: number = 2;

  private clients: IMembershipCustomer[] = [
    {firstName: '', lastName: '', tickets: []},
  ];

  // Whether or not show a text to explain the second step
  // we do it only for Equilibre for now
  get showClarifyingInfo() {
    const orgId = OrganizerModule.id;
    if (orgId && orgId === 16192) {
      return true;
    } else {
      return false;
    }
  }

  get hasError() {
    return this.$v.code.$error || this.responseError;
  }

  get defaultOptionValue() {
    return this.membership && this.options.includes(this.membership.name) ?
      this.membership.name : this.options[0];
  }

  get options() {
    // debug1('hi from options()');
    const groups = NBookingModule.filtTicketsGroups;
    // debug1('groups', groups);

    const opts = this.discounts.filter((d) => {
      const membershipGroup = d.ticketGroup;
      if (!membershipGroup) {
        return false;
      }

      // Membership required tickets are included by default
      if (d.type === MembershipType.RequiredTicketsMembership) {
        return true;
      }

      return groups.includes(membershipGroup.id);
    }).map((discount) => discount.name);
    // debug1('opts', opts);

    if (!opts.length) {
      return opts;
    }

    // TODO: LEAVE COMMENTED
    // Just in case people want to show no membership option
    // return ['Regular booking'].concat(opts);
    return opts;
  }

  get hasCustomersLimitError() {
    if (this.clients.length < 2 && this.membership && this.membership.type === MembershipType.Family) {
      // should have at least 2 clients for Family membership
      return true;
    }
    return false;
  }

  get customersLimitByMembershipErrorText() {
    return (this.$t('membership.minimum-customers-per-membership') as string)
    .replace('X', this.customersLimitByMembership.toString())
            .replace('MembershipName', String(this.membership?.name));
  }

  get hasEmptyInput() {
    for (const client of this.clients) {
      if (!client.firstName || !client.lastName) {
        return true;
      }
    }
    return false;
  }

  /**
   * Indicates whether all custom info is inserted
   */
  get canFinish() {
    if (!this.clients.length) {
      return;
    }

    if (this.hasEmptyInput || this.hasCustomersLimitError) {
      return false;
    }

    // for (const client of this.clients) {
    //   if (!client.firstName || !client.lastName) {
    //     return false;
    //   }
    // }

    // if (this.clients.length < 2 && this.membership && this.membership.id === 5) {
    //   // should have at least 2 clients for Family membership
    //   return false;
    // }

    return true;
  }

  get isMobile() {
    return AppModule.isMobile;
  }

  get membership() {
    if (this.bookingChoice === BookingTypes.Individual) {
      return null;
    }

    return NBookingModule.membership;
  }

  get isMembershipOnly() {
    return ReferrerModule.isMembershipOnly;
  }

  private async created() {
    // Check if the already fetched tickets have ticket groups.
    // skip doing this check if no ticket groups are available
    this.done = debounce(this.done, 300);

    const tickets = NBookingModule.fetchedTickets;
    if (tickets && tickets.length) {
      let hasTicketGroup = false;
      for (const t of tickets) {
        if (t.ticketGroups && t.ticketGroups.length) {
          hasTicketGroup = true;
          break;
        }
        // Check sub categories for seating tickets
        if (isSeatParentCategory(t) && t.seatingSubProducts && t.seatingSubProducts.length) {
          for (const seatingCategory of t.seatingSubProducts) {
            if (seatingCategory.ticketGroups && seatingCategory.ticketGroups.length) {
              hasTicketGroup = true;
              break;
            }
          }
        }

        // Make sure we break in case we found group in seating sub products
        if (hasTicketGroup) {
          break;
        }
      }

      if (!hasTicketGroup) {
        debug('No ticket groups found, skipping membership stepper');
        this.$emit('done');
        return;
      }
    }

    this.memType = ReferrerModule.memType || 0;

    if (this.isMembershipOnly) {
      this.step = 2;
    }

    // Ensure that we don't fetch memberships without having fetched tickets first
    // This way of blocking the function is not ideal, but I am using it as a quick solution
    // until we modify the way carts are rendered.
    this.loading = true;
    while (!NBookingModule.fetchedTickets.length) {
      await Timeout.set(500);
    }
    this.loading = false;

    // Ensure that current tickets belong to a membership.
    await this.fetchMemberships();
    if (!this.options || this.options.length === 0) {
      debug('No membership options available for current tickets, skipping');
      NBookingModule.setMembership(null);
      this.$emit('done');
      return;
    }

    this.exec();
  }

  private async fetchMemberships() {
    this.loading = true;
    this.memType = ReferrerModule.memType || 0;
    this.memIds = ReferrerModule.memIds;
    this.memId = NBookingModule.memId;
    const orgId = OrganizerModule.id;
    if (!orgId) {
      setTimeout(this.fetchMemberships, 2000);
      throw new Error('No organiser fetched for memberships');
    }

    const { membershipPSS, discounts } = NBookingModule;
    if (discounts.length) {
      this.prodSlotStates = membershipPSS;
      this.discounts = discounts;
      // No need to continue, cause we got all we need
      this.loading = false;
      return;
    }

    const response = await getGroupMemberships(orgId);
    const data = response.data;
    this.loading = false;
    if (!data) {
      return;
    }

    // Show only certain discounts if the organiser wants to.
    this.discounts = !this.memIds.length ? data.membershipDiscounts :
      data.membershipDiscounts.filter((discount) => this.memIds.includes(discount.id));

    // Keep only the discounts that are displayed to the customer
    this.discounts = this.discounts.filter((discount) => this.options.includes(discount.name));

    // by default choose first membership
    if (this.discounts.length) {
      this.memId = this.discounts[0].id;
    }

    NBookingModule.setMembershipDiscounts(this.discounts);

    if (this.discounts.length) {
      NBookingModule.setMembership(this.discounts[0]);
    }
  }

  private async exec() {
    if (this.step === 2) {
      this.fetchMemberships();
    }

    if (this.step === 3) {
      NBookingModule.setMembershipCount(this.count);
    }
  }

  private chooseIndividualBooking() {
    // Ensure that no membership discount is selected
    NBookingModule.setMembership(null);

    this.$emit('done');
  }

  private optionsChange(memName: string) {
    const discount = this.discounts.find((d) => d.name === memName);

    if (discount) {
      NBookingModule.setMembership(discount);
      this.memId = discount.id;
    } else {
      this.memId = null;
    }

    this.prodSlotStates = [];
  }

  private addClient() {
    this.clients.push({firstName: '', lastName: '', tickets: []});
  }

  private removeClient(index: number) {
    this.clients.splice(index, 1);
  }

  // private countChange(count: number) {
  // 	this.count = count;
  // 	NBookingModule.setMembershipCount(count);
  // }

  private async done() {
    if (!this.canFinish) {
      if (this.hasCustomersLimitError && !this.hasEmptyInput) {
        const errorElt = document.getElementById('customers-limit-text');
        errorElt?.classList.add('small-bouncing', 'f-error');
        setTimeout(() => { errorElt?.classList.remove('small-bouncing'); }, 450);
      }
      return;
    }

    // Do we want to continue without a memebership
    if (this.bookingChoice === BookingTypes.Individual) {
      NBookingModule.setMembership(null);
    }

    // NBookingModule.setMembershipCount(this.count);
    NBookingModule.setMembCustomers(this.clients);

    const membership = NBookingModule.membership;
    // These memberships don't require any further data pulling. So we are done
    if (!membership || RealMembershipDiscountTypes.includes(membership.type)) {
      this.$emit('done');
      return;
    }

    try {
      enableQuasarStyling();
      Loading.show();
      // pull the membership ticket group data
      const membershipTicketGroup = membership.ticketGroup?.id;
      if (!membershipTicketGroup) {
        throw new Error('No ticket group is set on membership dicount');
      }
      // Pull the PSC data

      const membershipsInfo = await getTicketGroupMemberships(membershipTicketGroup);
      if (membership.type === MembershipType.RegularMembership) {
        // From the ticket groups tickets, keep only the one that is linked to a membership product
        // membership products on dev: 37865
        // membership products on prod: 62898
        const toPullMemberships = [37865, 62898];
        const membershipsToPull = membershipsInfo.filter((mem) => toPullMemberships.includes(mem.productId as number));
        // For now, we gonna support only 1
        const toPullMembership = membershipsToPull[0];
        const regularMembershipId = toPullMembership.categoryId;
        const regularMemProductId = toPullMembership.productId;
        if (!regularMembershipId || !regularMemProductId) {
          throw new Error('Membership info not found');
        }
        ReferrerModule.setVisibleCategories();
        await ProductModule.fetchProducts([regularMemProductId]);
        await NBookingModule.fetchProductsTickets([regularMemProductId]);

        // Let's add the membership * count of customers
        // Currently we support only 1 membership, so let's use its info
        const regularMembershipTicket =
          NBookingModule.fetchedTickets.find((ticket) => toPullMemberships.includes(ticket.productId));
        if (!regularMembershipTicket) {
          throw new Error ('No membership fetched');
        }
        const categoryInfoMembership = regularMembershipTicket.categoryInfo;
        const slotMembership = categoryInfoMembership.timeslots[0];
        const priceMembership = slotMembership.export_prices && slotMembership.export_prices[0];
        if (!priceMembership) {
          throw new Error('No price on membership');
        }
        // Let's book that membership with count equal to customers length
        await NBookingModule.addPrice({
          ticket: regularMembershipTicket,
          price: priceMembership,
          count: this.clients.length,
          slot: slotMembership,
        });

        const fetchedBooking = await fetchServerBooking(NBookingModule.bookingId, NBookingModule.bookingToken);
        const {membershipCustomers} = NBookingModule;
        const categoryId = regularMembershipTicket.categoryId;
        const priceCategory = priceMembership.priceCategory;

        // Let's add all the automatically added addons to each customer
        for (const customer of membershipCustomers) {
          // tslint:disable-next-line:max-line-length
          const customerTickets = addRegularTicketsToCustomers(membershipCustomers, membership, fetchedBooking, categoryId, priceCategory);
          customer.tickets = customer.tickets.concat(customerTickets);
        }
        ReferrerModule.setVisibleCategories(String(regularMembershipId));
        this.$emit('done');
        return;
      }

      // currently we don't suport multiple memberships
      if (membershipsInfo.length > 1) {
        throw new Error('No support for multiple memberships yet');
      }

      const membershipId = membershipsInfo[0]?.categoryId;
      const productId = membershipsInfo[0]?.productId;
      if (!membershipId || !productId) {
        throw new Error('Required membership info not present');
      }

      const memberships = await fetchTicketsAddons([membershipId]);
      await ProductModule.fetchProducts([productId]);
      NBookingModule.setProdsTickets({[productId]: memberships});

    } finally {
      disableQuasarStyling();
      Loading.hide();
    }
    this.$emit('done');
    // Set it as visible
    // close stepper
  }
}





















/*
 * This is just a component that will override some styling issues
 * that we had in the old site.
 */
import { isIE } from '@/utils/platform';
import { Component, Vue, Watch } from 'vue-property-decorator';
import UnsupBoard from './components/UnsupBoard.vue';

@Component({
  components: {
    UnsupBoard,
  },
})
export default class Unsup extends Vue {
  private isIE = isIE();
}




































































import { fetchTicketSlots } from '@/api/booking';
import { ESlotsHours, EVSlotsFilter } from '@/models/events';
import { ITicket, ITimeslotsEntity } from '@/models/store/booking';
import {
  IPackageCategory, IPackageMainPrices, IPackagePrice,
  IPackageTimeSlotsEntity, IPricePackage, IPackagePrices,
} from '@/models/store/packages';
import { IProduct } from '@/models/store/product';
import { isSameDay } from '@/utils/helpers';
import { NBookingModule, ProductModule } from '@/utils/storemodules';
import dayjs from 'dayjs';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import Price from './SlotPrice.vue';
import SlotsFilter from './SlotsFilter2.vue';
import SlotsHours from './SlotsHours.vue';
import { getPrimaryColor } from '@/utils/colors';
import { hexToRGBA } from '@/utils/colors';


@Component({
  components: {
    Price,
    SlotsFilter,
    SlotsHours,
  },
})
export default class PackageCategory extends Vue {
  @Prop() public packageCateogry!: IPackageCategory;
  @Prop() public pricePackage!: IPricePackage;
  @Prop() public ticket!: ITicket;
  @Prop() public mainTimeSlotId!: number;
  @Prop() public isLastCategory!: boolean;
  @Prop() public packageError!: string;
  @Prop() public packageId!: string;

  public selectedDates: Date[] = [];
  private slot: IPackageTimeSlotsEntity | null = null;
  private EVSlotsFilter = EVSlotsFilter;
  private ESlotsHours = ESlotsHours;
  private hour = '';
  private slots: ITimeslotsEntity[] | null = null;
  private availableDates: Date[] = [];
  private isPckageError: string = '';


  // Does this ticket have a long description
  private isChangeble: boolean = false;
  // Is the long description fully displayed or is it cut
  private isFullShow: boolean = false;
  // TO-DO: showInfo will decide whether to show ticket name or not in the future
  private showInfo: boolean = false;

  private maxParticipants: number = 0;

  // Cut the packageCateogry.category_description if it is long
  get handleCategoryDescription(): string {
    if (this.packageCateogry.category_description !== null) {
      // category_description comes with <p> in the begining and </p> in the end, lets consider that
      const categoryDescription = this.packageCateogry.category_description.slice(3, -4);
      // Check how much is pure text in string
      const pureText = categoryDescription.replace(/<[^>]*>?/gm, '');
      // Show the See More button uf textLimit is reached
      const textLimit = 90;
      // Useful when packageCateogry.category_description is too near to textLimit
      const cutTextLimit = textLimit - 20;
      // If pure text > textLimit symbols and text is cutted
      if ( pureText.length > textLimit && !this.isFullShow) {
        this.isChangeble = true;
        // return description pure text + html text + '...' in the end
        return categoryDescription.slice(0, cutTextLimit);
      } else {
        return categoryDescription;
      }
    } else {
      return '';
    }
  }

  private toggleFullShow() {
    this.isFullShow = !this.isFullShow;
  }

  get firstPriceId() {
    const catPrices = this.packageCateogry.package_prices;
    const prices = Object.keys(catPrices);
    if (prices.length === 0) {
      return '';
    }

    return catPrices[prices[0]].priceId;
  }

  get showFilter(): boolean {

    // No filters for single slot tickets
    const slots = this.packageCateogry.package_time_slots;
    if (slots && slots.length <= 1) {
      return false;
    }

    // Make sure that all slots aren't from a single day
    // const day1 = dayjs(this.selectedDaysSlots[0].startDateTime);
    // const day1 = dayjs(slots[0].startDateTime);
    // for (const slot of slots) {
    //   if (!isSameDay(day1, dayjs(slot.startDateTime))) {
    //     return true;
    //   }
    // }
    return true;

    // All slots are from same day.
    // No need to show filters
    return false;
  }

  get chosenSlot() {
    const slots = this.slots;
    // const selectedDates = this.selectedDates;
    const date = this.selectedDates[0];
    // skip if no slots to choose from
    if (!slots || !slots.length) {
      return null;
    }

    if (date) {
      const jsDate = dayjs(date);
      return slots.filter((slot) => {
        if (this.hour) {
          return dayjs(slot.startDateTime).isSame(`${jsDate.format('YYYY-MM-DD')} ${this.hour}:00`);
        }
        return isSameDay(jsDate, dayjs(slot.startDateTime));
        // return jsDate.isSame(dayjs(slot.startDateTime).format('YYYY-MM-DD'))
      })[0];
    }

    return slots[0];
  }

  // Returns slots that belong to the same selected day
  get selectedDaySlots(): IPackageTimeSlotsEntity[] | ITimeslotsEntity[] {
    const day = this.selectedDates[0];

    if (!day || !this.slots) {
      return [];
    }

    const jsDay = dayjs(day);
    return this.slots.filter((slot) => {
      return isSameDay(jsDay, dayjs(slot.startDateTime));
    });
  }

  get isCalendarTicket(): boolean {

    // days are already set, forget about it
    if (this.availableDates && (this.availableDates.length > 6)) {
      return true;
    }

    return false;
  }

  get selectedHour(): string {
    if (this.hour) {
      return this.hour;
    }

    if (this.selectedDates && this.selectedDates.length) {
      const date = this.selectedDates[0];
      return dayjs(date).format('HH:mm');
    }

    return '';
  }

  // Returns the category package prices sorted in ascending order.
  // prices with lower priority are at the top of the list.
  get packagePrices(): IPackagePrice[] {
    if (!this.chosenSlot) {
      return [];
    }

    // Transform prices from object to array so that we can sort
    // on priority
    const prices: IPackagePrice[] = [];
    const pricesObj = this.packageCateogry.package_prices;
    for (const priceId in pricesObj) {
      if (!priceId) {
        continue;
      }
      prices.push(pricesObj[priceId]);
    }

    return prices.sort((p1, p2) => p1.priority - p2.priority);
  }

  get showTeamInfo() {
    const cateogryId = this.packageCateogry.category_id;
    const teamSetting = this.packageCateogry.team_settings;
    if (teamSetting && teamSetting[0]) {
      this.maxParticipants = teamSetting[0].maxParticipants;
      return true;
    }
  }

  private get numberofTeams() {
    const teamInfo = NBookingModule.teamInfoPerCategory
                    .find((info) => info.categoryId === this.ticket.categoryId);
    if (teamInfo && teamInfo.maxParticipants && teamInfo.teamCount) {
      const numberofTeams = Math.ceil(teamInfo.teamCount / teamInfo.maxParticipants);
      return numberofTeams;
    }
    return 0;
  }



  private separatorTopBorder() {
    return '1px solid ' + hexToRGBA(getPrimaryColor(), '0.2');
  }

  private created() {
    this.slots = this.packageCateogry.package_time_slots as any;
    if (this.slots) {
      const today = dayjs(new Date());
      const cache: {[date: string]: boolean} = {};
      this.availableDates = this.slots
      .filter((slot) => { // Remove duplicates
        const dateString = dayjs(slot.startDateTime).format('YYYY-MM-DD');
        if (cache[dateString]) {
          return false;
        }
        cache[dateString] = true;
        return true;
      })
      .map((slot) => {
        return dayjs(slot.startDateTime).toDate();
      });
    }
  }

  private onDatesSelected(dates: Date[]) {
    this.selectedDates = dates;
    // this.fetchTicketSlots(dates[0])

  }

  private selectHour(hour: string) {
    this.hour = hour;
  }

  private async fetchTicketSlots(day: Date) {
    // fetch slots starting from the selected day
    const productId = (ProductModule.product as IProduct).id;
    const categoryId = this.packageCateogry.category_id;
    const from = dayjs(day).format('YYYY-MM-DD');
    // to should be "from" at 23:59:59. But despite that, I always get a slot
    // from another day. So, I am just gonna request both days.
    // const to = dayjs(day).add(1, 'day').format('YYYY-MM-DD');
    // this.loading = true;
    try {
      const slots = await fetchTicketSlots({productId, categoryId, from, to: ''});
      // ensure that previous hour is cleared
      this.hour = '';
      // I couldn't get the backend to select only slots from "from",
      // so I am ensuring that only slots on similar day as "from" are returned :)
      // A ticket needs to be created though, I am doing this cause we don't have time
      // to wait for backend team.
      this.slots = slots.filter((slot) => {
        return dayjs(slot.startDateTime).format('YYYY-MM-DD') === from;
      });
    } finally {
      // this.loading = false;
    }
  }

  @Watch('packageError')
  private showWarining() {
    this.isPckageError = this.packageError;
  }
}


































import { Vue, Component, Prop } from 'vue-property-decorator';
import { BookingModule } from '@/utils/storemodules';
import { GET_PRODUCT_NAME, CLEAR_CHARTS, GET_BOOKING_FLOW } from '@/store/modules/booking/constants';
import { BookingSteps, BookingFlowType } from '../../../models/store/booking';
import { destroyCharts } from '../../../utils/helpers';
import { inIframe, closeIframe } from '@/utils/iframe';

@Component({})
export default class BookingNav extends Vue {
  @Prop({ default: '' }) public title!: string;
  @Prop({ default: false }) public noBackArrow!: boolean;

  get hideBackArrow() {
    // check if we are in post booking view
    return this.noBackArrow;
  }

  // handler for close button on top right
  private async close() {
    // destroy charts if present

    if (inIframe()) {
      closeIframe();
      return;
    }

    this.$router.push('/');
  }

  // handler for backArrow button on top left
  private async stepBack() {
    this.$emit('stepBack');
  }
}



















import { ITicket, ITimeslotsEntity } from '@/models/store/booking';
import { IPackageMainPrices, IPackagePrice, IPricePackage } from '@/models/store/packages';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import PackageCategory from './PackageCategory.vue';
import { getPrimaryColor } from '@/utils/colors';
import { hexToRGBA } from '@/utils/colors';
import { NBookingModule, AppModule } from '@/utils/storemodules';
import { genPackageId } from '@/utils/booking';

@Component({
  components: {
    PackageCategory,
  },
})
export default class PricePackage extends Vue {
  @Prop() public pricePackage!: IPricePackage;
  @Prop() public ticket!: ITicket;
  @Prop() public timeSlot!: ITimeslotsEntity;

  private opacity = '0.12';

  // Returns the package categories sorted in ascending order based on their priorities.
  get sortedCategories() {
    // Convert the object into an array of objects so that we can sort them
    let categories: any[];
    categories = Object.values(this.pricePackage.package_categories);

    const finalCategories = categories.filter((t) => t.display_page === 1);

    // Return the array sorted based on 'priority' value of each category
    return finalCategories.sort((c1, c2) => c1.priority - c2.priority);
  }

  get packageId(): string {
    return genPackageId(String(this.timeSlot.id), String(this.pricePackage.price_id));
  }

  get packageError(): string {
    const err = NBookingModule.packageErrors[this.packageId];
    if (!err) {
      return '';
    } else if (err === 'min') {
      const min = this.pricePackage.min_quantity;
      if (min === 1) {
        return this.$t('new-booking-flow.order.package-min-required-single') as string;
      }

      return (this.$t('new-booking-flow.order.package-min-required') as string).replace('XXX', String(min));
    } else if (err === 'max') {
      const max = this.pricePackage.max_quantity;
      // if (min === 1) {
      //   return this.$t('new-booking-flow.order.package-min-required-single') as string;
      // }

      return (this.$t('new-booking-flow.order.package-max-allowed') as string).replace('XXX', String(max));
    }

    return '';
  }

  private get isMobile() {
    return AppModule.isMobile;
  }

  get chooseAddonText() {
   if ([63413, 65365, 65471, 65496, 65497, 65498, 65499, 65500].includes(this.ticket.productId)) {
    return this.$t('new-booking-flow.order.choose-addons-fort-boyard');
   } else {
    return this.$t('new-booking-flow.order.choose-addons');
   }
  }
  private backgroundColor() {
    return hexToRGBA(getPrimaryColor(), this.opacity);
  }

  private setPackageMinRequired() {
    // Set the min required from this package
    const pricePackage = this.pricePackage;
    if (pricePackage && pricePackage.min_quantity) {
      const packageId = this.packageId;
      NBookingModule.addPackageMin({id: packageId, package: pricePackage});
    }
  }

  private destroyed() {
    const pricePackage = this.pricePackage;
    if (pricePackage && pricePackage.min_quantity) {
      const packageId = this.packageId;
      NBookingModule.removePackageMin(packageId);
    }
  }

  @Watch('pricePackage', {immediate: true, deep: true})
  private setPackageMin() {
    this.setPackageMinRequired();
  }
}

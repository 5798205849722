import { gql } from 'graphql-tag';

/**
 * Get Overview of a membership by using its ID
 *
 */
// tslint:disable-next-line
export const GET_MembershipOverview = gql`
  query GetMembershipOverview($id: String!) {
    MembershipDiscountRead(id: $id) {
      id: Id
      name: Name
      numTicket: NumTicket
      minimalNumberTicket: MinimalNumberTicket
      discount: Discount
      discountReduced: DiscountReduced
      status: Status
      type: Type
      ticketGroup: TicketGroupRead {
        id: Id
      }
      num_ticket: NumTicket
    }
  }
`;





































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { IBookingField, IPostBookingField } from '@/models/store/booking';
import NewQuestion from './NewQuestion.vue';
import { getBookingFields, postBookingField } from '@/api/booking';
import { EPersonalDetails } from '@/models/events';
import { NBookingModule, OrganizerModule, ProductModule, ReferrerModule } from '@/utils/storemodules';
import { getShippingRates } from '@/api/rates';
import Debug from 'debug';
import { bookingDecorateErrorMessage } from '@/utils/debug';

const debug = new Debug('smeetz:seats');



@Component({
  components: {
    NewQuestion,
  },
})
export default class OrderDeliveryInformation extends Vue {
  @Prop({default: 0}) private windowWidth!: number;
  @Prop({default: false}) private isSendDeliveryData!: boolean;
  private deliveryActive: boolean = true;
  private validQuestions: boolean[] = [];
  private loading: boolean = false;
  private bookingId: number = -1;
  private bookingToken: string = '';
  private finalRates: any;
  private countryCode: string = '';

  // All booking questions
  private bookingQuestions: IBookingField[] = [];

// unique string for this component
  private uniqueKey: string = String(Math.random() * Math.random());
  private totalWeight: number = 0;

  private noShippingMethodAvailable = false;
  private shippingMethods: [] | null = null;
  private shippingMethod: {
    id: number,
    name: string,
    price: number,
    rateToken: string,
  } | null = null;

  private get recapCategories() {
    return NBookingModule.recapCategories;
  }

   // returns a checkbox value that is unique to this question
  private checkboxVal() {
    return this.uniqueKey;
  }


  private async setCountryCode(value: string) {
    await NBookingModule.deleteDynamicShippingTicket();
    this.countryCode = value;
  }

  private triggerdeliveryActive() {
    this.deliveryActive = !this.deliveryActive;
  }

  private calculateWeight() {
    this.totalWeight = this.recapCategories.map((item) =>
      Number(item.quantity) * Number(item.weight))
      .reduce((a, b) => a + b, 0);
  }

  private async getShippingMethodes() {
    this.noShippingMethodAvailable = false;
    const groupId = OrganizerModule.id;
    const bookingReference = NBookingModule.bookingRes?.bookingRef;

    if (!groupId || this.countryCode === '' || !bookingReference) {
      return;
    }

    this.loading = true;
    try {
      const data = await getShippingRates(groupId, this.countryCode, '', bookingReference);

      if (data && data.length) {
        this.finalRates = data.filter((t: any) =>
          t.minWeight <= this.totalWeight
          && t.maxWeight >= this.totalWeight
          && t.servicePointInput === 'none'
          && t.id !== 8) || null;

        if (this.totalWeight < 0.1) {
          this.finalRates = data.filter((t: any) =>
          t.minWeight === 0.1
          && t.servicePointInput === 'none'
          && t.id !== 8) ||  null;
        }

        this.noShippingMethodAvailable = this.finalRates.length === 0;

        this.shippingMethods = this.finalRates.map((item: any) => {
          return {
            id: item.id,
            name:  item.name + ' ( ' + item.countries[0].price + ' EUR )',
            price: item.countries[0].price,
            rateToken: item.rateToken,
          };
        });

        const dynamicShippingTicketAdded = NBookingModule.bookingRes ?
          NBookingModule.bookingRes.bookingRecap.categories?.filter(
            (t) => t.isShipping === true && t.priceValue > 1) : null;

        if (dynamicShippingTicketAdded && dynamicShippingTicketAdded.length > 0) {
        const shippingMethod = this.shippingMethods?.filter(
          (t: any) => t.price === dynamicShippingTicketAdded[0].priceValue)[0];
        if (shippingMethod) {
          this.shippingMethod = shippingMethod;
        }
        }
      }
    } catch (err) {
      debug(JSON.stringify(err));
      setTimeout(() => {
        throw Error(bookingDecorateErrorMessage(JSON.stringify(err), 'Get Shipping Rates'));
      }, 0);
    }
    this.loading = false;
  }

  private async onShippingMethods() {
    if (!this.shippingMethod) {
      return;
    }
    const url = new URL(window.location.href);
    url.searchParams.set('rateId', String(this.shippingMethod.id));
    window.history.pushState({}, '', url.toString());

    await NBookingModule.updateDynamicShippingTicket(
      {price: this.shippingMethod.price, rateToken: this.shippingMethod.rateToken});
  }

  private async mounted() {
    this.bookingId = NBookingModule.bookingId;
    this.bookingToken = NBookingModule.bookingToken;

    if ( this.bookingId && this.bookingToken) {
      const customFields = await getBookingFields(this.bookingId, this.bookingToken);

      this.bookingQuestions = customFields.booking_fields?.sort((f1, f2) => {
          const s1 = Number(f1.productId) + f1.position;
          const s2 = Number(f2.productId) + f2.position;
          if (s1 > s2) { return 1; } else { return -1; }
        },
      ) as IBookingField[];

      if (this.bookingQuestions.length ) {
        this.validQuestions = new Array(this.bookingQuestions.length).fill(true);
      }
    }
  }

  private emitValidated() {
    this.$emit('validateFields', !this.validQuestions.includes(false));
  }

  private updateQuestionValidity(validity: {position: number, valid: boolean }) {
    this.validQuestions[validity.position - 1] = validity.valid;

    this.emitValidated();
  }

   private touchFields() {
    for (const field of this.bookingQuestions) {
      const question: NewQuestion = (this.$refs['delivery-field' + field.fieldId] as any)[0] as NewQuestion;
      (question as any).touch();
    }
  }

  private updateShippingMethods() {
    this.calculateWeight();
    this.getShippingMethodes();
  }

  @Watch('countryCode')
  private async getShippingMethodByCountry() {
    this.updateShippingMethods();
  }

  @Watch('recapCategories', {deep: true})
  private async setTotalWeight() {
    this.updateShippingMethods();
  }

  // Watch if user press on button on Checkout component
  @Watch('isSendDeliveryData')
  private onSendData() {
    this.sendData();
  }

  private async sendData() {
    // retrieve all the answers
    this.bookingId = NBookingModule.bookingId;
    this.bookingToken = NBookingModule.bookingToken;
    const answers: IPostBookingField[] = [];

    for (const field of this.bookingQuestions) {
      const question: NewQuestion = (this.$refs['delivery-field' + field.fieldId] as any)[0] as NewQuestion;
      const answer = question.getAnswer();

      if (answer.answer) {
        answers.push(answer);
      }
    }
    try {
      if (answers.length) {
        await postBookingField(this.bookingId as number, this.bookingToken, answers);
        this.$emit('isSendDeliveryData');
      }
    } catch (err) {
      // error goes here
      debug(`Error: After submitting booking fields`);
      this.$emit(EPersonalDetails.Error, err);
      throw err;
    }
  }

}

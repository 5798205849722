































































import { Vue, Component } from 'vue-property-decorator';
import { AppModule, NBookingModule as BookingModule, OrganizerModule, RBookingModule } from '@/utils/storemodules';
import { inIframe } from '@/utils/iframe';
import { RefundSteps, StepName } from '@/store/modules/refundBooking/constants';

@Component
export default class RefundNav extends Vue {
  private inIframe = inIframe();

  get isOrganizerUserSpace() {
    return OrganizerModule.isOrganizerUserSpace;
  }

  get steps(): any {
    return StepName;
  }
  get currentStep(): RefundSteps {
    return RBookingModule.currentStep;
  }

  private get isSelectedSlots() {
    return !!RBookingModule.selectedSlots.length;
  }

  private currentStepName(i: RefundSteps): string {
    return this.steps[i].replace(/\s+/g, '').toLowerCase();
  }
  private stepTo(step: number) {
    if (step !== this.currentStep && step !== RefundSteps.Confirmation &&
        this.currentStep !== RefundSteps.Confirmation) {
      const bookingId = BookingModule.bookingId;
      const orgId = this.$route.params.orgId;
      const baseUrl = this.isOrganizerUserSpace ? `/org-user/${orgId}` : '/user-space';
      if (step === 0) {
        this.$router.push(`${baseUrl}/userbookings/${bookingId}`);
      }
      if (step === RefundSteps.RefundSummary && !this.isSelectedSlots) {
        return;
      }
      RBookingModule.setCurrentStep(step);
    }
  }

  private stepBack() {
    this.stepTo(this.currentStep - 1);
  }
}

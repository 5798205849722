


































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { email, required, maxLength } from 'vuelidate/lib/validators';
import { ModalStatus } from '@/models/definitions';
import { NModalModule, NNoty, OrganizerModule, ProductModule, UserModule } from '@/utils/storemodules';
import { IContactOrganizer } from '@/models/organizer';
import Noty from 'noty';


@Component({
  validations: {
    firstname: {
      required,
      maxLength: maxLength(255),
    },
    lastname: {
      required,
      maxLength: maxLength(255),
    },
    email: {
      required,
      email,
      maxLength: maxLength(255),
    },
    message: {
      required,
      maxLength: maxLength(500),
    },
  },
})
export default class ContactOrganizer extends Vue {
  private firstname: string = '';
  private lastname: string = '';
  private email: string = '';
  private message: string = '';

    // Vuelidate doesn't trigger if user doesn't click on field.
  // So we check for error and not-empty fields
  private get enableSubmit() {
    return !this.$v.$error && this.firstname && this.lastname && this.email && this.message;
  }


  private created() {
    this.firstname = UserModule.user.firstName || '';
    this.lastname = UserModule.user.lastName || '';
    this.email = UserModule.user.emails.find((e: any) => e.isPrimary && e.stateName === 'Verified').emailAddress || '';
  }

  // Submit changes to store
  private async submit() {
    const sendData: IContactOrganizer = {
      firstName: this.firstname,
      lastName: this.lastname,
      email: this.email,
      content: this.message,
      subject: `Contact an organizer - ${window.location.href}`,
      preferredlanguage: this.$i18n.locale,
      orgId: String(OrganizerModule.id),
    };
    if ( this.enableSubmit ) {
      try {
        await ProductModule.sendReport(sendData);
        new Noty({
          type: 'success',
          text: String(this.$t('organizer-page.contact-form.on-success-msg')),
          timeout: 4000,
          theme: 'metroui',
        }).show();
      } catch (error) {
        new Noty({
          type: 'error',
          text: String(this.$t('error.error-response-banner')),
          timeout: 4000,
          theme: 'metroui',
        }).show();
        throw error;
      } finally {
        this.$emit('modalClose');
      }
    }
    this.$emit('modalClose');
  }
}











































































import { Vue, Component } from 'vue-property-decorator';
import { Mixins } from 'vue-mixin-decorator';
import BookingDoneMixin from '@/mixins/BookingDone';
import NewButton from '@/components/presentational/NewButton.vue';
import { ReferrerModule, UserModule, NBookingModule, OrganizerModule, NModalModule, AuthModule } from '@/utils/storemodules';
import { ModalStatus } from '@/models/definitions';
import { inIframe } from '@/utils/iframe';
import {getTicketOrReceipt } from '@/api/booking';
import Timeout from 'await-timeout/dist/es5';
import { LoggerService } from '@/services/log';
import { loginWithBookingIdAndToken } from '@/api/auth';
import jwt_decode from 'jwt-decode';
import Noty from 'noty';
import { RouteNames } from '@/models/enums';
import booking from '@/store/modules/booking';
import Mixpanel, { EVENTS } from '@/services/mixpanel';

@Component({
  components: {
    NewButton,
  },
})
export default class BookingConfirmed extends Mixins<BookingDoneMixin>(BookingDoneMixin) {
  private isRequestToBook = false;
  private isLoading: boolean = false;
  private get isLogged(): boolean {
    return UserModule.isLogedIn;
  }

  private created() {
    this.isRequestToBook = NBookingModule.isRequestToBook;
  }

  private openEmailConfirmationModal() {
    NModalModule.setStatus(ModalStatus.BookingEmailConf);
  }

  private get customText() {
    if (ReferrerModule.customText) {
      return ReferrerModule.customText;
    }
    return '';
  }

  private get isPaymentPending(): boolean {
    return NBookingModule.getIsPaymentPending;
  }

  private get showDownloadTicket() {
    const bookingRes = NBookingModule.bookingRes;
    if (!bookingRes || !bookingRes.bookingRecap.categories) {
      return false;
    }
    const tickets = bookingRes.bookingRecap.categories;
    const filtredTickets = tickets.filter((c) => c.isShipping === false && c.retailOptionId === null);
    return filtredTickets.length > 0 ? true : false;
  }

  private get isExternal() {
    return inIframe() || ReferrerModule.isExternalReferrer;
  }

  private async visitProfile() {
    this.isLoading = true;
    const bookingId = Number(this.$route.query.bookingId);
    LoggerService.info(`View Booking: ${bookingId}`);
    try {
      // Get token from bookingId and bookingToken if not loggedIn
      const bookingToken = this.$route.query.bookingToken as string;
      const res = await loginWithBookingIdAndToken(bookingId, bookingToken);
      const accessToken = res.token;
      const object: any = jwt_decode(accessToken!);
      UserModule.setCustomAuthToken(accessToken);
      UserModule.setCustomAuthEmail(object.email);
      const orgId = OrganizerModule.id;
      const ocol = OrganizerModule.orgColor;
      this.$router.push({
        name: RouteNames.BookingSummary,
        params: {
          locale: String(this.$i18n.locale),
          orgId: String(orgId),
          bookingId: String(bookingId),
          },
        query: {
          access_token: accessToken,
          booking_flow: 'true',
          ocol,
        },
      });
    } catch (error) {
      this.isLoading = false;
      new Noty({
        type: 'error',
        text: String(this.$t('error.error-response-banner')),
        timeout: 4000,
        theme: 'metroui',
      }).show();
    } finally {
      this.isLoading = false;
    }
  }

  private download(data: any, bookingId: number) {
    const url = URL.createObjectURL(data);
    const a = document.createElement('a');
    a.download = 'ticket-' + bookingId.toString() + '.pdf';
    a.href = url;
    a.target = '_self';
    a.click();

    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      a.remove();
      URL.revokeObjectURL(url);
    }, 100);
  }
  private async downloadPdf(type: string) {
    const bookingToken = NBookingModule.bookingToken;
    const bookingId = NBookingModule.bookingId;
    LoggerService.info(`Download tickets of bookingId#${bookingId}`);
    // Download tickets Tracking
    Mixpanel.track(EVENTS.DownloadTickets, {});
    try {
      const blob = await getTicketOrReceipt(bookingId, bookingToken, type);
      this.download(blob, bookingId);
    } catch (error) {
      try {
        await Timeout.set(3000);
        const blob = await getTicketOrReceipt(bookingId, bookingToken, type);
        this.download(blob, bookingId);
      } catch (error) {
        LoggerService.info(`Ticket with the bookingId#${bookingId} still not generated`);
      }
    }
  }
}


















import { Vue, Component } from 'vue-property-decorator';
import ModalButton from '@/components/modal/ModalButton.vue';
import { AuthModule } from '@/utils/storemodules';

@Component({
  components: {
    ModalButton,
  },
})
export default class EmailConf extends Vue {
  private email!: string;

  @AuthModule.Action('sendConfEmail') private sendConfEmail!: any;

  private created() {
    this.email = this.$route.query.email as string;
    if (!this.email) {
      return;
    }

    // remove email from query parameters
    const query = Object.assign({}, this.$route.query);
    delete query.email;
    this.$router.replace({ query });
  }

  // Send confirmation email
  private async send() {
    const button: ModalButton = this.$refs.button as ModalButton;
    button.executing(true);
    try {
      await this.sendConfEmail(this.email);
    } catch (err) {
      button.executing(false);
    }
    button.executing(false);
  }
}

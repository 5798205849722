import Debug from 'debug';
import { Module } from 'vuex';

import { IBookingState } from '@/models/store/booking';
import { IRootState } from '@/models/store';
import { State } from './values';
import Actions from './actions';
import Getters from './getters';
import Mutations from './mutations';

const debug = Debug('smeetz:booking');

const namespaced = true;

const booking: Module<IBookingState, IRootState> = {
  namespaced,
  state: State,
  getters: Getters,
  mutations: Mutations,
  actions: Actions,
};

export default booking;


/**
 * Checks whether the object doesn't have any fields
 * @param obj
 */
export function isObjectEmpty(obj: object) {
  return Object.keys(obj).length === 0;
}

/**
 * When using Number((1.005).toFixed(2)) OR Math.round(1.005*100)/100
 * We'll get 1 instead of 1.01
 * Rounding decimals by using numbers represented in exponential notation
 * This way round(1.005, 2) === 1.01
 */
export function round(value: number, decimals: number) {
  return Number(Math.round(Number(value + 'e' + decimals)) + 'e-' + decimals);
}

/**
 * Compares two arrays for equality.
 *
 * @param {T[]} arr1 - The first array to compare.
 * @param {T[]} arr2 - The second array to compare.
 * @returns {boolean} True if the arrays are equal, false otherwise.
 */
export function arraysEqual<T>(arr1: T[], arr2: T[]): boolean {
  // Check if the arrays have the same length
  if (arr1.length !== arr2.length) {
      return false;
  }

  // Iterate through each element and compare them
  for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
          return false;
      }
  }

  // If all elements are equal, return true
  return true;
}

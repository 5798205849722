import { client } from '@/utils/graphqlClient/graphql-client';
import {
  GetControlGroupUserListQuery,
  GetControlGroupUserListQueryVariables,
  GetStrategyPricesListQuery,
  GetStrategyPricesListQueryVariables,
} from '@/types/gql/generated/graphql';
import { GET_ControlGroupUserList, GET_StrategyPrices } from './gql/controlGroupUser.graphql';

/**
 * Returns a list of control group user
 */
export async function getControlGroupUserList(userTrackerId: string, productId: string) {

  const res = await client.query<GetControlGroupUserListQuery, GetControlGroupUserListQueryVariables>(
    GET_ControlGroupUserList,
    { userTrackerId, productId }).toPromise();

  return res.data?.controlGroupUserList;

}

export async function getStrategyPrices(priceIds: number[]) {
  const res = await client.query<GetStrategyPricesListQuery, GetStrategyPricesListQueryVariables>(
    GET_StrategyPrices,
    {priceIds: `(ProductSlotPriceId,in,${priceIds.join(',')})`},
  ).toPromise();

  return res.data?.StrategyPriceList;
}


















import { Vue, Component } from 'vue-property-decorator';
import { Mixins } from 'vue-mixin-decorator';
import {  NBookingModule, ProductModule } from '@/utils/storemodules';
import DonationMixin from '@/mixins/Donation';
import { getLinkedDonations } from '@/api/donation';
import { CategoryType } from '@/models/enums';

@Component({
  components: {
  },
})
export default class DonationConfirmed extends Mixins<DonationMixin>(DonationMixin) {
  private hasDonation = false;

   private async mounted() {
    const bookingRes = NBookingModule.bookingRes;
    if (!bookingRes) {
      this.hasDonation = false;
      return;
    }

    const tickets = bookingRes.bookingRecap.categories || [];
    const hasDonation = !!tickets.filter((i) => i.categoryType === CategoryType.Donation).length;
    if (hasDonation) {
      this.hasDonation = true;
      const categoryIds = tickets.map((cat) => cat.categoryId);
      const ticketDonationList = await getLinkedDonations(categoryIds);
      if (ticketDonationList && ticketDonationList.length > 0) {
        ProductModule.setDonations(ticketDonationList);
      }
    }
  }
}
